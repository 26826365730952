<template>
  <span></span>
</template>
<script>

import A from '../../constants/actions';
import AppConfig from '../../config/appConfig';
import PopupUtils from '../../utils/PopupUtils';
import Features from '../../config/features';
import FeatureType from '../../constants/featureType';
import TimeService from '../../services/timeService';
import OperationPlaneState from "../../constants/operationPlanState";
import NetworkUtils from "../../utils/NetworkUtils";
import DronesMessageProcessor from "../websocket/DroneMessageProcessor";
import {setInterval} from 'worker-timers';
import MapUtils from "../../utils/MapUtils";

export default {
  name: 'MSGSimulator',

  methods: {

    messageCleanUp() {
      const timeWithOffset = TimeService.currentTimeSliderTime().valueOf();
      let opIdsToRemove = [];
      let toRemove = [];
      this.$store.state.informationStore.messages.forEach((msg) => {
        if (msg.featureType === FeatureType.DRONES) {
          return;
        }
        const keepUntilLaterExpired = msg.validTime.keepUntil !== undefined && msg.validTime.keepUntil < timeWithOffset;
        const shouldRemoveImmediately = msg.validTime.to < timeWithOffset && (!msg.validTime.keepUntil);
        if (shouldRemoveImmediately || keepUntilLaterExpired) {
          toRemove.push(msg);
          if (msg.featureType.indexOf('operationplan') !== -1) {
            opIdsToRemove.push(msg.id);
          }
        }
      });
      const droneIdsToRemove = this.$store.state.informationStore.messages.filter((msg) => msg.featureType === FeatureType.DRONE_ALERT &&
        opIdsToRemove.includes(msg.operationPlanIds[0]));
      toRemove = toRemove.concat.apply(toRemove, droneIdsToRemove);
      this.$store.dispatch(A.INFORMATION_PANEL_MESSAGE_CLEANUP, toRemove);
      DronesMessageProcessor.cleanupExpiredDrones(timeWithOffset);
    },

    favoriteMessagesCleanUp() {
      const currentUtcTime = TimeService.currentUtcTime().valueOf();
      this.$store.state.informationStore.favorites.forEach((msg) => {
        const keepUntilLaterExpired = msg.validTime.keepUntil !== undefined && msg.validTime.keepUntil < currentUtcTime;
        const shouldRemoveImmediately = msg.validTime.to < currentUtcTime && (!msg.validTime.keepUntil);
        const op = this.$store.getters.getOperationPlanById(msg.id);
        const shouldRemoveClosedOp = op ? msg.id === op.id && op.state === OperationPlaneState.CLOSED.state : false;
        if (shouldRemoveImmediately || keepUntilLaterExpired || shouldRemoveClosedOp) {
          this.$store.dispatch(A.INFORMATION_PANEL_TOGGLE_FAVORITE_MESSAGE, msg);
        }
      });
    },

    mapOnlyFeaturesCleanup(layerIds) {
      const timeWithOffset = TimeService.currentTimeSliderTime().valueOf();
      const layers = this.$store.state.mapOperations.map.getLayers();
      layers.forEach(layer => {
          if (layerIds.includes(layer.getProperties().id)) {
            const layerSource = MapUtils.getSourceOfLayer(layer);
            layerSource.getFeatures().forEach((f) => {
              const props = f.getProperties();
              if (props.validTime && props.validTime.to <= timeWithOffset) {
                layerSource.removeFeature(f);
              }
              if (props.associatedFeatureId && this.associatedFeatureWasRemovedFromMap(props.associatedFeatureId)) {
                layerSource.removeFeature(f);
              }
            });
          }
        }
      );
    },

    associatedFeatureWasRemovedFromMap(associatedFeatureId) {
      const associatedFeature = this.$store.state.informationStore.messages.filter(m => m.id === associatedFeatureId);
      if (associatedFeature.length !== 0) {
        return associatedFeature[0].featureType.indexOf('operationplan') !== -1 &&
          associatedFeature.state === OperationPlaneState.CLOSED.state;
      } else {
        return true;
      }
    },

    checkMessageTimeout(productTypes) {
      if (!productTypes || productTypes.size === 0) return;

      const timedOutMessages = [];

      this.$store.state.informationStore.messages.forEach(
        msg => {
          productTypes.forEach(productType => {
            if (productType.timeoutHandler.timeoutCheck(msg, productType.id, productType.timeoutHandler.timeoutValue)) {
              timedOutMessages.push({msg, productType});
            }
          });
        }
      );

      timedOutMessages.forEach(entry => {
        this.$store.dispatch(A.INFORMATION_PANEL_MSG_RECEIVED, entry.productType.timeoutHandler.nilMessage(entry.msg));
        PopupUtils.error(entry.productType.name.toUpperCase() + ' ' + this.$i18n.t('popupMessages.timedOut'));
      });
    },

    checkValidityMessageTimeout() {
      const timeWithOffset = TimeService.currentTimeSliderTime().valueOf();
      if (timeWithOffset - this.$store.state.informationStore.lastRenderTime >= AppConfig.ui.timeBetweenRenders) {
        this.$store.dispatch(A.CHANGE_LAST_RENDER_TIME, timeWithOffset);
        this.$store.state.informationStore.forceRenderCount += 1;
      }
    },

    refreshTimeSliderDate() {
      if (!this.$store.state.timeSliderStore.dateIsSelected) {
        this.$store.dispatch(A.TIME_SLIDER_SET_DATE, TimeService.currentUtcTime());
      }
    }
  },

  mounted: function () {
    const self = this;

    setInterval(function () {
      if (!TimeService.isInHistoryMode()) {
        self.messageCleanUp();
        self.favoriteMessagesCleanUp();
      }
    }, AppConfig.ui.messageCleanUpInterval);

    setInterval(function () {
      self.$store.dispatch(A.POPUP_CLEANUP_MESSAGES);
    }, 1000);

    const productTypesToCheckForTimeout = Features.getAvailableFeatures().filter(prodType => prodType.timeoutHandler && prodType.generateNil !== false);
    setInterval(function () {
      self.checkMessageTimeout(productTypesToCheckForTimeout);
    }, AppConfig.ui.messageTimeoutCheckInterval);

    const featuresToCleanup = Features.getAvailableFeatures()
      .filter(feature => feature.displayOnMap && !feature.displayInMessages && !feature.isStaticDataType)
      .map(feature => feature.id);
    setInterval(function () {
      self.mapOnlyFeaturesCleanup(featuresToCleanup);
    }, AppConfig.ui.mapOnlyFeatureCleanUpInterval);

    const heartbeatInterval = AppConfig.ui.heartbeatInterval * 1000;

    setInterval(function () {
      self.$store.dispatch(A.WEBSOCKET_HEARTBEAT);
      if (NetworkUtils.existExpiredHeartbeats(heartbeatInterval)) {
        self.$store.dispatch(A.WEBSOCKET_CLOSED);
      } else if (!self.$store.getters.webSocketIsConnected) {
        self.$store.dispatch(A.WEBSOCKET_CONNECTED);
      }
    }, heartbeatInterval);

    setInterval(function () {
      self.checkValidityMessageTimeout();
    }, AppConfig.ui.timeBetweenRenders);

    setInterval(function () {
      self.refreshTimeSliderDate();
    }, 10 * 1000);
  },

};
</script>
