import FeatureType from '../../constants/featureType';
import FeatureUtils from '../../utils/FeatureUtils';
import Feature from "ol/Feature";
import {v4 as uuidv4} from "uuid";
import timeService from "../../services/timeService";
import ModelUtils from "@/utils/ModelUtils";

export default {

  id: FeatureType.WEATHER_DATA,
  name: 'features.weather',
  icon: 'metar',
  staticDataType: [],
  isViewPortBased: false,
  isFlightLevelBased: false,
  maxNrOfFeaturesPerLocation: 100,
  getReplaceableMessages: FeatureUtils.Filters.filterById,
  zIndex: 1,
  displayOnMap: false,
  displayInMessages: true,

  defaultStyle: () => {
    return {
      showText: true,
      textSize: 14,
      iconSize: 10,
      colors: {
        borderColor: {r: 143, g: 20, b: 58, a: 1},
        fillColor: {r: 143, g: 20, b: 58, a: 0.3},
        textColor: {r: 143, g: 20, b: 58, a: 1},
        textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
        imageColor: {r: 255, g: 255, b: 255, a: 1},
      }
    }
  },

  geoJsonMapper: (feature) => new Feature(feature),

  getMinifiedObject(feature) {
    return {
      id: uuidv4(),
      message: feature.message,
      featureType: feature.featureType,
      validTime: {
        to: new Date(timeService.asMoment(feature.expirationDateTime).toISOString()),
      },
    };
  },

  getInformationPanelMessageFormat(msg) {
    return (`<div class="m-1">${msg.message}</div>`);
  },

  sortInfoPanelMessageList(a, b) {
    return ModelUtils.sort(a.acquisitionDateTime, b.acquisitionDateTime);
  },

}