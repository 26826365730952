<template>
  <div
    id="alert-reply-to-alert-button"
    class="action-button"
    v-if="isAlertReply &&  hasAccessToAlertMessageAndOperationPlanFeature"
  >
    <a @click="toAlertMessage">
      <div>
        <span class="button-icon"><img class="infoPanelButton" v-bind:src="getImg('prod_briefing')"></span>
      </div>
      <span class="button-text">{{getText}}</span>
    </a>
  </div>
</template>

<script>
  import A from '../../../constants/actions';
  import FeatureType from '../../../constants/featureType';
  import HighlightedFeatureService from '../../../services/highlightedFeatureService';
  import FeatureUtils from "../../../utils/FeatureUtils";

  export default {
    name: 'AlertReplyToAlertButton',
    props: ['message', 'getImg', 'getText'],
    computed: {
      isAlertReply() {
        return this.message.featureType === FeatureType.ALERT_REPLY;
      },
      hasAccessToAlertMessageAndOperationPlanFeature() {
        return FeatureUtils.Access.hasAccessToOperationPlanFeature() &&
          FeatureUtils.Access.hasAccessToDroneAlertFeature();
      }
    },
    methods: {
      toAlertMessage() {
        const alertMessage = this.message.alertId ?
          this.$store.state.informationStore.messages.filter(f => f.id === this.message.alertId) : null;
        if (alertMessage && alertMessage.length > 0) {
          setTimeout(() => this.focusFeature(alertMessage[0]), 120);
        }
      },
      focusFeature(alertMessage) {
        HighlightedFeatureService.selectFeature(alertMessage);
        this.$store.dispatch(A.MAP_REFRESH);
      }
    }
  }
</script>