import Features from '../config/features/index';
import FeatureType from '../constants/featureType';

const fields = [
  {
    id: 'free-text',
    title: 'labels.droneAlert.freeText',
    propName: 'free_text',
    isMandatory: false,
    isValid(alertDetails) {
      return !(alertDetails.type === "OTHER_SEE_FREE_TEXT" && alertDetails.free_text.length === 0);
    }
  },
  {
    id: 'severity-type',
    title: 'labels.droneAlert.severityType',
    propName: 'severity_type',
    isDropDown: true,
    options: Features.getFeature(FeatureType.DRONE_ALERT).severityTypes,
    isMandatory: true,
    isValid(severity_type) {
      return severity_type !== '';
    },
  },
  {
    id: 'type',
    title: 'labels.common.type',
    propName: 'type',
    isDropDown: true,
    options: Features.getFeature(FeatureType.DRONE_ALERT).types,
    isMandatory: true,
    isValid(type) {
      return type !== '';
    },
  },

];

const validateField = (propName, value) => {
  let matchingField = fields.filter(f => f.propName === propName);
  return matchingField[0].isValid(value);
};

const getPropNames = () => {
  return fields.map(f => f.propName);
};

export default {
  fields,
  validateField,
  getPropNames
}