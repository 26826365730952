import FeatureType from '../../constants/featureType';
import FeatureUtils from '../../utils/FeatureUtils';
import ThemeElements from '../../constants/theme-elements';
import operationPlanState from '../../constants/operationPlanState';
import OperationPlan from './operationplan';

export default {

  id: FeatureType.OPERATION_PLAN_ACCEPTED,
  name: 'features.operationPlanAccepted.name',
  icon: 'approved_plan',
  abbreviation: 'features.operationPlanAccepted.abbreviation',
  staticDataType: [FeatureType.RESPONSIBILITY_AREA, FeatureType.AIRSPACE],
  maxNrOfFeaturesPerLocation: 200,
  getReplaceableMessages: FeatureUtils.Filters.filterById,
  featureIdentifier: 'id',
  isViewPortBased: false,
  isFlightLevelBased: true,
  maxResolution: 10000,
  declutter: false,
  zIndex: 10,
  flightLevel: null,
  theme: {
    elements: [
      ThemeElements.SHOW_TEXT,
      ThemeElements.BORDER_COLOR,
      ThemeElements.FILL_COLOR,
      ThemeElements.TEXT_COLOR,
      ThemeElements.TEXT_SIZE,
      ThemeElements.TEXT_BG_COLOR,
      ThemeElements.IMAGE_COLOR,
    ]
  },
  displayOnMap: true,
  displayInMessages: true,
  associatedMapOnlyFeatureLayerConfig: OperationPlan.associatedMapOnlyFeatureLayerConfig,
  geoJsonMapper: (feature) => {
    return OperationPlan.geoJsonMapper(feature);
  },

  color: (colors) => {
    return colors.imageColor;
  },

  defaultStyle: () => {
    return {
      showText: true,
      textSize: 14,
      iconSize: 10,
      colors: {
        borderColor: {r: 78, g: 107, b: 242, a: 1},
        fillColor: {r: 78, g: 107, b: 242, a: 0.3},
        textColor: {r: 255, g: 255, b: 255, a: 1},
        textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
        imageColor: {r: 78, g: 107, b: 242, a: 1},
      }
    }
  },

  getNotificationMessage(message) {
    return message.state === operationPlanState.TAKEOFFREQUESTED.state ?
      'OPERATIONPLAN_' + operationPlanState.TAKEOFFREQUESTED.state : message.featureType.toUpperCase();
  },

  getMinifiedObject(feature, minifiedObject) {
    return OperationPlan.getMinifiedObject(feature, minifiedObject);
  },

  sortInfoPanelMessageList(a, b) {
    return OperationPlan.sortInfoPanelMessageList(a, b);
  },

  sortFeaturesList(a, b) {
    return OperationPlan.sortFeaturesList(a, b);
  }

}