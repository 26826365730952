import AppConfig from "../../config/appConfig";
import {Style} from "ol/style";
import CommonStyleFunction from "../CommonStyleFunction";
import {i18n} from '../../internationalization/index';

export default class MetFeatureStyleFunction {

  static buildMetarStyleFunction(stylingConfig, feature, resolution) {
    const ident = feature.getProperties().metarDescription;
    const color = feature.getProperties().color;
    const image = CommonStyleFunction.getImageStyleConfig(stylingConfig, 'icons/metar_yellow.svg', 100, undefined, color);
    if (stylingConfig.showText && resolution < AppConfig.maxResolutionForText) {
      const offsets = {
        x: 0,
        y: 25
      };
      const text = CommonStyleFunction.getTextStyleConfig(stylingConfig, ident, offsets);
      const style = new Style({
        image: image,
        text: text
      });
      return [style];
    } else {
      const style = new Style({
        image: image
      });
      return [style];
    }
  }

  static buildWsWarningStyleFunction(stylingConfig, feature) {
    const awText = i18n.t('labels.map.wsWarning') + ' ' + feature.getProperties().phenomenon;
    const offsets = {
      x: -10,
      y: -50
    };
    const text = CommonStyleFunction.getTextStyleConfig(stylingConfig, awText, offsets);
    const style = CommonStyleFunction.getStyleConfigWithImageAndText(stylingConfig, text);
    const strokeConfig = {
      color: stylingConfig.colors.borderColor,
      width: 4
    };
    const geometryStyle = CommonStyleFunction.getStyleConfigWithCircle(10, strokeConfig, 100);
    return [geometryStyle, style];
  }
}