<template>
  <component
    v-if="message"
    :is="customMessageCard"
    :message="message"
    :model="model"
    :allMessages="allMessages"></component>
</template>

<script>
import A from '../../../constants/actions';
import DialogUtils from "../../../utils/DialogUtils";
import CustomMessageDetailedViewComponentsConfig from "../config/customMessageDetailedViewConfig";

export default {
  name: 'MessageDetailedViewForm',
  props: ['model', 'messageId', 'allMessages'],
  computed: {
    customMessageCard() {
      return CustomMessageDetailedViewComponentsConfig.getCustomComponent(this.message);
    },
    message() {
      const message = this.$store.state.informationStore.messages.find(msg => msg.id === this.messageId);
      const isViewportResetting = this.$store.getters.isViewportResetting;
      if (!message && !isViewportResetting) {
        const messageFeatureType = this.getFeatureTypeDisplayValue(this.model.name);
        DialogUtils.notification({
          title: this.$i18n.t('labels.common.warning'),
          message: this.$i18n.t('logMessages.featureRemoved', {messageFeatureType})
        });
        this.$store.dispatch(A.UTM_PANEL_DESELECT_ELEM);
      }
      return message;
    }
  },
  methods: {
    getFeatureTypeDisplayValue(modelName) {
      switch (modelName) {
        case 'OperationPlan':
          return this.$i18n.t('labels.operationPlan.dronePlan');
        case 'Geozone':
          return this.$i18n.t('labels.geozone.geozone');
        default:
          return this.$i18n.t('labels.common.message');
      }
    }
  }
}

</script>
<style src="../utm.css"></style>