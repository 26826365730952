import AppConfig from '../config/appConfig';
import AjaxUtils from '../utils/AjaxUtils';
import NotamFilterConfig from '../config/notamFilterConfig';

const deleteFilterURL = AppConfig.server.serviceUrlRoot + '/notamFilter';
const saveFilterURL = AppConfig.server.serviceUrlRoot + '/notamFilter';
const getAllFilterURL = AppConfig.server.serviceUrlRoot + '/notamFilter/_all';

function saveFilter(config) {
  return fetch(AjaxUtils.setParameters(saveFilterURL, [AjaxUtils.getUserNameParam()]),
    {
      method: 'put',
      headers: AjaxUtils.getHeaders(),
      body: JSON.stringify(mapFilter(config.filter)),
    })
    .then(response => {
      if (response.status !== 200 && response !== 201) {
        response.text().then(data => {
          config.errorCallback(data);
        });
      } else {
        response.text().then(data => {
          config.successCallback(data);
        });
      }
    })
    .catch(error => config.errorCallback(error));
}

function getAllFilters(config) {
  return fetch(AjaxUtils.setParameters(getAllFilterURL, [AjaxUtils.getUserNameParam()]),
    {
      method: 'get',
      headers: AjaxUtils.getHeaders(),
    }).then(resp => resp.json())
    .then(data => config.onSuccess(data))
    .catch(error => config.onError(error));
}

function deleteFilter(config) {
  return fetch(
    AjaxUtils.setParameters(deleteFilterURL, [
      AjaxUtils.getParamObjectFor('filterId', config.filterId),
      AjaxUtils.getUserNameParam(),
    ]),
    {
      method: 'delete',
      headers: AjaxUtils.getHeaders(),
    })
    .then(data => {
      if (!data.ok) {
        config.errorCallback(data);
      } else {
        config.successCallback(data);
      }
    })
    .catch(error => config.errorCallback(error));
}

function mapFilter(filter) {
  let valueProps = [];
  let rangeProps = [];
  const mappedProperties = NotamFilterConfig.mapFilterProperties;

  Object.keys(filter).filter(prop => mappedProperties.some(mp => mp.name === prop) && filter[prop]).forEach(prop => {
    let property = mappedProperties.find(p => p.name === prop);
    if (property.type === 'valueProp') {
      const propertyValues = filter[prop].split(',');
      valueProps.push({
        'name': property.name,
        'propertyName': property.propertyName,
        'values': propertyValues,
        'pattern': 'INCLUDE',
      });
    } else if (property.type === 'listOfValueProps' && filter[prop].length > 0) {
      valueProps.push({
        'name': property.name,
        'propertyName': property.propertyName,
        'values': Array.isArray(filter[prop]) ? filter[prop] : [filter[prop]],
        'pattern':  property.name.startsWith('qCode') ? filter.qCodeCondition : filter.catCondition,
      });
    } else if (property.type === 'rangeProp' && (filter[prop]['lower'] || filter[prop]['upper'])){
      rangeProps.push({
        'name': property.name,
        'propertyName': property.propertyName,
        'range': {
          'from': filter[prop]['lower'],
          'to': filter[prop]['upper']
        }
      });
    }
  });

  return {
    'id': filter.id,
    'name': filter.name,
    'description': filter.description,
    'filterType': filter.filterType,
    'valueProps': valueProps,
    'rangeProps': rangeProps,
  };
}

export default {
  getAllFilters,
  deleteFilter,
  saveFilter,
  mapFilter,
}