import A from '../../constants/actions';
import M from '../../constants/mutations';
import {i18n} from '../../internationalization/index';
import droneIdentificationTypesConfig from "../../config/droneIdentificationTypesConfig";
import geozoneWhitelistedIdType from "../../constants/geozoneWhitelistedIdType";

const mapDronePlanIds = (ids) => {
  return ids ? ids.map(id => {
    return {
      displayedType: i18n.global.t('labels.geozone.allowlist.dronePlanId'),
      type: geozoneWhitelistedIdType.WHITELISTED_OPS,
      id: id
    }
  }) : [];
};

const mapDroneIds = (objectIdentifications) => {
  return objectIdentifications ? objectIdentifications.map(objectIdentification => {
    const displayedDroneIdType = objectIdentification.other
      || droneIdentificationTypesConfig.getDroneIdentificationTypeObjectByValue(objectIdentification.type)?.name;
    return {
      displayedType: i18n.global.t('labels.geozone.allowlist.droneId') + " - " + displayedDroneIdType,
      type: geozoneWhitelistedIdType.WHITELISTED_DRONES,
      id: objectIdentification.value,
      droneIdType: objectIdentification.type,
      droneIdOtherType: objectIdentification.other,
    }
  }) : [];
};

const mapOperatorIds = (ids) => {
  return ids ? ids.map(id => {
    return {
      displayedType: i18n.global.t('labels.geozone.allowlist.operatorId'),
      type: geozoneWhitelistedIdType.WHITELISTED_OPERATORS,
      id: id
    }
  }) : [];
};

const state = {
  isOpen: false,
  geozone: null,
};

const actions = {
  [A.WHITELIST_PANEL_OPEN]({commit}, message) {
    commit(M.WHITELIST_PANEL_OPEN, message);
  },

  [A.WHITELIST_PANEL_CLOSE]({commit}) {
    commit(M.WHITELIST_PANEL_CLOSE);
  },

  [A.WHITELIST_PANEL_UPDATE_MONITORED_GEOZONE]({commit}, updatedGeozone) {
    commit(M.WHITELIST_PANEL_UPDATE_MONITORED_GEOZONE, updatedGeozone);
  },
};

const mutations = {
  [M.WHITELIST_PANEL_OPEN](state, message) {
    state.isOpen = true;
    state.geozone = message;
  },

  [M.WHITELIST_PANEL_CLOSE](state) {
    state.isOpen = false;
    state.geozone = null;
  },

  [M.WHITELIST_PANEL_UPDATE_MONITORED_GEOZONE](state, updatedGeozone) {
    state.geozone = updatedGeozone;
  }
};

const getters = {
  isWhiteListPanelOpen: state => state.isOpen,
  getGeozoneId: state => state.geozone?.id,
  getSelectedGeozone: state => state.geozone,
  getWhiteListedElements: state => [...mapDronePlanIds(state.geozone.whitelistedOperationPlans),
    ...mapDroneIds(state.geozone.whitelistedDrones),
    ...mapOperatorIds(state.geozone.whitelistedOperators)],
};

export default {
  state,
  actions,
  mutations,
  getters
};