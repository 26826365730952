import {Circle as CircleStyle, Fill, Icon, Style} from "ol/style";
import CommonStyleFunction from "../CommonStyleFunction";
import Features from "../../config/features";
import FeatureType from "../../constants/featureType";
import DroneState from "../../constants/droneState";

/* eslint-disable no-unused-vars, no-dupe-class-members */
export default class DroneStyleFunction {

  static #appliedStylingConfig = null;
  static #contingencyColor = null;
  static #contingencyColorCircle = null;
  static #nonConformantColor = null;
  static #nonConformantColorCircle = null;
  static #themeColor = null;
  static #baseStyle = null;
  static #themeColorIcon = null;
  static #themeColorCircle = null;
  static #contingencyColorIcon = null;
  static #nonConformantColorIcon = null;
  static #trailStyle = null;

  static buildDroneStyleFunctions(stylingConfig, feature, resolution) {
    this.#initialize(stylingConfig);
    this.#updateStylesBasedOnFeature(feature);
    this.#updateStylesWhenSelected(feature);
    this.#updateStylesBasedOnResolution(resolution);
    return [this.#baseStyle, this.#trailStyle];
  }

  static #initialize(stylingConfig) {
    if (!this.#contingencyColor || !this.#nonConformantColor) {
      const featureConfig = Features.getFeature(FeatureType.DRONES);
      this.#contingencyColor = featureConfig.warningIconColor.bgColor;
      this.#nonConformantColor = featureConfig.criticalIconColor.bgColor;
      this.#themeColor = CommonStyleFunction.toRgba(stylingConfig.colors.imageColor);
      this.#themeColorCircle = this.#buildTrailStyleImage(this.#themeColor);
      this.#contingencyColorCircle = this.#buildTrailStyleImage(this.#contingencyColor);
      this.#nonConformantColorCircle = this.#buildTrailStyleImage(this.#nonConformantColor);
    }
    if (!this.#trailStyle) {
      this.#trailStyle = new Style();
    }
    if (this.#stylingConfigWasChanged(stylingConfig)) {
      this.#themeColor = CommonStyleFunction.toRgba(stylingConfig.colors.imageColor);
      this.#baseStyle = this.#buildBaseStyle(stylingConfig);
      this.#themeColorIcon = this.#buildImageStyle(stylingConfig, this.#themeColor);
      this.#contingencyColorIcon = this.#buildImageStyle(stylingConfig, this.#contingencyColor);
      this.#nonConformantColorIcon = this.#buildImageStyle(stylingConfig, this.#nonConformantColor);
      this.#appliedStylingConfig = JSON.stringify(stylingConfig);
    }
  }

  static #updateStylesBasedOnFeature(feature) {
    this.#updateStyleColorsBasedOnState(feature.getProperties().state);
    this.#updateBaseStyleBasedOnFeature(feature);
    this.#trailStyle.setGeometry(feature.getProperties().pointHistoryGeometry);
  }

  static #updateStylesWhenSelected(feature) {
    if(!feature.getProperties().selected){
      this.#baseStyle.getText()?.setText(null);
    } else {
      const baseStyleText = this.#baseStyle.getText();
      baseStyleText?.setText(feature.getProperties().mapLabel);
    }
  }

  static #updateStylesBasedOnResolution(resolution) {
    if (resolution > 5) {
      this.#trailStyle.setGeometry(null);
    }
  }

  static #stylingConfigWasChanged(stylingConfig) {
    return this.#appliedStylingConfig !== JSON.stringify(stylingConfig);
  }

  static #buildBaseStyle(stylingConfig) {
    const text = stylingConfig.showText ?
      CommonStyleFunction.getTextStyleConfig(stylingConfig, null, {x: 0, y: -25}) : null;
    return new Style({text});
  }

  static #buildImageStyle(stylingConfig, color) {
    return new Icon({
      color: color,
      src: 'icons/drone.svg',
      scale: stylingConfig.iconSize / 100
    });
  }

  static #buildTrailStyleImage(color) {
    return new CircleStyle({
      radius: 3,
      fill: new Fill({color})
    })
  }

  static #updateStyleColorsBasedOnState(state) {
    switch (state) {
      case DroneState.CONTINGENCY: {
        this.#applyColorToStyles(this.#contingencyColorIcon, this.#contingencyColorCircle);
        break;
      }
      case DroneState.NON_CONFORMANT: {
        this.#applyColorToStyles(this.#nonConformantColorIcon, this.#nonConformantColorCircle);
        break;
      }
      default: {
        this.#applyColorToStyles(this.#themeColorIcon, this.#themeColorCircle);
        break;
      }
    }
  }

  static #applyColorToStyles(iconStyle, circleStyle) {
    this.#baseStyle.setImage(iconStyle);
    this.#trailStyle.setImage(circleStyle);
  }

  static #updateBaseStyleBasedOnFeature(feature) {
    this.#baseStyle.getImage().setRotation(feature.getProperties().trackAngle || 0);
    const baseStyleText = this.#baseStyle.getText();
    if (baseStyleText) {
      baseStyleText?.setText(feature.getProperties().mapLabel);
    }
  }
}