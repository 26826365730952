<template>
  <div>
    <div class="card smartSISPanel" id="firs">
      <h5 class="card-header">{{ $t('labels.configurationPanel.view.airspaces') }}</h5>
      <ul class="list-group list-group-flush viewList" v-bind:style="{height: heightSize}">
        <template v-for="airspace in airspaces" :key="constructAirspaceEntryKey(airspace)">
          <li
            draggable="true"
            v-on:dragstart="dragStart"
            v-on:dragenter="dragEnter"
            v-on:dragleave="dragLeave"
            v-on:dragover="dragOver"
            v-on:drop="dragDrop"
            v-on:touchstart="touchStart"
            v-on:touchend="touchEnd"
            v-on:touchmove="touchMove"
            class="list-group-item smartSISPanel"
          >
            <div class="row viewListValueWrapper">
              <div class="viewListValue">
                <div>
                  <span class="grip"></span>
                  <p class="viewListItemCaption">{{ airspace.locationIcao }}</p>
                </div>
              </div>
              <div :class="airspace.locationType + '-location-type'" class="icaoLocationIdentifier">
                <div>
                  <p class="m-0">{{ getIcaoLocationIdentifier(airspace.locationType) }} </p>
                </div>
              </div>
              <div>
                <button
                  :class="['btn', 'btn-primary', 'rmvBtn'].join(' ')"
                  @click="removeAirspace(airspace)"
                >
                  <img class="removeImg" v-bind:src="getButtonImg('remove')">
                </button>
              </div>
            </div>
          </li>
        </template>
      </ul>
      <div class="searchForm form-inline">
        <input
          id="newFIRName"
          type="text"
          class="col-8 form-control text-uppercase"
          v-model="newFIRCode"
          :placeholder=getFirPlaceholder()
          v-on:keyup.enter="addFIR"
        >
        <button id="addFIR" type="submit" class="col-4 btn btn-primary" :disabled="newFIRCode === ''" @click="addFIR">
          {{ $t('labels.common.add') }}
        </button>
      </div>
    </div>
    <button id="FIRFilter" type="submit" class="col-7 btn m-2 float-right btn-primary" @click="showFilters">
      {{ $t('labels.configurationPanel.view.firFilters') }}
    </button>
  </div>
</template>
<script>

import A from '../../../../constants/actions';
import ImgUtils from '../../../../utils/ImgUtils';
import FeatureType from '../../../../constants/featureType';

export default {
  name: 'FIRs',

  data: () => {
    return {
      newFIRCode: '',
      dragged: null,
      initialX: null,
      initialY: null,
      elementWithBorder: null,
    }
  },

  computed: {
    airspaces() {
      return this.$store.getters.getCurrentAirspaces;
    },
    heightSize() {
      return this.$store.state.configurationStore.listViewHeight + 'px';
    }
  },
  methods: {
    getIcaoLocationIdentifier(locationType) {
      return locationType === FeatureType.RESPONSIBILITY_AREA ? 'R' : 'F';
    },
    dragStart(evt) {
      evt.dataTransfer.effectAllowed = 'move';
      evt.dataTransfer.setData('text/plain', null);
      this.dragged = evt.target;
    },
    dragOver(evt) {
      evt.preventDefault();
    },
    dragEnter(evt) {
      const target = this.getParentListItem(evt.target);
      target.style['border-bottom'] = '20px solid #414243';
    },
    dragLeave(evt) {
      evt.target.style['border-bottom'] = '';
    },
    dragDrop(evt) {
      evt.preventDefault();
      const target = this.getParentListItem(evt.target);
      target.style['border-bottom'] = '';
      /* eslint-disable no-control-regex */
      const moved = this.dragged.innerText.trim().replace(new RegExp('\n\nR|\n\nF', 'g'), '');
      const insertAfter = target.innerText.trim().replace(new RegExp('\n\nR|\n\nF', 'g'), '');
      this.$store.dispatch(A.VIEW_EDITOR_UPDATE_LOCATION_ORDER, {moved, insertAfter});
    },
    touchStart(evt) {
      this.initialX = evt.touches[0].pageX;
      this.initialY = evt.touches[0].pageY;
      this.dragged = evt.target;
      this.elementWithBorder = evt.target;
      this.elementWithBorder.style['border-bottom'] = '';
    },
    touchEnd(evt) {
      let elmts = document.elementsFromPoint(evt.changedTouches[0].pageX, evt.changedTouches[0].pageY);
      let filteredElements = elmts.filter(e => e.nodeName === 'LI' && e.innerText !== this.dragged.innerText);
      if (filteredElements[0]) {
        const target = this.getParentListItem(filteredElements[0]);
        target.style['border-bottom'] = '';
        this.getParentListItem(this.dragged).style['transform'] = '';
      } else {
        this.elementWithBorder.style['border-bottom'] = '';
        this.getParentListItem(this.dragged).style['transform'] = '';
      }
    },
    touchMove(evt) {
      let dragged = this.getParentListItem(this.dragged);
      let elmts = document.elementsFromPoint(evt.touches[0].pageX, evt.touches[0].pageY);
      let filteredElements = elmts.filter(e => e.nodeName === 'LI' && e.innerText !== this.dragged.innerText);
      if (filteredElements[0]) {
        this.setTranslate(evt.touches[0].pageX - this.initialX, evt.touches[0].pageY - this.initialY, dragged);
        if (filteredElements[0].innerText !== this.elementWithBorder.innerText) {
          this.elementWithBorder.style['border-bottom'] = '';
          this.elementWithBorder = filteredElements[0];
          this.elementWithBorder.style['border-bottom'] = '20px solid #414243';
        }
      }
    },
    setTranslate(xPos, yPos, el) {
      el.style.transform = 'translate3d(' + xPos + 'px, ' + yPos + 'px, 0)';
    },
    getButtonImg(name) {
      return ImgUtils.getButtonImg(name, 'svg');
    },
    removeAirspace(airspace) {
      this.$store.dispatch(A.VIEW_EDITOR_TOGGLE_LOCATION, airspace);
    },
    addFIR() {
      const firCode = this.newFIRCode.trim().toUpperCase();
      this.$store.dispatch(A.VIEW_EDITOR_TOGGLE_AIRSPACE, firCode);
      this.newFIRCode = '';
    },
    showFilters() {
      this.$store.dispatch(A.VIEW_EDITOR_SELECT_FILTER_TYPE, FeatureType.AIRSPACE);
      this.$store.dispatch(A.FIRFILTER_PANEL_TOGGLE);
    },
    getParentListItem(child) {
      let target = child;
      while (target.nodeName.toLowerCase() !== 'li') {
        target = target.parentNode;
      }
      return target;
    },
    constructAirspaceEntryKey(airspace) {
      return airspace.locationIcao + '_' + airspace.locationType;
    },
    getFirPlaceholder() {
      return this.$i18n.t('labels.configurationPanel.view.firCode') + '...';
    }
  },
}

</script>
<style src="./viewEditor.css"></style>