import AlertState from '../constants/alertState';
import MessageUtils from "../utils/MessageUtils";

function getDroneAlertStateColor(state) {
  switch (state) {
    case AlertState.ACTIVE: return MessageUtils.getColors().RED;
    case AlertState.NOT_ACKNOWLEDGED: return MessageUtils.getColors().RED;
    case AlertState.RESOLVED: return MessageUtils.getColors().GREEN;
    case AlertState.ACKNOWLEDGED: return MessageUtils.getColors().GREEN;
    case AlertState.PENDING_ACKNOWLEDGEMENT: return 'rgb(215,147,41)';
    case AlertState.RECEIVED: return 'rgb(90,110,230)';
    default: return '';
  }
}

export default {
  getDroneAlertStateColor
}