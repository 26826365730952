<template>
    <div class="leftPanel h-100">
        <div class="smartSISPanel directoryPanel h-100">
            <ul class="smart-nav">
                <template v-for="directory in directories()" :key="directory.name">
                    <li
                        :class="['topic-tab', isActive(directory.name) ? 'active' : ''].join(' ')"
                        :id="directory.name"
                    >
                        <div class="topic-name">
                            <a
                                @click="selectTopic(directory)"
                                class="directoryName"
                            >
                                <span>{{directory.name}}</span>
                            </a>
                            <img
                                class="directoryImg"
                                :style="getStyle(directory)"
                                @click="openUtm(directory)"
                                src="../../../public/icons/right_arrow.svg"
                                v-if='directory.isSelectable'>
                        </div>
                    </li>
                </template>
                <li
                    class="topic-tab"
                    id="documents"
                >
                    <div class="topic-name">
                        <a
                            @click="openDocumentsPage()"
                            class="directoryName"
                        >
                            <span>DOC</span>
                        </a>
                        <img @click="openDocumentsPage()" class="directoryImg" src="../../../public/icons/right_arrow.svg">
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
  import AppConfig from '../../config/appConfig';
  import A from '../../constants/actions';
  import WebsocketSubscriptionService from "../../services/websocketSubscriptionService";
  import HudTabName from '../../constants/hudTabName';

  export default {
    name: 'DirectoryNavigation',
    methods: {
      directories() {
        return AppConfig.topics.map(t => {
          return {name: t.name, isSelectable: t.isSelectable}
        });
      },
      selectTopic(topic) {
        this.$store.dispatch(A.DIRECTORY_SELECT_TOPIC, topic.name);
        this.reloadDate();
      },
      openDocumentsPage() {
        this.$store.dispatch(A.HUD_SET_OPENED_TAB, HudTabName.DOCUMENTS);
        this.$store.dispatch(A.DOCUMENTS_RESET_IS_UPDATED);
      },
      openUtm(topic) {
        this.$store.dispatch(A.UTM_PANEL_TOGGLE);
        this.$store.dispatch(A.DIRECTORY_ADD_TOPIC, topic.name);
        this.$store.dispatch(A.UTM_PANEL_DESELECT_ELEM);
      },
      isActive(tab) {
        return (this.$store.state.informationStore.selectedTopics.indexOf(tab) !== -1);
      },
      getStyle() {
        return this.$store.getters.isGivenTabOpened(HudTabName.UTM_PANEL) ? 'transform: scaleX(-1)' : '';
      },
      reloadDate() {
        this.$store.dispatch(A.MAP_WIPE);
        this.$store.dispatch(A.INFORMATION_WIPE);
        WebsocketSubscriptionService.websocketSubscribe(this.$store);

      }
    }
  }
</script>
<style src="../information-panel/informationPanel.css"></style>