import AppConfig from "../config/appConfig";
import AjaxUtils from "../utils/AjaxUtils";
import Store from "../store";
import LayerUtils from "../utils/LayerUtils";
import A from "../constants/actions";
import {i18n} from '../internationalization/index';

export default class GeoserverLayersService {

  static serviceUrlRoot = AppConfig.server.serviceUrlRoot;
  static getGeoserverLayersEndpoint = "/getGeoserverLayers";
  static createGeoserverLayerEndpoint = "/createNewLayer";
  static removeGeoserverLayerEndpoint = "/deleteLayer/";

  static readAllGeoserverLayers(config) {
    return fetch(GeoserverLayersService.serviceUrlRoot + GeoserverLayersService.getGeoserverLayersEndpoint,
      {
        method: 'get',
        headers: AjaxUtils.getHeaders(),
      }).then(resp => resp.json())
      .then(data => {
        config.onSuccess(data);
      })
      .catch(error => config.onError(error));
  }

  static async getGeoserverLayers() {
    if (GeoserverLayersService.geoserverLayers === null) {
      GeoserverLayersService.geoserverLayers = await GeoserverLayersService.readAllGeoserverLayers();
    }
    return GeoserverLayersService.geoserverLayers;
  }

  static updateThemesWithGeoserverLayersStylingConfigs(themes) {
    const geoserverLayers = Store.getters.getGeoserverLayers;
    if (geoserverLayers.length !== 0) {
      return themes.map(theme => {
        if (theme.dynamicFeatureStylingConfigs) {
          const dynamicFeatureStylingConfigs = JSON.parse(JSON.stringify(theme.dynamicFeatureStylingConfigs));
          delete theme.dynamicFeatureStylingConfigs;
          theme.stylingConfigs.push(...this.getGeoserverLayersForConfigs(dynamicFeatureStylingConfigs, geoserverLayers));
        }
        return theme;
      });
    }
    return themes;
  }

  static getGeoserverLayersForConfigs(dynamicFeatureStylingConfigs, geoserverLayers) {
    return [...LayerUtils.constructExistingGeoserverLayerStyleConfigs(dynamicFeatureStylingConfigs, geoserverLayers),
      ...LayerUtils.constructGeoserverLayerStyleConfigsIfNotExist(dynamicFeatureStylingConfigs, geoserverLayers)
    ]
  }

  static updateGeoserverLayerByLayerId(layerId) {
    Store.dispatch(A.MAP_REFRESH_LAYER_DATA_BY_ID, layerId);
  }

  static createGeoserverLayerConfiguration(config) {
    return GeoserverLayersService.fetchEndpoint(GeoserverLayersService.createGeoserverLayerEndpoint, config)
  }

  static removeGeoserverLayerConfiguration(config) {
    return GeoserverLayersService.fetchDelete(GeoserverLayersService.removeGeoserverLayerEndpoint, config)
  }

  static fetchEndpoint(endpoint, config) {
    return fetch(GeoserverLayersService.serviceUrlRoot + endpoint,
      {
        method: 'post',
        headers: AjaxUtils.getHeaders(),
        body: JSON.stringify(config.layerConfig),
      })
      .then(response => {
        switch (response.status) {
          case 200:
          case 201:
            response.text().then(data => {
              config.successCallback(data);
            });
            break;
          default:
            response.text().then(data => {
              config.errorCallback(data);
            });
        }
      })
      .catch(error => config.errorCallback(error));
  }

  static fetchDelete(endpoint, config) {
    return fetch(GeoserverLayersService.serviceUrlRoot + endpoint + config.id,
      {
        method: 'delete',
        headers: AjaxUtils.getHeaders(),
      })
      .then(data => {
        if (!data.ok) {
          data.text().then(dataText => config.errorCallback(dataText));
        } else {
          data.text().then(dataText => config.successCallback(dataText));
        }
      })
      .catch(error => config.errorCallback(error));
  }

  static async fetchLayerCapabilities(layerCapabilitiesEndpoint) {
    const response = await fetch(layerCapabilitiesEndpoint);
    if (response.status !== 200) {
      throw new Error(i18n.global.t('errorMessages.fetchLayerCapabilitiesError') + layerCapabilitiesEndpoint);
    }
    return await response.text();
  }
}
