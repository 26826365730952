<template>
  <div class="background-selector">
    <strong class="mr-1">{{ $t("labels.common.basemap") }}:</strong>
    <DropDown name="background-selector-dropdown"
              :options="availableBackgrounds"
              :value="activeBackground"
              :onChange="updateBackground"/>
  </div>
</template>

<script>
import A from '../../constants/actions';
import BackgroundConfig from '../../config/backgroundConfig';
import {mapGetters} from 'vuex';
import DropDown from '../../common/base/DropDown';
import AppConfig from '../../config/appConfig';

export default {
  name: 'BackgroundSelector',
  components: {
    DropDown
  },
  computed: {
    ...mapGetters({
      activeBackground: 'activeViewBasemap',
      geoserverBackgrounds: 'getBaseMapLayers'
    }),
    availableBackgrounds() {
      return [...this.geoserverBackgrounds, ...this.defaultBackgrounds].map(this.toDropdownOption);
    },
    defaultBackgrounds() {
      return AppConfig.enableDefaultBaseMapConfigs ? BackgroundConfig.backgrounds : [];
    },
  },
  methods: {
    updateBackground(backgroundId) {
      this.$store.dispatch(A.UPDATE_BASE_MAP, backgroundId);
    },
    toDropdownOption(backgroundConfig) {
      return {value: backgroundConfig.id, name: backgroundConfig.defaultName};
    }
  }
};
</script>
<style src="./backgroundSelector.css"></style>