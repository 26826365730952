import CommonStyleFunction from "../../CommonStyleFunction";
import {Style} from "ol/style";

export default class DronePlanTextStyleFunction {

  static buildTextStyle(featureProps, stylingConfig) {
    return featureProps.feature.getGeometry().getPolygons()
      .map(((polygon, index) => getLayerTextStyleConfig(featureProps, stylingConfig, polygon, index)));
  }
}

function getLayerTextStyleConfig(featureProps, stylingConfig, polygon, index) {
  const offsets = {
    x: 0,
    y: featureProps.associatedAlerts.length > 0 ? 30 : 0
  };
  const layerTextStyleConfig = CommonStyleFunction.getTextStyleConfig(stylingConfig, featureProps.text[index], offsets, null);
  return featureProps.selected &&
  CommonStyleFunction.shouldDisplayFeatureText(polygon, featureProps.resolution) ?
    CommonStyleFunction.getLayerTextStylingInTheVisualCenterOfGeometry(stylingConfig, layerTextStyleConfig, polygon) :
    new Style({});
}
