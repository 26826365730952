import {v4 as uuidv4} from "uuid";
import GeozoneValidationUtils from "./GeozoneValidationUtils";
import TimeService from "../../services/timeService";
import Store from "../../store";
import A from "../../constants/actions";
import GeozoneService from "../../services/geozoneService";
import Logger from "../LoggerUtils";
import PopupUtils from "../PopupUtils";
import DialogUtils from "../DialogUtils";
import UomConfig from "../../config/uomConfig";
import globalCrsConfig from "../../config/globalCrsConfig";
import AppConfig from '../../config/appConfig';
import GeozoneConfigUtils from "./GeozoneConfigUtils";
import {i18n} from '../../internationalization/index';
import turf from "turf";

export default class GeozoneFormUtils {
  static getInitialGeozone() {
    return generateInitialGeozone([constructInitialAirspaceVolume()]);
  }

  static async saveGeozoneCallback(model) {
    if (GeozoneConfigUtils.hasValidAuthorityDetails(model)) {
      Store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
      const identifier = await generateUniqueIdentifier(model.country);
      saveGeozone(model, identifier);
    } else {
      const errorMessage = i18n.global.t('errorMessages.geozoneValidationError');
      Logger.error(errorMessage);
      DialogUtils.errorNotification(errorMessage);
    }
  }

  static validateGeozoneModel(geozoneInstance) {
    return geozoneInstance ? GeozoneValidationUtils.applyGeozoneValidations(geozoneInstance) : false;
  }

  static async constructGeozoneForResponsibilityArea(designator, country) {
    const responsibilityArea = Store.getters.getResponsibilityAreaByDesignator(designator);
    const geozoneGeometry = constructGeozoneGeometry(responsibilityArea);
    const geozoneAirspaceVolumes = geozoneGeometry.map(geometry => constructAirspaceVolume(responsibilityArea, geometry));
    return await createGeozoneForGeometry(geozoneAirspaceVolumes, country, responsibilityArea);
  }
}

const generateInitialGeozone = (geometry) => {
  return {
    zoneId: uuidv4(),
    identifier: null,
    country: AppConfig.geozoneCountries[0],
    type: 'COMMON',
    restriction: 'PROHIBITED',
    reason: [null],
    otherReasonInfo: null,
    name: 'Temporary no flight zone',
    geometry: geometry,
    applicability: [{
      permanent: "NO",
      startDateTime: TimeService.getUtcStartTimeForCreationForms(),
      endDateTime: TimeService.anHourFromCurrentTimeISOFormatted()
    }],
    zoneAuthority: [AppConfig.geozoneAuthorityDetails]
  };
};

const constructInitialAirspaceVolume = () => {
  const currentCrsTypeDisplayValue = globalCrsConfig.getConfigForCrsType(AppConfig.ui.globalCrs).rasModelValue;
  return {
    uomDimensions: null,
    lowerLimit: null,
    lowerVerticalReference: currentCrsTypeDisplayValue,
    upperLimit: null,
    upperVerticalReference: currentCrsTypeDisplayValue,
    horizontalProjection: null
  }
}

async function generateUniqueIdentifier(country) {
  const config = {
    identifier: generateIdentifier(),
    country: country
  };
  let isUniqueIdentifier = await GeozoneService.checkUasZoneUniqueness(config);
  while (isUniqueIdentifier !== 'true') {
    config.identifier = generateIdentifier();
    isUniqueIdentifier = await GeozoneService.checkUasZoneUniqueness(config);
  }
  return config.identifier;
}

function generateIdentifier() {
  let rand4LetterString = Math.random().toString(36).substring(2, 6);
  return "DNF" + rand4LetterString;
}

function saveGeozone(model, identifier) {
  model.identifier = identifier;
  model.geometry[0].uomDimensions = UomConfig.getCurrentlyConfiguredUomDisplayValue();
  let geozoneCoordinates = model.geometry[0].horizontalProjection.coordinates;
  if (geozoneCoordinates && !GeozoneValidationUtils.isFirstCoordinateEqualWithLastCoordinate(geozoneCoordinates)) {
    geozoneCoordinates[0][geozoneCoordinates[0].length] = geozoneCoordinates[0][0];
  }
  GeozoneService.saveGeozone({
    geozone: model,
    successCallback: () => {
      Store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
      Logger.info(i18n.global.t('logMessages.geozoneCreated'));
      PopupUtils.success(i18n.global.t('popupMessages.geozoneCreated'));
      Store.dispatch(A.FORM_WRAPPER_CLOSE);
    },
    errorCallback: (error) => {
      Store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
      const errorMessage = i18n.global.t('errorMessages.geozoneCreateError', {error});
      Logger.error(errorMessage);
      DialogUtils.errorNotification(errorMessage);
    }
  });
}

function constructGeozoneGeometry(responsibilityArea) {
  const geozoneGeometry = responsibilityArea?.geometry.type === 'MultiPolygon' ?
    mergeMultiPolygon(responsibilityArea?.geometry) : responsibilityArea?.geometry;
  return geozoneGeometry.geometry?.type === 'MultiPolygon' ?
    createPolygonsFromCoordinates(geozoneGeometry.geometry.coordinates) : [geozoneGeometry];
}

async function createGeozoneForGeometry(geometry, country, responsibilityArea) {
  const geozone = generateInitialGeozone(geometry);
  geozone.country = country;
  geozone.identifier = await generateUniqueIdentifier(country);
  geozone.name = 'Restricted AOR ' + responsibilityArea.designator;
  geozone.message = 'Restrict AOR with designator: ' + responsibilityArea.designator;
  geozone.reason = ['OTHER'];
  return geozone;
}

function constructAirspaceVolume(responsibilityArea, geometry) {
  let airspaceVolume = constructInitialAirspaceVolume();
  airspaceVolume.lowerLimit = Math.round(responsibilityArea?.minimumAltitude);
  airspaceVolume.upperLimit = Math.round(responsibilityArea?.maximumAltitude);
  airspaceVolume.uomDimensions = responsibilityArea?.altitudeUom;
  airspaceVolume.lowerVerticalReference = responsibilityArea?.verticalReferenceType;
  airspaceVolume.upperVerticalReference = responsibilityArea?.verticalReferenceType;
  airspaceVolume.horizontalProjection = geometry;
  return airspaceVolume;
}

function mergeMultiPolygon(multipolygon) {
  return multipolygon.coordinates
    .map(polygonCoordinates => turf.polygon(polygonCoordinates))
    .reduce((previousPolygon, currentPolygon) => turf.union(previousPolygon, currentPolygon));
}

function createPolygonsFromCoordinates(coordinates) {
  return coordinates.map(coordinates => turf.polygon(coordinates).geometry);
}