import {createStore} from 'vuex';

import GeoserverLayers from './modules/geoserverLayers';
import ColorPickerStore from './modules/colorPicker';
import ConfigurationStore from './modules/configuration';
import DialogStore from './modules/dialog';
import DocumentsStore from './modules/documents';
import DroneAlertDetails from './modules/droneAlertDetails';
import AlertReplyDetails from './modules/alertReplyDetails';
import HUDStore from './modules/hud';
import InformationStore from './modules/information';
import MapOperations from './modules/mapOperations';
import NotamFilter from './modules/notamFilter';
import NotificationStore from './modules/notification';
import PopupStore from './modules/popup';
import ThemeStore from './modules/theme';
import ThresholdStore from './modules/threshold';
import TimeSliderStore from './modules/timeSlider';
import UpdateStore from './modules/update';
import UserStore from './modules/user';
import UtmConfiguration from './modules/utmConfiguration';
import UtmStore from './modules/utm';
import ViewStore from './modules/view';
import EmbeddedMapStore from './modules/embeddedMap';
import WebSocketStore from './modules/websocket';
import WorkspaceStore from './modules/workspace';
import FormWrapper from './modules/formWrapper';
import OperationPlanTrajectory from './modules/operationPlanTrajectory';
import OpRejectionDialog from './modules/opRejectionDialog';
import OpRevokeDialog from "./modules/opRevokeDialog";
import LayerConfigurationStore from './modules/layerConfiguration';
import GeozoneWhiteListDetailsStore from './modules/geozoneWhitelistDetails';
import GlobalConfigurationsStore from './modules/globalConfigurations';
import CollapseInfoPanelMessage from './modules/collapseInfoPanelMessage'
import IcaoLocationsConfigDialog from './modules/icaoLocationsConfigDialog';
import RestrictAorDialog from './modules/restrictAorDialog.js';

const store = createStore({
  modules: {
    restrictAorDialogStore: RestrictAorDialog,
    geoserverLayersStore: GeoserverLayers,
    colorPickerStore: ColorPickerStore,
    configurationStore: ConfigurationStore,
    dialogStore: DialogStore,
    documentsStore: DocumentsStore,
    droneAlertDetails: DroneAlertDetails,
    alertReplyDetails: AlertReplyDetails,
    hudStore: HUDStore,
    informationStore: InformationStore,
    mapOperations: MapOperations,
    notamFilterStore: NotamFilter,
    notificationStore: NotificationStore,
    popupStore: PopupStore,
    themeStore: ThemeStore,
    thresholdStore: ThresholdStore,
    timeSliderStore: TimeSliderStore,
    updateStore: UpdateStore,
    userStore: UserStore,
    utmConfigurationStore: UtmConfiguration,
    utmStore: UtmStore,
    viewStore: ViewStore,
    embeddedMapStore: EmbeddedMapStore,
    webSocketStore: WebSocketStore,
    workspaceStore: WorkspaceStore,
    formWrapper: FormWrapper,
    operationPlanTrajectory: OperationPlanTrajectory,
    opRejectionDialogStore: OpRejectionDialog,
    opRevokeDialogStore: OpRevokeDialog,
    layerConfigurationStore: LayerConfigurationStore,
    geozoneWhiteListDetailsStore: GeozoneWhiteListDetailsStore,
    collapseInfoPanelMessageStore: CollapseInfoPanelMessage,
    globalConfigurationsStore: GlobalConfigurationsStore,
    icaoLocationsConfigDialog: IcaoLocationsConfigDialog,
  }
});

export default store;
