<template>
  <div :class="[this.isDisabled ? 'disabled-action-button' : 'action-button']" id="reject-button-id">
    <component :is="rejectionButtonComponent" :message="message" :getImg="getButtonImg" :isDisabled="isDisabled"/>
  </div>
</template>
<script>

import DronePlanUtils from "../../../../utils/dronePlans/DronePlanUtils";
import UserRolePriorities from "../../../../utils/dronePlans/UserRolePriorities";
import RejectOpButton from "./RejectOpButton";
import ImgUtils from "../../../../utils/ImgUtils";

export default {
  name: 'RejectRevokeEndOpButton',
  props: ['message'],

  computed: {
    isDisabled() {
      return DronePlanUtils.isCloseOpButtonDisabledForDronePlan(this.message);
    },
    rejectionButtonComponent() {
      return this.message ? UserRolePriorities.getButtonComponentForOp(this.message) : RejectOpButton;
    }
  },
  methods: {
    getButtonImg(name) {
      return ImgUtils.getButtonImg(name, 'svg');
    },
  }
}
</script>