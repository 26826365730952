<template>
  <div id="feature-selection-popup" class="ol-popup">
    <a href="#" id="popup-close-button" class="ol-popup-close-button">
      <img class="popup-close-button-image" v-bind:src="getButtonImage('close')" alt="Close"/>
    </a>
    <div id="popup-content">
      <div class="popup-title"></div>
      <div class="popup-message-container">
        <div v-for="message in messages" :key="message.associatedFeatureId || message.id">
          <div v-on:click="selectFeature(message)"
               :key="message.associatedFeatureId || message.id"
               :id="'select-message-' + (message.associatedFeatureId || message.id)"
               :class="['selectMessageDetails', isSelected(message)? 'selectedMessage' : '']"
               v-html="getDisplayMessage(message)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FeatureSelectionConfig from '../../config/map/featureSelectionConfig';
import {mapGetters} from 'vuex';
import HighlightedFeatureService from "../../services/highlightedFeatureService";
import ImgUtils from "../../utils/ImgUtils";

export default {
  name: 'FeatureSelectionPopup',
  computed: {
    ...mapGetters({
      messages: 'getClickedFeatures',
      selectedFeatureId: 'selectedFeatureId',
    }),
  },
  methods: {
    getButtonImage(name) {
      return ImgUtils.getButtonImage(name, 'svg');
    },
    getDisplayValueForMessage(message) {
      return FeatureSelectionConfig.findDisplayedValueForFeature(message);
    },
    getDisplayName(message) {
      return FeatureSelectionConfig.findDisplayedName(message);
    },
    getDisplayMessage(message) {
      return this.getDisplayName(message) + " - " + this.getDisplayValueForMessage(message);
    },
    selectFeature(message) {
      HighlightedFeatureService.selectFeature(message);
    },
    isSelected(message) {
      const messageId = message.associatedFeatureId || message.id;
      return messageId === this.selectedFeatureId;
    }
  }
}
</script>
<style src="./featureSelectionPopup.css"></style>
