<template>
  <MessageDetailedViewForm
    v-if="selectedElem"
    :all-messages="getMessages"
    :messageId="selectedElem"
    :model="model"
  />
  <List
    v-else
    :elements="getMessages"
    :listHeight="'100'"
    :model="model"
    :onSelect="this.onSelect"
    :onSort="this.onSort"
    :reverse="this.reverse"
    :sortCriteria="this.lastSortCriteria"
  />
</template>

<script>
import List from './common/List';
import FeatureType from '../../../constants/featureType';
import MessageDetailedViewForm from '../detailed-view-form-components/MessageDetailedViewForm';
import A from '../../../constants/actions';
import LaraAirspaceModel from '../../../model/laraAirspace';

export default {
  name: 'LaraAirspaces',
  components: {
    MessageDetailedViewForm,
    List,
  },
  data() {
    return {
      lastSortCriteria: null,
      reverse: false,
    }
  },
  computed: {
    model() {
      return LaraAirspaceModel;
    },
    getMessages() {
      const messages = this.$store.state.informationStore.messages.filter(msg => msg.featureType === FeatureType.LARA_AIRSPACE);

      if (this.lastSortCriteria) {
        const index = this.model.fields.findIndex(field => field.propertyName === this.lastSortCriteria);
        const sortMethod = this.model.fields[index].sort;
        let sortedMessages = messages.sort(sortMethod);
        return this.reverse ? sortedMessages.reverse() : sortedMessages;
      }

      return messages;
    },
    selectedElem() {
      return this.$store.state.utmStore.selectedId;
    }
  },
  methods: {
    onSelect(element) {
      this.$store.dispatch(A.UTM_PANEL_SELECT_ELEM, element.id);
    },
    onSort(propertyName) {
      const index = this.model.fields.findIndex(field => field.propertyName === propertyName);
      if (index > -1) {
        this.reverse = this.lastSortCriteria === propertyName && !this.reverse;
        this.lastSortCriteria = propertyName;
      }
    }
  }
}
</script>