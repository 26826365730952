import FeatureBase from './base';
import FeatureType from '../../constants/featureType';
import FeatureUtils from '../../utils/FeatureUtils';
import ThemeElements from '../../constants/theme-elements';

export default {

  id: FeatureType.AIRMET,
  name: 'features.airmet',
  icon: 'airmet',
  displayOnMap: true,
  displayInMessages: true,
  staticDataType: [FeatureType.AIRSPACE],
  isViewPortBased: false,
  isFlightLevelBased: false,
  maxNrOfFeaturesPerLocation: 30,
  getReplaceableMessages: FeatureUtils.Filters.filterByAirmetSigmet,
  featureIdentifier: 'id',
  maxResolution: 10000,
  declutter: false,
  zIndex: 1,
  flightLevel: null,
  theme: {
    elements: [
      ThemeElements.SHOW_TEXT,
      ThemeElements.BORDER_COLOR,
      ThemeElements.FILL_COLOR,
      ThemeElements.TEXT_COLOR,
      ThemeElements.TEXT_SIZE,
      ThemeElements.TEXT_BG_COLOR,
      ThemeElements.IMAGE_COLOR,
      ThemeElements.ICON_SIZE,
    ]
  },

  geoJsonMapper: (feature) => FeatureBase.mapSigmetAirmet(feature, 'airmet'),

  color: (colors) => {
    return colors.imageColor;
  },

  defaultStyle: () => {
    return {
      showText: true,
      textSize: 14,
      iconSize: 10,
      colors: {
        borderColor: {r: 116, g: 211, b: 33, a: 1},
        fillColor: {r: 116, g: 211, b: 33, a: 0.1},
        textColor: {r: 255, g: 255, b: 255, a: 1},
        textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
        imageColor: {r: 116, g: 211, b: 33, a: 1}
      }
    }
  },

  getMinifiedObject(feature, minifiedObject) {
    return {
      ...minifiedObject,
      canceled: feature.canceled,
      validTime: feature.canceled ? {to: 1, from: 1} : feature.validTime,
      canceledId: (feature.canceled) ? feature.canceledId : false,
      sigmetId: feature.sigmetId,
    }
  },

  sortFeaturesList(e1, e2) {
    if ((e1.canceledId || e2.canceledId) && !(e1.canceledId && e2.canceledId)) {
      return (e1.canceledId) ? 1 : -1;
    }
    return 0;
  }
}