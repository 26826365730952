import A from '../../constants/actions';
import M from '../../constants/mutations';

const state = {
  openedMessageId: null
};

const actions = {
  [A.COLLAPSE_INFO_PANEL_MESSAGE_TOGGLE]({commit}, messageId) {
    commit(M.COLLAPSE_INFO_PANEL_MESSAGE_TOGGLE, messageId);
  }
};

const mutations = {
  [M.COLLAPSE_INFO_PANEL_MESSAGE_TOGGLE](state, messageId) {
    if (state.openedMessageId === messageId) {
      state.openedMessageId = null;
    } else {
      state.openedMessageId = messageId;
    }
  }
}

const getters = {
  isInfoPanelMessageOpened: state => messageId => state.openedMessageId === messageId
};

export default {
  state,
  actions,
  mutations,
  getters
};