<template>
  <div>
    <div class="card smartSISPanel" id="airports">
      <h5 class="card-header">{{ $t('labels.configurationPanel.groupAndRole.roles') }}</h5>
      <div class="list-group groupList" v-bind:style="{height: heightSize}">
        <template v-for="role in roles" :key="role.name">
          <button
            :id="role.id"
            type="btn btn-primary"
            class="list-group-item smartSISPanel"
            @click="displayRoleConfiguration(role)"
          >
            {{ role.name }}
          </button>
        </template>
      </div>
    </div>
  </div>
</template>
<script>

import A from '../../../../constants/actions';

export default {
  name: 'Roles',

  data: () => {
    return {
      newRoleName: '',
    }
  },

  computed: {
    roles() {
      return this.$store.state.userStore.currentGroup.roles;
    },
    heightSize() {
      return this.$store.state.configurationStore.listViewHeight + 'px';
    }
  },

  methods: {
    displayRoleConfiguration(role) {
      this.$store.dispatch(A.USER_MANAGEMENT_ENABLE_DISPLAY_OF_ROLE_EDITOR);
      this.$store.dispatch(A.USER_MANAGEMENT_SELECT_ROLE, role);
    }
  }

}

</script>
<style src="./groupEditor.css"></style>