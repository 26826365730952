<template>
  <div id="form-wrapper-container" class="form-wrapper-container">
      <component :is="formComponent"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "FormWrapper",
  computed: {
    ...mapGetters({
      formComponent: 'getFormWrapperComponent',
    })
  },
}
</script>

<style src="./formWrapper.css"/>