<template>
  <div class="numberItem input-group">
    <button
      id="variables-number-item-decrease"
      class="btn btn-primary"
      @click="onDecrease()"
    >
      -
    </button>
    <input
      class="form-control numberItemInput"
      type="text"
      @keyup="this.onChange"
      @change="this.onChange"
      :value="value">
    <button
      id="variables-number-item-increase"
      class="btn btn-primary"
      @click="onIncrease()"
    >
      +
    </button>
  </div>
</template>
<script>

import A from '../../../../constants/actions';

export default {
  name: 'NumberItem',
  props: ['value', 'minValue', 'maxValue', 'reverseOrder', 'name', 'thresholdIndex'],
  methods: {
    onChange(event) {
      const newValue = event.target.value.trim() || 0;
      if (!newValue || isNaN(newValue)) {
        event.preventDefault();
        event.target.value = this.value;
      } else {
        event.target.value = parseInt(event.target.value);
        this.$store.dispatch(A.THRESHOLD_UPDATE_VALUE, {
          propName: this.name,
          thresholdIndex: this.thresholdIndex,
          thresholdValue: event.target.value,
        });
      }
    },
    onDecrease() {
      const newValue = parseInt(this.value) - 1;
      if ((this.reverseOrder) ? (newValue <= this.minValue) : (newValue >= this.minValue)) {
        this.$store.dispatch(A.THRESHOLD_UPDATE_VALUE, {
          propName: this.name,
          thresholdIndex: this.thresholdIndex,
          thresholdValue: newValue,
        });
      }
    },
    onIncrease() {
      const newValue = parseInt(this.value) + 1;
      if ((this.reverseOrder) ? (newValue >= this.maxValue) : (newValue <= this.maxValue)) {
        this.$store.dispatch(A.THRESHOLD_UPDATE_VALUE, {
          propName: this.name,
          thresholdIndex: this.thresholdIndex,
          thresholdValue: newValue,
        });
      }
    },
  }

};
</script>
<style src="./thresholdsEditor.css"></style>
