<template>
  <div class="leftPanel h-100">
    <div id="left-panel-container" class="left-panel-container h-100" v-if="isInfoPanelOpen"
         v-bind:style="{width: widthSize}">
      <ProductNavigation :bgColor="color"/>
      <DraggableBorder
        :position="'right'"
        :minSize="minSize"
        :maxSize="maxSize"
        :bgColor="color"
      />
      <TasksPanel v-if="taskTabIsOpened"/>
      <InformationPanel v-else/>
    </div>
    <FormWrapper v-else-if="isFormOpen"/>
    <ProductNavigation :bgColor="color" v-else/>
  </div>
</template>

<script>
import InformationPanel from '../information-panel/InformationPanel.vue';
import DraggableBorder from '../left-panel/DraggableBorder.vue';
import draggableConfig from '../../config/draggableConfig';
import ProductNavigation from './ProductNavigation.vue';
import FormWrapper from "../form-wrapper/FormWrapper";
import FeatureUtils from '../../utils/FeatureUtils';
import TasksPanel from "../information-panel/tasks/TasksPanel";
import {mapGetters} from "vuex";
import GlobalConfigurationUtils from "../../utils/GlobalConfigurationUtils";
import GlobalConfigurationIdConfig from "../../config/GlobalConfigurationIdConfig";

export default {
  name: 'LeftPanel',
  components: {
    TasksPanel,
    InformationPanel,
    DraggableBorder,
    ProductNavigation,
    FormWrapper,
  },

  computed: {
    ...mapGetters({
      taskTabIsOpened: 'taskTabIsOpened',
    }),
    widthSize() {
      return this.$store.state.informationStore.width + 'px';
    },
    isInfoPanelOpen() {
      return this.$store.state.informationStore.activeTab || this.taskTabIsOpened;
    },
    isFormOpen() {
      return this.$store.getters.isFormWrapperOpened;
    },
    minSize() {
      return draggableConfig.leftPanel.minSize;
    },
    maxSize() {
      return draggableConfig.leftPanel.maxSize;
    },
    color() {
      const activeProduct = this.$store.state.informationStore.activeTab;
      if (activeProduct) {
        const color = FeatureUtils.Styling.getFeatureColor(activeProduct.featureType);
        return 'rgba(' + [color.r, color.g, color.b, color.a].join(',') + ')';
      } else if (this.taskTabIsOpened) {
        const taskSubTabColor = GlobalConfigurationUtils.getDisplayConfigColorByIdAndByCurrentTheme(
          GlobalConfigurationIdConfig.DISPLAY_CONFIGURATION_IDS.TASKS_SUBTAB
        );
        return FeatureUtils.Styling.toRgba(taskSubTabColor);
      }
      return '';
    }
  },

}
</script>
<style src="../information-panel/informationPanel.css"></style>