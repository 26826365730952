import Store from '../store';
import {i18n} from "../internationalization/index";

export default class AjaxUtils {

  static getHeaders() {
    return {
      'Content-type': 'application/json',
      'Authorization': this.getAuthorization(),
    };
  }

  static getAuthorization() {
    return 'Bearer ' + Store.state.userStore.authInstance.token;
  }

  static getParamObjectFor(name, value) {
    return {name, value};
  }

  static getUserNameParam() {
    return AjaxUtils.getParamObjectFor('username', Store.state.userStore.userProfile.username);
  }

  static setParameters(url, params) {
    const paramString = params.map(param => {
      return param.name + '=' + param.value;
    }).join('&');

    return url + '?' + paramString;
  }

  static parseErrorMessage(error) {
    try {
      const parsedError = JSON.parse(error);
      return parsedError.code ? i18n.global.t('serviceErrorMessages.' + parsedError.code) : '';
    } catch (e) {
      return error;
    }
  }

}