import TimeService from "../services/timeService";
import FeatureType from "../constants/featureType";
import AlertState from "../constants/alertState";
import A from "../constants/actions";
import MessageUtils from "./MessageUtils";
import AppConfig from "../config/appConfig";
import Store from '../store';

export default class ProgressBarUtils {

  static computeProgressBarStyling(message) {
    const timeWithOffset = TimeService.currentTimeSliderTime().valueOf();
    Store.dispatch(A.CHANGE_LAST_RENDER_TIME, timeWithOffset);
    let currentValidityPercent = calculateCurrentValidityPercent(message);

    return {
      'width': currentValidityPercent + '%',
      'height': 15 + 'px',
      'background-color': getProgressBarBackgroundColor(message),
    };
  }

}

function shouldDisplayFullPercentForMessage(message) {
  return message.featureType === FeatureType.DRONE_ALERT &&
    (message.state === AlertState.NOT_ACKNOWLEDGED || message.state === AlertState.ACKNOWLEDGED);
}

function calculateCurrentValidityPercent(message) {
  const thresholdForPermNotam = 221842620000000; // year 9000 in millis
  const minValue = message.validTime.from;
  const maxValue = message.validTime.to;
  const timeWithOffset = TimeService.currentTimeSliderTime().valueOf();

  let currentValidityPercent = null;

  if (shouldDisplayFullPercentForMessage(message)) {
    currentValidityPercent = 100;
  } else {
    if (timeWithOffset < minValue) {
      currentValidityPercent = 0;
    } else if (timeWithOffset > maxValue) {
      currentValidityPercent = 100;
    } else {
      currentValidityPercent = 100 - (maxValue - timeWithOffset) * 100 / (maxValue - minValue);
    }
    currentValidityPercent = maxValue > thresholdForPermNotam ? 50 : currentValidityPercent;
  }
  return currentValidityPercent;
}

function getProgressBarBackgroundColor(message) {
  let timeWithOffset = TimeService.currentTimeSliderTime().valueOf();
  if (message.state === AlertState.NOT_ACKNOWLEDGED || timeWithOffset > message.validTime.to) {
    return MessageUtils.getColors().RED;
  }
  return AppConfig.ui.progressBarColor;
}