<template>
  <div v-if="isOpen" id="icao-locations-config-dialog-container" class="dialogContainer">
    <div class="dialog modal" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div id="icao-locations-config-dialog-modal-content" class="modal-content smartSISPanel">
          <div id="icao-locations-config-dialog-modal-header" class="modal-header">
            <h5 id="icao-locations-config-dialog-modal-title" class="modal-title">
              {{ $t('labels.icaoLocations.configureIcaoLocations') }}
            </h5>
          </div>
          <div id="icao-locations-config-dialog-modal-body" class="modal-body errorBody">
            <div class="pb-3">
              <div class="row p-1"
                   v-for="icaoLocation in availableIcaoLocations"
                   :key="constructAirspaceEntryKey(icaoLocation)">
                <div class="col-9 dialog-text-align ml-4">{{ constructLocationDescription(icaoLocation) }}</div>
                <button id="button-add"
                        class="col-2 btn btn-primary"
                        :disabled="isIcaoLocationAlreadyConfiguredOnView(icaoLocation)"
                        @click="addIcaoLocation(icaoLocation)">
                  {{ $t('labels.common.add') }}
                </button>
              </div>
            </div>
            <div id="icao-locations-config-dialog-modal-footer" class="modal-footer p-0 pt-1">
              <div>
                <button id="icao-locations-config-cancel-button"
                        class="btn btn-primary"
                        @click="cancelConfigAction">
                  {{ $t('labels.common.close') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import A from '../../constants/actions';
import {mapGetters} from 'vuex';
import FeatureType from '../../constants/featureType';

export default {
  name: "IcaoLocationsConfigDialog",
  components: {},
  computed: {
    ...mapGetters({
      isOpen: 'isIcaoLocationsConfigDialogOpen',
      availableIcaoLocations: 'getAvailableIcaoLocations',
    }),
    airspaces() {
      return this.$store.getters.getCurrentAirspaces;
    },
  },
  methods: {
    cancelConfigAction() {
      this.$store.dispatch(A.VIEW_EDITOR_TOGGLE_ICAO_LOCATIONS_DIALOG_CONFIG);
    },
    constructAirspaceEntryKey(airspace) {
      return airspace.locationIcao + '_' + airspace.locationType;
    },
    constructLocationDescription(airspace) {
      return airspace.locationIcao + ' - ' + this.constructLocationTypeName(airspace);
    },
    constructLocationTypeName(airspace) {
      return airspace.locationType === FeatureType.AIRSPACE ?
        this.$i18n.t('features.airspace') : this.$i18n.t('features.responsibilityArea');
    },
    isIcaoLocationAlreadyConfiguredOnView(icaoLocation) {
      return this.airspaces.some(airspace =>
        airspace.locationIcao === icaoLocation.locationIcao && airspace.locationType === icaoLocation.locationType
      );
    },
    addIcaoLocation(icaoLocation) {
      this.$store.dispatch(A.VIEW_EDITOR_TOGGLE_LOCATION, icaoLocation);
    }
  }
}
</script>

<style src="./dialog.css"/>