<template>
  <div>
    <div class="card smartSISPanel" id="categories">
      <h5 class="card-header">{{ $t('labels.configurationPanel.notamFilter.categories') }}</h5>
      <ul class="list-group list-group-flush viewList" v-bind:style="{height: heightSize}">
        <template v-for="category in categories" :key="category">
          <li class="list-group-item smartSISPanel">
            <div class="row">
              <div class="col">
                {{ category }}
              </div>
              <div class="col-1 text-right p-0 m-0">
                <button
                  id="remove-category"
                  :class="['btn', 'btn-primary', 'rmvBtn'].join(' ')"
                  @click="removeCategory(category)"
                >
                  <img class="removeImg" v-bind:src="getButtonImg('remove')">
                </button>
              </div>
            </div>
          </li>
        </template>
      </ul>
      <div class="searchForm form-inline">
        <input
          id="newCategory"
          type="text"
          class="col-8 form-control text-uppercase"
          v-model="newCategory"
          :placeholder=getCategoryPlaceholder()
          v-on:keyup.enter="addCategory"
        >
        <button id="add-category" type="submit" class="col-4 btn btn-primary" @click="addCategory">
          {{ $t('labels.common.add') }}
        </button>
      </div>
      <div class="inclusionControl">
        <div class="custom-control custom-radio custom-control-inline">
          <input
            type="radio"
            id="categoryRadioAll"
            value="ALL"
            v-model="picked"
            class="custom-control-input">
          <label class="custom-control-label" for="categoryRadioAll">
            {{ $t('labels.common.all') }}
          </label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input
            type="radio"
            id="categoryRadioInclude"
            value="INCLUDE"
            v-model="picked"
            class="custom-control-input">
          <label class="custom-control-label" for="categoryRadioInclude">
            {{ $t('labels.configurationPanel.notamFilter.include') }}
          </label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input
            type="radio"
            id="categoryRadioExclude"
            value="EXCLUDE"
            v-model="picked"
            class="custom-control-input">
          <label class="custom-control-label" for="categoryRadioExclude">
            {{ $t('labels.configurationPanel.notamFilter.exclude') }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import A from '../../../../constants/actions';
import ImgUtils from '../../../../utils/ImgUtils';

export default {
  name: 'Categories',

  data: () => {
    return {
      newCategory: '',
    }
  },

  computed: {
    heightSize() {
      return (this.$store.state.configurationStore.notamFilterPanelHeight - 180) + 'px';
    },
    categories() {
      return this.$store.state.notamFilterStore.currentFilter.categories;
    },
    picked: {
      get() {
        return this.$store.state.notamFilterStore.currentFilter.catCondition;
      },
      set(value) {
        this.$store.dispatch(A.NOTAMFILTER_UPDATE_PROPERTY, {type: 'catCondition', value: value});
      }
    },
  },
  methods: {
    getButtonImg(name) {
      return ImgUtils.getButtonImg(name, 'svg');
    },
    removeCategory(category) {
      this.$store.dispatch(A.NOTAMFILTER_TOGGLE_CATEGORY, category);
    },
    addCategory() {
      const category = this.newCategory.trim().toUpperCase();
      const existingCategories = this.$store.state.notamFilterStore.currentFilter.categories || [];
      if (category.length > 0 && !existingCategories.includes(category)) {
        this.$store.dispatch(A.NOTAMFILTER_TOGGLE_CATEGORY, category);
      }
      this.newCategory = '';
    },
    getCategoryPlaceholder() {
      return this.$i18n.t('labels.configurationPanel.notamFilter.category') + "...";
    }
  },
}
</script>