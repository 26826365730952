import FeatureType from '../../constants/featureType';
import FeatureUtils from '../../utils/FeatureUtils';
import ThemeElements from '../../constants/theme-elements';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';

export default {
  id: FeatureType.FLIGHT_OBJECT,
  name: 'features.flightObject',
  icon: 'briefing',
  staticDataType: [FeatureType.AIRPORT_HELIPORT],
  isViewPortBased: false,
  isFlightLevelBased: false,
  maxNrOfFeaturesPerLocation: 80,
  getReplaceableMessages: FeatureUtils.Filters.filterById,
  featureIdentifier: 'id',
  maxResolution: 10000,
  declutter: true,
  zIndex: 1,
  flightLevel: null,
  theme: {
    elements: [
      ThemeElements.SHOW_TEXT,
      ThemeElements.BORDER_COLOR,
      ThemeElements.TEXT_COLOR,
      ThemeElements.TEXT_SIZE,
      ThemeElements.TEXT_BG_COLOR,
      ThemeElements.ICON_SIZE
    ]
  },
  displayOnMap: true,
  displayInMessages: true,

  geoJsonMapper: (feature) => {
    return new Feature({
      geometry: new GeoJSON().readGeometry(feature.route),
      id: feature.id,
      groupId: feature.id,
      featureType: 'flightobject',
      aircraftAddress: feature.aircraftAddress,
      departureAirport: feature.departureAerodrome,
      destinationAirport: feature.arrivalAerodrome,
      routeElements: feature.routeElements,
      messageType: feature.messageType,
      text: feature.text
    });
  },

  getMinifiedObject(feature, defaultMinifiedObject) {
    const minifiedObject = {
      ...defaultMinifiedObject,
      ...feature,
      validTime: {
        from: feature.departureTime,
        to: feature.arrivalTime
      }
    };
    const getOriginalMessage = flightObjectText => flightObjectText.match(/\(FPL-[\s\S]+?\)/g)?.[0] || '-missing-';
    minifiedObject.tac = getOriginalMessage(minifiedObject.text);
    return minifiedObject;
  },

  color: (colors) => {
    return colors.textColor;
  },

  defaultStyle: () => {
    return {
      showText: true,
      textSize: 14,
      iconSize: 2,
      colors: {
        borderColor: {r: 255, g: 102, b: 0, a: 1},
        textColor: {r: 255, g: 120, b: 31, a: 1},
        textBackgroundColor: {r: 0, g: 0, b: 0, a: 1}
      }
    }
  }
}