import A from '../../constants/actions';
import M from '../../constants/mutations';

const state = {
  isOpen: false,

  title: '',
  message: '',
  buttons: [],
  component: undefined,
  props: undefined,

};

const actions = {
  [A.DIALOG_SHOW]({commit}, config) {
    commit(M.DIALOG_SHOW, config);
  },

  [A.DIALOG_HIDE]({commit}) {
    commit(M.DIALOG_HIDE);
  },
};

const mutations = {
  [M.DIALOG_SHOW](state, config) {
    state.isOpen = true;
    state.title = config.title;
    state.message = config.message;
    state.buttons = config.buttons;
    state.component = config.component;
    state.props = config.props;
  },

  [M.DIALOG_HIDE](state) {
    state.isOpen = false;
    state.component = undefined;
    state.props = undefined;
  },
};

const getters = {};

export default {
  state,
  actions,
  getters,
  mutations,
};