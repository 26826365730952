<template>
    <label class="smartsis-checkbox" :class="{ disabled: disabled }">
        <span v-if="!labelOnRight">{{label}}</span>
        <input
            type="checkbox"
            :checked="isChecked"
            :disabled="disabled"
            @click="onClick"
        />
        <span class="smartsis-checkbox-control"></span>
        <span v-if="labelOnRight">{{label}}</span>
    </label>
</template>

<script>
  export default {
    name: "SmartSisCheckbox",
    model: {
      prop: 'isChecked',
      event: 'change'
    },
    props: [
      "label",
      "isChecked",
      "disabled",
      "onClickAction",
      "labelOnRight"
    ],
    methods: {
      onClick() {
        if (this.onClickAction) {
          this.onClickAction();
        }
        this.$emit('change', this.isChecked);
      }
    }
  }
</script>

<style src="./smartSisCheckbox.css"/>