import FeatureType from '../../constants/featureType';
import FeatureUtils from '../../utils/FeatureUtils';
import AlertState from '../../constants/alertState';
import OperationPlaneState from '../../constants/operationPlanState';
import ThemeElements from '../../constants/theme-elements';
import Features from "./index";
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import Store from '../../store';
import OperationPlanService from '../../services/operationPlanService';
import A from '../../constants/actions';
import DroneAlertStateConfig from "../droneAlertStateColoringConfig";
import AlertMessageUtils from "../../utils/AlertMessageUtils";
import MapUtils from "@/utils/MapUtils";
import OpGeometryUtils from "@/utils/dronePlans/OpGeometryUtils";

function findRelatedActiveAlerts(messages, maxTimestamp, operationPlanId) {
  return messages.filter(m =>
    m.featureType === FeatureType.DRONE_ALERT &&
    m.operationPlanIds[0] === operationPlanId &&
    m.state === AlertState.ACTIVE &&
    new Date(m.history[m.history.length - 1].time_stamp).getTime() < maxTimestamp
  );
}

function findRelatedResolvedAlerts(messages, minTimestamp, operationPlanId) {
  return messages.filter(m =>
    m.featureType === FeatureType.DRONE_ALERT &&
    m.operationPlanIds[0] === operationPlanId &&
    m.state === AlertState.RESOLVED &&
    new Date(m.history[m.history.length - 1].time_stamp).getTime() > minTimestamp
  );
}

export default {

  id: FeatureType.DRONE_ALERT,
  name: 'features.droneAlert',
  icon: 'alert_message',
  staticDataType: [],
  isViewPortBased: false,
  isFlightLevelBased: true,
  maxNrOfFeaturesPerLocation: 1,
  getReplaceableMessages: FeatureUtils.Filters.filterByFeatureTypeAndId,
  zIndex: 1,
  displayOnMap: true,
  displayInMessages: true,

  tabs: [
    {
      id: FeatureType.DRONE_ALERT,
      featureType: FeatureType.DRONE_ALERT,
      label: 'features.droneAlert',
      icon: 'alert_message',
      topLeftIcon: 'manually_created',
      topLeftIconExtension: 'svg',
      shouldDisplayTopLeftIcon: FeatureUtils.Icons.alertsForManuallyCreatedOperationPlanExist,
      filter: (message) => {
        return !OperationPlanService.isClosedOperationPlan(message.operationPlanIds[0])
      }
    }
  ],

  theme: {
    elements: [
      ThemeElements.SHOW_TEXT,
      ThemeElements.TEXT_COLOR,
      ThemeElements.TEXT_SIZE,
      ThemeElements.TEXT_BG_COLOR,
      ThemeElements.IMAGE_COLOR,
      ThemeElements.ICON_SIZE,
    ]
  },

  color: (colors) => {
    return colors.imageColor;
  },

  defaultStyle: () => {
    return {
      showText: true,
      textSize: 14,
      iconSize: 10,
      colors: {
        borderColor: {r: 143, g: 20, b: 58, a: 1},
        fillColor: {r: 143, g: 20, b: 58, a: 0.3},
        textColor: {r: 143, g: 20, b: 58, a: 1},
        textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
        imageColor: {r: 255, g: 255, b: 255, a: 1},
      }
    }
  },

  geoJsonMapper: (feature) => {
    const associatedOpIsClosed = OperationPlanService.isClosedOperationPlan(feature.operation_plans[0]);
    const operationGeometry = OpGeometryUtils.createAlertGeometryForOperation(feature.operation_plans[0]);
    return new Feature({
      geometry: operationGeometry ? operationGeometry.transform('EPSG:3857', 'EPSG:4326') :
        new GeoJSON().readGeometry(feature.location),
      id: feature.message_id,
      featureType: FeatureType.DRONE_ALERT,
      history: feature.history ? feature.history : [],
      refrainFromMap: associatedOpIsClosed,
      operationPlanIds: feature.operation_plans,
      state: AlertMessageUtils.determineState(feature.history || []),
    });
  },

  getMsgAssociatedOpValidTo(opPlanId) {
    return Store.state.informationStore.messages
      .filter(m => m.featureType.indexOf('operationplan') !== -1)
      .filter(m => opPlanId === m.id)
      .map(m => new Object({to: m.validTime.to, keepUntil: m.validTime.keepUntil}));
  },

  getMinifiedObject(feature, minifiedObject) {
    const validity = this.getMsgAssociatedOpValidTo(feature.operation_plans[0])[0];
    return {
      ...minifiedObject,
      id: feature.message_id,
      operationPlanIds: feature.operation_plans,
      poseIds: feature.poses,
      free_text: feature.free_text ? feature.free_text : '',
      type: feature.type,
      severityType: feature.severity_type,
      history: feature.history || [],
      state: AlertMessageUtils.determineState(feature.history || []),
      source: feature.uss_name,
      validTime: {
        from: new Date(feature.time_sent).getTime(),
        to: validity ? validity.to : null,
        keepUntil: validity ? validity.keepUntil : null
      },
      refrainFromInfoPanel: OperationPlanService.isClosedOperationPlan(feature.operation_plans[0])
    };
  },

  getInformationPanelMessageStyle(msg, style) {
    const color = DroneAlertStateConfig.getDroneAlertStateColor(msg.state);

    style['border-left'] = '7px solid ' + color + ' !important';
    style['padding-left'] = '5px';
    style['border-radius'] = '0.25rem';

    return style;
  },

  getTypeName(messageType) {
    const alertTypes = Features.getFeature(FeatureType.DRONE_ALERT).types;
    let typeName = alertTypes.filter(at => at.value === messageType).map(at => at.name);
    return typeName ? typeName : '';
  },

  ignoreMessage: (alert, messages, Store) => {
    let operationPlans = messages.filter(m => m.featureType.indexOf('operationplan') !== -1);
    const aliveOperationPlanIds = operationPlans.filter((msg) => {
      return (msg.state !== OperationPlaneState.NO_SERVICE.state);
    }).map(msg => msg.id);
    if (alert.state === AlertState.RESOLVED) {
      const alertTimestamp = new Date(alert.history[alert.history.length - 1].time_stamp).getTime();
      const activeAlerts = findRelatedActiveAlerts(messages, alertTimestamp, alert.operationPlanIds[0]);
      if (activeAlerts.length !== 0) {
        Store.dispatch(A.INFORMATION_PANEL_MESSAGE_CLEANUP, activeAlerts);
        Store.dispatch(A.MAP_MESSAGE_CLEANUP, activeAlerts);
      }
    }
    if (alert.state === AlertState.ACTIVE) {
      const alertTimestamp = new Date(alert.history[alert.history.length - 1].time_stamp).getTime();
      const resolvedAlerts = findRelatedResolvedAlerts(messages, alertTimestamp, alert.operationPlanIds[0]);
      if (resolvedAlerts.length !== 0) {
        return true;
      }
    }
    return !alert.operationPlanIds.some(opId => aliveOperationPlanIds.indexOf(opId) !== -1);
  },

  filterMessages: (messages) => {
    let closedOperationPlanIds = messages.filter(m => m.featureType.indexOf('operationplan') !== -1)
      .filter(op => op.state === OperationPlaneState.CLOSED.state).map(m => m.id);
    return messages
      .filter(m => m.featureType === FeatureType.DRONE_ALERT)
      .filter(msg => !closedOperationPlanIds.includes(msg.operationPlanIds[0]));
  },

  groupMessages: (messages) => {
    return AlertMessageUtils.groupDroneAlertsByDronePlanId(messages);
  },

  handleSideEffects(message) {
    MapUtils.refreshOperationPlanLayerSource(message.operationPlanIds[0]);
    MapUtils.refreshEmbeddedMapOpLayerSource(message.operationPlanIds[0]);
  },

}
