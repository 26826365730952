import AppConfig from '../config/appConfig';
import AjaxUtils from '../utils/AjaxUtils';
import PermissionUtils from '../utils/PermissionUtils';
import LoggerUtils from "../utils/LoggerUtils";
import Store from "../store";
import A from "../constants/actions";
import {i18n} from '../internationalization/index';

const getAllThemesServiceURL = AppConfig.server.serviceUrlRoot + '/theme/_all';
const getUserThemesServiceURL = AppConfig.server.serviceUrlRoot + '/theme';
const saveThemeURL = AppConfig.server.serviceUrlRoot + '/theme';
const deleteThemeURL = AppConfig.server.serviceUrlRoot + '/theme';
const defaultThemeURL = AppConfig.server.serviceUrlRoot + '/userDefaultTheme';

function getAllThemes(config) {
  const url = PermissionUtils.isSuperAdmin() ? getAllThemesServiceURL : getUserThemesServiceURL;
  return fetch(AjaxUtils.setParameters(url, [AjaxUtils.getUserNameParam()]), {
    method: 'get',
    headers: AjaxUtils.getHeaders(),
    withCredentials: true,
  }).then(resp => resp.json())
    .then(data => config.onSuccess(data))
    .catch(error => config.onError(error));
}

function getDefaultTheme(config) {
  return fetch(AjaxUtils.setParameters(defaultThemeURL, [
    AjaxUtils.getUserNameParam(),
  ]), {
    method: 'get',
    headers: AjaxUtils.getHeaders(),
  }).then(resp => resp.text())
    .then(data => config.onSuccess(data))
    .catch(() => {
      LoggerUtils.info(i18n.global.t('logMessages.defaultThemeNotFound', {
        username: Store.state.userStore.userProfile.username
      }));
      Store.dispatch(A.THEME_CHANGE_DEFAULT_THEME);
    });
}

function saveDefaultTheme(config) {
  return fetch(AjaxUtils.setParameters(defaultThemeURL, [
    AjaxUtils.getParamObjectFor('themeId', config.themeId),
    AjaxUtils.getUserNameParam()
  ]), {
    method: 'put',
    headers: AjaxUtils.getHeaders(),
  })
    .then(response => {
      if (response.status !== 200 && response.status !== 201) {
        response.text().then(data => {
          config.errorCallback(data);
        });
      } else {
        response.text().then(data => {
          config.successCallback(data);
        });
      }
    })
    .catch(error => config.errorCallback(error));
}

function saveTheme(config) {
  return fetch(
    AjaxUtils.setParameters(saveThemeURL,
      [
        AjaxUtils.getUserNameParam(),
      ]),
    {
      method: 'put',
      headers: AjaxUtils.getHeaders(),
      body: JSON.stringify(config.theme),
    })
    .then(response => {
      if (response.status !== 200 && response.status !== 201) {
        response.text().then(data => {
          config.errorCallback(data);
        });
      } else {
        response.text().then(data => {
          config.successCallback(data);
        });
      }
    })
    .catch(error => config.errorCallback(error));
}

function deleteTheme(config) {
  return fetch(
    AjaxUtils.setParameters(deleteThemeURL, [
      AjaxUtils.getParamObjectFor('themeId', config.themeId),
      AjaxUtils.getUserNameParam(),
    ]), {
      method: 'delete',
      headers: AjaxUtils.getHeaders()
    })
    .then(data => {
      if (!data.ok) {
        config.errorCallback(data);
      } else {
        config.successCallback(data);
      }
    })
    .catch(error => config.errorCallback(error));
}

export default {
  getAllThemes,
  getDefaultTheme,
  saveTheme,
  saveDefaultTheme,
  deleteTheme,
}