import Store from "../../store";
import OpGeometryUtils from "../../utils/dronePlans/OpGeometryUtils";
import {Icon, Style} from "ol/style";
import AlertState from "../../constants/alertState";
import DronePlanUtils from "../../utils/dronePlans/DronePlanUtils";
import AlertMessageUtils from "@/utils/AlertMessageUtils";

export default class DroneAlertStyleFunction {

  static buildDroneAlertStyleFunction(stylingConfig) {
    return function (feature) {
      return shouldDisplayResolvedMessageIcon(feature) && shouldDisplayAssociatedPlan(feature)
        ? constructDroneAlertStyle(stylingConfig, feature) : [];
    };
  }
}

function shouldDisplayResolvedMessageIcon(feature) {
  return Store.getters.getDisplayOfResolvedMessage || !isAlertAcknowledgedOrResolved(feature.getProperties());
}

function shouldDisplayAssociatedPlan(feature) {
  const associatedOp = Store.getters.getOperationPlanById(feature.getProperties().operationPlanIds[0]);
  const startTime = new Date(associatedOp.validity.validFrom).getTime();
  return DronePlanUtils.isUpcomingDronePlan(associatedOp)
    ? Store.getters.getDisplayOfUpcomingPlansOnMap() && DronePlanUtils.isOutlookOpInFilteringTimeInterval(startTime)
    : true;
}

function isAlertAcknowledgedOrResolved(alert) {
  return alert.state === AlertState.RESOLVED || alert.state === AlertState.ACKNOWLEDGED;
}

function constructDroneAlertStyle(stylingConfig, feature) {
  const operationPlanGeometry =
    OpGeometryUtils.createAlertGeometryForOperation(feature.getProperties().operationPlanIds[0]);
  const alertIconPath = AlertMessageUtils.getAlertIcon(feature.getProperties().operationPlanIds[0], 'png');
  const style = new Style({
    image: new Icon({
      src: alertIconPath,
      scale: stylingConfig.iconSize / 150,
      rotation: 0
    }),
    geometry: operationPlanGeometry.getInteriorPoint() || feature.getGeometry(),
    zIndex: 30
  });
  return [style];
}