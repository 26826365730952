import Store from '../store';

export default class ImgUtils {

  static getButtonImg(name, extension) {
    extension = "." + (extension === undefined ? 'png' : extension);
    return 'icons/' + ((Store.getters.getIsDayThemeSelected) ? name : name + '_white') + extension;
  }

  static getButtonImage(name, extension) {
    extension = "." + (extension === undefined ? 'png' : extension);
    return 'icons/' + name  + extension;
  }

}