import MapUtils from "../../utils/MapUtils";
import FeatureType from "../../constants/featureType";
import Store from "../../store";
import A from "../../constants/actions";
import AircraftFeature from "../../config/features/aircraft";
import {transform} from "ol/proj";
import TimeService from "../../services/timeService";
import AppConfig from '../../config/appConfig';
import ModelUtils from "../../utils/ModelUtils";


export default class AircraftMessageProcessor {

  static aircraftLayerSource = null;

  static process(payload) {
    if (TimeService.timeOffsetInMilliseconds() === 0) {
      this.#initializeAircraftLayer();
      this.processAircraftFeature(payload);
    }
  }

  static processAircraftFeature(payload) {
    const records = JSON.parse(payload);
    records.forEach(record => {
      if (this.isMatchingAircraftAltitude(record)) {
        this.addOrUpdateAircraftOnMap(record);
      } else {
        Store.dispatch(A.MAP_MESSAGE_CLEANUP, [record]);
      }
    });
  }

  static updateExistingAircraftProperties(feature, record) {
    const end = transform(record.p, 'EPSG:4326', 'EPSG:3857');
    feature.getGeometry().setCoordinates(end);
    feature.setProperties(
      {
        validTime: {
          to: TimeService.currentTimeSliderTime().valueOf() + AppConfig.aircraftValidity * 1000,
        },
        altitude: record.a,
        acquisitionDatetime: record.acquisitionDatetime
      },
      true
    );
  }

  static #initializeAircraftLayer() {
    if (this.aircraftLayerSource === null) {
      this.aircraftLayerSource = MapUtils.getMapLayerSourceById(FeatureType.AIRCRAFT);
    }
  }

  static isMatchingAircraftAltitude(aircraft) {
    if (Store.getters.getAltitudeFilteredFeatures.includes(FeatureType.AIRCRAFT)) {
      return ModelUtils.isAltitudeBetweenMinAndMaxFilteringAltitude(aircraft.a);
    }
    return true;
  }

  static addOrUpdateAircraftOnMap(record) {
    const feature = this.aircraftLayerSource.getFeatureById(record.id);
    if (feature) {
      if (Date.parse(feature.getProperties().acquisitionDatetime) <= Date.parse(record.acquisitionDatetime)) {
        this.updateExistingAircraftProperties(feature, record);
      }
    } else {
      Store.dispatch(A.MAP_ADD_MESSAGE, AircraftFeature.geoJsonMapper(record));
    }
  }
}