import A from '../constants/actions';
import CoordinateUtils from './CoordinateUtils';
import LayerUtils from './LayerUtils';
import Store from '../store';
import HighlightedFeatureService from '../services/highlightedFeatureService'
import FeatureSelectionConfig from '../config/map/featureSelectionConfig';
import {transform} from 'ol/proj';
import {toStringHDMS} from 'ol/coordinate';
import HudTabName from '../constants/hudTabName';
import Features from "../config/features";
import FeatureType from "../constants/featureType";
import StyleFunctionService from "../styleFunction/styleFunctionService";
import {Cluster, Vector as VectorSource} from "ol/source";
import OpGeometryUtils from "./dronePlans/OpGeometryUtils";
import Point from "ol/geom/Point";
import {getCenter} from "ol/extent";
import VectorLayer from "ol/layer/Vector";

export default class MapUtils {

  static updateHUDCoordinates(evt) {
    if (evt.dragging) {
      return;
    }

    const coordinate = transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326');
    const coordinateDMS = toStringHDMS(coordinate);
    Store.dispatch(A.HUD_SET_COORDINATE, CoordinateUtils.normalize(coordinateDMS));
  }

  static blurSearchInputBox() {
    const searchInput = document.getElementById('searchInput');
    if (searchInput) searchInput.blur();
  }

  static updateSnapshot() {
    // optimisation, no need for snapshot, as config panel is closed
    let canvasList = document.getElementsByTagName('canvas');
    let canvas = canvasList[canvasList.length - 1];
    if (!Store.getters.isGivenTabOpened(HudTabName.CONFIGURATION)) {
      return;
    }
    // loading.gif
    const loadingGif = 'data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH+GkNyZWF0ZWQgd2l0aCBhamF4bG9hZC5pbmZvACH5BAAKAAAAIf8LTkVUU0NBUEUyLjADAQAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQACgABACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkEAAoAAgAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkEAAoAAwAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkEAAoABAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQACgAFACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQACgAGACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAAKAAcALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==';
    Store.dispatch(A.MAP_VIEWPORT_SNAPSHOT, loadingGif);

    setTimeout(function () {
      Store.dispatch(A.MAP_VIEWPORT_SNAPSHOT, canvas.toDataURL('image/png', 0.1));
    }, 1000);
  }

  static selectClickedFeature(evt, overlay) {
    if (Store.getters.isFormWrapperOpened || Store.getters.getInteractionHandler) {
      return;
    }
    overlay.setPosition(undefined);
    const messagesOnPosition = getMessagesFromPosition(evt);
    const messagesToBeDisplayed = messagesOnPosition
      .map(feature => feature.getProperties())
      .filter(feature => FeatureSelectionConfig.findConfigForFeatureType(feature.featureType));
    const uniqueMessagesToBeDisplayed = messagesToBeDisplayed
      .filter((feature, index) => filterOutDuplicates(feature, index, messagesToBeDisplayed));
    if (uniqueMessagesToBeDisplayed.length > 1) {
      this.displayFeatureSelectionPopup(evt, overlay, uniqueMessagesToBeDisplayed)
    } else {
      this.selectClickedFeatureInInfoPanel(evt, messagesOnPosition);
    }
  }

  static selectClickedFeatureInInfoPanel(evt, messagesOnPosition) {
    const clickPriorities = LayerUtils.getClickPriorities();
    const clickedFeatures = messagesOnPosition
      .map(feature => mapSelectedFeatureWithClickPriority(feature, clickPriorities))
      .filter(feature => clickPriorities[feature.featureType]);

    clickedFeatures.sort((f1, f2) => (f1.priority === f2.priority) ? 0 : (f1.priority > f2.priority) ? 1 : -1);
    if (clickedFeatures.length > 0) {
      HighlightedFeatureService.selectFeature(clickedFeatures[0]);
    } else {
      Store.dispatch(A.REMOVE_SELECT_FEATURE);
    }
    Store.dispatch(A.MAP_REFRESH);
  }

  static displayFeatureSelectionPopup(evt, overlay, clickedFeatures) {
    Store.dispatch(A.MAP_SET_CLICKED_FEATURES, clickedFeatures);
    const coordinate = evt.coordinate;
    overlay.setPosition(coordinate);
  }

  static getMapLayerSourceById(id) {
    const layer = this.getMapLayer(id);
    return this.getSourceOfLayer(layer);
  }

  static getMapLayer(id) {
    return Store.getters.getAllMapLayers.find(layer => layer.get('id') === id);
  }

  static refreshOperationPlanLayerSource(id) {
    refreshOpLayerForMessage(id, Store.getters.getAllMapLayers);
  }

  static refreshEmbeddedMapOpLayerSource(id) {
    refreshOpLayerForMessage(id, Store.getters.getEmbeddedMapLayers);
  }

  static removeMessageLayersFromMap(mapLayers, message) {
    const layer = mapLayers.find(layer => layer.get('id') === message.featureType);
    const layerSource = layer.getSource().getSource ? layer.getSource().getSource() : layer.getSource();
    if (LayerUtils.isLayerWithMultipleVolumes(message.featureType)) {
      const featuresToDelete = layerSource.getFeatures()
        .filter(feat => feat.getProperties().associatedFeatureId === message.id);
      featuresToDelete.forEach(feature => layerSource.removeFeature(feature));
    } else {
      const featureToDelete = layerSource.getFeatureById(message.id);
      if (featureToDelete) {
        layerSource.removeFeature(featureToDelete);
      }
    }
  }

  static removeAssociatedMessageLayersFromMap(mapLayers, message) {
    let associatedFeatureLayerConfigs = this.getLayerConfigForFeatureType(message.featureType);
    if (associatedFeatureLayerConfigs) {
      associatedFeatureLayerConfigs.forEach(associatedLayerConfig => {
        const layer = mapLayers.find(layer => layer.get('id') === associatedLayerConfig.id);
        const featuresToDelete = layer.getSource().getFeatures()
          .filter(feat => associatedLayerConfig.getAssociatedFeatureId(feat) === message.id);
        featuresToDelete.forEach(feat => layer.getSource().removeFeature(feat));
      })
    }
  }

  static getLayerConfigForFeatureType(featureType) {
    let associatedFeatureLayerConfigs = Features.getFeature(featureType).associatedMapOnlyFeatureLayerConfig;
    const availableFeatureIds = Features.getAvailableFeatures().map(feature => feature.id);
    return associatedFeatureLayerConfigs ?
      associatedFeatureLayerConfigs.filter(layerConfig => availableFeatureIds.includes(layerConfig.id)) : [];
  }

  static getViewGeometries() {
    const respAreaLayerGeometries = getMapLayerGeometries(FeatureType.RESPONSIBILITY_AREA) || [];
    const airspaceLayerGeometries = getMapLayerGeometries(FeatureType.AIRSPACE) || [];
    return [...respAreaLayerGeometries, ...airspaceLayerGeometries];
  }

  static constructClusteredLayer(feature) {
    const stylingConfig = Store.getters.getStyleConfigForLayer(feature.id);
    const styleFunction = StyleFunctionService.createStyleFunction(stylingConfig);
    return this.constructClusteredLayerWithStyle(feature, styleFunction);
  }

  static constructClusteredLayerWithStyle(feature, styleFunction) {
    const clusterSource = new Cluster({
      distance: 10,
      source: new VectorSource({}),
      geometryFunction: (feature) => {
        const visibleGeometry = OpGeometryUtils.getPolygonIntersectingViewportWithGeometry(feature.getProperties().geometry);
        const featureGeometry = visibleGeometry ? visibleGeometry : feature.getProperties().geometry;
        return new Point(getCenter(featureGeometry.getExtent()));
      }
    });
    const vectorLayer = new VectorLayer({
      source: clusterSource,
      style: (feature, resolution) => {
        return styleFunction(feature, resolution);
      },
    });
    vectorLayer.set('id', feature.id);
    vectorLayer.setZIndex(feature.zIndex);
    return vectorLayer;
  }

  static getSourceOfLayer(layer) {
    return layer instanceof VectorLayer ? layer.getSource()?.getSource() : layer?.getSource();
  }
}

function refreshOpLayerForMessage(opId, map) {
  const operationPlan = Store.getters.getOperationPlanById(opId);
  if (operationPlan) {
    const opMapLayerSource = map?.find(layer => layer.get('id') === operationPlan?.featureType)?.getSource().getSource();
    opMapLayerSource?.changed();
  }
}

function getMessagesFromPosition(evt) {
  const allFeatures = evt.map.getFeaturesAtPixel(evt.pixel)
    .map(feature => feature.getProperties().features ? feature.getProperties().features : feature);
  return allFeatures.flat();
}

function mapSelectedFeatureWithClickPriority(feature, clickPriorities) {
  const props = feature.getProperties();
  return {
    id: props.id,
    featureType: props.featureType,
    priority: clickPriorities[props.featureType],
    color: props.color,
    associatedFeatureId: props.associatedFeatureId
  };
}

function filterOutDuplicates(feature, index, messages) {
  const featurePositionInList = messages
    .map(msg => msg.associatedFeatureId || msg.id)
    .findIndex(id => id === (feature.associatedFeatureId || feature.id));
  return featurePositionInList === -1 || featurePositionInList === index;
}

function getMapLayerGeometries(layerId) {
  return MapUtils.getMapLayerSourceById(layerId)?.getFeatures()
    .map(feature => feature.getGeometry().clone().transform('EPSG:3857', 'EPSG:4326'));
}