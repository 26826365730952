<template>
  <div>
    <div class="thresholdTitle">{{ this.name }} [{{ this.unit }}]</div>
    <div class="rangeThreshold">
      <div class="thresholdColor thresholdGreen">
        <div class="minValue">&nbsp;</div>
      </div>
      <div class="thresholdColor thresholdYellow">
        <input
          class="listInput text-uppercase form-control thresholdYellowInput"
          type="text"
          @keyup="this.onChangeFirst"
          @change="this.onChangeFirst"
          :value="getFirstThreshold">
      </div>
      <div class="thresholdColor thresholdRed">
        <input
          class="listInput text-uppercase form-control thresholdRedInput"
          type="text"
          @keyup="this.onChangeSecond"
          @change="this.onChangeSecond"
          :value="getSecondThreshold">
      </div>
      <div class="clear"></div>
    </div>
  </div>
</template>
<script>

import A from '../../../../constants/actions';

export default {
  name: 'ListThresholdItem',
  components: {},
  props: ['name', 'unit', 'threshold'],
  computed: {
    getFirstThreshold() {
      return this.threshold[0].value;
    },
    getSecondThreshold() {
      return this.threshold[1].value;
    },
  },
  methods: {
    onChangeFirst(evt) {
      this.onChangeThreshold(evt, 0);
    },
    onChangeSecond(evt) {
      this.onChangeThreshold(evt, 1);
    },
    onChangeThreshold(evt, index) {
      const value = evt.target.value.toUpperCase();
      this.$store.dispatch(A.THRESHOLD_UPDATE_VALUE, {
        propName: this.name,
        thresholdIndex: index,
        thresholdValue: value,
      });
    }
  }

};
</script>
