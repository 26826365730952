import A from '../constants/actions';
import Store from '../store';
import Features from '../config/features';
import FeatureUtils from '../utils/FeatureUtils';
import MessageProcessorService from '../services/messageProcessorService';

function processMetData(metData) {

  let allFeatures = [];
  metData.forEach(md => {
    const features = MessageProcessorService.processESResponse(JSON.parse(md));
    MessageProcessorService.processFeatures(features, {src: 'pull'});

    allFeatures = [...allFeatures, ...features];
  });
  return allFeatures;
}

function processStaticData(staticData) {
  staticData.forEach(md => {
    let esResponse = JSON.parse(md);
    let features = [];
    if (esResponse.hits && esResponse.hits.hits) {
      const mappedFeatures = esResponse.hits.hits.map(hit => {
        let f = hit._source;
        f.id = hit._id;
        f.featureType = hit._type;
        return f;
      });
      features = [...features, ...mappedFeatures];
    }
    if (esResponse.aggregations && esResponse.aggregations.group && esResponse.aggregations.group.buckets) {
      esResponse.aggregations.group.buckets.forEach((bucket) => {
        const mappedFeatures = (bucket.group_docs) ? bucket.group_docs.hits.hits.map(hit => {
          let f = hit._source;
          f.id = hit._id;
          f.featureType = hit._type;
          return f;
        }) : [];
        features = [...features, ...mappedFeatures];
      });
    }

    features.forEach(f => {
      const feature = Features.getFeature(FeatureUtils.Mapping.getFeatureType(f));
      if (!feature) return;

      const featureMapping = FeatureUtils.Mapping.mapFeatureToGeoJson(f, feature);
      const minifiedMsg = (feature.isStaticDataType || feature.displayInMessages) ? FeatureUtils.Mapping.getMinifiedObject(f) : null;

      if (feature.isStaticDataType) {
        Store.dispatch(A.STATIC_DATA_RECEIVED, minifiedMsg);
      }
      (Array.isArray(featureMapping) ? featureMapping : [featureMapping]).forEach(featureMap => Store.dispatch(A.MAP_ADD_MESSAGE, featureMap));
    });
  });
}

export default {
  processMetData: processMetData,
  processStaticData: processStaticData
}