import FeatureType from '../constants/featureType';
import FormMapLayersStyleUtils from "../utils/FormMapLayersStyleUtils";

const CONFIG_IDS = {
  OPERATION_PLAN_VOLUMES: 'formOperationPlanVolumes',
  OPERATION_PLAN_WAYPOINTS: 'formOperationPlanWaypoints',
};

const DOTTED_WAYPOINTS = 'dottedWaypoints';
const GEOZONE_COORDINATES = 'geozoneCoordinates';

const CONFIGS = [
  {
    id: CONFIG_IDS.OPERATION_PLAN_VOLUMES,
    stylingConfigFeatureType: FeatureType.OPERATION_PLAN_PROPOSED,
    zIndex: 1001,
    getStyleFunction: FormMapLayersStyleUtils.buildOperationVolumeStyleFunction,
  },
  {
    id: CONFIG_IDS.OPERATION_PLAN_WAYPOINTS,
    stylingConfigFeatureType: FeatureType.OPERATION_PLAN_PROPOSED,
    zIndex: 1001,
    getStyleFunction: FormMapLayersStyleUtils.buildOperationWaypointsStyleFunction
  },
  {
    id: DOTTED_WAYPOINTS,
    stylingConfigFeatureType: FeatureType.OPERATION_PLAN_PROPOSED,
    zIndex: 1001,
    getStyleFunction: FormMapLayersStyleUtils.buildOperationWaypointsDottedStyleFunction
  },
  {
    id: GEOZONE_COORDINATES,
    stylingConfigFeatureType: FeatureType.ED269,
    zIndex: 1001,
    getStyleFunction: FormMapLayersStyleUtils.buildGeozoneCoordinatesStyleFunction
  }
];

function getConfigById(id) {
  return CONFIGS.find(config => config.id === id);
}

export default {
  CONFIG_IDS,
  CONFIGS,
  DOTTED_WAYPOINTS,
  GEOZONE_COORDINATES,
  getConfigById,
};
