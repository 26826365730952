<template>
  <div class="row m-0">
    <ListPanel
      :entries="filters"
      :idPrefix="'filter'"
      :entryStyle="getButtonStyle"
      :onSelectEntry="selectFilter"
      class="pt-2 px-0"
    >
    </ListPanel>
    <NotamFilterEditor></NotamFilterEditor>
  </div>
</template>

<script>
import A from '../../../constants/actions';
import ListPanel from '../list-panel/ListPanel.vue';
import PermissionUtils from '../../../utils/PermissionUtils';
import NotamFilterEditor from './editor/NotamFilterEditor.vue';
import DialogUtils from '../../../utils/DialogUtils';

export default {
  name: 'NotamFilterConfiguration',
  components: {
    ListPanel,
    NotamFilterEditor,
  },
  computed: {
    filters() {
      return PermissionUtils.getAvailableNotamFilters();
    }
  },
  methods: {
    selectFilter(filter) {
      const currentFilter = this.$store.state.notamFilterStore.currentFilter;
      const originalFilter = this.$store.state.notamFilterStore.originalFilter;
      if (JSON.stringify(currentFilter) !== JSON.stringify(originalFilter)) {
        DialogUtils.confirmOperation(
          {
            message: this.$i18n.t('confirmationMessages.notamFilterModified'),
            yesAction: () => this.$store.dispatch(A.NOTAMFILTER_SELECT_NOTAMFILTER, filter.id),
          }
        );
      } else {
        this.$store.dispatch(A.NOTAMFILTER_SELECT_NOTAMFILTER, filter.id);
      }
    },
    getButtonStyle(filter) {
      return [
        'btn',
        'btn-primary',
        'w-100',
        'm-1',
        'listButton',
        (this.$store.state.notamFilterStore.currentFilter.id === filter.id) ? 'selected' : '',
      ].join(' ');
    }
  }

}
</script>