<template>
  <div>
    <div class="card smartSISPanel" id="flightLevel">
      <h5 class="card-header">{{ $t('labels.configurationPanel.notamFilter.flightLevel') }}</h5>
      <div class="form-group row ml-1">
        <div class="col">
          <strong>{{ $t('labels.common.min') }}</strong>
          <PropertyInput :title="$t('labels.configurationPanel.notamFilter.lower')" :type="'minFl.lower'"
                         :cssClass="'range'"/>
          <PropertyInput :title="$t('labels.configurationPanel.notamFilter.upper')" :type="'minFl.upper'"
                         :cssClass="'range'"/>
        </div>
        <div class="col">
          <strong>{{ $t('labels.common.max') }}</strong>
          <PropertyInput :title="$t('labels.configurationPanel.notamFilter.lower')" :type="'maxFl.lower'"
                         :cssClass="'range'"/>
          <PropertyInput :title="$t('labels.configurationPanel.notamFilter.upper')" :type="'maxFl.upper'"
                         :cssClass="'range'"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PropertyInput from './PropertyInput.vue';

export default {
  name: 'FlightLevel',

  components: {
    PropertyInput,
  }

}
</script>