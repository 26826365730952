import FeatureType from '../../constants/featureType';
import ThemeElements from '../../constants/theme-elements';
import FeatureUtils from '../../utils/FeatureUtils';
import Features from '../../config/features';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import TimeService from "../../services/timeService";
import ModelUtils from '../../utils/ModelUtils';
import DroneInfoUtils from "../../utils/DroneInfoUtils";
import CoordinateUtils from "../../utils/CoordinateUtils";
import DroneState from "../../constants/droneState";
import UomConfig from "../uomConfig";
import UomUtils from '../../utils/UomUtils';
import DroneStateColoringConfig from "../droneStateColoringConfig";
import Store from '../../store';
import AppConfig from '../appConfig';
import {i18n} from '../../internationalization/index';

export default {

  id: FeatureType.DRONES,
  name: 'features.drones',
  abbreviation: 'features.drones',
  icon: 'drone',
  iconExtension: 'png',
  isViewPortBased: false,
  isFlightLevelBased: true,
  isNotificationsDisabled: true,
  maxNrOfFeaturesPerLocation: 100,
  getReplaceableMessages: FeatureUtils.Filters.filterDronesByTrackingId,
  displayWithPagination: true,
  featureIdentifier: 'id',
  maxResolution: 10000,
  declutter: false,
  zIndex: 1,
  flightLevel: null,
  theme: {
    elements: [
      ThemeElements.SHOW_TEXT,
      ThemeElements.TEXT_COLOR,
      ThemeElements.TEXT_SIZE,
      ThemeElements.TEXT_BG_COLOR,
      ThemeElements.IMAGE_COLOR,
      ThemeElements.ICON_SIZE,
    ]
  },

  displayOnMap: true,
  displayInMessages: true,

  geoJsonMapper(feature) {
    const droneFeature = Features.getFeature(FeatureType.DRONES);
    if (droneFeature.showOnlyCritical && feature.state === DroneState.CONFORMANT) {
      return null;
    }
    return new Feature({
      geometry: new Point([feature.p[0], feature.p[1]]).transform('EPSG:4326', 'EPSG:3857'),
      id: feature.trackingId,
      flightId: feature.flightId,
      registration: feature.registration,
      trackingId: feature.trackingId,
      altitude: feature.a,
      trackAngle: feature.d,
      providerId: feature.providerId,
      maxResolution: 10000,
      featureType: FeatureType.DRONES,
      associatedTab: this.getAssociatedTab(),
      refrainFromMap: feature.refrainFromMap,
      acquisitionDatetime: feature.acquisitionDatetime,
      lineHistoryGeometry: CoordinateUtils.constructDroneHistoryGeometryForLineString(feature),
      pointHistoryGeometry: CoordinateUtils.constructDroneHistoryGeometry(feature),
      color: feature.color,
      mapLabel: this.buildMapLabel(feature.registration, feature.a),
      validTime: {
        to: TimeService.currentTimeSliderTime().valueOf() + AppConfig.droneValidity * 1000,
      },
      state: feature.state,
      selected: false
    });
  },

  color: (colors) => {
    return colors.imageColor;
  },

  defaultStyle: () => {
    return {
      showText: true,
      textSize: 14,
      iconSize: 30,
      colors: {
        textColor: {r: 255, g: 255, b: 255, a: 1},
        textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
        imageColor: {r: 218, g: 204, b: 32, a: 0.66}
      }
    }
  },

  getMinifiedObject(feature, minifiedObject) {
    return {
      ...minifiedObject,
      associatedTab: this.getAssociatedTab(),
      id: feature.trackingId,
      altitude: feature.a,
      flightId: feature.flightId,
      registration: feature.registration,
      trackingId: feature.trackingId,
      providerId: feature.providerId,
      color: feature.color,
      textColor: feature.textColor,
      state: feature.state,
      acquisitionDatetime: feature.acquisitionDatetime,
      droneInfo: feature.droneInfo
    }
  },

  getInformationPanelMessageStyle(msg, style) {
    const droneStyleConfig = Store.state.themeStore.currentTheme.stylingConfigs.find(s => s.layer === FeatureType.DRONES);
    const color = DroneStateColoringConfig.getDroneIconColorByState(msg.state, droneStyleConfig);
    style['border-left'] = '7px solid ' + color + ' !important';
    return style;
  },

  sortInfoPanelMessageList(a, b) {
    return ModelUtils.sort(b.registration, a.registration);
  },

  getDroneInformation(msg) {
    const pilotNme = DroneInfoUtils.constructPilotName(msg.droneInfo);
    const pilotContact = DroneInfoUtils.constructContact(msg.droneInfo.pilotContactDetails).replace(/\n/g, '<br />');
    const operatorContact = DroneInfoUtils.constructContact(msg.droneInfo.operatorContactDetails).replace(/\n/g, '<br />');
    return `
      ${i18n.global.t('labels.drone.pilotName')}: ${pilotNme}<br/>
      ${i18n.global.t('labels.drone.pilotContact')}: ${pilotContact}<br />
      ${i18n.global.t('labels.drone.operatorName')}: ${msg.droneInfo.operatorContactDetails?.name ? msg.droneInfo.operatorContactDetails?.name : ''}<br />
      ${i18n.global.t('labels.drone.operatorContact')}: ${operatorContact}<br />
      `;
  },

  sortFeaturesList(a, b) {
    return ModelUtils.sort(a.registration, b.registration);
  },

  listViewDefaultSort(a, b) {
    return ModelUtils.sort(a.trackingId, b.trackingId);
  },

  buildMapLabel(droneRegistration, droneAltitude) {
    const displayedRegistration = droneRegistration || '';
    const convertedAltitude = UomUtils.convertValueToCurrentUom(droneAltitude, UomConfig.UOM.FEET);
    const altitudeToDisplay = ModelUtils.roundToNearestInteger(convertedAltitude);
    const currentlySelectedUomDisplayValue = UomConfig.getCurrentlyConfiguredUomDisplayValue();
    return displayedRegistration + '\n' + altitudeToDisplay + ' ' + currentlySelectedUomDisplayValue;
  },

  getAssociatedTab() {
    const productTypes = Store.state.viewStore.currentView.productTypes;
    return productTypes.includes(FeatureType.DRONE_FLIGHT) ? FeatureType.DRONE_FLIGHT_UNIDENTIFIED : FeatureType.DRONES;
  }
}