import AppConfig from '../../config/appConfig';
import Store from '../../store';
import OperationPlanState from "../../constants/operationPlanState";
import UomConfig from "../../config/uomConfig";
import Moment from "moment";
import FeatureUtils from "../FeatureUtils";
import PermissionUtils from "../PermissionUtils";
import FeatureType from "../../constants/featureType";
import TimeService from "../../services/timeService";
import OperationPlanServices from "../../services/operationPlanService";
import UserRolePriorities from "./UserRolePriorities";
import ModelUtils from "../ModelUtils";
import UomUtils from "../UomUtils";

export default class DronePlanUtils {

  static getPublicTitleForFlightId(flightId) {
    const dronePlans = this.getDronePlanByFlightId(flightId);
    return dronePlans.length > 0 ? dronePlans[0].publicTitle || '' : '';
  }

  static getPublicTitleForPopUp(dronePlan) {
    const title = dronePlan.publicTitle;
    return title && title.length > 25 ? title.substring(0, 25) + '...' : title;
  }

  static getDronePlanByFlightId(flightId) {
    return Store.state.informationStore.messages
      .filter(msg => msg.featureType.indexOf('operationplan') !== -1 && msg.id === flightId);
  }

  static checkIfVolumeExists(op) {
    return op.volumes && op.volumes.length > 0;
  }

  static verifyIfCoordinatesExists(waypoint) {
    return waypoint?.longitude !== undefined &&
      waypoint.latitude !== undefined &&
      waypoint.altitude !== undefined &&
      waypoint.time !== undefined;
  }

  static findFirstWaypointIndexWithIncorrectTime(trajectoryElements) {
    return trajectoryElements.findIndex((currentWaypoint, index) => {
      return index > 0 ? currentWaypoint.time <= trajectoryElements[index - 1]?.time : false
    });
  }

  static currentUserHasRightsForLocalApproval(operationPlan, userProviderId) {
    if (operationPlan.state === OperationPlanState.PROPOSED.state) {
      return DronePlanUtils.hasUserLocalApprovalRights(operationPlan, userProviderId) &&
        !DronePlanUtils.hasUserAlreadyApprovedTheAuthorizationForOp(operationPlan, userProviderId);
    }
    if (operationPlan.state === OperationPlanState.TAKEOFFREQUESTED.state) {
      return DronePlanUtils.hasUserLocalClearanceRights(operationPlan, userProviderId) &&
        !DronePlanUtils.hasUserAlreadyApprovedTheActivationForOp(operationPlan, userProviderId);
    }
    return false;
  }

  static hasUserLocalClearanceRights(operationPlan, userProviderId) {
    return operationPlan.localTakeoffClearanceResults ? operationPlan.localTakeoffClearanceResults
      .some(result => isResultForLocalClearanceRights(result, userProviderId)) : false;
  }

  static getUserLocalClearanceRight(operationPlan, userProviderId) {
    return operationPlan.localTakeoffClearanceResults ? operationPlan.localTakeoffClearanceResults
      .filter(result => isResultForLocalClearanceRights(result, userProviderId))[0] : null;
  }

  static hasUserLocalApprovalRights(operationPlan, userProviderId) {
    return operationPlan.localApprovalResults ? operationPlan.localApprovalResults
      .some(result => isResultForLocalApprovalRights(result, userProviderId)) : false;
  }

  static getUserLocalApprovalRight(operationPlan, userProviderId) {
    return operationPlan.localApprovalResults ? operationPlan.localApprovalResults
      .filter(result => isResultForLocalApprovalRights(result, userProviderId))[0] : null;
  }

  static hasUserAlreadyApprovedTheAuthorizationForOp(operationPlan, userProviderId) {
    return operationPlan.localApprovalResults
      .some(result => result.authorityId === userProviderId && result.state !== 'PENDING');
  }

  static hasUserAlreadyApprovedTheActivationForOp(operationPlan, userProviderId) {
    return operationPlan.localTakeoffClearanceResults
      .some(result => result.authorityId === userProviderId && result.state !== 'PENDING');
  }

  static constructVolumeText(volume) {
    const startTime = Moment.utc(volume.startTime).format('HH:mm');
    const endTime = Moment.utc(volume.endTime).format('HH:mm');
    return startTime + '-' + endTime + '\n' +
      constructOperationPlanAltitudeText(volume);
  }

  static getMaxAltitude(op) {
    return Math.max.apply(Math, op.volumes.map(v =>
      UomUtils.convertValueToCurrentUom(v.maxAltitude, UomConfig.FEATURE_UOM_TO_INTERNAL_UOM_ID[v.unitOfMeasure])));
  }

  static isUpcomingDronePlan(dronePlan) {
    return dronePlan.featureType === FeatureType.OPERATION_PLAN_OUTLOOK;
  }

  static isDronePlanInApprovedState(dronePlan) {
    return dronePlan ? FeatureUtils.State.getOperationState(dronePlan.state, dronePlan.stateAttributeType) ===
      OperationPlanState.AUTHORIZED.state : false;
  }

  static isTakeOffRequestDisabledForDronePlan(dronePlan) {
    return !this.isDronePlanInApprovedState(dronePlan) || TimeService.timeOffsetInMilliseconds() !== 0 ||
      !hasCurrentUserRightsForTakeoffRequest(dronePlan) || PermissionUtils.isReadOnlyUser() ||
      this.isUpcomingDronePlan(dronePlan) || isDronePlanTimeBeforeActivationTimeWithThreshold(dronePlan);
  }

  static isCloseOpButtonDisabledForDronePlan(dronePlan) {
    return dronePlan ?
      (isNotRejectableOrClose(dronePlan) || UserRolePriorities.userHasNoRightsForRejectOrClose(dronePlan)) ||
      PermissionUtils.isReadOnlyUser() : true;
  }

  static isOperationPlanInProposedState(message) {
    return message.state === OperationPlanState.PROPOSED.state;
  }

  static isOperationPlanInTakeoffRequestedState(message) {
    return message.state === OperationPlanState.TAKEOFFREQUESTED.state;
  }

  static isOperationPlanClosed(message) {
    return message.state === OperationPlanState.CLOSED.state;
  }

  static isOperationPlanActivated(message) {
    return message.state === OperationPlanState.ACTIVATED.state;
  }

  static isDronePlanWaitingApprovalRequest(message) {
    return FeatureUtils.Icons.isOperationPlan(message) && this.isOperationPlanInProposedState(message) &&
      hasUserLocalApprovalRightsAndOpWasNotApproved(message, AppConfig.providerId);
  }

  static isDronePlanWaitingTakeoffClearanceRequest(message) {
    return FeatureUtils.Icons.isOperationPlan(message) && this.isOperationPlanInTakeoffRequestedState(message) &&
      hasUserLocalClearanceRightsAndOpWasNotApproved(message, AppConfig.providerId);
  }

  static isDronePlanExpired(message) {
    return FeatureUtils.Icons.isOperationPlan(message) && isActiveExpiredOperationPlan(message);
  }

  static isDronePlanMonitored(message) {
    return FeatureUtils.Icons.isOperationPlan(message) && FeatureUtils.Filters.isMessageMarkedAsFavorite(message);
  }

  static constructOperatorName(op) {
    let contactName = op?.contactDetails?.firstName ? op?.contactDetails?.firstName + ' ' : '';
    contactName = contactName + (op?.contactDetails?.lastName ? op?.contactDetails?.lastName : '');
    return contactName;
  }

  static isCurrentUserUsspForOperationPlan(operationPlan, userProviderId) {
    return operationPlan.usspProviderId === userProviderId;
  }

  static isCurrentUserRequestorOfOperationPlan(operationPlan, userProviderId) {
    return userProviderId === operationPlan.authorizationRequestedBy;
  }

  static isOutlookOpInFilteringTimeInterval(time) {
    const filterTimeFrom = new Date().getTime() + parseInt(Store.getters.outlookPanelSelectedFilter) * 3600000;
    return time <= filterTimeFrom;
  }
}

function isResultForLocalClearanceRights(result, userProviderId) {
  return result.authorityId === userProviderId && result.partialResultType === 'CLEARANCE'
    && result.storedByProcess === 'CLEARANCE';
}

function isResultForLocalApprovalRights(result, userProviderId) {
  return result.authorityId === userProviderId && result.partialResultType === 'APPROVAL'
    && result.storedByProcess === 'APPROVAL';
}

function constructOperationPlanAltitudeText(volume) {
  const minAltitudeText = constructOperationPlanMinimumAltitudeText(volume);
  const maxAltitudeText = constructOperationPlanMaximumAltitudeText(volume);
  return (volume.minAltitude !== 0 ? minAltitudeText + ' - ' : '') + maxAltitudeText;
}

function constructOperationPlanMinimumAltitudeText(volume) {
  const minAltitudeValueInConfiguredUom =
    UomUtils.convertValueToCurrentUom(volume.minAltitude, UomConfig.FEATURE_UOM_TO_INTERNAL_UOM_ID[volume.unitOfMeasure]);
  const minAltitudeToBeDisplayed = ModelUtils.roundToNearestInteger(minAltitudeValueInConfiguredUom);
  return minAltitudeToBeDisplayed + ' ' + UomConfig.getCurrentlyConfiguredUomDisplayValue();
}

function constructOperationPlanMaximumAltitudeText(volume) {
  const maxAltitudeValueInConfiguredUom =
    UomUtils.convertValueToCurrentUom(volume.maxAltitude, UomConfig.FEATURE_UOM_TO_INTERNAL_UOM_ID[volume.unitOfMeasure]);
  const maxAltitudeToBeDisplayed = ModelUtils.roundToNearestInteger(maxAltitudeValueInConfiguredUom);
  return maxAltitudeToBeDisplayed + ' ' + UomConfig.getCurrentlyConfiguredUomDisplayValue();
}

function hasCurrentUserRightsForTakeoffRequest(dronePlan) {
  return dronePlan.usspProviderId === AppConfig.providerId ||
    dronePlan.authorizationRequestedBy === AppConfig.providerId;
}

function isDronePlanTimeBeforeActivationTimeWithThreshold(dronePlan) {
  return AppConfig.operationPlanAllowActivationBeforeStartMillis !== null ? TimeService.currentUtcTimeInMillis() <
    OperationPlanServices.getEarliestTimeBeginWithActivationThreshold(dronePlan) : false;
}

function isActiveExpiredOperationPlan(message) {
  return message.state === OperationPlanState.ACTIVATED.state &&
    message.validTime.to < TimeService.currentUtcTime().valueOf();
}

function hasUserLocalApprovalRightsAndOpWasNotApproved(message, userProviderId) {
  return DronePlanUtils.hasUserLocalApprovalRights(message, userProviderId) &&
    DronePlanUtils.getUserLocalApprovalRight(message, userProviderId)?.evaluationType === "MANUAL" &&
    !DronePlanUtils.hasUserAlreadyApprovedTheAuthorizationForOp(message, userProviderId);
}

function hasUserLocalClearanceRightsAndOpWasNotApproved(message, userProviderId) {
  return DronePlanUtils.hasUserLocalClearanceRights(message, userProviderId) &&
    DronePlanUtils.getUserLocalClearanceRight(message, userProviderId)?.evaluationType === "MANUAL" &&
    !DronePlanUtils.hasUserAlreadyApprovedTheActivationForOp(message, userProviderId);
}

function isNotRejectableOrClose(dronePlan) {
  const offsetTimeInMillis = TimeService.timeOffsetInMilliseconds();
  const operationState = FeatureUtils.State.getOperationState(dronePlan.state, dronePlan.stateAttributeType);

  return [
    OperationPlanState.CLOSED.state,
    OperationPlanState.NONCONFORMING.state,
    OperationPlanState.INACTIVETWR.state,
    OperationPlanState.NO_SERVICE.state]
    .indexOf(operationState) !== -1 || offsetTimeInMillis !== 0;
}