<template>
  <div id="view-editor-container" class="col-10">
    <div>
      <div class="form-inline mb-1 row form-group">
        <div class="view-editor-name">
          <strong>{{ $t('labels.common.name') }}:</strong>
          <input
            id="viewName"
            type="text"
            :class="['form-control', 'm-1', isValid ? '' : 'is-invalid'].join(' ')"
            v-model="name"
          >
          <span v-if="!isValid" class="text-left invalid-feedback">
          {{ $t('errorMessages.invalidName') }}
          </span>
        </div>
        <div class="pl-4">
          <strong>{{ $t('labels.configurationPanel.view.taskNotification') }}:</strong>
          <button
            id="sound-button"
            :class="['btn', 'btn-primary', 'ml-2', (taskNotificationIsEnabled)? 'sound-on' : 'sound-off'].join(' ')"
            @click="toggleTaskTabSoundNotification()"
            type="button"
          >
            <img v-bind:src="getButtonImgForSound()"
                 class="task-notification-sound-img"
                 :alt="$t('labels.configurationPanel.view.taskNotification')">
          </button>
        </div>
        <div class="pl-4">
          <strong>{{ $t('labels.configurationPanel.view.altitudeFilter') }}:</strong>
          <button
            id="altitude-filtered-features-button"
            :class="['btn', 'btn-primary', 'ml-2'].join(' ')"
            @click="toggleAltitudeFilteredFeaturesDialog()"
            type="button"
          >
            {{ $t('labels.configurationPanel.view.features') }}
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <FIRs id="airspace-container" class="col viewListElement maxWidthForTablet stationListPanel"/>
      <Airports id="airport-container" class="col viewListElement maxWidthForTablet stationListPanel"/>
      <Information id="information-container" class="col viewListElement"/>
      <Notifications id="notification-container" class="col viewListElement"/>
      <Viewport id="viewport-container" class="col viewListElement hideOnTablet"/>
    </div>
    <div>
      <SavePanel
        :isModified="isModified"
        :isEditable="isEditable"
        :isValid="isValid"
        :saveAction="save"
        :saveAsAction="saveAs"
        :cancelAction="cancel"
        :deleteAction="deleteView"
      />
    </div>
  </div>
</template>
<script>

import Airports from './Airports.vue';
import FIRs from './FIRs.vue';
import Information from './Information.vue';
import Notifications from './Notifications.vue';
import Viewport from './Viewport.vue';
import SavePanel from '../../save-panel/SavePanel.vue';
import DialogUtils from '../../../../utils/DialogUtils';
import PopupUtils from '../../../../utils/PopupUtils';
import PermissionUtils from '../../../../utils/PermissionUtils';

import ViewService from '../../../../services/smartSisViewService';
import A from '../../../../constants/actions';
import Logger from '../../../../utils/LoggerUtils';
import AppConfig from '../../../../config/appConfig';
import Features from '../../../../config/features';
import ImgUtils from "../../../../utils/ImgUtils";
import {mapGetters} from "vuex";
import Store from "../../../../store";
import SubscriptionUtils from "../../../../utils/SubscriptionUtils";

export default {
  name: 'ViewEditor',

  components: {
    Airports,
    FIRs,
    Information,
    Notifications,
    SavePanel,
    Viewport,
  },

  computed: {
    ...mapGetters({taskNotificationIsEnabled: 'getCurrentViewTaskTabSoundNotificationEnabled'}),
    name: {
      get() {
        return this.$store.state.viewStore.currentView.name;
      },
      set(value) {
        this.$store.dispatch(A.VIEW_UPDATE_NAME, value);
      },
    },
    isModified() {
      const currentView = this.$store.state.viewStore.currentView;
      const originalView = this.$store.state.viewStore.originalView;
      return PermissionUtils.isAllowedToEditViews() ? JSON.stringify(currentView) !== JSON.stringify(originalView) : false;
    },
    isEditable() {
      return this.$store.state.viewStore.currentView.editable !== false;
    },
    isValid() {
      return this.$store.state.viewStore.currentView.name.length > 0;
    },
    saveAs() {
      return PermissionUtils.isSuperAdmin() ? this.saveAsCallback : null;
    },
  },

  methods: {
    save() {
      this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);

      const currentView = JSON.parse(JSON.stringify(this.$store.state.viewStore.currentView));
      delete currentView.editable;
      delete currentView.filterType;
      currentView.productTypes = currentView.productTypes.filter(ft => this.isAvailableFeatureType(ft));
      const config = {
        view: currentView,
        successCallback: () => {
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          this.$store.dispatch(A.VIEW_SAVE_VIEW);
          PopupUtils.success(this.$i18n.t('popupMessages.viewSaved'));
          Logger.info(this.$i18n.t('logMessages.viewSaved', {viewName: currentView.name}));
        },
        errorCallback: (error) => {
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          const errorMessage = JSON.parse(error);
          Logger.error(this.$i18n.t('logMessages.viewSaveError', {viewName: currentView.name, error}));
          DialogUtils.errorNotification(errorMessage.errorMessage);
        },
      };
      ViewService.saveView(config);
    },
    saveAsCallback(viewName) {
      this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
      const newView = JSON.parse(JSON.stringify(this.$store.state.viewStore.currentView));
      newView.name = viewName;
      if (!AppConfig.heightSlider.heightSliderEnabled || Store.getters.getAltitudeFilteredFeatures.length === 0) {
        newView.maxAltitudeInMeters = AppConfig.heightSlider.maximumHeightLimitInMeters;
        newView.minAltitudeInMeters = AppConfig.heightSlider.minimumHeightLimitInMeters;
      }
      newView.productTypes = newView.productTypes.filter(ft => this.isAvailableFeatureType(ft));
      newView.notifications
        .forEach(n => n.products = n.products.filter(p =>
          this.isAvailableFeatureType(p) && !Features.getFeature(p).isNotificationsDisabled));

      delete newView.editable;
      delete newView.filterType;
      delete newView.id;

      const config = {
        view: newView,
        successCallback: (data) => {
          newView.id = data;
          Logger.info(this.$i18n.t('logMessages.viewCreated', {viewName: newView.name}));
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          this.$store.dispatch(A.VIEW_SAVE_VIEW_AS, newView);
          PopupUtils.success(this.$i18n.t('popupMessages.viewSaved'));
        },
        errorCallback: (error) => {
          const errorMessage = JSON.parse(error);
          Logger.error(this.$i18n.t('logMessages.viewCreateError', {error}));
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          DialogUtils.errorNotification(errorMessage.errorMessage);
        },
      };

      ViewService.saveView(config);
    },
    cancel() {
      this.$store.dispatch(A.VIEW_CANCEL_EDIT);
      const affectedFeatures = Features.getConfiguredAltitudeBasedFeatureTypes();
      this.$store.dispatch(A.WEBSOCKET_PARTIAL_SUBSCRIBE, SubscriptionUtils.constructPartialSubscriptionConfig(affectedFeatures));
    },
    deleteView() {
      this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);

      const viewId = this.$store.state.viewStore.currentView.id;
      const viewName = this.$store.state.viewStore.currentView.name;
      const config = {
        viewId,
        successCallback: () => {
          Logger.info(this.$i18n.t('logMessages.viewDeleted', {viewName}));
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          this.$store.dispatch(A.VIEW_DELETE_VIEW, viewId);
          PopupUtils.success(this.$i18n.t('popupMessages.viewDeleted'));
        },
        errorCallback: (error) => {
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          Logger.error(this.$i18n.t('logMessages.viewDeleteError', {viewName, error}));
          DialogUtils.errorNotification(this.$i18n.t('errorMessages.viewDeleteError'));
        },
      };

      ViewService.deleteView(config);
    },
    isAvailableFeatureType(featureType) {
      return Features.getAllFeaturesForView().some(ft => ft.id === featureType);
    },
    getButtonImgForSound() {
      const name = this.$store.state.viewStore.currentView.taskTabSoundNotificationEnabled ? 'unmute' : 'mute';
      return ImgUtils.getButtonImg(name, 'svg');
    },
    toggleTaskTabSoundNotification() {
      this.$store.dispatch(A.VIEW_TOGGLE_TASK_SOUND_NOTIFICATION);
    },
    toggleAltitudeFilteredFeaturesDialog() {
      this.$store.dispatch(A.VIEW_EDITOR_ALTITUDE_FILTERED_FEATURES_DIALOG_TOGGLE);
    }
  }

}
</script>
<style src="./viewEditor.css"></style>