import AppConfig from '../config/appConfig';
import AjaxUtils from "./AjaxUtils";
import Store from '../store';
import A from "../constants/actions";
import TimeService from "../services/timeService";
import LoggerUtils from "./LoggerUtils";
import {i18n} from '../internationalization/index';

export default class NetworkUtils {

  static checkOnlineStatus = async () => {
    const connectionIsOnline = await NetworkUtils.testTheConnection();
    if (connectionIsOnline) {
      if (!Store.getters.webSocketIsConnected) {
        LoggerUtils.info(i18n.global.t('logMessages.networkConnectionRestored'));
        await Store.dispatch(A.WEBSOCKET_CONNECTED);
      }
    } else {
      LoggerUtils.info(i18n.global.t('logMessages.networkConnectionLost'));
      await Store.dispatch(A.WEBSOCKET_CLOSED);
    }
  };

  static testTheConnection = async () => {
    try {
      const controller = new AbortController();
      const id = setTimeout(() => controller.abort(), 5000);
      await fetch(AppConfig.server.serviceUrlRoot + '/users', {
        method: 'get',
        headers: AjaxUtils.getHeaders(),
        signal: controller.signal
      });
      clearTimeout(id);
      return true;
    } catch (err) {
      return false;
    }
  }

  static existExpiredHeartbeats(heartbeatInterval) {
    const heartbeatCache = JSON.parse(JSON.stringify(Store.getters.getHeartbeatCache));
    return heartbeatCache.map(hb => hb.split('|')[1])
      .some(hb => hb < TimeService.browserTimeInMilliseconds() - 2 * heartbeatInterval);
  }
}