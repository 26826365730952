<script>
import ModeOfOperation from '../../../../../constants/modeOfOperation';
import UomConfig from "../../../../../config/uomConfig";
import ModelUtils from "../../../../../utils/ModelUtils";
import UomUtils from "../../../../../utils/UomUtils";
import OpPriorityConfig from "../../../../../config/OpPriorityConfig";

export default {
  name: "DronePlanConflictItem",
  props: {
    conflict: Object,
  },
  computed: {
    maxAltitude() {
      const maxAltitude = ModelUtils.roundToTwoDecimals(
        UomUtils.convertValueToCurrentUom(this.conflict.operationPlanDetails.maxAltitude.altitudeValue,
          UomConfig.FEATURE_UOM_TO_INTERNAL_UOM_ID[this.conflict.operationPlanDetails.maxAltitude.unitsOfMeasure])
      );
      return `${maxAltitude} ${ModelUtils.constructDisplayedUomAndCrs()}`;
    }
  },
  methods: {
    displayValueForOpType(type) {
      return ModeOfOperation.getDisplayModeOfOperation(type);
    },
    displayValueForOpPriority(priority) {
      return OpPriorityConfig.getDisplayValueForPriority(priority);
    }
  }
}
</script>

<template>
  <div class="conflicting-drone-plan-details m-0">
    <div>
      {{ $t('labels.operationPlan.planId') }}: {{ conflict.objectId }}
    </div>
    <div class="row m-0">
      <div class="col-6 m-0 p-0">
        <div>{{ $t('labels.common.state') }}: {{ conflict.operationPlanDetails.state }}</div>
        <div>{{ $t('labels.operationPlan.priority') }}: {{
            displayValueForOpPriority(conflict.operationPlanDetails.priority)
          }}
        </div>
      </div>
      <div class="col-6 m-0 p-0">
        <div>{{ $t('labels.common.type') }}: {{
            displayValueForOpType(conflict.operationPlanDetails.typeOfOperation)
          }}
        </div>
        <div>{{ $t('labels.common.maxAltitude') }}: {{ maxAltitude }}</div>
      </div>
    </div>
  </div>
</template>

<style src="./conflictSuppressionPanel.css"></style>