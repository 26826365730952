<template>
  <div v-if="isOpen" id="geoserver-layer-storing-dialog-container" class="dialogContainer">
    <div class="dialog modal" role="dialog">
      <div class="geoserver-layer-storing-dialog modal-dialog modal-dialog-centered" role="document">
        <div id="geoserver-layer-storing-dialog-modal-content" class="modal-content smartSISPanel">
          <div id="geoserver-layer-storing-dialog-modal-header" class="modal-header">
            <h5 id="geoserver-layer-storing-dialog-modal-title" class="modal-title">
              {{ $t('labels.configurationPanel.gisLayers.addLayerConfiguration') }}
            </h5>
          </div>
          <div id="geoserver-layer-storing-dialog-modal-body" class="modal-body errorBody">
            <div id="geoserver-layer-title-container" class="row">
              <div class="col-3 layerConfigFormDiv pr-0">
                <span>
                  <strong>
                    {{ $t('labels.configurationPanel.gisLayers.title') }}<strong class="text-danger">*</strong>:
                  </strong>
                </span>
              </div>
              <div class="col-5 inputFieldsWrapper pl-2 pr-0 pb-1">
                <input
                  id="title"
                  name="title"
                  type="text"
                  :class="['form-control', (this.isValidTitle(this.layerFormConfig.title) ? '' : 'invalid')].join(' ')"
                  @keyup="onKeyup"
                  @change="onKeyup"
                  :value="this.layerFormConfig.title"
                >
              </div>
              <div class="title-error-message text-danger" v-if="this.isDuplicateTitle(this.layerFormConfig.title)">
                {{ $t('labels.configurationPanel.gisLayers.invalidTitleMessage') }}
              </div>
            </div>
            <div id="geoserver-layer-online-reference-container" class="row">
              <div class="col-3 layerConfigFormDiv pr-0">
                <span>
                  <strong>
                    {{ $t('labels.configurationPanel.gisLayers.onlineReference') }}<strong
                    class="text-danger">*</strong>:
                  </strong>
                </span>
              </div>
              <div class="col-5 inputFieldsWrapper pl-2 pr-0">
                <input
                  id="online-reference"
                  name="onlineReference"
                  type="text"
                  :class="['form-control', (this.isValidText('onlineReference') ? '' : 'invalid')].join(' ')"
                  @keyup="onKeyup"
                  @change="onKeyup"
                  :value="this.layerFormConfig.onlineReference"
                >
              </div>
            </div>
            <div id="geoserver-layer-basemap-container" class="row">
              <div class="col-3 layerConfigFormDiv pr-0">
                <strong>{{ $t('labels.common.basemap') }}<strong class="text-danger">*</strong>:</strong>
              </div>
              <div class="col-6 inputFieldsWrapper">
                <label class="m-2 radio">
                  <input id="radio-button-yes" type="radio" v-model="layerFormConfig.isBaseMap" value="true">
                  {{ $t('labels.common.yes') }}
                </label>
                <label class="m-2 radio">
                  <input id="radio-button-no" type="radio" v-model="layerFormConfig.isBaseMap" value="false">
                  {{ $t('labels.common.no') }}
                </label>
              </div>
            </div>
            <div id="geoserver-layer-visibility-roles-container" class="row">
              <div class="col-3 layerConfigFormDiv pr-0">
                <strong>
                  {{ $t('labels.configurationPanel.gisLayers.visibility') }}<strong class="text-danger">*</strong>:
                </strong>
              </div>
              <div class="col-6 inputFieldsWrapper">
                <label class="m-2 radio" v-for="role in visibilityRoles" :key="role.name">
                  <input type="checkbox" :id="role.name" v-model="layerFormConfig.visibleForRoles"
                         :value="role.name">
                  {{ role.displayValue }}
                </label>
              </div>
            </div>
            <div id="geoserver-layer-start-validity-container" class="row">
              <div class="col-3 layerConfigFormDiv pr-0">
                <strong>{{ $t('labels.configurationPanel.gisLayers.startValidity') }}<strong
                  class="text-danger">*</strong>:</strong>
              </div>
              <div class="col-5 pl-2 pb-1 p-0">
                <date-picker
                  :get-value="() => getValue('startValidity')"
                  :set-value="(value) => setValue(value, 'startValidity')"
                  :custom-class="['custom-icon', 'p-0', (isEmpty('startValidity') ? 'invalid' : '')].join(' ')"
                  format="yyyy-MM-dd HH:mm"
                  id="geoserver-layer-start-validity">
                </date-picker>
              </div>
            </div>
            <div id="geoserver-layer-end-validity-container" class="row">
              <div class="col-3 layerConfigFormDiv pr-0">
                <strong>{{ $t('labels.configurationPanel.gisLayers.endValidity') }}<strong
                  class="text-danger">*</strong>:</strong></div>
              <div class="pl-2 col-5 p-0">
                <date-picker
                  :get-value="() => getValue('endValidity')"
                  :set-value="(value) => setValue(value, 'endValidity')"
                  :custom-class="['custom-icon', 'p-0', (isEmpty('endValidity') ? 'invalid' : '')].join(' ')"
                  format="yyyy-MM-dd HH:mm"
                  id="geoserver-layer-end-validity">
                </date-picker>
              </div>
            </div>
          </div>
          <div id="geoserver-layer-storing-dialog-modal-footer" class="modal-footer">
            <div>
              <button id="add-layer-configuration"
                      class="btn btn-danger"
                      :disabled="!isLayerFormConfigValid"
                      @click="addLayerConfiguration">
                {{ $t('labels.common.add') }}
              </button>
              <button id="layer-configuration-cancel"
                      class="btn btn-primary"
                      @click="cancelLayerStoring">
                {{ $t('labels.common.cancel') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import A from '../../constants/actions';
import {mapGetters} from 'vuex';
import DatePicker from "../../common/DatePicker";
import layerVisibilityConfig from '../../config/layerVisibilityConfig';
import TimeService from "../../services/timeService";

export default {
  name: "GeoserverLayerStoringDialog",
  components: {
    DatePicker,
  },
  data() {
    return {
      layerFormConfig: {
        title: null,
        onlineReference: null,
        isBaseMap: null,
        visibleForRoles: [],
        startValidity: TimeService.currentUtcTimeISOFormatted(),
        endValidity: TimeService.currentUtcTimeISOFormatted()
      }
    }
  },
  watch: {
    isOpen: {
      immediate: true,
      handler() {
        this.layerFormConfig = {
          title: this.layerConfig.title,
          onlineReference: this.layerConfig.onlineReference,
          isBaseMap: null,
          visibleForRoles: [],
          startValidity: TimeService.currentUtcTimeISOFormatted(),
          endValidity: TimeService.currentUtcTimeISOFormatted()
        };
      }
    }
  },
  computed: {
    ...mapGetters({
      isOpen: 'getIsStoreLayerDialogOpen',
      layerConfig: 'getLayerConfigToBeStored',
      geoserverLayers: 'getAllGeoserverLayers',
    }),
    isLayerFormConfigValid() {
      return this.layerFormConfig && this.layerFormConfig.isBaseMap && this.layerFormConfig.startValidity &&
        this.layerFormConfig.endValidity && this.layerFormConfig.visibleForRoles &&
        this.layerFormConfig.visibleForRoles.length > 0 &&
        this.isValidTitle(this.layerFormConfig.title) &&
        this.layerFormConfig.onlineReference && this.layerFormConfig.onlineReference !== "";
    },
    visibilityRoles() {
      return layerVisibilityConfig.getLayerVisibilityRoles();
    }
  },
  methods: {
    getValue(propName) {
      const dateTime = this.layerFormConfig[propName];
      if (dateTime) {
        const dt = new Date(dateTime);
        dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
        return dt;
      }
      return '';
    },
    setValue(value, propName) {
      const dateTime = new Date(Date.parse(value));
      dateTime.setMinutes(dateTime.getMinutes() - dateTime.getTimezoneOffset());
      this.layerFormConfig[propName] = TimeService.truncateTimestampToMinuteGranularity(dateTime.toISOString());
    },
    cancelLayerStoring() {
      this.layerFormConfig = {
        title: null,
        onlineReference: null,
        isBaseMap: null,
        visibleForRoles: [],
        startValidity: null,
        endValidity: null
      };
      this.$store.dispatch(A.LAYERS_CONFIG_CANCEL_STORE_LAYER_DIALOG);
    },
    addLayerConfiguration() {
      this.$store.dispatch(A.LAYERS_CONFIG_ADD_LAYER_CONFIGURATION, this.layerFormConfig);
    },
    onKeyup(evt) {
      this.layerFormConfig[evt.target.name] = evt.target.value;
    },
    isValidText(propName) {
      return this.layerFormConfig[propName] && this.layerFormConfig[propName] !== "";
    },
    isValidTitle(title) {
      return title && title !== "" && !this.isDuplicateTitle(title);
    },
    isDuplicateTitle(title) {
      return this.geoserverLayers.some(l => l.defaultName === title);
    },
    isEmpty(propName) {
      return this.layerFormConfig[propName] === null || this.layerFormConfig[propName] === "";
    }
  }
}
</script>

<style src="./geoserverLayersConfig.css"/>