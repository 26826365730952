<template>
  <div id="geoserver-layer-configurations">
    <div class="row ml-1 layerConfigMarginTop">
      <div class="card smartSISPanel layerConfigTabParentDiv col-7 p-0" v-bind:style="{height: heightSize}">
        <div class="table-row header card-header">
          <div class="col-3 p-0">{{ $t('labels.common.name') }}</div>
          <div class="col-2 p-0">{{ $t('labels.common.type') }}</div>
          <div class="col-2">{{ $t('labels.configurationPanel.gisLayers.startValidity') }}</div>
          <div class="col-2">{{ $t('labels.configurationPanel.gisLayers.endValidity') }}</div>
          <div class="col-2 p-0">{{ $t('labels.configurationPanel.gisLayers.visibility') }}</div>
          <div class="col-1 p-0"></div>
        </div>
        <div class="rowContainer card-body">
          <div :key="layerConfig.id" class="table-row pb-1" v-for="(layerConfig) in geoserverLayers">
            <div class="col-3 layerConfigCol p-0">{{ layerConfig.defaultName }}</div>
            <div class="col-2 layerConfigCol p-0">{{ constructLayerType(layerConfig.isBaseMap) }}</div>
            <div class="col-2 layerConfigCol">{{ formatDateTime(layerConfig.startValidity) }}</div>
            <div class="col-2 layerConfigCol">{{ formatDateTime(layerConfig.endValidity) }}</div>
            <div class="col-2 layerConfigCol p-0">{{ formatLayerVisibility(layerConfig.visibleForRoles) }}</div>
            <div class="col-1 p-0">
              <button
                :id="layerConfig.id + '-remove-geoserver-layer-config'"
                :class="['btn', 'btn-primary', 'rmvBtn'].join(' ')"
                @click="removeGeoserverLayerConfig(layerConfig)"
              >
                <img class="removeImg" v-bind:src="getButtonImg('remove')">
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="shouldDisplayImportFromCswButton" class="col-5">
        <button id="add-new-layer"
                class="btn btn-primary ml-3"
                @click="addNewLayer">
          {{ $t('labels.configurationPanel.gisLayers.addLayer') }}
        </button>
        <button id="import-from-csw"
                class="btn btn-primary ml-3"
                @click="showCSWConnectorFormAndListOfAvailableLayers">
          {{ $t('labels.configurationPanel.gisLayers.importFromCsw') }}
        </button>
      </div>
      <div v-else class="col-5 row">
        <GeoserverCswServiceConnector class="col-4"/>
        <GeoserverCswServiceLayers class="col-8"/>
      </div>
      <div v-if="shouldDisplayProgressIndicator" class="cswServiceConnectorProgressIndicator"
           v-bind:style="{ backgroundImage: 'url(' + this.progressSrc() + ')', height: heightSize }"></div>
    </div>
  </div>
</template>

<script>

import A from '../../constants/actions';
import Moment from "moment";
import GeoserverCswServiceConnector from "./GeoserverCswServiceConnector";
import GeoserverCswServiceLayers from "./GeoserverCswServiceLayers";
import {mapGetters} from "vuex";
import ImgUtils from "../../utils/ImgUtils";
import DialogUtils from "../../utils/DialogUtils";
import GeoserverLayersService from "../../services/geoserverLayersService";
import Logger from "../../utils/LoggerUtils";
import PopupUtils from "../../utils/PopupUtils";
import LayerVisibilityUtils from "../../utils/LayerVisibilityUtils";

export default {
  name: "GeoserverLayersConfig",
  components: {GeoserverCswServiceLayers, GeoserverCswServiceConnector},
  computed: {
    ...mapGetters({
      geoserverLayers: 'getAllGeoserverLayers',
      shouldDisplayImportFromCswButton: 'shouldDisplayImportFromCswButton',
      shouldDisplayProgressIndicator: 'shouldDisplayProgressIndicator'
    }),
    heightSize() {
      return this.$store.state.configurationStore.geoserverLayerConfigPanelHeight + 'px';
    },
  },
  methods: {
    constructLayerType(layerConfigIsBaseMap) {
      return layerConfigIsBaseMap ? this.$i18n.t('labels.common.basemap') : this.$i18n.t('labels.common.layer');
    },
    formatDateTime(dateTime) {
      return dateTime ? Moment.utc(dateTime).format('DD MMM YYYY HH:mm') : "";
    },
    showCSWConnectorFormAndListOfAvailableLayers() {
      this.$store.dispatch(A.LAYERS_CONFIG_TOGGLE_CONNECTOR_AND_AVAILABLE_LAYERS);
    },
    getButtonImg(name) {
      return ImgUtils.getButtonImg(name, 'svg');
    },
    removeGeoserverLayerConfig(config) {
      let message = this.$i18n.t('confirmationMessages.gisLayerRemove', {layerName: config.defaultName});
      message = config.isBaseMap ?
        message + '<br>' + this.$i18n.t('logMessages.basemapLayerUpdateNecessary') : message;
      DialogUtils.confirmOperation({
        message: message,
        yesAction: () => this.performRemoveAction(config),
      });
    },
    performRemoveAction(layerConfig) {
      this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
      GeoserverLayersService.removeGeoserverLayerConfiguration({
        id: layerConfig.id,
        successCallback: () => {
          Logger.info(this.$i18n.t('logMessages.gisLayerRemoved'));
          this.$store.dispatch(A.PROGRESS_INDICATOR_END);
          this.$store.dispatch(A.GEOSERVER_LAYERS_REMOVE_LAYER, layerConfig.id);
          this.$store.dispatch(A.MAP_REMOVE_ALL_LAYERS, layerConfig.id);
          PopupUtils.success(this.$i18n.t('popupMessages.gisLayerRemoved'));
        },
        errorCallback: (error) => {
          Logger.error(error);
          DialogUtils.errorNotification(error);
          this.$store.dispatch(A.PROGRESS_INDICATOR_END);
        }
      });
    },
    formatLayerVisibility(visibleForRoles) {
      return visibleForRoles ? visibleForRoles.map(
        role => LayerVisibilityUtils.getDisplayValueOfLayerVisibility(role)
      ).join(", ") : "-";
    },
    progressSrc() {
      return 'icons/progressIndicator.gif';
    },
    addNewLayer() {
      this.$store.dispatch(A.LAYERS_CONFIG_OPEN_STORE_LAYER_DIALOG, {});
    },
  }
}
</script>

<style src="./geoserverLayersConfig.css"/>