import FeatureType from '../../constants/featureType';
import ThemeElements from '../../constants/theme-elements';
import FeatureUtils from '../../utils/FeatureUtils';
import Store from '../../store';
import MessageUtils from '../../utils/MessageUtils';
import DialogUtils from '../../utils/DialogUtils';
import AppConfig from '../../config/appConfig';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import {i18n} from '../../internationalization/index';

export default {

  id: FeatureType.METAR,
  name: 'features.metar',
  icon: 'metar',
  staticDataType: [FeatureType.AIRPORT_HELIPORT],
  isViewPortBased: false,
  isFlightLevelBased: false,
  maxNrOfFeaturesPerLocation: 1,
  getReplaceableMessages: FeatureUtils.Filters.filterByIdOrStation,
  timeoutHandler: FeatureUtils.Timeout.getMetTimeoutHandler(FeatureUtils.Timeout.getTimeoutForLayer(FeatureType.METAR)),
  featureIdentifier: 'id',
  maxResolution: 10000,
  declutter: false,
  zIndex: 1,
  flightLevel: null,
  theme: {
    elements: [
      ThemeElements.SHOW_TEXT,
      ThemeElements.TEXT_COLOR,
      ThemeElements.TEXT_SIZE,
      ThemeElements.TEXT_BG_COLOR,
      ThemeElements.ICON_SIZE,
    ]
  },

  displayOnMap: true,
  displayInMessages: true,

  geoJsonMapper: (metar) => {
    const thresholdConfig = Store.state.thresholdStore.thresholds.find(s => s.id === metar.featureType);
    let geom = null;
    if (!metar.location) {
      DialogUtils.errorNotification(i18n.global.t('errorMessages.missingStationLocation', {metar: metar.station}));
    } else {
      geom = new Point([metar.location.lon, metar.location.lat]);
    }
    return new Feature({
      geometry: geom,
      id: metar.id,
      station: metar.station,
      metarDescription: AppConfig.ui.metarCriticalInfoOnly ? MessageUtils.getCriticalDescription(metar, thresholdConfig) : metar.metarDescription,
      maxResolution: 10000,
      wind: metar.wind,
      horizontalVisibility: metar.horizontalVisibility,
      minVisibility: metar.minVisibility,
      runways: metar.runways,
      clouds: metar.clouds,
      presentWeather: metar.presentWeather,
      featureType: metar.featureType,
      color: MessageUtils.getColor(metar, thresholdConfig),
    });
  },

  color: (colors) => {
    return colors.textColor;
  },

  defaultStyle: () => {
    return {
      showText: true,
      textSize: 14,
      iconSize: 100,
      colors: {
        borderColor: {r: 239, g: 8, b: 8, a: 0.83},
        fillColor: {r: 239, g: 8, b: 8, a: 0.01},
        textColor: {r: 134, g: 134, b: 134, a: 0.8},
        textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
        imageColor: {r: 239, g: 8, b: 8, a: 0.5}
      }
    }
  },

  getInformationPanelMessageStyle(msg, style) {

    if (msg.color) {
      if (msg.color !== MessageUtils.getColors().GREEN) {
        style['border-left'] = '7px solid ' + msg.color;
      }
      style['padding-left'] = '5px';
    }

    return style;
  },

  getMinifiedObject(feature, minifiedObject) {
    const thresholdConfig = Store.state.thresholdStore.thresholds.find(s => s.id === FeatureType.METAR);
    return {
      ...minifiedObject,
      tac: MessageUtils.highlightThresholds(feature, thresholdConfig),
    }
  }

}