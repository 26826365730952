import FeatureType from '../../constants/featureType';
import FeatureUtils from '../../utils/FeatureUtils';
import MessageUtils from '../../utils/MessageUtils';
import OperationPlaneState from "../../constants/operationPlanState";
import TimeService from "../../services/timeService";
import Feature from "ol/Feature";
import {i18n} from '../../internationalization/index';

export default {

  id: FeatureType.ALERT_REPLY,
  name: 'features.alertReply',
  icon: 'alert_reply',
  displayOnMap: false,
  displayInMessages: true,
  staticDataType: [FeatureType.AIRSPACE, FeatureType.RESPONSIBILITY_AREA],
  isViewPortBased: false,
  isFlightLevelBased: false,
  maxNrOfFeaturesPerLocation: 200,
  getReplaceableMessages: FeatureUtils.Filters.filterById,
  featureIdentifier: 'id',
  maxResolution: 10000,
  declutter: false,
  zIndex: 1,
  flightLevel: null,

  geoJsonMapper: (feature) => {
    return new Feature({
      id: feature.id,
      featureType: FeatureType.ALERT_REPLY,
    });
  },

  getInformationPanelMessageFormat(msg) {
    return (`
      <div>
      ${i18n.global.t('labels.geozone.message')}: ${msg.message}<br />
      </div>
    `);
  },

  getMinifiedObject(feature, minifiedObject) {
    return {
      ...minifiedObject,
      id: feature.id,
      alertId: feature.alertId,
      validTime: {
        from: new Date(TimeService.currentUtcTime()).getTime(),
        to: new Date(feature.operationEndTime).getTime()
      },
      message: feature.message
    }
  },

  filterMessages: (messages) => {
    let closedOperationPlanIds = messages.filter(m => m.featureType.indexOf('operationplan') !== -1)
      .filter(op => op.state === OperationPlaneState.CLOSED.state).map(m => m.id);
    let displayedAlertIds = messages.filter(m => m.featureType === FeatureType.DRONE_ALERT)
      .filter(alertMessage => !closedOperationPlanIds.includes(alertMessage.operationPlanIds[0])).map(m => m.id);
    return messages.filter(msg => msg.featureType === FeatureType.ALERT_REPLY)
      .filter(alertReply => displayedAlertIds.includes(alertReply.alertId));
  },

  getInformationPanelMessageStyle(msg, style) {
    const color = MessageUtils.getColors().TRANSPARENT;
    style['border-left'] = '7px solid ' + color;
    style['padding-left'] = '5px';
    return style;
  },

}