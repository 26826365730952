<script>

export default {
  name: "OtherConflictItem",
  props: {
    conflict: Object,
  },
}
</script>

<template>
  <div class="conflicting-drone-plan-details m-0" id="other-conflict-details">
    <div id="other-conflict-type">
      {{ $t('labels.operationPlan.conflictType') }}: {{ conflict.conflictType }}
    </div>
    <div id="other-conflict-message">
      {{ conflict.message }}
    </div>
  </div>
</template>

<style src="./conflictSuppressionPanel.css"/>