<template>
  <div id="informationContainer" ref="informationContainer"
       :class="['informationContainer', (this.displayInformationContainerWithMargin ? 'informationContainerMargin' : '')].join(' ')">
    <Message
      v-for="message in displayedMessages"
      :key="message.id + message.addedOn"
      :message="message"
      :removable="true"
    ></Message>
  </div>
</template>
<script>

import Message from './Message.vue';
import customMessageCardComponentsConfig from "../../config/customMessageCardComponentsConfig";
import {mapGetters} from "vuex";

export default {
  name: 'MessageContainer',
  props: ['messages', 'displayMessagesPaginated'],
  components: {
    Message,
  },
  mounted() {
    if (this.displayMessagesPaginated) {
      this.$refs['informationContainer'].onscroll = this.handleScrollEvent;
    }
  },
  data() {
    return {
      pageDelta: 5,
      currentPage: 1
    }
  },
  watch: {
    messages: {
      handler(newMessages, oldMessages) {
        if (this.displayMessagesPaginated && newMessages.length < oldMessages.length) {
          while (this.pageMiddleIndex > newMessages.length && this.currentPage > 1) {
            this.currentPage--;
          }
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      activeTab: 'activeInformationTab',
      taskTabIsOpened: 'taskTabIsOpened',
    }),
    pageMiddleIndex() {
      return this.currentPage * this.pageDelta;
    },
    pageLowerIndex() {
      return this.pageMiddleIndex - this.pageDelta;
    },
    pageUpperIndex() {
      const pageUpperIndex = this.pageMiddleIndex + this.pageDelta;
      return pageUpperIndex > this.messages.length ? this.messages.length : pageUpperIndex;
    },
    displayedMessages() {
      const internalMessages = new Array(...this.messages).reverse();
      return this.displayMessagesPaginated ? internalMessages.slice(this.pageLowerIndex, this.pageUpperIndex) : internalMessages;
    },
    displayInformationContainerWithMargin(){
      return this.hasCustomMessageComponent() || this.taskTabIsOpened;
    },
  },
  methods: {
    handleScrollEvent() {
      if (this.scrollBottomWasReached()) {
        this.adjustScrollWhenBottomWasReached();
        this.displayMessagesFromNextPage();
      } else if (this.scrollTopWasReached()) {
        this.adjustScrollWhenTopWasReached();
        this.displayMessagesFromPreviousPage();
      }
    },
    scrollTopWasReached() {
      const messageContainer = this.$refs['informationContainer'];
      return messageContainer.scrollTop === 0;
    },
    scrollBottomWasReached() {
      const messageContainer = this.$refs['informationContainer'];
      return messageContainer.offsetHeight + messageContainer.scrollTop >= messageContainer.scrollHeight;
    },
    displayMessagesFromPreviousPage() {
      if (this.pageLowerIndex > 0) {
        this.currentPage--;
      }
    },
    displayMessagesFromNextPage() {
      if (this.pageUpperIndex < this.messages.length) {
        this.currentPage++;
      }
    },
    adjustScrollWhenTopWasReached() {
      const messageContainer = this.$refs['informationContainer'];
      if (this.pageLowerIndex > 0) {
        messageContainer.scrollTo(0, 25);
      }
    },
    adjustScrollWhenBottomWasReached() {
      const messageContainer = this.$refs['informationContainer'];
      if (this.pageUpperIndex < this.messages.length) {
        messageContainer.scrollTo(0, 25);
      }
    },
    hasCustomMessageComponent() {
      return this.activeTab ? customMessageCardComponentsConfig.hasCustomComponent(this.activeTab.featureType) : false;
    }
  }
}

</script>
<style src="./informationPanel.css"></style>
