import FeatureType from "../../constants/featureType";
import FeatureUtils from "../../utils/FeatureUtils";
import ThemeElements from "../../constants/theme-elements";
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';

export default {

  id: FeatureType.NETWORK_COVERAGE,
  name: 'features.networkCoverage',
  staticDataType: [FeatureType.RESPONSIBILITY_AREA, FeatureType.AIRSPACE],
  maxNrOfFeaturesPerLocation: 200,
  getReplaceableMessages: FeatureUtils.Filters.filterById,
  featureIdentifier: 'id',
  isViewPortBased: false,
  isFlightLevelBased: false,
  maxResolution: 10000,
  declutter: false,
  zIndex: 1,
  flightLevel: null,
  theme: {
    elements: [
      ThemeElements.SHOW_TEXT,
      ThemeElements.BORDER_COLOR,
      ThemeElements.FILL_COLOR,
      ThemeElements.TEXT_COLOR,
      ThemeElements.TEXT_SIZE,
      ThemeElements.TEXT_BG_COLOR,
      ThemeElements.IMAGE_COLOR,
    ]
  },
  displayOnMap: true,
  displayInMessages: false,

  geoJsonMapper: (feature) => {
    return new Feature({
      id: feature.id,
      featureType: feature.featureType,
      associatedFeatureId: feature.operationPlanGufi
    });
  },

  geoJsonVolumeMapper: (feature, volume) => {
    return new Feature({
      geometry: new GeoJSON().readGeometry(volume.geometry),
      featureType: feature.featureType,
      hasSignal: volume.hasSignal,
      associatedFeatureId: feature.operationPlanGufi
    })
  }


}