import A from "../../constants/actions";
import M from "../../constants/mutations";
import cswServiceConnectionService from "../../services/cswServiceConnectionService";
import GeoserverLayersService from "../../services/geoserverLayersService";
import Logger from "../../utils/LoggerUtils";
import DialogUtils from "../../utils/DialogUtils";
import PopupUtils from "../../utils/PopupUtils";
import {i18n} from '../../internationalization/index';
import {v4 as uuidv4} from "uuid";

function getLayerConfigurationFromFormFields(layerConfigurationFormFields) {
  return {
    id: uuidv4(),
    dataFormat: 'WMS',
    names: {
      default: layerConfigurationFormFields.title,
    },
    clients: null,
    endpoint: layerConfigurationFormFields.onlineReference,
    isBaseMap: layerConfigurationFormFields.isBaseMap,
    startValidity: layerConfigurationFormFields.startValidity,
    endValidity: layerConfigurationFormFields.endValidity,
    legalConstraints: null,
    label: null,
    altitude: null,
    isFileBased: false,
    refreshSchedule: null,
    visibleForRoles: layerConfigurationFormFields.visibleForRoles,
  };
}

const defaultConfig = {
  availableLayers: [],
  isCswServiceConnected: false,
  isImportFromCSWButtonClicked: false,
  isStoreDialogOpen: false,
  layerConfigToBeStored: {
    title: null,
    onlineReference: null
  },
  cswServiceConfig: {
    endpoint: null,
    user: null,
    password: null
  },
  displayProgressIndicator: false,
};

const state = {
  currentConfiguration: JSON.parse(JSON.stringify(defaultConfig)),
  originalConfiguration: JSON.parse(JSON.stringify(defaultConfig)),
};

const actions = {
  [A.LAYERS_CONFIG_CANCEL_EDIT]({commit}) {
    commit(M.LAYERS_CONFIG_CANCEL_EDIT);
  },
  [A.LAYERS_CONFIG_UPDATE_CSW_SERVICE_PROPERTY]({commit}, config) {
    commit(M.LAYERS_CONFIG_UPDATE_CSW_SERVICE_PROPERTY, config);
  },
  [A.LAYERS_CONFIG_CONNECT_TO_CSW_SERVICE]({state, dispatch}) {
    const cswServiceConfig = state.currentConfiguration.cswServiceConfig;
    dispatch(A.LAYERS_CONFIG_PROGRESS_INDICATOR_TOGGLE);
    cswServiceConnectionService.connectToCswService({
      cswServiceConfig,
      successCallback: (availableLayers) => {
        dispatch(A.PROGRESS_INDICATOR_TOGGLE);
        dispatch(A.LAYERS_CONFIG_PROGRESS_INDICATOR_TOGGLE);
        Logger.info(i18n.global.t('logMessages.cswServiceConnected'));
        dispatch(A.LAYERS_CONFIG_SET_AVAILABLE_LAYERS, availableLayers);
        dispatch(A.PROGRESS_INDICATOR_END);
      },
      errorCallback: (error) => {
        dispatch(A.PROGRESS_INDICATOR_TOGGLE);
        dispatch(A.LAYERS_CONFIG_PROGRESS_INDICATOR_TOGGLE);
        Logger.error(error);
        DialogUtils.errorNotification(error);
        dispatch(A.PROGRESS_INDICATOR_END);
      }
    });
  },
  [A.LAYERS_CONFIG_SET_AVAILABLE_LAYERS]({commit}, availableLayers) {
    commit(A.LAYERS_CONFIG_SET_AVAILABLE_LAYERS, availableLayers);
  },
  [A.LAYERS_CONFIG_CLEAR_CSW_SERVICE_CONNECTION]({commit}) {
    commit(A.LAYERS_CONFIG_CLEAR_CSW_SERVICE_CONNECTION);
  },
  [A.LAYERS_CONFIG_TOGGLE_CONNECTOR_AND_AVAILABLE_LAYERS]({commit}) {
    commit(M.LAYERS_CONFIG_TOGGLE_CONNECTOR_AND_AVAILABLE_LAYERS);
  },
  [A.LAYERS_CONFIG_OPEN_STORE_LAYER_DIALOG]({commit}, layerConfig) {
    commit(M.LAYERS_CONFIG_OPEN_STORE_LAYER_DIALOG, layerConfig);
  },
  [A.LAYERS_CONFIG_CANCEL_STORE_LAYER_DIALOG]({commit}) {
    commit(M.LAYERS_CONFIG_CANCEL_STORE_LAYER_DIALOG);
  },
  [A.LAYERS_CONFIG_ADD_LAYER_CONFIGURATION]({dispatch}, layerFormConfig) {
    const layerConfig = getLayerConfigurationFromFormFields(layerFormConfig);
    GeoserverLayersService.createGeoserverLayerConfiguration({
      layerConfig,
      successCallback: () => {
        dispatch(A.LAYERS_CONFIG_CANCEL_STORE_LAYER_DIALOG);
        dispatch(A.PROGRESS_INDICATOR_TOGGLE);
        Logger.info(i18n.global.t('logMessages.gisLayerAdded'));
        dispatch(A.PROGRESS_INDICATOR_END);
        PopupUtils.success(i18n.global.t('popupMessages.gisLayerAdded'));
        dispatch(A.GEOSERVER_LAYERS_ADD_LAYER, layerConfig);
      },
      errorCallback: (error) => {
        dispatch(A.LAYERS_CONFIG_CANCEL_STORE_LAYER_DIALOG);
        dispatch(A.PROGRESS_INDICATOR_TOGGLE);
        const errorMessage = i18n.global.t('errorMessages.gisLayerAddError', {error})
        Logger.error(errorMessage);
        DialogUtils.errorNotification(errorMessage);
        dispatch(A.PROGRESS_INDICATOR_END);
      }
    });
  },
  [A.LAYERS_CONFIG_PROGRESS_INDICATOR_TOGGLE]({commit}) {
    commit(M.LAYERS_CONFIG_PROGRESS_INDICATOR_TOGGLE);
  },
};

const mutations = {
  [M.LAYERS_CONFIG_CANCEL_EDIT](state) {
    state.currentConfiguration = JSON.parse(JSON.stringify(state.originalConfiguration));
  },
  [M.LAYERS_CONFIG_UPDATE_CSW_SERVICE_PROPERTY](state, config) {
    state.currentConfiguration.cswServiceConfig[config.name] = config.value;
  },
  [M.LAYERS_CONFIG_SET_AVAILABLE_LAYERS](state, availableLayers) {
    state.currentConfiguration.isCswServiceConnected = true;
    state.currentConfiguration.availableLayers = JSON.parse(availableLayers);
  },
  [M.LAYERS_CONFIG_CLEAR_CSW_SERVICE_CONNECTION](state) {
    state.currentConfiguration.isCswServiceConnected = false;
    state.currentConfiguration.cswServiceConfig.endpoint = null;
    state.currentConfiguration.cswServiceConfig.user = null;
    state.currentConfiguration.cswServiceConfig.password = null;
    state.currentConfiguration.availableLayers = [];
  },
  [M.LAYERS_CONFIG_TOGGLE_CONNECTOR_AND_AVAILABLE_LAYERS](state) {
    state.currentConfiguration.isImportFromCSWButtonClicked = !state.currentConfiguration.isImportFromCSWButtonClicked;
  },
  [M.LAYERS_CONFIG_OPEN_STORE_LAYER_DIALOG](state, layerConfig) {
    state.currentConfiguration.isStoreDialogOpen = true;
    state.currentConfiguration.layerConfigToBeStored.title = layerConfig.title;
    state.currentConfiguration.layerConfigToBeStored.onlineReference = layerConfig.onlineReference;
  },
  [M.LAYERS_CONFIG_CANCEL_STORE_LAYER_DIALOG](state) {
    state.currentConfiguration.isStoreDialogOpen = false;
    state.currentConfiguration.layerConfigToBeStored.title = null;
    state.currentConfiguration.layerConfigToBeStored.onlineReference = null;
  },
  [M.LAYERS_CONFIG_PROGRESS_INDICATOR_TOGGLE](state) {
    state.currentConfiguration.displayProgressIndicator = !state.currentConfiguration.displayProgressIndicator;
  },
};

const getters = {
  getIsCswServiceConnected: state => state.currentConfiguration.isCswServiceConnected,
  getCurrentConfigAttributeValue: state => name => state.currentConfiguration.cswServiceConfig[name],
  getCurrentConfigCswServiceConfig: state => state.currentConfiguration.cswServiceConfig,
  getAvailableLayers: state => state.currentConfiguration.availableLayers,
  shouldDisplayImportFromCswButton: state => !state.currentConfiguration.isImportFromCSWButtonClicked,
  getIsStoreLayerDialogOpen: state => state.currentConfiguration.isStoreDialogOpen,
  getLayerConfigToBeStored: state => state.currentConfiguration.layerConfigToBeStored,
  shouldDisplayProgressIndicator: state => state.currentConfiguration.displayProgressIndicator,
};

export default {
  state,
  actions,
  getters,
  mutations,
};