<template>
    <div class="progressIndicator"  :style="{ backgroundImage: 'url(' + this.progressSrc() + ')'}"></div>
</template>

<script>

  export default {
    name: 'ProgressIndicator',

    computed: {
      displayProgressIndicator() {
        return this.$store.state.workspaceStore.progressIndicator;
      },
    },
    methods: {
      progressSrc() {
        return 'icons/progressIndicator.gif';
      },
    }

  }

</script>

<style src="./progressIndicator.css"></style>