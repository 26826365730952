import A from '../../constants/actions';
import M from '../../constants/mutations';
import sound from '../../assets/sounds/notification.wav';
import PopupUtils from '../../utils/PopupUtils';
import Store from '../';
import AppConfig from '../../config/appConfig';
import MessageUtils from '../../utils/MessageUtils';
import FeatureUtils from '../../utils/FeatureUtils';
import DronePlanUtils from "../../utils/dronePlans/DronePlanUtils";
import Features from '../../config/features';

const state = {
  lastNotification: Date.now(),
  enabled: false,
  notificationSound: null,
  mute: true,
};

const actions = {

  [A.NOTIFICATION_STATUS_TOGGLE]({commit}, status) {
    commit(M.NOTIFICATION_STATUS_TOGGLE, status);
  },

  [A.NOTIFICATION_NEW_MESSAGE]({dispatch}, message) {
    dispatch(A.NOTIFICATION_SOUND_NOTIFICATION, message);
    dispatch(A.NOTIFICATION_SOUND_TASK_TAB_SOUND_NOTIFICATION, message);
    dispatch(A.NOTIFICATION_VISUAL_NOTIFICATION, message);
  },

  [A.NOTIFICATION_SOUND_TOGGLE]({commit}) {
    if (state.mute) {
      commit(M.NOTIFICATION_SOUND_UNMUTE);
    } else {
      commit(M.NOTIFICATION_SOUND_MUTE);
    }
  },

  [A.NOTIFICATION_SOUND_SET]({commit}, isGeneralSoundEnabled) {
    if (isGeneralSoundEnabled) {
      commit(M.NOTIFICATION_SOUND_UNMUTE);
    } else {
      commit(M.NOTIFICATION_SOUND_MUTE);
    }
  },

  [A.NOTIFICATION_SOUND_NOTIFICATION]({commit, state}, message) {
    const audioNotificationProducts = Store.state.viewStore.currentView.notifications
      .find(notificationType => notificationType.type === 'audio').products;
    if (state.enabled && !state.mute && audioNotificationProducts.includes(message.featureType)) {
      commit(M.NOTIFICATION_SOUND);
    }
  },

  [A.NOTIFICATION_SOUND_TASK_TAB_SOUND_NOTIFICATION]({commit, state}, message) {
    const soundEnabled = state.enabled && !state.mute;
    const taskTabSoundNotificationEnabled = Store.getters.getCurrentViewTaskTabSoundNotificationEnabled;
    const isNewTaskTabDronePlan = DronePlanUtils.isDronePlanWaitingApprovalRequest(message) ||
      DronePlanUtils.isDronePlanWaitingTakeoffClearanceRequest(message);
    if (soundEnabled && taskTabSoundNotificationEnabled && isNewTaskTabDronePlan) {
      commit(M.NOTIFICATION_SOUND);
    }
  },

  [A.NOTIFICATION_VISUAL_NOTIFICATION](message) {
    const popupNotification = AppConfig.features.popups &&
      Store.state.viewStore.currentView.notifications.find(notificationType => notificationType.type === 'popup');
    if (popupNotification && popupNotification.products.includes(message.featureType)) {
      const popUpCssClass = message.color ? MessageUtils.getColorText(message.color) : null;
      const feature = Features.getFeature(message.featureType);
      const featureType =
        feature.getNotificationMessage
        ? feature.getNotificationMessage(message) : message.featureType.toUpperCase();
      const messageText = '' + featureType;

      if (popUpCssClass) {
        PopupUtils.showAlert(messageText, 'alert-' + popUpCssClass);
      } else {
        const featureColor = FeatureUtils.Styling.getFeatureColor(message.featureType);
        PopupUtils.showAlertWithBG(messageText, featureColor);
      }
    }
  }

};

const mutations = {

  [M.NOTIFICATION_STATUS_TOGGLE](state, status) {
    state.enabled = status;
  },

  [M.NOTIFICATION_SOUND_UNMUTE](state) {
    state.mute = false;
    state.notificationSound = new Audio(sound);
    state.notificationSound.load();
    state.notificationSound.muted = false;
  },

  [M.NOTIFICATION_SOUND_MUTE](state) {
    state.mute = true;
    if (state.notificationSound) {
      state.notificationSound.muted = true;
    }
  },

  [M.NOTIFICATION_SOUND](state) {
    if (Date.now() - state.lastNotification > AppConfig.ui.soundNotificationTimeout) {
      state.notificationSound.load();
      state.notificationSound.play();
      state.lastNotification = Date.now();
    }
  }
};

export default {
  state,
  actions,
  mutations,
};
