import A from '../../constants/actions';
import M from '../../constants/mutations';
import AlertReplyDetailsUtils from "../../utils/AlertReplyDetailsUtils";

const state = {
  isOpen: false,
  replyMessage: null,
  alertMessage: null,
};

const actions = {
  [A.ALERT_REPLY_DETAILS_SHOW]({commit}, alertMessage) {
    commit(M.ALERT_REPLY_DETAILS_SHOW, alertMessage);
  },

  [A.ALERT_REPLY_DETAILS_CANCEL]({commit}) {
    commit(M.ALERT_REPLY_DETAILS_CANCEL);
  },

  [A.ALERT_REPLY_DETAILS_SET_MESSAGE]({commit}, newReplyMessage) {
    commit(M.ALERT_REPLY_DETAILS_SET_MESSAGE, newReplyMessage);
  },

  [A.ALERT_REPLY_DETAILS_SEND]({commit}) {
    commit(M.ALERT_REPLY_DETAILS_SEND);
  },
};

const mutations = {
  [M.ALERT_REPLY_DETAILS_SHOW](state, alertMessage) {
    state.isOpen = true;
    state.replyMessage = '';
    state.alertMessage = alertMessage;
  },

  [M.ALERT_REPLY_DETAILS_CANCEL](state) {
    state.isOpen = false;
    state.replyMessage = null;
    state.alertMessage = null;
  },

  [M.ALERT_REPLY_DETAILS_SET_MESSAGE](state, newReplyMessage) {
    state.replyMessage = newReplyMessage;
  },

  [M.ALERT_REPLY_DETAILS_SEND](state) {
    const alertReply = AlertReplyDetailsUtils.buildAlertReply(state.alertMessage, state.replyMessage);
    AlertReplyDetailsUtils.sendAlertReply(alertReply);
    state.isOpen = false;
    state.replyMessage = null;
    state.alertMessage = null;
  },
};

const getters = {
  alertReplyDetailsMessage: state => state.replyMessage,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
