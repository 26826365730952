<template>
  <div :class="['nav-button',  'task-button', taskTabIsOpened ? 'selectedTab' : ''].join(' ')"
       :style="buttonStyle"
       :key="this.$store.state.informationStore.forceRenderCount"
       id="task-button-id" @click="toggleTaskTab">
    <span>{{ $t('labels.operationPlan.tasks') }}</span>
    <div class="tasks-counter">
      <span>
        {{ messageNumber }}
      </span>
    </div>
  </div>
</template>

<script>
import A from '../../../constants/actions';
import {mapGetters} from "vuex";
import TaskPanelSubTabsConfig from "../../../config/TaskPanelSubTabsConfig";
import GlobalConfigurationUtils from "../../../utils/GlobalConfigurationUtils";
import GlobalConfigurationIdConfig from "../../../config/GlobalConfigurationIdConfig";
import FeatureUtils from "../../../utils/FeatureUtils";

export default {
  name: 'TaskButton',
  props: ['messageNumber'],
  computed: {
    ...mapGetters({
      taskTabIsOpened: 'taskTabIsOpened',
    }),
    buttonStyle() {
      const taskSubTabColor = GlobalConfigurationUtils.getDisplayConfigColorByIdAndByCurrentTheme(
        GlobalConfigurationIdConfig.DISPLAY_CONFIGURATION_IDS.TASKS_SUBTAB
      );
      if (this.taskTabIsOpened) {
        return FeatureUtils.Styling.computeColorAndBgColorStyleByBgColor(taskSubTabColor)
      }
      if (TaskPanelSubTabsConfig.isTaskPanelButtonHighlighted()){
        const taskTabColor = GlobalConfigurationUtils.getDisplayConfigColorByIdAndByCurrentTheme(
          GlobalConfigurationIdConfig.DISPLAY_CONFIGURATION_IDS.TASKS_TAB
        );
        return FeatureUtils.Styling.computeColorAndBgColorStyleByBgColor(taskTabColor)
      }
      return undefined;
    }
  },
  methods: {
    toggleTaskTab() {
      this.$store.dispatch(A.INFORMATION_PANEL_TOGGLE_TASK_TAB);
      this.$store.dispatch(A.MAP_DESELECT_ALL_FEATURES);
    },
  }
}

</script>