<template>
  <button
    id="remove-button-img-id"
    :class="[this.isDisabled ? 'disabled-button btn btn-primary' : 'btn btn-primary']"
    @click="removeMessage"
    v-if="removable && getImg"
  >
    <img class="infoPanelButton" v-bind:src="getImg('remove')">
  </button>
  <button
    id="remove-button-text-id"
    :class="[this.isDisabled ? 'disabled-button btn btn-primary utmButton' : 'btn btn-primary utmButton']"
    @click="removeMessage"
    v-else-if="removable && getText"
  >
    {{ getText }}
  </button>
</template>
<script>

import A from '../../../constants/actions';
import FeatureType from '../../../constants/featureType';
import PermissionUtils from "../../../utils/PermissionUtils";
import HudTabName from "../../../constants/hudTabName";

export default {
  name: 'RemoveButton',
  props: ['message', 'getImg', 'removable', 'getText'],


  computed: {
    isDisabled() {
      return PermissionUtils.isReadOnlyUser();
    }
  },

  methods: {
    removeMessage() {
      PermissionUtils.performActionIfAllowed(this.isDisabled, this.performRemoveAction);
    },
    performRemoveAction() {
      this.$store.dispatch(A.INFORMATION_PANEL_MESSAGE_CLEANUP, [this.message]);
      if (this.$store.getters.isGivenTabOpened(HudTabName.UTM_PANEL)) {
        this.$store.dispatch(A.UTM_PANEL_DESELECT_ELEM);
      }
      if (this.message.featureType.indexOf('operationplan') !== -1) {
        const droneIdsToRemove = this.$store.state.informationStore.messages.filter((msg) => msg.featureType === FeatureType.DRONE_ALERT &&
          this.message.id === msg.operationPlanIds[0]);
        this.$store.dispatch(A.INFORMATION_PANEL_MESSAGE_CLEANUP, droneIdsToRemove);
      }
    },
  },
};
</script>
