import AppConfig from '../config/appConfig';
import AjaxUtils from '../utils/AjaxUtils';
import {i18n} from '../internationalization/index';

const aorConfigurationURL = AppConfig.server.serviceUrlRoot + '/aorConfiguration';

function saveUtmConfiguration(config) {
  const aorConfig = config.configuration;
  aorConfig.providerId = AppConfig.providerId;

  return fetch(
    AjaxUtils.setParameters(aorConfigurationURL,
      [
        AjaxUtils.getUserNameParam(),
      ]),
    {
      method: 'put',
      headers: AjaxUtils.getHeaders(),
      body: JSON.stringify(config.configuration),
    })
    .then(response => {
      if (response.status !== 200 && response.status !== 201) {
        response.text().then(data => {
          const errorMessage = AjaxUtils.parseErrorMessage(data);
          if (response.status === 408) {
            config.errorCallback(i18n.global.t('errorMessages.utmConfigurationTimeoutError') + ' ' + errorMessage);
          } else {
            config.errorCallback(errorMessage);
          }
        });
      } else {
        response.text().then(data => {
          config.successCallback(data);
        });
      }
    })
    .catch(error => config.errorCallback(AjaxUtils.parseErrorMessage(error)));
}

export default {
  saveUtmConfiguration
}