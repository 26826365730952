import A from '../constants/actions';
import AppConfig from '../config/appConfig';
import PermissionUtils from '../utils/PermissionUtils';
import Store from '../store';

let tabsProps = null;

function getTabProps() {
  if (tabsProps === null) {
    const FeatureUtils = require('../utils/FeatureUtils').default;
    tabsProps = [
      {
        id: 'labels.configurationPanel.view.viewName',
        name: 'ViewConfiguration',
        isVisible: PermissionUtils.isSmartSISUser(),
        storeName: 'viewStore',
        originalContentName: 'originalView',
        currentContentName: 'currentView',
        resetContentActionName: A.VIEW_CANCEL_EDIT,
        compare(originalView, currentView) {
          if (!PermissionUtils.isAllowedToEditViews()) {
            return false;
          }
          const currentViewWithoutViewPort = JSON.parse(JSON.stringify(currentView));
          const originalViewWithoutViewPort = JSON.parse(JSON.stringify(originalView));

          delete currentViewWithoutViewPort.viewPort;
          delete originalViewWithoutViewPort.viewPort;
          return JSON.stringify(currentViewWithoutViewPort) !== JSON.stringify(originalViewWithoutViewPort);
        }
      }, {
        id: 'labels.configurationPanel.theme.themeName',
        name: 'ThemeConfiguration',
        isVisible: PermissionUtils.isSmartSISUser(),
        storeName: 'themeStore',
        originalContentName: 'originalTheme',
        currentContentName: 'currentTheme',
        resetContentActionName: A.THEME_CANCEL_EDIT,
      },
      {
        id: 'labels.configurationPanel.groupAndRole.groupAndRoleName',
        name: 'GroupRoleConfiguration',
        isVisible: PermissionUtils.isSupervisor() || PermissionUtils.isSuperAdmin(),
        storeName: 'userStore',
        originalContentName: 'originalGroup',
        currentContentName: 'currentGroup',
        resetContentActionName: A.USER_MANAGEMENT_GROUP_CANCEL_EDIT,
      },
      {
        id: 'labels.configurationPanel.thresholds.thresholdsName',
        name: 'ThresholdsConfiguration',
        isVisible: PermissionUtils.isSuperAdmin() && AppConfig.ui.enableThresholds &&
          FeatureUtils.Access.hasAccessToMetarFeature(),
        storeName: 'thresholdStore',
        originalContentName: 'originalThreshold',
        currentContentName: 'currentThreshold',
        resetContentActionName: A.THRESHOLD_CANCEL_EDIT,
      },
      {
        id: 'labels.configurationPanel.notamFilter.notamFilterName',
        name: 'NotamFilterConfiguration',
        isVisible: (PermissionUtils.isSupervisor() || PermissionUtils.isSuperAdmin()) &&
          FeatureUtils.Access.hasAccessToNotamFeature(),
        storeName: 'notamFilterStore',
        originalContentName: 'originalFilter',
        currentContentName: 'currentFilter',
        resetContentActionName: A.NOTAMFILTER_CANCEL_EDIT,
      },
      {
        id: 'labels.configurationPanel.utm.utmName',
        name: 'UtmConfiguration',
        isVisible: PermissionUtils.isUser() || PermissionUtils.isSupervisor() || PermissionUtils.isSuperAdmin(),
        storeName: 'utmConfigurationStore',
        originalContentName: 'originalConfiguration',
        currentContentName: 'currentConfiguration',
        resetContentActionName: A.UTM_CONFIG_CANCEL_EDIT,
      },
      {
        id: 'labels.configurationPanel.global.globalName',
        name: 'GlobalConfigurations',
        isVisible: PermissionUtils.isUser() || PermissionUtils.isSupervisor() || PermissionUtils.isSuperAdmin(),
        storeName: 'globalConfigurationsStore',
        originalContentName: 'originalConfiguration',
        currentContentName: 'currentConfiguration',
        resetContentActionName: A.GLOBAL_CONFIGURATION_CANCEL_EDIT,
      },
      {
        id: 'labels.configurationPanel.gisLayers.gisLayersName',
        name: 'GeoserverLayersConfig',
        isVisible: PermissionUtils.isSupervisor() || PermissionUtils.isSuperAdmin(),
        storeName: 'layerConfigurationStore',
        originalContentName: null,
        currentContentName: null,
        resetContentActionName: A.LAYERS_CONFIG_CANCEL_EDIT,
      },
    ];
  }
  return tabsProps;
}

function getOriginalContentOfTab(tabName) {
  if (tabsProps !== null) {
    const tabConfig = tabsProps.filter(tab => tab.name === tabName)[0];
    const storeName = tabConfig.storeName;
    const originalContentName = tabConfig.originalContentName;
    return Store.state[storeName][originalContentName];
  }
}

function getCurrentContentOfTab(tabName) {
  if (tabsProps !== null) {
    const tabConfig = tabsProps.filter(tab => tab.name === tabName)[0];
    const storeName = tabConfig.storeName;
    const currentContentName = tabConfig.currentContentName;
    return Store.state[storeName][currentContentName];
  }
}

function canChangeTab(tabName) {
  const tabConfig = tabsProps !== null ? tabsProps.filter(tab => tab.name === tabName)[0] : null;
  const originalContentOfTab = getOriginalContentOfTab(tabName);
  const currentContentOfTab = getCurrentContentOfTab(tabName);
  return tabConfig && tabConfig.compare ? !tabConfig.compare(originalContentOfTab, currentContentOfTab) :
    JSON.stringify(originalContentOfTab) === JSON.stringify(currentContentOfTab);
}

function resetContentOfTabToOriginal(tabName) {
  if (tabsProps !== null) {
    const resetContentActionName = tabsProps.filter(tab => tab.name === tabName)[0].resetContentActionName;
    Store.dispatch(resetContentActionName);
  }
}

export default {
  getTabProps,
  canChangeTab,
  resetContentOfTabToOriginal,
}