<template>
  <div>
    <div class="card smartSISPanel h-100">
      <h5 class="card-header">{{ $t('labels.configurationPanel.view.notifications') }}</h5>
      <div class="mx-auto m-2 notificationButtons">
        <template v-for="notificationType in notificationTypes" :key="notificationType.id">
          <button
            type="btn btn-primary"
            :id="'notification_type_' + notificationType.id"
            :class="['list-group-item', isSelectedType(notificationType) ? 'selected' : ''].join(' ')"
            @click="setSelectedNotificationType(notificationType)"
          >
            <img class="notificationIcon" v-bind:src="getButtonImg(notificationType.name)">
          </button>
        </template>
      </div>
      <div class="card-body">
        <div :id="selectedNotificationType + '-notification-list'"
             class="list-group viewList"
             v-bind:style="{height: heightSize}">
          <template v-for="productType in productTypes" :key="productType.id">
            <button
              type="button"
              :id="'notification_' + productType.id"
              :class="['list-group-item', 'smartSISPanel', isSelected(productType) ? 'selected' : ''].join(' ')"
              @click="toggleNotificationType(productType)"
            >
              {{ $t(productType.name) }}
            </button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import A from '../../../../constants/actions';
import NotificationsConfig from '../../../../config/notificationsConfig';
import Features from '../../../../config/features';
import ImgUtils from '../../../../utils/ImgUtils';

export default {
  name: 'Notifications',
  computed: {
    notificationTypes() {
      return NotificationsConfig.getNotifications().filter(notificationType => notificationType.visible);
    },
    productTypes() {
      return Features.getDynamicFeatures()
        .filter(feature => !feature.isNotificationsDisabled || this.isSelected(feature));
    },
    heightSize() {
      return this.$store.state.configurationStore.listViewNotificationHeight + 'px';
    },
    selectedNotificationType() {
      return this.$store.state.viewStore.selectedNotificationType;
    }
  },
  methods: {
    isSelectedType(notificationType) {
      return this.selectedNotificationType === notificationType.id;
    },
    isSelected(notification) {
      const products = this.$store.state.viewStore.currentView.notifications
        .find((not) => not.type === this.selectedNotificationType).products;
      return products.indexOf(notification.id) > -1;
    },
    setSelectedNotificationType(notificationType) {
      this.$store.dispatch(A.VIEW_EDITOR_SELECT_NOTIFICATION_TYPE, notificationType.id);
    },
    toggleNotificationType(layer) {
      this.$store.dispatch(A.VIEW_EDITOR_TOGGLE_NOTIFICATION_TYPE, layer.id);
    },
    getButtonImg(name) {
      return ImgUtils.getButtonImg(name, 'svg');
    },
  },

}
</script>
<style src="./viewEditor.css"/>