import FeatureType from "../../constants/featureType";
import FeatureUtils from "../../utils/FeatureUtils";
import ThemeElements from "../../constants/theme-elements";
import Feature from "ol/Feature";
import Point from 'ol/geom/Point';
import {v4 as uuidv4} from 'uuid';
import Moment from "moment";

export default {

  id: FeatureType.METEOPROBE,
  name: 'features.meteoprobe',
  staticDataType: [FeatureType.RESPONSIBILITY_AREA, FeatureType.AIRSPACE],
  maxNrOfFeaturesPerLocation: 200,
  getReplaceableMessages: FeatureUtils.Filters.filterById,
  featureIdentifier: 'id',
  isViewPortBased: false,
  isFlightLevelBased: false,
  maxResolution: 10000,
  declutter: false,
  zIndex: 1,
  flightLevel: null,
  theme: {
    elements: [
      ThemeElements.SHOW_TEXT,
      ThemeElements.BORDER_COLOR,
      ThemeElements.FILL_COLOR,
      ThemeElements.TEXT_COLOR,
      ThemeElements.TEXT_SIZE,
      ThemeElements.TEXT_BG_COLOR,
      ThemeElements.IMAGE_COLOR,
    ]
  },
  displayOnMap: true,
  displayInMessages: false,

  geoJsonMapper: (feature) => {
    return new Feature({
      id: uuidv4(),
      featureType: feature.featureType,
      geometry: new Point([feature.position3D.longitude.value, feature.position3D.latitude.value]),
      altitude: {
        value: feature.position3D.altitude.value,
        uom: feature.position3D.altitude.uom
      },
      temperature: {
        value: feature.weather.temperature.value,
        uom: feature.weather.temperature.uom
      },
      wind: {
        speed: {
          value: feature.weather.wind.speed.value,
          uom: feature.weather.wind.speed.uom
        },
        direction: {
          value: feature.weather.wind.direction.value,
          uom: feature.weather.wind.direction.uom
        },
      },
      pressure: {
        value: feature.weather.pressure.value,
        uom: feature.weather.pressure.uom
      },
      observationTime: Moment.utc(feature.observationtime).format('HH:mm:ss')
    });
  },
}