
export default class GeozoneConfigUtils {
  static constructAuthorityDetails(authorityDetails) {
    let zoneAuthority = {
      name: '',
      service: '',
      contactName: '',
      siteURL: '',
      email: '',
      phone: '',
      purpose: 'INFORMATION'
    };
    const details = authorityDetails.split('||');
    details.forEach(details => {
      const splittedDetails = details.split("::");
      zoneAuthority[splittedDetails[0]] = splittedDetails[1];
    });
    return zoneAuthority;
  }

  static hasValidAuthorityDetails(geozone) {
    const zoneAuthority = geozone.zoneAuthority[0];
    return zoneAuthority && zoneAuthority.name &&
      (zoneAuthority.contactName || zoneAuthority.phone || zoneAuthority.email || zoneAuthority.siteURL);
  }
}