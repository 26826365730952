import {Fill, Stroke, Style} from "ol/style";
import CommonStyleFunction from "../../CommonStyleFunction";

export default class DronePlanOuterBoxGeometryStyleFunction {

  static buildOuterBoxGeometryStyle(featureProperties) {
    const outerBoxGeometry = featureProperties.feature.getProperties().outerBoxGeometry;
    return new Style({
      stroke: new Stroke({
        color: CommonStyleFunction.toRgba(featureProperties.colorConfigs.borderConfig.color),
        width: featureProperties.colorConfigs.borderConfig.width,
        lineDash: [10, 15]
      }),
      fill: new Fill({color: CommonStyleFunction.toRgba(featureProperties.colorConfigs.fillColor)}),
      zIndex: 30,
      geometry: outerBoxGeometry.clone().transform('EPSG:4326', 'EPSG:3857')
    });
  }
}