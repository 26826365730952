<template>
  <div id="entries-configuration-container" class="listpanel col-2" v-bind:style="{height: heightSize}">
    <div
      :key="$t(entry.name) + (entry.id) ? entry.id : '' "
      class="row m-0"
      style="flex-wrap: nowrap" v-for="entry in entries">
      <div
        :class="['p-0', 'm-0', 'w-100', 'hideOverflow']">
        <button
          :class="entryStyle(entry)"
          :id="idPrefix + '_' + $t(entry.name)"
          @click="(entry.disabled) ? null : onSelectEntry(entry)"
        >
          {{ $t(entry.name) }}
        </button>
      </div>
      <div class="text-right p-0 m-0" v-if="themeConfig(entry)">
        <button
          :class="['list-group-item', 'defaultButton',
                isDefaultTheme(entry.id)? 'disabledDefaultButton' : '', 'smartSISPanel',
                isDefault(entry.id) ? 'selectedDefault' : '']"
          :disabled="isDefaultTheme(entry.id)"
          :id="'defaultTheme_' + $t(entry.name)"
          @click="toggleDefault(entry)"
          type="btn btn-primary"
        >
          <span>
            <img class="pinImg" v-bind:src="getButtonImg('pin')">
          </span>
          <span class="defaultText">
            {{ $t("labels.common.default") }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import ImgUtils from '../../../utils/ImgUtils';
import A from '../../../constants/actions';
import ThemeService from '../../../services/smartSisThemeService';
import DialogUtils from '../../../utils/DialogUtils';
import PopupUtils from '../../../utils/PopupUtils';
import Logger from "../../../utils/LoggerUtils";

export default {
  name: 'ListPanel',
  props: ['entries', 'idPrefix', 'entryStyle', 'onSelectEntry'],
  computed: {
    heightSize() {
      return this.$store.state.configurationStore.listPanelHeight + 'px';
    },
  },
  methods: {
    getButtonImg(name) {
      return ImgUtils.getButtonImg(name, 'svg');
    },
    themeConfig(entry) {
      return !!entry.stylingConfigs;
    },
    isDefault(themeId) {
      return this.$store.state.themeStore.defaultThemeForUser ?
        this.$store.state.themeStore.defaultThemeForUser === themeId : false;
    },
    isDefaultTheme(themeId) {
      return themeId === 'Default';
    },
    toggleDefault(theme) {
      const themeAndUser = {
        themeId: theme.id,
        username: this.$store.state.userStore.userProfile.username,
      };

      const config = {
        themeId: theme.id,
        successCallback: () => {
          this.$store.dispatch(A.THEME_CHANGE_DEFAULT_THEME, themeAndUser.themeId);
          PopupUtils.success(this.$i18n.t('popupMessages.defaultThemeSaved'));
          Logger.info(this.$i18n.t('logMessages.defaultThemeSaved', {
            themeId: themeAndUser.themeId,
            username: themeAndUser.username
          }));
        },
        errorCallback: (error) => {
          const errorMessage = error;
          Logger.error(this.$i18n.t('errorMessages.errorSavingDefaultTheme', {
            themeId: themeAndUser.themeId,
            username: themeAndUser.username,
            error
          }));
          DialogUtils.errorNotification(errorMessage.errorMessage);
        },
      };
      ThemeService.saveDefaultTheme(config);
    }
  }
}
</script>
<style src="./listPanel.css"></style>