<template>
  <div class="container-fluid">
    <ListHeader
      :elements="elements"
      :headers="headers"
      :onSort="onSort"
      :reverse="reverse"
      :buttons="buttons"
      :sortCriteria="sortCriteria"
      :model="model"
    ></ListHeader>
    <ListBody
      :elements="elements"
      :targetedMessage="targetedMessage"
      :model="model"
      :onSelect="onSelect"
      :listHeight="listHeight"
    ></ListBody>
  </div>
</template>
<script>
import ListHeader from './ListHeader';
import ListBody from './ListBody';

export default {
  name: 'List',
  props: ['model', 'elements', 'onSelect', 'onSort', 'sortCriteria', 'reverse', 'listHeight', 'targetedMessage'],
  components: {
    ListHeader,
    ListBody,
  },
  computed: {
    headers() {
      return this.model.fields.filter(f => f.displayInList).map(f => {
        return {name: f.name, tableStyle: f.tableStyle, propertyName: f.propertyName}
      });
    },
    buttons() {
      return this.model.buttons;
    }
  },
}
</script>