import A from '../../constants/actions';
import M from '../../constants/mutations';

const state = {
  isOpen: false,
  message: null,
};

const actions = {
  [A.AOR_RESTRICTION_DIALOG_OPEN]({commit}, message) {
    commit(M.AOR_RESTRICTION_DIALOG_OPEN, message);
  },

  [A.AOR_RESTRICTION_DIALOG_CLOSE]({commit}) {
    commit(M.AOR_RESTRICTION_DIALOG_CLOSE);
  }
};

const mutations = {
  [M.AOR_RESTRICTION_DIALOG_OPEN](state, message) {
    state.isOpen = true;
    state.message = message;
  },

  [M.AOR_RESTRICTION_DIALOG_CLOSE](state) {
    state.isOpen = false;
    state.message = null;
  }
};

const getters = {
  isAorRestrictionDialogOpen: state => state.isOpen,
  aorRestrictionDialogMessage: state => state.message
};

export default {
  state,
  actions,
  mutations,
  getters
};
