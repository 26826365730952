import Store from '../store';
import A from '../constants/actions';
import {i18n} from '../internationalization/index';

export default class DialogUtils {

  static hideDialog() {
    Store.dispatch(A.DIALOG_HIDE);
  }

  static notification(config, style) {
    const styleClass = style || 'btn-danger';
    const extendedConfig = {
      ...config,
      buttons: [
        {
          id: 'button-ok',
          label: i18n.global.t('labels.common.ok'),
          action: DialogUtils.hideDialog,
          style: styleClass,
        }
      ]
    };

    Store.dispatch(A.DIALOG_SHOW, extendedConfig);
  }

  static errorNotification(message) {
    DialogUtils.notification(
      {
        title: i18n.global.t('labels.common.error'),
        message,
      }
    );
  }

  static warningNotification(message) {
    DialogUtils.notification(
      {
        title: i18n.global.t('labels.common.warning'),
        message,
      },
      'btn-warning'
    );
  }

  static confirmOperation(config) {

    const extendedConfig = {
      title: i18n.global.t('confirmationMessages.title'),
      message: config.message,
      buttons: [
        {
          id: 'button-yes',
          label: i18n.global.t('labels.common.yes'),
          action: () => {
            config.yesAction();
            DialogUtils.hideDialog();
          },
          style: 'btn-danger',
        },
        {
          id: 'button-no',
          label: i18n.global.t('labels.common.no'),
          action: () => {
            if (config.noAction) {
              config.noAction();
            }
            DialogUtils.hideDialog();
          },
        }
      ]
    };

    Store.dispatch(A.DIALOG_SHOW, extendedConfig);
  }

  static showCustomComponentDialog(component, props) {
    Store.dispatch(A.DIALOG_SHOW, {component, props});
  }
}