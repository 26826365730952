import LineString from "ol/geom/LineString";
import {MultiPoint} from "ol/geom";

export default class CoordinateUtils {

  static normalize(coordinatesStr) {

    const format = (str, length) => {
      str = '00' + str;
      return str.slice(str.length - length - 1, -1);
    };

    let parts = coordinatesStr.trim().split(' ');
    parts = parts.map((part, index) => (index !== 3 && index !== 7) ? format(part, (index !== 4) ? 2 : 3) : part);

    return {
      lat: parts.slice(0, 4).join(''),
      lon: parts.slice(4, parts.length).join('')
    };
  }

  static computeAngle(start, end) {
    if (start[0] === end[0] && start[1] === end[1]) return 1;
    if (start[1] === end[1]) return (start[0] > end[0]) ? -1 * Math.PI / 2 : Math.PI / 2;
    if (start[0] === end[0]) return (start[1] < end[1]) ? 0 : Math.PI;

    const tan = (end[0] - start[0]) / (end[1] - start[1]);
    return (start[1] < end[1]) ? Math.atan(tan) : Math.PI + Math.atan(tan);
  }

  static areCoordinatesEqual(cord1, cord2) {
    return cord1[0] && cord1[1] && cord2[0] && cord2[1] &&
      cord1[0] === cord2[0] && cord1[1] === cord2[1];
  }

  static isValidCoordinate(cord) {
    return cord.length === 2 &&
      cord[0] >= -180 && cord[0] <= 180 && cord[1] >= -90 && cord[1] <= 90;
  }

  static verifyPolygonCoordinates(coordinates) {
    const valid = coordinates.every(cord => {
      return this.isValidCoordinate(cord);
    });
    return valid &&
      this.areCoordinatesEqual(coordinates[0], coordinates[coordinates.length - 1]);
  }

  static constructDroneHistoryGeometryForLineString(feature) {
    const geometry = feature.droneHistory.map(h => h.position);
    geometry.push([feature.p[0], feature.p[1]]);
    return new LineString(this.mapHistoryToCoordinates(geometry))
      .clone().transform('EPSG:4326', 'EPSG:3857');
  }

  static constructDroneHistoryGeometry(feature) {
    return new MultiPoint(this.mapHistoryToCoordinates(feature.droneHistory.map(h => h.position)))
      .clone().transform('EPSG:4326', 'EPSG:3857');
  }

  static mapHistoryToCoordinates(geometry) {
    return geometry
      .map(point => {
        const longitude = point[0];
        const latitude = point[1];
        return longitude && latitude ? [longitude, latitude] : null;
      })
      .filter(point => point !== null);
  }

}