import AjaxUtils from "../utils/AjaxUtils";
import AppConfig from "../config/appConfig";
import Logger from "../utils/LoggerUtils";
import DialogUtils from "../utils/DialogUtils";
import {i18n} from '../internationalization/index';

const serviceUrlRoot = AppConfig.server.serviceUrlRoot;
const getIcaoLocationsForDesignatorsEndpoint = serviceUrlRoot + "/getIcaoLocationsForDesignators";

export default class IcaoLocationsService {

  static async getIcaoLocationsForDesignators(designators) {
    const response = await fetch(
      AjaxUtils.setParameters(getIcaoLocationsForDesignatorsEndpoint, []),
      {
        method: 'post',
        headers: AjaxUtils.getHeaders(),
        body: JSON.stringify(designators)
      });

    if (response.status !== 200) {
      const errorMessage = i18n.global.t('errorMessages.icaoLocationsQueryError');
      Logger.error(errorMessage);
      DialogUtils.errorNotification(errorMessage);
      throw new Error(errorMessage);
    }
    return await response.text();
  }
}