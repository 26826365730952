import {transformExtent} from "ol/proj";
import {transform} from "ol/proj";


export default class ProjectionConverter {

  static convertTo4326 = function (extent) {
    return transformExtent(extent, 'EPSG:3857', 'EPSG:4326');
  };

  static convertTo3857 = function (extent) {
    return transformExtent(extent, 'EPSG:4326', 'EPSG:3857');
  };


  static convertCoordinateTo4326 = function (point) {
    return transform(point, 'EPSG:3857', 'EPSG:4326');
  };

  static convertCoordinateTo3857 = function (point) {
    return transform(point, 'EPSG:4326', 'EPSG:3857');
  };

}

