<template>
  <div id="restrict-aor-button" :class="[this.isDisabled ? 'disabled-nav-button text-red' : 'nav-button text-red']"
       v-if="showRestrictAorButton" @click="restrictAorClicked()">
    <div>
      <span class="nav-icon">
        <img v-bind:src="getButtonImg('prod_drone_restrict')">
      </span>
    </div>
    <span>{{ $t('labels.icaoLocations.restrictAor') }}</span>
  </div>
</template>

<script>
import A from '../../constants/actions';
import ImgUtils from '../../utils/ImgUtils';
import FeatureUtils from "../../utils/FeatureUtils";
import PermissionUtils from "../../utils/PermissionUtils";
import Store from "../../store";

export default {
  name: 'RestrictAorButton',
  computed: {
    showRestrictAorButton() {
      return this.hasAccessToGeozoneFeature && Store.getters.getDisplayOfRestrictAor();
    },
    hasAccessToGeozoneFeature() {
      return FeatureUtils.Access.hasAccessToGeozoneFeature();
    },
    isDisabled() {
      return PermissionUtils.isReadOnlyUser();
    }
  },
  methods: {
    getButtonImg(name) {
      return ImgUtils.getButtonImg(name);
    },
    restrictAorClicked() {
      PermissionUtils.performActionIfAllowed(this.isDisabled, this.performRestrictAorAction);
    },
    performRestrictAorAction() {
      const designator = this.$store.getters.getCurrentResponsibilityAreas;
      if (designator !== undefined) {
        this.$store.dispatch(A.AOR_RESTRICTION_DIALOG_OPEN,
          this.$i18n.t('confirmationMessages.restrictAor', {respArea: designator.join(',')}));
      }
    },
  }
}
</script>