<template>
  <div>
    <div class="card smartSISPanel" id="qcodes">
      <h5 class="card-header">{{ $t('labels.configurationPanel.notamFilter.qCodes') }}</h5>
      <ul class="list-group list-group-flush viewList" v-bind:style="{height: heightSize}">
        <template v-for="qcode in qcodes" :key="qcode">
          <li class="list-group-item smartSISPanel">
            <div class="row">
              <div class="col">
                {{ qcode }}
              </div>
              <div class="col-1 text-right p-0 m-0">
                <button
                  id="remove-qCode"
                  :class="['btn', 'btn-primary', 'rmvBtn'].join(' ')"
                  @click="removeQCode(qcode)"
                >
                  <img class="removeImg" v-bind:src="getButtonImg('remove')">
                </button>
              </div>
            </div>
          </li>
        </template>
      </ul>
      <div class="searchForm form-inline">
        <input
          id="newQcode"
          type="text"
          class="col-8 form-control text-uppercase"
          v-model="newQCode"
          :placeholder=getQCodePlaceholder()
          v-on:keyup.enter="addQCode"
        >
        <button
          id="add-qCode"
          type="submit"
          class="col-4 btn btn-primary"
          @click="addQCode">
          {{ $t('labels.common.add') }}
        </button>
      </div>
      <div class="inclusionControl">
        <div class="custom-control custom-radio custom-control-inline">
          <input
            type="radio"
            id="qCodesRadioAll"
            value="ALL"
            v-model="picked"
            class="custom-control-input">
          <label class="custom-control-label" for="qCodesRadioAll">
            {{ $t('labels.common.all') }}
          </label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input
            type="radio"
            id="qCodesRatioInclude"
            value="INCLUDE"
            v-model="picked"
            class="custom-control-input">
          <label class="custom-control-label" for="qCodesRatioInclude">
            {{ $t('labels.configurationPanel.notamFilter.include') }}
          </label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input
            type="radio"
            id="qCodesRadioExclude"
            value="EXCLUDE"
            v-model="picked"
            class="custom-control-input">
          <label class="custom-control-label" for="qCodesRadioExclude">
            {{ $t('labels.configurationPanel.notamFilter.exclude') }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import A from '../../../../constants/actions';
import ImgUtils from '../../../../utils/ImgUtils';
import DialogUtils from '../../../../utils/DialogUtils';

export default {
  name: 'QCodes',

  data: () => {
    return {
      newQCode: '',
    }
  },

  computed: {
    heightSize() {
      return (this.$store.state.configurationStore.notamFilterPanelHeight - 180) + 'px';
    },
    qcodes() {
      return this.$store.state.notamFilterStore.currentFilter.qCodes;
    },
    picked: {
      get() {
        return this.$store.state.notamFilterStore.currentFilter.qCodeCondition;
      },
      set(value) {
        this.$store.dispatch(A.NOTAMFILTER_UPDATE_PROPERTY, {type: 'qCodeCondition', value: value});
      }
    },
  },
  methods: {
    getButtonImg(name) {
      return ImgUtils.getButtonImg(name, 'svg');
    },
    removeQCode(qCode) {
      this.$store.dispatch(A.NOTAMFILTER_TOGGLE_QCODE, qCode);
    },
    addQCode() {
      let qCode = this.newQCode.trim().toUpperCase();
      if (qCode.length < 2) {
        DialogUtils.errorNotification(this.$i18n.t('errorMessages.qcodeError'));
      } else {
        if (qCode.length < 5 && !qCode.endsWith('*')) {
          qCode = qCode.concat('*');
        }
        const existingQCodes = this.$store.state.notamFilterStore.currentFilter.qCodes || [];
        if (!existingQCodes.includes(qCode)) {
          this.$store.dispatch(A.NOTAMFILTER_TOGGLE_QCODE, qCode);
        }
      }
      this.newQCode = '';
    },
    getQCodePlaceholder() {
      return this.$i18n.t('labels.configurationPanel.notamFilter.qCode') + "...";
    }
  },
}
</script>