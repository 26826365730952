import AppConfig from '../config/appConfig';
import Store from '../store';

import {stringify} from 'flatted';
import {saveAs} from 'file-saver';

import Logger from '../utils/LoggerUtils';
import {i18n} from '../internationalization/index';

export default class DebugUtils {

  static exportAppState() {
    try {
      Logger.info(i18n.global.t('logMessages.exportingApplicationState'));
      const storeDump = new Blob([stringify({
          config: AppConfig,
          store: {
            ...Store.state,
            mapOperations: {},
          },
        }
      )], {
        type: 'application/json',
        name: 'smartSISStore' + Date.now() + '.json',
      });

      saveAs(storeDump);
    } catch (error) {
      Logger.error(i18n.global.t('errorMessages.applicationStateExportError', {error}));
    }
  }

}