export default class DroneInfoUtils {

  static constructPilotName(droneInfo) {
    let pilotName = droneInfo.pilotContactDetails?.firstName ? droneInfo.pilotContactDetails?.firstName + ' ' : '';
    pilotName = pilotName + (droneInfo.pilotContactDetails?.lastName ? droneInfo.pilotContactDetails?.lastName : '');
    return pilotName;
  }

  static constructContact(contact) {
    let contactDetails = contact?.address ? contact?.address + '\n' : '';
    contactDetails = contactDetails + '\n' + (contact?.phone ? contact?.phone + '\n' : '');
    contactDetails = contactDetails + (contact?.email ? contact?.email + '\n' : '');
    return contactDetails.replace(/^\s+|\s+$/g, '');
  }
}