import A from '../../constants/actions';
import M from '../../constants/mutations';
import LayerVisibilityUtils from "../../utils/LayerVisibilityUtils";

const state = {
  layers: [],
};

const actions = {
  [A.GEOSERVER_LAYERS_READ_ALL]({commit, dispatch}, layers) {
    commit(M.GEOSERVER_LAYERS_READ_ALL, layers);
    commit(M.LAYERS_CONFIG_PANEL_ADD_DYNAMIC_LAYERS, layers);
    dispatch(A.THEME_UPDATE_DYNAMIC_LAYER_CONFIGS);
  },
  [A.GEOSERVER_LAYERS_REMOVE_LAYER]({commit}, layerId) {
    commit(M.GEOSERVER_LAYERS_REMOVE_LAYER, layerId);
  },
  [A.GEOSERVER_LAYERS_ADD_LAYER]({commit}, layer) {
    commit(M.GEOSERVER_LAYERS_ADD_LAYER, layer);
  }
};

const mutations = {
  [M.GEOSERVER_LAYERS_READ_ALL](state, layers) {
    state.layers = layers;
  },
  [M.GEOSERVER_LAYERS_REMOVE_LAYER](state, layerId) {
    state.layers = state.layers.filter(layer => layer.id !== layerId);
  },
  [M.GEOSERVER_LAYERS_ADD_LAYER](state, layer) {
    const mappedLayer = {
      id: layer.id,
      dataFormat: layer.dataFormat,
      defaultName: layer.names.default,
      endpoint: layer.endpoint,
      startValidity: layer.startValidity,
      endValidity: layer.endValidity,
      isBaseMap: layer.isBaseMap === 'true',
      visibleForRoles: [...layer.visibleForRoles]
    };
    state.layers.push(mappedLayer);
  }
};

const getters = {
  getAllGeoserverLayers: (state) => {
    return state.layers;
  },
  getGeoserverLayers: (state) => {
    return state.layers.filter(layer => !layer.isBaseMap);
  },
  getGeoserverLayerIds: (state, getters) => {
    return getters.getGeoserverLayers.map(layer => layer.id);
  },
  getGeoserverFeatures: (state, getters) => {
    return getters.getGeoserverLayers
      .filter(layerConfig => LayerVisibilityUtils.isLayerAvailableForAirspaceManagers(layerConfig))
      .map(feature => {
        return {id: feature.id, name: feature.defaultName}
      });
  },
  getBaseMapLayers: (state) => {
    return state.layers.filter(layer =>
      layer.isBaseMap && LayerVisibilityUtils.isLayerAvailableForAirspaceManagers(layer)
    );
  }
};

export default {
  state,
  actions,
  getters,
  mutations,
};
