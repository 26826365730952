import FeatureType from '../../constants/featureType';
import ThemeElements from '../../constants/theme-elements';
import GeoJSON from 'ol/format/GeoJSON';
import Feature from 'ol/Feature';

export default {

  id: FeatureType.AIRPORT_HELIPORT,
  name: 'features.airportHeliport.name',
  isStaticDataType: true,
  abbreviation: 'features.airportHeliport.abbreviation',
  displayOnMap: true,
  displayInMessages: false,
  featureIdentifier: 'id',
  isViewPortBased: false,
  isFlightLevelBased: false,
  maxResolution: 1000,
  declutter: true,
  zIndex: 1,
  flightLevel: null,
  theme: {
    elements: [
      ThemeElements.SHOW_TEXT,
      ThemeElements.TEXT_COLOR,
      ThemeElements.TEXT_SIZE,
      ThemeElements.TEXT_BG_COLOR,
      ThemeElements.IMAGE_COLOR,
      ThemeElements.ICON_SIZE,
    ]
  },

  geoJsonMapper: (feature) => {
    return new Feature({
      geometry: new GeoJSON().readGeometry(feature.location),
      id: feature.timesliceId || feature.id || feature.featureId,
      designator: feature.designator,
      maxResolution: 10000,
      featureType: 'airportheliport'
    });
  },

  color: (colors) => {
    return colors.imageColor;
  },

  defaultStyle: () => {
    return {
      showText: true,
      textSize: 14,
      iconSize: 100,
      colors: {
        borderColor: {r: 203, g: 203, b: 203, a: 0.5},
        fillColor: {r: 255, g: 255, b: 255, a: 0.1},
        textColor: {r: 42, g: 157, b: 244, a: 1},
        textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
        imageColor: {r: 42, g: 157, b: 244, a: 0}
      }
    }
  },

}