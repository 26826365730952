import FeatureUtils from "../../utils/FeatureUtils";
import OPStateColoringConfig from "../../config/OPStateColoringConfig";
import DronePlanUtils from "../../utils/dronePlans/DronePlanUtils";
import Store from "../../store";
import CommonStyleFunction from "../CommonStyleFunction";
import DronePlanTextStyleFunction from "./drone-plan-style-functions/DronePlanTextStyleFunction";
import DronePlanGeometryStyleFunction from "./drone-plan-style-functions/DronePlanGeometryStyleFunction";
import DronePlanIconStyleFunction from "./drone-plan-style-functions/DronePlanIconStyleFunction";
import DronePlanOuterBoxGeometryStyleFunction
  from "./drone-plan-style-functions/DronePlanOuterBoxGeometryStyleFunction";
import DronePlanTrajectoryStyleFunction from "./drone-plan-style-functions/DronePlanTrajectoryStyleFunction";

export default class DronePlanStyleFunction {

  static buildOutlookOperationPlanStyleFunction(stylingConfig, isEmbeddedMap) {
    return (feature, resolution) => {
      const parentFeature = feature.getProperties().features[0];
      const startTime = new Date(parentFeature.getProperties().startTime).getTime();
      return (!!isEmbeddedMap || Store.getters.getDisplayOfUpcomingPlansOnMap()) && DronePlanUtils.isOutlookOpInFilteringTimeInterval(startTime) ?
        operationPlanStyleFunction(feature, resolution, stylingConfig) : null;
    }
  }

  static buildOperationPlanStyleFunction(stylingConfig) {
    return (feature, resolution) => operationPlanStyleFunction(feature, resolution, stylingConfig)
  }
}

function operationPlanStyleFunction(feature, resolution, stylingConfig) {
  const clusteredFeatureProps = feature.getProperties().features
    .map(operationPlan => mapOp(feature, operationPlan, stylingConfig, resolution));
  const parentFeatureProps = clusteredFeatureProps[0];

  if (parentFeatureProps === undefined) {
    return;
  }
  return CommonStyleFunction.shouldDisplayFeatureGeometry(parentFeatureProps.geometry, resolution) ?
    clusteredFeatureProps.map(featureProps => buildGeometryStyles(featureProps, stylingConfig)).flat() :
    buildIconStyle(parentFeatureProps, stylingConfig);
}

function buildGeometryStyles(featureProps, stylingConfig) {
  const textStyle = DronePlanTextStyleFunction.buildTextStyle(featureProps, stylingConfig);
  const geometryStyles = DronePlanGeometryStyleFunction.buildGeometryStyle(featureProps);
  const trajectoryElementsStyles = DronePlanTrajectoryStyleFunction.buildTrajectoryStyle(featureProps);
  let styles = [...textStyle, ...geometryStyles, ...trajectoryElementsStyles];
  if(featureProps.shouldDisplayOuterBoxGeometry) {
    const outerBoxGeometryStyle = DronePlanOuterBoxGeometryStyleFunction.buildOuterBoxGeometryStyle(featureProps);
    styles = [...styles, outerBoxGeometryStyle];
  }
  return styles;
}

function buildIconStyle(featureProps, stylingConfig) {
  return [...DronePlanIconStyleFunction.buildIconStyle(featureProps, stylingConfig)];
}

function getOperationPlanColorConfigs(feature, stylingConfig) {
  return {
    fillColor: getFillColor(feature, stylingConfig),
    borderConfig: getOperationPlanStrokeConfig(feature, stylingConfig)
  };
}

function getFillColor(feature, stylingConfig) {
  const opState = FeatureUtils.State.getOperationState(
    feature.getProperties().state, feature.getProperties().stateAttributeType);
  return OPStateColoringConfig.getOpMapFillColorByState(opState, stylingConfig.colors.fillColor);
}

function getOperationPlanStrokeConfig(feature, stylingConfig) {
  const opState = FeatureUtils.State.getOperationState(
    feature.getProperties().state, feature.getProperties().stateAttributeType);
  let borderColor = OPStateColoringConfig.getOpMapBorderColorByState(opState, stylingConfig.colors.borderColor);
  const color = borderColor ? borderColor : stylingConfig.colors.borderColor;
  const width = feature.getProperties().selected ? 5 : 1.5;
  const alertBorderWidth = feature.getProperties().selected ? 5 : 2.5;
  return {color, width, alertBorderWidth};
}

function mapOp(feature, operationPlan, stylingConfig, resolution) {
  return {
    dronePlanId: operationPlan.getProperties().associatedFeatureId,
    resolution: resolution,
    feature: operationPlan,
    text: operationPlan.getProperties().text,
    selected: operationPlan.getProperties().selected,
    associatedAlerts: FeatureUtils.Icons.getAssociatedAlertsForOperationPlanId(operationPlan.getProperties().associatedFeatureId),
    colorConfigs: getOperationPlanColorConfigs(operationPlan, stylingConfig),
    shouldDisplayOuterBoxGeometry: operationPlan.getProperties().shouldDisplayOuterBoxGeometry,
    geometry: operationPlan.getProperties().geometry,
    trajectoryTakeoffLocation: operationPlan.getProperties().trajectoryTakeoffLocation,
    trajectoryLandingLocation: operationPlan.getProperties().trajectoryLandingLocation,
    clusteredFeatures: feature.getProperties().features.length
  }
}