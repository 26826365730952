<template>
  <div class="alert bg-warning text-dark p-2" v-if="isUpdateAvailable">
    {{ $t('errorMessages.updateAvailableNotification') }}
  </div>
  <div v-else>
    <div v-if="!saveAsMode" class="form-group form-inline">
      <button
        id="save-panel-save"
        :class="['btn', 'btn-primary', ' m-1', (isModified && isEditable) ? 'active' : '']"
        @click="saveAction"
        :disabled="!isModified || !isEditable || (isValid === false)"
      >
        {{ $t('labels.common.save') }}
      </button>
      <button
        v-if="saveAsAction"
        id="save-panel-save-as"
        class="btn btn-primary m-1"
        @click="setSaveAsMode(true)"
      >
        {{ $t('labels.common.saveAs') }}
      </button>
      <button
        id="save-panel-reset"
        :class="['btn', 'btn-primary', ' m-1', isModified ? '' : '']"
        @click="cancelEditingItem"
        :disabled="!isModified"
      >
        {{ $t('labels.common.reset') }}
      </button>
      <button
        v-if="deleteAction"
        id="save-panel-delete"
        :class="['btn', 'btn-danger', ' m-1']"
        @click="deleteItem"
        :disabled="!isEditable"
      >
        {{ $t('labels.common.delete') }}
      </button>
    </div>
    <div class="form-group form-inline" v-else>
      {{ $t('labels.common.name') }}
      <input
        id="savePanel_saveAsName"
        type="text"
        class="form-control m-1"
        v-model="saveAsName"
      >
      <button
        id="save-panel-save-as-ok"
        :class="['btn', 'btn-primary', ' m-1', isSaveAsActive ? '' : '']"
        @click="saveAsAction(saveAsName) || setSaveAsMode(false)"
        :disabled="!isSaveAsActive"
      >
        {{ $t('labels.common.ok') }}
      </button>
      <button
        id="save-panel-save-as-cancel"
        class="btn btn-primary m-1"
        @click="setSaveAsMode(false)"
      >
        {{ $t('labels.common.cancel') }}
      </button>
    </div>
  </div>
</template>
<script>

import DialogUtils from '../../../utils/DialogUtils';

export default {
  name: 'SavePanel',
  props: ['isModified', 'isEditable', 'isValid', 'saveAction', 'saveAsAction', 'cancelAction', 'deleteAction'],
  data: () => {
    return {
      saveAsMode: false,
      saveAsName: '',
    }
  },
  computed: {
    isUpdateAvailable() {
      return this.$store.state.updateStore.isUpdateAvailable;
    },
    isSaveAsActive() {
      return this.saveAsName.length > 0;
    },
  },
  methods: {
    setSaveAsMode(flag) {
      this.saveAsMode = flag;
      this.saveAsName = '';
    },
    cancelEditingItem() {
      DialogUtils.confirmOperation(
        {
          message: this.$i18n.t('confirmationMessages.savePanelReset'),
          yesAction: this.cancelAction,
        }
      );
    },

    deleteItem() {
      DialogUtils.confirmOperation(
        {
          message: this.$i18n.t('confirmationMessages.savePanelDelete'),
          yesAction: this.deleteAction,
        }
      );
    },
  }

};

</script>
