import OperationPlaneState from '../constants/operationPlanState';
import Store from '../store';

function createColorFunction(currentState, isCounter) {
  const stylingConfig = Store.state.themeStore.currentTheme.stylingConfigs.find(s => s.layer === currentState.assocFeatureType);
  const imageColor = stylingConfig ? stylingConfig.colors.imageColor : {r: 250, g: 9, b: 0};
  let color = 'rgb(' + imageColor.r + ', ' + imageColor.g +', ' + imageColor.b + ')';
  switch (currentState.state) {
    case OperationPlaneState.PROPOSED.state: return {color: color};
    case OperationPlaneState.AUTHORIZED.state: return {color: color};
    case OperationPlaneState.ACTIVATED.state: return {color: color};
    case OperationPlaneState.CLOSED.state: return {color: 'rgb(250, 9, 0)'};
    case OperationPlaneState.TAKEOFFREQUESTED.state:
      return {
        color: 'rgb(247, 11, 70)',
        borderLeft: isCounter ? '' : '5px solid rgb(247, 11, 70)',
        marginBottom: isCounter ? '' : '10px'
      };
    case OperationPlaneState.NO_SERVICE.state:
      return {color: 'rgb(145,147,149)'};
    case OperationPlaneState.INACTIVETWR.state:
      return {color: 'rgb(145,147,149)'};
    default: return {color: color};
  }
}

function getOpMapBorderColorByState(currentState, stylingConfigBorderColor){
  return getOpMapColorByState(currentState, true, stylingConfigBorderColor);
}

function getOpMapFillColorByState(currentState, stylingConfigFillColor){
  return getOpMapColorByState(currentState, false, stylingConfigFillColor);
}

function getOpMapColorByState(currentState, isBorderColor, stylingConfigColor) {
  switch (currentState) {
    case OperationPlaneState.PROPOSED.state: return stylingConfigColor;
    case OperationPlaneState.AUTHORIZED.state: return stylingConfigColor;
    case OperationPlaneState.ACTIVATED.state: return stylingConfigColor;
    case OperationPlaneState.TAKEOFFREQUESTED.state: return { r: 247, g:11, b: 70, a: isBorderColor ? 1 : 0.3};
    case OperationPlaneState.NO_SERVICE.state: {
      if(isBorderColor){
        return { r: 250, g: 9, b: 0, a: 1};
      }
      return { r: 255, g: 255, b: 255, a: 0.3};
    }
    case OperationPlaneState.INACTIVETWR.state: {
      if(isBorderColor){
        return { r: 250, g: 9, b: 0, a: 1};
      }
      return { r: 255, g: 255, b: 255, a: 0.3};
    }
    default: return stylingConfigColor;
  }
}

export default {
  createColorFunction,
  getOpMapFillColorByState,
  getOpMapBorderColorByState
}