<template>
    <div class="form-inline w-100">
        <span class="fixedSizeTitle"><strong>{{ title }}:</strong></span>
        <input
            :id="type"
            type="text"
            :disabled="disabled === true"
            :class="['form-control', 'm-1'].join(' ')"
            @keyup="onChange"
            @change="onChange"
            :value="getValue(type)"
        >
    </div>
</template>

<script>

  import A from '../../../../constants/actions';

  export default {
    name: 'PropertyInput',
    props: ['title', 'type', 'disabled'],
    methods: {
      getValue(propName) {
        if(propName.indexOf('.') !== -1){
          const props = propName.split('.');
          return this.$store.state.notamFilterStore.currentFilter[props[0]][props[1]];
        } else {
          return this.$store.state.notamFilterStore.currentFilter[propName];
        }
      },
      onChange(evt) {
        this.$store.dispatch(A.NOTAMFILTER_UPDATE_PROPERTY,
          {
            type: evt.target.id,
            value: evt.target.value,
          });
      },
    }
  }
</script>
<style src="./notamFilterEditor.css"></style>