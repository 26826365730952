export default {
  SUBMIT_OPERATION_PLAN: '/ops/submitOperationPlan',
  REQUEST_TAKEOFF: '/ops/requestTakeOff',
  WITHDRAW_OPERATION_PLAN: '/operation-plan/withdraw',
  CANCEL_OPERATION_PLAN: '/operation-plan/cancel',
  DECLARE_END_OF_FLIGHT: '/operation-plan/declareEnd',
  GRANT_OPERATION_PLAN: '/operation-plan/grant',
  REJECT_OPERATION_PLAN: '/operation-plan/reject',
  REVOKE_OPERATION_PLAN: '/operation-plan/revoke',
  FETCH_AUTHORIZATION_CONFLICTS: '/ops/fetch-authorization-conflicts',
  FETCH_ACTIVATION_CONFLICTS: '/fetch-activation-conflicts',
};