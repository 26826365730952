import Store from '../store';
import DroneService from '../services/droneService';
import A from '../constants/actions';
import Logger from './LoggerUtils';
import DialogUtils from './DialogUtils';
import PopupUtils from './PopupUtils';
import FeatureType from "../constants/featureType";
import {i18n} from '../internationalization/index';

export default class AlertReplyDetailsUtils {

  static buildAlertReply(alertMessage, replyMessage) {
    return {
      alertId: alertMessage.id,
      message: replyMessage,
      operationEndTime: getOperationEndTime(getAssociatedOperation(alertMessage)),
    };
  }

  static sendAlertReply(replyMessage) {
    Store.dispatch(A.PROGRESS_INDICATOR_START);
    DroneService.sendAlertReply({
      alertReply: replyMessage,
      successCallback: () => {
        Store.dispatch(A.PROGRESS_INDICATOR_END);
        PopupUtils.success(i18n.global.t('popupMessages.alertReplyCreated'));
      },
      errorCallback: error => {
        Store.dispatch(A.PROGRESS_INDICATOR_END);
        const errorMessage = i18n.global.t('errorMessages.alertReplyCreateError', {error});
        Logger.error(errorMessage);
        DialogUtils.errorNotification(errorMessage);
      },
    })
  }

  static alertHasReply(alertId) {
    return Store.state.informationStore.messages
      .some(f => f.featureType === FeatureType.ALERT_REPLY && f.alertId === alertId);
  }
}

function getAssociatedOperation(alertMessage) {
  const associatedOperationPlanId = alertMessage.operationPlanIds?.[0];
  return associatedOperationPlanId ? Store.getters.getOperationPlanByOpGufi(associatedOperationPlanId)?.[0] : null;
}

function getOperationEndTime(operationPlan) {
  return getLatestTime(operationPlan?.volumes?.map(volume => volume.endTime));
}

function getLatestTime(volumeEndTimes) {
  return volumeEndTimes?.sort(compareTimestamps)[0];
}

function compareTimestamps(firstTimestamp, secondTimestamp) {
  return firstTimestamp > secondTimestamp ? -1 : 1;
}
