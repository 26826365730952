<template>
  <div>
    <strong>{{ $t('labels.configurationPanel.theme.themeStyle') }}:</strong>
    <button id="theme-style-toggle" :class="['btn', 'btn-primary', 'm-1']" @click="this.changeThemeStyle">
      {{ this.isDayTheme ? $t('labels.common.day') : $t('labels.common.night') }}
    </button>
  </div>
</template>

<script>

import A from '../../constants/actions';
import {mapGetters} from "vuex";

export default {
  name: 'DayNightSwitch',

  computed: {
    ...mapGetters({
      isDayTheme: 'getIsDayThemeSelected',
    }),
  },

  methods: {
    changeThemeStyle() {
      this.$store.dispatch(A.THEME_SWITCH_STYLE);
    }
  },
}
</script>
<style src="./dayNightSwitch.css"></style>