import layerVisibilityConfig from "../config/layerVisibilityConfig";

export default class LayerVisibilityUtils {

  static getDisplayValueOfLayerVisibility(layerVisibilityString) {
    const layerVisibility = layerVisibilityConfig.LAYER_VISIBILITY.AIRSPACE_USER === layerVisibilityString ?
      layerVisibilityConfig.LAYER_VISIBILITY.AIRSPACE_USER :
      layerVisibilityConfig.LAYER_VISIBILITY.AIRSPACE_MANAGER;
    return layerVisibilityConfig.getLayerVisibilityDisplayValue(layerVisibility)
  }

  static isLayerAvailableForAirspaceManagers(layerConfiguration) {
    return layerConfiguration.visibleForRoles !== null && layerConfiguration.visibleForRoles &&
      layerConfiguration.visibleForRoles.includes(layerVisibilityConfig.LAYER_VISIBILITY.AIRSPACE_MANAGER);
  }

}