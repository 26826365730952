<template>
  <div class="alert alert-warning py-4 my-4" v-if="isUpdateAvailable">
    {{ updateMessage }}
    <button
      id="update-now-button"
      @click="updateAssets"
      class="btn btn-warning"
    >
      {{ $t('labels.common.updateNow') }}
    </button>
  </div>
</template>

<script>

import A from '../../constants/actions';
import {mapGetters} from "vuex";

export default {
  name: 'UpdatesPanel',

  methods: {
    updateAssets() {
      this.$store.dispatch(A.UPDATE_START_UPDATING);
    }
  },

  computed: {
    ...mapGetters({
      updateMessage: 'getUpdateMessage'
    }),
    isUpdateAvailable() {
      return this.$store.state.updateStore.isUpdateAvailable;
    },
  }

}
</script>
<style src="./rolesPanel.css"></style>