import ModelUtils from '../utils/ModelUtils';
import UomConfig from '@/config/uomConfig';
import UomUtils from '../utils/UomUtils';

const fields = [
  {
    id: 'drone-id',
    name: 'labels.drone.droneId',
    propertyName: 'id',
    displayInList: true,
    displayInForm: true,
    type: '',
    isTimeDependent: true,
    selector: true,
    sort: (a, b) => {
      return ModelUtils.sort(a.registration || a.id, b.registration || b.id);
    },
    getter(drone) {
      return drone.registration || drone.id;
    }
  },
  {
    id: 'drone-flight-id',
    name: 'labels.drone.flightId',
    propertyName: 'flightId',
    displayInList: true,
    displayInForm: true,
    type: '',
    tableStyle: 'telemetryId col-4 p-0',
    selector: true,
    sort: (a, b) => {
      return ModelUtils.sort(a.flightId, b.flightId);
    },
  },
  {
    id: 'drone-provider-id',
    name: 'labels.drone.providerId',
    propertyName: 'providerId',
    displayInList: true,
    displayInForm: true,
    type: '',
    selector: true,
    sort: (a, b) => {
      return ModelUtils.sort(a.providerId, b.providerId);
    },
  },
  {
    id: 'drone-altitude',
    name: 'labels.drone.altitude',
    propertyName: 'altitude',
    displayInList: true,
    displayInForm: true,
    type: '',
    selector: true,
    sort: (a, b) => {
      return ModelUtils.sort(a.altitude, b.altitude);
    },
    getter(drone) {
      return ModelUtils.roundToTwoDecimals(
        UomUtils.convertValueToCurrentUom(drone.altitude, UomConfig.UOM.FEET)
      ) + " " + ModelUtils.constructDisplayedUomAndCrs();
    }
  },
];

export default {
  name: 'Drone',
  fields,
}