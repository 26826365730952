import A from '../../constants/actions';
import M from '../../constants/mutations';
import Vue from 'vue';

const state = {
  documents: [],
  openDocs: {},
  isUpdated: false,
};

const actions = {

  [A.DOCUMENTS_PANEL_SELECT_DOC]({commit}, params) {
    commit(M.DOCUMENTS_PANEL_SELECT_DOC, params);
  },

  [A.DOCUMENTS_PANEL_DESELECT_DOC]({commit}, docName) {
    commit(M.DOCUMENTS_PANEL_DESELECT_DOC, docName);
  },

  [A.DOCUMENTS_PANEL_UPDATE_DOCUMENTS]({commit}, documents) {
    commit(M.DOCUMENTS_PANEL_UPDATE_DOCUMENTS, documents);
  },

  [A.DOCUMENTS_UPDATE_IS_AVAILABLE]({commit}) {
    commit(M.DOCUMENTS_UPDATE_IS_AVAILABLE);
  },

  [A.DOCUMENTS_PANEL_ADD_DOCUMENT]({commit}, docName) {
    commit(M.DOCUMENTS_PANEL_ADD_DOCUMENT, docName);
  },

  [A.DOCUMENTS_PANEL_DELETE_DOCUMENT]({commit}, docName) {
    commit(M.DOCUMENTS_PANEL_DELETE_DOCUMENT, docName);
  },

  [A.DOCUMENTS_RESET_IS_UPDATED]({commit}) {
    commit(M.DOCUMENTS_RESET_IS_UPDATED);
  },
};

const mutations = {

  [M.DOCUMENTS_RESET_IS_UPDATED](state) {
    state.isUpdated = false;
  },

  [M.DOCUMENTS_PANEL_SELECT_DOC](state, params) {
    Vue.set(state.openDocs, params.documentName, params.docWindow);
  },

  [M.DOCUMENTS_PANEL_DESELECT_DOC](state, docName) {
    Vue.set(state.openDocs, docName, null);
  },

  [M.DOCUMENTS_PANEL_UPDATE_DOCUMENTS](state, documents) {
    state.documents = documents;
  },

  [M.DOCUMENTS_UPDATE_IS_AVAILABLE](state) {
    state.isUpdated = true;
  },

  [M.DOCUMENTS_PANEL_ADD_DOCUMENT](state, docName) {
    state.documents.push(docName);
  },

  [M.DOCUMENTS_PANEL_DELETE_DOCUMENT](state, docName) {
    state.documents = state.documents.filter(documentName => documentName !== docName);
  },

};

export default {
  state,
  actions,
  mutations,
}