<template>
  <div class="form-group row m-1 center-elements-flex-row">
    <strong class="col-0 form-inline">{{ $t('labels.configurationPanel.notamFilter.fir') }}:</strong>
    <select
      :value="getSelectedFirFilter"
      @change="setSelectedFirFilter"
      id="FIR"
      v-bind:class="getClassFirFilter()">
      <option class="notam-filter-option" value="NO FILTERS">
        {{ $t('labels.configurationPanel.notamFilter.noFilters') }}
      </option>
      <option class="notam-filter-option" value="ALL FILTERS">
        {{ $t('labels.configurationPanel.notamFilter.allFilters') }}
      </option>
      <template v-for="filter in getFirFilters" :key="filter.id">
        <option
          class="notam-filter-option"
          :value="filter.id">
          {{ filter.name }}
        </option>
      </template>
    </select>
    <strong class="col-0 form-inline title">{{ $t('labels.configurationPanel.notamFilter.airport') }}:</strong>
    <select
      :value="getSelectedAirportFilter"
      @change="setSelectedAirportFilter"
      id="Airport"
      v-bind:class="getClassAirportFilter()">
      <option class="notam-filter-option" value="NO FILTERS">
        {{ $t('labels.configurationPanel.notamFilter.noFilters') }}
      </option>
      <option class="notam-filter-option" value="ALL FILTERS">
        {{ $t('labels.configurationPanel.notamFilter.allFilters') }}
      </option>
      <template v-for="filter in getAirportFilters" :key="filter.id">
        <option
          class="notam-filter-option"
          :value="filter.id">
          {{ filter.name }}
        </option>
      </template>
    </select>
  </div>
</template>
<script>
import A from '../../constants/actions';
import PermissionUtils from '../../utils/PermissionUtils';
import FeatureType from '../../constants/featureType';

export default {
  name: 'NotamFilters',

  computed: {
    getFirFilters() {
      const filtersSelectedToView = this.$store.state.viewStore.currentView.filterIds;
      return PermissionUtils.getAvailableNotamFilters().filter(f => f.filterType === FeatureType.AIRSPACE).filter(f => filtersSelectedToView.includes(f.id));
    },
    getAirportFilters() {
      const filtersSelectedToView = this.$store.state.viewStore.currentView.filterIds;
      return PermissionUtils.getAvailableNotamFilters().filter(f => f.filterType === FeatureType.AIRPORT_HELIPORT).filter(f => filtersSelectedToView.includes(f.id));
    },
    getSelectedFirFilter() {
      return this.$store.state.notamFilterStore.currentFirFilter;
    },
    getSelectedAirportFilter() {
      return this.$store.state.notamFilterStore.currentAirportFilter;
    }
  },

  methods: {
    getClassFirFilter() {
      if (this.isFirFiltersWrong()) {
        return 'col-4 notam-filters-option-list form-control wrongQuery';
      }
      return 'col-4 notam-filters-option-list form-control';
    },
    isFirFiltersWrong() {
      return this.$store.state.notamFilterStore.wrongFirQuery;
    },
    getClassAirportFilter() {
      if (this.isAirportFiltersWrong()) {
        return 'col-4 notam-filters-option-list form-control wrongQuery';
      }
      return 'col-4 notam-filters-option-list form-control';
    },
    isAirportFiltersWrong() {
      return this.$store.state.notamFilterStore.wrongAirportQuery;
    },
    setSelectedFirFilter(evt) {
      const config = {
        type: 'currentFirFilter',
        value: evt.target.value,
      };
      this.$store.dispatch(A.NOTAMFILTER_SET_SELECTED_FILTER, config);
      if (evt.target.value === 'NO FILTERS') {
        this.$store.dispatch(A.NOTAMFILTER_SET_SELECTED_FILTER, {type: 'wrongFirQuery', value: false})
      }
    },
    setSelectedAirportFilter(evt) {
      const config = {
        type: 'currentAirportFilter',
        value: evt.target.value,
      };
      this.$store.dispatch(A.NOTAMFILTER_SET_SELECTED_FILTER, config);
      if (evt.target.value === 'NO FILTERS') {
        this.$store.dispatch(A.NOTAMFILTER_SET_SELECTED_FILTER, {type: 'wrongAirportQuery', value: false})
      }
    },
  }
}

</script>
<style src="./informationPanel.css"></style>