import FeatureType from '../../constants/featureType';
import ThemeElements from '../../constants/theme-elements';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';

export default {
  id: FeatureType.GROUND_HAZARD,
  name: 'features.groundHazard',
  icon: 'briefing',
  staticDataType: [],
  isViewPortBased: true,
  isFlightLevelBased: false,
  isNotificationsDisabled: true,
  maxNrOfFeaturesPerLocation: 10,
  featureIdentifier: 'id',
  maxResolution: 10000,
  declutter: false,
  zIndex: 1,
  flightLevel: null,
  theme: {
    elements: [
      ThemeElements.SHOW_TEXT,
      ThemeElements.BORDER_COLOR,
      ThemeElements.FILL_COLOR,
      ThemeElements.TEXT_COLOR,
      ThemeElements.TEXT_SIZE,
      ThemeElements.TEXT_BG_COLOR,
    ]
  },

  displayOnMap: true,
  displayInMessages: false,

  color: (colors) => {
    return colors.textColor;
  },

  defaultStyle: () => {
    return {
      showText: true,
      textSize: 14,
      iconSize: 100,
      colors: {
        borderColor: {r: 168, g: 23, b: 197, a: 1},
        fillColor: {r: 225, g: 136, b: 240, a: 0.27},
        textColor: {r: 134, g: 134, b: 134, a: 0.8},
        textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
        imageColor: {r: 239, g: 8, b: 8, a: 0.5}
      }
    }
  },

  geoJsonMapper: (feature) => {
    return new Feature({
      geometry: new GeoJSON().readGeometry(feature.geometry),
      id: feature.id,
      level: feature.level,
      text: feature.text,
      featureType: 'groundhazard'
    });
  },
}