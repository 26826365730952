<template>
  <div id="map-layers-button-wrapper" class="map-layers-button-wrapper">
    <button id="map-layers-container-toggle-btn"
            @click="toggleLayersContainerDisplay"
            class="btn btn-primary map-layers-button"
    >
      {{ $t('labels.hud.mapLayers') }}
    </button>
    <div v-show="displayLayersContainer"
         id="map-layers-container"
         class="map-layers-container">
      <div :key="index" v-for="(layerConfig, index) in layerOptions"
           :class="[
             'map-layers-checkbox-wrapper',
             isLayerNameLonger(layerConfig.label) ? 'map-layer-separation' : ''
             ].join(' ')">
        <SmartSisCheckbox
          :is-checked="areActive(layerConfig.layerIds)"
          :on-click-action="() => toggleLayers(layerConfig.layerIds)"
          :label="getLayerConfigLabel(layerConfig.label)"
          :disabled="isSmartSisCheckboxDisabled(layerConfig.layerIds)"
          :label-on-right="true"
        />
        <div v-if="getColorContainerBackground(layerConfig)"
             class="map-layer-color-container"
             :style="getColorContainerBackground(layerConfig)">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import A from '../../constants/actions';
import {mapGetters} from 'vuex';
import Features from '../../config/features';
import LayerUtils from '../../utils/LayerUtils';
import FeatureType from '../../constants/featureType';
import SmartSisCheckbox from "../../common/SmartSisCheckbox";
import PermissionUtils from '../../utils/PermissionUtils';
import Store from "../../store";

export default {
  name: 'LayerSelectorPanel',
  components: {
    SmartSisCheckbox
  },

  created() {
    document.addEventListener('click', this.closeLayersContainerIfNecessary);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeLayersContainerIfNecessary);
  },
  data: () => {
    return {
      displayLayersContainer: false,
      disabledFeatureTypes: [FeatureType.DRONE_ALERT, FeatureType.OPERATION_PLAN_PROPOSED,
        FeatureType.OPERATION_PLAN_ACCEPTED, FeatureType.OPERATION_PLAN_ACTIVATED]
    }
  },
  computed: {
    ...mapGetters({
      activeView: 'activeView'
    }),
    layerOptions() {
      const currentView = this.$store.state.viewStore.currentView;
      let layerIdsForSelectedTopics = LayerUtils.getLayerIdsByTopicNames(this.$store.state.informationStore.selectedTopics);
      let layersOption = [];
      if (layerIdsForSelectedTopics.length > 0) {
        layersOption = Features.getAvailableMapFeatures().filter(product => this.isStaticLayerToDisplay(product.id) || !currentView ||
          (currentView.productTypes.includes(product.id) && layerIdsForSelectedTopics.includes(product.id)));
      } else {
        let mapFeatures = Features.getAvailableMapFeatures();
        layersOption = mapFeatures.filter(product => this.isStaticLayerToDisplay(product.id) ||
          !currentView || currentView.productTypes.includes(product.id));
      }
      if (Store.getters.getGeoserverLayers) {
        const geoserverFeatures = Store.getters.getGeoserverLayers
          .filter(product => !currentView || (currentView.productTypes.includes(product.id)))
          .map(layer => {
            return LayerUtils.constructDefaultStyleConfigForGeoserverLayer(layer);
          });
        layersOption = [...layersOption, ...geoserverFeatures];
      }
      return LayerUtils.getLayerSelectorOptions(layersOption);
    }
  },
  methods: {
    areActive(layerIds) {
      return layerIds.every(lId => this.isActive(lId));
    },
    isActive(layerId) {
      const activeLayers = this.activeView.dynamicLayers;
      return activeLayers.includes(layerId);
    },
    toggleLayers(layerIds) {
      layerIds.forEach(layer => {
        this.$store.dispatch(A.LAYERS_CONFIG_PANEL_TOGGLE_LAYER, layer);
        this.$store.dispatch(A.MAP_CHANGE_LAYER_VISIBILITY, {id: layer, visibility: this.isActive(layer)});
      });
    },
    isStaticLayerToDisplay(productId) {
      return productId === FeatureType.AIRSPACE || productId === FeatureType.AIRPORT_HELIPORT ||
        productId === FeatureType.RESPONSIBILITY_AREA;
    },
    isSmartSisCheckboxDisabled(layerIds) {
      return !PermissionUtils.isSuperAdmin() && this.disabledFeatureTypes.some(el => el === layerIds[0]);
    },
    toggleLayersContainerDisplay() {
      this.displayLayersContainer = !this.displayLayersContainer;
    },
    closeLayersContainerIfNecessary(event) {
      const clickedOnToggleButton = event.composedPath().some(el => el.id === 'map-layers-container-toggle-btn');
      const clickedOnContainer = event.composedPath().some(el => el.id === 'map-layers-container');
      if (!clickedOnToggleButton && !clickedOnContainer) {
        this.displayLayersContainer = false;
      }
    },
    getColorContainerBackground(layerConfig) {
      const currentTheme = this.$store.state.themeStore.currentTheme;
      const stylingConfig = currentTheme.stylingConfigs.find(conf => conf.layer === layerConfig.layerIds[0]);
      const color = (layerConfig.color && stylingConfig) ? layerConfig.color(stylingConfig.colors) : null;
      return color ? {'background-color': 'rgba(' + [color.r, color.g, color.b, color.a].join(',') + ')'} : null;
    },
    getLayerConfigLabel(layerConfigLabel) {
      return layerConfigLabel.length > 60 ? layerConfigLabel.substring(0, 60) + '...' : layerConfigLabel;
    },
    isLayerNameLonger(layerName) {
      return layerName.length > 25;
    }
  }
};
</script>
<style src="./layerSelector.css"></style>