export default {

  T_CHART_GENERATOR: 'tChartGenerator',

  USER: 'User',

  SUPERVISOR: 'Supervisor',

  SUPER_ADMIN: 'SuperAdmin',

  FEATURE_ACCESS_ROLES_CLIENT: 'bearer_only',

  APPROVAL_AUTOMATION_MANAGER: 'ApprovalAutomationManager',

  CAPACITY_MANAGER: 'CapacityManager',

  GEOZONE_ACTIVATION: 'GeozoneActivation',

  ATM_AIRSPACE_RESERVATION: 'ATMAirspaceReservation',

}