<template>
  <div class="alert alert-secondary py-2 my-2 userPanelDetailsContainer">
    v. <strong>{{ version }}.<span @click="dumpState()">{{ build }}</span></strong>
    <LanguageSelector class="language-selector"/>
  </div>
</template>

<script>

import AppConfig from '../../config/appConfig';
import DebugUtils from '../../utils/DebugUtils';
import LanguageSelector from "../hud/language-selector/LanguageSelector";

export default {
  name: 'VersionPanel',
  components: {
    LanguageSelector
  },

  computed: {
    version() {
      return AppConfig.version;
    },
    build() {
      return AppConfig.build;
    }
  },

  methods: {
    dumpState() {
      DebugUtils.exportAppState();
    }
  },

}
</script>
<style src="./rolesPanel.css"></style>