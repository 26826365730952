import DroneState from '../constants/droneState';
import Features from "./features";
import FeatureType from "../constants/featureType";
import FeatureUtils from "../utils/FeatureUtils";

function getDroneIconColorByState(state, droneStyleConfig) {
  return getDroneColorByState(
    state,
    featureColorConfig => featureColorConfig.bgColor,
    stylingColorConfig => stylingColorConfig.imageColor,
    droneStyleConfig
  );
}

function getDroneTextColorByState(state, droneStyleConfig) {
  const getTextColor = colorConfig => colorConfig.textColor;
  return getDroneColorByState(state, getTextColor, getTextColor, droneStyleConfig);
}

function getDroneColorByState(state, getDroneFeatureColor, getDroneStylingConfigColor, droneStyleConfig) {
  const droneFeature = Features.getFeature(FeatureType.DRONES);
  switch (state) {
    case DroneState.NON_CONFORMANT:
      return getDroneFeatureColor(droneFeature.criticalIconColor);
    case DroneState.CONTINGENCY:
      return getDroneFeatureColor(droneFeature.warningIconColor);
    default:
      return FeatureUtils.Styling.toRgba(getDroneStylingConfigColor(droneStyleConfig.colors));
  }
}

export default {
  getDroneIconColorByState,
  getDroneTextColorByState
}