<template>
  <div class="searchForm w-100 form-group form-inline row">
    <input
      id="searchInput"
      type="text"
      class="col-7 form-control"
      v-model="currentSearchInput"
      :placeholder="$t('labels.common.search')"
      v-on:keyup.enter="applySearch"
    >
    <button id="search-button" type="button" class="col-5 btn btn-primary" @click="applySearch">
      {{ $t('labels.common.search') }}
    </button>
  </div>
</template>
<script>

export default {
  name: 'Search',
  props: ['activeTab', 'searchInput'],
  data: () => {
    return {
      currentSearchInput: null,
    }
  },
  watch: {
    activeTab: {
      immediate: true,
      handler() {
        this.currentSearchInput = null;
      }
    }
  },
  methods: {
    applySearch() {
      this.$emit("updateSearchInput", this.currentSearchInput);
    },
  }
};
</script>