import droneIdentificationTypes from "../config-files/drone-identification-types.json";

const droneIdentificationTypeExists = (droneIdentificationType) => {
  return !!(droneIdentificationTypes.map(it => it.value).find(it => it === droneIdentificationType));
};

const validateDroneIdentificationTypes = (givenDroneIdentificationTypes) => {
  let parsedDroneIdentificationTypes;
  try {
    parsedDroneIdentificationTypes = JSON.parse(givenDroneIdentificationTypes);
  } catch (e) {
    console.error(e);
    throw Error("Configuration VUE_APP_DRONE_IDENTIFICATION_TYPES could not be parsed!");
  }
  if (parsedDroneIdentificationTypes.length === 0) {
    throw Error("Configuration VUE_APP_DRONE_IDENTIFICATION_TYPES cannot be empty!");
  }
  const invalidDroneIdentificationTypes = parsedDroneIdentificationTypes
    .filter(droneIdType => !droneIdentificationTypeExists(droneIdType));
  if (invalidDroneIdentificationTypes.length > 0) {
    throw Error("The following drone identification types from VUE_APP_DRONE_IDENTIFICATION_TYPES are invalid: " +
      invalidDroneIdentificationTypes);
  }
  return parsedDroneIdentificationTypes;
};

const validateDroneIdentificationOtherTypes = (givenDroneIdentificationOtherTypes) => {
  let parsedDroneIdentificationOtherTypes;
  try {
    parsedDroneIdentificationOtherTypes = JSON.parse(givenDroneIdentificationOtherTypes);
  } catch (e) {
    console.error(e);
    throw Error("Configuration VUE_APP_DRONE_IDENTIFICATION_OTHER_TYPES could not be parsed!");
  }
  if (!(parsedDroneIdentificationOtherTypes instanceof Array)) {
    throw Error("Configuration VUE_APP_DRONE_IDENTIFICATION_OTHER_TYPES should be a JSON Array!");
  }
  return parsedDroneIdentificationOtherTypes;
};

const getDroneIdentificationTypeObjectByValue = (value) => {
  return droneIdentificationTypes.find(dit => dit.value === value);
}

const getDroneIdentificationTypeObjectsByValues = (droneIdentificationTypeValues) => {
  return droneIdentificationTypeValues.map(ditv => getDroneIdentificationTypeObjectByValue(ditv));
};

const getDefaultDroneIdentificationTypes = () => {
  return ["ID_ICAO", "ID_CALLSIGN", "ID_TRACK", "ID_TRACKID", "ID_GUFI", "ID_FLARM", "ID_IMEI", "ID_IMSI", "ID_SERIAL"];
}

const mapOtherTypeValueToDroneIdentificationTypeObject = (otherTypeValue) => {
  return {
    name: otherTypeValue,
    value: otherTypeValue
  }
}

export default {
  droneIdentificationTypes,
  validateDroneIdentificationTypes,
  getDroneIdentificationTypeObjectByValue,
  getDroneIdentificationTypeObjectsByValues,
  getDefaultDroneIdentificationTypes,
  droneIdentificationTypeExists,
  mapOtherTypeValueToDroneIdentificationTypeObject,
  validateDroneIdentificationOtherTypes,
}