import Logger from "../LoggerUtils";
import PopupUtils from "../PopupUtils";
import Store from '@/store';
import A from '@/constants/actions';
import DialogUtils from "../DialogUtils";
import {i18n} from '../../internationalization';
import DronePlanUtils from "./DronePlanUtils";

export default class OpDialogCallBackUtils {
  static getSuccessCallback(operationPlan) {
    return () => {
      Store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
      Logger.info(i18n.global.t('logMessages.dronePlanClosed', {dronePlanId: operationPlan.id}));
      PopupUtils.success(i18n.global.t('popupMessages.dronePlanClosed',
        {title: DronePlanUtils.getPublicTitleForPopUp(operationPlan)}));
      Store.dispatch(A.REMOVE_SELECT_FEATURE);
    };
  }

  static getErrorCallback(operationPlan) {
    return (error) => {
      Store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
      Logger.error(i18n.global.t('errorMessages.dronePlanCloseError', {
        dronePlanId: operationPlan.id,
        error
      }));
      DialogUtils.errorNotification(i18n.global.t('errorMessages.dronePlanCloseFailed'));
      Store.dispatch(A.REMOVE_SELECT_FEATURE);
    };
  }
}