import FeatureType from '../../../constants/featureType';
import {defineAsyncComponent} from "vue";

const CUSTOM_DETAILED_VIEW_COMPONENT_CONFIGS = [
  {
    featureType: FeatureType.OPERATION_PLAN,
    shouldBeUsed: message => message.featureType.startsWith(FeatureType.OPERATION_PLAN),
    component: defineAsyncComponent(() => import('../detailed-view-form-components/OperationPlanDetailedView'))
  },
  {
    featureType: FeatureType.DRONE_FLIGHT,
    shouldBeUsed: message => message.featureType.startsWith(FeatureType.DRONE_FLIGHT),
    component: defineAsyncComponent(() => import('../detailed-view-form-components/DroneFlightDetailedView'))
  },
  {
    featureType: FeatureType.DRONES,
    shouldBeUsed: message => message.featureType.startsWith(FeatureType.DRONES),
    component: defineAsyncComponent(() => import('../detailed-view-form-components/DroneDetailedView'))
  },
  {
    featureType: FeatureType.ED269_BASE_FEATURE,
    shouldBeUsed: message => message.featureType.startsWith(FeatureType.ED269_BASE_FEATURE),
    component: defineAsyncComponent(() => import('../detailed-view-form-components/GeozoneDetailedView'))
  },
  {
    featureType: FeatureType.LARA_AIRSPACE,
    shouldBeUsed: message => message.featureType === FeatureType.LARA_AIRSPACE,
    component: defineAsyncComponent(() => import('../detailed-view-form-components/LaraAirspaceDetailedView'))
  },
];

function getCustomComponent(message) {
  return CUSTOM_DETAILED_VIEW_COMPONENT_CONFIGS.find(config => config.shouldBeUsed(message))?.component;
}

function hasCustomComponent(featureType) {
  return CUSTOM_DETAILED_VIEW_COMPONENT_CONFIGS.some(config => featureType.startsWith(config.featureType));
}

export default {
  getCustomComponent,
  hasCustomComponent
};