<template>
    <div class="form-inline w-100">
        <span class="fixedSizeTitle"><strong>{{ title }}:</strong></span>
        <ToggleButton
            :id="id"
            :name="this.getPropertyValue.display"
            :class="['form-control', 'm-1', 'col-1'].join(' ')"
            :enabled="isEnabled"
            :disabled="disabled"
            :onClickAction="this.onChange"
            enabledClass="on"
            disabledClass="off"
        />
    </div>
</template>

<script>
  import ToggleButton from './ToggleButton.vue';

  export default {
    name: 'ToggleButtonItem',
    components: {
      ToggleButton,
    },
    props: ['title', 'propName', 'disabled', 'getValue', 'setValue', 'values', 'id'],
    computed: {
      isEnabled() {
        return this.getPropertyValue.value === this.values.on.value;
      },
      getPropertyValue() {
        return (this.getValue(this.propName) === this.values.on.value) ? this.values.on : this.values.off;
      },
    },
    methods: {
      onChange() {
        this.setValue(this.propName, this.isEnabled ? this.values.off.value : this.values.on.value);
      },
    }
  }
</script>
<style src="../components/config-panel/utm/editor/utmEditor.css"></style>