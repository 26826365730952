import Store from '../store';
import A from '../constants/actions';

export default class PopupUtils {

  static error(message) {
    PopupUtils.showAlert(message, 'alert-danger');
  }

  static success(message) {
    PopupUtils.showAlert(message, 'alert-success');
  }

  static warning(message) {
    PopupUtils.showAlert(message, 'alert-warning');
  }

  static showAlert(message, styleName) {
    Store.dispatch(A.POPUP_ADD_MESSAGE, {
      id: styleName,
      text: message,
      style: styleName
    });
  }

  static showAlertWithBG(message, bgColor) {
    Store.dispatch(A.POPUP_ADD_MESSAGE, {
      id: 'alert-feature',
      text: message,
      bgColor,
    });
  }

}