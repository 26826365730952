<template>
    <button
        :class="['btn', 'toggle-button', 'btn-block', 'btn-sm', (this.enabled) ? this.enabledClass : this.disabledClass].join(' ')"
        :disabled="disabled"
        @click="this.onClickAction"
    >
        {{ this.name }}
    </button>
</template>

<script>

  export default {
    name: 'ToggleButton',
    props: ['enabled', 'onClickAction', 'enabledClass', 'disabledClass', 'name', 'disabled'],
  }
</script>
