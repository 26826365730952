import A from '../../constants/actions';
import M from '../../constants/mutations';

const defaultFilter = {
  id: 'def',
  name: 'default',
  description: null,
  filterType: 'airspace',
  type: null,
  status: null,
  filterClass: null,
  traffic: null,
  purpose: null,
  scope: null,
  qCodes: null,
  categories: null,
  series: null,
  qCodeCondition: 'INCLUDE',
  catCondition: 'INCLUDE',
  minFl: {
    lower: null,
    upper: null,
  },
  maxFl: {
    lower: null,
    upper: null,
  },
  editable: true,
};

const sortFunction = (filterA, filterB) => {
  return (filterA.name === filterB.name) ? 0 : (filterA.name > filterB.name) ? 1 : -1;
};
const state = {
  filters: [
    defaultFilter,
  ],
  currentFilter: defaultFilter,
  originalFilter: defaultFilter,
  currentFirFilter: 'ALL FILTERS',
  currentAirportFilter: 'ALL FILTERS',
  wrongFirQuery: false,
  wrongAirportQuery: false,
};

const actions = {
  [A.VIEW_SELECT_VIEW]({commit}) {
    commit(M.NOTAMFILTER_SET_SELECTED_FILTER, {type: 'currentFirFilter', value: 'ALL FILTERS'});
    commit(M.NOTAMFILTER_SET_SELECTED_FILTER, {type: 'currentAirportFilter', value: 'ALL FILTERS'});
  },

  [A.NOTAMFILTER_SELECT_NOTAMFILTER]({commit}, filterId) {
    commit(M.NOTAMFILTER_SELECT_NOTAMFILTER, filterId);
  },

  [A.NOTAMFILTER_SET_SELECTED_FILTER]({commit}, config) {
    commit(M.NOTAMFILTER_SET_SELECTED_FILTER, config);
  },

  [A.NOTAMFILTER_SAVE_NOTAMFILTER]({commit}) {
    commit(M.NOTAMFILTER_SAVE_NOTAMFILTER);
  },

  [A.NOTAMFILTER_UPDATE_PROPERTY]({commit}, config) {
    commit(M.NOTAMFILTER_UPDATE_PROPERTY, config);
  },

  [A.NOTAMFILTER_DELETE_FILTER]({commit}, filterID) {
    commit(M.NOTAMFILTER_DELETE_FILTER, filterID);
  },

  [A.NOTAMFILTER_TOGGLE_QCODE]({commit}, qCode) {
    commit(M.NOTAMFILTER_TOGGLE_QCODE, qCode);
  },

  [A.NOTAMFILTER_TOGGLE_CATEGORY]({commit}, category) {
    commit(M.NOTAMFILTER_TOGGLE_CATEGORY, category);
  },

  [A.NOTAMFILTER_READ_ALL_FILTERS]({commit}, data) {
    commit(M.NOTAMFILTER_READ_ALL_FILTERS, data);
  },

  [A.NOTAMFILTER_SAVE_AS_NOTAMFILTER]({commit}, newFilter) {
    commit(M.NOTAMFILTER_SAVE_AS_NOTAMFILTER, newFilter)
  },

  [A.NOTAMFILTER_CANCEL_EDIT]({commit}) {
    commit(M.NOTAMFILTER_CANCEL_EDIT);
  },
};

const mutations = {
  [M.NOTAMFILTER_SET_SELECTED_FILTER](state, config) {
    state[config.type] = config.value;
  },

  [M.NOTAMFILTER_SAVE_AS_NOTAMFILTER](state, filter) {
    const index = state.filters.findIndex((filter) => filter.id === state.currentFilter.id);
    state.filters[index] = JSON.parse(JSON.stringify(state.originalFilter));

    const defaultIndex = state.filters.findIndex((filter) => filter.id === defaultFilter.id);
    if (defaultIndex > -1) {
      state.filters.splice(defaultIndex, 1);
    }

    state.filters.push(filter);
    state.currentFilter = filter;
    state.originalFilter = JSON.parse(JSON.stringify(filter));
    state.filters.sort(sortFunction);
  },
  [M.NOTAMFILTER_SAVE_NOTAMFILTER](state) {
    const index = state.filters.findIndex((filter) => filter.id === state.currentFilter.id);

    state.filters[index] = JSON.parse(JSON.stringify(state.currentFilter));

    state.currentFilter = state.filters[index];
    state.originalFilter = JSON.parse(JSON.stringify(state.currentFilter));
    state.filters.sort(sortFunction);

  },
  [M.NOTAMFILTER_SELECT_NOTAMFILTER](state, filterId) {
    if (state.currentFilter) {
      const index = state.filters.findIndex((filter) => filter.id === state.currentFilter.id);
      state.filters[index] = JSON.parse(JSON.stringify(state.originalFilter));
    }

    state.currentFilter = state.filters.find((filter) => {
      return filter.id === filterId;
    });
    state.originalFilter = JSON.parse(JSON.stringify(state.currentFilter));
  },
  [M.NOTAMFILTER_UPDATE_PROPERTY](state, config) {
    if (config.type.indexOf('.') !== -1) {
      let propPaths = config.type.split('.');
      if (config.value === '') {
        state.currentFilter[propPaths[0]][propPaths[1]] = null;
      } else {
        state.currentFilter[propPaths[0]][propPaths[1]] = config.value;
      }
    } else {
      if (config.value === '') {
        state.currentFilter[config.type] = null;
      } else {
        state.currentFilter[config.type] = config.value;
      }
    }
  },
  [M.NOTAMFILTER_DELETE_FILTER](state, filterID) {
    const indexOfFilter = state.filters.findIndex(filter => filter.id === filterID);
    state.filters.splice(indexOfFilter, 1);

    if (state.filters.length === 0) {
      state.filters.push(defaultFilter);
      state.currentFilter = defaultFilter;
      state.originalFilter = JSON.parse(JSON.stringify(defaultFilter));
    }

  },
  [M.NOTAMFILTER_TOGGLE_QCODE](state, qCode) {
    state.currentFilter.qCodes = state.currentFilter.qCodes || [];
    const index = state.currentFilter.qCodes.indexOf(qCode);
    if (index > -1) {
      state.currentFilter.qCodes.splice(index, 1);
    } else {
      state.currentFilter.qCodes.push(qCode);
    }
  },
  [M.NOTAMFILTER_TOGGLE_CATEGORY](state, category) {
    state.currentFilter.categories = state.currentFilter.categories || [];
    const index = state.currentFilter.categories.indexOf(category);
    if (index > -1) {
      state.currentFilter.categories.splice(index, 1);
    } else {
      state.currentFilter.categories.push(category);
    }
  },
  [M.NOTAMFILTER_READ_ALL_FILTERS](state, filters) {
    state.filters = [];
    filters.sort(sortFunction);
    filters.forEach(f => {
        const filter = {
          id: f.id,
          name: f.name,
          description: f.description,
          filterType: f.filterType,
          type: (f.valueProps.filter(t => t.name === 'type'))[0] ? (f.valueProps.filter(t => t.name === 'type'))[0].values[0] : '',
          status: (f.valueProps.filter(t => t.name === 'status'))[0] ? (f.valueProps.filter(t => t.name === 'status'))[0].values[0] : '',
          filterClass: (f.valueProps.filter(t => t.name === 'filterClass'))[0] ? (f.valueProps.filter(t => t.name === 'filterClass'))[0].values[0] : '',
          traffic: (f.valueProps.filter(t => t.name === 'traffic'))[0] ? (f.valueProps.filter(t => t.name === 'traffic'))[0].values[0] : '',
          purpose: (f.valueProps.filter(t => t.name === 'purpose'))[0] ? (f.valueProps.filter(t => t.name === 'purpose'))[0].values[0] : '',
          scope: (f.valueProps.filter(t => t.name === 'scope'))[0] ? (f.valueProps.filter(t => t.name === 'scope'))[0].values[0] : '',
          qCodes: (f.valueProps.filter(t => t.name === 'qCodes'))[0] ? (f.valueProps.filter(t => t.name === 'qCodes'))[0].values : '',
          categories: (f.valueProps.filter(t => t.name === 'categories'))[0] ? (f.valueProps.filter(t => t.name === 'categories'))[0].values : '',
          series: (f.valueProps.filter(t => t.name === 'series'))[0] ? (f.valueProps.filter(t => t.name === 'series'))[0].values[0] : '',
          qCodeCondition: (f.valueProps.filter(t => t.name === 'qCodes'))[0] ? (f.valueProps.filter(t => t.name === 'qCodes'))[0].pattern : '',
          catCondition: (f.valueProps.filter(t => t.name === 'categories'))[0] ? (f.valueProps.filter(t => t.name === 'categories'))[0].pattern : '',
          minFl: {
            lower: (f.rangeProps.filter(t => t.name === 'minFl'))[0] ? (f.rangeProps.filter(t => t.name === 'minFl'))[0].range.from : '',
            upper: (f.rangeProps.filter(t => t.name === 'minFl'))[0] ? (f.rangeProps.filter(t => t.name === 'minFl'))[0].range.to : '',
          },
          maxFl: {
            lower: (f.rangeProps.filter(t => t.name === 'maxFl'))[0] ? (f.rangeProps.filter(t => t.name === 'maxFl'))[0].range.from : '',
            upper: (f.rangeProps.filter(t => t.name === 'maxFl'))[0] ? (f.rangeProps.filter(t => t.name === 'maxFl'))[0].range.to : '',
          },
          editable: true,
        };
        state.filters.push(filter);
      }
    );
    if (state.filters.length === 0) {
      state.filters.push(defaultFilter);
    }
    state.currentFilter = state.filters[0];
    state.originalFilter = JSON.parse(JSON.stringify(state.currentFilter));
  },
  [M.NOTAMFILTER_CANCEL_EDIT](state) {
    const index = state.filters.findIndex((filter) => filter.id === state.currentFilter.id);
    const originalFilter = JSON.parse(JSON.stringify(state.originalFilter));

    state.filters.splice(index, 1, originalFilter);
    state.currentFilter = state.filters[index];
  },
};

export default {
  state,
  actions,
  mutations,
};