import PermissionUtils from "../utils/PermissionUtils";
import {i18n} from "../internationalization/index";

const OP_PRIORITY_CONFIGS = [
  {
    name: 'labels.operationPlan.low',
    value: 'PRIO_LOW',
    accessRight: 'feature_operationPlans_write_low'
  },
  {
    name: 'labels.operationPlan.medium',
    value: 'PRIO_MEDIUM',
    accessRight: 'feature_operationPlans_write_medium'
  },
  {
    name: 'labels.operationPlan.high',
    value: 'PRIO_HIGH',
    accessRight: 'feature_operationPlans_write_high'
  }
]

function getAvailableOpPriorities() {
  return OP_PRIORITY_CONFIGS
    .filter(priority => PermissionUtils.getAvailableOpCreateRights().includes(priority.accessRight))
    .map(priority => {
      return {name: i18n.global.t(priority.name), value: priority.value}
    });
}

function getLowestAvailableOpPriorityValue() {
  return this.getAvailableOpPriorities()[0]?.value;
}

function getDisplayValueForPriority(priority) {
  return i18n.global.t(OP_PRIORITY_CONFIGS.find(conf => conf.value === priority)?.name);
}

export default {
  getAvailableOpPriorities,
  getLowestAvailableOpPriorityValue,
  getDisplayValueForPriority
}