import FeatureType from '../../constants/featureType';
import ThemeElements from '../../constants/theme-elements';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';

export default {

  id: FeatureType.WINDBARB,
  name: 'features.windbarb',
  featureIdentifier: 'id',
  isViewPortBased: true,
  isFlightLevelBased: true,
  isNotificationsDisabled: true,
  maxResolution: 1000,
  declutter: false,
  zIndex: 1,
  flightLevel: null,
  theme: {
    elements: [
      ThemeElements.SHOW_TEXT,
      ThemeElements.TEXT_COLOR,
      ThemeElements.TEXT_SIZE,
      ThemeElements.TEXT_BG_COLOR,
      ThemeElements.IMAGE_COLOR,
      ThemeElements.ICON_SIZE,
    ]
  },
  maxNrOfFeaturesPerLocation: 5000,

  displayOnMap: true,
  displayInMessages: false,

  geoJsonMapper: (feature) => {
    return new Feature({
      geometry: new GeoJSON().readGeometry(feature.location),
      id: feature.id,
      featureType: feature.featureType,
      speed: feature.speed,
      direction: feature.direction,
      flightLevel: feature.flightLevel
    });
  },

  color: (colors) => {
    return colors.imageColor;
  },

  defaultStyle: () => {
    return {
      showText: true,
      textSize: 14,
      iconSize: 17,
      colors: {
        borderColor: {r: 0, g: 46, b: 240, a: 0.9},
        fillColor: {r: 239, g: 231, b: 8, a: 0.52},
        textColor: {r: 255, g: 255, b: 255, a: 1},
        textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
        imageColor: {r: 0, g: 46, b: 240, a: 0.9}
      }
    }
  },

}