<template>
  <div id="group-editor-container" class="col-10" v-if="currentGroup">
    <div>
      <div class="row form-group form-inline">
        <div class="group-editor-group-name">
          <strong>{{ $t('labels.configurationPanel.groupAndRole.groupName') }}:</strong>
          <input
            disabled
            id="groupName"
            type="text"
            :class="['form-control', 'm-1', isValid ? '' : 'is-invalid'].join(' ')"
            v-model="name"
          >
          <span v-if="!isValid" class="text-left invalid-feedback">
            {{ $t('errorMessages.invalidName') }}
          </span>
        </div>
        <div class="pl-3">
          <strong>{{ $t('labels.configurationPanel.groupAndRole.unitOfMeasure') }}:</strong>
          <PropertyDropDown
            class="group-editor-dropdown-list"
            :prop-name="'altitudeUom'"
            :is-mandatory="false"
            :is-valid="true"
            :get-value="getCurrentUom"
            :set-value="setUomValue"
            :options="getUomConfigs"
            id="unit-of-measure-selector"/>
        </div>
      </div>
    </div>
    <div class="row">
      <Themes class="col-4 p-1"/>
      <Users class="col-6 p-1"/>
      <Roles class="col-2 p-1"/>
    </div>
    <div>
      <SavePanel
        :isModified="isModified"
        :isEditable="isEditable"
        :isValid="isValid"
        :saveAction="save"
        :saveAsAction="null"
        :cancelAction="cancel"
      ></SavePanel>
    </div>
  </div>
</template>
<script>

import A from '../../../../constants/actions';
import SavePanel from '../../save-panel/SavePanel.vue';
import DialogUtils from '../../../../utils/DialogUtils';
import Logger from '../../../../utils/LoggerUtils';
import PopupUtils from '../../../../utils/PopupUtils';
import PermissionUtils from '../../../../utils/PermissionUtils';
import UserService from '../../../../services/smartSisUserService';
import UomConfig from '../../../../config/uomConfig';
import PropertyDropDown from '../../../../common/PropertyDropDown';
import {mapGetters} from 'vuex';

import Roles from './Roles.vue';
import Themes from './Themes.vue';
import Users from './Users.vue';

export default {
  name: 'GroupEditor',

  components: {
    Roles,
    SavePanel,
    Themes,
    Users,
    PropertyDropDown,
  },

  computed: {
    ...mapGetters({
      getCurrentGroupUom: 'getCurrentGroupUom'
    }),
    name: {
      get() {
        return this.$store.state.userStore.currentGroup.name;
      },
      set(value) {
        this.$store.dispatch(A.USER_MANAGEMENT_UPDATE_GROUP_NAME, value);
      },
    },
    isModified() {
      const currentGroup = this.$store.state.userStore.currentGroup;
      const originalGroup = this.$store.state.userStore.originalGroup;
      return PermissionUtils.isAllowedToEditViews() ? JSON.stringify(currentGroup) !== JSON.stringify(originalGroup) : false;
    },
    isEditable() {
      return this.$store.state.userStore.currentGroup.editable !== false;
    },
    currentGroup() {
      return this.$store.state.userStore.currentGroup;
    },
    isSuperAdmin() {
      return PermissionUtils.isSuperAdmin();
    },
    isValid() {
      return this.$store.state.userStore.currentGroup.name.length > 0;
    },
    getUomConfigs() {
      return UomConfig.getSupportedUnits();
    }
  },

  methods: {
    save() {
      this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);

      const originalGroup = JSON.parse(JSON.stringify(this.$store.state.userStore.originalGroup));
      const currentGroup = JSON.parse(JSON.stringify(this.$store.state.userStore.currentGroup));
      delete currentGroup.editable;
      if (currentGroup.roles.length === 0) {
        this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
        DialogUtils.errorNotification(this.$i18n.t('errorMessages.groupRoleNoRoleError'));
        return;
      }

      const config = {
        group: currentGroup,
        successCallback: (group) => {
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          this.$store.dispatch(A.USER_MANAGEMENT_SAVE_GROUP, group);
          PopupUtils.success(this.$i18n.t('popupMessages.groupSaved'));
          Logger.info(this.$i18n.t('logMessages.groupRoleGroupSaved', {groupName: currentGroup.name}));
          if (this.isUnitOfMeasureChanged(originalGroup, currentGroup)) {
            this.$store.dispatch(A.UPDATE_START_UPDATE_UOM_CHANGED);
          }
        },
        errorCallback: (error) => {
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          const errorMessage = JSON.parse(error);
          Logger.error(this.$i18n.t('errorMessages.groupRoleGroupSaveError', {groupName: currentGroup.name, error}));
          DialogUtils.errorNotification(errorMessage);
        },
      };
      UserService.saveGroup(config);
    },
    cancel() {
      this.$store.dispatch(A.USER_MANAGEMENT_GROUP_CANCEL_EDIT);
    },
    getCurrentUom() {
      return this.getCurrentGroupUom;
    },
    setUomValue(propName, value) {
      this.$store.dispatch(A.USER_MANAGEMENT_UPDATE_UOM, value);
    },
    isUnitOfMeasureChanged(originalGroup, currentGroup) {
      return originalGroup.altitudeUom !== currentGroup.altitudeUom;
    }
  }

}
</script>
<style src="./groupEditor.css"></style>