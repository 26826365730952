<template>
  <div v-if="isOpen" id="drone-plan-rejection-dialog-container" class="dialogContainer">
    <div class="dialog modal" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div id="drone-plan-rejection-dialog-modal-content" class="modal-content smartSISPanel">
          <div id="drone-plan-rejection-dialog-modal-header" class="modal-header">
            <h5 id="drone-plan-rejection-dialog-modal-title" class="modal-title">
              {{ $t('labels.operationPlan.confirmRejectionOperation') }}
            </h5>
          </div>
          <div id="drone-plan-rejection-dialog-modal-body" class="modal-body errorBody">
            <OpRejectionPropsForm :operation-plan="operationPlan" v-model:rejection-props="rejectionProps"/>
            <p v-html="message"></p>
          </div>
          <div id="drone-plan-rejection-dialog-modal-footer" class="modal-footer">
            <div>
              <button id="op-rejection-reject" class="btn btn-danger" :disabled="!this.rejectionProps.isValid"
                      @click="doRejection">
                {{ $t('labels.common.reject') }}
              </button>
              <button id="op-rejection-cancel" class="btn btn-primary" @click="cancelRejection">
                {{ $t('labels.common.cancel') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import OpRejectionPropsForm from '@/components/op-rejection-props-form/OpRejectionPropsForm';
import {mapGetters} from 'vuex';
import OpRejectionDialogUtils from '@/utils/dronePlans/OpRejectionDialogUtils';

export default {
  name: "OpRejectionDialog",
  components: {
    OpRejectionPropsForm
  },
  computed: {
    ...mapGetters({
      isOpen: 'isOpRejectionDialogOpen',
      message: 'opRejectionDialogMessage',
      operationPlan: 'opRejectionDialogOpInstance'
    })
  },
  data() {
    return {
      rejectionProps: {
        isValid: true
      }
    }
  },
  methods: {
    doRejection() {
      OpRejectionDialogUtils.rejectOperationPlan(this.operationPlan, this.rejectionProps);
      OpRejectionDialogUtils.close();
    },
    cancelRejection() {
      OpRejectionDialogUtils.close();
    }
  }
}
</script>

<style src="./dialog.css"/>