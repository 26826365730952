import ModelUtils from "../utils/ModelUtils";
import Moment from "moment";
import UomUtils from "../utils/UomUtils";
import DateTimeUtils from "../utils/DateTimeUtils";
import {i18n} from '../internationalization/index';

const fields = [
  {
    id: 'geozone-name',
    name: 'labels.common.name',
    propertyName: 'Name',
    displayInList: true,
    displayInForm: true,
    tableStyle: 'name',
    sort: (a, b) => {
      return ModelUtils.sort(a.name, b.name);
    },
    getter(geozone) {
      return geozone.name || geozone.id;
    },
  },
  {
    id: 'geozone-identifier',
    name: 'labels.common.identifier',
    propertyName: 'Identifier',
    displayInList: true,
    displayInForm: true,
    tableStyle: 'identifier',
    sort: (a, b) => {
      return ModelUtils.sort(a.id, b.id);
    },
    getter(geozone) {
      return geozone.id;
    }
  },
  {
    id: 'geozone-start-time',
    name: 'labels.common.startTime',
    propertyName: 'startTime',
    displayInList: true,
    displayInForm: true,
    tableStyle: 'startTime',
    sort: (a, b) => {
      return ModelUtils.sort(a.validTime.from, b.validTime.from);
    },
    getter(geozone) {
      return Moment.utc(geozone.validTime.from).format('YYYY DD MMM HH:mm');
    },
    isTimeDependent: true,
  },
  {
    id: 'geozone-end-time',
    name: 'labels.common.endTime',
    propertyName: 'endTime',
    displayInList: true,
    displayInForm: true,
    tableStyle: 'endTime',
    sort: (a, b) => {
      return ModelUtils.sort(a.validTime.to, b.validTime.to);
    },
    getter(geozone) {
      const endTime = geozone.validTime.to;
      return ModelUtils.timestampYearIs9999(endTime) ?
        i18n.global.t('labels.geozone.noEndTime') : DateTimeUtils.formatTimestampAsDateAndTime(endTime);
    },
  },
  {
    id: 'geozone-min-altitude',
    name: 'labels.common.minAltitude',
    propertyName: 'minAltitude',
    displayInList: true,
    displayInForm: true,
    tableStyle: 'minAltitude',
    sort: (a, b) => {
      const aMinAltitudeValueInCurrentUom = UomUtils.convertAltitudeObjectToCurrentUom(a.minAltitude);
      const bMinAltitudeValueInCurrentUom = UomUtils.convertAltitudeObjectToCurrentUom(b.minAltitude);
      return ModelUtils.sort(aMinAltitudeValueInCurrentUom, bMinAltitudeValueInCurrentUom);
    },
    getter(geozone) {
      const minAltitudeValueInCurrentUom = UomUtils.convertAltitudeObjectToCurrentUom(geozone.minAltitude);
      return ModelUtils.roundToTwoDecimals(minAltitudeValueInCurrentUom) + " " + ModelUtils.constructDisplayedUomAndCrs();
    }
  },
  {
    id: 'geozone-max-altitude',
    name: 'labels.common.maxAltitude',
    propertyName: 'maxAltitude',
    displayInList: true,
    displayInForm: true,
    tableStyle: 'maxAltitude',
    sort: (a, b) => {
      const aMaxAltitudeValueInCurrentUom = UomUtils.convertAltitudeObjectToCurrentUom(a.maxAltitude);
      const bMaxAltitudeValueInCurrentUom = UomUtils.convertAltitudeObjectToCurrentUom(b.maxAltitude);
      return ModelUtils.sort(aMaxAltitudeValueInCurrentUom, bMaxAltitudeValueInCurrentUom);
    },
    getter(geozone) {
      const maxAltitudeValueInCurrentUom = UomUtils.convertAltitudeObjectToCurrentUom(geozone.maxAltitude);
      return ModelUtils.roundToTwoDecimals(maxAltitudeValueInCurrentUom) + " " + ModelUtils.constructDisplayedUomAndCrs();
    }
  },
  {
    id: 'geozone-restriction-type',
    name: 'labels.geozone.restrictionType',
    propertyName: 'restriction',
    displayInList: true,
    displayInForm: true,
    tableStyle: 'restriction',
    sort: (a, b) => {
      return ModelUtils.sort(a.restriction, b.restriction);
    },
    getter(geozone) {
      return geozone.restriction;
    }
  },
  {
    id: 'geozone-reason',
    name: 'labels.common.reason',
    propertyName: 'reason',
    displayInList: false,
    displayInForm: true,
    tableStyle: 'reason',
    getter(geozone) {
      return geozone.reasons.join('; ');
    }
  },
  {
    id: 'geozone-other-reason-info',
    name: 'labels.geozone.otherReasonInformation',
    propertyName: 'otherReasonInfo',
    displayInList: false,
    displayInForm: true,
    tableStyle: 'otherReasonInfo',
    getter(geozone) {
      return geozone.otherReasonInfo;
    }
  },
  {
    id: 'geozone-country',
    name: 'labels.geozone.country',
    propertyName: 'country',
    displayInList: false,
    displayInForm: true,
    tableStyle: 'country',
    getter(geozone) {
      return geozone.country;
    }
  },
  {
    id: 'geozone-type',
    name: 'labels.common.type',
    propertyName: 'type',
    displayInList: false,
    displayInForm: true,
    tableStyle: 'type',
    getter(geozone) {
      return geozone.type;
    }
  },
  {
    id: 'geozone-validity',
    name: 'labels.geozone.validity',
    propertyName: 'validity',
    displayInList: false,
    displayInForm: true,
    tableStyle: 'validity',
    getter(geozone) {
      return geozone.isPermanent ? i18n.global.t('labels.geozone.perm') :
        ModelUtils.constructDisplayedValidity(geozone.validity).join('<br />');
    }
  },
  {
    id: 'geozone-message',
    name: 'labels.geozone.message',
    propertyName: 'message',
    displayInList: false,
    displayInForm: true,
    displayOnSeparateLine: true,
    tableStyle: 'message',
    getter(geozone) {
      return geozone.message;
    }
  },
  {
    id: 'geozone-authority-details',
    name: 'labels.geozone.authorityDetails',
    propertyName: 'authorityDetails',
    displayInList: false,
    displayInForm: true,
    tableStyle: 'authorityDetails',
    displayOnSeparateLine: true,
    getter(geozone) {
      return geozone.authorityDetails
        .map(authority =>
          authority.name +
          (authority?.contactName ? ' || ' + authority.contactName : '') +
          (authority?.email ? ' || ' + authority.email : '') +
          (authority?.phone ? ' || ' + authority.phone : '') +
          (authority?.service ? ' || ' + authority.service : '') +
          (authority?.siteURL ? ' || ' + authority.siteURL : '')
        ).join('<br />');
    }
  }
]

export default {
  name: 'Geozone',
  fields
}