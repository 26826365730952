export default class ThemeElements {

  static get BASE_TOGGLE() {
    return {
      type: 'toggle',
    }
  }

  static get BASE_COLOR() {
    return {
      type: 'color',
    }
  }

  static get BASE_NUMBER() {
    return {
      type: 'number',
    }
  }

  static get SHOW_TEXT() {
    return {
      ...ThemeElements.BASE_TOGGLE,
      id: 'showText',
      name: 'labels.configurationPanel.theme.showText',
      retrieve: (style) => {
        return style.showText;
      },
      update: (style, value) => {
        style.showText = value;
      },
    }
  }

  static get BORDER_COLOR() {
    return {
      ...ThemeElements.BASE_COLOR,
      id: 'borderColor',
      name: 'labels.common.border',
      retrieve: (style) => {
        return style.colors.borderColor;
      },
      update: (style, value) => {
        style.colors.borderColor = value;
      },
    }
  }

  static get FILL_COLOR() {
    return {
      ...ThemeElements.BASE_COLOR,
      id: 'fillColor',
      name: 'labels.common.fill',
      retrieve: (style) => {
        return style.colors.fillColor;
      },
      update: (style, value) => {
        style.colors.fillColor = value;
      },
    }
  }

  static get TEXT_COLOR() {
    return {
      ...ThemeElements.BASE_COLOR,
      id: 'textColor',
      name: 'labels.common.text',
      retrieve: (style) => {
        return style.colors.textColor;
      },
      update: (style, value) => {
        style.colors.textColor = value;
      },
    }
  }

  static get TEXT_BG_COLOR() {
    return {
      ...ThemeElements.BASE_COLOR,
      id: 'textBackgroundColor',
      name: 'labels.configurationPanel.theme.textBackground',
      retrieve: (style) => {
        return style.colors.textBackgroundColor;
      },
      update: (style, value) => {
        style.colors.textBackgroundColor = value;
      },
    }
  }

  static get IMAGE_COLOR() {
    return {
      ...ThemeElements.BASE_COLOR,
      id: 'imageColor',
      name: 'labels.configurationPanel.theme.image',
      retrieve: (style) => {
        return style.colors.imageColor;
      },
      update: (style, value) => {
        style.colors.imageColor = value;
      },
    }
  }

  static get TEXT_SIZE() {
    return {
      ...ThemeElements.BASE_NUMBER,
      id: 'textSize',
      name: 'labels.configurationPanel.theme.textSize',
      measurement: 'px',
      retrieve: (style) => {
        return style.textSize;
      },
      update: (style, value) => {
        style.textSize = value;
      }
    }
  }

  static get ICON_SIZE() {
    return {
      ...ThemeElements.BASE_NUMBER,
      id: 'iconSize',
      name: 'labels.configurationPanel.theme.iconSize',
      measurement: '%',
      retrieve: (style) => {
        return style.iconSize;
      },
      update: (style, value) => {
        style.iconSize = value;
      }
    }
  }

}