<template>
  <div class="alert-reply-details-form-container w-100 h-100">
    <div class="alert-reply-details-form">
      <PropertyInput
        :title='$t("labels.alertReply.replyMessage")'
        prop-name="replyMessage"
        :is-mandatory="true"
        :is-valid="isReplyMessageValid"
        :get-value="getReplyMessage"
        :set-value="setReplyMessage"
        id="message-reply"
      />
      <div class="alert-reply-details-form-buttons">
        <button id="alert-reply-send" class="btn btn-primary m-2" @click="sendReply" :disabled="!isReplyMessageValid">
          {{ $t("labels.common.send") }}
        </button>
        <button id="alert-reply-cancel" class="btn btn-primary m-2" @click="cancel">
          {{ $t("labels.common.cancel") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from 'vuex';
import PropertyInput from '../../common/PropertyInput';
import A from '../../constants/actions';

export default {
  name: "AlertReplyDetails",
  components: {
    PropertyInput,
  },
  computed: {
    ...mapGetters({replyMessage: 'alertReplyDetailsMessage'}),
    isReplyMessageValid() {
      return this.replyMessage && this.replyMessage.length > 0;
    },
  },
  methods: {
    getReplyMessage() {
      return this.replyMessage;
    },
    setReplyMessage(propName, newReplyMessage) {
      this.$store.dispatch(A.ALERT_REPLY_DETAILS_SET_MESSAGE, newReplyMessage);
    },
    sendReply() {
      this.$store.dispatch(A.ALERT_REPLY_DETAILS_SEND);
    },
    cancel() {
      this.$store.dispatch(A.ALERT_REPLY_DETAILS_CANCEL);
    },
  }
}
</script>

<style src="./alertReplyDetails.css"/>
