import FeaturesBase from '../config/features/base';
import Features from '../config/features';
import Store from '../store';
import LayerUtils from './LayerUtils';
import TimeService from '../services/timeService';
import FeatureType from "../constants/featureType";
import OperationPlanState from "../constants/operationPlanState";
import AlertState from "../constants/alertState";
import GlobalConfigurationUtils from "./GlobalConfigurationUtils";
import GlobalConfigurationIdConfig from "../config/GlobalConfigurationIdConfig";
import {i18n} from "../internationalization";

class Filters {

  static cmp(valueA, valueB) {
    const result = valueA === valueB;
    return (result && (valueA === undefined || valueA === null)) ? false : result;
  }

  static filterById(msg, msgList) {
    return msgList.filter(message => {
      return Filters.cmp(msg.id || msg.featureId, message.id || message.featureId);
    });
  }

  static filterByVaa(msg, msgList) {
    return msgList.filter(message => {
      return (message.featureType === 'vaa' && msg.volcanoInfo) ?
        Filters.cmp(msg.volcanoInfo.volcanoName, message.volcanoInfo.volcanoName) : false;
    });
  }

  static filerByTca(msg, msgList) {
    return msgList.filter(message => {
      return (message.featureType === 'tca') ? Filters.cmp(msg.cycloneName, message.cycloneName) : false;
    });
  }

  static filterByIdOrStation(msg, msgList) {
    return msgList.filter(message => {
      return Filters.cmp(msg.id, message.id) ||
        (Filters.cmp(msg.featureType, message.featureType) && Filters.cmp(msg.station, message.station));
    });
  }

  static filterDronesByTrackingId(msg, msgList) {
    return msgList.filter(message => {
      return (message.featureType === 'drones') ? Filters.cmp(msg.trackingId, message.trackingId) : false;
    });
  }

  static filterAircraftsByTrackingId(msg, msgList) {
    return msgList.filter(message => {
      return (message.featureType === 'aircraft') ? Filters.cmp(msg.trackingId, message.trackingId) : false;
    });
  }

  static filterByAirmetSigmet(msg, msgList) {
    return msgList.filter(message => {
      return Filters.cmp(msg.featureType, message.featureType) && ((msg.canceled === true) &&
        Filters.cmp(message.sigmetId, msg.canceledId) || Filters.cmp(message.sigmetId, msg.sigmetId));
    });
  }

  static filterByWarning(msg, msgList) {
    return msgList.filter(message => {
      return (Filters.cmp(msg.status, 'CANCELLATION') &&
        Filters.cmp(msg.featureType, message.featureType) &&
        Filters.cmp(message.station, msg.station) &&
        (Filters.cmp(message.sequenceNr, msg.cancelledId) ||
          Filters.cmp(message.sequenceNr, msg.sequenceNr)));
    });
  }

  static filterByNotam(msg, msgList) {
    return msgList.filter(message => {
      return (Filters.cmp(msg.featureId, message.featureId) ||
        ((msg.type === 'R' || msg.type === 'C') &&
          Filters.cmp(message.featureId, msg.cancelledId)));
    });
  }

  static filterByGamet(msg, msgList) {
    return msgList.filter(message => {
      return (
        Filters.cmp(msg.featureType, message.featureType) &&
        Filters.cmp(message.station, msg.station)
      );
    });
  }

  static filterByFeatureTypeAndId(msg, msgList) {
    return msgList.filter(message => {
      return (
        Filters.cmp(msg.featureType, message.featureType) &&
        Filters.cmp(msg.id, message.id))
    });
  }

  static isMessageMarkedAsFavorite(msg) {
    return Store.state.informationStore.favorites.find(favorite => favorite.id === msg.id);
  }
}

class Timeout {

  static getTimeoutForLayer(id) {
    const layerEnvConfig = LayerUtils.getAllLayersFromTopics().find(layer => layer.id === id);
    return (layerEnvConfig) ? layerEnvConfig.timeout : null;
  }

  static timeoutCheck(msg, productType, timeout) {
    const currentTime = TimeService.currentUtcTime();
    const timeWithOffset = TimeService.currentTimeSliderTime().valueOf();
    if (timeWithOffset < currentTime) {
      return msg.featureType === productType &&
        msg.addedOn > timeWithOffset + timeout * 1000;
    }
    return msg.featureType === productType &&
      msg.addedOn < timeWithOffset - timeout * 1000;
  }

  static getNilMessage(msg, nilTacGenerator) {
    const res = JSON.parse(JSON.stringify(msg));
    res.validTime.from = TimeService.currentTimeSliderTime().valueOf();
    res.validTime.to += 6 * 60 * 60 * 1000;
    res.tac = nilTacGenerator(msg);
    res.seen = false;
    res.src = 'timeout';
    res.generated = true;
    delete res.color;
    return res;
  }

  static getMetTimeoutHandler(timeoutValue) {
    return {
      timeoutValue,
      timeoutCheck: this.timeoutCheck,
      nilMessage: (msg) => {
        return this.getNilMessage(msg, (msg) => {
          const date = TimeService.currentTimeSliderTime().toDate();
          const timeStamp = [
            ('0' + date.getUTCDate()).slice(-2),
            ('0' + date.getUTCHours()).slice(-2),
            ('0' + date.getUTCMinutes()).slice(-2),
            'Z'].join('');
          return msg.featureType.toUpperCase() + ' ' + msg.station + ' ' + timeStamp + ' NIL=';
        });
      }
    };
  }

  static getGametTimeoutHandler(timeoutValue) {
    return {
      timeoutValue,
      timeoutCheck: this.timeoutCheck,
      nilMessage: (msg) => {
        return this.getNilMessage(msg, (msg) => {
          const date = TimeService.currentTimeSliderTime().toDate();
          const timeStamp = [
            ('0' + date.getUTCDate()).slice(-2),
            ('0' + date.getUTCHours()).slice(-2),
            ('0' + date.getUTCMinutes()).slice(-2),
            'Z'].join('');
          return msg.station + ' ' + msg.featureType.toUpperCase() + ' ' + timeStamp + ' NIL=';
        });
      }
    };
  }

  static getValidityTimeAfterTimeout(id) {
    const layerEnvConfig = LayerUtils.getAllLayersFromTopics().find(layer => layer.id === id);
    return (layerEnvConfig) ? layerEnvConfig.timeToKeepAfterTimeout : 604800000;
  }
}

class Mapping {
  static mapFeatureToGeoJson(feature, featureConfig) {
    const mapper = (featureConfig) ? featureConfig.geoJsonMapper : FeaturesBase.defaultMapGeoJsonFeature;
    return mapper(feature);
  }

  static mapFeatureToVolumeGeoJson(feature, volume, featureConfig, index) {
    const mapper = (featureConfig) ? featureConfig.geoJsonVolumeMapper : FeaturesBase.defaultMapGeoJsonFeature;
    return mapper(feature, volume, index);
  }

  static getFeatureType(msg) {
    return (msg && msg.featureType) ? msg.featureType :
      (msg.type === 'Feature' && msg.id) ? msg.id.substring(0, msg.id.indexOf('.')) : 'default';
  }

  static getMinifiedObject(feature) {
    const featureType = Features.getFeature(feature.featureType);
    const minifiedObject = {
      id: feature.id || feature.timesliceId || feature.featureId,
      tac: feature.tac || feature.text || feature.notamText,
      featureType: feature.featureType,
      validTime: feature.validTime,
      groupId: feature.groupId,
      station: feature.station || feature.designator || feature.location,
    };

    return (featureType && featureType.getMinifiedObject) ? featureType.getMinifiedObject(feature, minifiedObject) : minifiedObject;
  }

  static getTabs(f) {
    return f.tabs || [{
      id: f.id,
      featureType: f.id,
      label: f.abbreviation || f.name,
      icon: f.icon,
      iconExtension: f.iconExtension,
      counter: f.counter,
      filter: () => true
    }];
  }

}

class Styling {

  static getFeatureColor(featureId) {
    const feature = Features.getFeature(featureId);
    const currentTheme = Store.state.themeStore.currentTheme;
    const stylingConfig = currentTheme.stylingConfigs.find(conf => conf.layer === feature.id);
    return (stylingConfig && feature.color) ? feature.color(stylingConfig.colors) : getDisplayColorBasedOnTheme();
  }

  static toRgba(color) {
    if (color) {
      return 'rgba(' + color.r + ',' + color.g + ',' + color.b + ',' + color.a + ')';
    }
    return 'rgba(255, 255, 255, 0.5)';
  }

  static fromRgba(rgbaColor) {
    const rgbaValues = rgbaColor.slice(5, -1).split(',');
    return {
      r: parseInt(rgbaValues[0]),
      g: parseInt(rgbaValues[1]),
      b: parseInt(rgbaValues[2]),
      a: parseInt(rgbaValues[3])
    }
  }

  static getOppositeColorOfGivenColor(color) {
    const colorCode = (color.r + color.g + color.b) / 3;
    return colorCode > 128 ? "black" : "white";
  }

  static computeColorAndBgColorStyleByBgColor(backgroundColor) {
    return {
      'color': this.getOppositeColorOfGivenColor(backgroundColor),
      'background-color': this.toRgba(backgroundColor)
    };
  }

}

class Icons {
  static isManuallyCreatedOperationPlan(opMessage) {
    return opMessage !== undefined && this.isOperationPlan(opMessage) && opMessage.createdBySmartsis === true;
  }

  static isAlertForManuallyCreatedOperationPlan(alertMessage) {
    return alertMessage.featureType === FeatureType.DRONE_ALERT &&
      alertMessage.operationPlanIds.some(id => this.isOperationPlanCreatedManuallyAndNotClosed(id));
  }

  static isOperationPlanCreatedManuallyAndNotClosed(opId) {
    const messageMatchingId = Store.state.informationStore.messages.find(msg => msg.id === opId);
    return this.isManuallyCreatedOperationPlan(messageMatchingId) && this.isNotClosedOperationPlan(messageMatchingId);
  }

  static isOperationPlan(message) {
    return message.featureType.indexOf(FeatureType.OPERATION_PLAN) !== -1;
  }

  static isNotClosedOperationPlan(message) {
    return message.state !== OperationPlanState.CLOSED.state
  }

  static alertsForManuallyCreatedOperationPlanExist() {
    const alertsInInfoPanel =
      Store.state.informationStore.messages.filter(msg => msg.featureType === FeatureType.DRONE_ALERT);
    return alertsInInfoPanel.some(alert => Icons.isAlertForManuallyCreatedOperationPlan(alert));
  }

  static getAssociatedAlertsForOperationPlan(opMessage) {
    return Store.state.informationStore.messages.filter(f =>
      (f.featureType === FeatureType.DRONE_ALERT && f.operationPlanIds.includes(opMessage.id))
    );
  }

  static getAssociatedAlertsForOperationPlanId(opId) {
    return Store.state.informationStore.messages.filter(f =>
      (f.featureType === FeatureType.DRONE_ALERT && f.operationPlanIds.includes(opId))
    );
  }
}

class Access {
  static hasAccessToOperationPlanFeature() {
    return [
      FeatureType.OPERATION_PLAN_PROPOSED,
      FeatureType.OPERATION_PLAN_ACCEPTED,
      FeatureType.OPERATION_PLAN_ACTIVATED,
      FeatureType.OPERATION_PLAN_OUTLOOK
    ].some(opFeatureId => Features.isFeatureAvailable(opFeatureId));
  }

  static hasAccessToDroneFlightFeature() {
    return Features.isFeatureAvailable(FeatureType.DRONE_FLIGHT);
  }

  static hasAccessToLaraAirspaceFeature() {
    return Features.isFeatureAvailable(FeatureType.LARA_AIRSPACE);
  }

  static hasAccessToDroneAlertFeature() {
    return Features.isFeatureAvailable(FeatureType.DRONE_ALERT);
  }

  static hasAccessToDroneTelemetryFeature() {
    return Features.isFeatureAvailable(FeatureType.DRONES);
  }

  static hasAccessToSailDetailsFeature() {
    return Features.isFeatureAvailable(FeatureType.SAIL_DETAILS);
  }

  static hasAccessToNotamFeature() {
    return Features.isFeatureAvailable(FeatureType.EVENT);
  }

  static hasAccessToMetarFeature() {
    return Features.isFeatureAvailable(FeatureType.METAR);
  }

  static layersAreConfiguredPerView(layers) {
    let viewProductTypes = Store.state.viewStore.currentView.productTypes;
    return layers.filter(l => !viewProductTypes.includes(l)).length === 0;
  }

  static hasAccessToGeozoneFeature() {
    return [FeatureType.ED269, FeatureType.ED269_OUTLOOK]
      .some(geozoneFeatureType => Features.isFeatureAvailable(geozoneFeatureType));
  }

  static hasAccessToTasks() {
    const dronePlanFeatures = [
      FeatureType.OPERATION_PLAN_PROPOSED,
      FeatureType.OPERATION_PLAN_ACCEPTED,
      FeatureType.OPERATION_PLAN_ACTIVATED,
      FeatureType.OPERATION_PLAN_OUTLOOK
    ];
    let viewProductTypes = Store.getters.getFeaturesConfiguredForCurrentView;
    return dronePlanFeatures.some(dp => viewProductTypes.includes(dp));
  }

  static featureIsConfiguredForView(featureId) {
    return Store.getters.getFeaturesConfiguredForCurrentView.includes(featureId);
  }
}

class State {
  static getOperationState(state, stateAttributeType) {
    return stateAttributeType && stateAttributeType !== OperationPlanState.NONE.state ?
      stateAttributeType : state;
  }

  static getOperationStateToDisplay(state, stateAttributeType, authorizationRequestedBy) {
    const opState = stateAttributeType && stateAttributeType !== OperationPlanState.NONE.state ?
      stateAttributeType : state;
    return getOpDisplayState(opState, authorizationRequestedBy).toUpperCase();
  }

  static existAlertWithUnresolvedState(alerts) {
    return alerts.some(alert => alert.state !== AlertState.RESOLVED && alert.state !== AlertState.ACKNOWLEDGED);
  }
}

function getOpDisplayState(state, authorizationRequestedBy) {
  switch (state) {
    case OperationPlanState.PROPOSED.state:
      return authorizationRequestedBy ? i18n.global.t(OperationPlanState.PROPOSED.displayValue) :
        i18n.global.t(OperationPlanState.PROPOSED.state);
    case OperationPlanState.TAKEOFFREQUESTED.state:
      return i18n.global.t(OperationPlanState.TAKEOFFREQUESTED.displayValue);
    case OperationPlanState.AUTHORIZED.state:
      return i18n.global.t(OperationPlanState.AUTHORIZED.displayValue);
    case OperationPlanState.ACTIVATED.state:
      return i18n.global.t(OperationPlanState.ACTIVATED.displayValue);
    default:
      return state;
  }
}

function getDisplayColorBasedOnTheme() {
  return GlobalConfigurationUtils.getDisplayConfigColorByIdAndByCurrentTheme(
    GlobalConfigurationIdConfig.DISPLAY_CONFIGURATION_IDS.DRONE_PLAN_UPCOMING_TAB
  );
}

export default {

  Filters,
  Timeout,
  Mapping,
  Styling,
  Icons,
  Access,
  State,

}