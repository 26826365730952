import A from '../../constants/actions';
import M from '../../constants/mutations';
import AppConfig from '../../config/appConfig';

const state = {

  messages: [],

};

const actions = {

  [A.POPUP_ADD_MESSAGE]({commit}, message) {
    if (AppConfig.ui.notificationPopupDurationSeconds !== 0) {
      commit(M.POPUP_ADD_MESSAGE, message);
    }
  },

  [A.POPUP_CLEANUP_MESSAGES]({commit}) {
    commit(M.POPUP_CLEANUP_MESSAGES);
  },

};

const mutations = {

  [M.POPUP_ADD_MESSAGE](state, message) {
    message.expirationTime = Date.now() + AppConfig.ui.notificationPopupDurationSeconds * 1000;
    state.messages.push(message);
  },

  [M.POPUP_CLEANUP_MESSAGES](state) {
    const now = Date.now();
    state.messages = state.messages.filter(message => message.expirationTime > now);
  },

};

const getters = {};

export default {
  state,
  actions,
  getters,
  mutations,
};