<template>
  <div :id="`${id}`" class="form-inline row w-100">
        <span class="fixedSizeTitle">
            <strong>
                {{ $t(title) }}<strong v-if="isMandatory" class="text-danger">*</strong>:
            </strong>
        </span>
    <DatePicker
      :get-value="getDate"
      :set-value="setDate"
      :format="format"
      :disabled="disabled"
      :minDate="minDate()"
      :minTime="minTime()"
      :custom-class="['custom-icon', 'p-0', (isValid ? '' : 'invalid')].join(' ')"
      :id="`${id}-${title.toLowerCase()}-datetime-picker`">
    </DatePicker>
  </div>
</template>

<script>
import DatePicker from "./DatePicker";
import TimeService from "../services/timeService";

export default {
  props: ['title', 'propName', 'getValue', 'setValue', 'format', 'isMandatory', 'isValid', 'disabled', 'minDatetime', 'id'],
  components: {
    DatePicker,
  },
  methods: {
    getDate() {
      const date = this.getValue(this.propName);
      if (date) {
        const dt = new Date(this.getValue(this.propName));
        dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
        return dt;
      }
      return '';
    },
    setDate(value) {
      const date = new Date(Date.parse(value));
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      this.setValue(this.propName, date.toISOString());
    },
    minDate() {
      const referenceDatetime = this.minDatetime ? this.minDatetime : TimeService.currentUtcTimeISOFormatted();
      const minDate = new Date(referenceDatetime);
      minDate.setHours(0, 0, 0, 0);
      return minDate;
    },
    minTime() {
      const referenceDatetime = this.minDatetime ? this.minDatetime : TimeService.currentUtcTimeISOFormatted();
      const minDateTime = new Date(referenceDatetime);
      minDateTime.setMinutes(minDateTime.getMinutes() + minDateTime.getTimezoneOffset(), 0, 0);
      const hours = minDateTime.getHours();
      const minutes = minDateTime.getMinutes();
      const selectedDate = this.getDate();
      minDateTime.setMinutes(0, 0, 0);
      selectedDate.setMinutes(0, 0, 0);

      return selectedDate <= minDateTime ? {hours: hours, minutes: minutes} : null;
    },
  }
}
</script>

<style src="./featurePropertyFormField.css"/>