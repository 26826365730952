import Store from '@/store';
import A from '@/constants/actions';
import OperationPlanService from '../../services/operationPlanService';
import OpDialogCallBackUtils from "./OpDialogCallBackUtils";
import UserRolePriorities from "./UserRolePriorities";

export default class OpRevokeDialogUtils {

  static open(operationPlan, message) {
    Store.dispatch(A.OP_REVOKE_DIALOG_OPEN, {operationPlan, message});
  }

  static revokeOperationPlan(operationPlan, message) {
    Store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
    OperationPlanService.revokeOperationPlan({
      operationPlanId: operationPlan.id,
      partialResultId: UserRolePriorities.getPartialResultIdForOpAndCurrentUser(operationPlan),
      reasonMessage: message,
      proposedTimeOffsetInMinutes: null,
      successCallback: OpDialogCallBackUtils.getSuccessCallback(operationPlan),
      errorCallback: OpDialogCallBackUtils.getErrorCallback(operationPlan)
    });
  }

  static close() {
    Store.dispatch(A.OP_REVOKE_DIALOG_CLOSE);
  }
}
