import {Point, LineString, Polygon} from "ol/geom";
import CoordinateUtils from "../../utils/CoordinateUtils";

const GEOZONE_GEOMETRY_CONFIG = [
  {
    name: 'Point',
    isMatchingCoordinatesLength: (length) => length === 1,
    buildGeometry: (coordinates) => new Point(coordinates[0])
  },
  {
    name: 'LineString',
    isMatchingCoordinatesLength:  (length) => length === 2,
    buildGeometry: (coordinates) => new LineString(coordinates)
  },
  {
    name: 'Polygon',
    isMatchingCoordinatesLength:  (length) => length > 2,
    buildGeometry: (coordinates) => {
      if (!CoordinateUtils.areCoordinatesEqual(coordinates[0], coordinates[coordinates.length - 1])) {
        coordinates.push(coordinates[0]);
      }
      return new Polygon([coordinates])
    }
  }
];

function findConfigForCoordinates(coordinates) {
  return GEOZONE_GEOMETRY_CONFIG.find(conf =>  conf.isMatchingCoordinatesLength(coordinates.length));
}

function buildGeometryForCoordinates(coordinates) {
  return findConfigForCoordinates(coordinates)?.buildGeometry(coordinates);
}

export default {
  buildGeometryForCoordinates
}