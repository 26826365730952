import {i18n} from "../internationalization/index";
import ModelUtils from "../utils/ModelUtils";
import DroneFlightDetails from "../constants/droneFlightDetails";
import Store from "../store";
import DateTimeUtils from "../utils/DateTimeUtils";
import DronePlanUtils from "../utils/dronePlans/DronePlanUtils";
import ModeOfOperation from "../constants/modeOfOperation";

function getLinkedOperationPlan(operationPlanId) {
  return Store.getters.getOperationPlanById(operationPlanId);
}

const fields = [
  {
    id: 'dronePlanPublicTitle',
    name: 'labels.droneFlight.dronePlanPublicTitle',
    propertyName: 'dronePlanPublicTitle',
    displayInList: true,
    displayInForm: true,
    sort: (a, b) => {
      const publicTitleA = getLinkedOperationPlan(a.operationPlanId)?.publicTitle || 0;
      const publicTitleB = getLinkedOperationPlan(b.operationPlanId)?.publicTitle || 0;
      return ModelUtils.sort(publicTitleA, publicTitleB);
    },
    getter(droneFlight) {
      return getLinkedOperationPlan(droneFlight.operationPlanId)?.publicTitle ||
        i18n.global.t('labels.operationPlan.publicTitleMissing');
    }
  },
  {
    id: 'flightState',
    name: 'labels.droneFlight.flightState',
    propertyName: 'flightState',
    displayInList: true,
    displayInForm: true,
    sort: (a, b) => {
      return ModelUtils.sort(a.flightState, b.flightState);
    },
    getter(droneFlight) {
      return DroneFlightDetails.getDisplayFlightState(droneFlight.flightState)
    }
  },
  {
    id: 'conformanceState',
    name: 'labels.droneFlight.conformance',
    propertyName: 'conformanceState',
    displayInList: true,
    displayInForm: true,
    sort: (a, b) => {
      return ModelUtils.sort(a.conformanceState, b.conformanceState);
    },
    getter(droneFlight) {
      return DroneFlightDetails.getDisplayConformanceState(droneFlight.conformanceState);
    }
  },
  {
    id: 'emergencyState',
    name: 'labels.droneFlight.emergencyState',
    propertyName: 'emergencyState',
    displayInList: true,
    displayInForm: true,
    sort: (a, b) => {
      return ModelUtils.sort(a.emergencyDeclarationState, b.emergencyDeclarationState);
    },
    getter(droneFlight) {
      return DroneFlightDetails.getDisplayEmergencyState(droneFlight.emergencyDeclarationState);
    }
  },
  {
    id: 'survCooperation',
    name: 'labels.droneFlight.survCooperationState',
    propertyName: 'survCooperationState',
    displayInList: true,
    displayInForm: true,
    sort: (a, b) => {
      return ModelUtils.sort(a.survCooperationState, b.survCooperationState);
    },
    getter(droneFlight) {
      return DroneFlightDetails.getDisplaySurvCooperationState(droneFlight.survCooperationState);
    }
  },
  {
    id: 'responsive',
    name: 'labels.droneFlight.responsive',
    propertyName: 'responsivenessState',
    displayInList: true,
    displayInForm: true,
    sort: (a, b) => {
      return ModelUtils.sort(a.responsivenessState, b.responsivenessState);
    },
    getter(droneFlight) {
      return DroneFlightDetails.getDisplayResponsivenessState(droneFlight.responsivenessState);
    }
  },
  {
    id: 'lastCheck',
    name: 'labels.droneFlight.lastCheck',
    propertyName: 'lastCheck',
    displayInList: false,
    displayInForm: true,
    sort: (a, b) => {
      return ModelUtils.sort(a.timeLastResponsivenessCheck, b.timeLastResponsivenessCheck);
    },
    getter(droneFlight) {
      return droneFlight.timeLastResponsivenessCheck ?
        DateTimeUtils.formatTimestampAsTime(droneFlight.timeLastResponsivenessCheck) : "-";
    }
  },
]

const dronePlanDetailFields = [
  {
    id: 'contactDetailPilotName',
    name: 'labels.droneFlight.pilotName',
    propertyName: 'contactDetailPilotName',
    displayInList: false,
    displayInForm: true,
    getter(droneFlight) {
      const linkedOperationPlan = getLinkedOperationPlan(droneFlight.operationPlanId);
      return linkedOperationPlan ? DronePlanUtils.constructOperatorName(linkedOperationPlan) :
        i18n.global.t('labels.operationPlan.pilotNameMissing');
    },
  },
  {
    id: 'contactDetailPhoneNumber',
    name: 'labels.operationPlan.contactPhone',
    propertyName: 'contactDetailPhoneNumber',
    displayInList: false,
    displayInForm: true,
    getter(droneFlight) {
      const linkedOperationPlan = getLinkedOperationPlan(droneFlight.operationPlanId);
      return linkedOperationPlan?.contactDetails?.phones[0];
    },
  },
  {
    id: 'contactDetailOrganization',
    name: 'labels.droneFlight.pilotOrganization',
    propertyName: 'contactDetailOrganization',
    displayInList: false,
    displayInForm: true,
    getter(droneFlight) {
      const linkedOperationPlan = getLinkedOperationPlan(droneFlight.operationPlanId);
      return linkedOperationPlan?.operator || i18n.global.t('labels.operationPlan.operatorOrganizationMissing');
    },
  },
  {
    id: 'contactDetailEmail',
    name: 'labels.droneFlight.pilotEmail',
    propertyName: 'contactDetailEmail',
    displayInList: false,
    displayInForm: true,
    getter(droneFlight) {
      const linkedOperationPlan = getLinkedOperationPlan(droneFlight.operationPlanId);
      return linkedOperationPlan?.contactDetails?.emails[0];
    },
  },
  {
    id: 'contactDetailModeOfOperation',
    name: 'labels.operationPlan.dronePlanMode',
    propertyName: 'contactDetailModeOfOperation',
    displayInList: false,
    displayInForm: true,
    getter(droneFlight) {
      const linkedOperationPlan = getLinkedOperationPlan(droneFlight.operationPlanId);
      return ModeOfOperation.getDisplayModeOfOperation(linkedOperationPlan?.modeOfOperation);
    },
  },
  {
    id: 'contactDetailLocationAlias',
    name: 'labels.operationPlan.locationAlias',
    propertyName: 'contactDetailLocationAlias',
    displayInList: false,
    displayInForm: true,
    getter(droneFlight) {
      const linkedOperationPlan = getLinkedOperationPlan(droneFlight.operationPlanId);
      const numberOfVolumes = linkedOperationPlan?.volumes?.length || 0;
      return linkedOperationPlan?.volumes?.[numberOfVolumes - 1]?.alias ||
        i18n.global.t('labels.operationPlan.locationAliasMissing');
    },
  },
]

export default {
  name: 'DroneFlight',
  fields,
  dronePlanDetailFields
}