import AppConfig from '../config/appConfig';
import AjaxUtils from '../utils/AjaxUtils';
import PermissionUtils from '../utils/PermissionUtils';

const getAllViewsServiceURL = AppConfig.server.serviceUrlRoot + '/view/_all';
const getUserViewsServiceURL = AppConfig.server.serviceUrlRoot + '/view';
const saveViewURL = AppConfig.server.serviceUrlRoot + '/view';
const deleteViewURL = AppConfig.server.serviceUrlRoot + '/view';

function getAllViews(config) {
  const url = PermissionUtils.isSuperAdmin() ? getAllViewsServiceURL : getUserViewsServiceURL;
  return fetch(AjaxUtils.setParameters(url, [AjaxUtils.getUserNameParam()]),
    {
      method: 'get',
      headers: AjaxUtils.getHeaders(),
    }).then(resp => resp.json())
    .then(data => config.onSuccess(data))
    .catch(error => config.onError(error));
}

function saveView(config) {
  return fetch(AjaxUtils.setParameters(saveViewURL, [AjaxUtils.getUserNameParam()]),
    {
      method: 'put',
      headers: AjaxUtils.getHeaders(),
      body: JSON.stringify(config.view),
    })
    .then(response => {
      if (response.status !== 200 && response.status !== 201) {
        response.text().then(data => {
          config.errorCallback(data);
        });
      } else {
        response.text().then(data => {
          config.successCallback(data);
        });
      }
    })
    .catch(error => config.errorCallback(error));
}

function deleteView(config) {
  return fetch(
    AjaxUtils.setParameters(deleteViewURL, [
      AjaxUtils.getParamObjectFor('viewId', config.viewId),
      AjaxUtils.getUserNameParam(),
    ]),
    {
      method: 'delete',
      headers: AjaxUtils.getHeaders(),
    })
    .then(data => {
      if (!data.ok) {
        config.errorCallback(data);
      } else {
        config.successCallback(data);
      }
    })
    .catch(error => config.errorCallback(error));
}

export default {
  getAllViews,
  saveView,
  deleteView,
};
