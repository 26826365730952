import A from '../../constants/actions';
import M from '../../constants/mutations';
import draggableConfig from '../../config/draggableConfig';
import Store from '../index';
import ConfigurationPanelTabs from '../../config/configurationPanelTabs';
import DialogUtils from '../../utils/DialogUtils';
import HudTabName from '../../constants/hudTabName';
import {i18n} from '../../internationalization/index';
import Features from "../../config/features";
import SubscriptionUtils from "../../utils/SubscriptionUtils";

const state = {
  selectedTabName: 'ViewConfiguration',
  displayFirFilters: false,
  height: draggableConfig.configPanel.minSize,
  listViewHeight: draggableConfig.configPanel.viewListMinSize,
  listViewInformationHeight: draggableConfig.configPanel.listViewInformationHeight,
  listViewNotificationHeight: draggableConfig.configPanel.listViewNotificationHeight,
  listPanelHeight: draggableConfig.configPanel.listPanelMinSize,
  themeViewHeight: draggableConfig.configPanel.themeViewHeight,
  roleViewHeight: draggableConfig.configPanel.roleViewHeight,
  thresholdPanelHeight: draggableConfig.configPanel.thresholdPanel,
  notamFilterPanelHeight: draggableConfig.configPanel.notamFilterPanelHeight,
  utmPanelHeight: draggableConfig.configPanel.utmPanelHeight,
  geoserverLayerConfigPanelHeight: draggableConfig.configPanel.geoserverLayerConfigPanelHeight,
};

const actions = {
  [A.FIRFILTER_PANEL_TOGGLE]({commit}) {
    commit(M.FIRFILTER_PANEL_TOGGLE);
  },

  [A.CONFIGURATION_PANEL_TOGGLE]({commit, dispatch, getters}, newlyOpenedTab) {
    if (getters.isGivenTabOpened(HudTabName.CONFIGURATION)) {
      if (ConfigurationPanelTabs.canChangeTab(state.selectedTabName)) {
        commit(M.HUD_SET_OPENED_TAB, newlyOpenedTab);
        dispatch(A.USER_ADD_USER_TOGGLE, false);
        commit(M.MAP_REFRESH);
      } else {
        DialogUtils.confirmOperation({
          message: i18n.global.t('confirmationMessages.configurationPanelCloseTab'),
          yesAction: () => {
            ConfigurationPanelTabs.resetContentOfTabToOriginal(state.selectedTabName);
            dispatch(A.HUD_SET_OPENED_TAB, newlyOpenedTab);
            dispatch(A.USER_ADD_USER_TOGGLE, false);
            commit(M.MAP_REFRESH);
            const affectedFeatures = Features.getConfiguredAltitudeBasedFeatureTypes();
            dispatch(A.WEBSOCKET_PARTIAL_SUBSCRIBE, SubscriptionUtils.constructPartialSubscriptionConfig(affectedFeatures));
          },
          noAction: () => {
            const isConfigPanelOpened = Store.getters.isGivenTabOpened(HudTabName.CONFIGURATION);
            if (isConfigPanelOpened && Store.state.informationStore.activeTab !== null) {
              dispatch(A.INFORMATION_PANEL_SELECT_TAB, null);
            }
            commit(M.MAP_REFRESH);
          }
        })
      }
    } else {
      dispatch(A.HUD_SET_OPENED_TAB, HudTabName.CONFIGURATION);
      const isConfigPanelOpened = Store.getters.isGivenTabOpened(HudTabName.CONFIGURATION);
      if (isConfigPanelOpened) {
        if (Store.getters.activeInformationTab) {
          dispatch(A.INFORMATION_PANEL_SELECT_TAB);
        }
        if (Store.getters.isFormWrapperOpened) {
          dispatch(A.FORM_WRAPPER_CLOSE);
        }
      }
      commit(M.INFORMATION_PANEL_CLOSE_TASK_TAB);
      commit(M.MAP_REFRESH);
    }
  },

  [A.CONFIGURATION_PANEL_HEIGHT_CHANGE]({commit}, value) {
    commit(M.CONFIGURATION_PANEL_HEIGHT_CHANGE, value);
  },

  [A.CONFIGURATION_PANEL_SELECT_TAB]({commit, dispatch}, tabName) {
    dispatch(A.USER_ADD_USER_TOGGLE, false);
    commit(M.CONFIGURATION_PANEL_SELECT_TAB, tabName);
  }
};

const mutations = {
  [M.FIRFILTER_PANEL_TOGGLE](state) {
    state.displayFirFilters = !state.displayFirFilters;
  },

  [M.CONFIGURATION_PANEL_HEIGHT_CHANGE](state, value) {
    state.height = value;
    state.listViewHeight = value - 290;
    state.listPanelHeight = value - 160;
    state.themeViewHeight = value - 230;
    state.roleViewHeight = value - 240;
    state.thresholdPanelHeight = value - 115;
    state.notamFilterPanelHeight = value - 135;
    state.utmPanelHeight = value - 190;
    state.geoserverLayerConfigPanelHeight = value - 80;
    state.listViewInformationHeight = value - 210;
    state.listViewNotificationHeight = value - 260;
  },

  [M.CONFIGURATION_PANEL_SELECT_TAB](state, tabName) {
    state.selectedTabName = tabName;
  }
};

const getters = {};

export default {
  state,
  actions,
  getters,
  mutations,
};