import A from '../../constants/actions';
import M from '../../constants/mutations';
import {i18n} from '../../internationalization/index';

const DEFAULT_UPDATE_MESSAGE = 'logMessages.defaultUpdateMessage';

const state = {

  isUpdateAvailable: false,
  doUpdate: false,
  updateMessage: null,
  uomChange: null,
};

const actions = {

  [A.UPDATE_NEW_UPDATE_AVAILABLE]({commit}, message) {
    commit(M.UPDATE_NEW_UPDATE_AVAILABLE, message);
  },

  [A.UPDATE_START_UPDATE_UOM_CHANGED]({commit}) {
    commit(M.UPDATE_START_UPDATE_UOM_CHANGED);
  },

  [A.UPDATE_START_UPDATING]({commit, getters}) {
    commit(M.MAP_REMOVE_ALL_LAYERS, getters.getGeoserverLayerIds);
    commit(M.UPDATE_START_UPDATING);
  },

  [A.UPDATE_FINISHED_UPDATING]({commit}) {
    commit(M.UPDATE_FINISHED_UPDATING);
  },

};

const mutations = {

  [M.UPDATE_NEW_UPDATE_AVAILABLE](state, payload) {
    state.isUpdateAvailable = true;
    state.updateMessage = payload.message;
  },

  [M.UPDATE_START_UPDATING](state) {
    state.doUpdate = true;
  },

  [M.UPDATE_FINISHED_UPDATING](state) {
    state.isUpdateAvailable = false;
    state.doUpdate = false;
  },

  [M.UPDATE_START_UPDATE_UOM_CHANGED](state) {
    state.uomChange = Date.now();
  }

};

const getters = {
  getUpdateMessage: state => state.updateMessage || i18n.global.t(DEFAULT_UPDATE_MESSAGE),
};

export default {
  state,
  actions,
  getters,
  mutations,
};