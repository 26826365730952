<template>
  <div>
    <div class="card smartSISPanel">
      <h5 class="card-header">{{ $t('labels.configurationPanel.groupAndRole.views') }}</h5>
      <div class="card-body">
        <div class="list-group viewList" v-bind:style="{height: heightSize}">
          <template v-for="view in views" :key="view.id">
            <div>
              <div class="row m-0" style="flex-wrap: nowrap">
                <div class="p-0 m-0 w-100 hideOverflow">
                  <button
                    :class="['list-group-item', 'smartSISPanel', 'selectViewButton',
                    isDefaultView(view.id) ? 'disabledDefaultButton' : '',
                    isSelected(view.id) ? 'selected' : '', isSelected(view.id) ? 'selected' : ''].join(' ')"
                    :id="'view_' + view.name"
                    :disabled="isDefaultView(view.id)"
                    @click="toggleView(view.id)"
                    type="btn btn-primary"
                  >
                    {{ view.name }}
                  </button>
                </div>
                <div class="text-right p-0 m-0">
                  <button
                    :class="['list-group-item', 'smartSISPanel', 'defaultButton',
                    isDisabled(view.id) ? 'disabledDefaultButton' : '',
                    isDefault(view.id) ? 'selectedDefault' : '']"
                    :disabled="isDisabled(view.id)"
                    :id="'defaultView_' + view.name"
                    @click="toggleDefault(view.id)"
                    type="btn btn-primary"
                  >
                    <span>
                      <img class="pinImg" v-bind:src="getButtonImg('pin')">
                    </span>
                    <span class="defaultText">
                      {{ $t('labels.common.default') }}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import A from '../../../../constants/actions';
import ImgUtils from "../../../../utils/ImgUtils";

export default {
  name: 'Views',
  components: {},
  computed: {
    views() {
      return this.$store.state.viewStore.views;
    },
    heightSize() {
      return this.$store.state.configurationStore.roleViewHeight + 'px';
    },
  },
  methods: {
    getButtonImg(name) {
      return ImgUtils.getButtonImg(name, 'svg');
    },
    isDisabled(viewId) {
      return !this.isSelected(viewId) || this.isDefaultView(viewId);
    },
    isDefaultView(viewId) {
      return viewId === 'Default';
    },
    isSelected(viewId) {
      return this.$store.state.userStore.currentRole.views.some(view => view.viewId === viewId);
    },
    isDefault(viewId) {
      return this.$store.state.userStore.currentRole.views.some(view => view.viewId === viewId && view.defaultView);
    },
    toggleView(viewId) {
      this.$store.dispatch(A.USER_MANAGEMENT_ROLE_TOGGLE_VIEW, viewId);
    },
    toggleDefault(viewId) {
      this.$store.dispatch(A.USER_MANAGEMENT_ROLE_TOGGLE_VIEW_DEFAULT, viewId);
    }
  },
}
</script>
<style src="./roleEditor.css"></style>