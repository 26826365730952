import AppConfig from '../config/appConfig';
import AjaxUtils from '../utils/AjaxUtils';
import PermissionUtils from '../utils/PermissionUtils';
import LoggerUtils from '../utils/LoggerUtils';
import DialogUtils from '../utils/DialogUtils';
import A from '../constants/actions';
import Store from '../store';
import SortingOrderConfig from "../config/sortingOrderConfig";
import {i18n} from '../internationalization/index';

let serverPath = AppConfig.server.serviceUrlRoot;
const saveGroupURL = serverPath + '/group';
const getConflictsOfResponsibilityURL = serverPath + '/getConflictsOfResponsibility';
const defaultGroupRolePairURL = serverPath + '/defaultGroupRolePair';
const getGroupRepresentativesByUserURL = serverPath + '/group/representatives';
const getAllGroupRepresentativesURL = serverPath + '/group/representatives/_all';
const getGroupDetailsURL = serverPath + '/group/';
const getGroupRoleRepresentativesByUserURL = serverPath + '/group/grouproles';
const getAllGroupRoleRepresentativesURL = serverPath + '/group/grouproles/_all';
const getAllUsersURL = serverPath + '/users';
const getUserSortingConfigURL = serverPath + '/getSortingConfigForUser';
const saveUserSortingConfigURL = serverPath + '/saveSortingConfigForUser';
const userDefaultLanguageURL = serverPath + '/user-preferences/language';

function getDefaultGroupRolePair(config) {
  return fetch(AjaxUtils.setParameters(defaultGroupRolePairURL, [
    AjaxUtils.getUserNameParam(),
  ]), {
    method: 'get',
    headers: AjaxUtils.getHeaders(),
  }).then(resp => resp.json())
    .then(data => config.onSuccess(data))
    .catch(() => {
      Store.dispatch(A.USER_MANAGEMENT_GROUPS_READ_DEFAULT_GROUP_ROLE, null);
      LoggerUtils.info(i18n.global.t('logMessages.defaultGroupRoleNotFound'))
    });
}

function getUserSortingConfig(config) {
  return fetch(AjaxUtils.setParameters(getUserSortingConfigURL, [
    AjaxUtils.getUserNameParam(),
  ]), {
    method: 'get',
    headers: AjaxUtils.getHeaders(),
  }).then(resp => resp.json())
    .then(data => config.onSuccess(data))
    .catch(() => {
      Store.dispatch(A.USER_MANAGEMENT_SORTING_CONFIG_READ, SortingOrderConfig.DEFAULT_SORTING_CONFIGS);
      LoggerUtils.info(
        i18n.global.t('logMessages.sortingConfigNotFound', {username: Store.state.userStore.userProfile.username})
      );
    });
}

function getConflictsOfResponsibility(config) {
  return fetch(AjaxUtils.setParameters(getConflictsOfResponsibilityURL, [AjaxUtils.getUserNameParam()]), {
    method: 'get',
    headers: AjaxUtils.getHeaders(),
  }).then(resp => resp.json())
    .then(data => config.onSuccess(data))
    .catch(error => config.onError(error));
}

function saveGroup(config) {
  return fetch(AjaxUtils.setParameters(saveGroupURL, [AjaxUtils.getUserNameParam()]), {
    method: 'put',
    headers: AjaxUtils.getHeaders(),
    body: JSON.stringify(config.group),
  })
    .then(resp => resp.json())
    .then(response => config.successCallback(response))
    .catch(error => config.errorCallback(error));
}

function saveDefaultGroupAndRole(config) {
  return fetch(AjaxUtils.setParameters(defaultGroupRolePairURL, [AjaxUtils.getUserNameParam()]), {
    method: 'put',
    headers: AjaxUtils.getHeaders(),
    body: JSON.stringify(config.groupAndRole),
  })
    .then(resp => resp.json())
    .then(response => config.successCallback(response))
    .catch(error => config.errorCallback(error));
}

function saveUserSortingConfig(config) {
  return fetch(AjaxUtils.setParameters(saveUserSortingConfigURL, [AjaxUtils.getUserNameParam()]), {
    method: 'post',
    headers: AjaxUtils.getHeaders(),
    body: JSON.stringify(config.sortingConfig),
  })
    .then(response => config.successCallback(response))
    .catch(error => config.errorCallback(error));
}

function getGroupDetailsById(config) {
  return fetch(getGroupDetailsURL + config.groupId, {
    method: 'get',
    headers: AjaxUtils.getHeaders(),
  }).then(resp => resp.json())
    .then(response => config.successCallback(response))
    .catch(error => config.errorCallback(error));
}

function getAllGroupRepresentatives(config) {
  const isSuperAdmin = PermissionUtils.isSuperAdmin();
  const url = isSuperAdmin ? getAllGroupRepresentativesURL : getGroupRepresentativesByUserURL;
  const params = isSuperAdmin ? [] : [AjaxUtils.getUserNameParam()];
  return getRepresentatives(config, url, params);
}

function getGroupRoleRepresentatives(config) {
  const isSuperAdmin = PermissionUtils.isSuperAdmin();
  const url = isSuperAdmin ? getAllGroupRoleRepresentativesURL : getGroupRoleRepresentativesByUserURL;
  const params = isSuperAdmin ? [] : [AjaxUtils.getUserNameParam()];
  return getRepresentatives(config, url, params);
}

function getRepresentatives(config, url, params) {
  return fetch(AjaxUtils.setParameters(url, params), {
    method: 'get',
    headers: AjaxUtils.getHeaders(),
  }).then(resp => resp.json())
    .then(response => config.onSuccess(response))
    .catch(error => config.onError(error));
}

function getGroupDetails(config) {
  const groupDetailsConfig = {
    groupId: config.groupId,
    successCallback: (group) => {
      Store.dispatch(A.USER_MANAGEMENT_SELECT_GROUP, group);
      const selectedRole = group.roles.find(r => r.id === config.roleId) || group.roles[0];
      config.onSuccess({group: group, role: selectedRole});
    },
    errorCallback: (error) => {
      LoggerUtils.error(i18n.global.t('errorMessages.getGroupDetailsError', {groupId: config.groupId, error}));
      DialogUtils.errorNotification(error);
    },
  };
  this.getGroupDetailsById(groupDetailsConfig);
}

function getAllUsers(config) {
  return fetch(getAllUsersURL, {
    method: 'get',
    headers: AjaxUtils.getHeaders(),
  }).then(resp => resp.json())
    .then(response => config.onSuccess(response))
    .catch(error => config.onError(error));
}

function getUserDefaultLanguage({onSuccess}) {
  return fetch(AjaxUtils.setParameters(userDefaultLanguageURL, [
    AjaxUtils.getUserNameParam(),
  ]), {
    method: 'get',
    headers: AjaxUtils.getHeaders(),
  }).then(resp => resp.text())
    .then(data => onSuccess(data))
    .catch(() => {
      LoggerUtils.info(
        i18n.global.t('logMessages.defaultLanguageNotFound', {username: Store.state.userStore.userProfile.username})
      );
    });
}

function saveUserDefaultLanguage({successCallback, errorCallback, language}) {
  return fetch(AjaxUtils.setParameters(userDefaultLanguageURL, [
    AjaxUtils.getUserNameParam(),
  ]), {
    method: 'post',
    headers: AjaxUtils.getHeaders(),
    body: language
  }).then(resp => resp.text())
    .then(response => successCallback(response))
    .catch(error => errorCallback(error));
}

export default {
  getAllGroupRepresentatives,
  getGroupDetailsById,
  getConflictsOfResponsibility,
  getDefaultGroupRolePair,
  saveGroup,
  saveDefaultGroupAndRole,
  getGroupRoleRepresentatives,
  getGroupDetails,
  getAllUsers,
  getUserSortingConfig,
  saveUserSortingConfig,
  getUserDefaultLanguage,
  saveUserDefaultLanguage
};
