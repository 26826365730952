<template>
  <div v-if="isOpen" id="altitude-filtered-features-dialog-container" class="dialogContainer">
    <div class="dialog modal" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div id="altitude-filtered-features-dialog-modal-content" class="modal-content smartSISPanel">
          <div id="altitude-filtered-features-dialog-modal-header" class="modal-header">
            <h5 id="altitude-filtered-features-dialog-modal-title" class="modal-title">
              {{ $t('labels.configurationPanel.view.altitudeFilteredFeatures') }}
            </h5>
            <button
              id="close-altitude-filtered-features-dialog"
              :class="['btn'].join(' ')"
              @click="toggleAltitudeFilteredFeaturesDialog"
            >
              <img class="popup-close-button-image" v-bind:src="getButtonImg('close')" alt="Close"/>
            </button>
          </div>
          <div id="altitude-filtered-features-dialog-modal-body" class="modal-body errorBody">
            <div class="pb-3">
              <div class="row altitude-feature-selection"
                   v-for="feature in altitudeFilteredFeatureTypes"
                   :id="`${feature.modelValue}-feature-id`"
                   :key="feature.modelValue">
                <button :class="['btn', 'btn-primary', 'w-100', 'altitude-filtered-feature',
                        isFeatureSelectedForFiltering(feature)? 'selected': ''].join(' ')"
                        :id="`${feature.modelValue}-feature-button-id`"
                        @click="toggleFeature(feature)">
                  {{ $t(feature.displayValue) }}
                </button>
              </div>
            </div>
            <div id="altitude-filtered-features-dialog-modal-footer" class="modal-footer p-0 pt-3">
              <div>
                <button id="altitude-filtered-feature-update-button"
                        class="btn btn-primary"
                        @click="toggleAltitudeFilteredFeaturesDialog">
                  {{ $t('labels.common.update') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import A from "../../constants/actions";
import AltitudeFilteredFeatureTypes from '../../constants/altitudeFilteredFeatureTypes';
import ImgUtils from "../../utils/ImgUtils";

export default {
  name: 'AltitudeFilteredFeaturesDialog',
  computed: {
    ...mapGetters({
      isOpen: 'getIsAltitudeFilteredFeaturesDialogOpened',
    }),
    altitudeFilteredFeatureTypes() {
      return AltitudeFilteredFeatureTypes.altitudeFilteredFeatureTypes;
    }
  },
  methods: {
    getButtonImg(name) {
      return ImgUtils.getButtonImage(name, 'svg');
    },
    isFeatureSelectedForFiltering(feature) {
      return this.$store.getters.getAltitudeFilteredFeatures.some(f => f === feature.modelValue);
    },
    toggleFeature(feature) {
      this.$store.dispatch(A.VIEW_EDITOR_TOGGLE_ALTITUDE_FILTERED_FEATURE, feature.modelValue);
    },
    toggleAltitudeFilteredFeaturesDialog() {
      this.$store.dispatch(A.VIEW_EDITOR_ALTITUDE_FILTERED_FEATURES_DIALOG_TOGGLE);
    }
  }
}

</script>