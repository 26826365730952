<template>
  <div class="col-10">
    <div id="notamFilterEditor" v-bind:style="{height: heightSize}">
      <div class="form-group row w-100 mt-3">
        <div class="col">
          <PropertyInput class="col p-0 my-1" :title="$t('labels.common.name')" :type="'name'"/>
          <PropertyInput :title="$t('labels.common.description')" :type="'description'"/>
          <PropertyInput :title="$t('labels.common.status')" :type="'status'"/>
          <PropertyInput :title="$t('labels.common.type')" :type="'type'"/>
          <PropertyInput :title="$t('labels.configurationPanel.notamFilter.class')" :type="'filterClass'"/>
          <PropertyInput :title="$t('labels.configurationPanel.notamFilter.series')" :type="'series'"/>
        </div>
        <div class="col">
          <PropertyDropDown :title="$t('labels.configurationPanel.notamFilter.filterType')" :type="'filterType'"
                            :options="filterConfig.types"/>
          <PropertyDropDown :title="$t('labels.configurationPanel.notamFilter.traffic')" :type="'traffic'"
                            :options="filterConfig.traffics"/>
          <PropertyDropDown :title="$t('labels.configurationPanel.notamFilter.purpose')" :type="'purpose'"
                            :options="filterConfig.purposes"/>
          <PropertyDropDown :title="$t('labels.configurationPanel.notamFilter.scope')" :type="'scope'"
                            :options="filterConfig.scopes"/>
        </div>
        <QCodes class="col"></QCodes>
        <Categories class="col"></Categories>
        <FlightLevel class="col"></FlightLevel>
      </div>
    </div>
    <SavePanel
      :isModified="isModified"
      :isEditable="isEditable"
      :isValid="isValid"
      :saveAction="save"
      :saveAsAction="saveAs"
      :cancelAction="cancel"
      :deleteAction="deleteFilter"
    ></SavePanel>
  </div>

</template>
<script>
import A from '../../../../constants/actions';
import QCodes from './QCodes.vue';
import Categories from './Categories.vue';
import DialogUtils from '../../../../utils/DialogUtils';
import FlightLevel from './FlightLevel.vue';
import Logger from '../../../../utils/LoggerUtils';
import NotamFilterConfig from '../../../../config/notamFilterConfig';
import NotamFilterService from '../../../../services/smartSisNotamFilterService';
import PropertyInput from './PropertyInput.vue';
import PermissionUtils from '../../../../utils/PermissionUtils';
import PopupUtils from '../../../../utils/PopupUtils';
import PropertyDropDown from './PropertyDropDown.vue';
import SavePanel from '../../save-panel/SavePanel.vue';
import WebsocketSubscriptionService from "../../../../services/websocketSubscriptionService";

export default {
  name: 'NotamFilterEditor',

  components: {
    QCodes,
    Categories,
    FlightLevel,
    PropertyInput,
    PropertyDropDown,
    SavePanel,
  },

  computed: {
    filterConfig() {
      return NotamFilterConfig;
    },
    isModified() {
      const currentFilter = this.$store.state.notamFilterStore.currentFilter;
      const originalFilter = this.$store.state.notamFilterStore.originalFilter;
      return PermissionUtils.isAllowedToEditNotamFilters() ? JSON.stringify(currentFilter) !== JSON.stringify(originalFilter) : false;
    },
    isEditable() {
      return this.$store.state.notamFilterStore.currentFilter.editable !== false;
    },
    isValid() {
      return true;
    },
    heightSize() {
      return this.$store.state.configurationStore.notamFilterPanelHeight + 'px';
    },
  },
  methods: {
    getValue(propName) {
      return this.$store.state.notamFilterStore.currentFilter[propName];
    },
    setProperty(evt) {
      const config = {
        type: evt.target.id,
        value: evt.target.value,
      };
      this.$store.dispatch(A.NOTAMFILTER_UPDATE_PROPERTY, config);
    },
    save() {
      this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
      const currentFilter = this.$store.state.notamFilterStore.currentFilter;

      const config = {
        filter: currentFilter,
        successCallback: () => {
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          this.$store.dispatch(A.NOTAMFILTER_SAVE_NOTAMFILTER);
          PopupUtils.success(this.$i18n.t('popupMessages.filterSaved'));
          Logger.info(this.$i18n.t('logMessages.notamFilterSaved', {currentFilterName: currentFilter.name}));
        },
        errorCallback: (error) => {
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          const errorMessage = JSON.parse(error);
          Logger.error(
            this.$i18n.t('errorMessages.notamFilterSaveError', {currentFilterName: currentFilter.name, error})
          );
          DialogUtils.errorNotification(errorMessage.errorMessage);
        }
      };
      NotamFilterService.saveFilter(config);
      let filterIsAttachedToAView = false;
      this.$store.state.viewStore.views.some(v => {
        if (v.filterIds.includes(currentFilter.id)) {
          filterIsAttachedToAView = true;
          return true;
        }
      });
      if (filterIsAttachedToAView) {
        this.$store.dispatch(A.MAP_WIPE);
        this.$store.dispatch(A.INFORMATION_WIPE);
        WebsocketSubscriptionService.websocketSubscribe(this.$store);
      }
    },
    saveAs(filterName) {
      this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
      const newFilter = this.$store.state.notamFilterStore.currentFilter;
      newFilter.name = filterName;
      newFilter.id = null;
      const conifg = {
        filter: newFilter,
        successCallback: (data) => {
          newFilter.id = data;
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          this.$store.dispatch(A.NOTAMFILTER_SAVE_AS_NOTAMFILTER, newFilter);
          PopupUtils.success(this.$i18n.t('popupMessages.filterSaved'));
          Logger.info(this.$i18n.t('logMessages.notamFilterCreated', {newFilterName: newFilter.name}));
        },
        errorCallback: (error) => {
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          const errorMessage = JSON.parse(error);
          Logger.error(this.$i18n.t('errorMessages.notamFilterCreateError', {error}));
          DialogUtils.errorNotification(errorMessage.errorMessage);
        }
      };
      NotamFilterService.saveFilter(conifg);
    },
    cancel() {
      this.$store.dispatch(A.NOTAMFILTER_CANCEL_EDIT);
    },
    deleteFilter() {
      this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
      const filterId = this.$store.state.notamFilterStore.currentFilter.id;
      const filterName = this.$store.state.notamFilterStore.currentFilter.name;
      const config = {
        filterId,
        successCallback: () => {
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          this.$store.dispatch(A.NOTAMFILTER_DELETE_FILTER, filterId);
          PopupUtils.success(this.$i18n.t('popupMessages.filterDeleted'));
          Logger.info(this.$i18n.t('logMessages.notamFilterDeleted', {filterName}));
        },
        errorCallback: (error) => {
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          const errorMessage = JSON.parse(error);
          Logger.error(this.$i18n.t('logMessages.notamFilterDeleted', {filterName, error}));
          DialogUtils.errorNotification(errorMessage.errorMessage);
        },
      };
      NotamFilterService.deleteFilter(config);
    },
  }
}
</script>
<style src="./notamFilterEditor.css"></style>