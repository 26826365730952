import AppConfig from '../config/appConfig';
import PermissionUtils from './PermissionUtils';
import Features from '../config/features';
import LayersWithMultipleVolumesConfig from '../config/LayersWithMultipleVolumesConfig';
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import {bbox as bboxStrategy} from "ol/loadingstrategy";
import VectorLayer from "ol/layer/Vector";
import styleFunctionService from "../styleFunction/styleFunctionService";
import TileLayer from "ol/layer/Tile";
import {TileWMS, WMTS} from "ol/source";
import {optionsFromCapabilities} from "ol/source/WMTS";
import {WMTSCapabilities} from "ol/format";
import DataFormat from "../constants/dataFormat";
import ThemeElements from "../constants/theme-elements";
import Store from '../store';
import {fromExtent} from "ol/geom/Polygon";
import UomConfig from "../config/uomConfig";
import UomUtils from "./UomUtils";
import XYZ from "ol/source/XYZ";
import GeoserverLayersService from "../services/geoserverLayersService";
import Logger from "./LoggerUtils";
import {i18n} from '../internationalization/index';

export default class LayerUtils {

  static clickPriorities = null;

  static getClickPriorities() {
    if (LayerUtils.clickPriorities === null) {
      LayerUtils.clickPriorities = [];
      Features.getFeatures()
        .forEach(layer => {
          let layerConfig = LayerUtils.getAllLayersFromTopics().find(l => l.id === layer.id && l.clickPriority);
          if (layerConfig) {
            LayerUtils.clickPriorities[layerConfig.id] = layerConfig.clickPriority;
          }
        });
    }

    return LayerUtils.clickPriorities;
  }

  static getAllLayersFromTopics() {
    return AppConfig.topics.map(topic => topic.layers).flat();
  }

  static getAvailableLayersFromTopics() {
    return AppConfig.topics
      .map(topic => topic.layers.filter(layer => PermissionUtils.hasAccessToLayer(layer)))
      .flat();
  }

  static getLayerIdsByTopicNames(selectedTopics) {
    return AppConfig.topics
      .filter(topic => selectedTopics.includes(topic.name))
      .map(topic => topic.layers.filter(layer => PermissionUtils.hasAccessToLayer(layer)).map(layer => layer.id))
      .flat();
  }

  static getLayerSelectorOptions(layers) {
    let options = [];
    let skippedLayers = [];
    layers.forEach(layer => {
      if (!skippedLayers.find(l => l === layer.id)) {
        const group = computeGroupFromLayer(layer);
        skippedLayers = skippedLayers.concat.apply(skippedLayers, group.layerIds);
        if (group.show) {
          options.push(group);
        }
      }
    });
    return options;
  }

  static isLayerWithMultipleVolumes(layerId) {
    return LayersWithMultipleVolumesConfig.layersConfig.some(layer => layer.layerId === layerId);
  }

  static isGeoserverLayer(layerId) {
    return Store.getters.getGeoserverLayers.some(layer => layer.id === layerId);
  }

  static async createGeoserverLayer(layer) {
    const stylingConfig = Store.getters.getStyleConfigForLayer(layer.id);
    const mapLayer = await this.constructMapLayer(layer, stylingConfig);

    const isVisible = Store.getters.activeView.dynamicLayers.includes(layer.id);
    mapLayer?.setVisible(isVisible);
    mapLayer?.set('id', layer.id);
    mapLayer?.setZIndex(0.75);

    return mapLayer;
  }

  static async constructMapLayer(layer, stylingConfig) {
    switch (layer.dataFormat) {
      case DataFormat.WFS:
        return this.createWFSLayer(layer, stylingConfig);
      case  DataFormat.WMS:
        return this.createWMSLayer(layer);
      case DataFormat.WMTS:
        return await this.createWMTSLayer(layer);
      default:
        return null;
    }
  }

  static async createBaseMapLayer(layer) {
    const basemap = await this.constructBaseMapLayer(layer);
    basemap.set('id', 'base');

    return basemap;
  }

  static async constructBaseMapLayer(layer) {
    switch (layer.dataFormat) {
      case DataFormat.XYZ:
        return this.createXYZLayer(layer);
      case  DataFormat.WMS:
        return this.createWMSLayer(layer);
      case DataFormat.WMTS:
        return await this.createWMTSLayer(layer);
      default:
        return null;
    }
  }

  static createXYZLayer(layer) {
    return new TileLayer({
      source: new XYZ({
        url: layer.url,
        crossOrigin: 'anonymous'
      }),
    });
  }

  static createWFSLayer(layer, stylingConfig) {
    const vectorSource = new VectorSource({
      format: new GeoJSON(),
      url: extent => layer.endpoint + LayerUtils.getWfsLayerQuery(extent, layer),
      strategy: bboxStrategy,
      crossOrigin: "anonymous"
    });

    return new VectorLayer({
      source: vectorSource,
      style: styleFunctionService.createStyleFunction(stylingConfig),
    });
  }

  static createWMSLayer(layer) {
    return new TileLayer({
      source: new TileWMS({
        url: layer.endpoint,
        params: this.getWmsLayerSourceParams(layer),
        crossOrigin: "anonymous"
      }),
    });
  }

  static async createWMTSLayer(layer) {
    try {
      const options = await LayerUtils.getWmtsOptionsFromLayer(layer);
      return new TileLayer({
        source: new WMTS(options),
      });
    } catch (exception) {
      Logger.error(i18n.global.t('errorMessages.wmtsLayerCreateError', {layerId: layer.id, exception}));
      return new TileLayer();
    }
  }

  static async getWmtsOptionsFromLayer(layer) {
    const parser = new WMTSCapabilities();
    const layerCapabilities = await GeoserverLayersService.fetchLayerCapabilities(layer.wmtsConfiguration.capabilitiesEndpoint);
    const result = parser.read(layerCapabilities);
    return optionsFromCapabilities(result, {
      layer: layer.wmtsConfiguration.layerId,
      matrixSet: layer.wmtsConfiguration.matrixSetId,
      crossOrigin: 'anonymous'
    });
  }

  static constructDefaultStyleConfigForGeoserverLayer(layer) {
    return {
      id: layer.id,
      name: layer.defaultName,
      abbreviation: layer.defaultName,
      theme: {
        elements: [
          ThemeElements.SHOW_TEXT,
          ThemeElements.BORDER_COLOR,
          ThemeElements.FILL_COLOR,
          ThemeElements.TEXT_COLOR,
        ]
      },
      color: (colors) => {
        return colors.borderColor;
      },
      defaultStyle: () => {
        return this.constructGeoserverStyle(layer)
      },
    }
  }

  static getWmsLayerSourceParams(layer) {
    return layer.altitude ?
      {CQL_FILTER: LayerUtils.constructCQLAltitudeFilter(layer), TILED: true, TIMESTAMP: new Date().getTime()} :
      {};
  }

  static constructGeoserverStyle(layer) {
    return {
      layer: layer.id,
      showText: true,
      isGeoserverLayer: true,
      label: layer.label,
      textSize: 14,
      iconSize: 10,
      colors: {
        borderColor: {r: 78, g: 107, b: 242, a: 1},
        fillColor: {r: 78, g: 107, b: 242, a: 0.3},
        textColor: {r: 255, g: 255, b: 255, a: 1},
        textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
        imageColor: {r: 78, g: 107, b: 242, a: 1},
      }
    };
  }

  static constructExistingGeoserverLayerStyleConfigs(dynamicFeatureStylingConfigs, geoserverLayers) {
    return dynamicFeatureStylingConfigs.filter(stylingConfig =>
      geoserverLayers.some(layer => layer.id === stylingConfig.layer && layer.dataFormat === DataFormat.WFS)
    ).map(styleConfig => {
      return {...styleConfig, isGeoserverLayer: true}
    });
  }

  static constructGeoserverLayerStyleConfigsIfNotExist(dynamicFeatureStylingConfigs, geoserverLayers) {
    return geoserverLayers
      .filter(layer => layer.dataFormat === DataFormat.WFS && !dynamicFeatureStylingConfigs.some(sc => sc.layer === layer.id))
      .map(layer => {
        return LayerUtils.constructGeoserverStyle(layer);
      });
  }

  static getWfsLayerQuery(extent, layer) {
    return layer.altitude ?
      LayerUtils.constructCQLFilterWithAltitudeAndBbox(extent, layer) :
      LayerUtils.constructQueryWithBbox(extent);
  }

  static constructQueryWithBbox(extent) {
    return '&bbox=' + extent.join(',') + ',EPSG:3857';
  }

  static constructCQLFilterWithAltitudeAndBbox(extent, layer) {
    const geometry = fromExtent(extent);
    const geometry4326 = geometry.transform('EPSG:3857', 'EPSG:4326');
    const altitudeFilter = this.constructCQLAltitudeFilter(layer);
    return '&CQL_FILTER=' + altitudeFilter + 'AND BBOX(the_geom,' + geometry4326.getExtent().join(',') + ')';
  }

  static constructCQLAltitudeFilter(layer) {
    const altitude = this.getAltitude(layer);
    return 'altitude>=' + altitude.min + 'AND altitude<=' + altitude.max;
  }

  static getAltitude(layer) {
    const maxFilteringAltitude = Store.getters.getMaxFilteringAltitude;
    const sliderAltitude =
      UomUtils.convert(maxFilteringAltitude, UomConfig.UOM.METERS, UomConfig.FEATURE_UOM_TO_INTERNAL_UOM_ID[layer.altitude.uom]);
    return {
      min: this.findMinAltitude(layer.altitude.steps, sliderAltitude),
      max: this.findMaxAltitude(layer.altitude.steps, sliderAltitude)
    }
  }

  static findMinAltitude(steps, sliderAltitude) {
    const stepsLowerThanSliderAltitude = steps.filter(stepAltitude => stepAltitude <= sliderAltitude);
    return stepsLowerThanSliderAltitude.length > 0 ? Math.max(...stepsLowerThanSliderAltitude) : sliderAltitude;
  }

  static findMaxAltitude(steps, sliderAltitude) {
    const stepsGreaterThanSliderAltitude = steps.filter(stepAltitude => stepAltitude > sliderAltitude);
    return stepsGreaterThanSliderAltitude.length > 0 ? Math.min(...stepsGreaterThanSliderAltitude) : sliderAltitude;
  }
}

function computeGroupFromLayer(layer) {
  const groupedLayerSelector =
    AppConfig.groupedLayerSelectors.find(gls => gls.layerIds.find(lid => lid === layer.id));
  if (groupedLayerSelector) {
    groupedLayerSelector.label = i18n.global.t(groupedLayerSelector.labelPath);
    return groupedLayerSelector;
  }
  return {
    id: layer.id,
    show: true,
    label: i18n.global.t(layer.name || layer.abbreviation),
    color: layer.color,
    layerIds: [layer.id]
  };
}
