<template>
  <div class="op-rejection-reason-form">
    <PropertyDropDown :title="$t('labels.operationPlan.rejectionReason')"
                      :options="rejectionReasonOptions"
                      :get-value="() => currentlySelectedOptionValue"
                      :set-value="setCurrentlySelectedOptionValue"
                      :is-mandatory="displayFreeTextInput"
                      :is-valid="true"
                      id="rejection-reason"/>
    <PropertyInput v-if="displayFreeTextInput"
                   prop-name="free-text-rejection-reason"
                   :get-value="() => freeTextInputValue"
                   :set-value="setFreeTextValue"
                   :is-valid="isCurrentFreeTextValid"
                   id="free-text-rejection-reason"
    />
  </div>
</template>

<script>

import PropertyDropDown from '@/common/PropertyDropDown';
import PropertyInput from '@/common/PropertyInput';
import AppConfig from '@/config/appConfig';

const NO_REJECTION_REASON_OPTION = {name: '', value: null};
const OTHER_REJECTION_REASON_OPTION = {name: 'Other', value: 'Other'};

export default {
  name: 'OpRejectionReasonForm',
  components: {
    PropertyDropDown,
    PropertyInput
  },
  props: ['output'],
  data() {
    return {
      displayFreeTextInput: false,
      currentlySelectedOptionValue: null,
      freeTextInputValue: ''
    };
  },
  computed: {
    rejectionReasonOptions() {
      return [NO_REJECTION_REASON_OPTION, ...this.configuredRejectionReasonOptions, OTHER_REJECTION_REASON_OPTION];
    },
    configuredRejectionReasonOptions() {
      return AppConfig.operationPlanRejectionReasons.map(value => {
        return {name: value, value: value};
      });
    },
    isCurrentFreeTextValid() {
      const numberOfCharacters = this.freeTextInputValue?.length;
      return numberOfCharacters >= 1 && numberOfCharacters <= 256;
    }
  },
  methods: {
    setCurrentlySelectedOptionValue(propName, value) {
      this.currentlySelectedOptionValue = value;
      if (value === OTHER_REJECTION_REASON_OPTION.value) {
        this.displayFreeTextInput = true;
        this.setOutput(this.freeTextInputValue, this.isCurrentFreeTextValid);
      } else {
        this.displayFreeTextInput = false;
        this.freeTextInputValue = '';
        this.setOutput(value, true);
      }
    },
    setFreeTextValue(propName, value) {
      this.freeTextInputValue = value;
      this.setOutput(value, this.isCurrentFreeTextValid);
    },
    setOutput(rejectionReasonValue, isValid) {
      this.output.rejectionReason = rejectionReasonValue;
      this.output.isValid = isValid;
    },
  }
}
</script>

<style src="./opRejectionPropsForm.css"/>
