<template>
  <div id="role-editor-container" class="col-10" v-if="currentRole && isEnabledDisplayOfRoleEditor">
    <div>
      <div class="form-group form-inline">
        <strong>{{ $t('labels.configurationPanel.groupAndRole.roleName') }}:</strong>
        <input
          id="roleName"
          type="text"
          :class="['form-control', 'm-1', isValid ? '' : 'is-invalid'].join(' ')"
          v-model="name"
        >
        <span v-if="!isValid" class="text-left invalid-feedback">
          {{ $t('errorMessages.invalidName') }}
        </span>
      </div>
      <Views/>
    </div>
    <div>
      <SavePanel
        :isModified="isModified"
        :isEditable="isEditable"
        :isValid="isValid"
        :saveAction="save"
        :cancelAction="cancel"
      ></SavePanel>
    </div>
  </div>
</template>
<script>

import A from '../../../../constants/actions';
import SavePanel from '../../save-panel/SavePanel.vue';
import DialogUtils from '../../../../utils/DialogUtils';
import PopupUtils from '../../../../utils/PopupUtils';
import PermissionUtils from '../../../../utils/PermissionUtils';
import UserService from '../../../../services/smartSisUserService';
import Logger from '../../../../utils/LoggerUtils';
import Views from './Views.vue';

export default {
  name: 'RoleEditor',

  components: {
    SavePanel,
    Views,
  },

  computed: {
    name: {
      get() {
        return this.$store.state.userStore.currentRole.name;
      },
      set(value) {
        this.$store.dispatch(A.USER_MANAGEMENT_UPDATE_ROLE_NAME, value);
      },
    },
    isModified() {
      const currentRole = this.$store.state.userStore.currentRole;
      const originalRole = this.$store.state.userStore.originalRole;
      return PermissionUtils.isAllowedToEditViews() ? JSON.stringify(currentRole) !== JSON.stringify(originalRole) : false;
    },
    isEditable() {
      return this.$store.state.userStore.currentGroup.editable !== false;
    },
    currentRole() {
      return this.$store.state.userStore.currentRole;
    },
    isValid() {
      return this.$store.state.userStore.currentRole.name.length > 0;
    },
    isEnabledDisplayOfRoleEditor() {
      return this.$store.state.userStore.enabledDisplayOfRoleEditor;
    }
  },

  methods: {
    save() {
      this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);

      const currentGroup = JSON.parse(JSON.stringify(this.$store.state.userStore.currentGroup));
      delete currentGroup.editable;

      const config = {
        group: currentGroup,
        successCallback: (group) => {
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          this.$store.dispatch(A.USER_MANAGEMENT_SAVE_GROUP, group);
          this.$store.dispatch(A.USER_MANAGEMENT_DISABLE_DISPLAY_OF_ROLE_EDITOR);
          PopupUtils.success(this.$i18n.t('popupMessages.roleSaved'));
          Logger.info(this.$i18n.t('logMessages.groupRoleSaved', {groupName: group.name}));
        },
        errorCallback: (error) => {
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          console.error(this.$i18n.t('errorMessages.groupRoleSaveError') + error);
          Logger.error(this.$i18n.t('logMessages.groupRoleDeleteError'), {groupName: currentGroup.name, error});
          DialogUtils.errorNotification(this.$i18n.t('errorMessages.groupRoleSaveError'));
        },
      };
      UserService.saveGroup(config);
    },
    cancel() {
      this.$store.dispatch(A.USER_MANAGEMENT_RESET_ROLE);
    },
  }

}
</script>
<style src="./roleEditor.css"></style>