<template>
  <div class="progress-bar">
    <div :style="computeProgressBarStyling(message)"/>
  </div>
</template>
<script>

import ProgressBarUtils from "../../utils/ProgressBarUtils";

export default {
  name: 'ProgressBar',
  props: ['message'],
  methods: {
    computeProgressBarStyling(message) {
      return ProgressBarUtils.computeProgressBarStyling(message);
    }
  },

};
</script>
<style src="./progressBar.css"></style>
