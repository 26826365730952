import FeatureType from '../../constants/featureType';
import ThemeElements from '../../constants/theme-elements';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';

export default {

  id: FeatureType.VAISALA,
  name: 'features.vaisala',
  isViewPortBased: true,
  isFlightLevelBased: true,
  isNotificationsDisabled: true,
  maxNrOfFeaturesPerLocation: 5000,
  declutter: false,
  zIndex: 1,
  displayOnMap: true,
  displayInMessages: false,

  theme: {
    elements: [
      ThemeElements.IMAGE_COLOR,
      ThemeElements.ICON_SIZE,
    ]
  },

  color: (colors) => {
    return colors.imageColor;
  },

  geoJsonMapper: (feature) => {
    return new Feature({
      geometry: new Point([feature.geometry.lon, feature.geometry.lat]),
      id: feature.id,
      featureType: feature.featureType,
      speed: feature.speed,
      direction: feature.direction,
    });
  },

  defaultStyle: () => {
    return {
      showText: true,
      textSize: 14,
      iconSize: 20,
      colors: {
        borderColor: {r: 0, g: 46, b: 240, a: 0.9},
        fillColor: {r: 239, g: 231, b: 8, a: 0.52},
        textColor: {r: 255, g: 255, b: 255, a: 1},
        textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
        imageColor: {r: 0, g: 46, b: 240, a: 0.9}
      }
    }
  },
}