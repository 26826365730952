<template>
  <div id="droneAlertDetails" class="droneAlertDetailsContainer w-100 h-100">
    <div class="droneAlertDetails">
      <div v-for="(field, index) in droneAlertDetailsFields" :key="index">
        <PropertyDropDown
          v-if="field.isDropDown"
          :title="field.title"
          :prop-name="field.propName"
          :options="field.options"
          :is-mandatory="field.isMandatory"
          :is-valid="validateField(field.propName)"
          :get-value="getValue"
          :set-value="setValue"
          :id="field.id"
        ></PropertyDropDown>
        <PropertyInput
          v-else
          :title="field.title"
          :prop-name="field.propName"
          :is-mandatory="field.isMandatory"
          :is-valid="validateField(field.propName)"
          :get-value="getValue"
          :set-value="setValue"
          :id="field.id"
        ></PropertyInput>
      </div>
      <button
        id="drone-alert-details-save"
        class="btn btn-primary m-2"
        @click="save()"
        :disabled="hasNotValidValues"
      >
        {{ $t('labels.common.send') }}
      </button>
      <button id="drone-alert-details-cancel" class="btn btn-primary m-2" @click="cancel()">
        {{ $t('labels.common.cancel') }}
      </button>
    </div>
  </div>
</template>
<script>

import A from '../../constants/actions';
import PropertyInput from '../../common/PropertyInput';
import PropertyDropDown from '../../common/PropertyDropDown';
import DroneAlertDetailsFields from '../../model/droneAlertDetails';

export default {
  name: 'DroneAlertDetails',
  components: {
    PropertyInput,
    PropertyDropDown,
  },
  computed: {
    droneAlertDetailsFields() {
      return DroneAlertDetailsFields.fields;
    },
    hasNotValidValues() {
      return DroneAlertDetailsFields
        .getPropNames()
        .map(pn => this.validateField(pn))
        .filter(r => r === false)
        .length > 0;
    },
  },
  methods: {
    validateField(propName) {
      const propValidationValue = propName === "free_text" ?
        this.$store.state.droneAlertDetails.details : this.getValue(propName);
      return DroneAlertDetailsFields.validateField(propName, propValidationValue);
    },
    cancel() {
      this.$store.dispatch(A.DRONE_ALERT_DETAILS_CANCEL);
    },
    save() {
      this.$store.dispatch(A.DRONE_ALERT_DETAILS_SAVE);
    },
    getValue(name) {
      return this.$store.state.droneAlertDetails.details[name] || '';
    },
    setValue(name, value) {
      this.$store.dispatch(A.DRONE_ALERT_DETAILS_UPDATE_FIELD, {name, value});
    },
  }

};
</script>
<style src="./details.css"></style>