import FeatureType from '../../constants/featureType';
import FeatureUtils from '../../utils/FeatureUtils';
import ThemeElements from '../../constants/theme-elements';
import OperationPlan from './operationplan';
import OperationPlaneState from "../../constants/operationPlanState";
import DronePlanUtils from "../../utils/dronePlans/DronePlanUtils";

const OPERATION_PLAN_OUTLOOK_VALID_STATES = [OperationPlaneState.PROPOSED.state, OperationPlaneState.AUTHORIZED.state,
  OperationPlaneState.TAKEOFFREQUESTED.state, OperationPlaneState.ACTIVATED.state];

export default {

  id: FeatureType.OPERATION_PLAN_OUTLOOK,
  name: 'features.operationPlanOutlook.name',
  icon: 'outlook',
  abbreviation: 'features.operationPlanOutlook.abbreviation',
  staticDataType: [FeatureType.RESPONSIBILITY_AREA, FeatureType.AIRSPACE],
  maxNrOfFeaturesPerLocation: 200,
  getReplaceableMessages: FeatureUtils.Filters.filterById,
  featureIdentifier: 'id',
  isViewPortBased: false,
  isFlightLevelBased: true,
  maxResolution: 10000,
  declutter: false,
  zIndex: 10,
  flightLevel: null,
  theme: {
    elements: [
      ThemeElements.SHOW_TEXT,
      ThemeElements.BORDER_COLOR,
      ThemeElements.FILL_COLOR,
      ThemeElements.TEXT_COLOR,
      ThemeElements.TEXT_SIZE,
      ThemeElements.TEXT_BG_COLOR,
    ]
  },
  displayOnMap: true,
  displayInMessages: true,
  associatedMapOnlyFeatureLayerConfig: OperationPlan.associatedMapOnlyFeatureLayerConfig,
  validStates: OPERATION_PLAN_OUTLOOK_VALID_STATES,
  geoJsonMapper: (feature) => {
    return OperationPlan.geoJsonMapper(feature);
  },

  defaultStyle: () => {
    return {
      showText: true,
      textSize: 14,
      iconSize: 10,
      colors: {
        borderColor: {r: 230, g: 242, b: 78, a: 1},
        fillColor: {r: 230, g: 242, b: 78, a: 0.3},
        textColor: {r: 255, g: 255, b: 255, a: 1},
        textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
        imageColor: {r: 230, g: 242, b: 78, a: 1},
      }
    }
  },

  getMinifiedObject(feature, minifiedObject) {
    return OperationPlan.getMinifiedObject(feature, minifiedObject);
  },

  ignoreMessage: (incomingOperationPlan, messages) => {
    const areDuplicated = existentOperationPlan =>
      existentOperationPlan.featureType === FeatureType.OPERATION_PLAN_OUTLOOK &&
      existentOperationPlan.id === incomingOperationPlan.id &&
      existentOperationPlan.state === incomingOperationPlan.state &&
      existentOperationPlan.localApprovalResults === incomingOperationPlan.localApprovalResults &&
      existentOperationPlan.localTakeoffClearanceResults === incomingOperationPlan.localTakeoffClearanceResults;
    return messages.some(areDuplicated);
  },

  filterMessages: (messages) => {
    const hasOutlookFeatureType = msg => msg.featureType && msg.featureType === FeatureType.OPERATION_PLAN_OUTLOOK;
    const isInFilteringTimeInterval = msg => DronePlanUtils.isOutlookOpInFilteringTimeInterval(msg.validTime.from);
    const hasValidState = msg => OPERATION_PLAN_OUTLOOK_VALID_STATES.some(state => state === msg.state);
    return messages.filter(msg => hasOutlookFeatureType(msg) && isInFilteringTimeInterval(msg) && hasValidState(msg));
  },

  sortInfoPanelMessageList(a, b) {
    return OperationPlan.sortInfoPanelMessageList(a, b);
  },

  sortFeaturesList(a, b) {
    return OperationPlan.sortFeaturesList(a, b);
  }

}