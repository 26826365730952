import A from '../../constants/actions';
import M from '../../constants/mutations';
import FormMapLayersUtils from "../../utils/FormMapLayersUtils";
import HudTabName from "../../constants/hudTabName";

const state = {
  opened: false,
  component: null,
  isCurrentModelImported: false,
  isGeometryDrawingStarted: false,
  model: null
};

const actions = {
  [A.FORM_WRAPPER_OPEN]({getters, commit, dispatch}, config) {
    commit(M.FORM_WRAPPER_OPEN, config);
    dispatch(A.INFORMATION_PANEL_SELECT_TAB, null);
    if (getters.isGivenTabOpened(HudTabName.UTM_PANEL)) {
      dispatch(A.UTM_PANEL_TOGGLE);
      dispatch(A.UTM_PANEL_DESELECT_ELEM);
    }
  },

  [A.FORM_WRAPPER_CLOSE]({commit, dispatch}) {
    commit(M.FORM_WRAPPER_CLOSE);
    dispatch(A.MAP_REFRESH);
  },

  [A.FORM_WRAPPER_SET_CURRENT_MODEL_IMPORTED]({commit}) {
    commit(M.FORM_WRAPPER_SET_CURRENT_MODEL_IMPORTED);
  },
};

const mutations = {
  [M.FORM_WRAPPER_OPEN](state, config) {
    state.opened = true;
    state.component = config.component;
    state.model = config.model;
  },

  [M.FORM_WRAPPER_CLOSE](state) {
    state.opened = false;
    state.component = null;
    state.isCurrentModelImported = false;
    state.isGeometryDrawingStarted = false;
    state.model = null;
    FormMapLayersUtils.clearMapInteractionAndFormGeometryLayers();
  },

  [M.FORM_WRAPPER_SET_CURRENT_MODEL_IMPORTED](state) {
    state.isCurrentModelImported = true;
  },

};

const getters = {
  isFormWrapperOpened: state => state.opened,
  getFormWrapperComponent: state => state.component,
  getFormWrapperModel: state => state.model,
  isFormWrapperCurrentModelImported: state => state.isCurrentModelImported,
};

export default {
  state,
  actions,
  mutations,
  getters,
};