<template>
  <div id="opOutlookTabFilter">
    <p>{{ $t('labels.operationPlan.showOutlookDronePlans') }}</p>
    <PropertyDropDown
      :options="timeFilter"
      :is-valid="true"
      :get-value="getValue"
      :set-value="setValue"
      id="outlook-tab-time-filter"
    />
    <p>{{ $t('labels.operationPlan.displayedOutlookOperationPlans', {nr: flightsCountMessage}) }}</p>
    <p v-if="newMessagesOutsideTheCurrentTimeFilter" style="color: red;">
      {{ $t('labels.operationPlan.newPlansReceived') }}
    </p>
    <p v-else-if="arePlansOutsideSelectedTimeFilter">
      {{ $t('labels.operationPlan.thereAreOtherPlans') }}
    </p>
  </div>
</template>
<script>

import A from '../../../constants/actions';
import PropertyDropDown from "../../../common/PropertyDropDown.vue";
import Features from '../../../config/features';
import FeatureType from '../../../constants/featureType';
import OperationOutlookProps from '../../../config/features/operationplan-outlook';

export default {
  name: "OpOutlookTabFilter",
  props: ['messages'],
  components: {
    PropertyDropDown,
  },
  computed: {
    flightsCountMessage() {
      const flightsCount = this.messages.length;
      return flightsCount + " " + (flightsCount === 1 ? this.$i18n.t('labels.operationPlan.flight') :
        this.$i18n.t('labels.operationPlan.flights'));
    },
    timeFilter() {
      return Features.getFeature(FeatureType.OPERATION_PLAN_OUTLOOK).filters;
    },
    newMessagesOutsideTheCurrentTimeFilter() {
      const outlookPlans = this.getAllOutlookPlans();
      const unreadOutlookPlansOutsideSelectedInterval = outlookPlans.filter(om => !this.messages.includes(om)).some(op => !op.seen);
      return outlookPlans.length > this.messages.length && unreadOutlookPlansOutsideSelectedInterval;
    },
    arePlansOutsideSelectedTimeFilter() {
      return this.getAllOutlookPlans().length > this.messages.length;
    }
  },
  methods: {
    getValue() {
      return this.$store.state.utmStore.outlookPanelSelectedFilter;
    },
    setValue(name, value) {
      this.$store.dispatch(A.UTM_PANEL_OUTLOOK_PANEL_SELECT_FILTER, value);
      this.$store.dispatch(A.MAP_REFRESH_LAYERS_BY_FEATURE_TYPE, FeatureType.OPERATION_PLAN_OUTLOOK);
      this.$store.dispatch(A.MAP_REFRESH_LAYERS_BY_FEATURE_TYPE, FeatureType.DRONE_ALERT);
    },
    getAllOutlookPlans() {
      const isOutlookOperation = op => op.featureType === FeatureType.OPERATION_PLAN_OUTLOOK;
      const hasValidOutlookState = op => OperationOutlookProps.validStates.some(s => op.state === s);
      return this.$store.state.informationStore.messages
        .filter(op => isOutlookOperation(op) && hasValidOutlookState(op));
    }
  },
};
</script>
<style src="./opOutlookTabFilter.css"></style>