<template>
  <div class="col-3 p-0 m-0">
    <span id="feature-counter-right" class="nav-counter-right" v-if="!isBaseFeature()">{{ countMessages(1) }}</span>
    <div class="feature-subtypes-counter" v-else>
      <span class="feature-subtypes-counter-element"
            :id="'feature-subtypes-counter-' + featureType.id"
            :style="getBgColor(featureType.id)"
            v-for="featureType in featureSubtypes" :key="featureType.id">
        {{ countMessagesByFeatureType(featureType.id) }}
      </span>
    </div>
  </div>
</template>

<script>
import Features from "../../config/features";
import {mapGetters} from "vuex";
import FeatureUtils from "../../utils/FeatureUtils";
import BaseFeaturesConfig from "../../config/BaseFeaturesConfig";
import FeatureSubtypesUtils from "../../utils/FeatureSubtypesUtils";

export default {
  name: "TabMessagesCounter",
  props: ['tab'],
  computed: {
    ...mapGetters({
      messages: 'getMessages',
    }),
    featureSubtypes() {
      return FeatureSubtypesUtils.getFeatureSubtypesSortedByConfigOrder(this.tab.featureType);
    },
  },
  methods: {
    defaultMessageFilter(messages, featureType) {
      return messages.filter((msg) => msg.featureType === featureType);
    },
    countMessages(index) {
      const feature = Features.getFeature(this.tab.featureType);
      const messageFilter = this.tab.counter ?
        this.tab.counter : feature.filterForCounter || feature.filterMessages || this.defaultMessageFilter;
      const filteredMessages = messageFilter(this.messages, this.tab.featureType, index).filter(this.tab.filter);
      return filteredMessages.length > 99 ? '99+' : filteredMessages.length;
    },
    countMessagesByFeatureType(featureType) {
      const feature = Features.getFeatureById(featureType);
      const messageFilter = feature.filterForCounter || feature.filterMessages || this.defaultMessageFilter;
      const filteredMessages = messageFilter(this.messages, featureType);
      return filteredMessages.length > 99 ? '99+' : filteredMessages.length;
    },
    getBgColor(featureType) {
      const color = FeatureUtils.Styling.getFeatureColor(featureType);
      const bgColor = FeatureUtils.Styling.toRgba(color);
      return {'background-color': bgColor};
    },
    isBaseFeature() {
      return BaseFeaturesConfig.isBaseFeature(this.tab.featureType);
    }
  }
}
</script>

<style src="../information-panel/informationPanel.css"></style>