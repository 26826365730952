<template>
  <div id="task-panel-sub-tabs">
    <div class="feature-subtypes form-inline row extended-margins">
      <button :id="feature.id"
              :class="['feature-subtypes-element', 'btn', 'btn-primary', 'smart-tab', 'col', 'no-borders'].join(' ')"
              v-for="feature in this.features" :key="feature.id"
              :style="subTabStyleObject(feature.id)"
              @click="selectSubTab(feature.id)"
      >
        <span id="sub-tab-nr-of-features" class="nav-counter-right-feature-subtypes">
          {{ countMessages(feature.id) }}
        </span>
        <img id="sub-tab-highlighted-icon" v-if="isTaskPanelSubTabHighlighted(feature.id)"
             class="navImg nav-icon-left-task-sub-tab" v-bind:src="getIconImg()" alt="High.">
        {{ $t(feature.displayName).toUpperCase() }}
      </button>
      <div class="feature-subtypes-border extended-margins" :style="configuredSubTabColor"
           id="task-panel-sub-tabs-border"/>
    </div>
  </div>
</template>

<script>
import A from '../../../constants/actions';
import TaskPanelSubTabsConfig from "../../../config/TaskPanelSubTabsConfig";
import {mapGetters} from "vuex";
import ImgUtils from "../../../utils/ImgUtils";
import GlobalConfigurationUtils from "../../../utils/GlobalConfigurationUtils";
import GlobalConfigurationIdConfig from "../../../config/GlobalConfigurationIdConfig";
import FeatureUtils from "../../../utils/FeatureUtils";

export default {
  name: "TaskPanelSubTabs",
  computed: {
    ...mapGetters({
      selectedSubTabId: 'selectedTaskSubTab',
    }),
    features() {
      return TaskPanelSubTabsConfig.getTaskPanelSubTabs();
    },
    configuredSubTabColor() {
      const taskSubTabColor = GlobalConfigurationUtils.getDisplayConfigColorByIdAndByCurrentTheme(
        GlobalConfigurationIdConfig.DISPLAY_CONFIGURATION_IDS.TASKS_SUBTAB
      );
      return FeatureUtils.Styling.computeColorAndBgColorStyleByBgColor(taskSubTabColor);
    }
  },
  methods: {
    subTabStyleObject(featureId) {
      if (featureId !== this.selectedSubTabId) {
        return undefined;
      }
      return this.configuredSubTabColor;
    },
    countMessages(subTabId) {
      return TaskPanelSubTabsConfig.getNrOfMessagesForTaskPanelSubTab(subTabId);
    },
    selectSubTab(subTabId) {
      this.$store.dispatch(A.INFORMATION_PANEL_UPDATE_SELECTED_TASK_SUB_TAB, subTabId);
    },
    isTaskPanelSubTabHighlighted(subTabId) {
      return TaskPanelSubTabsConfig.isTaskPanelSubTabHighlighted(subTabId);
    },
    getIconImg() {
      return ImgUtils.getButtonImg('prod_pending_plan', 'svg');
    },
  }
}
</script>