import {i18n} from "../internationalization/index";
import ModelUtils from "../utils/ModelUtils";
import Moment from "moment/moment";
import UomUtils from "../utils/UomUtils";
import laraAirspaceReservationStatus from "../constants/laraAirspaceReservationStatus";

const fields = [
  {
    id: 'laraAirspaceName',
    name: 'labels.common.name',
    propertyName: 'laraAirspaceName',
    displayInList: true,
    displayInForm: true,
    sort: (a, b) => {
      return ModelUtils.sort(a.name, b.name);
    },
    getter(laraAirspace) {
      return laraAirspace.name || i18n.global.t('labels.laraAirspace.nameMissing');
    }
  },
  {
    id: 'laraAirspaceIdentifier',
    name: 'labels.common.identifier',
    propertyName: 'laraAirspaceIdentifier',
    displayInList: true,
    displayInForm: true,
    sort: (a, b) => {
      return ModelUtils.sort(a.id, b.id);
    },
    getter(laraAirspace) {
      return laraAirspace.id;
    }
  },
  {
    id: 'laraAirspaceActiveFrom',
    name: 'labels.laraAirspace.airspaceActiveFrom',
    propertyName: 'laraAirspaceActiveFrom',
    displayInList: true,
    displayInForm: true,
    tableStyle: 'startTime',
    sort: (a, b) => {
      return ModelUtils.sort(a.startDate, b.startDate);
    },
    getter(laraAirspace) {
      return Moment.utc(laraAirspace.startDate).format('YYYY DD MMM HH:mm');
    },
  },
  {
    id: 'laraAirspaceActiveUntil',
    name: 'labels.laraAirspace.airspaceActiveUntil',
    propertyName: 'laraAirspaceActiveUntil',
    displayInList: true,
    displayInForm: true,
    tableStyle: 'endTime',
    sort: (a, b) => {
      const aEndTime = a.isPermanent ? Moment.utc().add(100, 'year') : Moment.utc(a.endDate);
      const bEndTime = b.isPermanent ? Moment.utc().add(100, 'year') : Moment.utc(b.endDate);
      return ModelUtils.sort(aEndTime, bEndTime);
    },
    getter(laraAirspace) {
      return laraAirspace.isPermanent ? i18n.global.t('labels.geozone.noEndTime') :
        Moment.utc(laraAirspace.endDate).format('YYYY DD MMM HH:mm');
    },
  },
  {
    id: 'laraAirspaceMinAltitude',
    name: 'labels.common.minAltitude',
    propertyName: 'laraAirspaceMinAltitude',
    displayInList: true,
    displayInForm: true,
    tableStyle: 'minAltitude',
    sort: (a, b) => {
      const aMinAltitudeValueInCurrentUom = UomUtils.convertAltitudeObjectToCurrentUom(a.minAltitude);
      const bMinAltitudeValueInCurrentUom = UomUtils.convertAltitudeObjectToCurrentUom(b.minAltitude);
      return ModelUtils.sort(aMinAltitudeValueInCurrentUom, bMinAltitudeValueInCurrentUom);
    },
    getter(laraAirspace) {
      const minAltitudeValueInCurrentUom = UomUtils.convertAltitudeObjectToCurrentUom(laraAirspace.minAltitude);
      return ModelUtils.roundToTwoDecimals(minAltitudeValueInCurrentUom) + " " + ModelUtils.constructDisplayedUomAndCrs();
    }
  },
  {
    id: 'laraAirspaceMaxAltitude',
    name: 'labels.common.maxAltitude',
    propertyName: 'laraAirspaceMaxAltitude',
    displayInList: true,
    displayInForm: true,
    tableStyle: 'maxAltitude',
    sort: (a, b) => {
      const aMaxAltitudeValueInCurrentUom = UomUtils.convertAltitudeObjectToCurrentUom(a.maxAltitude);
      const bMaxAltitudeValueInCurrentUom = UomUtils.convertAltitudeObjectToCurrentUom(b.maxAltitude);
      return ModelUtils.sort(aMaxAltitudeValueInCurrentUom, bMaxAltitudeValueInCurrentUom);
    },
    getter(laraAirspace) {
      const maxAltitudeValueInCurrentUom = UomUtils.convertAltitudeObjectToCurrentUom(laraAirspace.maxAltitude);
      return ModelUtils.roundToTwoDecimals(maxAltitudeValueInCurrentUom) + " " + ModelUtils.constructDisplayedUomAndCrs();
    }
  },
  {
    id: 'laraAirspaceActiveReservations',
    name: 'labels.laraAirspace.activeReservations',
    propertyName: 'laraAirspaceActiveReservations',
    displayInList: true,
    displayInForm: false,
    sort: (a, b) => {
      const aActiveReservations = a.reservations.filter(r => r.status === laraAirspaceReservationStatus.APPROVED).length;
      const bActiveReservations = b.reservations.filter(r => r.status === laraAirspaceReservationStatus.APPROVED).length;
      return ModelUtils.sort(aActiveReservations, bActiveReservations);
    },
    getter(laraAirspace) {
      return laraAirspace.reservations.filter(r => r.status === laraAirspaceReservationStatus.APPROVED).length;
    }
  },
  {
    id: 'laraAirspaceReservationType',
    name: 'labels.geozone.restrictionType',
    propertyName: 'laraAirspaceReservationType',
    displayInList: false,
    displayInForm: true,
    sort: (a, b) => {
      return ModelUtils.sort(a.type, b.type);
    },
    getter(laraAirspace) {
      return laraAirspace.type;
    }
  },
]

export default {
  name: 'LaraAirspace',
  fields
}