<template>
  <div id="information-panel-feature-list-container" class="smartSISPanel h-100">
    <div class="smart-nav">
      <ul id="information-panel-feature-list" class="smart-nav-main">
        <template v-for="tab in tabs()" :key="tab.id">
          <li
            :class="['smart-tab', isActive(tab) ? 'active' : ''].join(' ')"
            :id="tab.id"
            :style="getStyle(tab)"
          >
            <a
              @click="selectTab(tab)"
            >
              <div class="row m-0">
                <div class="col-9 p-0 m-0">
                  <span class="nav-counter-left" v-if="hasMoreCounters(tab)">{{ countMessages(tab, 0) }}</span>
                  <span class="nav-icon">
                    <img class="navImg" v-bind:src="getButtonImg('prod_' + tab.icon, tab.iconExtension || 'svg')">
                  </span>
                  <span class="nav-top-left-icon" v-if="shouldDisplayTopLeftIcon(tab)">
                    <img class="nav-top-left-icon-img"
                         v-bind:src="getButtonImg(tab.topLeftIcon, tab.topLeftIconExtension)"
                    >
                  </span>
                  <span class="nav-text">{{ $t(tab.label).toUpperCase() }}</span>
                </div>
                <TabMessagesCounter :tab="tab"></TabMessagesCounter>
              </div>
            </a>
          </li>
        </template>
      </ul>
      <div id="information-panel-feature-list-footer" class="smart-nav-footer"
           :key="this.$store.state.informationStore.forceRenderCount">
        <TasksButton v-if="shouldDisplayTaskTab" :message-number="countMessagesForTasksTab()"></TasksButton>
        <RestrictAorButton v-if="this.getCurrentResponsibilityArea() !== ''"></RestrictAorButton>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import A from '../../constants/actions';
import AppConfig from '../../config/appConfig';
import ImgUtils from '../../utils/ImgUtils';
import Features from '../../config/features';
import FeatureUtils from '../../utils/FeatureUtils';
import LayerUtils from '../../utils/LayerUtils';
import RestrictAorButton from '../restrict-aor/restrictAorButton';
import TabMessagesCounter from "./TabMessagesCounter";
import BaseFeaturesConfig from "../../config/BaseFeaturesConfig";
import FeatureSubtypesUtils from "../../utils/FeatureSubtypesUtils";
import TasksButton from "../information-panel/tasks/TasksButton";
import TaskPanelSubTabsConfig from "../../config/TaskPanelSubTabsConfig";
import FeatureType from "../../constants/featureType";

export default {
  name: 'ProductNavigation',
  props: ['bgColor'],
  components: {
    TasksButton,
    TabMessagesCounter,
    RestrictAorButton,
  },
  computed: {
    ...mapGetters({
      messages: 'getMessages',
    }),
    shouldDisplayTaskTab() {
      return FeatureUtils.Access.hasAccessToTasks();
    }
  },
  methods: {
    shouldDisplayTopLeftIcon(tab) {
      return tab.topLeftIcon && tab.topLeftIconExtension && tab.shouldDisplayTopLeftIcon();
    },
    getButtonImg(icon, extension) {
      return ImgUtils.getButtonImg(icon, extension);
    },
    countMessages(tab, index) {
      const feature = Features.getFeature(tab.featureType);
      const messageFilter = tab.counter ?
        tab.counter : feature.filterForCounter || feature.filterMessages || this.defaultMessageFilter;
      const filteredMessages = messageFilter(this.messages, tab, index).filter(tab.filter);
      return filteredMessages.length > 99 ? '99+' : filteredMessages.length;
    },
    countMessagesForTasksTab() {
      return TaskPanelSubTabsConfig.getTotalNrOfMessagesDisplayedInTaskPanel();
    },
    tabs() {
      const features = this.getFeaturesForCurrentView();
      let featureTabs = this.replaceFeaturesWithBaseFeature(features);
      featureTabs = this.filterDronesTabByDroneFlightTab(featureTabs);
      const tabs = featureTabs.flatMap(f => FeatureUtils.Mapping.getTabs(f));
      if (tabs.length === 0) {
        this.$store.dispatch(A.INFORMATION_PANEL_SELECT_TAB, null);
      }
      return tabs;
    },
    getFeaturesForCurrentView() {
      const currentView = JSON.parse(JSON.stringify(this.$store.state.viewStore.currentView));
      currentView.productTypes = this.filterDroneFlightDronesProductTypeByDroneFlight(currentView.productTypes);
      let layerIdsForSelectedTopics = LayerUtils.getLayerIdsByTopicNames(this.$store.state.informationStore.selectedTopics);
      if (layerIdsForSelectedTopics.length > 0 && AppConfig.ui.showDirectoryPanel) {
        return Features.getInfoPanelFeatures().filter(product =>
          (!currentView || currentView.productTypes.includes(product.id)) && layerIdsForSelectedTopics.includes(product.id));
      } else {
        return Features.getInfoPanelFeatures().filter(product => !currentView || currentView.productTypes.includes(product.id));
      }
    },
    replaceFeaturesWithBaseFeature(features) {
      const outDuplicates = (currentFeature, currentIndex, featuresArray) =>
        featuresArray.findIndex(otherFeature => currentFeature.id === otherFeature.id) === currentIndex;
      return features.map(feature => this.convertToBaseFeatureIfNecessary(feature)).filter(outDuplicates);
    },
    convertToBaseFeatureIfNecessary(feature) {
      return BaseFeaturesConfig.belongsToABaseFeature(feature.id) ?
        BaseFeaturesConfig.getBaseFeatureFor(feature.id) : feature;
    },
    isActive(tab) {
      return this.$store.state.informationStore.activeTab && this.$store.state.informationStore.activeTab.id === tab.id;
    },
    selectTab(tab) {
      if (BaseFeaturesConfig.isBaseFeature(tab.featureType)) {
        tab.featureType = FeatureSubtypesUtils.getDefaultSelectedFeatureSubtype(tab.featureType);
      }
      this.$store.dispatch(A.INFORMATION_PANEL_SELECT_TAB, tab);
      this.$store.dispatch(A.REMOVE_SELECT_FEATURE);
    },
    getStyle(tab) {
      const messages = this.$store.state.informationStore.messages;
      const containsUnread = messages.find(msg => msg.featureType === tab.featureType && !msg.seen && !msg.generated && tab.filter(msg));
      if (this.isActive(tab) || containsUnread) {
        const color = FeatureUtils.Styling.getFeatureColor(tab.featureType);
        const bgColor = 'rgba(' + [color.r, color.g, color.b, color.a].join(',') + ')';
        return {'background-color': bgColor}
      }
    },
    getCurrentResponsibilityArea() {
      const responsibilityAreaDesignators = this.$store.getters.getCurrentResponsibilityAreas;
      if (responsibilityAreaDesignators === undefined || responsibilityAreaDesignators.length <= 0) {
        return '';
      }
      return responsibilityAreaDesignators[0];
    },
    hasMoreCounters(tab) {
      return tab.counter;
    },
    filterDronesTabByDroneFlightTab(featureTabs) {
      if (featureTabs.find(ft => ft.id === FeatureType.DRONE_FLIGHT)) {
        return featureTabs.filter(ft => ft.id !== FeatureType.DRONES);
      }
      return featureTabs;
    },
    filterDroneFlightDronesProductTypeByDroneFlight(productTypes) {
      if (!productTypes.includes(FeatureType.DRONE_FLIGHT)) {
        return productTypes.filter(t => t !== FeatureType.DRONE_FLIGHT_UNIDENTIFIED);
      }
      return productTypes;
    },
  }
}
</script>
<style src="../information-panel/informationPanel.css"></style>