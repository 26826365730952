import FeatureType from '../../constants/featureType';
import FeatureUtils from '../../utils/FeatureUtils';
import ThemeElements from '../../constants/theme-elements';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';

export default {

  id: FeatureType.GAMET,
  name: 'features.gamet',
  icon: 'gamet',
  staticDataType: [FeatureType.AIRSPACE],
  isViewPortBased: false,
  isFlightLevelBased: false,
  maxNrOfFeaturesPerLocation: 1,
  getReplaceableMessages: FeatureUtils.Filters.filterByGamet,
  timeoutHandler: FeatureUtils.Timeout.getGametTimeoutHandler(FeatureUtils.Timeout.getTimeoutForLayer(FeatureType.GAMET)),
  featureIdentifier: 'id',
  maxResolution: 10000,
  declutter: true,
  zIndex: 1,
  flightLevel: null,
  theme: {
    elements: [
      ThemeElements.SHOW_TEXT,
      ThemeElements.BORDER_COLOR,
      ThemeElements.FILL_COLOR,
      ThemeElements.TEXT_COLOR,
      ThemeElements.TEXT_SIZE,
      ThemeElements.TEXT_BG_COLOR,
      ThemeElements.IMAGE_COLOR,
      ThemeElements.ICON_SIZE,
    ]
  },
  displayOnMap: true,
  displayInMessages: true,

  geoJsonMapper: (feature) => {
    let ident = ' GAMET ' + (feature.station ? feature.station :
      ((feature.atsUnit && feature.atsUnit.designator) ? feature.atsUnit.designator : ''));

    return new Feature({
      geometry: new GeoJSON().readGeometry(feature.geometry),
      id: feature.id,
      station: feature.station,
      ident: ident,
      pressure: feature.pressureCentresAndFronts.pressure,
      designator: feature.designator,
      maxResolution: 10000,
      featureType: 'gamet'
    });
  },

  color: (colors) => {
    return colors.imageColor;
  },

  defaultStyle: () => {
    return {
      showText: true,
      textSize: 14,
      iconSize: 10,
      colors: {
        borderColor: {r: 100, g: 130, b: 220, a: 1},
        fillColor: {r: 240, g: 228, b: 255, a: 0.1},
        textColor: {r: 255, g: 255, b: 255, a: 1},
        textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
        imageColor: {r: 100, g: 130, b: 220, a: 1}
      }
    }
  },

}