<template>
  <div>
    <div>
      <span class="fixedSizeTitle"><strong>{{ $t('labels.configurationPanel.gisLayers.endpointUrl') }}:</strong></span>
      <input
        id="endpoint"
        type="text"
        :disabled="isCswServiceConnected"
        :class="['form-control', 'm-1'].join(' ')"
        @keyup="onChange"
        @change="onChange"
        :value="getValue('endpoint')"
      >
    </div>
    <div>
      <span class="fixedSizeTitle"><strong>{{ $t('labels.common.user') }}:</strong></span>
      <input
        id="user"
        type="text"
        :disabled="isCswServiceConnected"
        :class="['form-control', 'm-1'].join(' ')"
        @keyup="onChange"
        @change="onChange"
        :value="getValue('user')"
      >
    </div>
    <div>
      <span class="fixedSizeTitle"><strong>{{ $t('labels.configurationPanel.gisLayers.password') }}:</strong></span>
      <input
        id="password"
        type="password"
        :disabled="isCswServiceConnected"
        :class="['form-control', 'm-1'].join(' ')"
        @keyup="onChange"
        @change="onChange"
        :value="getValue('password')"
      >
    </div>
    <button id="csw-service-connect"
            class="btn btn-primary m-1"
            :disabled="isCswServiceConnected || !isValidCswServiceConfig"
            @click="connectToCswService">
      {{ $t('labels.configurationPanel.gisLayers.connect') }}
    </button>
    <button id="csw-service-reset"
            class="btn btn-primary m-1"
            :disabled="!isCswServiceConnected"
            @click="clearCswServiceConfig">
      {{ $t('labels.common.clear') }}
    </button>
    <button id="csw-service-back"
            class="btn btn-primary m-1"
            @click="hideCswServiceConnectorForm">
      {{ $t('labels.common.back') }}
    </button>
  </div>
</template>

<script>
import A from '../../constants/actions';
import {mapGetters} from "vuex";

export default {
  name: "GeoserverCswServiceConnector",
  data() {
    return {
      cswServiceConfig: {
        endpoint: null,
        user: null,
        password: null
      }
    }
  },
  computed: {
    ...mapGetters({
      isCswServiceConnected: 'getIsCswServiceConnected'
    }),
    isValidCswServiceConfig() {
      const cswServiceConfig = this.$store.getters.getCurrentConfigCswServiceConfig;
      return cswServiceConfig.endpoint;
    }
  },
  methods: {
    onChange(evt) {
      this.$store.dispatch(A.LAYERS_CONFIG_UPDATE_CSW_SERVICE_PROPERTY,
        {
          name: evt.target.id,
          value: evt.target.value
        });
    },
    getValue(attribute) {
      return this.$store.getters.getCurrentConfigAttributeValue(attribute);
    },
    connectToCswService() {
      this.$store.dispatch(A.LAYERS_CONFIG_CONNECT_TO_CSW_SERVICE);
    },
    clearCswServiceConfig() {
      this.$store.dispatch(A.LAYERS_CONFIG_CLEAR_CSW_SERVICE_CONNECTION);
    },
    hideCswServiceConnectorForm() {
      this.clearCswServiceConfig();
      this.$store.dispatch(A.LAYERS_CONFIG_TOGGLE_CONNECTOR_AND_AVAILABLE_LAYERS);
    }
  }
}
</script>