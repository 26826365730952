import AjaxUtils from '../utils/AjaxUtils';
import AppConfig from '../config/appConfig';
import DialogUtils from '../utils/DialogUtils';
import MetDataProcessingService from './metDataProcessingService';
import NotamFilterService from './smartSisNotamFilterService';

import Store from '../store';
import Features from '../config/features';
import TimeService from './timeService';
import {i18n} from '../internationalization/index';

const smartSisQueryUrl = AppConfig.server.queryServiceURL;
const smartSisStaticDataQueryUrl = AppConfig.server.serviceUrlRoot + '/smartSisAixmDataQuery';
const smartSisRemoveMessageFromDBUrl = AppConfig.server.serviceUrlRoot + '/message';
const smartSisQueryByIdUrl = AppConfig.server.serviceUrlRoot + '/smartSisQueryById';

function smartSisRemoveMessage(config) {
  const url = smartSisRemoveMessageFromDBUrl + '?featureId=' + config.messageId + '&featureType=' + config.featureType;
  fetch(url, {
    method: 'delete',
    headers: AjaxUtils.getHeaders(),
  }).then(resp => {
    if (resp.status === 200) {
      config.onSuccess(resp);
    } else {
      config.onError(resp);
    }
  })
    .catch(error => config.onError(error));
}

function constructQueryFilters(filters) {
  if (filters.length !== 0) {
    filters = filters.map(filter => {
      return NotamFilterService.mapFilter(filter);
    });
  }
  return filters;
}

function smartSisQuery(featureTypes, locations, viewPort, filters) {
  return {
    username: 'anonymous',
    validAt: TimeService.currentTimeSliderTime().valueOf(),
    viewId: Store.state.viewStore.currentViewId,
    buckets: featureTypes.map(featureType => {
      const config = Features.getFeature(featureType);
      const minAltitudeInMeters = AppConfig.heightSlider.heightSliderEnabled && Store.getters.getAltitudeFilteredFeatures.length > 0
        ? Store.getters.getMinFilteringAltitude
        : AppConfig.heightSlider.minimumHeightLimitInMeters;
      const maxAltitudeInMeters = AppConfig.heightSlider.heightSliderEnabled && Store.getters.getAltitudeFilteredFeatures.length > 0
        ? Store.getters.getMaxFilteringAltitude
        : AppConfig.heightSlider.maximumHeightLimitInMeters;
      return {
        featureTypes: [featureType],
        locations: locations,
        maxFeaturesPerLocation: (config) ? config.maxNrOfFeaturesPerLocation : 30,
        maxAltitudeInMeters,
        minAltitudeInMeters,
        viewPort: viewPort,
        notamFilters: constructQueryFilters(filters),
      }
    }),
    providerId: AppConfig.providerId,
  };
}

function queryMetData(featureTypes, locations, viewPort, filters, hook = null) {
  const body = smartSisQuery(featureTypes, locations, viewPort, filters);
  return fetch(smartSisQueryUrl, {
    method: 'post',
    headers: AjaxUtils.getHeaders(),
    body: JSON.stringify(body)
  }).then((resp) => {
    if (resp.ok) {
      return resp.json();
    } else {
      resp.json().then(function (err) {
        console.error(err);
      });
      throw new Error(i18n.global.t('errorMessages.metDataError'));
    }
  })
    .then(function (data) {
      const features = MetDataProcessingService.processMetData(data);
      if (hook) hook(features);
    })
    .catch(function (error) {
      console.error(i18n.global.t('errorMessages.metDataQueryError', {error}));
      DialogUtils.errorNotification(error.message);
    });
}

function queryMetDataById(query, hook = null) {
  return fetch(smartSisQueryByIdUrl, {
    method: 'post',
    headers: AjaxUtils.getHeaders(),
    body: JSON.stringify(query)
  }).then((resp) => {
    if (resp.ok) {
      return resp.json();
    } else {
      resp.json().then(function (err) {
        console.error(err);
      });
      throw new Error(i18n.global.t('errorMessages.metDataError'));
    }
  })
    .then(function (data) {
      const features = MetDataProcessingService.processMetData(data, true);
      if (hook) hook(features);
    })
    .catch(function (error) {
      console.error(i18n.global.t('errorMessages.metDataQueryError', {error}));
      DialogUtils.errorNotification(error.message);
    });
}

function queryStaticData(featureTypes, locations) {
  let filteredLocations = [];
  featureTypes.forEach((featureType) => {
    const existingLocations = Store.state.informationStore.staticData[featureType].map(ft => ft.station || ft.designator);
    const newLocations = locations
      .filter(l => l.locationType === featureType)
      .filter(l => existingLocations.indexOf(l.ic) === -1);
    filteredLocations = filteredLocations.concat.apply(filteredLocations, newLocations);
  });
  if (filteredLocations.length === 0) {
    console.log(i18n.global.t('logMessages.noNewLocations'));
    return null;
  }
  let body = {
    username: 'anonymous',
    validAt: TimeService.currentTimeSliderTime().valueOf(),
    buckets: [{
      featureTypes: featureTypes,
      locations: filteredLocations,
      maxFeaturesPerLocation: 1,
    }],
    providerId: AppConfig.providerId,
  };
  return fetch(smartSisStaticDataQueryUrl, {
    method: 'post',
    headers: AjaxUtils.getHeaders(),
    body: JSON.stringify(body)
  }).then((resp) => {
    if (resp.ok) {
      return resp.json();
    } else {
      resp.json().then(function (err) {
        console.error(err);
      });
      throw new Error(i18n.global.t('errorMessages.staticDataError'));
    }
  })
    .then(function (data) {
      MetDataProcessingService.processStaticData(data);
    })
    .catch(function (error) {
      console.error(i18n.global.t('errorMessages.staticDataQueryError', {error}));
      DialogUtils.errorNotification(error.message);

    });
}

export default {
  constructQueryFilters,
  queryMetData,
  queryMetDataById,
  queryStaticData,
  smartSisRemoveMessage,
}