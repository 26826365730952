<template>
  <div id="servicesStates" class="servicesStatesContainer w-100 h-100">
    <div class="servicesForm">
      <div class="servicesStates">
        <div class="headingCentered" v-if="isUpdateRunning">
          <strong>
            <div style="color:red"> {{ $t('labels.hud.installingUpdate') }}</div>
            {{ $t('labels.hud.plannedFinish') }}: {{ plannedUpdateEnd }}
          </strong>
        </div>
        <div class="table-row header">
          <div class="text" v-for="header in headers" :key="header.name">{{ header.name }}</div>
        </div>
        <div class="rowContainer">
          <div class="table-row" v-for="(services, index) in getServices" :key="index">
            <div
              class="text"
              v-for="service in services"
              :key="service.name"
            >
              {{ service }}
            </div>
          </div>
        </div>
        <button id="close-services-states" class="btn btn-info m-2" @click="close()">
          {{ $t('labels.common.close') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>

import A from '../../constants/actions';
import TimeService from '../../services/timeService';

export default {
  name: 'ServicesStates',
  components: {},
  computed: {
    plannedUpdateEnd() {
      const d = new Date(this.$store.state.hudStore.health.servicesUpdatedUntil);
      var hr = d.getUTCHours();
      var min = d.getUTCMinutes();
      return (hr < 10 ? "0" + hr : hr) + ":" + (min < 10 ? "0" + min : min);
    },
    isUpdateRunning() {
      return (this.$store.state.hudStore.health.servicesUpdatedUntil > TimeService.currentUtcTime());
    },
    getServices() {
      return this.$store.state.hudStore.health.servicesStates;
    },
    headers() {
      return [{name: this.$i18n.t('labels.hud.serviceName')}, {name: this.$i18n.t('labels.common.state')}];
    }
  },
  methods: {
    close() {
      this.$store.dispatch(A.CLOSE_SERVICES_STATES);
    }
  }
};
</script>
<style src="./hud.css"></style>