
export default class FilterUtil{

  static constructSeriesFilter(filterType, value){
    return {
      'name': 'search',
      'id': '1',
      'description': '',
      'filterType': filterType,
      'series' : value,
    }
  }

  static constructNumberFilter(filterType, value){
    return {
      'name': 'search',
      'id': '1',
      'description': '',
      'filterType': filterType,
      'number' : value,
    }
  }
}