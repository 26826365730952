import Store from '../store';
import FeatureType from '../constants/featureType'
import Moment from "moment";
import bigDecimal, {RoundingModes} from 'js-big-decimal';
import UomUtils from "./UomUtils";
import UomConfig from "../config/uomConfig";
import globalCrsConfig from "../config/globalCrsConfig";

export default class ModelUtils {
  static compare(a, b) {
    if (a < b) {
      return -1;
    }
    if (b < a) {
      return 1;
    }
    return 0;
  }

  static sort(a, b) {
    return ModelUtils.compare(a, b);
  }

  static sortUsers(a, b) {
    const aLastName = a.lastName ? a.lastName.toUpperCase() : undefined;
    const bLastName = b.lastName ? b.lastName.toUpperCase() : undefined;
    return ModelUtils.compare(aLastName, bLastName);
  }

  static checkIfIsOutlookOperation(opGufi) {
    const operation = Store.getters.getOperationPlanByOpGufi(opGufi);
    return operation && operation.length > 0 && operation[0].featureType === FeatureType.OPERATION_PLAN_OUTLOOK;
  }

  static roundToTwoDecimals(number) {
    return +(Math.round(number + "e+2") + "e-2");
  }

  static getMinStartTime(validity) {
    return validity.map(v => v.validFrom).sort()[0];
  }

  static getMaxEndTime(validity) {
    return validity.map(v => v.validTo).sort()[validity.length - 1];
  }

  static formatUtcDateTime(dateTime) {
    return Moment.utc(dateTime).format('DD MMM YY HH:mm')
  }

  static constructDisplayedValidity(validity) {
    return validity.map(
      v => ModelUtils.formatUtcDateTime(v.validFrom) + " - " + ModelUtils.formatUtcDateTime(v.validTo)
    );
  }

  static constructDisplayedUomAndCrs() {
    const currentlySelectedUomDisplayValue = UomConfig.getCurrentlyConfiguredUomDisplayValue();
    const currentCrsTypeDisplayValue = globalCrsConfig.getCurrentCrsTypeDisplayValue();
    return `${currentlySelectedUomDisplayValue} ${currentCrsTypeDisplayValue}`;
  }

  static roundToNearestInteger(numberValue) {
    return parseInt(bigDecimal.round(numberValue, 0, RoundingModes.HALF_UP));
  }

  static timestampYearIs9999(timestamp) {
    return Moment.utc(timestamp).year() === 9999;
  }

  static isAltitudeBetweenMinAndMaxFilteringAltitude(altitude) {
    const minFilteringAltitudeInFeet =
      UomUtils.convert(Store.getters.getMinFilteringAltitude, UomConfig.UOM.METERS, UomConfig.UOM.FEET);
    const maxFilteringAltitudeInFeet =
      UomUtils.convert(Store.getters.getMaxFilteringAltitude, UomConfig.UOM.METERS, UomConfig.UOM.FEET);
    return altitude <= maxFilteringAltitudeInFeet && altitude >= minFilteringAltitudeInFeet;
  }
}