export default {

  WFS: 'WFS',

  WMS : 'WMS',

  WMTS: 'WMTS',

  XYZ: 'XYZ',

}