<template>
  <div>
    <OpNewStartTimeProposalForm v-if="displayNewStartTimeProposalForm"
                                :operation-plan="operationPlan"
                                v-model:output="newStartTimeProposalOutput"/>
    <OpRejectionReasonForm v-model:output="rejectionReasonOutput"/>
  </div>
</template>

<script>

import OpNewStartTimeProposalForm from '@/components/op-rejection-props-form/OpNewStartTimeProposalForm';
import OpRejectionReasonForm from '@/components/op-rejection-props-form/OpRejectionReasonForm';
import OperationPlanState from '@/constants/operationPlanState';

export default {
  name: 'OpRejectionPropsForm',
  props: ['operationPlan', 'rejectionProps'],
  components: {
    OpNewStartTimeProposalForm,
    OpRejectionReasonForm
  },
  data() {
    return {
      newStartTimeProposalOutput: {
        isValid: true,
        proposedTimeOffsetInMinutes: null,
        newStartTimeIsProposed: false
      },
      rejectionReasonOutput: {
       isValid: true,
       rejectionReason: null
      }
    }
  },
  watch: {
    newStartTimeProposalOutput: {
      handler(newValue) {
        this.rejectionProps.proposedTimeOffsetInMinutes = newValue.proposedTimeOffsetInMinutes;
        this.rejectionProps.newStartTimeIsProposed = newValue.newStartTimeIsProposed;
        this.rejectionProps.isValid = newValue.isValid && this.rejectionReasonOutput.isValid;
      },
      deep: true,
      immediate: true
    },
    rejectionReasonOutput: {
      handler(newValue) {
        this.rejectionProps.rejectionReason = newValue.rejectionReason;
        this.rejectionProps.isValid = newValue.isValid && this.newStartTimeProposalOutput.isValid;
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    displayNewStartTimeProposalForm() {
      return this.operationPlan.state === OperationPlanState.PROPOSED.state;
    }
  }
}
</script>
