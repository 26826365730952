<template>
  <div id="configuration-panel" class="smartSISPanel viewsConfigPanel">
    <div v-if="isOpen" class="viewsConfigPanelContent" v-bind:style="{height: widthSize}">
      <DraggableBorder
        :position="down"
        :minSize="minSize"
        :maxSize="maxSize"
      ></DraggableBorder>
      <ul class="nav nav-tabs">
        <li :id="`${tab.name.toLowerCase()}-panel`" class="nav-item" v-for="tab in tabs" :key="tab.id">
          <div class="nav-item" v-if="tab.isVisible">
            <a
              :class="tabClass(tab.name)"
              @click="navigate(tab.name)"
            >
              {{ $t(tab.id) }}
            </a>
          </div>
        </li>
      </ul>
      <component :id="`${currentConfigComponent.toLowerCase()}-container`" v-bind:is="currentConfigComponent"></component>
    </div>
  </div>
</template>

<script>

import A from '../../constants/actions';
import ThemeConfiguration from './theme/ThemeConfiguration.vue';
import ViewConfiguration from './view/ViewConfiguration.vue';
import GroupRoleConfiguration from './group/GroupRoleConfiguration.vue';
import DraggableBorder from '../left-panel/DraggableBorder.vue';
import draggableConfig from '../../config/draggableConfig';
import ThresholdsConfiguration from './variables/ThresholdsConfiguration.vue';
import NotamFilterConfiguration from './notam-filter/NotamFilterConfiguration.vue';
import UtmConfiguration from './utm/UtmConfiguration.vue';
import DialogUtils from '../../utils/DialogUtils';
import ConfigurationPanelTabs from '../../config/configurationPanelTabs';
import GeoserverLayersConfig from "../geoserver-layers-config/GeoserverLayersConfig";
import GlobalConfigurations from "./globalConfigurations/GlobalConfigurations";
import HudTabName from '../../constants/hudTabName';
import Features from "../../config/features";
import SubscriptionUtils from "../../utils/SubscriptionUtils";

export default {
  name: 'ConfigurationPanel',
  components: {
    GroupRoleConfiguration,
    ThemeConfiguration,
    ThresholdsConfiguration,
    NotamFilterConfiguration,
    UtmConfiguration,
    ViewConfiguration,
    GeoserverLayersConfig,
    GlobalConfigurations,
    DraggableBorder,
  },

  data: () => {
    return {
      down: 'down',
      currentConfigComponent: 'ViewConfiguration',
      tabs: ConfigurationPanelTabs.getTabProps(),
    }
  },

  computed: {
    widthSize() {
      return this.$store.state.configurationStore.height + 'px';
    },
    isOpen() {
      return this.$store.getters.isGivenTabOpened(HudTabName.CONFIGURATION);
    },
    minSize() {
      return draggableConfig.configPanel.minSize;
    },
    maxSize() {
      return draggableConfig.configPanel.maxSize;
    },
  },

  methods: {
    changeTab(tabName) {
      this.currentConfigComponent = tabName;
      this.$store.dispatch(A.CONFIGURATION_PANEL_SELECT_TAB, tabName);
    },
    resetContentAndChangeTab(tabName) {
      ConfigurationPanelTabs.resetContentOfTabToOriginal(this.currentConfigComponent);
      const affectedFeatures = Features.getConfiguredAltitudeBasedFeatureTypes();
      this.$store.dispatch(A.WEBSOCKET_PARTIAL_SUBSCRIBE, SubscriptionUtils.constructPartialSubscriptionConfig(affectedFeatures));
      this.changeTab(tabName);
    },
    navigate(tabName) {
      if (ConfigurationPanelTabs.canChangeTab(this.currentConfigComponent)) {
        this.changeTab(tabName);
      } else {
        DialogUtils.confirmOperation({
          message: this.$i18n.t('confirmationMessages.configurationPanelChangeTab'),
          yesAction: () => this.resetContentAndChangeTab(tabName)
        })
      }
    },
    tabClass: function (tabName) {
      return ['nav-link', 'btn', (tabName === this.currentConfigComponent) ? 'active' : ''].join(' ');
    }
  }
};
</script>
<style src="./configurationPanel.css"></style>