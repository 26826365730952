import FeatureType from '../../constants/featureType';
import ThemeElements from '../../constants/theme-elements';
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import TimeService from "../../services/timeService";
import AppConfig from "../appConfig";

export default {

  id: FeatureType.ADSB,
  name: 'features.adsb',
  abbreviation: 'features.adsb',
  isViewPortBased: false,
  isNotificationsDisabled: true,
  isFlightLevelBased: false,
  maxNrOfFeaturesPerLocation: 100,
  featureIdentifier: 'id',
  maxResolution: 10000,
  declutter: false,
  zIndex: 1,
  flightLevel: null,
  theme: {
    elements: [
      ThemeElements.SHOW_TEXT,
      ThemeElements.TEXT_COLOR,
      ThemeElements.TEXT_SIZE,
      ThemeElements.TEXT_BG_COLOR,
      ThemeElements.IMAGE_COLOR,
      ThemeElements.ICON_SIZE,
    ]
  },

  displayOnMap: true,
  displayInMessages: false,

  geoJsonMapper: (feature) => {
    //TODO: Serby const z = feature.a ? feature.a * 0.3048 : 1;
    return new Feature({
      geometry: new Point([feature.p[0], feature.p[1]]),
      id: feature.id,
      altitude: feature.a,
      trackAngle: feature.d,
      timestamp: feature.timestamp,
      maxResolution: 10000,
      featureType: 'adsb',
      identifier: feature.identifier,
      validTime: {
        to: TimeService.currentTimeSliderTime().valueOf() + AppConfig.adsbValidity * 1000,
      },
    });
  },

  color: (colors) => {
    return colors.imageColor;
  },

  defaultStyle: () => {
    return {
      showText: true,
      textSize: 14,
      iconSize: 70,
      colors: {
        borderColor: {r: 240, g: 60, b: 60, a: 1},
        fillColor: {r: 240, g: 228, b: 255, a: 0.1},
        textColor: {r: 255, g: 255, b: 255, a: 1},
        textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
        imageColor: {r: 240, g: 60, b: 60, a: 1}
      }
    }
  },


}