<template>
  <div class="row m-0">
    <ListPanel
      :entries="groupRepresentatives"
      :entryStyle="getGroupButtonStyle"
      :idPrefix="'group'"
      :onSelectEntry="selectGroup"
      class="pt-2 px-0"
    ></ListPanel>
    <component :is="showGroupEditorOrRoleEditor" v-if="isAllowedToEdit"></component>
  </div>
</template>
<script>

import A from '../../../constants/actions';
import DialogUtils from '../../../utils/DialogUtils';
import ListPanel from '../list-panel/ListPanel.vue';
import PermissionUtils from '../../../utils/PermissionUtils';
import GroupEditor from './editor/GroupEditor.vue';
import UserService from '../../../services/smartSisUserService';
import Logger from '../../../utils/LoggerUtils';
import RoleEditor from './editor/RoleEditor';

export default {
  name: 'GroupRoleConfiguration',
  components: {
    RoleEditor,
    GroupEditor,
    ListPanel,
  },
  computed: {
    showGroupEditorOrRoleEditor() {
      if (this.isEnabledDisplayOfRoleEditor) {
        return RoleEditor;
      } else {
        return GroupEditor;
      }
    },
    isAllowedToEdit() {
      return PermissionUtils.isAllowedToEditGroupAndRoles();
    },
    groupRepresentatives() {
      return this.$store.state.userStore.groupRepresentatives;
    },
    isEnabledDisplayOfRoleEditor() {
      return this.$store.state.userStore.enabledDisplayOfRoleEditor;
    }
  },
  methods: {
    selectGroup(group) {
      const currentGroup = this.$store.state.userStore.currentGroup;
      const originalGroup = this.$store.state.userStore.originalGroup;
      if (JSON.stringify(currentGroup) !== JSON.stringify(originalGroup)) {
        DialogUtils.confirmOperation(
          {
            message: this.$i18n.t('confirmationMessages.groupRoleSelectGroup'),
            yesAction: async () => {
              await this.setCurrentGroup(group);
              this.$store.dispatch(A.USER_MANAGEMENT_RESET_ROLE);
              this.$store.dispatch(A.USER_MANAGEMENT_DISABLE_DISPLAY_OF_ROLE_EDITOR);
            }
          }
        );
      } else {
        this.setCurrentGroup(group);
        this.$store.dispatch(A.USER_MANAGEMENT_DISABLE_DISPLAY_OF_ROLE_EDITOR);
      }
    },

    getGroupButtonStyle(group) {
      const currentGroup = this.$store.state.userStore.currentGroup;
      return [
        'btn',
        'btn-primary',
        'w-100',
        'm-1',
        'listButton',
        (currentGroup && currentGroup.id === group.id) ? 'selected' : '',
      ].join(' ');
    },

    async setCurrentGroup(group) {
      const config = {
        groupId: group.id,
        successCallback: (group) => {
          this.$store.dispatch(A.USER_MANAGEMENT_SELECT_GROUP, group);
        },
        errorCallback: (error) => {
          Logger.error(this.$i18n.t('errorMessages.getGroupConfigError', {groupName: group.name, error}));
          DialogUtils.errorNotification(error);
        },
      };
      await UserService.getGroupDetailsById(config);
    }
  }

}
</script>