import AppConfig from '../config/appConfig';

const getSearchHistory = () => {
  const searchHistoryStr = localStorage.getItem('searchHistory');
  return searchHistoryStr ? JSON.parse(searchHistoryStr) : [];
};

const updateSearchHistory = (searchString, locationType) => {
  const searchHistory = getSearchHistory() || {};
  searchHistory[locationType] = [...new Set([searchString, ...searchHistory[locationType] || []])];
  if (searchHistory[locationType].length > AppConfig.ui.searchHistoryLength) {
    searchHistory[locationType].splice(-1, 1);
  }
  localStorage.setItem('searchHistory', JSON.stringify(searchHistory));
};

export default {
  getSearchHistory,
  updateSearchHistory,
}