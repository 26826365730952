<template>
  <div class="colorItem">
    <div
      class="colorBox"
      @click="showColorPicker()"
      :style="'background:' + backgroundColor ">
    </div>
  </div>
</template>
<script>

import A from '../../../../constants/actions';

export default {
  name: 'ColorItem',
  props: ['value', 'layerStyle', 'element'],
  computed: {
    colors() {
      return this.element.retrieve(this.layerStyle);
    },
    backgroundColor() {
      return 'rgba(' + [this.value.r, this.value.g, this.value.b, this.value.a].join(',') + ')';
    },
  },
  methods: {
    updateStyle(layerStyle, element, value) {
      this.element.update(layerStyle, value);
      this.$store.dispatch(A.THEME_UPDATE_LAYER_STYLING, layerStyle);
    },
    showColorPicker() {
      const self = this;
      this.$store.dispatch(A.COLOR_PICKER_SHOW,
        {
          selectedColor: this.value,
          callback: (colors) => {
            self.updateStyle(self.layerStyle, self.element, colors.rgba || colors);
          }
        }
      );
    }
  }
};
</script>
<style src="./themeEditor.css"></style>
