<template>
  <div>
    <button
      id="theme-editor-toggle-item"
      :class="['btn', 'btn-primary', 'toggleButton', (value) ? 'selected' : ''].join(' ')"
      @click="updateStyle(layerStyle, element, !value)"
    >
      {{ (value) ? $t('labels.common.enabled') : $t('labels.common.disabled') }}
    </button>
  </div>
</template>
<script>

import A from '../../../../constants/actions';

export default {
  name: 'ToggleItem',
  props: ['value', 'layerStyle', 'element'],
  methods: {
    updateStyle(layerStyle, element, value) {
      this.element.update(layerStyle, value);
      this.$store.dispatch(A.THEME_UPDATE_LAYER_STYLING, layerStyle);
    },
  }

};
</script>
