<template>
  <div class="documentsPanelList">
    <div
      class="mb-1"
      :key="document"
      v-for="document in documentsList"
    >
      <button
        :class="['w-100', 'btn', (isSelected(document) ? 'btn-secondary' : 'btn-primary')].join(' ')"
        @click="selectDocument(document)"
      >
        {{ document }}
      </button>
      <button
        v-if="isAllowedToRemoveDocument"
        class="btn btn-link removeDocumentButton"
        @click="handleRemoveDocument(document)"
      >
        <img class="removeImg" :src="removeIconSrc" :alt="$t('popupMessages.removeDocument')">
      </button>
    </div>
    <template v-if="emptyDocumentsList">
      {{ $t('logMessages.noDocumentsWereFound') }}
    </template>
  </div>
</template>

<script>

import A from '../../constants/actions';
import DocumentService from '../../services/smartSisDocumentService';
import ImgUtils from "../../utils/ImgUtils";
import PermissionUtils from "../../utils/PermissionUtils";
import DialogUtils from "../../utils/DialogUtils";
import LoggerUtils from "../../utils/LoggerUtils";

export default {
  name: 'DocumentsPanelList',

  methods: {
    selectDocument(documentName) {
      const url = DocumentService.documentServiceURL + '?fileName=' + documentName;
      const docData = {
        url,
        title: documentName,
      };
      let docWindow = this.$store.state.documentsStore.openDocs[documentName];
      if (!docWindow || docWindow.closed) {
        docWindow = window.open('document.html', '_blank');
        docWindow.onbeforeunload = () => {
          this.$store.dispatch(A.DOCUMENTS_PANEL_DESELECT_DOC, documentName);
          return undefined;
        };
        docWindow.onload = () => docWindow.setData(docData);
        this.$store.dispatch(A.DOCUMENTS_PANEL_SELECT_DOC, {documentName, docWindow});
      } else {
        docWindow.focus();
      }
    },
    handleRemoveDocument(documentName) {
      DialogUtils.confirmOperation({
        message: this.$i18n.t('confirmationMessages.deleteDocument', {documentName}),
        yesAction: () => this.removeDocument(documentName),
        noAction: () => LoggerUtils.info(this.$i18n.t('logMessages.documentDeleteDropped'))
      });
    },
    removeDocument(documentName) {
      DocumentService.deleteDocument(documentName);
    },
    isSelected(docName) {
      return !!this.$store.state.documentsStore.openDocs[docName];
    }
  },

  computed: {
    emptyDocumentsList() {
      return this.$store.state.documentsStore.documents.length === 0;
    },
    selectedDocument() {
      return this.$store.state.documentsStore.selectedDocument;
    },
    documentsList() {
      return this.$store.state.documentsStore.documents;
    },
    isAllowedToRemoveDocument() {
      return PermissionUtils.isSuperAdmin();
    },
    removeIconSrc() {
      return ImgUtils.getButtonImg('remove', 'svg');
    }
  }

}
</script>
<style src="./documentsPanel.css"></style>
