import AppConfig from "../config/appConfig";
import AjaxUtils from "../utils/AjaxUtils";
import Features from "../config/features";
import FeatureType from "../constants/featureType";
import Store from "../store";
import A from "../constants/actions";
import Logger from "../utils/LoggerUtils";
import DialogUtils from "../utils/DialogUtils";
import {i18n} from '../internationalization/index';
import geozoneWhitelistedIdType from "../constants/geozoneWhitelistedIdType";

const POST = 'post';
const PUT = 'put';
const DELETE = 'delete';
const constructAllowListEndpoint = (type, country, identifier) => {
  switch (type) {
    case geozoneWhitelistedIdType.WHITELISTED_DRONES:
      return "/uas-zone/" + country + '/' + identifier + "/allowlist-drone";
    case geozoneWhitelistedIdType.WHITELISTED_OPERATORS:
      return "/uas-zone/" + country + '/' + identifier + "/allowlist-operator";
    case geozoneWhitelistedIdType.WHITELISTED_OPS:
      return "/uas-zone/" + country + '/' + identifier + "/allowlist-drone-plan";
  }
}

function saveGeozone(config) {
  let geozone = JSON.parse(JSON.stringify(config.geozone));
  const geozoneWrapper = constructGeozoneWrapper(geozone);
  const geozoneConfiguration = Features.getFeature(FeatureType.ED269);
  return fetchTheEndpoint(geozoneConfiguration.creationAndUpdateEndpoint, geozoneWrapper, config, [], POST);
}

function saveGeozones(config) {
  const geozoneWrappers = config.geozones.map(geozone => constructGeozoneWrapper(geozone));
  const geozoneConfiguration = Features.getFeature(FeatureType.ED269);
  return fetchTheEndpoint(geozoneConfiguration.creationMultipleGeozonesEndpoint, geozoneWrappers, config, [], POST);
}

function addIdToAllowlist(config) {
  const endpoint = constructAllowListEndpoint(config.type, config.country, config.identifier);
  return fetchTheEndpoint(endpoint, config.body, config, [], POST);
}

function removeIdFromAllowlist(config) {
  const endpoint = constructAllowListEndpoint(config.type, config.country, config.identifier);
  return fetchTheEndpoint(endpoint, config.body, config, [], DELETE);
}

function closeGeozone(config) {
  const geozoneConfiguration = Features.getFeature(FeatureType.ED269);
  const closeConfigParams = [
    AjaxUtils.getParamObjectFor('provider_id', config.providerId),
    AjaxUtils.getParamObjectFor('country', config.country),
    AjaxUtils.getParamObjectFor('identifier', config.identifier),
  ];
  return fetchTheEndpoint(geozoneConfiguration.terminateEndpoint, null, config, closeConfigParams, POST);
}

function activateDeactivateGeozone(config) {
  const geozoneConfiguration = Features.getFeature(FeatureType.ED269);
  const params = [
    AjaxUtils.getParamObjectFor('country', config.country),
    AjaxUtils.getParamObjectFor('identifier', config.identifier),
    AjaxUtils.getParamObjectFor('provider_id', config.providerId),
    AjaxUtils.getParamObjectFor('change_active_to', config.changeActiveTo),

  ];
  return fetchTheEndpoint(geozoneConfiguration.activateDeactivateEndpoint, null, config, params, PUT);
}

function fetchTheEndpoint(endpoint, body, config, params, method) {
  return fetch(
    AjaxUtils.setParameters(AppConfig.server.serviceUrlRoot + endpoint,
      [
        AjaxUtils.getUserNameParam(),
        ...params
      ]),
    {
      method: method,
      headers: AjaxUtils.getHeaders(),
      body: JSON.stringify(body),
    })
    .then(response => {
      switch (response.status) {
        case 200:
        case 201:
          response.text().then(data => {
            config.successCallback({data});
          });
          break;
        default:
          response.text().then(data => {
            config.errorCallback(AjaxUtils.parseErrorMessage(data));
          });
      }
    })
    .catch(error => config.errorCallback(AjaxUtils.parseErrorMessage(error)));
}

async function checkUasZoneUniqueness(config) {
  const geozoneConfiguration = Features.getFeature(FeatureType.ED269);
  const identifier = config.identifier;
  const country = config.country;

  const response = await fetch(
    AjaxUtils.setParameters(AppConfig.server.serviceUrlRoot + geozoneConfiguration.checkUasZoneUniquenessEndpoint, [
      AjaxUtils.getParamObjectFor('identifier', identifier),
      AjaxUtils.getParamObjectFor('country', country)
    ]),
    {
      method: POST,
      headers: AjaxUtils.getHeaders()
    });
  if (response.status !== 200) {
    Store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
    const errorMessage = i18n.global.t('errorMessages.geozoneGenerateIdentificationError');
    Logger.error(errorMessage);
    DialogUtils.errorNotification(errorMessage);
    throw new Error(errorMessage);
  } else {
    return await response.text();
  }
}

function constructGeozoneWrapper(geozone) {
  return {
    wrapperId: geozone.country + geozone.identifier,
    uasZone: geozone,
    providerId: AppConfig.providerId,
    createdBySmartsis: true
  }
}

export default {
  saveGeozone,
  saveGeozones,
  closeGeozone,
  checkUasZoneUniqueness,
  addIdToAllowlist,
  removeIdFromAllowlist,
  activateDeactivateGeozone,
}