import LineString from "ol/geom/LineString";

export default class OpWaypointFormRenderingUtils {

  static geometryInformationChanged(newWaypoints, oldWaypoints) {
    return CHECKED_PROPERTIES_EXTRACTORS
      .map(extractProperty => propertiesChanged(newWaypoints, oldWaypoints, extractProperty))
      .includes(true);
  }

  static isClimbOrDescendOccurringBetweenPoints(firstPoint, secondPoint) {
    const altitudeDifference = secondPoint.altitude - firstPoint.altitude;
    const lineFormedByPoints = new LineString([
      firstPoint.geometry.getCoordinates(), secondPoint.geometry.getCoordinates()
    ]);
    return Math.abs(altitudeDifference) > lineFormedByPoints.getLength();
  }

  static getClimbOrDescendIconRotation(firstPoint, secondPoint) {
    const altitudeDifference = secondPoint.altitude - firstPoint.altitude;
    return altitudeDifference > 0 ? 0 : Math.PI;
  }

  static getClimbOrDescendIconAnchor(firstPoint, secondPoint) {
    const altitudeDifference = secondPoint.altitude - firstPoint.altitude;
    return altitudeDifference > 0 ? [1, 0.5] : [0, 0.5];
  }
}

const CHECKED_PROPERTIES_EXTRACTORS = [
  waypoint => waypoint.altitude, waypoint => [waypoint.longitude, waypoint.latitude]
];

function propertiesChanged(newWaypoints, oldWaypoints, extractProperty) {
  const newProperties = newWaypoints?.map(extractProperty);
  const oldProperties = oldWaypoints?.map(extractProperty);
  return JSON.stringify(newProperties) !== JSON.stringify(oldProperties);
}
