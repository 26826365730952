import CommonStyleFunction from "../../CommonStyleFunction";
import {Stroke, Style} from "ol/style";
import FeatureUtils from "../../../utils/FeatureUtils";

export default class DronePlanGeometryStyleFunction {

  static buildGeometryStyle(featureProperties){
    return [
      CommonStyleFunction.getLayerGeometryFillStyling(featureProperties.colorConfigs.fillColor, featureProperties.geometry),
      ...getBorderStyles(featureProperties.colorConfigs.borderConfig, featureProperties.associatedAlerts, featureProperties.geometry)
    ];
  }
}

function getBorderStyles(strokeConfig, alertsForOperationPlan, geometry) {
  return FeatureUtils.State.existAlertWithUnresolvedState(alertsForOperationPlan) ?
    [...getBorderStylesForOpWithAlert(strokeConfig, geometry)] : [CommonStyleFunction.getLayerBorderStyling(strokeConfig, geometry)];
}

function getBorderStylesForOpWithAlert(strokeConfig, geometry) {
  return [
    new Style({
      stroke: new Stroke({
        color: [255, 9, 0, 100],
        width: strokeConfig.alertBorderWidth,
        lineDash: [6, 10],
        lineDashOffset: 8
      }),
      geometry: geometry
    }),
    new Style({
      stroke: new Stroke({
        color: [255, 255, 255, 100],
        width: strokeConfig.alertBorderWidth,
        lineDash: [6, 10]
      }),
      geometry: geometry
    })
  ];
}