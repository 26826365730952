<template>
  <div class="popupContainer">
    <div v-for="(alert, index) in alerts"
         :id="alert.id"
         :key="index"
         :class="['alert', alert.style ? alert.style : ''].join(' ')"
         :style="getStyle(alert)">
      <span>{{ alert.text }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Popup',
  computed: {
    alerts() {
      return this.$store.state.popupStore.messages;
    },
  },
  methods: {
    getStyle(alert) {
      if (alert.bgColor) {
        return {'background-color': 'rgba(' + [alert.bgColor.r, alert.bgColor.g, alert.bgColor.b, alert.bgColor.a].join(',') + ')'};
      }
      return {};
    },
  }
}
</script>
<style src="./popup.css"></style>