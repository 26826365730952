<template>
  <div id="task-panel" class="smartSISPanel tasksPanel">
    <div class="tasksPanelContent">
      <TaskPanelSubTabs/>
      <MessageContainer :messages="getMessages()"/>
      <div class="controls-container searchForm w-100 form-inline p-0">
        <OperationPlanStateControls :message="focusedMessageById"/>
      </div>
    </div>
  </div>
</template>

<script>

import MessageContainer from "../MessageContainer";
import {mapGetters} from "vuex";
import OperationPlanStateControls from "../custom-message-cards/controls/OperationPlanStateControls";
import TaskPanelSubTabs from "./TaskPanelSubTabs";
import TaskPanelSubTabsConfig from "../../../config/TaskPanelSubTabsConfig";

export default {
  name: 'TasksPanel',
  components: {
    TaskPanelSubTabs,
    MessageContainer,
    OperationPlanStateControls
  },
  computed: {
    ...mapGetters({
      messages: 'getMessages',
      activeTab: 'activeInformationTab',
      selectedSubTabId: 'selectedTaskSubTab',
    }),
    focusedMessageById() {
      const selectedMessageId = this.$store.getters.selectedFeatureId;
      const operationPlan = this.$store.getters.getOperationPlanById(selectedMessageId);
      return this.getMessages().includes(operationPlan) ? operationPlan : null;
    },
  },
  methods: {
    getMessages() {
      return TaskPanelSubTabsConfig.getMessagesForTaskPanelSubTab(this.selectedSubTabId);
    }
  }
}
</script>