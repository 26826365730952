import A from '../constants/actions';
import Store from '../store';
import DialogUtils from '../utils/DialogUtils';
import LoggerUtils from '../utils/LoggerUtils';
import PermissionUtils from '../utils/PermissionUtils';

import ThemeService from '../services/smartSisThemeService';
import ViewService from '../services/smartSisViewService';
import UserService from '../services/smartSisUserService';
import ThresholdService from '../services/smartSisThresholdService';
import NotamFilterService from '../services/smartSisNotamFilterService';
import GeoserverService from '../services/geoserverLayersService';
import GlobalConfigurationService from "../services/globalConfigurationService";
import {i18n, loadLanguageAsync} from '../internationalization/index';

export default class AssetUtils {

  static loadData(queries, onFinished) {

    const servicePromises = queries.filter(query => query.execute).map(query => {
      return query.service({
        onSuccess: (data) => {
          query.onSuccess(data);
        },
        onError: (error) => {
          const errorMessage = i18n.global.t('errorMessages.assetLoadingError', {asset: query.asset});
          LoggerUtils.error(errorMessage);
          LoggerUtils.error(JSON.stringify(error));
          DialogUtils.errorNotification(errorMessage);
        }
      });
    });

    Promise.all(servicePromises).then(onFinished);
  }

  static setInitialRole(postActions) {
    try {
      ThemeService.getDefaultTheme({
        onSuccess: (data) => {
          Store.dispatch(A.THEME_CHANGE_DEFAULT_THEME, data);
        }
      });
      Store.getters.getUserGroupRoleOrDefault({
        onSuccess: (groupAndRole) => {
          if (!groupAndRole.group || !groupAndRole.role) {
            if (!PermissionUtils.isSuperAdmin()) {
              alert(i18n.global.t('logMessages.noGroupRoleAssigned') + " " +
                i18n.global.t('logMessages.automaticallyLoggedOut'));
            } else {
              alert(
                i18n.global.t('logMessages.noSmartSisGroupConfigured') + " " +
                i18n.global.t('logMessages.automaticallyLoggedOut')
              );
            }
            Store.dispatch(A.AUTH_LOGOUT);
          } else {
            Store.dispatch(A.USER_MANAGEMENT_SELECT_ACTIVE_GROUP_ROLE, groupAndRole)
              .then(() => {
                if (postActions) {
                  postActions();
                }
              });
          }
        }
      });
    } catch (error) {
      console.error(i18n.global.t('errorMessages.initialAssetSelectError'), error);
      DialogUtils.errorNotification(i18n.global.t('errorMessages.initialAssetSelectError'));
    }
  }

  static loadAssets(onFinished) {
    AssetUtils.loadData([
        {
          asset: 'geoserverLayers',
          service: GeoserverService.readAllGeoserverLayers,
          onSuccess: (data) => Store.dispatch(A.GEOSERVER_LAYERS_READ_ALL, data),
          execute: PermissionUtils.isSmartSISUser(),
        },
        {
          asset: 'defaultTheme',
          service: ThemeService.getDefaultTheme,
          onSuccess: (data) => Store.dispatch(A.THEME_CHANGE_DEFAULT_THEME, data),
          execute: PermissionUtils.isSmartSISUser(),
        },
        {
          asset: 'views',
          service: ViewService.getAllViews,
          onSuccess: (data) => Store.dispatch(A.VIEW_READ_ALL_VIEWS, data),
          execute: PermissionUtils.isSmartSISUser(),
        },
        {
          asset: 'groupRepresentatives',
          service: UserService.getAllGroupRepresentatives,
          onSuccess: (data) => Store.dispatch(A.USER_MANAGEMENT_GROUP_REPRESENTATIVES_READ_ALL, data),
          execute: PermissionUtils.isSmartSISUser(),
        },
        {
          asset: 'defaultGroupRolePair',
          service: UserService.getDefaultGroupRolePair,
          onSuccess: (data) => Store.dispatch(A.USER_MANAGEMENT_GROUPS_READ_DEFAULT_GROUP_ROLE, data),
          execute: PermissionUtils.isSmartSISUser(),

        },
        {
          asset: 'thresholds',
          service: ThresholdService.getAllThresholds,
          onSuccess: (data) => Store.dispatch(A.THRESHOLD_READ_ALL_THRESHOLDS, data),
          execute: PermissionUtils.isSmartSISUser(),
        },
        {
          asset: 'notamFilters',
          service: NotamFilterService.getAllFilters,
          onSuccess: (data) => Store.dispatch(A.NOTAMFILTER_READ_ALL_FILTERS, data),
          execute: PermissionUtils.isSmartSISUser(),
        },
        {
          asset: 'groupRoleRepresentativesByUser',
          service: UserService.getGroupRoleRepresentatives,
          onSuccess: (data) => Store.dispatch(A.USER_MANAGEMENT_GROUP_ROLE_REPRESENTATIVES_READ_ALL, data),
          execute: PermissionUtils.isSmartSISUser(),
        },
        {
          asset: 'themes',
          service: ThemeService.getAllThemes,
          onSuccess: (data) => Store.dispatch(A.THEME_READ_ALL_THEMES, data),
          execute: PermissionUtils.isSmartSISUser(),
        },
        {
          asset: 'globalConfigurations',
          service: GlobalConfigurationService.getGlobalConfigurations,
          onSuccess: (data) => Store.dispatch(A.GLOBAL_CONFIGURATION_READ, data),
          execute: PermissionUtils.isSmartSISUser(),
        },
        {
          asset: 'userSortingConfig',
          service: UserService.getUserSortingConfig,
          onSuccess: (data) => Store.dispatch(A.USER_MANAGEMENT_SORTING_CONFIG_READ, data),
          execute: PermissionUtils.isSmartSISUser(),
        },
        {
          asset: 'defaultLanguage',
          service: UserService.getUserDefaultLanguage,
          onSuccess: (data) => loadLanguageAsync(data),
          execute: PermissionUtils.isSmartSISUser(),
        },
      ],
      onFinished);
  }

}