import GeozoneRestrictionTypeConfig from "./geozoneRestrictionTypeConfig";

function getGeozoneDefaultColorConfig() {
  return [
    {
      restrictionType: GeozoneRestrictionTypeConfig.NO_RESTRICTION,
      fillColor: {
        r: 42,
        g: 160,
        b: 243,
        a: 0.3
      },
      borderColor: {
        r: 42,
        g: 160,
        b: 243,
        a: 0.3
      }
    },
    {
      restrictionType: GeozoneRestrictionTypeConfig.REQ_AUTHORISATION,
      fillColor: {
        r: 243,
        g: 179,
        b: 242,
        a: 0.3
      },
      borderColor: {
        r: 243,
        g: 179,
        b: 242,
        a: 0.3
      }
    },
    {
      restrictionType: GeozoneRestrictionTypeConfig.PROHIBITED,
      fillColor: {
        r: 243,
        g: 103,
        b: 42,
        a: 0.3
      },
      borderColor: {
        r: 243,
        g: 103,
        b: 42,
        a: 0.3
      }
    },
    {
      restrictionType: GeozoneRestrictionTypeConfig.CONDITIONAL,
      fillColor: {
        r: 29,
        g: 21,
        b: 105,
        a: 0.3
      },
      borderColor: {
        r: 29,
        g: 21,
        b: 105,
        a: 0.3
      }
    },
  ]

}

export default {
  getGeozoneDefaultColorConfig,
}