import DronePlanUtils from "../dronePlans/DronePlanUtils";
import AppConfig from '../../config/appConfig';
import RevokeOpButton from "../../components/information-panel/controls/drone-plan-controls/RevokeOpButton";
import RejectOpButton from "../../components/information-panel/controls/drone-plan-controls/RejectOpButton";
import CancelOpButton from "../../components/information-panel/controls/drone-plan-controls/CancelOpButton";
import WithdrawOpButton from "../../components/information-panel/controls/drone-plan-controls/WithdrawOpButton";
import EndOpButton from "../../components/information-panel/controls/drone-plan-controls/EndOpButton";

export default class UserRolePriorities {
  static priorityRoles = [
    {
      roleName: 'Activation Approver',
      userMeetsTheRequirements: (op) => DronePlanUtils.hasUserLocalClearanceRights(op, AppConfig.providerId),
      getButtonComponent: (op) => DronePlanUtils.hasUserAlreadyApprovedTheActivationForOp(op, AppConfig.providerId) ?
        RevokeOpButton : RejectOpButton,
    },
    {
      roleName: 'Authorization Approver',
      userMeetsTheRequirements: (op) => DronePlanUtils.hasUserLocalApprovalRights(op, AppConfig.providerId),
      getButtonComponent: (op) => DronePlanUtils.hasUserAlreadyApprovedTheAuthorizationForOp(op, AppConfig.providerId) ?
        RevokeOpButton : RejectOpButton,
    },
    {
      roleName: 'USSP',
      userMeetsTheRequirements: (op) => DronePlanUtils.isCurrentUserUsspForOperationPlan(op, AppConfig.providerId),
      getButtonComponent: () => CancelOpButton,
    },
    {
      roleName: 'Requestor',
      userMeetsTheRequirements: (op) => DronePlanUtils.isCurrentUserRequestorOfOperationPlan(op, AppConfig.providerId),
      getButtonComponent: () => WithdrawOpButton,
    },
  ];

  static getUserFirstPriority(op) {
    return this.priorityRoles.find(priorityRole => priorityRole.userMeetsTheRequirements(op))
  }

  static getButtonComponentForOp(op) {
    const userPriorityRoleForOp = this.getUserFirstPriority(op);
    return DronePlanUtils.isOperationPlanActivated(op) ?
      (this.isCurrentUserAbleToEndOp(op) ? EndOpButton : RevokeOpButton)
      : userPriorityRoleForOp ? userPriorityRoleForOp.getButtonComponent(op) : RejectOpButton;
  }

  static isCurrentUserAbleToEndOp(op) {
    return DronePlanUtils.isCurrentUserUsspForOperationPlan(op, AppConfig.providerId)
      || DronePlanUtils.isCurrentUserRequestorOfOperationPlan(op, AppConfig.providerId);
  }

  static userHasNoRightsForRejectOrClose(op) {
    return this.getUserFirstPriority(op) === undefined;
  }

  static getPartialResultIdForOpAndCurrentUser(op) {
    const partialResult = DronePlanUtils.hasUserLocalClearanceRights(op, AppConfig.providerId) ?
      DronePlanUtils.getUserLocalClearanceRight(op, AppConfig.providerId)
      : DronePlanUtils.getUserLocalApprovalRight(op, AppConfig.providerId);
    return partialResult.partialResultId;
  }
}