import Store from "../store";
import {i18n} from "@/internationalization";

const UOM = {
  METERS: 'METERS',
  FEET: 'FEET'
}

const FEATURE_UOM_TO_INTERNAL_UOM_ID = {
  'M': UOM.METERS,
  'FT': UOM.FEET
};

const uomConfigs = {
  [UOM.METERS]: {
    displayValue: 'labels.common.m',
    toMeters: 1
  },
  [UOM.FEET]: {
    displayValue: 'labels.common.ft',
    toMeters: 0.3048
  }
};

function getCurrentUom() {
  return Store.getters.getActiveGroupUom || UOM.FEET;
}

function getCurrentlyConfiguredUomDisplayValue() {
  const currentlySelectedUom = getCurrentUom();
  return i18n.global.t(getConfigForUom(currentlySelectedUom).displayValue);
}

function getConfigForUom(uomId) {
  return uomConfigs[uomId];
}

function getSupportedUnits() {
  return Object.keys(uomConfigs).map(uomId => {
    const displayValue = uomConfigs[uomId].displayValue;
    return {value: uomId, name: displayValue};
  });
}

export default {
  UOM,
  FEATURE_UOM_TO_INTERNAL_UOM_ID,
  getConfigForUom,
  getCurrentUom,
  getCurrentlyConfiguredUomDisplayValue,
  getSupportedUnits
}
