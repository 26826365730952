import AppConfig from '../config/appConfig';
import AjaxUtils from '../utils/AjaxUtils';

const getAllThresholdsServiceURL = AppConfig.server.serviceUrlRoot + '/threshold/_all';
const saveThresholdURL = AppConfig.server.serviceUrlRoot + '/threshold';

function getAllThresholds(config) {
  const url = getAllThresholdsServiceURL;
  return fetch(AjaxUtils.setParameters(url, [AjaxUtils.getUserNameParam()]), {
    method: 'get',
    headers: AjaxUtils.getHeaders(),
    withCredentials: true,
  }).then(resp => resp.json())
    .then(data => config.onSuccess(data))
    .catch(error => config.onError(error));
}

function saveThreshold(config) {
  return fetch(
    AjaxUtils.setParameters(saveThresholdURL,
      [
        AjaxUtils.getUserNameParam(),
      ]),
    {
      method: 'put',
      headers: AjaxUtils.getHeaders(),
      body: JSON.stringify(config.threshold),
    })
    .then(response => {
      if (response.status !== 200 && response.status !== 201) {
        response.text().then(data => {
          config.errorCallback(data);
        });
      } else {
        response.text().then(data => {
          config.successCallback(data);
        });
      }
    })
    .catch(error => config.errorCallback(error));
}

export default {
  getAllThresholds,
  saveThreshold,
}