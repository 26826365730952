import AlertState from '../constants/alertState';
import ImgUtils from "./ImgUtils";
import ModelUtils from "./ModelUtils";
import FeatureUtils from "./FeatureUtils";

export default class AlertMessageUtils {

  static getAlertIcon(opId, extension) {
    const assocAlerts = FeatureUtils.Icons.getAssociatedAlertsForOperationPlanId(opId);
    const existUnresolvedAlert = FeatureUtils.State.existAlertWithUnresolvedState(assocAlerts);
    return ImgUtils.getButtonImg(getAlertIconImageName(existUnresolvedAlert), extension);
  }

  static determineState(history) {
    if (history.length === 0) {
      return AlertState.RECEIVED;
    }
    return history[history.length - 1].state;
  }

  static groupDroneAlertsByDronePlanId(droneAlerts) {
    let groupedDroneAlerts = [];
    droneAlerts
      .sort((a, b) => ModelUtils.sort(b.validTime.from, a.validTime.from))
      .forEach(msg => {
        let droneAlertParent = getDroneAlertGroupForDroneAlert(groupedDroneAlerts, msg.operationPlanIds[0]);
        droneAlertParent ? addElement(msg, droneAlertParent) : addParent(msg, groupedDroneAlerts);
      });
    return groupedDroneAlerts.reverse();
  }
}

function getAlertIconImageName(isUnresolved) {
  return isUnresolved ? "warning" : "green_warning";
}

function getDroneAlertGroupForDroneAlert(groupedDroneAlerts, operationPlanId) {
  return groupedDroneAlerts.find(m => m.operationPlanIds.includes(operationPlanId));
}

function addElement(droneAlert, droneAlertParent) {
  delete droneAlert.elements;
  droneAlert.parentId = droneAlertParent.id;
  droneAlertParent.elements.push(droneAlert);
}

function addParent(droneAlert, droneAlertGroup) {
  let droneAlertCpy = JSON.parse(JSON.stringify(droneAlert));
  droneAlertCpy.elements = [];
  droneAlert.elements = [droneAlertCpy];
  droneAlertGroup.push(droneAlert);
}