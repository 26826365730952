<template>
    <div class="dropdown form-inline  mb-3">
        <span class="fixedSizeTitle"><strong>{{ title }}:</strong></span>
        <select
            :id="type"
            :value="getValue(type)"
            class="form-control select"
            @change="onChange"
        >
            <option
                v-for="option in options"
                :value="option"
                :key="option"
                class="form-control select-option">
                {{option}}
            </option>
        </select>
    </div>
</template>

<script>
  import A from '../../../../constants/actions';

  export default {
    name: 'PropertyDropDown',
    props: ['title', 'type', 'options'],
    methods: {
      getValue(propName) {
        return this.$store.state.notamFilterStore.currentFilter[propName];
      },
      onChange(evt) {
        this.$store.dispatch(A.NOTAMFILTER_UPDATE_PROPERTY,
          {
            type: evt.target.id,
            value: evt.target.value,
          });
      },
    }
  }
</script>
<style src="./notamFilterEditor.css"></style>