export default {
  leftPanel: {
    minSize: 570,
    maxSize: 850,
  },

  configPanel: {
    minSize: 460,
    maxSize: 850,
    viewListMinSize: 165,
    listViewInformationHeight: 255,
    listViewNotificationHeight: 202,
    listPanelMinSize: 350,
    themeViewHeight: 230,
    roleViewHeight: 215,
    thresholdPanel: 335,
    notamFilterPanelHeight: 325,
    utmPanelHeight: 270,
    geoserverLayerConfigPanelHeight: 380,
  }

}