import Store from "../store";

export default class GlobalConfigurationUtils {

  static getDisplayConfigColorByIdAndByCurrentTheme(systemConfigId) {
    const colorUsageType = Store.getters.getIsDayThemeSelected ? "DAY" : "NIGHT";
    return Store.getters.getDisplayConfigurationById(systemConfigId)?.colors?.find(
      color => color.colorUsageType === colorUsageType
    )?.color;
  }

  static getUpdatedDisplayConfigurations(currentConfigs, originalConfigs) {
    return currentConfigs.filter(currentConfig =>
      !areTwoColorConfigsEqual(currentConfig.colors, getGlobalConfigById(originalConfigs, currentConfig.id)?.colors)
    );
  }

  static getUpdatedSystemConfigurations(currentConfigs, originalConfigs) {
    return currentConfigs.filter(currentConfig =>
      currentConfig.propertyType === getGlobalConfigById(originalConfigs, currentConfig.id)?.propertyType &&
      currentConfig.propertyValue !== getGlobalConfigById(originalConfigs, currentConfig.id)?.propertyValue);
  }
}

function getGlobalConfigById(systemConfigs, id) {
  return systemConfigs.find(systemConfig => id === systemConfig.id);
}

function areTwoColorConfigsEqual(colorsConfig1, colorsConfig2) {
  return colorsConfig1 && colorsConfig2 && colorsConfig1.length === colorsConfig2.length &&
    colorsConfig1.every(config1 => colorsConfig2.some(config2 => areEqualConfigs(config1, config2)));
}

function areEqualConfigs(config1, config2) {
  return Object.keys(config1).every(field => JSON.stringify(config1[field]) === JSON.stringify(config2[field]));
}