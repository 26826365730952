import AjaxUtils from "../utils/AjaxUtils";
import AppConfig from "../config/appConfig";

const globalConfigurationsUrl = '/getGlobalSettings';
const geozoneConfigurationUrl = '/geozoneConfiguration';
const systemConfigurationUrl = '/systemConfiguration';

function getGlobalConfigurations(config) {
  return fetch(AppConfig.server.serviceUrlRoot + globalConfigurationsUrl, {
    method: 'get',
    headers: AjaxUtils.getHeaders(),
    withCredentials: true,
  }).then(resp => resp.json())
    .then(data => config.onSuccess(data))
    .catch(error => config.onError(error));
}

function saveGeozoneConfiguration(config) {
  return saveConfig(config, config.geozoneConfiguration, geozoneConfigurationUrl);
}

function saveSystemConfigurations(config) {
  return saveConfig(config, config.systemConfigurations, systemConfigurationUrl);
}

function saveConfig(config, body, url) {
  return fetch(
    AjaxUtils.setParameters(AppConfig.server.serviceUrlRoot + url,
      [
        AjaxUtils.getUserNameParam(),
      ]),
    {
      method: 'put',
      headers: AjaxUtils.getHeaders(),
      body: JSON.stringify(body),
    })
    .then(response => {
      switch (response.status) {
        case 200:
        case 201:
          response.text().then(data => {
            config.successCallback({data, body});
          });
          break;
        default:
          response.text().then(data => {
            config.errorCallback(data);
          });
      }
    })
    .catch(error => config.errorCallback(error));
}

export default {
  getGlobalConfigurations,
  saveGeozoneConfiguration,
  saveSystemConfigurations,
}