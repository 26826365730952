<template>
  <div class="rowContainer" id="rowContainer">
    <div
      :id="element.id"
      :key="index"
      :style="getStyleForRow(element)"
      class="table-row"
      v-for="(element, index) in elements">
      <div class="icon-column operationIcons pl-2" v-if="isDronePlanModel()">
        <img :src="getIconImg('manually_created')"
             alt="manually created operation plan"
             class="icon-column-content"
             v-if="isManuallyCreatedOperationPlan(element)"
        >
        <img :src="getAlertIconImg(element)"
             alt="operation plan has messages associated with it"
             class="icon-column-content"
             v-if="hasAnyAssociatedAlerts(element) && hasAccessToDroneAlertFeature"
        >
      </div>
      <div
        :id="`${field.id}`"
        :class="[getClass(field), 'selector']"
        :key="getKey(field)"
        :style="getStyle(element, field)"
        @click="onSelect(element)"
        v-for="field in displayableFields"
      >
        <span v-html="getValue(element, field.propertyName, elements)"></span>
        <img :src="field.getImg(element)" class="statusClass mb-1" v-if="field.getImg">
      </div>
      <div
        :key="index + '-button'"
        class="button-column"
        v-for="(button, index) in buttons">
        <button
          :id="button.getId(element)"
          @click="button.onClick(element)"
          :class="['action-button', button.isDisabled(element) ? 'disabled-action-button' : '']">
          {{ button.getButtonText() }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import AppConfig from '../../../../config/appConfig';
import FeatureUtils from '../../../../utils/FeatureUtils';
import ImgUtils from '../../../../utils/ImgUtils';
import FeatureType from '../../../../constants/featureType';
import DronePlanModel from '../../../../model/operationPlan';
import DroneStateColoringConfig from "../../../../config/droneStateColoringConfig";
import GlobalConfigurationUtils from "../../../../utils/GlobalConfigurationUtils";
import GlobalConfigurationIdConfig from "../../../../config/GlobalConfigurationIdConfig";
import AlertMessageUtils from "../../../../utils/AlertMessageUtils";

export default {
  name: 'ListBody',
  props: ['model', 'elements', 'onSelect', 'listHeight', 'targetedMessage'],
  computed: {
    displayableFields() {
      return this.model.fields.filter(field => field.displayInList);
    },
    buttons() {
      return this.model.buttons;
    },
    hasAccessToDroneAlertFeature() {
      return FeatureUtils.Access.hasAccessToDroneAlertFeature();
    }
  },
  mounted: function () {
    let container = document.getElementById("rowContainer");
    const zoomLevel = AppConfig.ui.applicationZoomLevel;
    let height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    height = (height * this.listHeight) / 100;
    if (window.innerHeight > 800) {
      container.style.maxHeight = (height / zoomLevel) / 1.5 + "px";
    } else {
      container.style.maxHeight = (height / zoomLevel) / 2 + "px";
    }
    this.scrollToSelectedMessage();
  },
  updated: function () {
    this.scrollToSelectedMessage();
  },
  methods: {
    isManuallyCreatedOperationPlan(element) {
      return FeatureUtils.Icons.isManuallyCreatedOperationPlan(element);
    },
    hasAnyAssociatedAlerts(element) {
      return FeatureUtils.Icons.getAssociatedAlertsForOperationPlan(element).length > 0;
    },
    getAlertIconImg(element) {
      return AlertMessageUtils.getAlertIcon(element.id, 'svg')
    },
    getIconImg(icon) {
      return ImgUtils.getButtonImg(icon, 'svg');
    },
    defaultGetValue(element, name) {
      return element[name];
    },
    getValue(element, name, elements) {
      const field = this.model.fields.find(f => f.propertyName === name);
      const getter = field.getterForList || field.getter || this.defaultGetValue;
      return getter(element, name, elements);
    },
    getStyle(message, field) {
      return field.getFieldStyle && message ? field.getFieldStyle(message) : '';
    },
    getStyleForRow(message) {
      const featureHighlightColor = GlobalConfigurationUtils.getDisplayConfigColorByIdAndByCurrentTheme(
        GlobalConfigurationIdConfig.DISPLAY_CONFIGURATION_IDS.HIGHLIGHT_INFO_PANEL_NEW_MESSAGE
      );

      const style = FeatureUtils.Styling.computeColorAndBgColorStyleByBgColor(featureHighlightColor);
      const selectedMessageId = this.$store.getters.selectedFeatureId;
      if (message.featureType === FeatureType.DRONES && message.id !== selectedMessageId) {
        const droneStyleConfig = this.$store.state.themeStore.currentTheme.stylingConfigs.find(s => s.layer === FeatureType.DRONES);
        style['background-color'] = DroneStateColoringConfig.getDroneIconColorByState(message.state, droneStyleConfig);
        return style;
      }
      if (message.id === selectedMessageId || this.targetedMessage && message.id === this.targetedMessage.id) {
        const informationPanelHighlightColor = GlobalConfigurationUtils.getDisplayConfigColorByIdAndByCurrentTheme(
          GlobalConfigurationIdConfig.DISPLAY_CONFIGURATION_IDS.HIGHLIGHT_INFO_PANEL_SELECTED_MESSAGE
        );
        style['background-color'] = informationPanelHighlightColor !== undefined ?
          FeatureUtils.Styling.toRgba(informationPanelHighlightColor) : 'rgba(65, 65, 65, 0.5)';
        return style;
      }
    },
    getKey(field) {
      return field.isTimeDependent ? this.$store.state.informationStore.forceRenderCount : field.name + 'Key'
    },
    getClass(element) {
      return element.tableStyle ? element.tableStyle : 'text';
    },
    isDronePlanModel() {
      return this.model.name === DronePlanModel.name;
    },
    scrollToSelectedMessage() {
      const selectedMessageId = this.$store.getters.selectedFeatureId;
      if (selectedMessageId) {
        document.getElementById(selectedMessageId)?.scrollIntoView();
      }
    }
  }
}
</script>
<style src="../../utm.css"></style>