<template>
  <div id="geozone-allowlist-container" v-if="isOpen" class="allowListContainer">
    <div class="dialog modal" role="dialog">
      <div class="modal-dialog modal-dialog-centered allowlist-dialog" role="document">
        <div class="modal-content smartSISPanel">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('labels.geozone.allowlist.allowlistForGeozone') }}</h5>
          </div>
          <div class="modal-body allowlistErrorBody">
            <div id="allowlist-form" class="row m-0">
              <div class="col">
                <div class="id-type row">
                  <div class="m-1 col-4 identification-type p-0">
                    {{ $t('labels.geozone.allowlist.identificationType') }}:
                  </div>
                  <div class="m-1 col-7 p-0" id="id-type-selection-container">
                    <label class="m-1 radio">
                      <input id="allowlist-drone-identification" class="id-type" type="radio"
                             v-model="whitelistedIdType" :value="geozoneWhitelistedIdType.WHITELISTED_DRONES">
                      {{ $t('labels.geozone.allowlist.droneIdentification') }}
                    </label>
                    <label class="m-1 radio">
                      <input id="allowlist-operator-id" class="id-type" type="radio" v-model="whitelistedIdType"
                             :value="geozoneWhitelistedIdType.WHITELISTED_OPERATORS">
                      {{ $t('labels.geozone.allowlist.operatorId') }}
                    </label>
                    <label class="m-2 radio">
                      <input id="allowlist-drone-plan-id" class="id-type" type="radio" v-model="whitelistedIdType"
                             :value="geozoneWhitelistedIdType.WHITELISTED_OPS">
                      {{ $t('labels.geozone.allowlist.dronePlanId') }}
                    </label>
                  </div>
                </div>
                <div class="row" id="id-input-container">
                  <div class="col-4 p-0 mt-1">
                    <div v-if="whitelistedIdType === geozoneWhitelistedIdType.WHITELISTED_DRONES" class="m-1 row"
                         id="type-dropdown-container">
                      <div class="col-2 drone-type-centralization p-0 identification-type pr-1" id="drone-type-title">
                        {{ $t('labels.common.type') }}:
                      </div>
                      <div class="col-10 p-0 pl-2 centralization" id="type-dropdown">
                        <PropertyDropDown
                          class="m-0"
                          :is-mandatory="true"
                          :get-value="getWhitelistedDroneIdType"
                          :set-value="setWhitelistedDroneIdType"
                          :is-valid="whitelistedDroneIdTypeIsNotEmpty"
                          :options="droneIdentificationTypeOptions"
                          id="drone-identification-type-dropdown"/>
                      </div>
                    </div>
                  </div>
                  <div class="col-8 p-0 row ml-1">
                    <div class="col-10 row m-1 p-0">
                      <div class="m-1 col-1 centralization p-0 ml-2 pl-1 mr-2" id="id-title">
                        {{ $t('labels.geozone.allowlist.value') }}:
                      </div>
                      <div class="m-2 col-9 p-0" id="id-input">
                        <input id="allowlisted-input-value"
                               :class="['form-control', isValidUuid && isNotEmpty ? '' : 'invalid'].join(' ')"
                               type="text" v-model="whitelistedValue">
                      </div>
                    </div>
                    <div class="col-1 p-0 m-2">
                      <button class="btn btn-primary m-1 p-2"
                              id="add-id-to-allowList-button"
                              :disabled="addDisabled"
                              @click="addIdToWhiteList">
                        {{ $t('labels.common.add') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="already-allowlisted-ids-container" class="w-100 container-fluid pl-1 pr-1">
              <div class="table-row header">
                <div id="allowlisted-value-type" class="col-4">{{ $t('labels.common.type') }}</div>
                <div id="allowlisted-value" class="col-6">{{ $t('labels.common.id') }}</div>
                <div class="col-2"></div>
              </div>
              <div class="rowContainer card-body allowListBody pt-0">
                <div :key="whitelistedElement.type + index"
                     class="table-row allowList-table-item"
                     v-for="(whitelistedElement, index) in getWhiteListedElements"
                     :id="`allowlisted-entry-${index}`"
                >
                  <div id="type" class="col-4 allowlist-table-item-type">{{ whitelistedElement.displayedType }}</div>
                  <div id="value" class="col-6 allowlist-table-item-text">{{ whitelistedElement.id }}</div>
                  <div id="allowlisted-entry-remove-button" class="col-2">
                    <div id="allowlist-remove-item">
                      <button
                        :id="'remove-id-from-allowlist-button-' + index"
                        :class="['btn', 'btn-primary'].join(' ')"
                        @click="removeIdFromAllowList(whitelistedElement)"
                      >
                        <img class="removeImg" v-bind:src="getButtonImg('remove')"
                             :alt="$t('labels.common.remove')">
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button id="allowlist-close-button" class="btn btn-primary" @click="close">
              {{ $t('labels.common.close') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import A from '../../../constants/actions';
import AppConfig from '../../../config/appConfig';
import {mapGetters} from "vuex";
import DialogUtils from "../../../utils/DialogUtils";
import Logger from '../../../utils/LoggerUtils';
import PopupUtils from "../../../utils/PopupUtils";
import GeozoneService from "../../../services/geozoneService";
import ImgUtils from "../../../utils/ImgUtils";
import PropertyDropDown from "../../../common/PropertyDropDown";
import droneIdentificationTypesConfig from "../../../config/droneIdentificationTypesConfig";
import geozoneWhitelistedIdType from "../../../constants/geozoneWhitelistedIdType";

export default {
  name: 'WhiteListPanel',
  components: {
    PropertyDropDown
  },
  data() {
    return {
      whitelistedIdType: geozoneWhitelistedIdType.WHITELISTED_DRONES,
      whitelistedDroneIdType: null,
      whitelistedDroneIdOtherType: null,
      whitelistedValue: null,
    }
  },
  computed: {
    geozoneWhitelistedIdType() {
      return geozoneWhitelistedIdType;
    },
    ...mapGetters({
      isOpen: 'isWhiteListPanelOpen',
      geozoneId: 'getGeozoneId',
      selectedGeozone: 'getSelectedGeozone',
      getWhiteListedElements: 'getWhiteListedElements',
    }),
    addDisabled() {
      return this.whitelistedValue === null || this.whitelistedValue === '' || this.whitelistedIdType === null ||
        !this.isValidUuid || (this.whitelistedIdType === geozoneWhitelistedIdType.WHITELISTED_DRONES &&
          this.whitelistedDroneIdType === null);
    },
    isValidUuid() {
      const uuidRegExp = new RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i);
      return this.whitelistedIdType === geozoneWhitelistedIdType.WHITELISTED_OPS ?
          this.whitelistedValue?.match(uuidRegExp) : true;
    },
    isNotEmpty() {
      return this.whitelistedValue !== null && this.whitelistedValue !== '';
    },
    droneIdentificationTypeOptions() {
      return droneIdentificationTypesConfig
        .getDroneIdentificationTypeObjectsByValues(AppConfig.droneIdentificationTypes)
        .concat(this.droneIdentificationOtherTypes)
        .filter(type => type.value !== "ID_OTHER");
    },
    whitelistedDroneIdTypeIsNotEmpty() {
      return this.whitelistedDroneIdType !== null && this.whitelistedDroneIdType !== '';
    },
    droneIdentificationOtherTypes() {
      return AppConfig.droneIdentificationOtherTypes.map(
        t => droneIdentificationTypesConfig.mapOtherTypeValueToDroneIdentificationTypeObject(t)
      );
    },
  },
  watch: {
    whitelistedIdType() {
      this.whitelistedDroneIdType = null;
      this.whitelistedDroneIdOtherType = null;
      this.whitelistedValue = null;
    }
  },
  methods: {
    getButtonImg(name) {
      return ImgUtils.getButtonImg(name, 'svg');
    },
    close() {
      this.whitelistedIdType = geozoneWhitelistedIdType.WHITELISTED_DRONES;
      this.whitelistedDroneIdType = null;
      this.whitelistedDroneIdOtherType = null;
      this.whitelistedValue = null;
      this.$store.dispatch(A.WHITELIST_PANEL_CLOSE);
    },
    addIdToWhiteList() {
      const whitelistEntry = {
        type: this.whitelistedIdType,
        value: this.whitelistedValue,
        droneIdType: this.whitelistedDroneIdType,
        droneIdOtherType: this.whitelistedDroneIdOtherType,
        country: this.selectedGeozone.country,
        identifier: this.selectedGeozone.identifier
      };
      if (this.isIdAlreadyInTheList(whitelistEntry, this.selectedGeozone)) {
        DialogUtils.errorNotification(
          this.$i18n.t('errorMessages.geozoneAllowlistIdAlreadyIncluded', {configId: whitelistEntry.value})
        );
      } else {
        DialogUtils.confirmOperation({
          message: this.$i18n.t('confirmationMessages.geozoneAllowlistSendId', {allowlistedId: this.whitelistedValue}),
          yesAction: () => this.performAddAction(whitelistEntry),
        });
      }
    },
    performAddAction(config) {
      this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
      GeozoneService.addIdToAllowlist({
        country: config.country,
        identifier: config.identifier,
        type: config.type,
        body: {
          value: config.value,
          droneIdentificationType: config.droneIdType,
          other: config.droneIdOtherType
        },
        successCallback: () => {
          this.whitelistedIdType = geozoneWhitelistedIdType.WHITELISTED_DRONES;
          this.whitelistedDroneIdType = null;
          this.whitelistedDroneIdOtherType = null;
          this.whitelistedValue = null;
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          Logger.info(this.$i18n.t('logMessages.geozoneAllowlistIdAdded', {configId: config.value}));
          PopupUtils.success(this.$i18n.t('popupMessages.geozoneAllowlistIdAdded'));
        },
        errorCallback: (error) => {
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          Logger.error(this.$i18n.t('logMessages.geozoneAllowlistIdAddError', {configId: config.value, error}));
          DialogUtils.errorNotification(this.$i18n.t('errorMessages.geozoneAllowlistIdAddError', {configId: config.value}));
        }
      })
    },
    removeIdFromAllowList(whitelistedElement) {
      const config = {
        type: whitelistedElement.type,
        value: whitelistedElement.id,
        droneIdType: whitelistedElement.droneIdType,
        droneIdOtherType: whitelistedElement.droneIdOtherType,
        country: this.selectedGeozone.country,
        identifier: this.selectedGeozone.identifier
      };
      DialogUtils.confirmOperation({
        message: this.$i18n.t('confirmationMessages.geozoneAllowlistRemoveId', {idToRemove: whitelistedElement.id}),
        yesAction: () => this.performRemoveAction(config),
      });
    },
    performRemoveAction(config) {
      this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
      GeozoneService.removeIdFromAllowlist({
        country: config.country,
        identifier: config.identifier,
        type: config.type,
        body: {
          value: config.value,
          droneIdentificationType: config.droneIdType,
          other: config.droneIdOtherType,
        },
        successCallback: () => {
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          Logger.info(this.$i18n.t('logMessages.geozoneAllowlistIdRemoved', {configId: config.value}));
          PopupUtils.success(this.$i18n.t('popupMessages.geozoneAllowlistIdRemoved'));
        },
        errorCallback: () => {
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          Logger.error(this.$i18n.t('logMessages.geozoneAllowlistIdRemoveError', {configId: config.value}));
          DialogUtils.errorNotification(
            this.$i18n.t('logMessages.geozoneAllowlistIdRemoveError', {configId: config.value})
          );
        }
      })
    },
    isIdAlreadyInTheList(config, uasZone) {
      const list = uasZone[config.type];
      if (config.type === geozoneWhitelistedIdType.WHITELISTED_DRONES) {
        return list.some(c =>
          c.value === config.value &&
          c.type === config.droneIdType &&
          (c.other ? c.other === config.droneIdOtherType : true)
        );
      }
      return list.includes(config.value);
    },
    getWhitelistedDroneIdType() {
      return this.whitelistedDroneIdType === "ID_OTHER"
        ? this.whitelistedDroneIdOtherType : this.whitelistedDroneIdType;
    },
    setWhitelistedDroneIdType(propName, value) {
      if (droneIdentificationTypesConfig.droneIdentificationTypeExists(value)) {
        this.whitelistedDroneIdType = value;
        this.whitelistedDroneIdOtherType = null;
      } else {
        this.whitelistedDroneIdType = "ID_OTHER";
        this.whitelistedDroneIdOtherType = value;
      }
    }
  }
}
</script>
<style src="./whiteList.css"/>
