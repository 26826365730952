import FeatureType from '../../constants/featureType';
import FeatureUtils from '../../utils/FeatureUtils';
import ThemeElements from '../../constants/theme-elements';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';

export default {

  id: FeatureType.BRIEFING,
  name: 'features.briefing',
  icon: 'briefing',
  staticDataType: [FeatureType.AIRPORT_HELIPORT],
  isViewPortBased: false,
  isFlightLevelBased: false,
  maxNrOfFeaturesPerLocation: 80,
  getReplaceableMessages: FeatureUtils.Filters.filterById,
  featureIdentifier: 'id',
  maxResolution: 10000,
  declutter: true,
  zIndex: 1,
  flightLevel: null,
  theme: {
    elements: [
      ThemeElements.SHOW_TEXT,
      ThemeElements.BORDER_COLOR,
      ThemeElements.TEXT_COLOR,
      ThemeElements.TEXT_SIZE,
      ThemeElements.TEXT_BG_COLOR,
      ThemeElements.ICON_SIZE,
    ]
  },
  displayOnMap: true,
  displayInMessages: true,

  geoJsonMapper: (feature) => {
    return new Feature({
      geometry: new GeoJSON().readGeometry(feature.geometry),
      id: feature.id,
      groupId: feature.id,
      featureType: 'briefing',
      aircraftAddress: feature.aircraftAddress,
      aixmComponents: feature.aixmComponents,
      metFeatures: feature.metFeatures,
      notams: feature.notams,
      virtualFeatures: feature.virtualFeatures,
      departureAirport: feature.departureAirport,
      destinationAirport: feature.destinationAirport,
      firs: feature.firs,
      eobt: feature.eobt,
      arrivalTime: feature.arrivalTime,
      text: feature.text,
      briefingViewports: feature.briefingViewports,
    });
  },

  color: (colors) => {
    return colors.textColor;
  },

}