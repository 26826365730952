<template>
  <div>
    <h5 class="card-header">{{ $t('labels.configurationPanel.groupAndRole.addUser') }}</h5>
    <div class="userSearch">
      <input
        class="form-control form-control-borderless addUserSearch"
        :placeholder="$t('labels.configurationPanel.groupAndRole.groupRoleUserSearch')"
        type="text"
        v-model="searchText"
      >
    </div>
    <div class="list-group userList">
      <template v-for="user in filteredAndSortedUsers" :key="user.id">
        <div class="row m-0" style="flex-wrap: nowrap">
          <div class="p-0 m-0 w-100 hideOverflow">
            <div class="list-group-item smartSISPanel row addUserRow">
              <div class="userNameItem col-7">
                {{ user.lastName + ' ' + ' ' + user.firstName }}
              </div>
              <div class="userEmailItem col-5">
                {{ user.email }}
              </div>
            </div>
          </div>
          <div class="text-right p-0" style="margin: 0 1px">
            <button
              id="add-new-user"
              :class="['list-group-item', 'smartSISPanel', 'removeUserButton'].join(' ')"
              @click="addUserToGroup(user)"
            >
              <span>
                <img class="removeUserImg" v-bind:src="getButtonImg('plus')">
              </span>
            </button>
          </div>
        </div>
      </template>
    </div>
    <div class="closeButtonPanel">
      <button id="close-add-new-user" @click="hideAddUserPopup()" class="btn btn-info pt-1 pb-1">
        {{ $t('labels.common.close') }}
      </button>
    </div>
  </div>
</template>

<script>

import ImgUtils from '../../../../utils/ImgUtils';
import A from '../../../../constants/actions';
import {mapGetters} from 'vuex';
import ModelUtils from '../../../../utils/ModelUtils';

export default {
  name: 'AddNewUserPanel',
  props: ['usersForCurrentGroup'],

  data() {
    return {
      queryText: ''
    }
  },

  computed: {
    ...mapGetters({
      allUsers: 'getAllUsers',
      getCurrentGroupId: 'getCurrentGroupId',
    }),
    searchText: {
      get() {
        return this.queryText;
      },
      set(value) {
        this.queryText = value;
      }
    },
    filters: {
      get() {
        return [
          {
            shouldApply: this.queryText.length > 0,
            apply: users => users.filter(u => this.filterUsersByName(u, this.queryText.toUpperCase())),
            applyToCountedMessages: true
          },
          {
            shouldApply: true,
            apply: users => users.filter(u => this.filerUserAlreadyMemberOfGroup(u)),
            applyToCountedMessages: true
          }
        ];
      }
    },
    currentGroupId() {
      return this.getCurrentGroupId;
    },
    filteredAndSortedUsers() {
      let filteredUsers = this.allUsers;
      this.filters
        .filter(filter => filter.shouldApply)
        .forEach(filter => filteredUsers = filter.apply(filteredUsers));
      filteredUsers = filteredUsers.sort((a, b) => ModelUtils.sortUsers(a, b));
      return filteredUsers;
    }
  },

  methods: {
    getButtonImg(name) {
      return ImgUtils.getButtonImg(name, 'svg');
    },
    hideAddUserPopup() {
      this.$store.dispatch(A.USER_ADD_USER_TOGGLE, false);
    },
    filterUsersByName(user, text) {
      const matchesSearchCriteria = (fieldValue) => fieldValue && fieldValue.toUpperCase().indexOf(text) !== -1;
      return matchesSearchCriteria(user.firstName) || matchesSearchCriteria(user.lastName) ||
        matchesSearchCriteria(user.email);
    },
    filerUserAlreadyMemberOfGroup(user) {
      return !this.usersForCurrentGroup.some(u => u.id === user.id);
    },
    addUserToGroup(user) {
      this.$store.dispatch(A.USER_ADD_USER_TO_CURRENT_GROUP, user);
    },
  }

}
</script>
<style src="./userEditor.css"></style>