<template>
  <div class="row m-0">
    <ListPanel
      class="pt-2 px-0"
      :entries="themes"
      :idPrefix="'theme'"
      :entryStyle="getThemeButtonStyle"
      :onSelectEntry="selectTheme"
    >
    </ListPanel>
    <ThemeEditor v-if="isAllowedToEdit"></ThemeEditor>
  </div>
</template>
<script>

import A from '../../../constants/actions';
import DialogUtils from '../../../utils/DialogUtils';
import ListPanel from '../list-panel/ListPanel.vue';
import PermissionUtils from '../../../utils/PermissionUtils';
import ThemeEditor from './editor/ThemeEditor.vue';

export default {
  name: 'ThemeConfiguration',
  components: {
    ListPanel,
    ThemeEditor,
  },
  computed: {
    isAllowedToEdit() {
      return PermissionUtils.isAllowedToEditThemes();
    },
    themes() {
      return PermissionUtils.getAvailableThemes();
    }
  },
  methods: {
    selectTheme(theme) {
      const currentTheme = this.$store.state.themeStore.currentTheme;
      const originalTheme = this.$store.state.themeStore.originalTheme;
      if (JSON.stringify(currentTheme) !== JSON.stringify(originalTheme)) {
        DialogUtils.confirmOperation(
          {
            message: this.$i18n.t('confirmationMessages.themeConfigurationSelectTheme'),
            yesAction: () => this.$store.dispatch(A.THEME_SELECT_THEME, theme.id),
          }
        );
      } else {
        this.$store.dispatch(A.THEME_SELECT_THEME, theme.id);
      }
    },

    getThemeButtonStyle(theme) {
      return [
        'btn',
        'btn-primary',
        'm-0',
        'listButton',
        'themeButton',
        (this.$store.state.themeStore.currentTheme.id === theme.id) ? 'selected' : '',
      ].join(' ');
    }
  }

}
</script>
<style src="./themeConfiguration.css"></style>