import {i18n} from '../internationalization/index';

const modeOfOperationConfigs = {
  REMOTELY_PILOTED_VLOS: {
    displayValue: 'labels.operationPlan.vlos',
    detailedDisplayValue: 'labels.operationPlan.remotelyPilotedVlos',
    modelValue: 'REMOTELY_PILOTED_VLOS',
    displayInOptions: true
  },

  REMOTELY_PILOTED_EVLOS: {
    displayValue: 'labels.operationPlan.evlos',
    detailedDisplayValue: 'labels.operationPlan.remotelyPilotedEvlos',
    modelValue: 'REMOTELY_PILOTED_EVLOS',
    displayInOptions: true
  },

  REMOTELY_PILOTED_BVLOS: {
    displayValue: 'labels.operationPlan.bvlos',
    detailedDisplayValue: 'labels.operationPlan.remotelyPilotedBvlos',
    modelValue: 'REMOTELY_PILOTED_BVLOS',
    displayInOptions: true
  },

  PILOTED_IFR: {
    displayValue: 'labels.operationPlan.ifr',
    detailedDisplayValue: 'labels.operationPlan.pilotedIfr',
    modelValue: 'PILOTED_IFR',
    displayInOptions: true
  },

  PILOTED_VFR: {
    displayValue: 'labels.operationPlan.vfr',
    detailedDisplayValue: 'labels.operationPlan.pilotedVfr',
    modelValue: 'PILOTED_VFR',
    displayInOptions: true
  },

}

function getDisplayModeOfOperation(modeOfOperation) {
  const mode = Object.values(modeOfOperationConfigs).find(config => config.modelValue === modeOfOperation);
  return mode ? i18n.global.t(mode.displayValue) : '';
}

function getCompleteDisplayModeOfOperation(modeOfOperation) {
  const mode = Object.values(modeOfOperationConfigs).find(config => config.modelValue === modeOfOperation);
  return mode ? i18n.global.t(mode.detailedDisplayValue) : '';
}

function getOptionValuesForCreationForm() {
  const modeOfOperations = Object.values(modeOfOperationConfigs);
  return modeOfOperations
    .filter(type => type.displayInOptions)
    .map(type => {
      return {name: i18n.global.t(type.detailedDisplayValue), value: type.modelValue}
    });
}

export default {

  modeOfOperationConfigs,
  getDisplayModeOfOperation,
  getCompleteDisplayModeOfOperation,
  getOptionValuesForCreationForm,
}