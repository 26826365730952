export default {
  SAILDETAILS_1: {
    r: 86,
    g: 146,
    b: 146,
    a: 255
  },
  SAILDETAILS_2: {
    r: 116,
    g: 156,
    b: 132,
    a: 0.6
  },
  SAILDETAILS_3: {
    r: 148,
    g: 164,
    b: 116,
    a: 0.1
  },
  SAILDETAILS_4: {
    r: 180,
    g: 172,
    b: 100,
    a: 0.6
  },
  SAILDETAILS_5: {
    r: 213,
    g: 174,
    b: 81,
    a: 255
  },
  SAILDETAILS_6: {
    r: 248,
    g: 181,
    b: 64,
    a: 255
  }
}