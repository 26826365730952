import Store from '@/store';
import A from '@/constants/actions';
import OperationPlanService from '@/services/operationPlanService';
import OpDialogCallBackUtils from "./OpDialogCallBackUtils";
import UserRolePriorities from "./UserRolePriorities";

const MINUTES_TO_MILLIS = 60 * 1000;
const MILLIS_TO_MINUTES = 1 / MINUTES_TO_MILLIS;

export default class OpRejectionDialogUtils {

  static open(operationPlan, message) {
    Store.dispatch(A.OP_REJECTION_DIALOG_OPEN, {operationPlan, message});
  }

  static rejectOperationPlan(operationPlan, rejectionProps) {
    if (rejectionProps.newStartTimeIsProposed) {
      rejectOperationPlanWithCounterProposal(operationPlan, rejectionProps);
    } else {
      rejectOperationPlan(operationPlan, rejectionProps.rejectionReason);
    }
  }

  static getOpStartTime(operationPlan) {
    return new Date(OperationPlanService.getEarliestEffectiveTimeBegin(operationPlan));
  }

  static getOpEndTime(operationPlan) {
    return new Date(OperationPlanService.getLatestEffectiveTimeEnd(operationPlan));
  }

  static getOffsetInMinutes(proposedStartTime, initialOpStartTime) {
    proposedStartTime.setSeconds(0);
    proposedStartTime.setMilliseconds(0);
    initialOpStartTime.setSeconds(0);
    initialOpStartTime.setMilliseconds(0);

    return (proposedStartTime - initialOpStartTime) * MILLIS_TO_MINUTES;
  }

  static getOpEndTimeWithOffset(initialOpEndTime, offsetInMinutes) {
    return new Date(initialOpEndTime.getTime() + offsetInMinutes * MINUTES_TO_MILLIS);
  }

  static close() {
    Store.dispatch(A.OP_REJECTION_DIALOG_CLOSE);
  }
}

function rejectOperationPlanWithCounterProposal(operationPlan, rejectionProps) {
  Store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
  OperationPlanService.rejectOperationPlan({
    operationPlanId: operationPlan.id,
    partialResultId: UserRolePriorities.getPartialResultIdForOpAndCurrentUser(operationPlan),
    reasonMessage: rejectionProps.rejectionReason,
    proposedTimeOffsetInMinutes: rejectionProps.proposedTimeOffsetInMinutes,
    successCallback: OpDialogCallBackUtils.getSuccessCallback(operationPlan),
    errorCallback: OpDialogCallBackUtils.getErrorCallback(operationPlan)
  });
}

function rejectOperationPlan(operationPlan, reasonMessage) {
  Store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
  OperationPlanService.rejectOperationPlan({
    operationPlanId: operationPlan.id,
    partialResultId: UserRolePriorities.getPartialResultIdForOpAndCurrentUser(operationPlan),
    reasonMessage,
    proposedTimeOffsetInMinutes: null,
    successCallback: OpDialogCallBackUtils.getSuccessCallback(operationPlan),
    errorCallback: OpDialogCallBackUtils.getErrorCallback(operationPlan)
  });
}