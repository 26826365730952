<template>
  <div id="user-panel" class="userPanel smartSISPanel zIndexTop" v-if="isOpen">
    <div class="userPanelContent">
      <RolesPanelHeader></RolesPanelHeader>
      <UpdatesPanel></UpdatesPanel>
      <div class="alert alert-secondary p-3"
           v-html="$t('logMessages.rolesPanelWelcome', {username:user.username, rank})"/>
      <div id="row-group-container-id">
      <span :key="group.groupId" v-for="group in groupRoleRepresentatives">
        <span :key="role.name" v-for="role in group.roles">
          <span style="flex-wrap: nowrap">
            <a
              :class="getButtonStyle(group, role)"
              @click="selectGroupRole(group, role)"
            >
              <div class="wrapper">
                <div class="box box1">
                  <span class="btnGroupName">{{ group.groupName }}</span>
                  <button
                    :class="[isDefault(group.groupId, role.id) ? 'selectedDefault' : '', 'defaultGroupAndRoleButton']"
                    :disabled="isDefault(group.groupId, role.id)"
                    :id="'defaultTheme_' + group.groupName"
                    @click="toggleDefault(group, role, user.username, $event)"
                    type="btn btn-primary"
                  >
                    <span class="defaultIcon">
                      <img alt="PinButton" class="pinImg" v-bind:src="getButtonImg('pin')">
                    </span>
                    <span class="defaultText">
                      {{ $t('labels.common.default') }}
                    </span>
                  </button>
                </div>
                <div class="box box2">
                  <span class="btnRoleName">{{ role.name }}</span>
                </div>
              </div>
            </a>
          </span>
        </span>
      </span>
        <button
          id="logout-button"
          @click="logout()"
          class="roleButton logout btn btn-danger"
        >
          {{ $t('labels.common.logout') }}
        </button>
      </div>
      <VersionPanel></VersionPanel>
      <UsersConnections></UsersConnections>
    </div>
  </div>
</template>

<script>

import A from '../../constants/actions';
import PU from '../../utils/PermissionUtils';
import Roles from '../../constants/roles';
import RolesPanelHeader from './RolesPanelHeader.vue';
import UpdatesPanel from './UpdatesPanel.vue';
import UsersConnections from './UsersConnections';
import VersionPanel from './VersionPanel.vue';
import Logger from '../../utils/LoggerUtils';
import ImgUtils from "../../utils/ImgUtils";
import PopupUtils from "../../utils/PopupUtils";
import DialogUtils from "../../utils/DialogUtils";
import UserService from "../../services/smartSisUserService";
import HudTabName from '../../constants/hudTabName';
import PermissionUtils from "../../utils/PermissionUtils";

export default {
  name: 'RolesPanel',

  components: {
    RolesPanelHeader,
    UpdatesPanel,
    UsersConnections,
    VersionPanel,
  },

  methods: {
    getButtonImg(name) {
      return ImgUtils.getButtonImg(name, 'svg');
    },
    logout() {
      this.$store.dispatch(A.AUTH_LOGOUT);
      Logger.info(this.$i18n.t('logMessages.userLogout'));
    },
    selectGroupRole(group, role) {
      const config = {
        groupId: group.groupId,
        roleId: role.id,
        onSuccess: (data) => this.$store.dispatch(A.USER_MANAGEMENT_SELECT_ACTIVE_GROUP_ROLE, data)
      };
      UserService.getGroupDetails(config);
      this.toggleOpen();
    },
    toggleOpen() {
      this.$store.dispatch(A.HUD_SET_OPENED_TAB, HudTabName.USER_ROLES);
    },
    isSelected(groupId, roleId) {
      const activeRole = this.$store.state.userStore.activeGroupRole;
      return activeRole && activeRole.role.id === roleId && activeRole.group.id === groupId;
    },
    getButtonStyle(group, role) {
      return [
        'btn',
        'btn-primary',
        'pr-0',
        'roleButton',
        'listButton',
        (this.isSelected(group.groupId, role.id)) ? 'selected' : '',
      ].join(' ');
    },
    toggleDefault(group, role, username, event) {
      event.stopPropagation();

      const groupAndRole = {
        groupId: group.groupId,
        roleId: role.id,
        username: username
      };

      const config = {
        groupAndRole: groupAndRole,
        successCallback: (groupAndRole) => {
          this.$store.dispatch(A.USER_MANAGEMENT_TOGGLE, {type: 'groupAndRole', value: groupAndRole});
          PopupUtils.success(this.$i18n.t('popupMessages.defaultGroupRoleSaved'));
          Logger.info(this.$i18n.t('logMessages.defaultGroupRoleSaved', {groupName: group.name, roleName: role.name}));
        },
        errorCallback: (error) => {
          const errorMessage = JSON.parse(error);
          Logger.error(this.$i18n.t('errorMessages.defaultGroupRoleSaveError', {
            groupName: group.name,
            roleName: role.name,
            error
          }));
          DialogUtils.errorNotification(errorMessage.errorMessage);
        },
      };
      UserService.saveDefaultGroupAndRole(config);
    },
    isDefault(groupId, roleId) {
      const defaultGroupAndRole = this.$store.state.userStore.groupAndRole;
      return defaultGroupAndRole ? defaultGroupAndRole.groupId === groupId && defaultGroupAndRole.roleId === roleId : false;
    }
  },

  computed: {
    isOpen() {
      return this.$store.getters.isGivenTabOpened(HudTabName.USER_ROLES);
    },
    groupRoleRepresentatives() {
      return this.$store.state.userStore.groupRoleRepresentatives;
    },
    user() {
      return this.$store.state.userStore.userProfile;
    },
    rank() {
      const userRole = PU.isSuperAdmin() ? Roles.SUPER_ADMIN : PU.isSupervisor() ? Roles.SUPERVISOR : Roles.USER;
      return PermissionUtils.isReadOnlyUser() ? userRole + ' | Read Only' : userRole;
    }
  },

}
</script>
<style src="./rolesPanel.css"></style>