import FeatureType from '../../constants/featureType';
import FeatureUtils from '../../utils/FeatureUtils';
import ThemeElements from '../../constants/theme-elements';
import Feature from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";
import ModelUtils from "../../utils/ModelUtils";
import {v4 as uuidv4} from "uuid";
import MessageUtils from "../../utils/MessageUtils";

export default {

  id: FeatureType.LARA_AIRSPACE,
  name: 'features.laraAirspace',
  icon: 'sigmet',
  staticDataType: [],
  isViewPortBased: false,
  isFlightLevelBased: true,
  maxNrOfFeaturesPerLocation: 100,
  getReplaceableMessages: FeatureUtils.Filters.filterById,
  zIndex: 1,
  displayOnMap: true,
  displayInMessages: true,

  theme: {
    elements: [
      ThemeElements.SHOW_TEXT,
      ThemeElements.TEXT_COLOR,
      ThemeElements.TEXT_SIZE,
      ThemeElements.TEXT_BG_COLOR,
      ThemeElements.IMAGE_COLOR,
      ThemeElements.ICON_SIZE,
      ThemeElements.BORDER_COLOR,
      ThemeElements.FILL_COLOR,
    ]
  },

  color: (colors) => {
    return colors.imageColor;
  },

  defaultStyle: () => {
    return {
      showText: true,
      textSize: 14,
      iconSize: 10,
      colors: {
        borderColor: {r: 143, g: 20, b: 58, a: 1},
        fillColor: {r: 143, g: 20, b: 58, a: 0.3},
        textColor: {r: 143, g: 20, b: 58, a: 1},
        textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
        imageColor: {r: 255, g: 255, b: 255, a: 1},
      }
    }
  },

  geoJsonMapper: (feature) => {
    const geometry = feature.geometries[0];
    return new Feature({
      id: feature.id,
      geometry: new GeoJSON().readGeometry(geometry.horizontalProjection),
      featureType: feature.featureType,
      altitudeType: geometry.uomDimensions,
      minAltitude: geometry.lowerLimit,
      maxAltitude: geometry.upperLimit,
      selected: false,
      isActive: true
    });
  },

  geoJsonVolumeMapper: (feature, volume) => {
    return new Feature({
      id: uuidv4(),
      featureType: feature.featureType,
      geometry: new GeoJSON().readGeometry(volume.horizontalProjection),
      altitudeType: volume.uomDimensions,
      minAltitude: volume.lowerLimit,
      maxAltitude: volume.upperLimit,
      selected: false,
      isActive: true,
      associatedFeatureId: feature.id,
      name: feature.name
    });
  },

  getMinifiedObject(feature) {
    const reservations = feature.reservations.map(r => {
      return {
        id: r.id,
        startDate: r.startDate,
        endDate: r.endDate,
        minAltitude: {
          altitude_value: ModelUtils.roundToTwoDecimals(r.minAltitude),
          units_of_measure: r.uomDimensions
        },
        maxAltitude: {
          altitude_value: ModelUtils.roundToTwoDecimals(r.maxAltitude),
          units_of_measure: r.uomDimensions
        },
        status: r.status,
        closureReason: r.closureReason || "-"
      }
    });
    return {
      id: feature.id,
      featureType: feature.featureType,
      startDate: feature.startDate,
      endDate: feature.endDate,
      minAltitude: {
        altitude_value: ModelUtils.roundToTwoDecimals(MessageUtils.getMinAltitudeForLaraAirspace(feature)),
        units_of_measure: feature.geometries[0].uomDimensions
      },
      maxAltitude: {
        altitude_value: ModelUtils.roundToTwoDecimals(MessageUtils.getMaxAltitudeForLaraAirspace(feature)),
        units_of_measure: feature.geometries[0].uomDimensions
      },
      type: feature.type,
      name: feature.name,
      geometries: feature.geometries,
      reservations: reservations,
      reservable: feature.reservable,
      isPermanent: feature.isPermanent
    };
  },

}