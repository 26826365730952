<template>
  <div>
    <div class="feature-subtypes form-inline row extended-margins">
      <button :id="feature.id" class="feature-subtypes-element btn btn-primary smart-tab col no-borders"
              v-for="feature in this.features" :key="feature.id"
              @click="selectFeatureType(feature.id)"
              :style="feature.id === selectedFeatureType ? getStyle(feature.id) : ''"
      >
        <span class="nav-counter-right-feature-subtypes">{{ countMessages(feature.id) }}</span>
        <span class="nav-icon">
          <img class="navImg" v-bind:src="getButtonImg(feature)">
        </span>
        {{ $t(feature.subtabName || feature.abbreviation).toUpperCase() }}
      </button>
    </div>
    <div class="feature-subtypes-border extended-margins"
         :style="getStyle(this.selectedFeatureType)">
    </div>
  </div>
</template>

<script>
import Features from "../../config/features";
import ImgUtils from "../../utils/ImgUtils";
import {mapGetters} from "vuex";
import FeatureUtils from "../../utils/FeatureUtils";
import A from "../../constants/actions";

export default {
  name: "FeatureSubtypes",
  props: ['features'],
  computed: {
    ...mapGetters({
      messages: 'getMessages',
    }),
    selectedFeatureType() {
      return this.$store.state.informationStore.activeTab.featureType;
    }
  },
  methods: {
    defaultMessageFilter(messages, featureType) {
      return messages.filter((msg) => {
        return msg.featureType === featureType;
      });
    },
    getButtonImg(feature) {
      return ImgUtils.getButtonImg('prod_' + feature.icon, feature.iconExtension || 'svg');
    },
    selectFeatureType(featureType) {
      this.$store.state.informationStore.activeTab.featureType = featureType;
      this.$store.dispatch(A.REMOVE_SELECT_FEATURE);
    },
    countMessages(featureType) {
      const feature = Features.getInfoPanelFeatures().filter(product => product.id === featureType)[0];
      const messageFilter = feature.filterForCounter || feature.filterMessages || this.defaultMessageFilter;
      const filteredMessages = messageFilter(this.messages, featureType);
      return filteredMessages.length > 99 ? '99+' : filteredMessages.length;
    },
    getStyle(featureTypeId) {
      const messages = this.$store.state.informationStore.messages;
      const containsUnread = messages.find(msg => msg.featureType === featureTypeId && !msg.seen && !msg.generated && this.defaultMessageFilter([msg]));
      if (this.selectedFeatureType === featureTypeId || containsUnread) {
        const color = FeatureUtils.Styling.getFeatureColor(featureTypeId);
        const bgColor = 'rgba(' + [color.r, color.g, color.b, color.a].join(',') + ')';
        return {'background-color': bgColor}
      }
    },
  }


}
</script>

<style src="./leftPanel.css"></style>