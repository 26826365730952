import FeatureType from '../../constants/featureType';
import ThemeElements from '../../constants/theme-elements';
import FeatureUtils from '../../utils/FeatureUtils';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';

const TEN_YEARS = 10 * 365 * 24 * 60 * 60 * 1000;

export default {

  id: FeatureType.SERVICE_INSTANCE,
  name: 'features.serviceInstance.name',
  icon: 'serviceinstance',
  abbreviation: 'features.serviceInstance.abbreviation',
  isStaticDataType: false,
  featureIdentifier: 'id',
  isViewPortBased: false,
  isFlightLevelBased: false,
  getReplaceableMessages: FeatureUtils.Filters.filterByIdOrStation,
  maxResolution: 10000,
  declutter: false,
  zIndex: 1,
  flightLevel: null,
  theme: {
    elements: [
      ThemeElements.SHOW_TEXT,
      ThemeElements.BORDER_COLOR,
      ThemeElements.FILL_COLOR,
      ThemeElements.TEXT_COLOR,
      ThemeElements.TEXT_SIZE,
      ThemeElements.TEXT_BG_COLOR,
    ]
  },
  displayOnMap: true,
  displayInMessages: true,

  geoJsonMapper: (feature) => {
    return new Feature({
      geometry: new GeoJSON().readGeometry(feature.geometry),
      id: feature.id,
      name: feature.name,
      status: feature.status,
      maxResolution: 10000,
      featureType: 'serviceinstance'
    });
  },

  getMinifiedObject(feature, minifiedObject) {
    const text = feature.name +
      '\ninstanceId: ' + feature.instanceId +
      '\n' + feature.endpointUri +
      '\nversion: ' + feature.version +
      '\ntype: ' + feature.endpointType +
      '\ncomment: ' + feature.comment +
      '\nstatus : ' + feature.status;
    return {
      ...minifiedObject,
      status: feature.status,
      color: feature.color,
      tac: text,
      validTime: {
        to: Date.now() + TEN_YEARS
      },
      textColor: feature.textColor,
    }
  },

  color: (colors) => {
    return colors.textColor;
  },

  defaultStyle: () => {
    return {
      showText: true,
      textSize: 14,
      iconSize: 100,
      colors: {
        borderColor: {r: 74, g: 144, b: 226, a: 1},
        fillColor: {r: 0, g: 117, b: 255, a: 0.15},
        textColor: {r: 255, g: 255, b: 255, a: 0.8},
        textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
        imageColor: {r: 0, g: 117, b: 255, a: 1}
      }
    }
  },

}