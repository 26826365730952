<template>
  <div v-if="hasAccessToDroneAlertFeature">
    <div
      id="drone-alert-creation-info-panel"
      :class="[this.isDisabled ? 'disabled-action-button' : 'action-button']"
      v-if="getImg">
      <a @click="createDroneAlert">
        <div>
          <span class="button-icon"><img class="infoPanelButton" v-bind:src="getImg('message')"/></span>
        </div>
        <span class="button-text">{{ getText }}</span>
      </a>
    </div>
    <button
      id="drone-alert-button"
      :disabled="isDisabled"
      @click="createDroneAlert"
      class="btn btn-primary utmButton"
      v-else-if="getText"
    >
      {{ getText }}
    </button>
  </div>
</template>
<script>

import A from '../../../../constants/actions';
import DialogUtils from '../../../../utils/DialogUtils';
import PopupUtils from '../../../../utils/PopupUtils';
import Logger from '../../../../utils/LoggerUtils';
import FeatureType from '../../../../constants/featureType';
import DroneService from '../../../../services/droneService';
import Store from '../../../../store';
import AlertState from '../../../../constants/alertState';
import * as Moment from 'moment';
import FeatureUtils from "../../../../utils/FeatureUtils";
import PermissionUtils from "../../../../utils/PermissionUtils";

export default {
  name: 'DroneAlertButton',
  props: ['operationPlanId', 'getImg', 'getText'],
  computed: {
    isOperationPlan() {
      return this.$store.getters.getOperationPlanById(this.operationPlanId);
    },
    hasAccessToDroneAlertFeature() {
      return FeatureUtils.Access.hasAccessToDroneAlertFeature();
    },
    isDisabled() {
      return this.isOperationPlan ? PermissionUtils.isReadOnlyUser() : true;
    },
  },
  methods: {
    createDroneAlert() {
      PermissionUtils.performActionIfAllowed(this.isDisabled, this.showDroneAlertDetails);
    },
    showDroneAlertDetails() {
      this.$store.dispatch(A.DRONE_ALERT_DETAILS_SHOW, {
        operationPlan: [this.operationPlanId],
        callback: (droneAlert) => {
          droneAlert.poses = Store.state.informationStore.messages.filter(
            m => m.featureType === FeatureType.DRONES &&
              droneAlert.operation_plans.includes(m.flightId)
          ).map(m => m.id);
          droneAlert.history = [{
            state: AlertState.PENDING_ACKNOWLEDGEMENT,
            time_stamp: Moment.utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
          }];
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          DroneService.createDroneAlert({
            droneAlert,
            successCallback: () => {
              this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
              Logger.info(this.$i18n.t('logMessages.dronePlanMessageCreated', {dronePlanId: this.operationPlanId}));
              PopupUtils.success(this.$i18n.t('popupMessages.messageCreated'));
            },
            errorCallback: (error) => {
              this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
              const errorMessage = this.$i18n.t('errorMessages.messageCreateError', {error});
              Logger.error(errorMessage);
              DialogUtils.errorNotification(errorMessage);
            }
          });
        }
      });
    }
  }
};
</script>