export default {

  defaultStyling: {
    showText: true,
    iconSize: 4,
    textSize: 14,
    colors: {
      borderColor: {
        r: 140,
        g: 181,
        b: 205,
        a: 0.83
      },
      fillColor: {
        r: 140,
        g: 181,
        b: 205,
        a: 0.01
      },
      textColor: {
        r: 140,
        g: 181,
        b: 205,
        a: 0.8
      },
      textBackgroundColor: {
        r: 140,
        g: 181,
        b: 205,
        a: 0.2
      },
      imageColor: {
        r: 140,
        g: 181,
        b: 205,
        a: 0.4
      },
    }
  },

}