import Store from '../store';
import BaseFeaturesConfig from '../config/BaseFeaturesConfig';
import Features from '../config/features';

export default class FeatureSubtypesUtils {

  static getFeatureSubtypesSortedByConfigOrder(baseFeatureId) {
    return getFeatureSubtypeIdsSortedByConfigOrder(baseFeatureId)
      .map(productType => Features.getFeatureById(productType));
  }

  static getDefaultSelectedFeatureSubtype(baseFeatureId) {
    return getFeatureSubtypeIdsSortedByConfigOrder(baseFeatureId)[0];
  }
}

function getFeatureSubtypeIdsSortedByConfigOrder(baseFeatureId) {
  const featuresAssociatedWithBaseFeature = BaseFeaturesConfig.getBaseFeatureConfig(baseFeatureId).features;
  const byConfigOrder = (a, b) =>
    featuresAssociatedWithBaseFeature.indexOf(a) - featuresAssociatedWithBaseFeature.indexOf(b);
  return Store.getters.getFeaturesConfiguredForCurrentView
    .filter(productType => featuresAssociatedWithBaseFeature.includes(productType))
    .sort(byConfigOrder);
}
