import FeatureType from '../../constants/featureType';
import FeatureUtils from '../../utils/FeatureUtils';

export default {

  id: FeatureType.TAF,
  name: 'features.taf',
  icon: 'taf',
  staticDataType: [FeatureType.AIRPORT_HELIPORT],
  isViewPortBased: false,
  isFlightLevelBased: false,
  maxNrOfFeaturesPerLocation: 1,
  getReplaceableMessages: FeatureUtils.Filters.filterByIdOrStation,
  timeoutHandler: FeatureUtils.Timeout.getMetTimeoutHandler(FeatureUtils.Timeout.getTimeoutForLayer(FeatureType.TAF)),

  displayOnMap: false,
  displayInMessages: true,

  getMinifiedObject(feature, minifiedObject) {
    return {
      ...minifiedObject,
      status: feature.status,
      validTime: (feature.status === 'CANCELLATION') ? {from: 1, to: -1} : feature.validTime,
    };
  },

  sortFeaturesList(e1, e2) {
    if ((e1.status === 'CANCELLATION' || e2.status === 'CANCELLATION') &&
      !(e1.status === 'CANCELLATION' && e2.status === 'CANCELLATION')) {
      return (e1.status === 'CANCELLATION') ? 1 : -1;
    }
    return (e1.status === 'CANCELLATION') ? 1 : (e1.status === 'AMENDMENT' && e2.status !== 'AMENDMENT') ? -1 : 0;
  }

}