import FeatureType from '../../constants/featureType';
import ThemeElements from '../../constants/theme-elements';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';

export default {

  id: FeatureType.RUNWAY_ELEMENT,
  name: 'features.runwayElement.name',
  abbreviation: 'features.runwayElement.abbreviation',
  isStaticDataType: true,
  featureIdentifier: 'id',
  isViewPortBased: false,
  isFlightLevelBased: false,
  maxResolution: 10000,
  declutter: true,
  zIndex: 1,
  flightLevel: null,
  theme: {
    elements: [
      ThemeElements.SHOW_TEXT,
      ThemeElements.BORDER_COLOR,
      ThemeElements.FILL_COLOR,
      ThemeElements.TEXT_COLOR,
      ThemeElements.TEXT_SIZE,
      ThemeElements.TEXT_BG_COLOR,
    ]
  },
  displayOnMap: true,
  displayInMessages: false,

  geoJsonMapper: (feature) => {
    return new Feature({
      geometry: new GeoJSON().readGeometry(feature.geometry),
      id: feature.timesliceId || feature.id || feature.featureId,
      designator: feature.designator,
      type: feature.type,
      maxResolution: 10000,
      featureType: 'runwayelement'
    });
  },

  color: (colors) => {
    return colors.textColor;
  },

  defaultStyle: () => {
    return {
      showText: true,
      textSize: 14,
      iconSize: 10,
      colors: {
        borderColor: {r: 80, g: 70, b: 70, a: 1},
        fillColor: {r: 240, g: 228, b: 255, a: 0.1},
        textColor: {r: 80, g: 70, b: 70, a: 1},
        textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
      }
    }
  },

}