<template>
  <div class="col-10">
    <div class="d-flex justify-content-between">
      <div class="form-group form-inline">
        <strong>{{ $t('labels.common.name') }}:</strong>
        <input
          id="themeName"
          type="text"
          :class="['form-control', 'm-1', isValid ? '' : 'is-invalid'].join(' ')"
          v-model="name"
        >
        <span v-if="!isValid" class="text-left invalid-feedback">
          {{ $t('errorMessages.invalidName') }}
        </span>
      </div>
      <div>
        <DayNightSwitch></DayNightSwitch>
      </div>
      <div>
        <BackgroundSelector></BackgroundSelector>
      </div>
    </div>
    <div class="styleTable viewList">
      <table class="table table-fixed table-hover">
        <thead>
        <tr>
          <th id="layer-th-id">{{ $t('labels.common.layer') }}</th>
          <template v-for="element in styleElements" :key="$t(element.name)">
            <th :id="$t(element.name) + '-layer-id'">{{ $t(element.name) }}</th>
          </template>
        </tr>
        </thead>
        <tbody v-bind:style="{height: heightSize}">
        <template v-for="layer in layers" :key="layer.id">
          <tr>
            <td>{{ $t(layer.themeName || layer.name) }}</td>
            <div class="themeElement" v-for="element in styleElements" :key="element.id">
              <td v-for="(layerStyle,index) in [getLayerStyle(layer)]" :key="index">
                <component
                  :id="layer.id + '_' + element.id"
                  v-if="shouldDisplay(layer, element)"
                  :is="(element.type === 'toggle') ? 'ToggleItem' : (element.type === 'color') ? 'ColorItem' : 'NumberItem'"
                  :value="element.retrieve(layerStyle)"
                  :element="element"
                  :layerStyle="layerStyle"
                >
                </component>
              </td>
            </div>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
    <SavePanel
      :isModified="isModified"
      :isEditable="isEditable"
      :isValid="isValid"
      :saveAction="save"
      :saveAsAction="saveAs"
      :cancelAction="cancel"
      :deleteAction="deleteTheme"
    ></SavePanel>
  </div>
</template>
<script>

import A from '../../../../constants/actions';
import ColorItem from './ColorItem.vue';
import DayNightSwitch from '../../../day-night-switch/DayNightSwitch.vue';
import DialogUtils from '../../../../utils/DialogUtils';
import Features from '../../../../config/features';
import NumberItem from './NumberItem.vue';
import ToggleItem from './ToggleItem.vue';
import SavePanel from '../../save-panel/SavePanel.vue';
import PopupUtils from '../../../../utils/PopupUtils';

import ThemeService from '../../../../services/smartSisThemeService';
import BackgroundSelector from '../../../background-selector/BackgroundSelector.vue';
import Logger from '../../../../utils/LoggerUtils';
import PermissionUtils from "../../../../utils/PermissionUtils";

export default {
  name: 'ThemeEditor',

  components: {
    BackgroundSelector,
    ColorItem,
    DayNightSwitch,
    NumberItem,
    ToggleItem,
    SavePanel,
  },

  computed: {
    name: {
      get() {
        return this.$store.state.themeStore.currentTheme.name;
      },
      set(value) {
        this.$store.dispatch(A.THEME_UPDATE_NAME, value);
      },
    },
    heightSize() {
      return this.$store.state.configurationStore.themeViewHeight + 'px';
    },
    layers() {
      return Features.getFeaturesWithDefaultTheme();
    },
    isEditable() {
      return this.$store.state.themeStore.currentTheme.editable !== false;
    },
    styleElements() {
      let elements = [];
      Features.getAvailableMapFeatures().forEach((layer) => {
        if (layer.theme && layer.theme.elements) {
          elements = [...elements, ...layer.theme.elements.filter((element) => {
            return elements.filter((existing) => {
              return existing.name === element.name
            }).length === 0;
          })];
        }
      });
      return elements;
    },
    isModified() {
      const currentTheme = this.$store.state.themeStore.currentTheme;
      const originalTheme = this.$store.state.themeStore.originalTheme;
      return JSON.stringify(currentTheme) !== JSON.stringify(originalTheme);
    },
    isValid() {
      return this.$store.state.themeStore.currentTheme.name.length > 0;
    },
    saveAs() {
      return PermissionUtils.isSuperAdmin() ? this.saveAsCallback : null;
    }
  },

  methods: {
    getLayerStyle(layer) {
      const configs = this.$store.state.themeStore.currentTheme.stylingConfigs.filter((layerConfig) => {
        return layerConfig.layer === layer.id;
      });
      return configs.length > 0 ? configs[0] : null;
    },

    shouldDisplay(layer, element) {
      return layer.theme.elements.filter((layerElement) => {
        return layerElement.name === element.name;
      }).length > 0;
    },

    save() {
      this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
      const currentTheme = JSON.parse(JSON.stringify(this.$store.state.themeStore.currentTheme));
      delete currentTheme.editable;

      const config = {
        theme: currentTheme,
        successCallback: () => {
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          this.$store.dispatch(A.THEME_SAVE_THEME);
          PopupUtils.success(this.$i18n.t('popupMessages.themeSaved'));
          Logger.info(this.$i18n.t('logMessages.themeSaved', {themeName: currentTheme.name}));
        },
        errorCallback: (error) => {
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          const errorMessage = JSON.parse(error);
          Logger.error(this.$i18n.t('errorMessages.themeSaveError', {themeName: currentTheme.name, error}));
          DialogUtils.errorNotification(errorMessage.errorMessage);
        },
      };
      ThemeService.saveTheme(config);
    },

    saveAsCallback(themeName) {
      this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
      const newTheme = JSON.parse(JSON.stringify(this.$store.state.themeStore.currentTheme));
      newTheme.name = themeName;
      delete newTheme.editable;
      delete newTheme.id;

      const serviceConfig = {
        theme: newTheme,
        successCallback: (data) => {
          newTheme.id = data;
          Logger.info(this.$i18n.t('logMessages.themeCreated', {themeName: newTheme.name}));
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          this.$store.dispatch(A.THEME_SAVE_THEME_AS, newTheme);
          PopupUtils.success(this.$i18n.t('popupMessages.themeSaved'));
        },
        errorCallback: (error) => {
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          const errorMessage = JSON.parse(error);
          Logger.error(this.$i18n.t('errorMessages.themeCreateError', {error}));
          DialogUtils.errorNotification(errorMessage.errorMessage);
        },
      };
      ThemeService.saveTheme(serviceConfig);
    },
    cancel() {
      this.$store.dispatch(A.THEME_CANCEL_EDIT);
    },

    deleteTheme() {
      this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);

      let themeId = this.$store.state.themeStore.currentTheme.id;
      let themeName = this.$store.state.themeStore.currentTheme.name;
      const serviceConfig = {
        themeId,
        successCallback: () => {
          Logger.info(this.$i18n.t('logMessages.themeDeleted', {themeName}));
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          this.$store.dispatch(A.THEME_DELETE_THEME, themeId);
          PopupUtils.success(this.$i18n.t('popupMessages.themeDeleted'));
        },
        errorCallback: (error) => {
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          Logger.error(this.$i18n.t('errorMessages.themeDeleteError', {themeName, error}));
          DialogUtils.errorNotification(this.$i18n.t('errorMessages.themeDeleteErrorDialog'));
        },
      };

      ThemeService.deleteTheme(serviceConfig);
    }
  }
}
</script>
<style src="./themeEditor.css"></style>