<template>
  <div id="dialog-container" class="dialogContainer" style="display: block">
    <div class="dialog modal" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div v-if="customComponent">
          <component :is="customComponent" v-bind="componentProps"/>
        </div>
        <div v-else id="modal-content" class="modal-content smartSISPanel">
          <div id="modal-header" class="modal-header">
            <h5 id="modal-title" class="modal-title">{{ title }}</h5>
          </div>
          <div id="modal-body" class="modal-body errorBody">
            <p v-html="message"></p>
          </div>
          <div id="modal-footer" class="modal-footer">
            <template v-for="(button, index) in buttons" :key="index">
              <button type="button"
                      :id="button.id"
                      :class="['btn', button.style || 'btn-primary'].join(' ')"
                      @click="button.action"
              >
                {{ button.label }}
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Dialog',

  computed: {
    title() {
      return this.$store.state.dialogStore.title;
    },
    message() {
      return this.$store.state.dialogStore.message;
    },
    buttons() {
      return this.$store.state.dialogStore.buttons;
    },
    customComponent() {
      return this.$store.state.dialogStore.component;
    },
    componentProps() {
      return this.$store.state.dialogStore.props;
    }
  },

  methods: {},
}
</script>
<style src="./dialog.css"></style>