import AppConfig from '../config/appConfig';
import LogLevels from '../constants/logLevels';
import Store from '../store';

export default class LoggerUtils {

  static debug(message) {
    LoggerUtils.log(LogLevels.debug, message);
  }

  static info(message) {
    LoggerUtils.log(LogLevels.info, message);
  }

  static warn(message) {
    LoggerUtils.log(LogLevels.warn, message);
  }

  static error(message) {
    LoggerUtils.log(LogLevels.error, message);
  }

  static fatal(message) {
    LoggerUtils.log(LogLevels.fatal, message);
  }

  static log(level, message) {
    const user = (Store.state.userStore.userProfile) ? Store.state.userStore.userProfile.username : '';
    if (level.order >= AppConfig.logLevel.order) {
      console.log('[' + level.name + ']', Date.now(), user, message);
    }
  }

}