import FeatureType from "../constants/featureType";

const SORTING_ORDER_CONFIG = {
  ASC: 'ASC',
  DESC: 'DESC'
};

const DEFAULT_SORTING_CONFIGS = [{
  featureType: FeatureType.OPERATION_PLAN,
  sortingBy: 'submitTime',
  sortingOrder: SORTING_ORDER_CONFIG.DESC
}];

function isDescOrder(order) {
  switch (order) {
    case SORTING_ORDER_CONFIG.ASC: return false
    case SORTING_ORDER_CONFIG.DESC: return true
  }
}

function getSortingOrder(value) {
  switch (value) {
    case false: return SORTING_ORDER_CONFIG.ASC
    case true: return SORTING_ORDER_CONFIG.DESC
  }
}

export default {
  SORTING_ORDER_CONFIG,
  DEFAULT_SORTING_CONFIGS,
  isDescOrder,
  getSortingOrder
}
