<template>
  <div>
    <div class="card smartSISPanel">
      <h5 class="card-header">{{ $t('labels.configurationPanel.groupAndRole.themes') }}</h5>
      <div class="card-body">
        <div class="list-group groupList" v-bind:style="{height: heightSize}">
          <div :key="theme.id" v-for="theme in themes">
            <div class="row m-0" style="flex-wrap: nowrap">
              <div class="p-0 m-0 w-100 hideOverflow">
                <button
                  :class="['list-group-item', 'smartSISPanel', 'selectThemeButton',
                  isDefaultTheme(theme.id)? 'disabledDefaultButton' : '',
                  isSelected(theme.id) ? 'selected' : ''].join(' ')"
                  :disabled="isDefaultTheme(theme.id)"
                  :id="'theme_' + theme.name"
                  @click="toggleTheme(theme.id)"
                  type="btn btn-primary"
                >
                  {{ theme.name }}
                </button>
              </div>
              <div class="text-right p-0" style="margin: 0 1px">
                <button
                  :class="['list-group-item', 'defaultButton', 'smartSISPanel',
                  isDisabled(theme.id)? 'disabledDefaultButton' : '',
                  isDefault(theme.id) ? 'selectedDefault' : '']"
                  :disabled="isDisabled(theme.id)"
                  :id="'defaultTheme_' + theme.name"
                  @click="toggleDefault(theme.id)"
                  type="btn btn-primary"
                >
                  <span>
                    <img class="pinImg" v-bind:src="getButtonImg('pin')">
                  </span>
                  <span class="defaultText">
                    {{ $t('labels.common.default') }}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import A from '../../../../constants/actions';
import ImgUtils from "../../../../utils/ImgUtils";

export default {
  name: 'Themes',
  components: {},
  computed: {
    themes() {
      return this.$store.state.themeStore.themes;
    },
    heightSize() {
      return this.$store.state.configurationStore.roleViewHeight + 'px';
    },
  },
  methods: {
    getButtonImg(name) {
      return ImgUtils.getButtonImg(name, 'svg');
    },
    isDisabled(themeId) {
      return !this.isSelected(themeId) || this.isDefaultTheme(themeId);
    },
    isSelected(themeId) {
      return this.$store.state.userStore.currentGroup.themes.some(tr => tr.themeId === themeId);
    },
    isDefaultTheme(themeId) {
      return themeId === 'Default';
    },
    toggleTheme(themeId) {
      this.$store.dispatch(A.USER_MANAGEMENT_GROUP_TOGGLE_THEME, themeId);
    },
    isDefault(themeId) {
      return this.$store.state.userStore.currentGroup.themes.some(tr => tr.themeId === themeId && tr.defaultTheme);
    },
    toggleDefault(themeId) {
      this.$store.dispatch(A.USER_MANAGEMENT_GROUP_TOGGLE_THEME_DEFAULT, themeId);
    }
  },
}
</script>
<style src="./groupEditor.css"></style>