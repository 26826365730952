import FeatureType from '../constants/featureType';
import FeatureUtils from '../utils/FeatureUtils';
import {i18n} from '../internationalization/index';

const COLORS = {
  GREEN: '#28a745',
  YELLOW: '#ffd700',
  RED: '#ff0900',
  GREY: '#B0B4B7',
  RED_TAKE_OFF: 'rgba(247, 11, 70, 0.8)',
  TRANSPARENT: 'rgba(0,191,255,0)',
};

const METERS_PER_SECOND_TO_KNOTS_RATIO = 1.94384449;
const METERS_TO_FEET_RATIO = 3.2808399;

export default class MessageUtils {

  static getColors() {
    return COLORS;
  }

  static SPACE() {
    return '_space_';
  }

  static getColorText(colorCode) {
    if (colorCode === COLORS.GREEN) return 'green';
    if (colorCode === COLORS.YELLOW) return 'yellow';
    if (colorCode === COLORS.RED) return 'red';
  }

  static getColor(feature, thresholdConfig) {

    if (thresholdConfig === undefined) {
      return;
    }
    if (feature.featureType !== thresholdConfig.id) {
      console.warn(i18n.global.t('logMessages.noThresholdConfig'));
      return;
    }
    let currentColor = COLORS.GREEN;
    thresholdConfig.props.some(property => {
      const values = MessageUtils.constructValues(feature, property.fieldName);
      if (MessageUtils.isBetween(values, property.thresholds.filter(t => t.order === 1 || t.order === 2), property.type, property.unit)) {
        currentColor = COLORS.YELLOW;
      }
      if (MessageUtils.isBetween(values, property.thresholds.filter(t => t.order === 2 || t.order === 3), property.type, property.unit)) {
        currentColor = COLORS.RED;
        return true;
      }

    });

    return currentColor;
  }

  static highlightThresholds(feature, thresholdConfig) {

    function buildListRegEx(property, color) {
      const ret = [];
      property.thresholds.filter(t => t.order === ((color === COLORS.YELLOW) ? 1 : 2)).map(t => t.value).forEach(v => v.split(',').forEach(sv => ret.push(sv.trim().replace('+', '\\+'))));
      return ret.join('|');
    }

    let tac = feature.tac;

    thresholdConfig.props.forEach(property => {
      let currentColor = COLORS.GREEN;
      const values = MessageUtils.constructValues(feature, property.fieldName);

      if (MessageUtils.isBetween(values, property.thresholds.filter(t => t.order === 1 || t.order === 2), property.type, property.unit)) {
        currentColor = COLORS.YELLOW;
      }

      if (MessageUtils.isBetween(values, property.thresholds.filter(t => t.order === 2 || t.order === 3), property.type, property.unit)) {
        currentColor = COLORS.RED;
      }

      if (currentColor !== COLORS.GREEN) {
        const regExp = new RegExp((property.type === 'list') ? buildListRegEx(property, currentColor) : property.highlightExp, 'g');
        const subStr = feature.tac.match(regExp);
        tac = tac.replace(regExp, '<span' + MessageUtils.SPACE() + 'class="metarHighlight' + MessageUtils.getColorText(currentColor) + '">' + (subStr ? subStr[0] : '') + '</span>');
      }
    });

    return tac;
  }

  static getCriticalDescription(feature, thresholdConfig) {

    function buildListRegEx(property, color) {
      const ret = [];
      property.thresholds.filter(t => t.order === ((color === COLORS.YELLOW) ? 1 : 2)).map(t => t.value).forEach(v => v.split(',').forEach(sv => ret.push(sv.trim().replace('+', '\\+'))));
      return ret.join('|');
    }

    const res = [];

    thresholdConfig.props.forEach(property => {
      let currentColor = COLORS.GREEN;
      const values = MessageUtils.constructValues(feature, property.fieldName);

      if (MessageUtils.isBetween(values, property.thresholds.filter(t => t.order === 1 || t.order === 2), property.type, property.unit)) {
        currentColor = COLORS.YELLOW;
      }

      if (MessageUtils.isBetween(values, property.thresholds.filter(t => t.order === 2 || t.order === 3), property.type, property.unit)) {
        currentColor = COLORS.RED;
      }

      if (currentColor !== COLORS.GREEN) {
        const regExp = new RegExp((property.type === 'list') ? buildListRegEx(property, currentColor) : property.highlightExp, 'g');
        const matches = feature.tac.match(regExp);
        const subStr = matches ? matches[0] : '';
        if (!res.includes(subStr)) {
          res.push(subStr);
        }
      }
    });

    return res.join(' ');
  }

  static constructValues(feature, fieldName) {
    if ((!fieldName) || (!feature)) {
      return [];
    }
    let values = [];
    const tree = fieldName.split('.');
    //let root = (feature.getProperties) ? feature.getProperties()[tree[0]] : feature[tree[0]];
    let root = feature[tree[0]];

    if (!root) {
      return values;
    }
    if (tree.length === 1) {
      values.push(root);
      return values;
    }

    (Array.isArray(root) ? root : [root]).forEach(r => {
      values = values.concat.apply(values, MessageUtils.constructValues(r, fieldName.substring(fieldName.indexOf('.') + 1)));
    });

    return values;
  }

  static isBetween(values, thresholds, type, unit) {
    let mappedValues = null;
    if (unit === 'KNOTS' && type === 'range') {
      mappedValues = values.map(v => Math.round(v * METERS_PER_SECOND_TO_KNOTS_RATIO)); //convert meters/second to knots
    }

    if (unit === 'FEET' && type === 'range') {
      mappedValues = values.map(v => Math.round(v * METERS_TO_FEET_RATIO)); //convert meters to feet
    }

    if (mappedValues === null) {
      mappedValues = values;
    }

    if (type === 'range') {
      const value1 = Number(thresholds[0].value);
      const value2 = Number(thresholds[1].value);
      let thresholdOrderAsc = (value1 < value2);
      let minValue;
      let maxValue;
      if (value1 >= value2) {
        minValue = value2;
        maxValue = value1;
      } else {
        minValue = value1;
        maxValue = value2;
      }

      return mappedValues.some(v => {
        return (thresholdOrderAsc) ? (v >= minValue && v < maxValue) : (v > minValue && v <= maxValue);
      });
    } else if (type === 'list') {
      const threshold = thresholds[0];
      const thresholdValues = threshold.value.split(',');
      let result = false;
      values.some(v => {
        v = v ? v.replace('HEAVY_', '+').replace('LIGHT_', '-') : v;
        if (thresholdValues.includes(v)) {
          result = true;
          return true;
        }
      });
      return result;
    }
  }

  static getGroupObjects(feature) {
    let result = [feature];
    let minifiedParent = FeatureUtils.Mapping.getMinifiedObject(feature);

    if (feature && feature.featureType === FeatureType.BRIEFING) {
      result = [...result, ...feature.aixmComponents.map(feature => {
        return {...feature, groupId: minifiedParent.id}
      })];
      result = [...result, ...feature.metFeatures.map(feature => {
        return {...feature, groupId: minifiedParent.id}
      })];
      result = [...result, ...feature.notams.map(feature => {
        return {...feature, groupId: minifiedParent.id}
      })];
    }

    if (feature && feature.featureType === FeatureType.FLIGHT_OBJECT) {
      result = [...result, ...feature.routeElements.map(feature => {
        return {...feature, groupId: minifiedParent.id}
      })];
    }

    return result;
  }

  static getMaxAltitudeForUasZone(volumes) {
    return Math.max.apply(Math, volumes.map(v => v.upperLimit));
  }

  static getMinAltitudeForUasZone(volumes) {
    return Math.min.apply(Math, volumes.map(v => v.lowerLimit));
  }

  static getMinAltitudeForLaraAirspace(laraAirspace) {
    return Math.min.apply(Math, laraAirspace.geometries.map(v => v.lowerLimit));
  }

  static getMaxAltitudeForLaraAirspace(laraAirspace) {
    return Math.max.apply(Math, laraAirspace.geometries.map(v => v.upperLimit));
  }
}