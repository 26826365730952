import UomConfig from "../config/uomConfig";
import bigDecimal from "js-big-decimal";

export default class UomUtils {

  static convertAltitudeObjectToCurrentUom(altitudeObject) {
    const altitudeValue = altitudeObject.altitude_value;
    const altitudeUom = altitudeObject.units_of_measure === 'M' ? UomConfig.UOM.METERS : UomConfig.UOM.FEET;
    return this.convertValueToCurrentUom(altitudeValue, altitudeUom);
  }

  static convertValueToCurrentUom(value, sourceUomId) {
    const currentUom = UomConfig.getCurrentUom();
    return this.convert(value, sourceUomId, currentUom);
  }

  static convertAltitudeFromCurrentUomToTargetUom(altitudeValue, targetUomId) {
    const currentUom = UomConfig.getCurrentUom();
    return this.convert(altitudeValue, currentUom, targetUomId);
  }

  static convert(value, sourceUomId, targetUomId) {
    const valueInMeters = bigDecimal.multiply(UomConfig.getConfigForUom(sourceUomId).toMeters, value);
    const valueInTargetUom = bigDecimal.divide(valueInMeters, UomConfig.getConfigForUom(targetUomId).toMeters, 7);
    return parseFloat(valueInTargetUom);
  }
}