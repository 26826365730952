import FeatureType from '../constants/featureType';
import {defineAsyncComponent} from "vue";

const CUSTOM_MESSAGE_CARD_COMPONENT_CONFIGS = [
  {
    featureType: FeatureType.OPERATION_PLAN,
    shouldBeUsed: message => message.featureType.startsWith(FeatureType.OPERATION_PLAN),
    component: defineAsyncComponent(() => import('../components/information-panel/custom-message-cards/OperationPlanMessageCard'))
  },
  {
    featureType: FeatureType.DRONES,
    shouldBeUsed: message => message.featureType.startsWith(FeatureType.DRONES),
    component: defineAsyncComponent(() => import('../components/information-panel/custom-message-cards/DroneMessageCard'))
  },
  {
    featureType: FeatureType.DRONE_ALERT,
    shouldBeUsed: message => message.featureType.startsWith(FeatureType.DRONE_ALERT),
    component: defineAsyncComponent(() => import('../components/information-panel/custom-message-cards/DroneAlertMessageCard'))
  },
  {
    featureType: FeatureType.ED269_BASE_FEATURE,
    shouldBeUsed: message => message.featureType.startsWith(FeatureType.ED269_BASE_FEATURE),
    component: defineAsyncComponent(() => import('../components/information-panel/custom-message-cards/GeozoneMessageCard'))
  },
  {
    featureType: FeatureType.DRONE_FLIGHT,
    shouldBeUsed: message => message.featureType === FeatureType.DRONE_FLIGHT,
    component: defineAsyncComponent(() => import('../components/information-panel/custom-message-cards/DroneFlightMessageCard'))
  },
  {
    featureType: FeatureType.LARA_AIRSPACE,
    shouldBeUsed: message => message.featureType === FeatureType.LARA_AIRSPACE,
    component: defineAsyncComponent(() => import('../components/information-panel/custom-message-cards/LaraAirspaceMessageCard.vue'))
  },
];

function getCustomComponent(message) {
  return CUSTOM_MESSAGE_CARD_COMPONENT_CONFIGS.find(config => config.shouldBeUsed(message))?.component;
}

function hasCustomComponent(featureType) {
  return CUSTOM_MESSAGE_CARD_COMPONENT_CONFIGS.some(config => featureType.startsWith(config.featureType));
}

export default {
  getCustomComponent,
  hasCustomComponent
};