import AjaxUtils from '../utils/AjaxUtils';
import AppConfig from '../config/appConfig';
import Features from '../config/features';
import FeatureType from '../constants/featureType';
import TimeService from './timeService';
import {i18n} from '../internationalization/index';

let serviceUrlRoot = AppConfig.server.serviceUrlRoot;

function getDroneInfo(config) {
  const dronesConfiguration = Features.getFeature(FeatureType.DRONES);

  return fetch(
    AjaxUtils.setParameters(serviceUrlRoot + dronesConfiguration.droneInfoServiceEndpoint,
      [
        AjaxUtils.getParamObjectFor('droneId', config.drone.registration),
      ]),
    {
      method: 'get',
      headers: AjaxUtils.getHeaders(),
    })
    .then(response => {
      switch (response.status) {
        case 404:
          console.log(
            i18n.global.t('errorMessages.droneInfoNotFoundError', {providerId: config.drone.registration})
          );
          config.errorCallback(
            i18n.global.t('errorMessages.droneInfoNotFoundError', {providerId: config.drone.registration})
          );
          break;
        case 500:
          console.log(
            i18n.global.t('errorMessages.droneInfoRetrieveError', {providerId: config.drone.registration})
          );
          config.errorCallback(
            i18n.global.t('errorMessages.droneInfoRetrieveError', {providerId: config.drone.registration})
          );
          break;
        case 200: {
          response.text().then(data => {
            config.successCallback(data);
          });
          break;
        }
      }
    })
    .catch(error => config.errorCallback(error));
}

function createDroneAlert(config) {
  const droneAlertConfig = Features.getFeature(FeatureType.DRONE_ALERT);
  const droneAlert = JSON.parse(JSON.stringify(config.droneAlert));
  droneAlert.time_sent = TimeService.currentUtcTime().toISOString();
  const createdDroneAlert = JSON.parse(JSON.stringify(droneAlert));
  delete droneAlert.featureType;


  return fetch(
    AjaxUtils.setParameters(AppConfig.server.serviceUrlRoot + droneAlertConfig.serviceEndpoint,
      [
        AjaxUtils.getUserNameParam(),
        AjaxUtils.getParamObjectFor('providerId', AppConfig.providerId)
      ]),
    {
      method: 'put',
      headers: AjaxUtils.getHeaders(),
      body: JSON.stringify(droneAlert),
    })
    .then(response => {
      if (response.status !== 200 && response.status !== 201) {
        if (response.status === 408) {
          config.errorCallback(i18n.global.t('errorMessages.droneInfoTimeoutError'));
        } else {
          response.text().then(data => {
            config.errorCallback(data);
          });
        }
      } else {
        response.text().then(data => {
          config.successCallback({data, createdDroneAlert});
        });
      }
    })
    .catch(error => config.errorCallback(error));
}

function sendAlertReply(config) {
  const alertReplyEndpoint = Features.getFeature(FeatureType.ALERT_REPLY).serviceEndpoint;
  fetch(
    AjaxUtils.setParameters(AppConfig.server.serviceUrlRoot + alertReplyEndpoint, []),
    {
      method: 'post',
      headers: AjaxUtils.getHeaders(),
      body: JSON.stringify(config.alertReply),
    })
    .then(response => {
      if (response.status !== 200 && response.status !== 201) {
        response.text().then(config.errorCallback);
      } else {
        response.text().then(config.successCallback);
      }
    })
    .catch(error => config.errorCallback(error));
}

function linkDroneToFlight(config) {
  const dronesConfiguration = Features.getFeature(FeatureType.DRONES);

  return fetch(
    AjaxUtils.setParameters(AppConfig.server.serviceUrlRoot + dronesConfiguration.linkDroneServiceEndpoint, []),
    {
      method: 'put',
      headers: AjaxUtils.getHeaders(),
      body: JSON.stringify(config.linkConfig),
    })
    .then(response => {
      if (response.status !== 200 && response.status !== 201) {
        if (response.status === 408) {
          config.errorCallback(i18n.global.t('errorMessages.droneInfoTimeoutError'));
        } else {
          response.text().then(data => {
            config.errorCallback(data);
          });
        }
      } else {
        response.text().then(data => {
          config.successCallback({data});
        });
      }
    })
    .catch(error => config.errorCallback(error));
}

export default {
  createDroneAlert,
  getDroneInfo,
  sendAlertReply,
  linkDroneToFlight
}