import A from '../../constants/actions';
import M from '../../constants/mutations';

const state = {
  isOpen: false,
  callback: null,
  selectedColor: null,
};

const actions = {

  [A.COLOR_PICKER_SHOW]({commit}, config) {
    commit(M.COLOR_PICKER_SHOW, config);
  },

  [A.COLOR_PICKER_CANCEL]({commit}) {
    commit(M.COLOR_PICKER_CANCEL);
  },

  [A.COLOR_PICKER_UPDATE_COLOR]({commit}, colors) {
    commit(M.COLOR_PICKER_UPDATE_COLOR, colors);
  },

  [A.COLOR_PICKER_UPDATE]({commit}, colors) {
    commit(M.COLOR_PICKER_UPDATE, colors);
  },

};

const mutations = {

  [M.COLOR_PICKER_SHOW](state, config) {
    state.isOpen = true;
    state.callback = config.callback;
    state.selectedColor = config.selectedColor;
  },

  [M.COLOR_PICKER_CANCEL](state) {
    state.isOpen = false;
    state.callback = null;
  },

  [M.COLOR_PICKER_UPDATE](state) {
    state.isOpen = false;
    if (state.callback) {
      state.callback(state.selectedColor);
    }
  },

  [M.COLOR_PICKER_UPDATE_COLOR] (state, colors) {
    state.selectedColor = colors;
  }

};

const getters = {
};

export default {
  state,
  actions,
  getters,
  mutations,
};