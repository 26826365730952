import FeatureType from '../../constants/featureType';
import FeatureUtils from '../../utils/FeatureUtils';
import ThemeElements from '../../constants/theme-elements';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import Point from 'ol/geom/Point';
import {i18n} from '../../internationalization/index';

export default {

  id: FeatureType.VAA,
  name: 'features.vaa',
  icon: 'vaa',
  iconExtension: 'png',
  staticDataType: [FeatureType.AIRSPACE],
  isViewPortBased: false,
  isFlightLevelBased: false,
  maxNrOfFeaturesPerLocation: 30,
  getReplaceableMessages: FeatureUtils.Filters.filterByVaa,
  featureIdentifier: 'id',
  maxResolution: 10000,
  declutter: true,
  zIndex: 1,
  flightLevel: null,
  theme: {
    elements: [
      ThemeElements.SHOW_TEXT,
      ThemeElements.BORDER_COLOR,
      ThemeElements.FILL_COLOR,
      ThemeElements.TEXT_COLOR,
      ThemeElements.TEXT_SIZE,
      ThemeElements.TEXT_BG_COLOR,
      ThemeElements.IMAGE_COLOR,
      ThemeElements.ICON_SIZE,
    ]
  },
  displayOnMap: true,
  displayInMessages: true,

  geoJsonMapper: (feature) => {
    let geometry = null;
    let isGeometryPresent = false;

    if (feature) {
      if (feature.ashClouds && feature.ashClouds[0].ashCloudExtent && feature.ashClouds[0].ashCloudExtent.volumeGeometry) {
        isGeometryPresent = true;
      }
    }

    if (isGeometryPresent) {
      geometry = new GeoJSON.readGeometry(feature.forecasts[0].ashClouds[0].ashCloudExtent.volumeGeometry);
    }
    if (geometry === null && feature.volcanoInfo && feature.volcanoInfo.volcanoLocation) {
      geometry = new Point([feature.volcanoInfo.volcanoLocation.lon, feature.volcanoInfo.volcanoLocation.lat]);
    }
    return new Feature({
      geometry: geometry,
      id: feature.id,
      featureType: 'vaa',
      station: feature.station,
      text: i18n.global.t('features.vaa'),
    });
  },

  color: (colors) => {
    return colors.imageColor;
  },

  defaultStyle:
    () => {
      return {
        showText: true,
        textSize: 14,
        iconSize: 10,
        colors: {
          borderColor: {r: 105, g: 60, b: 160, a: 1},
          fillColor: {r: 240, g: 228, b: 255, a: 0.1},
          textColor: {r: 255, g: 255, b: 255, a: 1},
          textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
          imageColor: {r: 105, g: 60, b: 160, a: 1}
        }
      }
    },

  getMinifiedObject(feature, minifiedObject) {
    return {
      ...minifiedObject,
      volcanoInfo: feature.volcanoInfo,
    }
  }

}