<template>
  <div class="searchForm w-100 form-group form-inline row">
    <input
      id="searchInput"
      type="text"
      class="col-7 form-control text-uppercase"
      v-model="query"
      :placeholder="$t('labels.common.search')"
      v-on:keyup.enter="applySearch"
      @focus="focused = true"
      @blur="onBlur"
    >
    <button id="search-button" type="button" v-bind:class="getClass()" @click="applySearch">
      {{ $t('labels.common.search') }}
    </button>
    <button v-if="isNotamTabActive()"
            id="clear-button"
            type="button"
            class="col-2 ml-0 btn btn-primary"
            @click="clearAllNotams">
      {{ $t('labels.common.clear') }}
    </button>
    <div id="searchHistory" class="smartSISPanel" v-if="focused">
      <ul>
        <li v-for="(searchHistory, index) in getSearchHistory()" @click="setSearchString(searchHistory)" :key="index">
          <a class="btn btn-primary w-100">
            <span v-for="(format, formatIndex) in formatSearchHistory(searchHistory)" :key="formatIndex">
              <span>{{ searchHistory.substr(0, format.start) }}</span>
              <span class="highlight">{{ searchHistory.substr(format.start, format.end - format.start) }}</span>
              <span>{{ searchHistory.substr(format.end) }}</span>
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>

import A from '../../constants/actions';
import Features from '../../config/features';
import PopupUtils from '../../utils/PopupUtils';
import FeatureType from '../../constants/featureType';
import SearchHistoryService from '../../services/searchHistoryService';
import SmartSisQueryService from '../../services/smartSisQueryService';
import DialogUtils from '../../utils/DialogUtils';
import IcaoLocation from '../../model/icaoLocation';

export default {
  name: 'MetDataSearch',
  props: ['activeTab'],
  data: () => {
    return {
      focused: false,
      query: '',
    }
  },
  methods: {
    onBlur() {
      const self = this;
      setTimeout(function () {
        self.focused = false
      }, 100);
    },
    getClass() {
      if (this.isNotamTabActive()) {
        return 'col-3 btn btn-primary';
      }
      return 'col-5 btn btn-primary';
    },
    isNotamTabActive() {
      return this.activeTab && this.activeTab.id === Features.getFeature(FeatureType.EVENT).id;
    },
    getSearchHistory() {
      const feature = Features.getFeature(this.$store.state.informationStore.activeTab.featureType);
      const history = SearchHistoryService.getSearchHistory()[feature.staticDataType] || [];
      return history.filter(entry => (this.query.length === 0) || (entry.indexOf(this.query.toUpperCase()) > -1));
    },
    formatSearchHistory(entry) {
      const start = entry.toUpperCase().indexOf(this.query.toUpperCase());
      const end = start + this.query.length;
      return [{start, end}];
    },
    updateSearchHistory() {
      const feature = Features.getFeature(this.$store.state.informationStore.activeTab.featureType);
      SearchHistoryService.updateSearchHistory(this.query.trim().toUpperCase(), feature.staticDataType);
    },
    setSearchString(queryString) {
      this.query = queryString;
      this.focused = false;

      this.applySearch();
    },
    applySearch() {
      const searchInput = document.getElementById('searchInput');
      if (searchInput) searchInput.blur();

      const activeTab = this.$store.state.informationStore.activeTab;
      const feature = Features.getFeature(activeTab.featureType);

      let queryString = this.query.toUpperCase().trim();
      let splittedQuery = queryString.split(' ');
      let filterQuery = splittedQuery[1] ? splittedQuery[1] : '';

      const filters = (feature.getSearchFilters) ? feature.getSearchFilters(filterQuery) : [];
      const locations = (feature.getSearchLocations) ? feature.getSearchLocations(this.query.trim(), this.getLocations) : this.getLocations();

      if (locations.length > 0) {
        const viewPort = this.$store.state.viewStore.currentView.viewPort;
        if ((feature.executeQuery) ? feature.executeQuery(this.query) : true) {
          this.queryData(feature, locations, viewPort, filters);
        }
      }
      this.query = '';
    },
    getLocations() {
      if (this.query.trim() !== '') {
        this.updateSearchHistory();
        let locations = this.query.toUpperCase().split(',');
        locations = locations.map((location) => {
          location = location.trim();
          if (location.includes(' ')) {
            location = location.split(' ')[0];
          }
          if (location.length < 4) {
            if (!location.endsWith('*')) {
              location = location.concat('*');
            }
          }
          return location;
        });
        locations.forEach(f => {
          if (f.length < 3) {
            DialogUtils.errorNotification(this.$i18n.t('errorMessages.locationError'));
          }
        });
        locations = locations.filter(f => f.length > 2);
        let icaoLocations = locations.map((location) => {
          return new IcaoLocation(location, FeatureType.AIRPORT_HELIPORT);
        });
        icaoLocations = icaoLocations.concat(locations.map((location) => {
          return new IcaoLocation(location, FeatureType.AIRSPACE);
        }));
        this.getSearchHistory();
        return icaoLocations;
      }
      return [];
    },
    queryData(feature, locations, viewPort, filters) {
      SmartSisQueryService.queryMetData([feature.id], locations,
        viewPort, filters, (features) => {
          if (features.length > 0) {
            SmartSisQueryService.queryStaticData(feature.staticDataType, locations);
            this.$store.dispatch(A.INFORMATION_PANEL_SCROLL_TO_MESSAGE, features[0].id);
          } else {
            PopupUtils.warning(this.$i18n.t('errorMessages.noMessageError'));
          }
        });
    },
    clearAllNotams() {
      DialogUtils.confirmOperation(
        {
          message: this.$i18n.t('confirmationMessages.removeAllNotams'),
          yesAction: () => {
            this.$store.dispatch(A.MAP_WIPE_NONSTATIC_DATA, Features.getAvailableFeatures().filter(f => f.id === FeatureType.EVENT).map(f => f.id));
            const toRemove = this.$store.state.informationStore.messages.filter((msg) => {
              return msg.featureType === FeatureType.EVENT;
            });
            this.$store.dispatch(A.INFORMATION_PANEL_MESSAGE_CLEANUP, toRemove);
          },
        }
      );
    }
  }
};
</script>
