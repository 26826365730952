<template>
  <div id="global-configurations-container">
    <div class="row m-0">
      <ListPanel
        :entries="configurations"
        :entryStyle="getButtonStyle"
        :idPrefix="'ra'"
        :onSelectEntry="selectConfiguration"
        class="pt-2 px-0"
      >
      </ListPanel>
      <div class="col" :style="{height: heightSize, overflow: 'auto'}">
        <component
          v-bind:is="customConfigEditor"
          :config="selectedConfiguration"
          :isGlobalConfigEnabled="isAllowedToEdit"/>
      </div>
    </div>
  </div>
</template>

<script>

import ListPanel from "../list-panel/ListPanel";
import GlobalConfigs from "../../../config/GlobalConfigs";
import {mapGetters} from "vuex";
import DialogUtils from "../../../utils/DialogUtils";
import A from "../../../constants/actions";
import PermissionUtils from "../../../utils/PermissionUtils";

export default {
  name: 'GlobalConfigurations',
  components: {
    ListPanel
  },
  created() {
    this.selectConfiguration(GlobalConfigs.getGlobalConfigurations()[0]);
  },
  data() {
    return {
      selectedConfiguration: null
    }
  },
  computed: {
    ...mapGetters({
      globalConfigurations: 'getGlobalConfigs',
      originalConfig: 'getOriginalGlobalConfigs',
    }),
    heightSize() {
      return (this.$store.state.configurationStore.geoserverLayerConfigPanelHeight) + 'px';
    },
    configurations() {
      return GlobalConfigs.getGlobalConfigurations();
    },
    customConfigEditor() {
      return GlobalConfigs.getCustomEditor(this.selectedConfiguration.name);
    },
    isAllowedToEdit() {
      return PermissionUtils.isAllowedToEditGlobalConfigurations();
    }
  },
  methods: {
    selectConfiguration(config) {
      if (JSON.stringify(this.originalConfig) !== JSON.stringify(this.globalConfigurations)) {
        DialogUtils.confirmOperation(
          {
            message: this.$i18n.t("confirmationMessages.globalConfigurationSelectConfig"),
            yesAction: () => {
              this.$store.dispatch(A.GLOBAL_CONFIGURATION_CANCEL_EDIT);
              this.selectedConfiguration = config;
            }
          }
        );
      } else {
        this.selectedConfiguration = config;
      }
    },
    getButtonStyle(config) {
      return [
        'btn', 'btn-primary',
        'w-100',
        'm-1',
        'listButton',
        (this.selectedConfiguration?.name === config.name) ? 'selected' : '',
      ].join(' ');
    }
  }

}
</script>