<template>
  <div id="map" class="map">
    <div id="overlay" class="overlay"></div>
    <div id="editMessage" v-if="showOverlayEditMessage" class="edit-overlay">
      <div class="edit-content">{{ editMessageText }}</div>
    </div>
  </div>
</template>

<script>
import 'ol/ol.css';
import A from '../constants/actions';
import AppConfig from '../config/appConfig';
import Features from '../config/features';
import MapUtils from '../utils/MapUtils';
import StyleFunctionService from '../styleFunction/styleFunctionService';
import {defaults as defaultInteractions, DragRotateAndZoom} from 'ol/interaction';
import Map from 'ol/Map';
import View from 'ol/View';
import VectorImage from 'ol/layer/VectorImage';
import ProjectionConverter from '../mappers/ProjectionConverter';
import ScaleLine from 'ol/control/ScaleLine';
import {Overlay} from "ol";
import HudTabName from '../constants/hudTabName';
import SubscriptionUtils from "../utils/SubscriptionUtils";
import FeatureType from '../constants/featureType';
import {Vector as VectorSource} from 'ol/source.js';
import uomConfig from "../config/uomConfig";

export default {
  name: 'Map',
  data: () => {
    return {
      map: null
    }
  },

  computed: {
    showOverlayEditMessage() {
      return this.$store.state.mapOperations.showOverlayEditMessage;
    },
    editMessageText() {
      return this.$store.state.mapOperations.editMessageText;
    },
    scaleLineUnit() {
      return uomConfig.getCurrentUom() === uomConfig.UOM.METERS ? 'metric' : 'us';
    },
    scaleLineControl() {
      return new ScaleLine({
        className: 'scale-control',
        units: 'metric'
      });
    },
  },

  watch: {
    scaleLineUnit: {
      immediate: true,
      handler() {
        if (this.map !== null) {
          this.map.removeControl(this.scaleLineControl);
          this.scaleLineControl.setUnits(this.scaleLineUnit);
          this.map.addControl(this.scaleLineControl);
        }
      }
    },
  },

  created() {
    document.addEventListener('click', this.closeSelectedFeaturesOverlayIfNecessary);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeSelectedFeaturesOverlayIfNecessary);
  },

  mounted: function () {
    document.getElementById("map").style.zoom = 1 / AppConfig.ui.applicationZoomLevel;
    const overlay = this.createOverlayForFeatureSelectionPopup();

    this.$store.dispatch(A.DISABLE_PARTIAL_SUBSCRIBE);
    const mapLayers = Features.getAvailableMapFeatures().map(f => this.constructVectorLayer(f));
    this.map = new Map({
      interactions: defaultInteractions({
        altShiftDragRotate: false,
        pinchRotate: false
      }).extend([
        new DragRotateAndZoom()
      ]),
      layers: [...mapLayers],
      overlays: [overlay],
      target: 'map',
      renderer: 'webgl',
      view: new View({
        center: [0, 0],
        zoom: 2,
      })
    });
    this.$store.dispatch(A.INIT_MAP_HANDLER, this.map);

    this.scaleLineControl.setUnits(this.scaleLineUnit);
    this.map.addControl(this.scaleLineControl);

    this.map.on('moveend', this.onMapViewportChanged);
    this.map.on('postrender', this.enable);

    this.map.on('singleclick', MapUtils.blurSearchInputBox);
    this.map.on('singleclick', MapUtils.updateHUDCoordinates);
    this.map.on('singleclick', (evt) => MapUtils.selectClickedFeature(evt, overlay));


    this.$store.dispatch(A.UPDATE_BASE_MAP, this.$store.state.viewStore.currentView.baseMap);
    this.$store.dispatch(A.ZOOM_TO_VIEWPORT, this.$store.state.viewStore.currentView.viewPort);
  },
  methods: {
    onMapViewportChanged(evt) {
      const searchInput = document.getElementById('searchInput');
      if (searchInput) searchInput.blur();
      let extent4326 = ProjectionConverter.convertTo4326(this.map.getView().calculateExtent(this.map.getSize()));
      let viewPortCorrected = this.correctBBoxIfNeeded(this.ViewPort(extent4326));
      evt.map.once('postrender', MapUtils.updateSnapshot);

      viewPortCorrected = {
        ...viewPortCorrected,
        zoom: this.map.getView().getZoom(),
        bottomRightLat: parseFloat(viewPortCorrected.bottomRightLat.toFixed(4)),
        bottomRightLon: parseFloat(viewPortCorrected.bottomRightLon.toFixed(4)),
        topLeftLat: parseFloat(viewPortCorrected.topLeftLat.toFixed(4)),
        topLeftLon: parseFloat(viewPortCorrected.topLeftLon.toFixed(4)),
      };

      this.$store.dispatch(A.MAP_VIEWPORT_CHANGED, viewPortCorrected);
      if (this.$store.getters.partialSubscribeIsEnabled &&
        !this.$store.getters.isGivenTabOpened(HudTabName.CONFIGURATION)) {
        const viewPortBasedFeatures = Features.getAvailableMapFeatures().filter(f => f.isViewPortBased
          && this.$store.state.viewStore.currentView.productTypes.includes(f.id)).map(f => f.id);
        const config = SubscriptionUtils.constructPartialSubscriptionConfig(viewPortBasedFeatures);
        this.$store.dispatch(A.MAP_REMOVE_FEATURES, viewPortBasedFeatures);
        this.$store.dispatch(A.WEBSOCKET_PARTIAL_SUBSCRIBE, config);
      }
      this.$store.dispatch(A.ENABLE_PARTIAL_SUBSCRIBE);
      this.updateClusteredLayers();
    },
    ViewPort(extent4326) {
      if (extent4326 === undefined) {
        return;
      }
      return {
        topLeftLat: extent4326[3],
        topLeftLon: extent4326[0],
        bottomRightLat: extent4326[1],
        bottomRightLon: extent4326[2]
      }
    },
    correctBBoxIfNeeded(bbox) {
      if (bbox.topLeftLon <= -180) {
        bbox.topLeftLon = -179.99;
      }
      if (bbox.bottomRightLon >= 180) {
        bbox.bottomRightLon = 179.99;
      }
      if (bbox.bottomRightLat <= -90) {
        bbox.bottomRightLat = -89.99;
      }
      if (bbox.topLeftLat >= 90) {
        bbox.topLeftLat = 89.99;
      }
      return bbox;
    },

    constructVectorLayer: function (feature) {
      return feature.id.startsWith(FeatureType.OPERATION_PLAN) ? MapUtils.constructClusteredLayer(feature) :
        this.constructVectorImage(feature);
    },
    constructVectorImage: function (feature) {
      const stylingConfig = this.$store.state.themeStore.currentTheme.stylingConfigs.find(s => s.layer === feature.id);
      const styleFunction = StyleFunctionService.createStyleFunction(stylingConfig);
      let vectorLayer = new VectorImage({
        source: new VectorSource(),
        style: styleFunction,
        declutter: feature.id.includes(FeatureType.OPERATION_PLAN)
      });
      vectorLayer.set('id', feature.id);
      vectorLayer.setZIndex(feature.zIndex);
      return vectorLayer;
    },
    createOverlayForFeatureSelectionPopup() {
      const container = document.getElementById('feature-selection-popup');
      const closeButton = document.getElementById('popup-close-button');

      const overlay = new Overlay({
        element: container,
        autoPan: {
          animation: {
            duration: 250,
          },
        },
      });

      closeButton.onclick = function () {
        overlay.setPosition(undefined);
        closeButton.blur();
        return false;
      };

      return overlay;
    },
    closeSelectedFeaturesOverlayIfNecessary(event) {
      const clickedOnToggleButton = event.composedPath().some(el => el.id === 'feature-selection-popup');
      if (!clickedOnToggleButton) {
        const closeButton = document.getElementById('popup-close-button');
        this.$store.getters.getMap.getOverlays().array_[0].setPosition(undefined);
        closeButton.blur();
      }
    },
    updateClusteredLayers() {
      this.$store.getters.getAllMapLayers
        .filter(layer => layer.get('id').startsWith(FeatureType.OPERATION_PLAN))
        .forEach(layer => layer.getSource().getSource().changed());
    }
  }
};
</script>
<style src="./map.css"></style>

