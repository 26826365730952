export default {
  backgrounds: [{
    id: '0',
    defaultName: 'Dark',
    url: 'https://{a-c}.basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}.png',
    dataFormat: 'XYZ',
  }, {
    id: '1',
    defaultName: 'Dark [Labels]',
    url: 'https://{a-c}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png',
    dataFormat: 'XYZ',
  }, {
    id: '2',
    defaultName: 'Bright',
    url: 'https://{a-c}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png',
    dataFormat: 'XYZ',
  }, {
    id: '3',
    defaultName: 'Bright [Labels]',
    url: 'https://{a-c}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
    dataFormat: 'XYZ',
  }, {
    id: '4',
    defaultName: 'ArcGIS Streets',
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}.png',
    dataFormat: 'XYZ',
  }],
};
