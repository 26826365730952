import AppConfig from "../config/appConfig";
import AjaxUtils from "../utils/AjaxUtils";
import {i18n} from '../internationalization/index';

export default class CswConnectionService {

  static cswServiceConnectionEndpoint = '/getLayersFromCSW';

  static connectToCswService(config) {
    const cswServiceConfig = config.cswServiceConfig;
    return fetch(
      AjaxUtils.setParameters(AppConfig.server.serviceUrlRoot + CswConnectionService.cswServiceConnectionEndpoint,
        [
          AjaxUtils.getUserNameParam(),
        ]),
      {
        method: 'post',
        headers: AjaxUtils.getHeaders(),
        body: JSON.stringify(cswServiceConfig),
      })
      .then(response => {
        switch (response.status) {
          case 200:
          case 201:
            response.text().then(data => {
              config.successCallback(data);
            });
            break;
          case 400:
          case 404:
            config.errorCallback(i18n.global.t('errorMessages.cswServiceConnectEndpointError'));
            break;
          case 500:
            config.errorCallback(i18n.global.t('errorMessages.cswServiceConnectInternalServerError'));
            break;
          default:
            config.errorCallback(i18n.global.t('errorMessages.cswServiceConnectDefaultError'));
        }
      })
      .catch(error => config.errorCallback(error));
  }
}