<template>
  <div class="row m-0">
    <ListPanel
      :entries="views"
      :entryStyle="getViewButtonStyle"
      :idPrefix="'view'"
      :onSelectEntry="selectView"
      class="pt-2 px-0"
    >
    </ListPanel>
    <ViewEditor v-if="isAllowedToEdit"></ViewEditor>
  </div>
</template>
<script>

import A from '../../../constants/actions';
import DialogUtils from '../../../utils/DialogUtils';
import ListPanel from '../list-panel/ListPanel.vue';
import PermissionUtils from '../../../utils/PermissionUtils';
import ViewEditor from './editor/ViewEditor.vue';
import Logger from '../../../utils/LoggerUtils';

export default {
  name: 'ViewConfiguration',
  components: {
    ListPanel,
    ViewEditor,
  },
  computed: {
    isAllowedToEdit() {
      return PermissionUtils.isAllowedToEditViews();
    },
    views() {
      return PermissionUtils.getAvailableViews();
    }
  },
  methods: {
    selectView(view) {
      const currentView = this.$store.state.viewStore.currentView;
      const originalView = this.$store.state.viewStore.originalView;
      if ((PermissionUtils.isSuperAdmin() || PermissionUtils.isSupervisor()) &&
        this.compareViews(currentView, originalView)) {
        DialogUtils.confirmOperation(
          {
            message: this.$i18n.t('confirmationMessages.viewConfigurationChangeView'),
            yesAction: () => this.$store.dispatch(A.VIEW_SELECT_VIEW, view.id),
          }
        );
      } else {
        Logger.info(this.$i18n.t('logMessages.newViewSelected', {id: view.id, name: view.name}));
        this.$store.dispatch(A.VIEW_SELECT_VIEW, view.id);
      }
    },
    compareViews(currentView, originalView) {
      const currentViewWithoutViewPort = JSON.parse(JSON.stringify(currentView));
      const originalViewWithoutViewPort = JSON.parse(JSON.stringify(originalView));

      delete currentViewWithoutViewPort.viewPort;
      delete originalViewWithoutViewPort.viewPort;

      return JSON.stringify(currentViewWithoutViewPort) !== JSON.stringify(originalViewWithoutViewPort);
    },
    getViewButtonStyle(view) {
      return [
        'btn', 'btn-primary',
        'w-100',
        'm-1',
        'listButton',
        (this.$store.state.viewStore.currentView.id === view.id) ? 'selected' : '',
      ].join(' ');
    }
  }

}
</script>