import FeatureType from '../../constants/featureType';
import FeatureUtils from '../../utils/FeatureUtils';
import ThemeElements from '../../constants/theme-elements';
import Feature from "ol/Feature";
import DroneFlightDetails from '../../constants/droneFlightDetails';
import ModelUtils from "../../utils/ModelUtils";

export default {

  id: FeatureType.DRONE_FLIGHT,
  name: 'features.droneFlight.tab',
  subtabName: 'features.droneFlight.subtabs.ongoingFlights',
  icon: 'drone',
  iconExtension: 'png',
  staticDataType: [],
  isViewPortBased: false,
  isFlightLevelBased: false,
  maxNrOfFeaturesPerLocation: 1,
  getReplaceableMessages: FeatureUtils.Filters.filterById,
  zIndex: 1,
  displayOnMap: false,
  displayInMessages: true,

  theme: {
    elements: [
      ThemeElements.SHOW_TEXT,
      ThemeElements.TEXT_COLOR,
      ThemeElements.TEXT_SIZE,
      ThemeElements.TEXT_BG_COLOR,
      ThemeElements.IMAGE_COLOR,
      ThemeElements.ICON_SIZE,
    ]
  },

  color: (colors) => {
    return colors.imageColor;
  },

  defaultStyle: () => {
    return {
      showText: true,
      textSize: 14,
      iconSize: 10,
      colors: {
        borderColor: {r: 143, g: 20, b: 58, a: 1},
        fillColor: {r: 143, g: 20, b: 58, a: 0.3},
        textColor: {r: 143, g: 20, b: 58, a: 1},
        textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
        imageColor: {r: 255, g: 255, b: 255, a: 1},
      }
    }
  },

  geoJsonMapper: (feature) => new Feature(feature),

  getMinifiedObject(feature) {
    const oneYear = 365 * 24 * 60 * 60 * 1000;
    return {
      ...feature,
      validTime: {
        to: Date.now() + oneYear
      }
    };
  },

  listViewDefaultSort(a, b) {
    return ModelUtils.sort(a.responsivenessState, b.responsivenessState);
  },

  filterMessages: (messages) => {
    const operationPlanIds = messages.filter(m => m.featureType.startsWith(FeatureType.OPERATION_PLAN)).map(m => m.id);
    return messages
      .filter(m => m.featureType === FeatureType.DRONE_FLIGHT)
      .filter(m => operationPlanIds.includes(m.operationPlanId))
      .filter(m => m.flightState !== DroneFlightDetails.flightStates.FINISHED.modelValue);
  }
}
