<template>
  <div
    id="delete-button"
    :class="[this.isDisabled ? 'disabled-action-button' : 'action-button']"
    v-if="isSuperAdmin && removable && getImg">
    <a
      @click="deleteMessage"
    >
      <div>
        <span class="button-icon"><img class="removeImg" v-bind:src="getImg('trash')"></span>
      </div>
      <span class="button-text">{{ getText }}</span>
    </a>
  </div>
  <button
    id="delete-message-button"
    :disabled="isDisabled"
    @click="deleteMessage"
    :class="['btn', 'btn-outline-danger', this.isDrone ? 'deleteDrone' : 'utmButton']"
    v-else-if="isSuperAdmin && removable && getText"
  >
    {{ getText }}
  </button>
</template>
<script>

import A from '../../../constants/actions';
import DialogUtils from '../../../utils/DialogUtils';
import Logger from '../../../utils/LoggerUtils';
import PermissionUtils from '../../../utils/PermissionUtils';
import QueryService from '../../../services/smartSisQueryService';
import FeatureType from '../../../constants/featureType';
import TimeService from '../../../services/timeService';
import HudTabName from "../../../constants/hudTabName";

export default {
  name: 'DeleteButton',
  props: ['message', 'getImg', 'removable', 'getText'],

  computed: {
    isSuperAdmin() {
      return PermissionUtils.isSuperAdmin();
    },
    timeSliderIsModified() {
      return TimeService.isInHistoryMode();
    },
    isDrone() {
      return this.message.featureType === FeatureType.DRONES;
    },
    isDisabled() {
      return PermissionUtils.isReadOnlyUser();
    }
  },

  methods: {
    deleteMessage() {
      PermissionUtils.performActionIfAllowed(this.isDisabled, this.deleteMessageFromDB);
    },
    deleteMessageFromDB() {
      DialogUtils.confirmOperation(
        {
          message: this.$i18n.t('confirmationMessages.deleteFromDatabase'),
          yesAction: () => {
            this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
            if (this.$store.getters.isGivenTabOpened(HudTabName.UTM_PANEL)) {
              this.$store.dispatch(A.UTM_PANEL_DESELECT_ELEM);
            }
            QueryService.smartSisRemoveMessage({
              messageId: this.message.id || this.message.timesliceId || this.message.featureId,
              featureType: this.message.featureType,
              onSuccess: () => {
                this.$store.dispatch(A.INFORMATION_PANEL_MESSAGE_CLEANUP, [this.message]);
                if (this.message.featureType.indexOf('operationplan') !== -1) {
                  const droneIdsToRemove = this.$store.state.informationStore.messages.filter((msg) => msg.featureType === FeatureType.DRONE_ALERT &&
                    this.message.id === msg.operationPlanIds[0]);
                  this.$store.dispatch(A.INFORMATION_PANEL_MESSAGE_CLEANUP, droneIdsToRemove);
                }
                this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
                Logger.info(this.$i18n.t('logMessages.featureDeleted', {
                  featureType: this.message.featureType,
                  messageId: this.message.id
                }));
              },
              onError: () => {
                DialogUtils.errorNotification(this.$i18n.t('errorMessages.errorOccurred'));
                this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
                Logger.error(this.$i18n.t('errorMessages.featureDeleteError', {
                  featureType: this.message.featureType,
                  messageId: this.message.id
                }));
              },
            });
          },
        }
      );
    },
  },
};
</script>
<style src="../../utm-list-view-panel/utm.css"></style>
