import ModelUtils from '../utils/ModelUtils';
import Moment from 'moment';
import OperationPlaneState from '../constants/operationPlanState';
import OperationPlanState from '../constants/operationPlanState';
import OperationPlanService from '../services/operationPlanService';
import FeatureUtils from "../utils/FeatureUtils";
import DronePlanUtils from "../utils/dronePlans/DronePlanUtils";
import OPStateColoringConfig from "../config/OPStateColoringConfig";
import ModeOfOperation from "../constants/modeOfOperation";
import {i18n} from '../internationalization/index';
import OpPriorityConfig from "../config/OpPriorityConfig";

function getStateAbbreviation(operation) {
  const stateToVerify = FeatureUtils.State.getOperationState(operation.state, operation.stateAttributeType);
  switch (stateToVerify) {
    case OperationPlaneState.PROPOSED.state:
      return operation.authorizationRequestedBy ? i18n.global.t(OperationPlaneState.PROPOSED.abbreviation) :
        i18n.global.t('labels.operationPlan.prop');
    case OperationPlaneState.AUTHORIZED.state:
      return i18n.global.t(OperationPlaneState.AUTHORIZED.abbreviation);
    case OperationPlaneState.ACTIVATED.state:
      return i18n.global.t(OperationPlaneState.ACTIVATED.abbreviation);
    case OperationPlaneState.TAKEOFFREQUESTED.state:
      return i18n.global.t(OperationPlaneState.TAKEOFFREQUESTED.abbreviation);
    case OperationPlaneState.CLOSED.state:
      return i18n.global.t(OperationPlaneState.CLOSED.abbreviation);
    case OperationPlaneState.NO_SERVICE.state:
      return i18n.global.t(OperationPlaneState.NO_SERVICE.abbreviation);
    case OperationPlaneState.INACTIVETWR.state:
      return i18n.global.t(OperationPlaneState.INACTIVETWR.abbreviation);
    default:
      return operation.state;
  }
}

function getCoordinatesFromGeometry(geometry) {
  const formattedCoords = geometry.coordinates?.map(c => parseFloat(c.toFixed(4)));
  return "<b>Lon:</b> " + formattedCoords[0] + " <b>Lat:</b> " + formattedCoords[1];
}

const fields = [
  {
    id: 'drone-plan-id',
    name: 'labels.common.id',
    propertyName: 'id',
    displayInList: false,
    displayInForm: true,
    sort: (a, b) => {
      return ModelUtils.sort(a.id, b.id);
    },
  },
  {
    id: 'drone-plan-state',
    name: 'labels.common.state',
    propertyName: 'state',
    displayInList: true,
    displayInForm: true,
    tableStyle: 'state',
    getFieldStyle(op) {
      const stateToVerify = FeatureUtils.State.getOperationState(op.state, op.stateAttributeType);
      return OPStateColoringConfig.createColorFunction(OperationPlanState[stateToVerify]);
    },
    sort: (a, b) => {
      return ModelUtils.sort(getStateAbbreviation(a), getStateAbbreviation(b));
    },
    getter(op) {
      const closureReason = op.closureReason ? " (" + op.closureReason + ")" : "";
      return FeatureUtils.State.getOperationStateToDisplay(op.state, op.stateAttributeType, op.authorizationRequestedBy)
        + closureReason;
    },
    getterForList(op) {
      return getStateAbbreviation(op);
    }
  },
  {
    id: 'drone-plan-last-status-changed',
    name: 'labels.operationPlan.lastStatusChange',
    propertyName: 'timeStamp',
    displayInList: true,
    displayInForm: true,
    isTimeDependent: true,
    tableStyle: 'lastStatusChange',
    sort: (a, b) => {
      return ModelUtils.sort(a.updateTime, b.updateTime);
    },
    getter(op) {
      const date = Moment.utc(op.updateTime);
      const now = Moment.utc();
      const differenceInMinutes = now.diff(date, 'minutes');
      const day = Math.floor((differenceInMinutes / 60) / 24);
      const hour = Math.floor((differenceInMinutes - day * 60 * 24) / 60);
      const minute = Math.floor(differenceInMinutes - day * 60 * 24 - hour * 60);
      const time = (day ? day + "d " : "") + (hour ? hour + "h " : "") + (minute ? minute + "m " : " ");
      const passedTime = differenceInMinutes !== 0
        ? '<br>' + '(' + time + i18n.global.t('labels.operationPlan.ago') + ')' : "";
      return date.format('DD MMM HH:mm') + passedTime;
    },
  },
  {
    id: 'drone-plan-submit-time',
    name: 'labels.operationPlan.submitTime',
    propertyName: 'submitTime',
    displayInList: true,
    displayInForm: true,
    tableStyle: 'time',
    sort: (a, b) => {
      return ModelUtils.sort(a.submitTime, b.submitTime);
    },
    getter(op) {
      return Moment.utc(op.submitTime).format('DD MMM HH:mm');
    }
  },
  {
    id: 'drone-plan-takeoff',
    name: 'labels.operationPlan.startDateTime',
    propertyName: 'takeOff',
    displayInList: true,
    displayInForm: true,
    tableStyle: 'time',
    sort: (a, b) => {
      return ModelUtils.sort(a.volumes[0].startTime, b.volumes[0].startTime);
    },
    getter(op) {
      return Moment.utc(op.volumes[0].startTime).format('DD MMM HH:mm');
    }
  },
  {
    id: 'drone-plan-landing',
    name: 'labels.operationPlan.endDateTime',
    propertyName: 'landing',
    displayInList: true,
    displayInForm: true,
    tableStyle: 'time',
    sort: (a, b) => {
      return ModelUtils.sort(
        OperationPlanService.getLatestEffectiveTimeEnd(a),
        OperationPlanService.getLatestEffectiveTimeEnd(b)
      );
    },
    getter(op) {
      const latestEffectiveEndTime = OperationPlanService.getLatestEffectiveTimeEnd(op);
      return Moment.utc(latestEffectiveEndTime).format('DD MMM HH:mm');
    }
  },
  {
    id: 'drone-plan-operator-name-and-phone',
    name: 'labels.operationPlan.contactNameAndPhone',
    propertyName: 'nameAndPhone',
    displayInList: true,
    displayInForm: false,
    tableStyle: 'contactNameAndPhone',
    sort: (a, b) => {
      return ModelUtils.sort(DronePlanUtils.constructOperatorName(a), DronePlanUtils.constructOperatorName(b));
    },
    getter(op) {
      return DronePlanUtils.constructOperatorName(op) + '<br>' + op.contactDetails?.phones[0];
    }
  },
  {
    id: 'drone-plan-operator-id',
    name: 'labels.operationPlan.operatorId',
    propertyName: 'operatorId',
    tableStyle: 'operatorId',
    displayInList: true,
    displayInForm: true,
    sort: (a, b) => {
      return ModelUtils.sort(a.operator, b.operator);
    },
    getter(op) {
      return op.operator;
    }
  },
  {
    id: 'drone-plan-title',
    name: 'labels.operationPlan.publicTitle',
    propertyName: 'title',
    displayInList: false,
    displayInForm: true,
    sort: (a, b) => {
      return ModelUtils.sort(a.publicTitle, b.publicTitle);
    },
    getter(op) {
      return op.publicTitle;
    }
  },
  {
    id: 'drone-plan-priority',
    name: 'labels.operationPlan.priority',
    propertyName: 'priority',
    displayInList: false,
    displayInForm: true,
    sort: (a, b) => {
      return ModelUtils.sort(a.priority, b.priority);
    },
    getter(op) {
      return OpPriorityConfig.getDisplayValueForPriority(op.priority);
    }
  },
  {
    id: 'drone-plan-operation-type',
    name: 'labels.operationPlan.dronePlanMode',
    propertyName: 'modeOfOperation',
    displayInList: false,
    displayInForm: true,
    sort: (a, b) => {
      return ModelUtils.sort(a.modeOfOperation, b.modeOfOperation);
    },
    getter(op) {
      return op.modeOfOperation !== undefined ? ModeOfOperation.getCompleteDisplayModeOfOperation(op.modeOfOperation) : '';
    }
  },
  {
    id: 'drone-plan-takeoff-location',
    name: 'labels.operationPlan.takeoffLocation',
    propertyName: 'takeoffLocation',
    displayInList: false,
    displayInForm: true,
    sort: (a, b) => {
      return ModelUtils.sort(a.takeoffLocation, b.takeoffLocation);
    },
    getter(op) {
      return op.takeoffLocation !== undefined ? getCoordinatesFromGeometry(op.takeoffLocation) : '';
    }
  },
  {
    id: 'drone-plan-controller-location',
    name: 'labels.operationPlan.controllerLocation',
    propertyName: 'controllerLocation',
    displayInList: false,
    displayInForm: true,
    sort: (a, b) => {
      return ModelUtils.sort(a.controllerLocation, b.controllerLocation);
    },
    getter(op) {
      return op.controllerLocation !== undefined ? getCoordinatesFromGeometry(op.controllerLocation) : '';
    }
  },
  {
    id: 'drone-plan-max-altitude',
    name: 'labels.common.maxAltitude',
    propertyName: 'maxAltitude',
    displayInList: true,
    displayInForm: true,
    tableStyle: 'altitude',
    sort: (a, b) => {
      return ModelUtils.sort(DronePlanUtils.getMaxAltitude(a), DronePlanUtils.getMaxAltitude(b));
    },
    getter(op) {
      if (op.volumes.length > 0) {
        const maxAltitude = ModelUtils.roundToTwoDecimals(DronePlanUtils.getMaxAltitude(op));
        return `${maxAltitude} ${ModelUtils.constructDisplayedUomAndCrs()}`;
      }
      return `0.0 ${ModelUtils.constructDisplayedUomAndCrs()}`;
    }
  },
  {
    id: 'max-takeoff-mass',
    name: 'labels.operationPlan.maxTakeOffMass',
    propertyName: 'maxTakeoffMassGrams',
    displayInList: false,
    displayInForm: true,
    sort: (a, b) => {
      return ModelUtils.sort(a.maxTakeoffMassGrams, b.maxTakeoffMassGrams);
    },
    getter(op) {
      return op.maxTakeoffMassGrams;
    }
  },
  {
    id: 'drone-plan-contact-details',
    name: 'labels.operationPlan.contactDetails',
    propertyName: 'contactDetails',
    displayInList: false,
    displayInForm: true,
    sort: (a, b) => {
      const aObject = a.contactDetails?.firstName + " " + a.contactDetails?.lastName;
      const bObject = b.contactDetails?.firstName + " " + b.contactDetails?.lastName;
      return ModelUtils.sort(aObject, bObject);
    },
    getter(op) {
      return op.contactDetails?.firstName + " " + op.contactDetails?.lastName + "<br>"
        + op.contactDetails?.phones?.join(', ') + "<br>"
        + op.contactDetails?.emails?.join(', ');
    }
  }

];

export default {
  name: 'OperationPlan',
  fields
}