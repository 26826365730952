export default {

  RECEIVED: 'RECEIVED',

  ACKNOWLEDGED: 'ACKNOWLEDGED',

  NOT_ACKNOWLEDGED: 'NOT_ACKNOWLEDGED',

  PENDING_ACKNOWLEDGEMENT: 'PENDING_ACKNOWLEDGEMENT',

  ACTIVE: "ACTIVE",

  RESOLVED: "RESOLVED",

}