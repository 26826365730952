import {Circle, Fill, Icon, Stroke, Style, Text} from "ol/style";
import {getHeight, getWidth} from "ol/extent";
import OpGeometryUtils from "../utils/dronePlans/OpGeometryUtils";

export default class CommonStyleFunction {

  static getTextStyleConfig(stylingConfig,
                            text,
                            offsets,
                            stroke = new Stroke({color: this.toRgba(stylingConfig.colors.textBackgroundColor)})) {
    const font = 'bold ' + stylingConfig.textSize + 'px Arial';
    return new Text({
      text: text,
      font: font,
      offsetX: offsets.x,
      offsetY: offsets.y,
      fill: new Fill({color: this.toRgba(stylingConfig.colors.textColor)}),
      stroke: stroke,
      anchor: [10, 0],
      overflow: false
    });
  }

  static getImageStyleConfig(stylingConfig, pathToIcon, scale, rotation,
                             color = this.toRgba(stylingConfig.colors.imageColor)) {
    return new Icon({
      color: color,
      src: pathToIcon,
      scale: stylingConfig.iconSize / scale,
      rotation: rotation
    });
  }

  static toRgba(color) {
    if (color) {
      return 'rgba(' + color.r + ',' + color.g + ',' + color.b + ',' + color.a + ')';
    }
    return 'rgba(255, 255, 255, 0.5)';
  }

  static getStyleConfigWithCircle(radius, strokeConfig, zIndex, fill = undefined) {
    return new Style({
      image: new Circle({
        radius: radius,
        stroke: new Stroke({
          color: this.toRgba(strokeConfig.color),
          width: strokeConfig.width
        }),
        fill: fill
      }),
      zIndex: zIndex
    });
  }

  static getStyleConfigWithStrokeAndText(text, strokeConfig, zIndex, fill = undefined) {
    return new Style({
      text: text,
      stroke: new Stroke({
        color: this.toRgba(strokeConfig.color),
        width: strokeConfig.width
      }),
      fill: fill,
      zIndex: zIndex
    });
  }

  static getStyleConfigWithImageAndText(stylingConfig, text, image = undefined) {
    if (stylingConfig.showText) {
      return new Style({
        text: text,
        image: image
      });
    }
    return new Style({
      text: null,
      image: image
    });
  }

  static getEmbeddedMapHighlightedColor(stylingColor) {
    const isStrokeColorCloseToRed = stylingColor.r > 200 &&
      stylingColor.g < 128 &&
      stylingColor.b < 128;
    return isStrokeColorCloseToRed ?
      {r: 0, g: 255, b: 255, a: stylingColor.a} :
      {r: 255, g: 0, b: 0, a: stylingColor.a};
  }

  static shouldDisplayFeatureText(featureGeometry, resolution) {
    const MAX_FEATURE_SIZE_RESOLUTION_SCALE_FOR_TEXT = 150;
    return CommonStyleFunction.shouldDisplay(featureGeometry, resolution, MAX_FEATURE_SIZE_RESOLUTION_SCALE_FOR_TEXT);
  }

  static shouldDisplayFeatureGeometry(featureGeometry, resolution) {
    const MAX_OPERATION_PLAN_SIZE_RESOLUTION_SCALE_FOR_GEOMETRY = 30;
    return CommonStyleFunction.shouldDisplay(featureGeometry, resolution, MAX_OPERATION_PLAN_SIZE_RESOLUTION_SCALE_FOR_GEOMETRY);
  }

  static shouldDisplayTrajectoryElementStyle(featureGeometry, resolution) {
    const MAX_OPERATION_PLAN_SIZE_RESOLUTION_SCALE_FOR_GEOMETRY = 150;
    return CommonStyleFunction.shouldDisplay(featureGeometry, resolution, MAX_OPERATION_PLAN_SIZE_RESOLUTION_SCALE_FOR_GEOMETRY);
  }

  static shouldDisplay(featureGeometry, resolution, scale) {
    if (!featureGeometry) {
      return false;
    }
    const featureHeight = getHeight(featureGeometry.getExtent());
    const featureWidth = getWidth(featureGeometry.getExtent());
    const featureSize = Math.max(featureHeight, featureWidth);
    return resolution * scale <= featureSize;
  }

  static getLayerGeometryStyle(strokeConfig, fillColor, geometry) {
    return [
      CommonStyleFunction.getLayerGeometryFillStyling(fillColor, geometry),
      this.getLayerBorderStyling(strokeConfig, geometry)
    ];
  }

  static getLayerGeometryFillStyling(fillColor, geometry) {
    return new Style({
      fill: new Fill({color: CommonStyleFunction.toRgba(fillColor)}),
      zIndex: 30,
      geometry: geometry
    });
  }

  static getLayerTextStylingInTheVisualCenterOfGeometry(stylingConfig, layerTextStyleConfig, featureGeometry) {
    const visualCenter = featureGeometry ?
      OpGeometryUtils.getPolygonIntersectingViewportWithGeometry(featureGeometry) : undefined;
    return new Style({
      text: stylingConfig.showText && visualCenter ? layerTextStyleConfig : undefined,
      geometry: visualCenter,
      zIndex: 30
    });
  }

  static getLayerBorderStyling(strokeConfig, geometry) {
    return new Style({
      stroke: new Stroke({
        color: CommonStyleFunction.toRgba(strokeConfig.color),
        width: strokeConfig.width
      }),
      geometry: geometry
    })
  }
}