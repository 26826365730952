<template>
  <div v-if="threshold" class="col-10">
    <div class="thresholdsEditor" v-bind:style="{height: heightSize}">
            <span v-for="prop in props" :key="prop.name">
                <RangeThresholdItem
                  v-if="prop.type === 'range'"
                  :name="prop.name"
                  :unit="prop.unit"
                  :threshold="prop.thresholds"></RangeThresholdItem>
                <ListThresholdItem
                  v-else
                  :name="prop.name"
                  :unit="prop.unit"
                  :threshold="prop.thresholds"></ListThresholdItem>
            </span>
    </div>
    <SavePanel
      :isModified="isModified"
      :isEditable="isEditable"
      :isValid="isValid"
      :saveAction="save"
      :cancelAction="cancel"
    ></SavePanel>
  </div>
</template>
<script>

import A from '../../../../constants/actions';
import DialogUtils from '../../../../utils/DialogUtils';
import ListThresholdItem from './ListThresholdItem.vue';
import Logger from '../../../../utils/LoggerUtils';
import PopupUtils from '../../../../utils/PopupUtils';
import RangeThresholdItem from './RangeThresholdItem.vue';
import SavePanel from '../../save-panel/SavePanel.vue';
import ThresholdService from '../../../../services/smartSisThresholdService';

export default {
  name: 'ThresholdsEditor',

  components: {
    ListThresholdItem,
    RangeThresholdItem,
    SavePanel,
  },

  computed: {
    isEditable() {
      return true;
    },
    isModified() {
      const currentThreshold = this.$store.state.thresholdStore.currentThreshold;
      const originalThreshold = this.$store.state.thresholdStore.originalThreshold;
      return JSON.stringify(currentThreshold) !== JSON.stringify(originalThreshold);
    },
    isValid() {
      return true;
    },
    threshold() {
      return this.$store.state.thresholdStore.currentThreshold;
    },
    props() {
      return this.$store.state.thresholdStore.currentThreshold.props;
    },
    heightSize() {
      return this.$store.state.configurationStore.thresholdPanelHeight + 'px';
    }
  },

  methods: {
    save() {
      const threshold = this.$store.state.thresholdStore.currentThreshold;
      this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
      ThresholdService.saveThreshold({
        threshold,
        successCallback: () => {
          Logger.info(this.$i18n.t('logMessages.thresholdsSaved'));
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          this.$store.dispatch(A.THRESHOLD_SAVE_THRESHOLDS);
          PopupUtils.success(this.$i18n.t('popupMessages.thresholdSaved'));
        },
        errorCallback: (error) => {
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          Logger.error(this.$i18n.t('errorMessages.thresholdsSaveError') + ' ' + error);
          DialogUtils.errorNotification(this.$i18n.t('errorMessages.thresholdsSaveError'));
        }
      });
    },
    cancel() {
      this.$store.dispatch(A.THRESHOLD_CANCEL_EDIT);
    },
  }
}
</script>
<style src="./thresholdsEditor.css"></style>