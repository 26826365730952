<template>
  <div class="documentsPanelHeader">
    <button
      id="documents-upload"
      @click="handleEvent()"
      class="btn btn-danger"
      type="button"
    >
      {{ actionButtonLabel }}
    </button>
    <template v-if="isAllowedToUploadDocuments">
      <input
        @change="handleFileSelection()"
        accept="application/pdf"
        class="documentFileSelectionInput"
        id="documentFile"
        name="documentFile"
        ref="documentFile"
        type="file"
      />
      <label
        class="btn btn-info documentFileSelectionButton"
        for="documentFile">
        {{ $t('confirmationMessages.uploadDocument') }}
      </label>
    </template>
  </div>
</template>

<script>

import A from '../../constants/actions';
import PermissionUtils from "../../utils/PermissionUtils";
import DialogUtils from "../../utils/DialogUtils";
import LoggerUtils from "../../utils/LoggerUtils";
import DocumentService from "../../services/smartSisDocumentService";
import HudTabName from '../../constants/hudTabName';

export default {
  name: 'DocumentsPanelHeader',

  methods: {
    handleEvent() {
      if (this.selectedDocument) {
        this.$store.dispatch(A.DOCUMENTS_PANEL_DESELECT_DOC);
        return;
      }
      this.$store.dispatch(A.HUD_SET_OPENED_TAB, HudTabName.DOCUMENTS);
      this.$store.dispatch(A.DOCUMENTS_RESET_IS_UPDATED);
    },
    handleFileSelection() {
      const selectedDocumentFile = this.$refs.documentFile.files[0];
      DialogUtils.confirmOperation({
        message: this.$i18n.t('confirmationMessages.uploadDocument', {documentName: selectedDocumentFile.name}),
        yesAction: () => this.uploadSelectedFile(selectedDocumentFile),
        noAction: () => LoggerUtils.info(this.$i18n.t('logMessages.documentUploadDropped'))
      });
      this.$refs.documentFile.value = '';
    },
    uploadSelectedFile(documentFile) {
      if (this.selectedFileIsNotAPdfDocument(documentFile.name)) {
        setTimeout(
          () => DialogUtils.warningNotification(this.$i18n.t('errorMessages.uploadedDocumentNoPdfFile')),
          0
        );
        return;
      }
      DocumentService.uploadDocument(documentFile);
    },
    selectedFileIsNotAPdfDocument(fileName) {
      return fileName.split('.').pop() !== 'pdf';
    }
  },

  computed: {
    selectedDocument() {
      return this.$store.state.documentsStore.selectedDocument;
    },
    actionButtonLabel() {
      return this.selectedDocument ? this.$i18n.t('labels.common.back') : this.$i18n.t('labels.common.close');
    },
    isAllowedToUploadDocuments() {
      return PermissionUtils.isSuperAdmin();
    }
  },
};
</script>

<style src="./documentsPanel.css"></style>
