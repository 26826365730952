import A from '../../constants/actions';
import M from '../../constants/mutations';
import NotificationConfig from '../../config/notificationsConfig';
import FeatureType from '../../constants/featureType';
import IcaoLocation from '../../model/icaoLocation';
import AppConfig from '../../config/appConfig';
import Logger from "../../utils/LoggerUtils";
import DialogUtils from "../../utils/DialogUtils";
import IcaoLocationsService from "../../services/icaoLocationsService";
import {i18n} from '../../internationalization/index';

const defaultView = {
  id: 'Default',
  name: 'Default',
  editable: false,
  locations: [],
  productTypes: [],
  filterIds: [],
  filterType: '',
  timeOffsetInMillies: 0,
  notifications: NotificationConfig.getNotifications().map(
    notification => {
      return {
        type: notification.id,
        visible: notification.visible,
        products: [],
      };
    }
  ),
  altitudeFilteredProductTypes: [],
  viewPort: {
    zoom: 5,
    upperLeft: {
      lat: 69.39,
      lon: -53.19,
    },
    bottomRight: {
      lat: 22.37,
      lon: 115.56,
    },
  },
  minAltitudeInMeters: AppConfig.heightSlider.minimumHeightLimitInMeters,
  maxAltitudeInMeters: AppConfig.heightSlider.maximumHeightLimitInMeters,
  taskTabSoundNotificationEnabled: false
};

const sortFunction = (viewA, viewB) => {
  return (viewA.name === viewB.name) ? 0 : (viewA.name > viewB.name) ? 1 : -1;
};

const isGivenIcaoLocationAlreadyAddedToView = (icaoLocation, currentAirspaces) => {
  return currentAirspaces.some(airspace =>
    airspace.locationIcao === icaoLocation.locationIcao && airspace.locationType === icaoLocation.locationType
  )
}

const getInvalidAorDesignators = async (aorDesignatorsForCurrentView) => {
  const validAorDesignatorsForCurrentView = JSON.parse(
    await IcaoLocationsService.getIcaoLocationsForDesignators(aorDesignatorsForCurrentView)
  ).filter(icaoLocation => icaoLocation.locationType === 'responsibilityarea')
    .map(icaoLocation => icaoLocation.locationIcao);
  return aorDesignatorsForCurrentView.filter(e => !validAorDesignatorsForCurrentView.includes(e));
}

const state = {
  views: [defaultView],
  currentView: defaultView,
  currentViewId: defaultView.id,
  originalView: JSON.parse(JSON.stringify(defaultView)),
  skipViewPortChanged: false,
  notificationTypes: NotificationConfig.getNotifications().map((notification) => notification.id),
  selectedNotificationType: NotificationConfig.getNotifications()[0].id,
  isViewportResetInProgress: false,
  isAltitudeFilteredFeaturesDialogOpened: false,
};

const getters = {

  getTimeSliderOffset: state => {
    return state.currentView.timeOffsetInMillies;
  },

  getCurrentFirs: state => {
    return state.currentView.locations.filter(l => l.locationType === FeatureType.AIRSPACE).map(l => l.locationIcao);
  },

  getFeaturesConfiguredForCurrentView: state => {
    return state.currentView.productTypes;
  },

  getCurrentResponsibilityAreas: state => {
    return state.currentView.locations.filter(l => l.locationType === FeatureType.RESPONSIBILITY_AREA).map(l => l.locationIcao);
  },

  getCurrentAirspaces: state => {
    return state.currentView.locations
      .filter(l => l.locationType === FeatureType.RESPONSIBILITY_AREA || l.locationType === FeatureType.AIRSPACE);
  },

  getCurrentAirports: state => {
    return state.currentView.locations.filter(l => l.locationType === FeatureType.AIRPORT_HELIPORT).map(l => l.locationIcao);
  },

  isViewportResetting: state => {
    return state.isViewportResetInProgress;
  },

  getMaxFilteringAltitude: state => {
    return state.currentView.maxAltitudeInMeters;
  },

  getMinFilteringAltitude: state => {
    return state.currentView.minAltitudeInMeters;
  },

  getOriginalMaxFilteringAltitude: state => {
    return state.originalView.maxAltitudeInMeters;
  },

  getOriginalMinFilteringAltitude: state => {
    return state.originalView.minAltitudeInMeters;
  },

  getCurrentViewTaskTabSoundNotificationEnabled: state => {
    return state.currentView.taskTabSoundNotificationEnabled;
  },

  getIsAltitudeFilteredFeaturesDialogOpened: state => {
    return state.isAltitudeFilteredFeaturesDialogOpened;
  },

  getCurrentViewPort: state => {
    return state.currentView.viewPort;
  },

  getAltitudeFilteredFeatures: state => {
    return state.currentView.altitudeFilteredProductTypes;
  }
};

const actions = {

  [A.VIEW_EDITOR_TOGGLE_AIRPORT]({commit}, id) {
    commit(M.VIEW_EDITOR_TOGGLE_LOCATION, new IcaoLocation(id, FeatureType.AIRPORT_HELIPORT));
  },

  async [A.VIEW_EDITOR_TOGGLE_AIRSPACE]({commit, getters}, id) {
    const icaoLocationsFound = JSON.parse(await IcaoLocationsService.getIcaoLocationsForDesignators([id]));
    switch (icaoLocationsFound.length) {
      case 0: {
        Logger.error(i18n.global.t('errorMessages.noLocationFoundError'));
        DialogUtils.errorNotification(i18n.global.t('errorMessages.noLocationFoundError'));
        break;
      }
      case 1: {
        if (isGivenIcaoLocationAlreadyAddedToView(icaoLocationsFound[0], getters.getCurrentAirspaces)) {
          Logger.error(i18n.global.t('errorMessages.givenLocationExistsError'));
          DialogUtils.errorNotification(i18n.global.t('errorMessages.givenLocationExistsError'));
          break;
        }
        commit(M.VIEW_EDITOR_TOGGLE_LOCATION, icaoLocationsFound[0]);
        break;
      }
      default: {
        commit(A.VIEW_EDITOR_TOGGLE_ICAO_LOCATIONS_DIALOG_CONFIG, icaoLocationsFound);
      }
    }
  },

  [A.VIEW_EDITOR_TOGGLE_LOCATION]({commit}, airspace) {
    commit(M.VIEW_EDITOR_TOGGLE_LOCATION, airspace);
  },

  [A.VIEW_EDITOR_UPDATE_LOCATION_ORDER]({commit}, params) {
    commit(M.VIEW_EDITOR_UPDATE_LOCATION_ORDER, params);
  },

  [A.VIEW_EDITOR_TOGGLE_INFORMATION_TYPE]({commit}, id) {
    commit(M.VIEW_EDITOR_TOGGLE_SETTING, {name: 'productTypes', id: id});
  },

  [A.VIEW_EDITOR_TOGGLE_NOTAM_FILTER]({commit}, id) {
    commit(M.VIEW_EDITOR_TOGGLE_SETTING, {name: 'filterIds', id: id});
  },

  [A.VIEW_EDITOR_TOGGLE_ALTITUDE_FILTERED_FEATURE]({commit}, id) {
    commit(M.VIEW_EDITOR_TOGGLE_SETTING, {name: 'altitudeFilteredProductTypes', id: id});
  },

  [A.VIEW_EDITOR_SELECT_FILTER_TYPE]({commit}, filterType) {
    commit(M.VIEW_EDITOR_SELECT_POPERTY, {type: 'filterType', value: filterType});
  },

  [A.VIEW_EDITOR_ADJUST_MAX_ALTITUDE_SIZE]({commit}, fl) {
    commit(M.VIEW_EDITOR_SELECT_POPERTY, {type: 'maxAltitudeInMeters', value: fl});
  },

  [A.VIEW_EDITOR_ADJUST_MIN_ALTITUDE_SIZE]({commit}, fl) {
    commit(M.VIEW_EDITOR_SELECT_POPERTY, {type: 'minAltitudeInMeters', value: fl});
  },

  [A.VIEW_EDITOR_ADJUST_TIME]({commit}, time) {
    commit(M.VIEW_EDITOR_SELECT_POPERTY, {type: 'timeOffsetInMillies', value: time});
  },

  [A.VIEW_EDITOR_TOGGLE_NOTIFICATION_TYPE]({commit}, id) {
    commit(M.VIEW_EDITOR_TOGGLE_NOTIFICATION_TYPE, id);
  },

  async [A.VIEW_SELECT_VIEW]({dispatch, commit, getters}, viewId) {
    commit(M.VIEW_SELECT_VIEW, viewId);
    dispatch(A.VIEW_RESET_VIEWPORT);
    const currentAors = getters.getCurrentResponsibilityAreas;
    const invalidAors = currentAors.length === 0 ? [] : await getInvalidAorDesignators(currentAors);
    if (invalidAors.length !== 0) {
      DialogUtils.errorNotification(i18n.global.t('errorMessages.invalidAorsError', {respAreas: invalidAors.join(',')}));
    }
  },

  [A.VIEW_READ_ALL_VIEWS]({commit}, views) {
    commit(M.VIEW_READ_ALL_VIEWS, views);
  },

  [A.VIEW_SAVE_VIEW]({commit}) {
    commit(M.VIEW_SAVE_VIEW);
  },

  [A.VIEW_SAVE_VIEW_AS]({commit}, newView) {
    commit(M.VIEW_SAVE_VIEW_AS, newView);
  },

  [A.VIEW_CANCEL_EDIT]({dispatch, commit}) {
    commit(M.VIEW_CANCEL_EDIT);
    dispatch(A.VIEW_RESET_VIEWPORT);
    dispatch(A.MAP_RESET_ORIENTATION);
  },

  [A.VIEW_DELETE_VIEW]({commit}, viewId) {
    commit(M.VIEW_DELETE_VIEW, viewId);
    commit(M.USER_MANAGEMENT_REMOVE_VIEW_FROM_ATTACHED_ROLES, viewId);
  },

  [A.MAP_VIEWPORT_CHANGED]({commit}, viewPort) {
    commit(M.MAP_VIEWPORT_CHANGED, viewPort);
  },

  [A.VIEW_RESET_VIEWPORT]({dispatch, commit}) {
    commit(M.VIEW_RESET_VIEWPORT);
    dispatch(A.ZOOM_TO_VIEWPORT, state.currentView.viewPort);
  },

  [A.VIEW_SET_RESETTING_VIEWPORT_IN_PROGRESS]({commit}) {
    commit(M.VIEW_SET_RESETTING_VIEWPORT_IN_PROGRESS);
  },

  [A.VIEW_SET_RESETTING_VIEWPORT_DONE]({commit}) {
    commit(M.VIEW_SET_RESETTING_VIEWPORT_DONE);
  },

  [A.VIEW_RESET_TIME_SLIDER]({commit}) {
    commit(M.VIEW_RESET_TIME_SLIDER);
  },

  [A.VIEW_RESET_ALTITUDE_SLIDER]({commit}) {
    commit(M.VIEW_RESET_ALTITUDE_SLIDER);
  },

  [A.VIEW_UPDATE_NAME]({commit}, name) {
    commit(M.VIEW_UPDATE_NAME, name);
  },

  [A.VIEW_TOGGLE_TASK_SOUND_NOTIFICATION]({commit}) {
    commit(M.VIEW_TOGGLE_TASK_SOUND_NOTIFICATION);
  },

  [A.VIEW_EDITOR_SELECT_NOTIFICATION_TYPE]({commit}, notificationTypeId) {
    commit(M.VIEW_EDITOR_SELECT_NOTIFICATION_TYPE, notificationTypeId);
  },

  [A.VIEW_EDITOR_ALTITUDE_FILTERED_FEATURES_DIALOG_TOGGLE]({commit}) {
    commit(M.VIEW_EDITOR_ALTITUDE_FILTERED_FEATURES_DIALOG_TOGGLE);
  },

};

const mutations = {

  [M.VIEW_EDITOR_TOGGLE_SETTING](state, setting) {
    const index = state.currentView[setting.name].indexOf(setting.id);
    if (index > -1) {
      state.currentView[setting.name].splice(index, 1);
    } else {
      state.currentView[setting.name].push(setting.id);
    }
  },

  [M.VIEW_EDITOR_TOGGLE_LOCATION](state, icaoLocation) {
    const index = state.currentView.locations.findIndex(
      l => l.locationIcao === icaoLocation.locationIcao
        && l.locationType === icaoLocation.locationType
    );
    if (index > -1) {
      state.currentView.locations.splice(index, 1);
    } else {
      state.currentView.locations.push(icaoLocation);
    }
  },

  [M.VIEW_EDITOR_SELECT_POPERTY](state, setting) {
    state.currentView[setting.type] = setting.value;
  },

  [M.VIEW_EDITOR_UPDATE_LOCATION_ORDER](state, params) {
    const locations = state.currentView.locations;
    const moved = locations.find(location => location.locationIcao === params.moved);
    const after = locations.find(location => location.locationIcao === params.insertAfter);

    locations.splice(locations.indexOf(moved), 1);
    locations.splice(locations.indexOf(after) + 1, 0, moved);
  },

  [M.VIEW_READ_ALL_VIEWS](state, views) {
    views.sort(sortFunction);
    state.views = views;
    state.currentView = defaultView;

    if (state.views.length === 0) {
      state.views.push(defaultView);
    }
  },

  [M.VIEW_SELECT_VIEW](state, viewId) {
    state.skipViewPortChanged = true;
    const index = state.views.findIndex((view) => view.id === state.currentView.id);
    if (index !== -1) {
      state.views[index] = JSON.parse(JSON.stringify(state.originalView));
    }
    let viewToSet = null;
    viewToSet = state.views.find((view) => {
      return view.id === viewId;
    });
    viewToSet = viewToSet || state.views[0];
    state.currentView = JSON.parse(JSON.stringify(viewToSet || defaultView));
    state.currentViewId = state.currentView.id;
    state.originalView = JSON.parse(JSON.stringify(state.currentView));
  },

  [M.VIEW_SAVE_VIEW](state) {
    const index = state.views.findIndex((view) => view.id === state.currentView.id);
    state.views[index] = JSON.parse(JSON.stringify(state.currentView));

    state.currentView = state.views[index];
    state.originalView = JSON.parse(JSON.stringify(state.currentView));

    state.currentViewId = Date.now();
    state.views.sort(sortFunction);
  },

  [M.VIEW_SAVE_VIEW_AS](state, view) {
    const index = state.views.findIndex((view) => view.id === state.currentView.id);
    state.views[index] = JSON.parse(JSON.stringify(state.originalView));

    const defaultIndex = state.views.findIndex((view) => view.id === defaultView.id);
    if (defaultIndex > -1) {
      state.views.splice(defaultIndex, 1);
    }

    state.views.push(view);
    state.currentView = view;
    state.currentViewId = state.currentView.id;
    state.originalView = JSON.parse(JSON.stringify(view));

    state.views.sort(sortFunction);
  },

  [M.VIEW_CANCEL_EDIT](state) {
    const index = state.views.findIndex((view) => view.id === state.currentView.id);
    const originalView = JSON.parse(JSON.stringify(state.originalView));
    state.views.splice(index, 1, originalView);
    state.currentView = state.views[index];
  },

  [M.VIEW_DELETE_VIEW](state, viewId) {
    const indexOfView = state.views.findIndex(view => view.id === viewId);
    state.views.splice(indexOfView, 1);

    if (state.views.length === 0) {
      state.views.push(defaultView);
      state.currentView = defaultView;
      state.originalView = JSON.parse(JSON.stringify(defaultView));
    } else {
      state.currentView = state.views[0];
      state.originalView = JSON.parse(JSON.stringify(state.views[0]));
    }
    state.currentViewId = state.currentView.id;
  },

  [M.MAP_VIEWPORT_CHANGED](state, viewPort) {
    if (state.skipViewPortChanged) {
      state.skipViewPortChanged = false;
    } else {
      state.currentView.viewPort = {
        zoom: viewPort.zoom,
        upperLeft: {lat: viewPort.topLeftLat, lon: viewPort.topLeftLon},
        bottomRight: {lat: viewPort.bottomRightLat, lon: viewPort.bottomRightLon},
      };
    }
  },

  [M.VIEW_RESET_VIEWPORT](state) {
    state.currentView.viewPort = JSON.parse(JSON.stringify(state.originalView.viewPort));
  },

  [M.VIEW_SET_RESETTING_VIEWPORT_IN_PROGRESS](state) {
    state.isViewportResetInProgress = true;
  },

  [M.VIEW_SET_RESETTING_VIEWPORT_DONE](state) {
    state.isViewportResetInProgress = false;
  },

  [M.VIEW_RESET_ALTITUDE_SLIDER](state) {
    state.currentView.maxAltitudeInMeters = JSON.parse(JSON.stringify(state.originalView.maxAltitudeInMeters));
    state.currentView.minAltitudeInMeters = JSON.parse(JSON.stringify(state.originalView.minAltitudeInMeters));
  },

  [M.VIEW_RESET_TIME_SLIDER](state) {
    state.currentView.timeOffsetInMillies = JSON.parse(JSON.stringify(state.originalView.timeOffsetInMillies));
  },

  [M.VIEW_UPDATE_NAME](state, name) {
    state.currentView.name = name;
  },

  [M.VIEW_TOGGLE_TASK_SOUND_NOTIFICATION](state) {
    state.currentView.taskTabSoundNotificationEnabled = !state.currentView.taskTabSoundNotificationEnabled;
  },

  [M.VIEW_EDITOR_SELECT_NOTIFICATION_TYPE](state, notificationTypeId) {
    state.selectedNotificationType = notificationTypeId;
  },

  [M.VIEW_EDITOR_TOGGLE_NOTIFICATION_TYPE](state, productType) {
    const products = state.currentView.notifications.find((not) => not.type === state.selectedNotificationType).products;
    const index = products.indexOf(productType);
    if (index > -1) {
      products.splice(index, 1);
    } else {
      products.push(productType);
    }
  },

  [M.VIEW_EDITOR_ALTITUDE_FILTERED_FEATURES_DIALOG_TOGGLE](state) {
    state.isAltitudeFilteredFeaturesDialogOpened = !state.isAltitudeFilteredFeaturesDialogOpened;
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};