import {i18n} from '../internationalization/index';

const LAYER_VISIBILITY = {
  AIRSPACE_USER: "AIRSPACE_USER",
  AIRSPACE_MANAGER: "AIRSPACE_MANAGER"
}

const layerVisibilityRoleConfigs = {
  [LAYER_VISIBILITY.AIRSPACE_USER]: {
    displayValue: 'labels.configurationPanel.gisLayers.airspaceUser'
  },
  [LAYER_VISIBILITY.AIRSPACE_MANAGER]: {
    displayValue: 'labels.configurationPanel.gisLayers.airspaceManager'
  },
}

function getLayerVisibilityDisplayValue(layerVisibilityRole) {
  return i18n.global.t(layerVisibilityRoleConfigs[layerVisibilityRole].displayValue);
}

function getLayerVisibilityRoles() {
  return Object.keys(layerVisibilityRoleConfigs).map(roleId => {
    const displayValue = i18n.global.t(layerVisibilityRoleConfigs[roleId].displayValue);
    return {name: roleId, displayValue};
  })
}

export default {
  LAYER_VISIBILITY,
  layerVisibilityRoleConfigs,
  getLayerVisibilityDisplayValue,
  getLayerVisibilityRoles
}