import Point from "ol/geom/Point";
import {Circle as CircleStyle, Fill, Style, Text} from "ol/style";
import CommonStyleFunction from "../../CommonStyleFunction";
import OpGeometryUtils from "../../../utils/dronePlans/OpGeometryUtils";

export default class DronePlanTrajectoryStyleFunction {

  static buildTrajectoryStyle(featureProps) {
    return featureProps.selected &&
    CommonStyleFunction.shouldDisplayTrajectoryElementStyle(featureProps.geometry, featureProps.resolution) ?
      buildStyleForTrajectoryElements(featureProps) : [];
  }
}

function buildStyleForTrajectoryElements(featureProps) {
  const takeOffPoint = featureProps.trajectoryTakeoffLocation?.coordinates;
  const landingPoint = featureProps.trajectoryLandingLocation?.coordinates;
  return OpGeometryUtils.arePointsEquals(takeOffPoint, landingPoint) ?
    [createStyleForConfig({geometry: takeOffPoint, text: 'T/L'})] :
    [
      createStyleForConfig({geometry: takeOffPoint, text: 'T'}),
      createStyleForConfig({geometry: landingPoint, text: 'L'})
    ]
}

function createStyleForConfig(config) {
  const geometry = config.geometry ? new Point(config.geometry).transform('EPSG:4326', 'EPSG:3857') : null;
  return createOpCircleStyle(geometry, 14, config.text);
}

function createOpCircleStyle(geometry, radius, text) {
  return geometry ? new Style({
    image: new CircleStyle({
      radius: radius,
      fill: new Fill({
        color: 'rgba(66, 82, 110, 1)',
      }),
    }),
    geometry: geometry,
    zIndex: 200,
    text: text ? new Text({
      text: text,
      font: radius / 1.5 + 'px Arial',
      fill: new Fill({color: 'rgb(255,255,255)'}),
      anchor: [10, 0],
    }) : null
  }) : new Style({});
}