<template>
  <div>
    <div v-if="selectedElem">
      <MessageDetailedViewForm
        :messageId="selectedElem"
        :model="model"
      ></MessageDetailedViewForm>
    </div>
    <div v-else>
      <List
        :elements="getMessages"
        :listHeight="'100'"
        :model="model"
        :onSelect="this.onSelect"
        :onSort="this.onSort"
        :reverse="this.reverse"
        :sortCriteria="this.lastSortCriteria"
      ></List>
    </div>
  </div>
</template>

<script>

  import List from './common/List';
  import DroneModel from '../../../model/drone';
  import FeatureType from '../../../constants/featureType';
  import MessageDetailedViewForm from '../detailed-view-form-components/MessageDetailedViewForm';
  import A from '../../../constants/actions';
  import Features from "../../../config/features";

  export default {
    name: 'Drones',
    components: {
      MessageDetailedViewForm,
      List,
    },
    data() {
      return {
        lastSortCriteria: null,
        reverse: false,
      }
    },
    computed: {
      model() {
        return DroneModel;
      },
      getMessages() {
        let messages = this.$store.state.informationStore.messages.filter((msg) => {
          return msg.featureType === FeatureType.DRONES;
        });
        if (this.lastSortCriteria) {
          const index = this.model.fields.findIndex(field => field.propertyName === this.lastSortCriteria);
          let sortMethod = this.model.fields[index].sort;
          let sortedMessages =  messages.sort(sortMethod);
          return this.reverse ? sortedMessages.reverse() : sortedMessages;
        } else {
          const droneFeature = Features.getFeature(FeatureType.DRONES);
          return messages.sort(droneFeature.listViewDefaultSort);
        }
      },
      selectedElem() {
        return this.$store.state.utmStore.selectedId;
      }
    },
    methods: {
      onSelect(element) {
        this.$store.dispatch(A.UTM_PANEL_SELECT_ELEM, element.id);
      },
      onSort(propertyName) {
        const index = this.model.fields.findIndex(field => field.propertyName === propertyName);
        if (index > -1) {
          if (this.lastSortCriteria === propertyName && !this.reverse) {
            this.getMessages.reverse();
            this.reverse = true;
          } else {
            this.reverse = false;
            let sortMethod = this.model.fields[index].sort;
            this.getMessages.sort(sortMethod);
          }
          this.lastSortCriteria = propertyName;
          this.$store.state.informationStore.forceRenderCount++;
        }
      }
    }
  }
</script>
