import FeatureBase from './base';
import FeatureType from '../../constants/featureType';
import ThemeElements from '../../constants/theme-elements';

export default {

  id: FeatureType.LIGHTNING,
  name: 'features.lightning',
  featureIdentifier: 'id',
  isViewPortBased: true,
  isFlightLevelBased: true,
  isNotificationsDisabled: true,
  maxResolution: 1000,
  declutter: false,
  zIndex: 10,
  flightLevel: null,
  theme: {
    elements: [
      ThemeElements.SHOW_TEXT,
      ThemeElements.TEXT_COLOR,
      ThemeElements.TEXT_SIZE,
      ThemeElements.TEXT_BG_COLOR,
      ThemeElements.IMAGE_COLOR,
      ThemeElements.ICON_SIZE,
      ThemeElements.FILL_COLOR,
      ThemeElements.BORDER_COLOR,
    ]
  },
  maxNrOfFeaturesPerLocation: 5000,

  displayOnMap: true,
  displayInMessages: false,

  geoJsonMapper: (feature) => FeatureBase.metSafeGeoJsonFeatureMapGeoJsonFeature(feature),

  color: (colors) => {
    return colors.imageColor;
  },

  defaultStyle: () => {
    return {
      showText: true,
      textSize: 14,
      iconSize: 100,
      colors: {
        borderColor: {r: 255, g: 246, b: 0, a: 0.83},
        fillColor: {r: 239, g: 231, b: 8, a: 0.52},
        textColor: {r: 255, g: 255, b: 255, a: 1},
        textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
        imageColor: {r: 255, g: 237, b: 0, a: 0.78}
      }
    }
  },

}