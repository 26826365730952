<template>
  <div class="rolesPanelHeader">
    <button
      id="close-button"
      type="button"
      class="btn btn-danger"
      @click="toggleOpen()"
    >
      {{ $t('labels.common.close') }}
    </button>
  </div>
</template>

<script>

import A from '../../constants/actions';
import HudTabName from '../../constants/hudTabName';

export default {
  name: 'RolesPanelHeader',

  methods: {
    toggleOpen() {
      this.$store.dispatch(A.HUD_SET_OPENED_TAB, HudTabName.USER_ROLES);
    },
  },
};
</script>

<style src="./rolesPanel.css"></style>
