import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';

export default class FeatureBase {

  static genericGeoJsonFeatureMapGeoJsonFeature(feature) {
    const olFeature = new GeoJSON().readFeature(feature);
    olFeature.setId(feature.id);
    olFeature.setProperties({featureType: feature.id.substring(0, feature.id.indexOf('.'))});
    return olFeature;
  }

  static metSafeGeoJsonFeatureMapGeoJsonFeature(feature) {
    const olFeature = new GeoJSON().readFeature(feature);
    olFeature.setId(feature.id);
    olFeature.setProperties({featureType: feature.featureType});
    return olFeature;
  }

  static defaultMapGeoJsonFeature(feature) {
    const olFeature = new GeoJSON().readFeature(feature);
    olFeature.setId(feature.id || feature.timesliceId || feature.featureId || 'unknown');
    olFeature.setProperties({featureType:  'unknown'});
    return olFeature;
  }

  static mapSigmetAirmet(met, type) {

    function FcstData(coordinates, speed, dir, intensityChange, isForecast, altitude) {
      this.coordinates = coordinates;
      this.speed = speed;
      this.dir = dir;
      this.intensityChange = intensityChange;
      this.isForecast = isForecast;
      this.altitude = altitude;
    }

    function mapIntensityChange(intensityChange) {
      if (intensityChange === 'NO_CHANGE') {
        return 'NC';
      }
      else if (intensityChange === 'WEAKEN') {
        return 'WKN';
      }
      else if (intensityChange === 'INTENSIFY') {
        return 'INTSF';
      }
      return '';
    }

    let sigmets = [];
    const geoJson = new GeoJSON();
    met.forecasts.forEach(function (f, index) {
      if (index > 0) {
        return;
      }
      let coordinates = [];
      let geometry = null;
      let altitude = {
        from: undefined,
        to: undefined
      };
      let isForecast = index > 0;
      if (f.volumes.length > 0) {
        geometry = f.volumes[0].volumeGeometry;
        altitude = f.volumes[0].altitude;
      }

      let fcst = new FcstData(coordinates, f.speedOfMotion, f.directionOfMotion, mapIntensityChange(f.intensityChange),
        isForecast, altitude);
      sigmets.push(new Feature({
        geometry: geoJson.readGeometry(geometry),
        id: met.id,
        speed: fcst.speed,
        intensityChange: fcst.intensityChange,
        tac: met.tac,
        isForecast: fcst.isForecast,
        dir: fcst.dir,
        altitude: fcst.altitude,
        phenomenonType: (met.phenomenon) ? met.phenomenon.type : 'UNK',
        featureType: type
      }));
    });
    return sigmets.length > 0 ? sigmets[0] : null;
  }

}