import FeatureBase from './base';
import FeatureType from '../../constants/featureType';
import ThemeElements from '../../constants/theme-elements';

export default {

  id: FeatureType.TURBULENCE,
  name: 'features.cat',
  featureIdentifier: 'id',
  isViewPortBased: true,
  isFlightLevelBased: true,
  isNotificationsDisabled: true,
  maxResolution: 1000,
  declutter: true,
  zIndex: 1,
  flightLevel: null,
  theme: {
    elements: [
      ThemeElements.SHOW_TEXT,
      ThemeElements.TEXT_COLOR,
      ThemeElements.TEXT_SIZE,
      ThemeElements.TEXT_BG_COLOR,
      ThemeElements.IMAGE_COLOR,
      ThemeElements.ICON_SIZE,
      ThemeElements.FILL_COLOR,
      ThemeElements.BORDER_COLOR,
    ]
  },
  maxNrOfFeaturesPerLocation: 5000,

  displayOnMap: true,
  displayInMessages: false,

  geoJsonMapper: (feature) => FeatureBase.metSafeGeoJsonFeatureMapGeoJsonFeature(feature),

  color: (colors) => {
    return colors.imageColor;
  }

}