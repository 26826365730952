import * as Moment from "moment";
import Store from '../store';

export default {

  browserTime() {
    return Moment.utc();
  },

  asMoment(isoStringTimestamp) {
    return new Moment(isoStringTimestamp);
  },

  browserTimeInMilliseconds() {
    return this.browserTime().valueOf();
  },

  currentUtcTime() {
    const clientServerOffset = Store.getters.getClientServerOffset;
    return this.browserTime().add(clientServerOffset, 'milliseconds');
  },

  currentUtcTimeInMillis() {
    return this.currentUtcTime().toDate().getTime();
  },

  currentUtcTimeISOFormatted() {
    return this.currentUtcTime().toISOString();
  },

  getUtcStartTimeForCreationForms() {
    const currentTime = new Date(this.currentUtcTimeISOFormatted());
    currentTime.setSeconds(0);
    currentTime.setMilliseconds(0);
    const currentTimeAsMoment = this.asMoment(currentTime);
    currentTimeAsMoment.add(1, 'minutes');
    return currentTimeAsMoment.toISOString();
  },

  currentTimeSliderTime() {
    const timeSliderSelectedDate = Moment.utc(Store.getters.timeSliderSelectedDate);
    const timeSliderOffset = Store.getters.getTimeSliderOffset;
    if (Store.getters.timeSliderHasSelectedDate) {
      return timeSliderSelectedDate.add(timeSliderOffset, 'milliseconds');
    } else {
      const clientServerOffset = Store.getters.getClientServerOffset;
      return this.browserTime().add(clientServerOffset + timeSliderOffset, 'milliseconds');
    }
  },

  timeOffsetInMilliseconds() {
    const timeSliderOffset = Store.getters.getTimeSliderOffset;
    if (Store.getters.timeSliderHasSelectedDate) {
      const timeSliderSelectedDate = Moment.utc(Store.getters.timeSliderSelectedDate);
      return timeSliderSelectedDate.diff(this.currentUtcTime(), 'milliseconds') + timeSliderOffset;
    } else {
      return timeSliderOffset;
    }
  },

  isInHistoryMode() {
    return Store.getters.timeSliderHasSelectedDate || Store.getters.getTimeSliderOffset !== 0;
  },

  anHourFromCurrentTimeISOFormatted() {
    let startTime = this.asMoment(this.getUtcStartTimeForCreationForms());
    startTime.add(1, 'hours');
    return startTime.toISOString();
  },

  truncateTimestampToMinuteGranularity(timestampIsoString) {
    const timestamp = new Date(timestampIsoString);
    timestamp.setSeconds(0);
    timestamp.setMilliseconds(0);
    return timestamp.toISOString();
  },

  isBeforeDateSelectionTimeStamp(verifiedTimestamp) {
    return verifiedTimestamp.isSameOrBefore(Store.getters.dateSelectionTimestamp);
  },

  addHourToIsoDate(date, hours) {
    return this.asMoment(date).add(hours, 'hour').toISOString()
  },

  addMinutesToIsoDate(date, minutes) {
    return this.asMoment(date).add(minutes, 'minutes').toISOString()
  },

  addDaysToIsoDate(date, days) {
    return this.asMoment(date).add(days, 'days').toISOString()
  }
}