import FeatureType from '../../constants/featureType';
import ThemeElements from '../../constants/theme-elements';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';

export default {

  id: FeatureType.RUNWAY_DIRECTION,
  name: 'features.runwayDirection',
  isStaticDataType: true,
  displayOnMap: true,
  displayInMessages: false,
  featureIdentifier: 'id',
  isViewPortBased: false,
  isFlightLevelBased: false,
  maxResolution: 10000,
  declutter: false,
  zIndex: 1,
  flightLevel: null,
  theme: {
    elements: [
      ThemeElements.SHOW_TEXT,
      ThemeElements.BORDER_COLOR,
      ThemeElements.FILL_COLOR,
      ThemeElements.TEXT_COLOR,
      ThemeElements.TEXT_SIZE,
      ThemeElements.TEXT_BG_COLOR,
      ThemeElements.IMAGE_COLOR,
      ThemeElements.ICON_SIZE,
    ]
  },

  geoJsonMapper: (feature) => {
    return new Feature({
      geometry: new GeoJSON().readGeometry(feature.location),
      id: feature.featureId,
      designator: feature.designator,
      maxResolution: 10000,
      featureType: feature.featureType,
      angle: feature.trueBearing || parseFloat(feature.designator) * 10
    });
  },

  color: (colors) => {
    return colors.textColor;
  },

  defaultStyle: () => {
    return {
      showText: true,
      textSize: 14,
      iconSize: 8,
      colors: {
        borderColor: {r: 248, g: 231, b: 28, a: 1},
        fillColor: {r: 248, g: 231, b: 28, a: 0.1},
        textColor: {r: 255, g: 255, b: 255, a: 1},
        textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
        imageColor: {r: 248, g: 231, b: 28, a: 1}
      }
    }
  },

  getMinifiedObject(feature, minifiedObject) {
    return {
      ...minifiedObject,
      ...feature,
      id: feature.featureId,
      designator: feature.designator
    }
  }

}