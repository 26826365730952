<script>
import GeozoneConflictItem from "./GeozoneConflictItem.vue";
import DronePlanConflictItem from "./DronePlanConflictItem.vue";
import OtherConflictItem from "./OtherConflictItem";

export default {
  name: "ConflictItem",
  components: {DronePlanConflictItem, GeozoneConflictItem, OtherConflictItem},
  props: {
    conflict: Object,
    selectConflict: Function,
    disabled: Boolean,
    isChecked: Boolean,
    showCheckbox: Boolean,
    activationConflict: Boolean
  },
  computed: {
    isDronePlanConflict() {
      return this.conflict.objectType === 'OPERATION_PLAN' && this.conflict.conflictType !== 'CONDITION_VIOLATION';
    },
    isOtherConflict() {
      return this.conflict.objectType !== 'UAS_ZONE' && this.conflict.conflictType === 'CONDITION_VIOLATION';
    },
  }
}
</script>

<template>
  <div :id="(conflict.rejecting ? 'blocking-conflict-' : 'non-blocking-conflict-') + conflict.conflictType + '-' + conflict.objectId"
       :class="['conflict-custom-message-card', conflict.rejecting ? 'blocking-conflict' : 'non-blocking-conflict' ].join(' ')">
    <GeozoneConflictItem v-if="conflict.objectType === 'UAS_ZONE'" :conflict="conflict"/>
    <DronePlanConflictItem v-if="isDronePlanConflict" :conflict="conflict"/>
    <OtherConflictItem v-if="isOtherConflict" :conflict="conflict"/>
    <label v-if="showCheckbox" class="conflict-suppression-input m-0 mb-1" :class="{ disabled: disabled }">
      <input type="checkbox"
             :id="'conflict-checkbox-' + conflict.conflictType + '-' + conflict.objectId"
             :checked="isChecked"
             :disabled="disabled"
             @click="selectConflict($event, conflict)"
      />
      <span class="conflict-suppression-checkbox-control" :class="{ disabled: disabled }"/>
      <span :id="'conflict-checkbox-text-' + conflict.conflictType + '-' + conflict.objectId"
            class="conflict-suppression-checkbox-text">
          {{
          (activationConflict) ? $t('labels.operationPlan.suppressForActivation') :
            $t('labels.operationPlan.suppressForAuthorization')
        }}
      </span>
    </label>
  </div>
</template>