import Store from '../store';
import DronePlanUtils from "../utils/dronePlans/DronePlanUtils";
import FeatureUtils from "../utils/FeatureUtils";
import FeatureType from "../constants/featureType";

const TASK_PANEL_SUB_TABS = {
  APPROVAL_REQUESTS: 'approvalRequests',
  ACTIVATION_REQUESTS: 'activationRequests',
  EXPIRED_DRONE_PLANS: 'expiredDronePlans',
  MONITORED_DRONE_PLANS: 'monitoredDronePlans'
}

const TASK_PANEL_SUB_TABS_CONFIG = [
  {
    id: TASK_PANEL_SUB_TABS.APPROVAL_REQUESTS,
    displayName: 'labels.operationPlan.approvalRequests',
    isEligible: message => DronePlanUtils.isDronePlanWaitingApprovalRequest(message),
    getMessages: () => {
      return [
        ...getDronePlansByFeatureTypeWaitingForApproval(FeatureType.OPERATION_PLAN_PROPOSED),
        ...getDronePlansByFeatureTypeWaitingForApproval(FeatureType.OPERATION_PLAN_OUTLOOK)
      ];
    },
    isHighlighted: () => {
      return getNrOfMessagesForTaskPanelSubTab(TASK_PANEL_SUB_TABS.APPROVAL_REQUESTS) > 0;
    },
    shouldDisplay: () => FeatureUtils.Access.featureIsConfiguredForView(FeatureType.OPERATION_PLAN_PROPOSED)
      || FeatureUtils.Access.featureIsConfiguredForView(FeatureType.OPERATION_PLAN_OUTLOOK),
  },
  {
    id: TASK_PANEL_SUB_TABS.ACTIVATION_REQUESTS,
    displayName: 'labels.operationPlan.activationRequests',
    isEligible: message => DronePlanUtils.isDronePlanWaitingTakeoffClearanceRequest(message),
    getMessages: () => FeatureUtils.Access.featureIsConfiguredForView(FeatureType.OPERATION_PLAN_ACCEPTED) ?
      getDronePlansWithTakeoffClearanceRequest() : [],
    isHighlighted: () => {
      return getNrOfMessagesForTaskPanelSubTab(TASK_PANEL_SUB_TABS.ACTIVATION_REQUESTS) > 0;
    },
    shouldDisplay: () => FeatureUtils.Access.featureIsConfiguredForView(FeatureType.OPERATION_PLAN_ACCEPTED),
  },
  {
    id: TASK_PANEL_SUB_TABS.EXPIRED_DRONE_PLANS,
    displayName: 'labels.operationPlan.expiredDronePlans',
    isEligible: message => DronePlanUtils.isDronePlanExpired(message),
    getMessages: () => FeatureUtils.Access.featureIsConfiguredForView(FeatureType.OPERATION_PLAN_ACTIVATED) ?
      getExpiredDronePlans() : [],
    isHighlighted: () => false,
    shouldDisplay: () => FeatureUtils.Access.featureIsConfiguredForView(FeatureType.OPERATION_PLAN_ACTIVATED),
  },
  {
    id: TASK_PANEL_SUB_TABS.MONITORED_DRONE_PLANS,
    displayName: 'labels.operationPlan.monitoredDronePlans',
    isEligible: message => DronePlanUtils.isDronePlanMonitored(message),
    getMessages: () => getMonitoredDronePlans(),
    isHighlighted: () => false,
    shouldDisplay: () => true,
  }
];

function getTaskPanelSubTabs() {
  return TASK_PANEL_SUB_TABS_CONFIG.filter(config => config.shouldDisplay());
}

function getTaskPanelSubTabIdForMessage(message) {
  return TASK_PANEL_SUB_TABS_CONFIG.find(config => message && config.isEligible(message))?.id;
}

function getMessagesForTaskPanelSubTab(subTabId) {
  return getConfigForSubTabId(subTabId)?.getMessages();
}

function getNrOfMessagesForTaskPanelSubTab(subTabId) {
  return getMessagesForTaskPanelSubTab(subTabId).length;
}

function getTotalNrOfMessagesDisplayedInTaskPanel() {
  return TASK_PANEL_SUB_TABS_CONFIG
    .map(config => getNrOfMessagesForTaskPanelSubTab(config.id))
    .reduce((a, b) => a + b, 0);
}

function isTaskPanelSubTabHighlighted(subTabId) {
  return TASK_PANEL_SUB_TABS_CONFIG.find(config => config.id === subTabId)?.isHighlighted();
}

function isTaskPanelButtonHighlighted() {
  return TASK_PANEL_SUB_TABS_CONFIG.some(config => config.isHighlighted());
}

function getConfigForSubTabId(subTabId) {
  return TASK_PANEL_SUB_TABS_CONFIG.find(config => config.id === subTabId);
}

function getDronePlansByFeatureTypeWaitingForApproval(featureType) {
  if (!FeatureUtils.Access.featureIsConfiguredForView(featureType)) {
    return [];
  }
  const messages = Store.getters.getMessages;
  return messages.filter(
    msg => msg.featureType === featureType && DronePlanUtils.isDronePlanWaitingApprovalRequest(msg)
  );
}

function getDronePlansWithTakeoffClearanceRequest() {
  const messages = Store.getters.getMessages;
  return messages.filter(msg => DronePlanUtils.isDronePlanWaitingTakeoffClearanceRequest(msg));
}

function getExpiredDronePlans() {
  const messages = Store.getters.getMessages;
  return messages.filter(msg => DronePlanUtils.isDronePlanExpired(msg));
}

function getMonitoredDronePlans() {
  const messages = Store.getters.getMessages;
  return messages.filter(msg => DronePlanUtils.isDronePlanMonitored(msg));
}

export default {
  TASK_PANEL_SUB_TABS,
  getTaskPanelSubTabs,
  getTaskPanelSubTabIdForMessage,
  getMessagesForTaskPanelSubTab,
  getNrOfMessagesForTaskPanelSubTab,
  getTotalNrOfMessagesDisplayedInTaskPanel,
  isTaskPanelSubTabHighlighted,
  isTaskPanelButtonHighlighted
}