import FeatureType from '../../constants/featureType';
import FeatureUtils from '../../utils/FeatureUtils';
import ThemeElements from '../../constants/theme-elements';
import ModelUtils from '../../utils/ModelUtils';
import OperationPlanState from '../../constants/operationPlanState';
import OperationPlanServices from '../../services/operationPlanService';
import Feature from 'ol/Feature';
import Features from './index'
import OpGeometryUtils from "../../utils/dronePlans/OpGeometryUtils";
import DronePlanUtils from "../../utils/dronePlans/DronePlanUtils";
import MultiPolygon from "ol/geom/MultiPolygon";

export default {

  id: FeatureType.OPERATION_PLAN,
  name: 'features.operationPlan.name',
  icon: 'pending_plan',
  abbreviation: 'features.operationPlan.abbreviation',
  staticDataType: [FeatureType.RESPONSIBILITY_AREA, FeatureType.AIRSPACE],
  maxNrOfFeaturesPerLocation: 200,
  getReplaceableMessages: FeatureUtils.Filters.filterById,
  featureIdentifier: 'id',
  isViewPortBased: false,
  isNotificationsDisabled: true,
  isFlightLevelBased: true,
  maxResolution: 10000,
  declutter: false,
  zIndex: 10,
  flightLevel: null,
  theme: {
    elements: [
      ThemeElements.IMAGE_COLOR,
    ]
  },
  displayOnMap: true,
  displayInMessages: true,
  associatedMapOnlyFeatureLayerConfig: [
    {
      id: FeatureType.SAIL_DETAILS,
      getAssociatedFeatureId: (mapFeature) => mapFeature.getProperties().associatedFeatureId
    },
    {
      id: FeatureType.NETWORK_COVERAGE,
      getAssociatedFeatureId: (mapFeature) => mapFeature.getProperties().associatedFeatureId
    }
  ],
  geoJsonMapper: (feature) => {
    const geometryCoordinates = feature.volumes.map(v => v.geometry.coordinates);
    const outerBoxGeometryCoordinates = OpGeometryUtils.getOuterBoxGeometryCoordinates(feature);
    const opState = feature?.state || feature.state;
    const textForVolumes = feature.volumes.map(v => DronePlanUtils.constructVolumeText(v));
    return new Feature({
      id: feature.id,
      state: opState,
      stateAttributeType: feature.stateAttributeType,
      shouldDisplayOuterBoxGeometry: outerBoxGeometryCoordinates.length > 0,
      outerBoxGeometry: new MultiPolygon(outerBoxGeometryCoordinates),
      geometry: new MultiPolygon(geometryCoordinates),
      featureType: feature.featureType,
      associatedFeatureId: feature.id,
      refrainFromMap: feature.state === OperationPlanState.CLOSED.state,
      selected: false,
      text: textForVolumes,
      publicTitle: feature.publicTitle,
      operationPolygons: feature.operationPolygons,
      trajectoryTakeoffLocation: feature.trajectoryTakeoffLocation,
      trajectoryLandingLocation: feature.trajectoryLandingLocation,
      startTime: feature.validity.validFrom
    });
  },

  color: (colors) => {
    return colors.imageColor;
  },

  defaultStyle: () => {
    return {
      showText: true,
      textSize: 14,
      iconSize: 10,
      colors: {
        borderColor: {r: 0, g: 255, b: 237, a: 1},
        fillColor: {r: 0, g: 255, b: 237, a: 0.3},
        textColor: {r: 255, g: 255, b: 255, a: 1},
        textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
        imageColor: {r: 0, g: 255, b: 237, a: 1},
      }
    }
  },

  getMinifiedObject(feature) {
    let timeToKeepAfterTimeout = Features.getFeature(feature.featureType).timeToKeepAfterTimeout;
    const operationPolygons = feature.volumes
      .map(volume => OpGeometryUtils.constructPolygonFromOperationGeography(volume.geometry));
    return {
      id: feature.id,
      featureType: feature.featureType,
      providerId: feature.providerId,
      usspProviderId: feature.usspProviderId,
      state: feature.state,
      validity: feature.validity,
      priority: feature.priority,
      validTime: {
        from: new Date(feature.validity.validFrom).getTime(),
        to: new Date(feature.validity.validTo).getTime(),
        keepUntil: new Date(feature.validity.validTo).getTime() + timeToKeepAfterTimeout
      },
      authorizationRequestedBy: feature.authorizationRequestedBy,
      localApprovalResults: feature.localApprovalResults,
      localTakeoffClearanceResults: feature.localTakeoffClearanceResults,
      conflicts: feature.conflicts,
      createdBySmartsis: feature.createdBySmartsis,
      volumes: feature.volumes,
      maxSail: feature.maxSail,
      maxAltitude: feature.maxAltitude,
      maxTakeoffMassGrams: feature.maxTakeoffMassGrams,
      unitOfMeasure: feature.unitOfMeasure,
      modeOfOperation: feature.modeOfOperation,
      contactDetails: feature.contactDetails,
      operator: feature.operator,
      publicTitle: feature.publicTitle,
      updateTime: feature.updateTime,
      submitTime: feature.submitTime,
      stateTransitionMode: feature.stateTransitionMode,
      controllerLocation: feature.controllerLocation,
      takeoffLocation: feature.takeoffLocation,
      trajectoryTakeoffLocation: feature.trajectoryTakeoffLocation,
      trajectoryLandingLocation: feature.trajectoryLandingLocation,
      closureReason: feature.closureReason,
      operationPolygons: new MultiPolygon(operationPolygons),
      refrainFromInfoPanel: OperationPlanServices.isClosedUpcomingOperationPlan(feature),
    }
  },

  filterMessages: (messages) => {
    return messages.filter((msg) => {
      return msg.featureType.startsWith(FeatureType.OPERATION_PLAN) && msg.state !== OperationPlanState.CLOSED.state;
    });
  },

  sortInfoPanelMessageList(a, b) {
    return ModelUtils.sort(
      OperationPlanServices.getEarliestEffectiveTimeBegin(b),
      OperationPlanServices.getEarliestEffectiveTimeBegin(a)
    );
  },

  sortFeaturesList(a, b) {
    return ModelUtils.sort(a.volumes[0].startTime, b.volumes[0].startTime);
  }

}