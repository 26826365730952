<template>
  <div class="row m-0">
    <ListPanel
      :entries="list"
      :idPrefix="'variable'"
      :entryStyle="getButtonStyle"
      :onSelectEntry="selectItem"
      class="pt-2 px-0"
    >
    </ListPanel>
    <ThresholdsEditor v-if="isAllowedToEdit"></ThresholdsEditor>
  </div>
</template>
<script>

import A from '../../../constants/actions';
import ListPanel from '../list-panel/ListPanel.vue';
import PermissionUtils from '../../../utils/PermissionUtils';
import ThresholdsEditor from './editor/ThresholdsEditor.vue';
import Features from '../../../config/features';

export default {
  name: 'ThresholdsConfiguration',
  components: {
    ListPanel,
    ThresholdsEditor,
  },
  computed: {
    isAllowedToEdit() {
      return PermissionUtils.isAllowedToEditViews();
    },
    list() {
      const variables = this.$store.state.thresholdStore.thresholds;
      return variables.map((variable) => {
        return {id: variable.id, name: this.$i18n.t(Features.getFeature(variable.id).name)}
      });
    }
  },
  methods: {
    selectItem(item) {
      this.$store.dispatch(A.THRESHOLD_SELECT_THRESHOLDS, item.id);
    },

    getButtonStyle(item) {
      return [
        'btn', 'btn-primary',
        'w-100',
        'm-1',
        'listButton',
        (this.$store.state.thresholdStore.currentThreshold.id === item.id) ? 'selected' : '',
      ].join(' ');
    }
  }

}
</script>