<script>
import ConflictItem from "./ConflictItem";
import OperationPlanService from "../../../../../services/operationPlanService";
import ImgUtils from "../../../../../utils/ImgUtils";
import DialogUtils from "../../../../../utils/DialogUtils";

export default {
  name: "ConflictSuppressionPanel",
  components: {ConflictItem},
  props: {
    value: Object, // OperationPlan
    disableSaveButton: {}
  },
  watch: {
    value: {
      deep: true,
      handler(updatedOp) {
        const suppressedBlockingConflicts = this.conflictsList
          .filter(conf => conf.rejecting === true)
          .filter(conflict => this.isConflictSuppressed(conflict, updatedOp));
        this.disableSaveButton(this.getBlockingConflicts !== suppressedBlockingConflicts.length);
      }
    }
  },
  data: () => ({
    conflictsList: [],
    showLoadingGif: false
  }),
  async created() {
    await this.fetchConflicts();
  },
  computed: {
    getBlockingConflicts() {
      return this.conflictsList ? this.conflictsList.filter(conf => conf.rejecting === true).length : 0;
    },
    getNonBlockingConflicts() {
      return this.conflictsList ? this.conflictsList.filter(conf => conf.rejecting === false).length : 0;
    },
    conflictingOps() {
      return this.conflictsList ? this.conflictsList.filter(conflict => conflict.objectType === 'OPERATION_PLAN')
        .filter(conflict => conflict.conflictType !== 'CONDITION_VIOLATION') : [];
    },
    conflictingUasZones() {
      return this.conflictsList ? this.conflictsList.filter(conflict => conflict.objectType === 'UAS_ZONE') : [];
    },
    otherConflicts() {
      return this.conflictsList ? this.conflictsList.filter(conflict => conflict.objectType !== 'UAS_ZONE')
        .filter(conflict => conflict.conflictType === 'CONDITION_VIOLATION') : [];
    },
    isPriorityHigh() {
      return this.value?.priority === 'PRIO_HIGH';
    },
    isAuthorizedOp() {
      return this.value?.state === 'AUTHORIZED';
    }
  },
  methods: {
    selectConflict(event, {objectType, conflictType, objectId}) {
      let updatedOp = JSON.parse(JSON.stringify(this.value));
      if (event.target.checked) {
        const suppressedConflicts = this.value.suppressedConflicts || [];
        suppressedConflicts.push({
          objectType,
          conflictType,
          objectId
        });
        updatedOp = {
          ...updatedOp,
          suppressedConflicts
        }
      } else {
        updatedOp = {
          ...updatedOp,
          suppressedConflicts: updatedOp.suppressedConflicts
            ?.filter(it => {
              return !(this.areEqualConflicts(it, {objectId: objectId, conflictType: conflictType}))
            })
        }
      }
      this.$emit('input', updatedOp);
    },
    async fetchConflicts() {
      this.showLoadingGif = true;
      if (this.isAuthorizedOp) {
        await this.fetchActivationConflicts();
      } else {
        await this.fetchAuthorizationConflicts();
      }
    },
    async fetchActivationConflicts() {
      await OperationPlanService.fetchActivationConflicts({
        providerId: this.value.providerId,
        operationPlanId: this.value.id,
        ...this.constructCallBacks()
      });
    },
    async fetchAuthorizationConflicts() {
      await OperationPlanService.fetchAuthorizationConflicts({
        operationPlanDTO: this.value,
        ...this.constructCallBacks()
      });
    },
    getButtonImg(name) {
      return ImgUtils.getButtonImg(name, 'svg');
    },
    conflictIsSuppressed(conflict) {
      return this.value.suppressedConflicts && this.value.suppressedConflicts
        ?.findIndex(sp => this.areEqualConflicts(sp, conflict)) !== -1;
    },
    removeNonExistingConflicts() {
      let updatedOp = JSON.parse(JSON.stringify(this.value));
      updatedOp = {
        ...updatedOp,
        suppressedConflicts: updatedOp.suppressedConflicts
          ?.filter(it => {
            return this.conflictsList
              ?.findIndex(conf => this.areEqualConflicts(it, conf)) !== -1
          })
      }
      this.$emit('input', updatedOp);
    },
    constructCallBacks() {
      return {
        successCallback: (conflicts) => {
          this.conflictsList = conflicts;
          this.showLoadingGif = false;
          this.removeNonExistingConflicts();
        },
        errorCallback: (error) => {
          this.showLoadingGif = false;
          console.error(this.$i18n.t('errorMessages.dronePlanConflictFetchError',
            {
              dronePlanId: this.value.id,
              error: error.message
            }));
          DialogUtils.errorNotification(error.message);
          this.disableSaveButton(true);
        }
      }
    },
    areEqualConflicts(firstConf, secondConf) {
      return firstConf.objectId === secondConf.objectId && firstConf.conflictType === secondConf.conflictType;
    },
    isConflictSuppressed(conflict, op) {
      return op.suppressedConflicts
        && op.suppressedConflicts?.findIndex(conf => this.areEqualConflicts(conf, conflict)) !== -1;
    }
  }
}
</script>

<template>
  <div class="conflicts-suppression-container" id="conflicts-suppression-container">
    <div class="conflicts-suppression-plan-form" id="conflicts-suppression-plan-form">
      <div v-if="showLoadingGif" id="loading"
           v-bind:style="{ backgroundImage: 'url(icons/progressIndicator.gif)'}"></div>
      <div>
        <div v-if="isAuthorizedOp" class="conflicts-panel-title" id="activation-precheck-title">
          {{ $t('labels.operationPlan.activationPrecheckResult') }}
        </div>
        <div v-else class="conflicts-panel-title" id="authorization-precheck-title">
          {{ $t('labels.operationPlan.authorizationPrecheckResult') }}
        </div>
        <div class="conflicts-counter row m-0 mb-2" id="conflicts-counter">
          <div class="col-6 m-0 p-0">
            <ul class="m-0 pl-3">
              <li class="blocking-counter" id="blocking-counter">
                {{ $t('labels.operationPlan.blockingConflicts') }} : {{ getBlockingConflicts }}
              </li>
              <li class="non-blocking-counter" id="non-blocking-counter">
                {{ $t('labels.operationPlan.nonBlockingConflicts') }} : {{ getNonBlockingConflicts }}
              </li>
            </ul>
          </div>
          <div class="refresh-precheck-button-container col-6 m-0 pr-1" id="refresh-precheck-button-container">
            <button class="btn btn-primary p-1 pr-2"
                    id="refresh-precheck-button"
                    @click="fetchConflicts">
            <span>
                <img class="refreshButtonImg" alt="Refresh Button" v-bind:src="getButtonImg('refresh')">
            </span>
              {{ $t('labels.operationPlan.refreshPrecheck') }}
            </button>
          </div>
        </div>
      </div>
      <div class="conflicts-container p-1" id="conflicts-panel">
        <div class="conflicts-panel-title pl-0" id="op-conflicts-panel-title">
          {{ $t('labels.operationPlan.conflictingDronePlans') }}
        </div>
        <div v-if="conflictingOps.length === 0" class="no-conflicts-message" id="no-op-conflict-message">
          {{ $t('labels.operationPlan.noConflict') }}
        </div>
        <ConflictItem
          v-for="conflict in conflictingOps"
          :key="conflict.objectId + '_' + conflict.conflictType"
          :conflict="conflict"
          :isChecked="conflictIsSuppressed(conflict)"
          :select-conflict="selectConflict"
          :show-checkbox="isPriorityHigh"
          :activation-conflict="isAuthorizedOp">
        </ConflictItem>
        <div class="conflicts-panel-title pl-0" id="geozone-conflicts-panel-title">
          {{ $t('labels.operationPlan.conflictingGeozones') }}
        </div>
        <div v-if="conflictingUasZones.length === 0" class="no-conflicts-message" id="no-geozone-conflict-message">
          {{ $t('labels.operationPlan.noConflict') }}
        </div>
        <ConflictItem
          v-for="conflict in conflictingUasZones"
          :key="conflict.objectId + '_' + conflict.conflictType"
          :conflict="conflict"
          :disabled="!conflict.uasZoneManagedByRequester"
          :select-conflict="selectConflict"
          :is-checked="conflictIsSuppressed(conflict)"
          :show-checkbox="isPriorityHigh"
          :activation-conflict="isAuthorizedOp">
        </ConflictItem>
        <div v-if="otherConflicts.length !== 0 " class="conflicts-panel-title pl-0" id="other-conflicts-panel-title">
          {{ $t('labels.operationPlan.otherConflicts') }}
        </div>
        <ConflictItem
          v-for="conflict in otherConflicts"
          :key="conflict.objectId + '_' + conflict.conflictType"
          :conflict="conflict"
          :select-conflict="selectConflict"
          :is-checked="conflictIsSuppressed(conflict)"
          :show-checkbox="isPriorityHigh"
          :activation-conflict="isAuthorizedOp">
        </ConflictItem>
      </div>
    </div>
    <div class="conflict-suppress-warning mt-1" id="conflict-suppress-warning">
      {{
        (isAuthorizedOp) ? $t('labels.operationPlan.suppressActivationWarning') :
          $t('labels.operationPlan.suppressAuthorizationWarning')
      }}
    </div>
  </div>
</template>

<style src="./conflictSuppressionPanel.css"/>