import {createI18n} from 'vue-i18n';
import AppConfig from "../config/appConfig";
import Logger from "../utils/LoggerUtils";
import DialogUtils from "../utils/DialogUtils";

function setI18nLanguage(lang) {
  i18n.global.locale = lang;
  document.querySelector('html').setAttribute('lang', lang);
  return lang
}

export async function loadLanguageAsync(lang) {
  if (typeof lang === 'undefined' || lang === '' || lang === null) {
    lang = document.querySelector('html').getAttribute('lang') || AppConfig.ui.defaultLanguage;
  }
  if (i18n.global.locale === lang || lang === '')
    return;

  await fetch(`i18n/${lang}.json`)
    .then(response => response.json())
    .then(msgs => {
      i18n.global.setLocaleMessage(lang, msgs);
      return setI18nLanguage(lang);
    })
    .catch(() => {
      const errorMessage = i18n.global.t('errorMessages.languageFileError', {lang});
      Logger.error(errorMessage);
      DialogUtils.errorNotification(errorMessage);
      return Promise.reject(errorMessage);
    });
}

export const i18n = createI18n({
  fallbackLocale: 'eng',
  silentTranslationWarn: true,
  globalInjection: true,
});

export default {
  i18n,
  loadLanguageAsync
}