<template>
  <div v-if="isOpen" class="dialogContainer" id="restrict-aor-confirmation-container-id" style="display: block">
    <div class="dialog modal" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div id="restrict-aor-dialog-modal-content" class="modal-content smartSISPanel">
          <div class="restrict-eor-country-header form-inline" id="restrict-aor-confirmation-header-id">
            <h5 class="modal-title col-6">{{ $t('confirmationMessages.title') }}</h5>
            <div v-if="displayCountryDropdown" class="col-6">
            <span id="property-drop-down-title" class="restrict-eor-country-title">
              <strong>
               {{ $t('labels.geozone.country') }}<strong class="text-danger">*</strong>:
              </strong>
            </span>
              <PropertyDropDown class="restrict-eor-country-dropdown-list col-4"
                                prop-name="country"
                                :is-mandatory="true"
                                :get-value="getCountry"
                                :set-value="setCountry"
                                :is-valid="validateCountry"
                                :options="options"
                                :disabled="false"
                                id="restriction-aor-country-property"/>
            </div>
          </div>
          <div class="modal-body errorBody" id="restrict-aor-confirmation-body-id">
            <p v-html="message"></p>
          </div>
          <div class="modal-footer" id="restrict-aor-confirmation-footer-id">
            <button type="button"
                    id="restrict-aor-confirmation-yes-button"
                    :class="['btn', 'btn-danger'].join(' ')"
                    :disabled="!validateCountry"
                    @click="restrictAors"
            >
              {{ $t('labels.common.yes') }}
            </button>
            <button type="button"
                    id="restrict-aor-confirmation-no-button"
                    :class="['btn', 'btn-primary'].join(' ')"
                    @click="close"
            >
              {{ $t('labels.common.no') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import A from '../../constants/actions';
import PropertyDropDown from "../../common/PropertyDropDown";
import AppConfig from '../../config/appConfig';
import GeozoneFormUtils from "../../utils/geozones/GeozoneFormUtils";
import GeozoneConfigUtils from "../../utils/geozones/GeozoneConfigUtils";
import Store from "../../store";
import Logger from "../../utils/LoggerUtils";
import DialogUtils from "../../utils/DialogUtils";
import GeozoneService from "../../services/geozoneService";
import PopupUtils from "../../utils/PopupUtils";

export default {
  name: 'RestrictionAorConfirmation',
  components: {
    PropertyDropDown
  },
  data() {
    return {
      country: AppConfig.geozoneCountries.length > 1 ? '' : AppConfig.geozoneCountries[0]
    }
  },
  computed: {
    message() {
      return this.$store.getters.aorRestrictionDialogMessage;
    },
    isOpen() {
      return this.$store.getters.isAorRestrictionDialogOpen;
    },
    options() {
      return AppConfig.geozoneCountries.map(value => {
        return {name: value, value: value};
      });
    },
    displayCountryDropdown() {
      return this.options.length > 1;
    },
    validateCountry() {
      return this.country && this.country !== '';
    },
  },

  methods: {
    async restrictAors() {
      const geozones = await this.getGeozonesForCurrentValidAors();
      if (geozones[0] && GeozoneConfigUtils.hasValidAuthorityDetails(geozones[0])) {
        this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
        this.saveGeozones(geozones);
      } else {
        const errorMessage = geozones.length === 0 ?
          this.$i18n.t('errorMessages.restrictAorInvalidAorsError', {
            respAreas: this.$store.getters.getCurrentResponsibilityAreas.join(',')
          }) : this.$i18n.t('errorMessages.geozoneValidationError');
        Logger.error(errorMessage);
        DialogUtils.errorNotification(errorMessage);
      }
      this.country = AppConfig.geozoneCountries.length > 1 ? '' : AppConfig.geozoneCountries[0];
      return this.$store.dispatch(A.AOR_RESTRICTION_DIALOG_CLOSE);
    },
    close() {
      this.country = AppConfig.geozoneCountries.length > 1 ? '' : AppConfig.geozoneCountries[0];
      return this.$store.dispatch(A.AOR_RESTRICTION_DIALOG_CLOSE);
    },
    getCountry() {
      return this.country;
    },
    setCountry(propName, value) {
      this.country = value;
    },
    saveGeozones(geozones) {
      GeozoneService.saveGeozones({
        geozones: geozones,
        successCallback: () => {
          Store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          Logger.info(this.$i18n.t('logMessages.geozoneCreated'));
          PopupUtils.success(this.$i18n.t('popupMessages.geozoneCreated'));
          Store.dispatch(A.FORM_WRAPPER_CLOSE);
          const invalidAors = this.getInvalidAorsForCurrentView();
          if (invalidAors.length !== 0) {
            DialogUtils.errorNotification(
              this.$i18n.t('errorMessages.restrictAorInvalidAorsError', {respAreas: invalidAors.join(',')})
            );
          }
        },
        errorCallback: (error) => {
          Store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          const errorMessage = this.$i18n.t('errorMessages.geozoneCreateError', {error});
          Logger.error(errorMessage);
          DialogUtils.errorNotification(errorMessage);
        }
      });
    },
    getInvalidAorsForCurrentView() {
      return this.$store.getters.getCurrentResponsibilityAreas.filter(designator => !this.isValidAorByDesignator(designator));
    },
    isValidAorByDesignator(aorDesignator) {
      const aor = this.$store.getters.getResponsibilityAreaByDesignator(aorDesignator);
      return aor !== undefined && aor.geometry !== undefined;
    },
    async getGeozonesForCurrentValidAors() {
      let currentResponsibilityAreaDesignators = this.$store.getters.getCurrentResponsibilityAreas;
      return await Promise.all(currentResponsibilityAreaDesignators
        .filter(designator => this.isValidAorByDesignator(designator))
        .map(async (ra) => await GeozoneFormUtils.constructGeozoneForResponsibilityArea(ra, this.country)));
    }
  },
}
</script>
<style src="./restrictAorDialog.css"/>