import FeatureType from '../../constants/featureType';
import FeatureUtils from '../../utils/FeatureUtils';
import ThemeElements from '../../constants/theme-elements';
import OperationPlan from './operationplan';
import OperationPlaneState from "../../constants/operationPlanState";

export default {

  id: FeatureType.OPERATION_PLAN_PROPOSED,
  name: 'features.operationPlanProposed.name',
  icon: 'pending_plan',
  abbreviation: 'features.operationPlanProposed.abbreviation',
  staticDataType: [FeatureType.RESPONSIBILITY_AREA, FeatureType.AIRSPACE],
  maxNrOfFeaturesPerLocation: 200,
  getReplaceableMessages: FeatureUtils.Filters.filterById,
  featureIdentifier: 'id',
  isViewPortBased: false,
  isFlightLevelBased: true,
  maxResolution: 10000,
  declutter: false,
  zIndex: 10,
  flightLevel: null,
  theme: {
    elements: [
      ThemeElements.SHOW_TEXT,
      ThemeElements.BORDER_COLOR,
      ThemeElements.FILL_COLOR,
      ThemeElements.TEXT_COLOR,
      ThemeElements.TEXT_SIZE,
      ThemeElements.TEXT_BG_COLOR,
      ThemeElements.IMAGE_COLOR,
    ]
  },
  displayOnMap: true,
  displayInMessages: true,
  associatedMapOnlyFeatureLayerConfig: OperationPlan.associatedMapOnlyFeatureLayerConfig,
  geoJsonMapper: (feature) => {
    return OperationPlan.geoJsonMapper(feature);
  },

  color: (colors) => {
    return colors.imageColor;
  },

  defaultStyle: () => {
    return {
      showText: true,
      textSize: 14,
      iconSize: 10,
      colors: {
        borderColor: {r: 230, g: 242, b: 78, a: 1},
        fillColor: {r: 230, g: 242, b: 78, a: 0.3},
        textColor: {r: 255, g: 255, b: 255, a: 1},
        textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
        imageColor: {r: 230, g: 242, b: 78, a: 1},
      }
    }
  },

  getMinifiedObject(feature, minifiedObject) {
    return OperationPlan.getMinifiedObject(feature, minifiedObject);
  },

  filterMessages: (messages) => {
    return messages.filter((msg) => {
      return msg.featureType && msg.featureType === FeatureType.OPERATION_PLAN_PROPOSED
        && msg.state === OperationPlaneState.PROPOSED.state
        && (!msg.stateAttributeType || msg.stateAttributeType === OperationPlaneState.NONE.state);
    });
  },
  sortInfoPanelMessageList(a, b) {
    return OperationPlan.sortInfoPanelMessageList(a, b);
  },

  sortFeaturesList(a, b) {
    return OperationPlan.sortFeaturesList(a, b);
  }

}