import {Fill, Icon, Stroke, Style} from "ol/style";
import Point from "ol/geom/Point";
import {getCenter} from "ol/extent";
import AppConfig from "../../config/appConfig";
import CommonStyleFunction from "../CommonStyleFunction";

export default class SigmetAirmetStyleFunction {

  static buildSigmetAirmetStyleFunction(feature, stylingConfig, resolution) {
    let speed = '';
    let dir = 0;
    //all speeds are converted to MPS on backend
    if (feature.get('speed') !== undefined) {
      speed = Math.round(feature.get('speed')) + 'KT';
    }
    let sigmetIcon = 'icons/noWind.svg';
    if (feature.get('dir') !== undefined) {
      dir = Math.round(feature.get('dir'));
      sigmetIcon = 'icons/arrow.svg';
    }
    let centroid;
    let geometryStyle;
    const fill = new Fill({
      color: CommonStyleFunction.toRgba(stylingConfig.colors.fillColor)
    });

    if (feature.getGeometry().getType() === 'Point') {
      centroid = feature.getGeometry();
      const strokeConfig = {
        color: stylingConfig.colors.borderColor,
        width: 4
      };
      geometryStyle = CommonStyleFunction.getStyleConfigWithCircle(5, strokeConfig, 100, fill);
    } else {
      centroid = new Point(getCenter(feature.getGeometry().getExtent()));
      const strokeConfig = {
        color: stylingConfig.colors.borderColor,
        width: 2
      };
      geometryStyle = CommonStyleFunction.getStyleConfigWithStrokeAndText(undefined, strokeConfig, 100, fill);
    }

    const iconStyle = new Style({
      image: new Icon({
        color: CommonStyleFunction.toRgba(stylingConfig.colors.imageColor),
        src: sigmetIcon,
        rotation: (Math.PI * (dir - 90)) / 180,
        scale: stylingConfig.iconSize / 100
      }),
      geometry: centroid,
      zIndex: 1000
    });
    if (!stylingConfig.showText) {
      return [geometryStyle, iconStyle];
    }
    const textStroke = new Stroke({
      color: CommonStyleFunction.toRgba(stylingConfig.colors.textBackgroundColor),
      width: 3
    });

    const headerText = feature.get('tac').split(/\s+/).slice(0, 3).join(' ');
    const headerTextOffsets = {
      x: -20,
      y: -40
    };
    const header = CommonStyleFunction.getTextStyleConfig(stylingConfig, headerText, headerTextOffsets, textStroke);
    const speedTextOffsets = {
      x: 25,
      y: 15
    };
    const speedText = CommonStyleFunction.getTextStyleConfig(stylingConfig, speed, speedTextOffsets, textStroke);
    const intensityChangeTextInput = feature.get('intensityChange');
    const intensityChangeTextOffsets = {
      x: -25,
      y: 15
    };
    const intensityChangeText = CommonStyleFunction.getTextStyleConfig(
      stylingConfig, intensityChangeTextInput, intensityChangeTextOffsets, textStroke
    );
    const altText = feature.get('altitude') === undefined ? '' : constructAltitudeText(feature.get('altitude').from,
      feature.get('altitude').to);
    const obsTypeText = feature.get('phenomenonType');
    const obsAndAltitudeText = obsTypeText + ' ' + altText;
    const obsAndAltitudeOffsets = {
      x: -20,
      y: -20
    };
    const obsAndAltitude = CommonStyleFunction.getTextStyleConfig(
      stylingConfig, obsAndAltitudeText, obsAndAltitudeOffsets, textStroke
    );
    if (resolution > AppConfig.maxResolutionForText) {
      return [geometryStyle, iconStyle];
    }
    return [
      geometryStyle,
      iconStyle,
      new Style({
        text: header,
        geometry: centroid,
        zIndex: 1001
      }),
      new Style({
        text: speedText,
        geometry: centroid,
        zIndex: 1000
      }),
      new Style({
        text: intensityChangeText,
        geometry: centroid,
        zIndex: 1000
      }),
      new Style({
        text: obsAndAltitude,
        geometry: centroid,
        zIndex: 1000
      })
    ];
  }
}

function constructAltitudeText(from, to) {
  if (from !== undefined) {
    from = Math.round(from);
  }
  if (to !== undefined) {
    to = Math.round(to);
  }
  if (from === undefined && to === undefined) {
    return '';
  } else if (from === undefined) {
    return 'TOP FL ' + to;
  } else if (to === undefined) {
    return 'FL ' + from;
  } else {
    return 'FL ' + from + '/' + to;
  }
}