import FeatureType from '../../constants/featureType';
import FeatureUtils from '../../utils/FeatureUtils';
import PermissionUtils from '../../utils/PermissionUtils';
import ThemeElements from '../../constants/theme-elements';
import FilterUtil from '../../utils/FilterUtil';
import Store from '../../store';
import DialogUtils from '../../utils/DialogUtils';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import {i18n} from '../../internationalization/index';

export default {

  id: FeatureType.EVENT,
  name: 'features.event',
  icon: 'notam',
  staticDataType: [FeatureType.AIRPORT_HELIPORT, FeatureType.AIRSPACE],
  isViewPortBased: false,
  isFlightLevelBased: false,
  maxNrOfFeaturesPerLocation: 200,
  getReplaceableMessages: FeatureUtils.Filters.filterByNotam,
  featureIdentifier: 'id',
  maxResolution: 10000,
  declutter: true,
  zIndex: 31,
  flightLevel: null,
  theme: {
    elements: [
      ThemeElements.SHOW_TEXT,
      ThemeElements.BORDER_COLOR,
      ThemeElements.FILL_COLOR,
      ThemeElements.TEXT_COLOR,
      ThemeElements.TEXT_SIZE,
      ThemeElements.TEXT_BG_COLOR,
      ThemeElements.IMAGE_COLOR,
      ThemeElements.ICON_SIZE,
    ]
  },
  displayOnMap: true,
  displayInMessages: true,

  geoJsonMapper: (feature) => {
    feature.station = feature.location || feature.affectedFir;
    return new Feature({
      geometry: new GeoJSON().readGeometry(feature.geometry),
      id: feature.timesliceId,
      series: feature.series,
      number: feature.number,
      year: feature.year,
      text: feature.text || feature.notamText,
      station: feature.station,
      maxResolution: 10000,
      featureType: FeatureType.EVENT,
      type: feature.type
    });
  },

  color: (colors) => {
    return colors.imageColor;
  },

  defaultStyle: () => {
    return {
      showText: true,
      textSize: 14,
      iconSize: 10,
      colors: {
        borderColor: {r: 0, g: 228, b: 255, a: 1},
        fillColor: {r: 0, g: 228, b: 255, a: 0.1},
        textColor: {r: 255, g: 255, b: 255, a: 1},
        textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
        imageColor: {r: 0, g: 228, b: 255, a: 1}
      }
    }
  },

  executeQuery: (query) => {
    let smartQuery = query.split(',').filter(f => f.trim().includes(' ')).length;
    if (smartQuery !== 0 && (Store.state.notamFilterStore.currentFirFilter !== 'NO FILTERS'
      || Store.state.notamFilterStore.currentAirportFilter !== 'NO FILTERS')) {
      DialogUtils.errorNotification(i18n.global.t('errorMessages.selectNoFilter'));
      if (Store.state.notamFilterStore.currentFirFilter !== 'NO FILTERS') {
        Store.state.notamFilterStore.wrongFirQuery = true;
      }
      if (Store.state.notamFilterStore.currentAirportFilter !== 'NO FILTERS') {
        Store.state.notamFilterStore.wrongAirportQuery = true;
      }
      return false;
    } else {
      return true;
    }
  },
  getSearchFilters: (queryString) => {
    let filters = [];
    const filtersSelectedToView = Store.state.viewStore.currentView.filterIds;
    const currentFirFilter = Store.state.notamFilterStore.currentFirFilter;
    const currentAirportFilter = Store.state.notamFilterStore.currentAirportFilter;
    const selectedFilter = PermissionUtils.getAvailableNotamFilters().filter(f => filtersSelectedToView.includes(f.id));
    if (Store.state.notamFilterStore.currentFirFilter === 'ALL FILTERS') {
      filters = filters.concat(selectedFilter.filter(f => f.filterType === FeatureType.AIRSPACE));
    } else if (Store.state.notamFilterStore.currentFirFilter === 'NO FILTERS') {
      filters = [...filters];
    } else {
      filters = filters.concat(selectedFilter.filter(f => f.id === currentFirFilter));
    }

    if (Store.state.notamFilterStore.currentAirportFilter === 'ALL FILTERS') {
      filters = filters.concat(selectedFilter.filter(f => f.filterType === FeatureType.AIRPORT_HELIPORT));
    } else if (Store.state.notamFilterStore.currentAirportFilter === 'NO FILTERS') {
      filters = [...filters];
    } else {
      filters = filters.concat(selectedFilter.filter(f => f.id === currentAirportFilter));
    }
    if (queryString) {
      let series = queryString.charAt(0);
      filters = filters.concat(FilterUtil.constructSeriesFilter('airspace', series));
      filters = filters.concat(FilterUtil.constructSeriesFilter('airportheliport', series));
      let number = queryString.slice(1, queryString.length);
      if (number && number !== '*') {
        if (!number.endsWith('*')) {
          number = number.concat('*');
        }
        filters = filters.concat(FilterUtil.constructNumberFilter('airspace', number));
        filters = filters.concat(FilterUtil.constructNumberFilter('airportheliport', number));
      }
      if (number === '*') {
        DialogUtils.errorNotification(i18n.global.t('errorMessages.incorrectSyntaxError', {
          queryString,
          correctSyntax: queryString.split('*')[0]
        }));
      }
    }
    return filters;
  },

  getSearchLocations: (queryString, defaultMethod) => {
    if (queryString !== '') {
      return defaultMethod(queryString);
    } else {
      return Store.state.viewStore.currentView.locations;
    }
  },

  getMinifiedObject(feature, minifiedObject) {
    const number = feature.number.toLocaleString('en-US', {minimumIntegerDigits: 4, useGrouping: false});
    const featureId = feature.series + number + '/' + feature.year;
    return {
      ...minifiedObject,
      cancelledId: feature.cancelledId,
      type: feature.type,
      year: feature.year,
      series: feature.series,
      number: feature.number,
      featureId: featureId,
      validTime: (feature.type === 'C') ? {to: 1, from: 1} : feature.validTime,
      status: feature.status,
    };
  },

  sortFeaturesList(e1, e2) {
    if ((e1.cancelledId || e2.cancelledId) && !(e1.cancelledId && e2.cancelledId)) {
      return (e1.cancelledId) ? 1 : -1;
    }
    return (e1.type === 'C') ? 1 : (e1.type === 'R' && e2.type !== 'R') ? -1 : 0;
  },

  filterMessages: (messages) => {
    return messages.filter((msg) => {
      return msg.featureType === FeatureType.EVENT;
    });
  }

}