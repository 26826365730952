export default {

  all: {
    order: 0,
    name: 'ALL',
  },

  debug: {
    order: 1,
    name: 'DEBUG',
  },

  info: {
    order: 2,
    name: 'INFO',
  },

  warn: {
    order: 3,
    name: 'WARN',
  },

  error: {
    order: 4,
    name: 'ERROR',
  },

  fatal: {
    order: 5,
    name: 'FATAL',
  },

}