export default {
  NETWORKCOVERAGE_WEAK: {
    r: 255,
    g: 0,
    b: 0,
    a: 0.3
  },
  NETWORKCOVERAGE_STRONG: {
    r: 0,
    g: 128,
    b: 0,
    a: 0.3
  }
}