import FeatureType from "./featureType";

const features = [
  FeatureType.DRONES,
  FeatureType.AIRCRAFT,
  FeatureType.ADSB
]

export default {
  features
}