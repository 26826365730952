import operationPlanState from '../constants/operationPlanState';
import OPStateColoringConfig from './OPStateColoringConfig';

const fields = [
  {
    text: 'labels.common.all',
    state: 'ALL',
    count(elements) {
      return elements ? elements.length : 0;
    },
    style() {
      return '';
    },
    show: true,
  },
  {
    text: operationPlanState.PROPOSED.abbreviation,
    state: operationPlanState.PROPOSED.state,
    count(elements) {
      return elements.filter(e => e.state === operationPlanState.PROPOSED.state).length;
    },
    style() {
      return OPStateColoringConfig.createColorFunction(operationPlanState.PROPOSED);
    },
    show: true,
  },
  {
    text: operationPlanState.AUTHORIZED.abbreviation,
    state: operationPlanState.AUTHORIZED.state,
    count(elements) {
      return elements.filter(e => e.state === operationPlanState.AUTHORIZED.state).length;
    },
    style() {
      return OPStateColoringConfig.createColorFunction(operationPlanState.AUTHORIZED);
    },
    show: true,
  },
  {
    text: operationPlanState.ACTIVATED.abbreviation,
    state: operationPlanState.ACTIVATED.state,
    count(elements) {
      return elements.filter(e => e.state === operationPlanState.ACTIVATED.state).length;
    },
    style() {
      return OPStateColoringConfig.createColorFunction(operationPlanState.ACTIVATED);
    },
    show: true,
  },
  {
    text: operationPlanState.TAKEOFFREQUESTED.abbreviation,
    state: operationPlanState.TAKEOFFREQUESTED.state,
    count(elements) {
      return elements.filter(e => e.state === operationPlanState.TAKEOFFREQUESTED.state).length;
    },
    style() {
      return OPStateColoringConfig.createColorFunction(operationPlanState.TAKEOFFREQUESTED, true);
    },
    show: true,
  },
  {
    text: operationPlanState.CLOSED.abbreviation,
    state: operationPlanState.CLOSED.state,
    count(elements) {
      return elements.filter(e => e.state === operationPlanState.CLOSED.state).length;
    },
    style() {
      return OPStateColoringConfig.createColorFunction(operationPlanState.CLOSED);
    },
    show: true,
  },
  {
    text: operationPlanState.NO_SERVICE.abbreviation,
    state: operationPlanState.NO_SERVICE.state,
    count(elements) {
      return elements.filter(e => e.state === operationPlanState.NO_SERVICE.state).length;
    },
    style() {
      return OPStateColoringConfig.createColorFunction(operationPlanState.NO_SERVICE);
    },
    show: false,
  }
];

export default {
  fields,
}