import FeatureType from '../../constants/featureType';
import ThemeElements from '../../constants/theme-elements';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';

export default {

  id: FeatureType.APRON_ELEMENT,
  name: 'features.apronelement.name',
  abbreviation: 'features.apronelement.abbreviation',
  isStaticDataType: true,
  featureIdentifier: 'id',
  isViewPortBased: false,
  isFlightLevelBased: false,
  maxResolution: 10000,
  declutter: true,
  zIndex: 1,
  flightLevel: null,
  theme: {
    elements: [
      ThemeElements.SHOW_TEXT,
      ThemeElements.BORDER_COLOR,
      ThemeElements.FILL_COLOR,
      ThemeElements.TEXT_COLOR,
      ThemeElements.TEXT_SIZE,
      ThemeElements.TEXT_BG_COLOR,
    ]
  },
  displayOnMap: true,
  displayInMessages: false,

  geoJsonMapper: (feature) => {
    return new Feature({
      geometry: new GeoJSON().readGeometry(feature.geometry),
      id: feature.timesliceId || feature.id || feature.featureId,
      designator: feature.designator,
      type: feature.type,
      maxResolution: 10000,
      featureType: 'apronelement'
    });
  },

  color: (colors) => {
    return colors.textColor;
  },

  defaultStyle: () => {
    return {
      showText: true,
      textSize: 14,
      iconSize: 10,
      colors: {
        borderColor: {r: 130, g: 137, b: 140, a: 1},
        fillColor: {r: 240, g: 228, b: 255, a: 0.1},
        textColor: {r: 130, g: 137, b: 140, a: 1},
        textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
      }
    }
  },

}