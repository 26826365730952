import FeatureType from '../../constants/featureType';
import FeatureUtils from '../../utils/FeatureUtils';
import ThemeElements from '../../constants/theme-elements';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';

export default {

  id: FeatureType.WS_WARNING,
  name: 'features.wswarning.name',
  abbreviation: 'features.wswarning.abbreviation',
  icon: 'wind',
  isViewPortBased: false,
  isFlightLevelBased: false,
  maxNrOfFeaturesPerLocation: 30,
  getReplaceableMessages: FeatureUtils.Filters.filterByWarning,
  staticDataType: [FeatureType.AIRPORT_HELIPORT],
  featureIdentifier: 'id',
  maxResolution: 10000,
  declutter: true,
  zIndex: 1,
  flightLevel: null,
  theme: {
    elements: [
      ThemeElements.SHOW_TEXT,
      ThemeElements.BORDER_COLOR,
      ThemeElements.FILL_COLOR,
      ThemeElements.TEXT_COLOR,
      ThemeElements.TEXT_SIZE,
      ThemeElements.TEXT_BG_COLOR,
      ThemeElements.IMAGE_COLOR,
      ThemeElements.ICON_SIZE,
    ]
  },
  displayOnMap: true,
  displayInMessages: true,

  geoJsonMapper: (feature) => {
    const phenomenonText = ((feature.phenomenon && feature.phenomenon.text) ? feature.phenomenon.text : '');

    return new Feature({
      geometry: new Point([feature.location.lon, feature.location.lat]),
      id: feature.id,
      phenomenon: phenomenonText,
      featureType: 'wswarning'
    });
  },

  color: (colors) => {
    return colors.imageColor;
  },

  defaultStyle: () => {
    return {
      showText: true,
      textSize: 14,
      iconSize: 10,
      colors: {
        borderColor: {r: 240, g: 60, b: 60, a: 1},
        fillColor: {r: 0, g: 228, b: 255, a: 0.1},
        textColor: {r: 255, g: 255, b: 255, a: 1},
        textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
        imageColor: {r: 240, g: 60, b: 60, a: 1}
      }
    }
  },

  getMinifiedObject(feature, minifiedObject) {
    return {
      ...minifiedObject,
      sequenceNr: feature.sequenceNr,
      cancelledId: feature.cancelledId,
      validTime: feature.canceledId ? {to: 1, from: 1} : feature.validTime,
      status: feature.status,
    }
  },

  sortFeaturesList(e1, e2) {
    if ((e1.cancelledId || e2.cancelledId) && !(e1.cancelledId && e2.cancelledId)) {
      return (e1.cancelledId) ? 1 : -1;
    }
    return 0;
  }

}