import LayerUtils from "../utils/LayerUtils";
import PermissionUtils from "../utils/PermissionUtils";
import AppConfig from "../config/appConfig";
import SmartSisQueryService from "./smartSisQueryService";
import A from "../constants/actions";
import Store from "../store";

export default {
  
  websocketSubscribe: (store) => {
    let currentView = JSON.parse(JSON.stringify(store.state.viewStore.currentView));
    let layerIdsForSelectedTopics = LayerUtils.getLayerIdsByTopicNames(store.state.informationStore.selectedTopics);
    if (store.state.informationStore.selectedTopics.length > 0) {
      currentView.productTypes = currentView.productTypes.filter(p => layerIdsForSelectedTopics.includes(p));
    }
    let selectedFilters = PermissionUtils.getAvailableNotamFilters().filter(f => currentView.filterIds.includes(f.id));

    delete currentView.editable;
    delete currentView.filterType;

    if(!AppConfig.heightSlider.heightSliderEnabled || Store.getters.getAltitudeFilteredFeatures.length === 0){
      currentView.maxAltitudeInMeters = AppConfig.heightSlider.maximumHeightLimitInMeters;
      currentView.minAltitudeInMeters = AppConfig.heightSlider.minimumHeightLimitInMeters;
    }

    const subscribeConfig = {
      username: store.state.userStore.userProfile.username,
      userRoles: store.state.userStore.userProfile.userRoles,
      providerId: store.getters.providerId,
      view: currentView,
      notamFilters: SmartSisQueryService.constructQueryFilters(selectedFilters),
    };
    store.dispatch(A.WEBSOCKET_SET_CONFIG, subscribeConfig);
  }

}