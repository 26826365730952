<template>
  <div id="language-selector-container" class="language-selector-wrapper">
    <div class="language-selector-content">
      <div class="language-selector-header">
        <strong>{{ $t('logMessages.selectedLanguage') }}:</strong>
      </div>
      <div class="language-selector-button">
        <button
          id="language-selector-toggle-button"
          :class="['btn', 'btn-primary', 'language-button', (displayLanguagesContainer) ? 'selected' : ''].join(' ')"
          @click="toggleLanguageContainerDisplay"
          type="button"
        >
          {{ selectedLanguage.toUpperCase() }}
        </button>
        <div v-show="displayLanguagesContainer"
             id="languages-container"
             class="languages-container">
          <button :key="index" v-for="(language, index) in languages"
                  :id="language + '-button-id'"
                  :class="['btn', 'btn-primary', isLanguageSelected(language) ? 'selected' : ''].join(' ')"
                  @click="selectLanguage(language)"
          >
            {{ language.toUpperCase() }}
          </button>
        </div>
      </div>
    </div>

  </div>
</template>
<script>

import {loadLanguageAsync} from "../../../internationalization";
import AppConfig from "../../../config/appConfig";
import SmartSisUserService from "../../../services/smartSisUserService";
import A from "../../../constants/actions";
import PopupUtils from "../../../utils/PopupUtils";
import Logger from "../../../utils/LoggerUtils";
import DialogUtils from "../../../utils/DialogUtils";

export default {
  name: 'LanguageSelector',
  created() {
    document.addEventListener('click', this.closeLanguagesContainerIfNecessary);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeLanguagesContainerIfNecessary);
  },
  data: () => {
    return {
      displayLanguagesContainer: false,
    }
  },
  computed: {
    languages() {
      return AppConfig.ui.supportedLanguages;
    },
    selectedLanguage() {
      return this.$i18n.locale;
    }
  },
  methods: {
    toggleLanguageContainerDisplay() {
      this.displayLanguagesContainer = !this.displayLanguagesContainer;
    },
    isLanguageSelected(language) {
      return this.selectedLanguage === language.toLowerCase();
    },
    selectLanguage(language) {
      this.$store.dispatch(A.PROGRESS_INDICATOR_END);
      loadLanguageAsync(language.toLowerCase()).then(() => {
        SmartSisUserService.saveUserDefaultLanguage({
          language,
          successCallback: () => {
            this.$store.dispatch(A.PROGRESS_INDICATOR_END);
            PopupUtils.success(this.$i18n.t('popupMessages.defaultLanguageSaved'));
          },
          errorCallback: (error) => {
            this.$store.dispatch(A.PROGRESS_INDICATOR_END);
            const errorMessage = this.$i18n.t('errorMessages.defaultLanguageError', {error});
            Logger.error(errorMessage);
            DialogUtils.errorNotification(errorMessage);
          }
        });
      }).catch((error) => Logger.error(this.$i18n.t('errorMessages.defaultLanguageError', {error})));
    },
    closeLanguagesContainerIfNecessary(event) {
      const clickedOnToggleButton = event.composedPath().some(el => el.id === 'language-selector-toggle-button');
      const clickedOnContainer = event.composedPath().some(el => el.id === 'languages-container');
      if (!clickedOnToggleButton && !clickedOnContainer) {
        this.displayLanguagesContainer = false;
      }
    }
  }
}
</script>
<style src="./language-selector.css"></style>
