import FeatureType from "../constants/featureType";

const layersConfig = [
  {
    layerId: FeatureType.SAIL_DETAILS,
    multipleVolumesField: 'sailCells'
  },
  {
    layerId: FeatureType.NETWORK_COVERAGE,
    multipleVolumesField: 'networkCoveragePolygons'
  },
  {
    layerId: FeatureType.ED269,
    multipleVolumesField: 'volumes',
    embeddedMapMultipleVolumesField: 'volumes'
  },
  {
    layerId: FeatureType.ED269_OUTLOOK,
    multipleVolumesField: 'volumes',
    embeddedMapMultipleVolumesField: 'volumes'
  },
  {
    layerId: FeatureType.LARA_AIRSPACE,
    multipleVolumesField: 'geometries',
    embeddedMapMultipleVolumesField: 'geometries'
  }
];

function getMultipleVolumesField(featureType) {
  return layersConfig.find(conf => conf.layerId === featureType).multipleVolumesField;
}

function getEmbeddedMapMultipleVolumesField(featureType) {
  const layerConfig = layersConfig.find(conf => conf.layerId === featureType);
  return layerConfig.embeddedMapMultipleVolumesField || layerConfig.multipleVolumesField;
}

export default {
  layersConfig,
  getMultipleVolumesField,
  getEmbeddedMapMultipleVolumesField
}