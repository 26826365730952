import MapUtils from "../../utils/MapUtils";
import FeatureType from "../../constants/featureType";
import DroneFeature from "../../config/features/drones";
import Store from "../../store";
import DroneState from "../../constants/droneState";
import DroneStateColoringService from "../../config/droneStateColoringConfig";
import A from "../../constants/actions";
import TimeService from "../../services/timeService";
import VectorSource from "ol/source/Vector";
import Point from "ol/geom/Point";
import CoordinateUtils from "../../utils/CoordinateUtils";
import FeatureUtils from "../../utils/FeatureUtils";
import AppConfig from '../../config/appConfig';
import ModelUtils from "../../utils/ModelUtils";

/* eslint-disable no-dupe-class-members */
export default class DronesMessageProcessor {

  static #displayedDrones = new Map();
  static #dronesLayer = null;

  static process(payload) {
    if (TimeService.timeOffsetInMilliseconds() === 0) {
      this.#initializeDronesLayer();
      const drones = this.#getDronesToBeProcessed(payload);
      drones.forEach(drone => this.#updateDroneForDisplay(drone));
      this.#renderDronesOnTheMap();
      drones.filter(drone => this.#isMatchingDroneAltitude(drone))
        .forEach(drone => Store.dispatch(A.INFORMATION_PANEL_MSG_RECEIVED, FeatureUtils.Mapping.getMinifiedObject(drone)));
    }
  }

  static cleanupExpiredDrones(currentTime) {
    const droneFeaturesToRemove = Array.from(this.#displayedDrones.values())
      .filter(drone => drone.getProperties().validTime.to < currentTime).map(drone => drone.getProperties());
    droneFeaturesToRemove.forEach(drone => this.#displayedDrones.delete(drone.id));
    this.#renderDronesOnTheMap();
    Store.dispatch(A.INFORMATION_PANEL_MESSAGE_CLEANUP, droneFeaturesToRemove);
  }

  static wipeAllCachedDrones() {
    this.#displayedDrones.clear();
  }

  static updateDroneMapLabels() {
    Array.from(this.#displayedDrones.values()).forEach(drone => {
      const registration = drone.getProperties().registration;
      const altitude = drone.getProperties().altitude;
      drone.setProperties({mapLabel: DroneFeature.buildMapLabel(registration, altitude)}, true);
    });
    this.#renderDronesOnTheMap();
  }

  static #getDronesToBeProcessed(payload) {
    const drones = JSON.parse(payload);
    const droneLayerStylingConfig =
      Store.state.themeStore.currentTheme.stylingConfigs.find(s => s.layer === FeatureType.DRONES);
    const displayOnlyRogueDrones = !Store.getters.getDisplayOfDrones;
    return drones
      .filter(drone => this.#isDronePositionNewerThanTheOneAlreadyDisplayed(drone))
      .map(drone => this.#mapDrone(drone, displayOnlyRogueDrones, droneLayerStylingConfig));
  }

  static #prepareDroneForDisplayOnTheMap(drone) {
    const alreadyDisplayedDrone = this.#displayedDrones.get(drone.trackingId);
    if (alreadyDisplayedDrone) {
      alreadyDisplayedDrone.setProperties(this.#getUpdatedDroneProperties(drone), true);
    } else {
      this.#displayedDrones.set(drone.trackingId, DroneFeature.geoJsonMapper(drone));
    }
  }

  static #getUpdatedDroneProperties(drone) {
    return {
      geometry: new Point(drone.p).transform('EPSG:4326', 'EPSG:3857'),
      altitude: drone.a,
      mapLabel: DroneFeature.buildMapLabel(drone.registration, drone.a),
      state: drone.state,
      pointHistoryGeometry: CoordinateUtils.constructDroneHistoryGeometry(drone),
      validTime: {
        to: TimeService.currentTimeSliderTime().valueOf() + AppConfig.droneValidity * 1000,
      },
    };
  }

  static #mapDrone(drone, displayOnlyRogueDrones, stylingConfig) {
    const previousDrone = this.#displayedDrones.get(drone.trackingId)?.getProperties();
    let refrainFromMap = displayOnlyRogueDrones && drone.state === DroneState.CONFORMANT;
    const bgColor = DroneStateColoringService.getDroneIconColorByState(drone.state, stylingConfig);
    const textColor = DroneStateColoringService.getDroneTextColorByState(drone.state, stylingConfig);
    if (previousDrone && previousDrone.state !== drone.state) {
      Store.dispatch(A.NOTIFICATION_NEW_MESSAGE, drone);
    }
    if (!previousDrone && drone.state === DroneState.NON_CONFORMANT) {
      Store.dispatch(A.NOTIFICATION_NEW_MESSAGE, drone);
    }
    return {
      ...drone,
      refrainFromMap: refrainFromMap,
      color: bgColor,
      textColor: textColor,
      validTime: {
        to: TimeService.currentTimeSliderTime().valueOf() + 10 * 1000,
      }
    };
  }

  static #isDronePositionNewerThanTheOneAlreadyDisplayed(drone) {
    const alreadyExistingDrone = this.#displayedDrones.get(drone.trackingId);
    if (alreadyExistingDrone) {
      return Date.parse(alreadyExistingDrone.getProperties().acquisitionDatetime) <=
        Date.parse(drone.acquisitionDatetime);
    }
    return true;
  }

  static #initializeDronesLayer() {
    if (this.#dronesLayer === null) {
      this.#dronesLayer = MapUtils.getMapLayer(FeatureType.DRONES);
    }
  }

  static #renderDronesOnTheMap() {
    this.#dronesLayer?.setSource(new VectorSource({features: Array.from(this.#displayedDrones.values())}));
  }

  static #isMatchingDroneAltitude(drone) {
    if (Store.getters.getAltitudeFilteredFeatures.includes(FeatureType.DRONES)) {
      return ModelUtils.isAltitudeBetweenMinAndMaxFilteringAltitude(drone.a);
    }
    return true;
  }

  static #updateDroneForDisplay(drone) {
    if (drone.refrainFromMap || !this.#isMatchingDroneAltitude(drone)) {
      this.#displayedDrones.delete(drone.trackingId);
    } else {
      this.#prepareDroneForDisplayOnTheMap(drone);
    }
  }
}