import Store from '../store';
import Roles from '../constants/roles';
import AppConfig from '../config/appConfig';
import DialogUtils from "./DialogUtils";
import {i18n} from '../internationalization/index';

export default class PermissionUtils {

  static isSmartSISUser() {
    const PU = PermissionUtils;
    return PU.isUser() || PU.isSupervisor() || PU.isSuperAdmin() || PU.isChartGenerator();
  }

  static isAllowedToEditThemes() {
    return PermissionUtils.isSupervisor() || PermissionUtils.isSuperAdmin();
  }

  static isAllowedToEditViews() {
    return PermissionUtils.isSupervisor() || PermissionUtils.isSuperAdmin();
  }

  static isAllowedToEditNotamFilters() {
    return PermissionUtils.isSupervisor() || PermissionUtils.isSuperAdmin();
  }

  static isAllowedToEditUtmConfigurations() {
    return PermissionUtils.isSupervisor() || PermissionUtils.isSuperAdmin() ||
      PermissionUtils.isApprovalAutomationManager() || PermissionUtils.isCapacityManager();
  }

  static isAllowedToEditGlobalConfigurations() {
    return PermissionUtils.isSupervisor() || PermissionUtils.isSuperAdmin();
  }

  static isAllowedToEditGroupAndRoles() {
    return PermissionUtils.isSupervisor() || PermissionUtils.isSuperAdmin();
  }

  static isUser() {
    return (AppConfig.ui.authRequired) ? Store.state.userStore.authInstance.resourceAccess[AppConfig.auth.clientId].roles.includes(Roles.USER) : true;
  }

  static isReadOnlyUser() {
    return (AppConfig.ui.authRequired) ? Store.state.userStore.isReadOnlyUser : false;
  }

  static isSupervisor() {
    return (AppConfig.ui.authRequired) ? Store.state.userStore.authInstance.resourceAccess[AppConfig.auth.clientId].roles.includes(Roles.SUPERVISOR) : true;
  }

  static isSuperAdmin() {
    return (AppConfig.ui.authRequired) ? Store.state.userStore.authInstance.resourceAccess[AppConfig.auth.clientId].roles.includes(Roles.SUPER_ADMIN) : true;
  }

  static isChartGenerator() {
    return (AppConfig.ui.authRequired) ? Store.state.userStore.authInstance.resourceAccess[AppConfig.auth.clientId].roles.includes(Roles.T_CHART_GENERATOR) : true;
  }

  static isApprovalAutomationManager() {
    return (AppConfig.ui.authRequired) ? Store.state.userStore.authInstance.resourceAccess[AppConfig.auth.clientId]
      .roles.includes(Roles.APPROVAL_AUTOMATION_MANAGER) : true;
  }

  static isCapacityManager() {
    return (AppConfig.ui.authRequired) ? Store.state.userStore.authInstance.resourceAccess[AppConfig.auth.clientId]
      .roles.includes(Roles.CAPACITY_MANAGER) : true;
  }

  static hasGeozoneActivationRole() {
    return (AppConfig.ui.authRequired) ? Store.state.userStore.authInstance.resourceAccess[AppConfig.auth.clientId]
      .roles.includes(Roles.GEOZONE_ACTIVATION) : true;
  }

  static hasAtmAirspaceReservationRole() {
    return (AppConfig.ui.authRequired) ? Store.state.userStore.authInstance.resourceAccess[AppConfig.auth.clientId]
      .roles.includes(Roles.ATM_AIRSPACE_RESERVATION) : true;
  }

  static getAvailableThemes() {
    return Store.state.themeStore.themes;
  }

  static getAvailableViews() {
    return Store.state.viewStore.views;
  }

  static getDefaultThemeIdForUser() {
    const groupDefaultThemeId = PermissionUtils.getGroupDefaultThemeId(Store.state.userStore.activeGroupRole.group);
    const defaultThemeForUser = Store.state.themeStore.defaultThemeForUser;
    const availableThemes = PermissionUtils.getAvailableThemes();
    const defaultThemeForUserIfExist = availableThemes.filter(th => th.id === defaultThemeForUser);
    return defaultThemeForUser && defaultThemeForUserIfExist.length !== 0 ? defaultThemeForUser :
      groupDefaultThemeId ? groupDefaultThemeId : availableThemes.length > 0 ? availableThemes[0].id : 'Default';
  }

  static getGroupDefaultThemeId(group) {
    const defaultTheme = group.themes.find(t => t.defaultTheme);
    const defaultThemeId = defaultTheme ? defaultTheme.themeId : undefined;
    return defaultThemeId ? defaultThemeId : group.themes.length > 0 ? group.themes[0].id : undefined;
  }

  static getDefaultViewIdForUser() {
    const roleDefaultViewId = PermissionUtils.getRoleDefaultViewId(Store.state.userStore.activeGroupRole.role);
    const availableViews = PermissionUtils.getAvailableViews();
    return roleDefaultViewId ? roleDefaultViewId : availableViews.length > 0 ? availableViews[0].id : 'Default';
  }

  static getRoleDefaultViewId(role) {
    const defaultView = role.views.find(t => t.defaultView);
    const defaultViewId = defaultView ? defaultView.viewId : undefined;
    const availableViews = PermissionUtils.getAvailableViews();
    const roleViewIds = role.views.map(view => view.viewId);
    const roleViewsFromAvailable = availableViews.filter(view => roleViewIds.includes(view.id));
    return defaultViewId ? defaultViewId : roleViewsFromAvailable.length > 0 ? roleViewsFromAvailable[0].id : undefined;
  }

  static getAvailableNotamFilters() {
    return Store.state.notamFilterStore.filters;
  }

  static hasAccessToLayer(layer) {
    if (layer.requiresAccessRole) {
      const clientResourceAccess = Store.state.userStore.authInstance.resourceAccess[Roles.FEATURE_ACCESS_ROLES_CLIENT];
      return clientResourceAccess ? clientResourceAccess.roles.includes(layer.accessRoleName) : false;
    }
    return true;
  }

  static currentUserBelongsToOrganisation(providerId) {
    return providerId === AppConfig.providerId;
  }

  static performActionIfAllowed(isNotAllowedToPerformAction, actionCallback) {
    if (isNotAllowedToPerformAction) {
      DialogUtils.errorNotification(i18n.global.t('errorMessages.actionNotAllowed'));
    } else {
      actionCallback();
    }
  }

  static isAllowedToCreateDronePlan() {
    return Store.state.userStore.authInstance.resourceAccess[Roles.FEATURE_ACCESS_ROLES_CLIENT]
      .roles.includes('feature_operationPlans_write');
  }

  static getAvailableOpCreateRights() {
    return Store.state.userStore.authInstance.resourceAccess[Roles.FEATURE_ACCESS_ROLES_CLIENT]
      .roles.filter(role => role.startsWith('feature_operationPlans_write_'));
  }
}