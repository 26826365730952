import AjaxUtils from '../utils/AjaxUtils';
import AppConfig from '../config/appConfig';
import FeatureType from '../constants/featureType';
import OperationPlanState from '../constants/operationPlanState';
import MessageUtils from '../utils/MessageUtils';
import Store from '../store';
import intersect from '@turf/intersect';
import turfPolygon from 'turf-polygon';
import TimeService from "./timeService";
import Moment from "moment";
import OperationPlanEndpoints from '../constants/operationPlanEndpoints';
import {multiPolygon} from "turf";

const approveOperationPlanStateMapping = [
  {
    currentState: OperationPlanState.PROPOSED.state,
    changedState: OperationPlanState.AUTHORIZED.state
  },
  {
    currentState: OperationPlanState.TAKEOFFREQUESTED.state,
    changedState: OperationPlanState.ACTIVATED.state
  },
  {
    currentState: OperationPlanState.AUTHORIZED.state,
    changedState: OperationPlanState.ACTIVATED.state
  },
  {
    currentState: OperationPlanState.PROPOSED.state,
    changedState: OperationPlanState.ACTIVATED.state
  },
];

function withdrawOperationPlan(config) {
  return fetchCancelOperationPlanEndpoint(config, OperationPlanEndpoints.WITHDRAW_OPERATION_PLAN);
}

function cancelOperationPlan(config) {
  return fetchCancelOperationPlanEndpoint(config, OperationPlanEndpoints.CANCEL_OPERATION_PLAN);
}

function endOperationPlan(config) {
  return fetchCancelOperationPlanEndpoint(config, OperationPlanEndpoints.DECLARE_END_OF_FLIGHT);
}

function grantOperationPlan(config) {
  return fetchOperationPlanApproveRejectRevokeEndpoint(config, OperationPlanEndpoints.GRANT_OPERATION_PLAN);
}

function rejectOperationPlan(config) {
  return fetchOperationPlanApproveRejectRevokeEndpoint(config, OperationPlanEndpoints.REJECT_OPERATION_PLAN);
}

function revokeOperationPlan(config) {
  return fetchOperationPlanApproveRejectRevokeEndpoint(config, OperationPlanEndpoints.REVOKE_OPERATION_PLAN);
}

function fetchCancelOperationPlanEndpoint(config, endpoint) {
  const body = {
    operationPlanId: config.operationPlanId,
    providerId: AppConfig.providerId,
    reasonMessage: config.reasonMessage
  };
  return fetchPostEndpointWithBody(config, endpoint, body);
}

function fetchOperationPlanApproveRejectRevokeEndpoint(config, endpoint) {
  const body = {
    operationPlanId: config.operationPlanId,
    partialResultId: config.partialResultId,
    providerId: AppConfig.providerId,
    reasonMessage: config.reasonMessage,
    proposedTimeOffsetInMinutes: config.proposedTimeOffsetInMinutes
  };
  return fetchPostEndpointWithBody(config, endpoint, body);
}

function fetchPostEndpointWithBody(config, endpoint, body) {
  return fetch(
    AppConfig.server.serviceUrlRoot + endpoint,
    {
      method: 'post',
      headers: AjaxUtils.getHeaders(),
      body: JSON.stringify(body)
    })
    .then(response => {
      if (response.ok) {
        config.successCallback();
      } else {
        response.text().then(data => {
          config.errorCallback(data);
        });
      }
    })
    .catch(error => config.errorCallback(error));
}

function sendTakeOffRequest(config) {
  return fetch(
    AjaxUtils.setParameters(AppConfig.server.serviceUrlRoot + OperationPlanEndpoints.REQUEST_TAKEOFF,
      [
        AjaxUtils.getUserNameParam(),
        AjaxUtils.getParamObjectFor('providerId', AppConfig.providerId),
        AjaxUtils.getParamObjectFor('operationPlanId', config.operationPlanId)
      ]),
    {
      method: 'post',
      headers: AjaxUtils.getHeaders(),
      body: JSON.stringify(config.suppressedConflicts),
    })
    .then(response => {
      if (response.ok) {
        config.successCallback();
      } else {
        response.text().then(config.errorCallback);
      }
    })
    .catch(error => config.errorCallback(error));
}

function saveDronePlan(config) {
  const opPlan = JSON.parse(JSON.stringify(config.operationPlanDTO));
  opPlan.corridorHorizontalBuffer = Store.getters.getCorridorHorizontalBuffer;
  opPlan.submitTime = TimeService.currentUtcTime().toISOString();
  opPlan.updateTime = TimeService.currentUtcTime().toISOString();
  return fetch(
    AjaxUtils.setParameters(AppConfig.server.serviceUrlRoot + OperationPlanEndpoints.SUBMIT_OPERATION_PLAN,
      [
        AjaxUtils.getUserNameParam(),
        AjaxUtils.getParamObjectFor('providerId', AppConfig.providerId)
      ]),
    {
      method: 'post',
      headers: AjaxUtils.getHeaders(),
      body: JSON.stringify(opPlan),
    })
    .then(response => {
      switch (response.status) {
        case 200:
        case 201:
          response.text().then(data => {
            config.successCallback({data, opPlan});
          });
          break;
        case 408 :
          response.text().then(data => {
            config.errorCallback(data);
          });
          break;
        case 400 :
          response.text().then(data => {
            config.errorCallback(data);
          });
          break;
        default:
          response.text().then(data => {
            config.errorCallback(data);
          });
      }
    })
    .catch(error => config.errorCallback(error));
}

function fetchAuthorizationConflicts(config) {
  const opDto = JSON.parse(JSON.stringify(config.operationPlanDTO));
  opDto.corridorHorizontalBuffer = Store.getters.getCorridorHorizontalBuffer;
  return fetch(
    AjaxUtils.setParameters(AppConfig.server.serviceUrlRoot + OperationPlanEndpoints.FETCH_AUTHORIZATION_CONFLICTS,
      [
        AjaxUtils.getUserNameParam(),
      ]),
    {
      method: 'post',
      headers: AjaxUtils.getHeaders(),
      body: JSON.stringify(opDto)
    })
    .then(response => {
      if (response.status !== 200 && response.status !== 201) {
        response.json().then(error => config.errorCallback(error));
      } else {
        response.json().then(conflicts => {
          config.successCallback(conflicts)
        });
      }
    })
    .catch(error => config.errorCallback(error));
}

function fetchActivationConflicts(config) {
  return fetch(
    AjaxUtils.setParameters(AppConfig.server.serviceUrlRoot + '/ops/' + config.operationPlanId +
      OperationPlanEndpoints.FETCH_ACTIVATION_CONFLICTS,
      [
        AjaxUtils.getUserNameParam(),
        AjaxUtils.getParamObjectFor('providerId', config.providerId),
      ]),
    {
      method: 'post',
      headers: AjaxUtils.getHeaders(),
    })
    .then(response => {
      if (response.status !== 200 && response.status !== 201) {
        response.json().then(error => config.errorCallback(error));
      } else {
        response.json().then(conflicts => {
          config.successCallback(conflicts)
        });
      }
    })
    .catch(error => config.errorCallback(error));
}



function getOpChangedState(currentState) {
  let stateMapping = approveOperationPlanStateMapping.filter(s => s.currentState === currentState);
  return stateMapping[0].changedState;
}

function getOpColorBarByState(currentState) {
  switch (currentState) {
    case OperationPlanState.PROPOSED.state:
      return MessageUtils.getColors().TRANSPARENT;
    case OperationPlanState.AUTHORIZED.state:
      return MessageUtils.getColors().TRANSPARENT;
    case OperationPlanState.ACTIVATED.state:
      return MessageUtils.getColors().TRANSPARENT;
    case OperationPlanState.TAKEOFFREQUESTED.state:
      return MessageUtils.getColors().RED_TAKE_OFF;
    case OperationPlanState.NO_SERVICE.state:
      return MessageUtils.getColors().TRANSPARENT;
    default:
      return MessageUtils.getColors().RED;
  }
}

function getOpIdsIntersectingGeometry(geometry) {
  const opFeatureIds = [
    FeatureType.OPERATION_PLAN_PROPOSED, FeatureType.OPERATION_PLAN_ACCEPTED, FeatureType.OPERATION_PLAN_ACTIVATED
  ];
  return Store.getters.getMessages
    .filter(m => opFeatureIds.includes(m.featureType))
    .filter(op => op.state !== OperationPlanState.CLOSED.state)
    .filter(op => operationFeatureIntersectsGeometry(op, geometry))
    .map(op => op.id);
}

function operationFeatureIntersectsGeometry(operationPlan, geometry) {
  const operationPolygon = multiPolygon(operationPlan.operationPolygons.getCoordinates());
  const geometryPolygon = turfPolygon(geometry.getCoordinates());
  return intersect(operationPolygon, geometryPolygon) !== null;
}

function getFeatureTypeByCurrentState(currentState) {
  return OperationPlanState[currentState].assocFeatureType;
}

function isClosedOperationPlan(opPlanId) {
  const operations = Store.getters.getMessages.filter(m => opPlanId === m.id && m.featureType.indexOf('operationplan') !== -1)
  return operations.length > 0 && operations[0].state === OperationPlanState.CLOSED.state;
}

function isClosedUpcomingOperationPlan(opPlan) {
  return opPlan.featureType === FeatureType.OPERATION_PLAN_OUTLOOK && opPlan.state === OperationPlanState.CLOSED.state;
}

function getEarliestEffectiveTimeBegin(op) {
  return Math.min(...op.volumes.map(v => Moment.utc(v.startTime).valueOf()));
}

function getLatestEffectiveTimeEnd(op) {
  return Math.max(...op.volumes.map(v => Moment.utc(v.endTime).valueOf()));
}

function getEarliestTimeBeginWithActivationThreshold(op) {
  return getEarliestEffectiveTimeBegin(op) - AppConfig.operationPlanAllowActivationBeforeStartMillis;
}

export default {
  sendTakeOffRequest,
  endOperationPlan,
  cancelOperationPlan,
  grantOperationPlan,
  revokeOperationPlan,
  rejectOperationPlan,
  withdrawOperationPlan,
  saveDronePlan,
  fetchAuthorizationConflicts,
  fetchActivationConflicts,
  getOpChangedState,
  getOpColorBarByState,
  getOpIdsIntersectingGeometry,
  getFeatureTypeByCurrentState,
  isClosedOperationPlan,
  getEarliestEffectiveTimeBegin,
  getLatestEffectiveTimeEnd,
  getEarliestTimeBeginWithActivationThreshold,
  isClosedUpcomingOperationPlan
}