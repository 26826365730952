import A from '../../constants/actions';
import M from '../../constants/mutations';
import Vue from 'vue';
import { v4 as uuidv4 } from 'uuid';
import Features from '../../config/features';
import FeatureType from '../../constants/featureType';
import AppConfig from '../../config/appConfig';

const state = {
  isOpen: false,
  callback: null,
  details: null,
};

const droneAlertFeature = Features.getFeature(FeatureType.DRONE_ALERT);

const actions = {

  [A.DRONE_ALERT_DETAILS_SHOW]({commit}, config) {
    commit(M.DRONE_ALERT_DETAILS_SHOW, config);
  },

  [A.DRONE_ALERT_DETAILS_CANCEL]({commit}) {
    commit(M.DRONE_ALERT_DETAILS_CANCEL);
  },

  [A.DRONE_ALERT_DETAILS_UPDATE_FIELD]({commit}, config) {
    commit(M.DRONE_ALERT_DETAILS_UPDATE_FIELD, config);
  },

  [A.DRONE_ALERT_DETAILS_SAVE]({commit}) {
    commit(M.DRONE_ALERT_DETAILS_SAVE);
  },

};

const mutations = {

  [M.DRONE_ALERT_DETAILS_SHOW](state, config) {
    state.isOpen = true;
    state.callback = config.callback;

    state.details = {
      featureType: FeatureType.DRONE_ALERT,
      uss_name: AppConfig.providerId,
      aircrafts: [],
      related_alerts: [],
      location: '',
      message_id: uuidv4(),
      free_text: '',
      severity_type: 'INFORMATIONAL',
      type: 'OTHER_SEE_FREE_TEXT',
      source: droneAlertFeature.source,
      operation_plans: config.operationPlan,
    };
  },

  [M.DRONE_ALERT_DETAILS_CANCEL](state) {
    state.isOpen = false;
    state.callback = null;
    state.details = null;
  },

  [M.DRONE_ALERT_DETAILS_UPDATE_FIELD](state, config) {
    Vue.set(state.details, config.name, config.value);
  },

  [M.DRONE_ALERT_DETAILS_SAVE](state) {
    state.isOpen = false;
    if (state.callback) {
      state.callback(state.details);
    }
    state.callback = null;
    state.details = null;
  }

};

const getters = {};

export default {
  state,
  actions,
  getters,
  mutations,
};