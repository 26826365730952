export default {

  AD_WARNING: 'adwarning',

  ADSB: 'adsb',

  AIRMET: 'airmet',

  AIRPORT_HELIPORT: 'airportheliport',

  AIRSPACE: 'airspace',

  AIRCRAFT: 'aircraft',

  APRON_ELEMENT: 'apronelement',

  LARA_AIRSPACE: 'laraairspace',

  BRIEFING: 'briefing',

  CTR: 'ctr',

  ED269_BASE_FEATURE: 'uas',

  ED269: 'uaszone',

  ED269_OUTLOOK: 'uaszone_outlook',

  FLIGHT_OBJECT: 'flightobject',

  FLIGHT_TRACK: 'flighttrack',

  CONVECTION: 'aspoc_mf',

  DESIGNATED_POINT: 'designatedpoint',

  DRONES: 'drones',

  DRONE_ALERT: 'alertmessage',

  DRONE_FLIGHT: 'droneflight',

  DRONE_FLIGHT_UNIDENTIFIED: 'droneflightunidentified',

  DUST: 'dust',

  EVENT: 'event',

  GAMET: 'gamet',

  GROUND_HAZARD: 'groundhazard',

  ICING: 'icing',

  LIGHTNING: 'lightning',

  METAR: 'metar',

  OPERATION_PLAN: 'operationplan',

  OPERATION_PLAN_PROPOSED: 'operationplan_proposed',

  OPERATION_PLAN_ACCEPTED: 'operationplan_accepted',

  OPERATION_PLAN_ACTIVATED: 'operationplan_activated',

  OPERATION_PLAN_OUTLOOK: 'operationplan_outlook',

  RUNWAY_DIRECTION: 'runwaydirection',

  RUNWAY_ELEMENT: 'runwayelement',

  SFO: 'sfo',

  SIGMET: 'sigmet',

  TAF: 'taf',

  TAXIWAY_ELEMENT: 'taxiwayelement',

  THUNDERSTORM: 'thunderstorm',

  TCA: 'tca',

  TEMPPOINT: 'temppoint',

  TURBULENCE: 'turbulence',

  VAA: 'vaa',

  VAISALA: 'vaisala',

  WEATHER_DATA: 'weatherdata',

  WS_WARNING: 'wswarning',

  WINDBARB: 'windbarb',

  ROUTE_SEGMENT: 'routesegment',

  SERVICE_INSTANCE: 'serviceinstance',

  RESPONSIBILITY_AREA: 'responsibilityarea',

  TERRAIN: 'terrain',

  SAIL_DETAILS: 'saildetails',

  NETWORK_COVERAGE: 'networkcoverage',

  METEOPROBE: 'meteoprobe',

  ALERT_REPLY: 'alertreply',

  ICAO_FLIGHT_PLANS: 'icaoflightplans'
}