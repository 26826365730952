import {defineAsyncComponent} from "vue";

const GLOBAL_CONFIGS = [
  {
    id: 'geozone_config',
    name: 'labels.configurationPanel.global.geozone',
    configField: 'geozoneFeatureConfiguration',
    component: defineAsyncComponent(() => import('../components/config-panel/globalConfigurations/editor/GeozoneConfigEditor')),
  },
  {
    id: 'system_config',
    name: 'labels.configurationPanel.global.system',
    configField: 'systemConfigurations',
    component: defineAsyncComponent(() => import('../components/config-panel/globalConfigurations/editor/SystemConfigEditor')),
  },
  {
    id: 'display_config',
    name: 'labels.configurationPanel.global.displayConfig',
    configField: 'displayConfigurations',
    component: defineAsyncComponent(() => import('../components/config-panel/globalConfigurations/editor/DisplayConfigEditor')),
  },
]

function getGlobalConfigurations() {
  return GLOBAL_CONFIGS;
}

function getCustomEditor(type) {
  return GLOBAL_CONFIGS.find(config => config.name === type)?.component;
}

export default {
  getGlobalConfigurations,
  getCustomEditor,
}