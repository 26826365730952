import 'bootstrap/dist/css/bootstrap.min.css';
import {createApp} from 'vue';
import store from './store/';
import App from './App.vue';
import {i18n, loadLanguageAsync} from './internationalization/index';
import AppConfig from "./config/appConfig";

window.name = 'smartSIS';

const app = createApp({
  extends: App,
  beforeCreate() {
    loadLanguageAsync(AppConfig.ui.defaultLanguage);
  },
}).use(store)
  .use(i18n);

app.mount('#app');
