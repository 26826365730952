import Polygon from "ol/geom/Polygon";
import LineString from "ol/geom/LineString";
import A from "../../constants/actions";
import Store from "../../store";
import MapUtils from "../MapUtils";
import {intersect, kinks, polygon} from "turf";

export default class OpGeometryTypeUtils {

  static isOperationPlanVolumeBased(operationPlanInstance) {
    return operationPlanInstance.volumes && operationPlanInstance.volumes.length > 0;
  }

  static isOperationPlanWaypointBased(operationPlanInstance) {
    return operationPlanInstance.trajectoryElements && operationPlanInstance.trajectoryElements.length > 0;
  }

  static isOpWaypointAndVolumeBased(operationPlaneInstance) {
    return OpGeometryTypeUtils.isOperationPlanVolumeBased(operationPlaneInstance) &&
      OpGeometryTypeUtils.isOperationPlanWaypointBased(operationPlaneInstance);
  }

  static clearOperationVolumesGeometry(operationPlanInstance) {
    operationPlanInstance.volumes = null;
  }

  static clearOperationWaypointsGeometry(operationPlanInstance) {
    operationPlanInstance.trajectoryElements = null;
    Store.dispatch(A.TRAJECTORY_CLEAR_ELEMENTS);
  }

  static getOperationVolumesGeometry(operationPlanInstance) {
    return operationPlanInstance.volumes
      .map(volume => {
        const coordinates = volume?.geometry?.coordinates;
        return coordinates ? new Polygon(coordinates) : null;
      })
      .filter(polygon => polygon !== null);
  }

  static getOperationPolygonsById(opGufi) {
    const operationPlan = Store.getters.getOperationPlanById(opGufi);
    return operationPlan.operationPolygons;
  }

  static getOperationWaypointsGeometry(operationPlanInstance) {
    const listOfCoords = OpGeometryTypeUtils.getOperationWaypointsGeometryCoordinates(
      operationPlanInstance.trajectoryElements
    );
    return [new LineString(listOfCoords)];
  }

  static getOperationWaypointsGeometryCoordinates(trajectoryElements) {
    return trajectoryElements
      .map(waypoint => mapWaypointToCoordinates(waypoint))
      .filter(point => point !== null);
  }

  static mapPointToCoordinates(takeOffLocation) {
    return takeOffLocation.type === 'Point' ? takeOffLocation.coordinates : null;
  }

  static constructGeometryFromTrajectoryElements(trajectoryElements) {
    const listOfCoords = OpGeometryTypeUtils.getOperationWaypointsGeometryCoordinates(trajectoryElements);
    return [new LineString(listOfCoords)];
  }

  static volumeBasedOpInstancesHaveDifferentGeometries(newOpInstance, oldOpInstance) {
    return extractVolumeGeometriesAsJsonString(newOpInstance) !== extractVolumeGeometriesAsJsonString(oldOpInstance);
  }

  static waypointBasedOpInstancesHaveDifferentGeometries(newOpInstance, oldOpInstance) {
    return extractWaypointGeometriesAsJsonString(newOpInstance) !== extractWaypointGeometriesAsJsonString(oldOpInstance);
  }

  static isGeometryNotIntersectingViewGeometries(opInstance, extractGeometries) {
    if (extractGeometries) {
      return areOpGeometriesNotIntersectingViewGeometries(extractGeometries(opInstance), MapUtils.getViewGeometries());
    }
    return false;
  }

  static isCorridorBufferValid() {
    const corridorHorizontalBuffer = Store.getters.getCorridorHorizontalBuffer;
    return corridorHorizontalBuffer !== null && !isNaN(corridorHorizontalBuffer) && parseFloat(corridorHorizontalBuffer) > 0;
  }

  static existSelfIntersectingGeometry(geometries) {
    return geometries ?
      geometries.some(geometry => geometry.getType() === 'Polygon' && isSelfIntersectingPolygon(geometry)) : false;
  }
}

function extractVolumeGeometriesAsJsonString(operationPlanInstance) {
  return JSON.stringify(operationPlanInstance.volumes?.map(volume => volume?.geometry));
}

function extractWaypointGeometriesAsJsonString(operationPlanInstance) {
  return JSON.stringify(operationPlanInstance.trajectoryElements);
}

function areOpGeometriesNotIntersectingViewGeometries(opGeometries, viewGeometries) {
  return !viewGeometries.some(viewGeometry => isAnyOpGeometryIntersectingViewGeometry(opGeometries, viewGeometry));
}

function isAnyOpGeometryIntersectingViewGeometry(opGeometries, viewGeometry) {
  return opGeometries.some(opGeometry => geometriesIntersect(opGeometry, viewGeometry));
}

function geometriesIntersect(opGeometry, viewGeometry) {
  if (OpGeometryTypeUtils.existSelfIntersectingGeometry([opGeometry]) || opGeometry.getCoordinates().length === 0) {
    return true;
  }
  return intersect(
    {type: opGeometry.getType(), coordinates: opGeometry.getCoordinates()},
    {type: viewGeometry.getType(), coordinates: viewGeometry.getCoordinates()}
  );
}

function mapWaypointToCoordinates(waypoint) {
  const longitude = waypoint?.longitude;
  const latitude = waypoint?.latitude;
  return longitude && latitude ? [longitude, latitude] : null;
}

function isSelfIntersectingPolygon(geometry) {
  const kinkedPoly = polygon(geometry.getCoordinates());
  const unkinkedPoly = kinks(kinkedPoly);
  return unkinkedPoly.features.length > 1
}