<template>
  <div>
    <div class="thresholdTitle">{{ this.name }} [{{ this.unit }}]</div>
    <div class="rangeThreshold">
      <div class="thresholdColor thresholdGreen">
        <div class="minValue">
          {{ minValue }}
        </div>
      </div>
      <div class="thresholdColor thresholdYellow">
        <NumberItem
          class="thresholdLeft"
          :value="getFirstThreshold"
          :minValue="minValue"
          :maxValue="getSecondThreshold"
          :reverseOrder="minValue > getSecondThreshold"
          :name="this.name"
          :thresholdIndex="1"
        >
        </NumberItem>
        <NumberItem
          class="thresholdRight"
          :value="getSecondThreshold"
          :minValue="getFirstThreshold"
          :maxValue="maxValue"
          :reverseOrder="getFirstThreshold > maxValue"
          :name="this.name"
          :thresholdIndex="2"
        >
        </NumberItem>
      </div>
      <div class="thresholdColor thresholdRed">
        <div class="maxValue">
          {{ maxValue }}
        </div>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</template>
<script>

import NumberItem from './NumberItem.vue';

export default {
  name: 'RangeThresholdItem',
  components: {
    NumberItem,
  },
  props: ['name', 'unit', 'threshold'],
  computed: {
    getFirstThreshold() {
      return parseInt(this.threshold[1].value);
    },
    getSecondThreshold() {
      return parseInt(this.threshold[2].value);
    },
    minValue() {
      return parseInt(this.threshold[0].value);
    },
    maxValue() {
      return parseInt(this.threshold[3].value);
    }
  }

};
</script>
