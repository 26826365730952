import FeatureType from '../../constants/featureType';
import FeatureUtils from '../../utils/FeatureUtils';
import ThemeElements from '../../constants/theme-elements';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import {i18n} from '../../internationalization/index';

export default {

  id: FeatureType.TCA,
  name: 'features.tca',
  icon: 'tca',
  iconExtension: 'png',
  staticDataType: [FeatureType.AIRSPACE],
  isViewPortBased: false,
  isFlightLevelBased: false,
  maxNrOfFeaturesPerLocation: 30,
  getReplaceableMessages: FeatureUtils.Filters.filerByTca,
  featureIdentifier: 'id',
  maxResolution: 10000,
  declutter: false,
  zIndex: 1,
  flightLevel: null,
  theme: {
    elements: [
      ThemeElements.SHOW_TEXT,
      ThemeElements.BORDER_COLOR,
      ThemeElements.FILL_COLOR,
      ThemeElements.TEXT_COLOR,
      ThemeElements.TEXT_SIZE,
      ThemeElements.TEXT_BG_COLOR,
      ThemeElements.IMAGE_COLOR,
      ThemeElements.ICON_SIZE,
    ]
  },
  displayOnMap: true,
  displayInMessages: true,

  geoJsonMapper: (feature) => {
    let geometry = null;

    if (feature.center) {
      geometry = new Point([feature.center.lon, feature.center.lat]);
    }

    return new Feature({
      geometry: geometry,
      id: feature.id,
      featureType: 'tca',
      station: feature.station,
      text: i18n.global.t('features.tca')
    });
  },

  color: (colors) => {
    return colors.imageColor;
  },

  defaultStyle: () => {
    return {
      showText: true,
      textSize: 14,
      iconSize: 10,
      colors: {
        borderColor: {r: 220, g: 160, b: 60, a: 1},
        fillColor: {r: 240, g: 228, b: 255, a: 0.1},
        textColor: {r: 255, g: 255, b: 255, a: 1},
        textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
        imageColor: {r: 220, g: 160, b: 60, a: 1}
      }
    }
  },

  getMinifiedObject(feature, minifiedObject) {
    return {
      ...minifiedObject,
      cycloneName: feature.cycloneName,
    }
  },

}