import AppConfig from '../config/appConfig';
import A from '../constants/actions';
import DialogUtils from '../utils/DialogUtils';
import Store from '../store';
import AjaxUtils from '../utils/AjaxUtils';
import LoggerUtils from "../utils/LoggerUtils";
import PopupUtils from "../utils/PopupUtils";
import {i18n} from '../internationalization/index';

const getAllDocumentsServiceURL = AppConfig.server.serviceUrlRoot + '/file/_all';
const documentServiceURL = AppConfig.server.serviceUrlRoot + '/getPdfFile';
const uploadFileUrl = AppConfig.server.serviceUrlRoot + '/uploadDocument';
const deleteFileUrl = AppConfig.server.serviceUrlRoot + '/deleteDocument';

function getAllDocuments() {
  fetch(getAllDocumentsServiceURL, {
    method: 'get',
    headers: AjaxUtils.getHeaders(),
  })
    .then(resp => {
      if (resp.status !== 200) {
        throw i18n.global.t('errorMessages.retrieveDocumentError');
      } else {
        resp.json().then(data => Store.dispatch(A.DOCUMENTS_PANEL_UPDATE_DOCUMENTS, data));
      }
    })
    .catch(error => DialogUtils.errorNotification(error));
}

function uploadDocument(documentFile) {
  const formData = new FormData();
  formData.append('file', documentFile);
  return fetch(
    uploadFileUrl,
    {
      method: 'post',
      headers: {Authorization: AjaxUtils.getAuthorization()},
      body: formData
    }).then(response => {
    if (response.status === 200) {
      PopupUtils.success(i18n.global.t('logMessages.documentUploadSuccess'));
      Store.dispatch(A.DOCUMENTS_PANEL_ADD_DOCUMENT, documentFile.name);
    } else {
      handleError(i18n.global.t('errorMessages.documentUploadError', {error: response.statusText}));
    }
  })
    .catch(error => handleError(i18n.global.t('errorMessages.documentUploadError', {error})));
}

function deleteDocument(documentName) {
  return fetch(
    deleteFileUrl + `?fileName=${documentName}`,
    {
      method: 'delete',
      headers: {Authorization: AjaxUtils.getAuthorization()}
    }).then(response => {
    if (response.status === 200) {
      PopupUtils.success(i18n.global.t('logMessages.documentDeleteSuccess'));
      Store.dispatch(A.DOCUMENTS_PANEL_DELETE_DOCUMENT, documentName);
    } else {
      handleError(i18n.global.t('errorMessages.documentDeleteError', {error: response.statusText}));
    }
  })
    .catch(error => handleError(i18n.global.t('errorMessages.documentDeleteError', {error})));
}

function handleError(errorMessage) {
  LoggerUtils.error(errorMessage);
  DialogUtils.errorNotification(errorMessage);
}

export default {
  getAllDocuments,
  uploadDocument,
  deleteDocument,
  documentServiceURL,
};
