<template>
  <div class="container-fluid p-3 w-75">
    <div class="table-row header">
      <div class="text" v-for="header in headers" :key="header.name">{{ header.name }}</div>
    </div>
    <div class="rowContainer">
      <div
        class="table-row"
        v-for="(userConnections, index) in getUserConnections" :key="index">
        <div
          class="text"
          v-for="(field, fieldIndex) in userConnections" :key="fieldIndex"
        >
          {{ field }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UsersConnection',
  computed: {
    headers() {
      return [
        {name: this.$i18n.t('labels.common.user')},
        {name: this.$i18n.t('labels.configurationPanel.groupAndRole.nrOfSessions')},
        {name: this.$i18n.t('features.responsibilityArea')}
      ];
    },
    getUserConnections() {
      let users = {};
      const userSession = this.$store.state.userStore.userConnections;
      userSession.sort(function (a, b) {
        if (a.userName < b.userName) {
          return -1;
        }
        if (a.userName > b.userName) {
          return 1;
        }
        return 0;
      });
      userSession.forEach(function (user) {
        {
          users[user.userName] = (users[user.userName] || 0) + 1;
        }
      });
      const uniqueUsers = userSession.filter(function (value, index, array) {
        return (index === 0) || (value.userName !== array[index - 1].userName);
      });
      return uniqueUsers.map(user => {
        return {name: user.userName, nrConnection: users[user.userName], responsibilityArea: user.locationNames}
      });
    },
  },
}
</script>