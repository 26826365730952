import A from '../../constants/actions';
import M from '../../constants/mutations';
import Vue from 'vue';
import AppConfig from '../../config/appConfig';
import UserService from '../../services/smartSisUserService';
import PermissionUtils from '../../utils/PermissionUtils';
import DronesMessageProcessor from "../../components/websocket/DroneMessageProcessor";
import SortingOrderConfig from "../../config/sortingOrderConfig";

const ViewReference = function (viewId, defaultView) {
  this.viewId = viewId;
  this.defaultView = defaultView;
};

const ThemeReference = function (themeId, defaultTheme) {
  this.themeId = themeId;
  this.defaultTheme = defaultTheme;
};

const defaultGroup = {
  id: '0',
  name: 'Default'
};

const sortFunction = (groupA, groupB) => {
  return (groupA.name === groupB.name) ? 0 : (groupA.name > groupB.name) ? 1 : -1;
};

const state = {

  authInstance: null,
  userProfile: null,
  providerId: 'unknown',
  isReadOnlyUser: false,

  activeGroupRole: null,

  allUsers: [],
  showUserPanel: false,
  currentGroup: null,
  originalGroup: null,
  currentRole: null,
  originalRole: null,
  groupAndRole: null,
  userConnections: [],
  groupRepresentatives: [],
  groupRoleRepresentatives: [],
  enabledDisplayOfRoleEditor: false,
  userSortingConfigs: null,

};

const actions = {

  [A.AUTH_UPDATE_TOKEN]({commit}, authInstance) {
    commit(M.AUTH_UPDATE_TOKEN, authInstance);
  },

  [A.AUTH_LOGOUT]({state}) {
    state.authInstance.logout();
  },

  [A.USER_MANAGEMENT_SELECT_GROUP]({commit}, group) {
    commit(M.USER_MANAGEMENT_SELECT_GROUP, group);
    DronesMessageProcessor.updateDroneMapLabels();
  },

  [A.USER_MANAGEMENT_SELECT_ROLE]({commit}, role) {
    commit(M.USER_MANAGEMENT_SELECT_ROLE, role);
  },

  [A.USER_MANAGEMENT_RESET_ROLE]({commit}) {
    commit(M.USER_MANAGEMENT_RESET_ROLE);
  },

  [A.USER_MANAGEMENT_UPDATE_GROUP_NAME]({commit}, groupName) {
    commit(M.USER_MANAGEMENT_UPDATE_GROUP_NAME, groupName);
  },

  [A.USER_MANAGEMENT_UPDATE_UOM]({commit}, uom) {
    commit(M.USER_MANAGEMENT_UPDATE_UOM, uom);
  },

  [A.USER_MANAGEMENT_UPDATE_ROLE_NAME]({commit}, roleName) {
    commit(M.USER_MANAGEMENT_UPDATE_ROLE_NAME, roleName);
  },

  [A.USER_MANAGEMENT_ROLE_TOGGLE_VIEW]({commit}, viewId) {
    commit(M.USER_MANAGEMENT_ROLE_TOGGLE_VIEW, viewId);
  },

  [A.USER_MANAGEMENT_ROLE_TOGGLE_VIEW_DEFAULT]({commit}, viewId) {
    commit(M.USER_MANAGEMENT_ROLE_TOGGLE_VIEW_DEFAULT, viewId);
  },

  [A.USER_MANAGEMENT_GROUP_TOGGLE_THEME]({commit}, themeId) {
    commit(M.USER_MANAGEMENT_GROUP_TOGGLE_THEME, themeId);
  },

  [A.USER_MANAGEMENT_GROUP_TOGGLE_THEME_DEFAULT]({commit}, themeId) {
    commit(M.USER_MANAGEMENT_GROUP_TOGGLE_THEME_DEFAULT, themeId);
  },

  [A.USER_MANAGEMENT_GROUP_CANCEL_EDIT]({commit}) {
    commit(M.USER_MANAGEMENT_GROUP_CANCEL_EDIT);
  },

  [A.USER_MANAGEMENT_SAVE_GROUP]({commit}, group) {
    commit(M.USER_MANAGEMENT_SAVE_GROUP, group);
  },

  [A.USER_MANAGEMENT_GROUP_REPRESENTATIVES_READ_ALL]({commit}, groups) {
    commit(M.USER_MANAGEMENT_GROUP_REPRESENTATIVES_READ_ALL, groups);
  },

  [A.USER_MANAGEMENT_GROUPS_READ_DEFAULT_GROUP_ROLE]({commit}, groupAndRole) {
    commit(M.USER_MANAGEMENT_GROUPS_READ_DEFAULT_GROUP_ROLE, groupAndRole);
  },

  [A.USER_MANAGEMENT_USER_CONNECTIONS_READ_ALL]({commit}, connections) {
    commit(M.USER_MANAGEMENT_USER_CONNECTIONS_READ_ALL, connections);
  },

  [A.AUTH_SET_USER_PROFILE]({commit}, profile) {
    commit(M.AUTH_SET_USER_PROFILE, profile);
  },

  [A.USER_MANAGEMENT_SELECT_ACTIVE_GROUP_ROLE]({commit, dispatch}, role) {
    commit(M.USER_MANAGEMENT_SELECT_ACTIVE_GROUP_ROLE, role);
    let viewSelectPromise = dispatch(A.VIEW_SELECT_VIEW, PermissionUtils.getDefaultViewIdForUser());
    let themeSelectPromise = dispatch(A.THEME_SELECT_THEME, PermissionUtils.getDefaultThemeIdForUser());
    DronesMessageProcessor.updateDroneMapLabels();
    return Promise.all([viewSelectPromise, themeSelectPromise]);
  },

  [A.USER_MANAGEMENT_TOGGLE]({commit}, config) {
    commit(M.USER_MANAGEMENT_TOGGLE, config);
  },

  [A.USER_MANAGEMENT_DISABLE_DISPLAY_OF_ROLE_EDITOR]({commit}) {
    commit(M.USER_MANAGEMENT_DISABLE_DISPLAY_OF_ROLE_EDITOR);
  },

  [A.USER_MANAGEMENT_ENABLE_DISPLAY_OF_ROLE_EDITOR]({commit}) {
    commit(M.USER_MANAGEMENT_ENABLE_DISPLAY_OF_ROLE_EDITOR);
  },

  [A.USER_MANAGEMENT_GROUP_ROLE_REPRESENTATIVES_READ_ALL]({commit}, groupRoleRepresentatives) {
    commit(M.USER_MANAGEMENT_GROUP_ROLE_REPRESENTATIVES_READ_ALL, groupRoleRepresentatives);
  },

  [A.USER_ADD_USER_TOGGLE]({commit}, showAddUserPanel) {
    commit(M.USER_ADD_USER_TOGGLE, showAddUserPanel);
  },

  [A.USER_READ_ALL_USERS]({commit}, users) {
    commit(M.USER_READ_ALL_USERS, users);
  },

  [A.USER_ADD_USER_TO_CURRENT_GROUP]({commit}, user) {
    commit(M.USER_ADD_USER_TO_CURRENT_GROUP, user);
  },

  [A.USER_REMOVE_USER_FROM_CURRENT_GROUP]({commit}, user) {
    commit(M.USER_REMOVE_USER_FROM_CURRENT_GROUP, user);
  },

  [A.USER_MANAGEMENT_SORTING_CONFIG_READ]({commit}, sortingConfig) {
    commit(M.USER_MANAGEMENT_SORTING_CONFIG_READ, sortingConfig)
  },

  [A.USER_MANAGEMENT_SORTING_CONFIG_UPDATE]({commit}, config) {
    commit(M.USER_MANAGEMENT_SORTING_CONFIG_UPDATE, config);
  }
};

const mutations = {

  [M.AUTH_UPDATE_TOKEN](state, authInstance) {
    state.authInstance = authInstance;
  },

  [M.USER_MANAGEMENT_SELECT_GROUP](state, group) {
    state.currentGroup = JSON.parse(JSON.stringify(group));
    state.originalGroup = JSON.parse(JSON.stringify(group));
  },

  [M.USER_MANAGEMENT_SELECT_ROLE](state, role) {
    state.currentRole = JSON.parse(JSON.stringify(role));
    state.originalRole = JSON.parse(JSON.stringify(state.currentRole));
  },

  [M.USER_MANAGEMENT_RESET_ROLE](state) {
    state.currentRole = JSON.parse(JSON.stringify(state.originalRole));
    if (state.currentRole) {
      const groupRoleIndex = state.currentGroup.roles.findIndex(r => r.id === state.currentRole.id);
      if (groupRoleIndex > -1) {
        state.currentGroup.roles.splice(groupRoleIndex, 1, JSON.parse(JSON.stringify(state.originalRole)));
      }
    }
  },

  [M.USER_MANAGEMENT_UPDATE_GROUP_NAME](state, groupName) {
    state.currentGroup.name = groupName;
  },

  [M.USER_MANAGEMENT_UPDATE_UOM](state, uom) {
    state.currentGroup.altitudeUom = uom;
  },

  [M.USER_MANAGEMENT_UPDATE_ROLE_NAME](state, roleName) {
    state.currentRole.name = roleName;
    let role = state.currentGroup.roles.filter(r => r.id === state.currentRole.id)[0];
    role.name = state.currentRole.name;
  },

  [M.USER_MANAGEMENT_REMOVE_VIEW_FROM_ATTACHED_ROLES](state, viewId) {
    if (state.currentGroup) {
      state.currentGroup.roles.forEach(role => {
        const index = role.views.findIndex(vr => vr.viewId === viewId);
        if (index > -1) {
          role.views.splice(index, 1);
        }
      });
    }
  },

  [M.USER_MANAGEMENT_ROLE_TOGGLE_VIEW](state, viewId) {
    const index = state.currentRole.views.findIndex(vr => vr.viewId === viewId);
    if (index > -1) {
      state.currentRole.views.splice(index, 1);
    } else {
      state.currentRole.views.push(new ViewReference(viewId, false));
    }

    state.currentGroup.roles.forEach(role => {
      if (role.id === state.currentRole.id) {
        const index = role.views.findIndex(vr => vr.viewId === viewId);
        if (index > -1) {
          role.views.splice(index, 1);
        } else {
          role.views.push(new ViewReference(viewId, false));
        }
      }
    });
  },

  [M.USER_MANAGEMENT_ROLE_TOGGLE_VIEW_DEFAULT](state, viewId) {
    let index = state.currentRole.views.findIndex(vr => vr.viewId === viewId);
    state.currentRole.views[index].defaultView = !state.currentRole.views[index].defaultView;
    if (state.currentRole.views[index].defaultView) {
      state.currentRole.views.forEach(vr => {
        if (vr.viewId !== viewId) {
          Vue.set(vr, 'defaultView', false);
        }
      });
    }
    const currentRoleId = state.currentRole.id;
    const currentRoleIdIndex = state.currentGroup.roles.findIndex(r => r.id === currentRoleId);
    const role = state.currentGroup.roles[currentRoleIdIndex];
    index = role.views.findIndex(vr => vr.viewId === viewId);
    role.views[index].defaultView = !role.views[index].defaultView;
    if (role.views[index].defaultView) {
      role.views.forEach(vr => {
        if (vr.viewId !== viewId) {
          Vue.set(vr, 'defaultView', false);
        }
      });
    }
  },

  [M.USER_MANAGEMENT_GROUP_TOGGLE_THEME](state, themeId) {
    const index = state.currentGroup.themes.findIndex(tr => tr.themeId === themeId);
    if (index > -1) {
      state.currentGroup.themes.splice(index, 1);
    } else {
      state.currentGroup.themes.push(new ThemeReference(themeId, false));
    }
  },

  [M.USER_MANAGEMENT_GROUP_TOGGLE_THEME_DEFAULT](state, themeId) {
    const index = state.currentGroup.themes.findIndex(tr => tr.themeId === themeId);
    state.currentGroup.themes[index].defaultTheme = !state.currentGroup.themes[index].defaultTheme;
    if (state.currentGroup.themes[index].defaultTheme) {
      state.currentGroup.themes.forEach(tr => {
        if (tr.themeId !== themeId) {
          Vue.set(tr, 'defaultTheme', false);
        }
      });
    }
  },

  [M.USER_MANAGEMENT_GROUP_CANCEL_EDIT](state) {
    if (state.currentGroup) {
      state.currentGroup = JSON.parse(JSON.stringify(state.originalGroup));
    }
  },

  [M.USER_MANAGEMENT_SAVE_GROUP](state, updatedGroup) {
    state.currentGroup = updatedGroup;
    state.originalGroup = JSON.parse(JSON.stringify(state.currentGroup));
    state.groupRepresentatives.sort(sortFunction);
    if (state.currentGroup.id === state.activeGroupRole?.group?.id) {
      state.activeGroupRole.group = JSON.parse(JSON.stringify(state.currentGroup));
    }
  },

  [M.USER_MANAGEMENT_GROUP_REPRESENTATIVES_READ_ALL](state, groups) {
    state.currentGroup = null;
    state.originalGroup = null;
    state.currentRole = null;
    state.originalRole = null;

    groups.sort(sortFunction);
    state.groupRepresentatives = groups;

    if (state.groupRepresentatives.length === 0) {
      state.groupRepresentatives.push(defaultGroup);
    }
  },

  [M.USER_MANAGEMENT_GROUPS_READ_DEFAULT_GROUP_ROLE](state, groupAndRole) {
    state.groupAndRole = groupAndRole;
  },

  [M.USER_MANAGEMENT_USER_CONNECTIONS_READ_ALL](state, connections) {
    state.userConnections = connections;
  },

  [M.AUTH_SET_USER_PROFILE](state, profile) {
    state.providerId = profile.attributes.providerId ? profile.attributes.providerId[0] : 'unknown';
    state.isReadOnlyUser = profile.attributes.readOnlyUser ? profile.attributes.readOnlyUser[0] === 'true' : false;
    AppConfig.providerId = state.providerId;
    state.userProfile = profile;
  },

  [M.USER_MANAGEMENT_SELECT_ACTIVE_GROUP_ROLE](state, groupRole) {
    state.activeGroupRole = groupRole;
  },

  [M.USER_MANAGEMENT_TOGGLE](state, config) {
    state[config.type] = config.value;
  },

  [M.USER_MANAGEMENT_DISABLE_DISPLAY_OF_ROLE_EDITOR](state) {
    state.enabledDisplayOfRoleEditor = false;
  },

  [M.USER_MANAGEMENT_ENABLE_DISPLAY_OF_ROLE_EDITOR](state) {
    state.enabledDisplayOfRoleEditor = true;
  },

  [M.USER_MANAGEMENT_GROUP_ROLE_REPRESENTATIVES_READ_ALL](state, groupRoleRepresentatives) {
    state.groupRoleRepresentatives = groupRoleRepresentatives;
  },

  [M.USER_ADD_USER_TOGGLE](state, showAddUserPanel) {
    state.showUserPanel = showAddUserPanel;
  },

  [M.USER_READ_ALL_USERS](state, users) {
    state.allUsers = users;
  },

  [M.USER_ADD_USER_TO_CURRENT_GROUP](state, user) {
    state.currentGroup.users.push(user);
  },

  [M.USER_REMOVE_USER_FROM_CURRENT_GROUP](state, user) {
    state.currentGroup.users = state.currentGroup.users.filter(u => u.id !== user.id);
  },

  [M.USER_MANAGEMENT_SORTING_CONFIG_READ](state, sortingConfig) {
    state.userSortingConfigs = sortingConfig;
  },

  [M.USER_MANAGEMENT_SORTING_CONFIG_UPDATE](state, config) {
    const index = state.userSortingConfigs.findIndex(conf => conf.featureType === config.featureType);
    if (state.userSortingConfigs[index].sortingBy === config.property) {
      const currentOrder = SortingOrderConfig.isDescOrder(state.userSortingConfigs[index].sortingOrder);
      state.userSortingConfigs[index].sortingOrder = SortingOrderConfig.getSortingOrder(!currentOrder);
    } else {
      state.userSortingConfigs[index].sortingBy = config.property;
      state.userSortingConfigs[index].sortingOrder = SortingOrderConfig.SORTING_ORDER_CONFIG.ASC;
    }
  },
};

const getters = {
  providerId(state) {
    return state.providerId;
  },

  getUserGroupRoleOrDefault: (state) => (config) => {
    const groupAndRole = state.groupAndRole || getFirstAvailableGroupWithAssignedRoles(state);
    if (groupAndRole.groupId && groupAndRole.roleId) {
      UserService.getGroupDetails({
        groupId: groupAndRole.groupId,
        roleId: groupAndRole.roleId,
        onSuccess: (data) => config.onSuccess(data)
      });
    } else {
      config.onSuccess({});
    }
  },

  showUserPanel(state) {
    return state.showUserPanel;
  },

  getAllUsers(state) {
    return state.allUsers;
  },

  getUsersForCurrentGroup(state) {
    return state.currentGroup.users;
  },

  getCurrentGroupId(state) {
    return state.currentGroup.id;
  },

  getCurrentGroupUom(state) {
    return state?.currentGroup?.altitudeUom;
  },

  getActiveGroupUom(state) {
    if (state?.currentGroup?.id === state?.activeGroupRole?.group.id) {
      return state?.currentGroup?.altitudeUom;
    }
    return state?.activeGroupRole?.group?.altitudeUom;
  },

  getUserSortingConfigForFeatureType: (state) => (featureType) => {
    return state.userSortingConfigs.find(config => config.featureType === featureType);
  },

  getUserSortingConfigs: (state) => {
    return state.userSortingConfigs;
  },
};

function getFirstAvailableGroupWithAssignedRoles(state) {
  const groupRole = state.groupRoleRepresentatives
    .find(groupRoleRepresentative => groupRoleRepresentative.roles.length > 0);
  return groupRole ? {groupId: groupRole.groupId, roleId: groupRole.roles[0].id} : {}
}

export default {
  state,
  actions,
  mutations,
  getters,
};