<template>
  <a id="revoke-op-button" @click="revokeOperationPlan()">
    <div>
          <span class="button-icon">
            <img class="infoPanelButton" v-bind:src="getImg('simplified_reject')"
                 :alt="$t('labels.operationPlan.rejectButton')">
          </span>
    </div>
    <span class="button-text">{{ $t('labels.common.revoke') }}</span>
  </a>
</template>

<script>
import PermissionUtils from "../../../../utils/PermissionUtils";
import OpRevokeDialogUtils from "../../../../utils/dronePlans/OpRevokeDialogUtils";

export default {
  name: "RevokeOpButton",
  props: ['message', 'getImg', 'isDisabled'],
  methods: {
    revokeOperationPlan() {
      PermissionUtils.performActionIfAllowed(this.isDisabled, this.openRevokeDialog);
    },
    openRevokeDialog() {
      OpRevokeDialogUtils.open(this.message, this.$i18n.t('confirmationMessages.dronePlanRevoke'));
    },
  }
}
</script>