<template>
  <div id="controls-action-buttons-container" class="controls-action-buttons-container">
    <FavoriteButton :getImg="getButtonImg" :getText="$t('labels.operationPlan.favorite')" :message="message"
                    :name="$t('labels.operationPlan.favoriteButton')"/>
    <ApproveOpButton :getImg="getSimplifiedAuthorizeButtonImg" :message="message"/>
    <RejectRevokeEndOpButton :message="message"/>
    <TakeOffRequestButton :get-img="getButtonImg" :get-text="$t('labels.operationPlan.requestActivation')"
                          :message="message"/>
    <DroneAlertButton :getImg="getButtonImg" :getText="$t('labels.droneAlert.sendMessage')"
                      :operationPlanId="operationPlanId"/>
  </div>
</template>

<script>

import FavoriteButton from "../../controls/drone-plan-controls/FavoriteButton";
import ApproveOpButton from "../../controls/drone-plan-controls/ApproveOpButton";
import RejectRevokeEndOpButton from "../../controls/drone-plan-controls/RejectRevokeEndOpButton";
import TakeOffRequestButton from "../../controls/drone-plan-controls/TakeOffRequestButton";
import DroneAlertButton from "../../controls/drone-plan-controls/DroneAlertButton";
import ImgUtils from "../../../../utils/ImgUtils";

export default {
  name: 'OperationPlanStateControls',
  props: ['message'],
  components: {
    FavoriteButton,
    ApproveOpButton,
    RejectRevokeEndOpButton,
    TakeOffRequestButton,
    DroneAlertButton,
  },
  computed: {
    operationPlanId() {
      return this.message?.id;
    }
  },
  methods: {
    getSimplifiedAuthorizeButtonImg() {
      return this.getButtonImg('simplified_approve', 'svg');
    },
    getButtonImg(name) {
      return ImgUtils.getButtonImg(name, 'svg');
    },
  }
}
</script>
<style src="../controls/controls.css"></style>