export default {

  PROPOSED: {
    state: 'PROPOSED',
    abbreviation: 'labels.operationPlan.pend',
    displayValue: 'labels.operationPlan.pendingApproval',
    assocFeatureType: 'operationplan_proposed'
  },

  AUTHORIZED: {
    state: 'AUTHORIZED',
    abbreviation: 'labels.operationPlan.author',
    assocFeatureType: 'operationplan_accepted',
    displayValue: 'labels.operationPlan.authorized',
  },

  ACTIVATED: {
    state: 'ACTIVATED',
    abbreviation: 'labels.operationPlan.activ',
    assocFeatureType: 'operationplan_activated',
    displayValue: 'labels.operationPlan.activated',
  },

  CLOSED: {
    state: 'CLOSED',
    abbreviation: 'labels.operationPlan.clos',
    assocFeatureType: 'operationplan_activated'
  },

  NONCONFORMING: {
    state: 'NONCONFORMING',
    abbreviation: 'labels.operationPlan.nonconforming',
    assocFeatureType: 'operationplan_activated'
  },

  ROGUE: {
    state: 'ROGUE',
    abbreviation: 'labels.operationPlan.rogue',
    assocFeatureType: 'operationplan_activated'
  },

  TAKEOFFREQUESTED: {
    state: 'TAKEOFFREQUESTED',
    abbreviation: 'labels.operationPlan.pendAct',
    displayValue: 'labels.operationPlan.pendingActivation',
    assocFeatureType: 'operationplan_accepted'
  },

  ACKNOWLEDGED: {
    state: 'ACKNOWLEDGED',
    abbreviation: 'labels.operationPlan.acknowledged',
    assocFeatureType: 'operationplan_proposed'
  },

  NO_SERVICE: {
    state: 'NO_SERVICE',
    abbreviation: 'labels.operationPlan.noServ',
    assocFeatureType: 'operationplan_proposed'
  },

  INACTIVETWR: {
    state: 'INACTIVETWR',
    abbreviation: 'labels.operationPlan.inactTwr',
    assocFeatureType: 'operationplan_proposed'
  },

  NONE: {
    state: 'NONE',
    abbreviation: 'labels.operationPlan.none',
    assocFeatureType: 'operationplan_proposed'
  },
}