<template>
  <div id="connectionLostLayer" v-if="this.isNotConnected()">
    <h3 id="connectionLostText">
      {{ $t('logMessages.connectionLost') }}...
    </h3>
  </div>
</template>
<script>

import A from '../../constants/actions';
import Logger from '../../utils/LoggerUtils';
import WSMessageProcessor from './MessageProcessor';
import WebsocketSubscriptionService from "../../services/websocketSubscriptionService";

export default {
  name: 'WebSocket',

  methods: {

    isNotConnected() {
      return !this.$store.state.webSocketStore.isConnected;
    },

    connect() {
      this.$store.dispatch(A.WEBSOCKET_CONNECT,
        {
          onMessage: this.onMessage,
          onError: this.onError,
          onClose: this.onClose,
          onOpen: this.onOpen,
        });
    },

    onOpen() {
      this.$store.dispatch(A.WEBSOCKET_CONNECTED);
      Logger.info(this.$i18n.t('logMessages.wsConnected') + '...');
      this.setConfig();
    },

    onClose() {
      Logger.info(this.$i18n.t('logMessages.wsDisconnected') + '...');
      this.$store.dispatch(A.WEBSOCKET_CLOSED);
      setTimeout(this.connect, 500);
    },

    onError(evt) {
      console.log(this.$i18n.t('labels.common.error'), evt);
    },

    onMessage(evt) {
      const message = evt.data.split('|');
      const operation = message.shift();
      const payload = message.join('|');

      WSMessageProcessor[operation](payload);
    },

    setConfig() {
      if (this.$store.state.userStore.userProfile) {
        WebsocketSubscriptionService.websocketSubscribe(this.$store);
      }
    },
  },

  mounted: function () {
    this.connect();
  },

};
</script>

<style src="./websocket.css"></style>
