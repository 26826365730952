<template>
  <div v-if="isOpen" id="revoke-dialog-container" class="dialogContainer">
    <div class="dialog modal" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div id="revoke-dialog-modal-content" class="modal-content smartSISPanel">
          <div id="revoke-dialog-modal-header" class="modal-header">
            <h5 id="revoke-dialog-modal-title" class="modal-title">
              {{ $t('labels.operationPlan.confirmRevokingOperation') }}</h5>
          </div>
          <div id="revoke-dialog-modal-body" class="modal-body errorBody">
            <span class="fixedSizeTitle">
              <strong>
                 {{ $t('labels.operationPlan.revokeReason') }}
              </strong>
            </span>
            <PropertyInput prop-name="free-text-revoke-reason"
                           :get-value="() => revokeMessage"
                           :set-value="setRevokeMessage"
                           :is-valid="isCurrentRevokeMessageValid"
                           inputClass="revokeMessageInput"
                           id="free-text-revoke-reason"
            />
            <p v-html="message"></p>
          </div>
          <div id="revoke-dialog-modal-footer" class="modal-footer">
            <div>
              <button id="op-revoke-send-button"
                      class="btn btn-danger"
                      :disabled="!this.isCurrentRevokeMessageValid"
                      @click="doRevoke">
                {{ $t('labels.common.revoke') }}
              </button>
              <button id="op-revoke-cancel-button"
                      class="btn btn-primary"
                      @click="cancelRevokeAction">
                {{ $t('labels.common.cancel') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import PropertyInput from '../../common/PropertyInput';
import {mapGetters} from 'vuex';
import OpRevokeDialogUtils from "../../utils/dronePlans/OpRevokeDialogUtils";

export default {
  name: "OpRevokeDialog",
  components: {
    PropertyInput
  },
  computed: {
    ...mapGetters({
      isOpen: 'isOpRevokeDialogOpen',
      message: 'opRevokeDialogMessage',
      operationPlan: 'opRevokeDialogOpInstance'
    }),
    isCurrentRevokeMessageValid() {
      return this.revokeMessage?.length <= 256;
    }
  },
  data() {
    return {
      revokeMessage: ''
    }
  },
  methods: {
    doRevoke() {
      OpRevokeDialogUtils.revokeOperationPlan(this.operationPlan, this.revokeMessage);
      this.revokeMessage = '';
      OpRevokeDialogUtils.close();
    },
    cancelRevokeAction() {
      this.revokeMessage = '';
      OpRevokeDialogUtils.close();
    },
    setRevokeMessage(propName, value) {
      this.revokeMessage = value;
    }
  }
}
</script>

<style src="./dialog.css"/>