import FeatureType from '../constants/featureType';

const altitudeFilteredFeatureTypes = [
  {
    displayValue: 'features.operationPlan.abbreviation',
    modelValue: 'operation_plans'
  },
  {
    displayValue: 'features.uas.abbreviation',
    modelValue: 'geozones'
  },
  {
    displayValue: 'labels.drone.drones',
    modelValue: FeatureType.DRONES
  },
  {
    displayValue: 'features.aircraft',
    modelValue: FeatureType.AIRCRAFT
  },
  {
    displayValue: 'features.laraAirspace',
    modelValue: FeatureType.LARA_AIRSPACE
  },
];

export default {
  altitudeFilteredFeatureTypes,
}