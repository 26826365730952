<template>
  <a id="cancel-op-button" @click="cancelOperationPlan()">
    <div>
          <span class="button-icon">
            <img class="infoPanelButton" v-bind:src="getImg('simplified_reject')"
                 :alt="$t('labels.operationPlan.rejectButton')">
          </span>
    </div>
    <span class="button-text">{{ $t('labels.common.cancel') }}</span>
  </a>
</template>

<script>
import DialogUtils from "../../../../utils/DialogUtils";
import DronePlanUtils from "../../../../utils/dronePlans/DronePlanUtils";
import OperationPlanServices from "../../../../services/operationPlanService";
import A from "../../../../constants/actions";
import Logger from "../../../../utils/LoggerUtils";
import PopupUtils from "../../../../utils/PopupUtils";
import PermissionUtils from "../../../../utils/PermissionUtils";
import {i18n} from "@/internationalization";

export default {
  name: "CancelOpButton",
  props: ['message', 'getImg', 'isDisabled'],
  methods: {
    cancelOperationPlan() {
      PermissionUtils.performActionIfAllowed(this.isDisabled, this.performCancelOperationPlan);
    },
    performCancelOperationPlan() {
      DialogUtils.confirmOperation({
        message: this.$i18n.t('confirmationMessages.dronePlanEnd'),
        yesAction: () => OperationPlanServices.cancelOperationPlan(this.createConfigForCancelOp()),
      });
    },
    createConfigForCancelOp() {
      this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
      const operationPlanId = this.message.id;
      const message = this.$i18n.t('labels.operationPlan.dronePlanEndAction',
        {title: DronePlanUtils.getPublicTitleForPopUp(this.message)}
      );
      return {
        operationPlanId: operationPlanId,
        successCallback: () => {
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          Logger.info(i18n.global.t('popupMessages.messageWasSuccessful', {message}) + ` '${operationPlanId}'.`);
          PopupUtils.success(i18n.global.t('popupMessages.messageWasSuccessful', {message}) + '.');
          this.$store.dispatch(A.REMOVE_SELECT_FEATURE);
        },
        errorCallback: (error) => {
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          Logger.error(i18n.global.t('popupMessages.messageFailed', {message}) + ` '${operationPlanId}'.` + error);
          DialogUtils.errorNotification(i18n.global.t('popupMessages.messageFailed', {message}) + '.');
          this.$store.dispatch(A.REMOVE_SELECT_FEATURE);
        },
      };
    }
  }
}
</script>