<template>
  <div>
    <div v-if="isCswServiceConnected">
      <div v-if="availableLayers.length === 0" class="text-danger">
        {{ $t('errorMessages.gisLayerNotFound') }}
      </div>
      <div v-else>
        <div class="row">
          <div class="searchLabel col-2"><strong>{{ $t('labels.common.search') }}: </strong></div>
          <input
            id="searchInput"
            type="text"
            class="form-control col-8 mb-2"
            @keyup="onChange"
            @change="onChange"
            :value='this.currentFilteringValue'
          >
        </div>
        <div class="card smartSISPanel" v-bind:style="{height: heightSize}">
          <div class="table-row header card-header">
            <div class="col-10">{{ $t('labels.common.name') }}</div>
          </div>
          <div class="rowContainer card-body">
            <div :key="layerConfig.id"
                 class="table-row layersTableRow"
                 v-for="(layerConfig) in filteredAvailableLayers">
              <div class="col-10 cswLayerTitle">
                {{ layerConfig.title }}
              </div>
              <button :id='layerConfig.id + "-save"'
                      class="btn btn-primary col-2"
                      @click="storeLayerToGeoserverLayerCatalog(layerConfig)">
                {{ $t('labels.common.add') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      {{ $t('errorMessages.noCswServiceConnected') }}
    </div>
  </div>
</template>

<script>

import A from '../../constants/actions';
import {mapGetters} from 'vuex';

export default {
  name: "GeoserverCswServiceLayers",
  data() {
    return {
      currentFilteringValue: "",
      filteredAvailableLayers: [],
    }
  },
  watch: {
    currentFilteringValue: {
      immediate: true,
      handler() {
        this.filteredAvailableLayers = this.filterAvailableLayers();
      }
    },
    availableLayers: {
      immediate: true,
      handler() {
        this.filteredAvailableLayers = this.filterAvailableLayers();
      }
    },
    isCswServiceConnected: {
      immediate: true,
      handler() {
        this.currentFilteringValue = '';
      }
    }
  },
  computed: {
    ...mapGetters({
      isCswServiceConnected: 'getIsCswServiceConnected',
      availableLayers: 'getAvailableLayers'
    }),
    heightSize() {
      return (this.$store.state.configurationStore.geoserverLayerConfigPanelHeight - 45) + 'px';
    },
  },
  methods: {
    storeLayerToGeoserverLayerCatalog(layerConfig) {
      this.$store.dispatch(A.LAYERS_CONFIG_OPEN_STORE_LAYER_DIALOG, layerConfig);
    },
    onChange(evt) {
      this.currentFilteringValue = evt.target.value;
    },
    filterAvailableLayers() {
      return this.availableLayers.filter(
        layer => this.layerTitleContainsContent(layer.title, this.currentFilteringValue)
      );
    },
    layerTitleContainsContent(layerTitle, content) {
      return layerTitle.toLowerCase().includes(content.toLowerCase());
    }
  }
}
</script>

<style src="./geoserverLayersConfig.css"/>