<template>
  <div
    id="favorite-button"
    :class="[this.isDisabled ? 'disabled-action-button' : 'action-button']"
  >
    <a @click="toggleFavorite">
      <div>
        <span class="button-icon" v-if="isFavorite"><img class="infoPanelButton"
                                                         v-bind:src="getImg('favorite_full')"></span>
        <span class="button-icon" v-else><img class="infoPanelButton" v-bind:src="getImg('favorite_empty')"></span>
      </div>
      <span class="button-text">{{ getText }}</span>
    </a>
  </div>
</template>
<script>

import A from '../../../../constants/actions';
import FeatureUtils from "../../../../utils/FeatureUtils";
import {mapGetters} from "vuex";

export default {
  name: 'FavoriteButton',
  props: ['message', 'getImg', 'getText'],

  computed: {
    ...mapGetters({
      taskTabIsOpened: 'taskTabIsOpened',
    }),
    isDisabled() {
      return !this.message;
    },
    isFavorite() {
      return this.message && FeatureUtils.Filters.isMessageMarkedAsFavorite(this.message);
    },
    isOperationPlan() {
      return this.message && FeatureUtils.Icons.isOperationPlan(this.message);
    },
  },

  methods: {
    toggleFavorite() {
      if (FeatureUtils.Filters.isMessageMarkedAsFavorite(this.message) && this.taskTabIsOpened) {
        this.$store.dispatch(A.REMOVE_SELECT_FEATURE);
        this.$store.dispatch(A.MAP_DESELECT_ALL_FEATURES);
      }
      this.$store.dispatch(A.INFORMATION_PANEL_TOGGLE_FAVORITE_MESSAGE, this.message);
      this.$store.dispatch(A.MAP_REFRESH);
    },
  },
};
</script>
