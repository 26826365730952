import A from '../constants/actions';
import Store from '../store';
import DialogUtils from '../utils/DialogUtils';
import {getCenter} from 'ol/extent';
import LayerUtils from "../utils/LayerUtils";
import MultiPolygon from "ol/geom/MultiPolygon";
import {i18n} from '../internationalization/index';
import MapUtils from "../utils/MapUtils";

function flyToAndSelect(message) {
  const mapLayerSourceForMessage = MapUtils.getMapLayerSourceById(message.featureType);
  let location;
  if (LayerUtils.isLayerWithMultipleVolumes(message.featureType)) {
    location = getLocationForMultipleVolumes(message, mapLayerSourceForMessage);
  } else {
    const feature = mapLayerSourceForMessage.getFeatures().find(f => f.getProperties().id === message.id);
    location = feature ? feature.getGeometry() : null;
  }
  if (location) {
    const zoomAndLocation = getZoomInLevelAndLocationForGeometry(location);
    const params = {
      zoomInLevel: zoomAndLocation.zoomInLevel,
      location: zoomAndLocation.location,
      onComplete: () => {
        if (Store.state.informationStore.activeTab || Store.getters.taskTabIsOpened) {
          selectFeature(message);
        }
      }
    };
    Store.dispatch(A.FLY_TO_FEATURE, params);
  } else {
    DialogUtils.errorNotification(i18n.global.t('errorMessages.geometryNotFoundError', {
      featureType: message.featureType,
      messageId: message.id
    }));
    console.error(i18n.global.t('logMessages.missingFeatureGeometry'));
  }
}

function getZoomInLevelAndLocationForGeometry(geometry) {
  let zoomInLevel;
  let location;
  if (geometry.getType() === 'Point') {
    zoomInLevel = 16;
    location = geometry.clone().transform('EPSG:3857', 'EPSG:4326').getCoordinates();
  } else {
    location = getCenter(geometry.clone().transform('EPSG:3857', 'EPSG:4326').getExtent());
    const currentView = Store.getters.getCurrentView;
    let resolutionForExtent = currentView.getResolutionForExtent(geometry.getExtent());
    zoomInLevel = Math.round(currentView.getZoomForResolution(resolutionForExtent)) - 2;
  }
  return {zoomInLevel: zoomInLevel, location: location}
}

function getLocationForMultipleVolumes(message, layerSource) {
  const volumeFeatures = layerSource
    .getFeatures().filter(f => f.getProperties().associatedFeatureId === message.id);
  const multiPolygon = new MultiPolygon([]);
  volumeFeatures.map(f => f.getGeometry()).forEach(polygon => multiPolygon.appendPolygon(polygon))
  return multiPolygon;
}

function selectFeature(message) {
  let messageToFocus = findAndUpdateFeatureSelectionOnMap(message);
  const messageId = message.associatedFeatureId ? message.associatedFeatureId : message?.id;
  const isMessageAlreadySelected = isMessageParentIdSelected(message) || Store.getters.selectedFeatureId === messageId;

  if (isMessageAlreadySelected) {
    Store.dispatch(A.REMOVE_SELECT_FEATURE);
  } else {
    Store.dispatch(A.SELECT_FEATURE, messageToFocus);
  }
}

function isMessageParentIdSelected(message) {
  return message?.parentId && message.parentId === Store.getters.selectedFeatureId;
}

function findAndUpdateFeatureSelectionOnMap(message) {
  const messageId = message.associatedFeatureId ? message.associatedFeatureId : message.id;
  // affected features can be undefined if selected message is not displayed on map
  const affectedFeatures = getAffectedFeatures(message, messageId);
  const previousState = affectedFeatures?.[0]?.getProperties().selected;
  setSelectedStateForFeatures(affectedFeatures, !previousState);
  message = LayerUtils.isLayerWithMultipleVolumes(message.featureType) ?
    Store.getters.getMessageById(messageId) : message;
  return !previousState ? message : null;
}

function getAffectedFeatures(message, messageId) {
  const mapLayerSourceForMessage = MapUtils.getMapLayerSourceById(message.featureType);
  const feature = mapLayerSourceForMessage?.getFeatures()?.find(f => f.getProperties().id === message.id);
  return LayerUtils.isLayerWithMultipleVolumes(message.featureType) ?
    getMapFeaturesById(mapLayerSourceForMessage, messageId) : [feature];
}

function setSelectedStateForFeatures(features, state) {
  Store.dispatch(A.MAP_DESELECT_ALL_FEATURES);
  features?.forEach(f => {
    f?.setProperties({
      selected: state
    });
  });
}

function getMapFeaturesById(mapLayerSource, id) {
  return mapLayerSource?.getFeatures().filter(f => f.getProperties().associatedFeatureId &&
    f.getProperties().associatedFeatureId === id);
}

export default {
  flyToAndSelect,
  selectFeature,
}