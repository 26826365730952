<template>
    <div v-if="isOpen" class="documentsPanel smartSISPanel zIndexTop">
        <DocumentsPanelHeader></DocumentsPanelHeader>
        <DocumentsPanelList></DocumentsPanelList>
    </div>
</template>

<script>

  import DocumentsPanelHeader from './DocumentsPanelHeader.vue';
  import DocumentsPanelList from './DocumentsPanelList.vue';
  import HudTabName from '../../constants/hudTabName';

  export default {
    name: 'DocumentsPanel',

    components: {
      DocumentsPanelHeader,
      DocumentsPanelList,
    },

    computed: {
      isOpen() {
        return this.$store.getters.isGivenTabOpened(HudTabName.DOCUMENTS);
      },
    }

  }
</script>
<style src="./documentsPanel.css"></style>