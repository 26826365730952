import Moment from "moment";

export default class DateTimeUtils {

  static formatTimestampAsTime(timestamp) {
    return Moment.utc(timestamp).format('HH:mm');
  }

  static formatTimestampAsDate(timestamp) {
    return Moment.utc(timestamp).format('YYYY-MM-DD');
  }

  static formatTimestampAsDateAndTime(timestamp) {
    return Moment.utc(timestamp).format('YYYY DD MMM HH:mm');
  }
}