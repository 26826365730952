<template>
  <div class="button-container-for-message btn-group-vertical float-right text-right p-1 m-0">
    <RemoveButton :getImg="getButtonImg" :getText="$t('labels.common.remove')"
                  :message="message" :removable="removable"/>
    <DeleteButton :getImg="getButtonImg" :getText="$t('labels.common.delete')"
                  :message="message" :removable="removable"/>
    <AlertReplyToAlertButton :get-text="$t('labels.alertReply.toAlert')" :getImg="getButtonImg" :message="message"/>
  </div>
</template>

<script>

import DeleteButton from "./controls/DeleteButton";
import RemoveButton from "./controls/RemoveButton";
import ImgUtils from "../../utils/ImgUtils";
import AlertReplyToAlertButton from "./controls/AlertReplyToAlertButton";

export default {
  name: "MessageControlContainer",
  props: ["message", "removable"],
  components: {
    AlertReplyToAlertButton,
    DeleteButton,
    RemoveButton
  },
  methods: {
    getButtonImg(name) {
      return ImgUtils.getButtonImg(name, 'svg');
    }
  }
}
</script>
<style src="./informationPanel.css"></style>