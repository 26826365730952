import {i18n} from '../internationalization/index';

const responsivenessStates = {
  UNKNOWN: {
    displayValue: 'labels.droneFlight.unknown',
    modelValue: 'UNKNOWN'
  },
  RESPONSIVE: {
    displayValue: 'labels.droneFlight.responsive',
    modelValue: 'RESPONSIVE'
  },
  UNRESPONSIVE: {
    displayValue: 'labels.droneFlight.unresponsive',
    modelValue: 'UNRESPONSIVE'
  }
}

const emergencyStates = {
  UNKNOWN: {
    displayValue: 'labels.droneFlight.unknown',
    modelValue: 'UNKNOWN'
  },
  NOMINAL: {
    displayValue: 'labels.droneFlight.nominal',
    modelValue: 'NOMINAL'
  },
  CONTINGENCY: {
    displayValue: 'labels.droneFlight.contingency',
    modelValue: 'CONTINGENCY'
  },
  EMERGENCY: {
    displayValue: 'labels.droneFlight.emergency',
    modelValue: 'EMERGENCY'
  },
}

const flightStates = {
  UNKNOWN: {
    displayValue: 'labels.droneFlight.unknown',
    modelValue: 'UNKNOWN'
  },
  ONGROUND: {
    displayValue: 'labels.droneFlight.onGround',
    modelValue: 'ONGROUND'
  },
  AIRBORNE: {
    displayValue: 'labels.droneFlight.airborne',
    modelValue: 'AIRBORNE'
  },
  FINISHED: {
    displayValue: 'labels.droneFlight.finished',
    modelValue: 'FINISHED'
  },
}

const conformanceStates = {
  UNKNOWN: {
    displayValue: 'labels.droneFlight.unknown',
    modelValue: 'UNKNOWN'
  },
  CONFORMANT: {
    displayValue: 'labels.droneFlight.conformant',
    modelValue: 'CONFORMANT'
  },
  NON_CONFORMANT: {
    displayValue: 'labels.droneFlight.non-conformant',
    modelValue: 'NON_CONFORMANT'
  },
}

const survCooperationStates = {
  COOPERATIVE: {
    displayValue: 'labels.droneFlight.cooperative',
    modelValue: 'COOPERATIVE'
  },
  NON_COOPERATIVE: {
    displayValue: 'labels.droneFlight.nonCooperative',
    modelValue: 'NON_COOPERATIVE'
  }
}

function getDisplayResponsivenessState(responsivenessState) {
  const state = Object.values(responsivenessStates).find(config => config.modelValue === responsivenessState);
  return state ? i18n.global.t(state.displayValue) : '';
}

function getDisplayEmergencyState(emergencyState) {
  const state = Object.values(emergencyStates).find(config => config.modelValue === emergencyState);
  return state ? i18n.global.t(state.displayValue) : '';
}

function getDisplayFlightState(flightState) {
  const state = Object.values(flightStates).find(config => config.modelValue === flightState);
  return state ? i18n.global.t(state.displayValue) : '';
}

function getDisplayConformanceState(conformanceState) {
  const confState = Object.values(conformanceStates).find(config => config.modelValue === conformanceState);
  return confState ? i18n.global.t(confState.displayValue) : '';
}

function getDisplaySurvCooperationState(survCooperationState) {
  const confState = Object.values(survCooperationStates).find(config => config.modelValue === survCooperationState);
  return confState ? i18n.global.t(confState.displayValue) : '';
}

export default {
  emergencyStates,
  conformanceStates,
  flightStates,
  getDisplayFlightState,
  getDisplayEmergencyState,
  getDisplayResponsivenessState,
  getDisplayConformanceState,
  getDisplaySurvCooperationState
}