import A from '../../constants/actions';
import M from '../../constants/mutations';

const state = {
  isOpen: false,
  availableIcaoLocations: []
};

const actions = {
  [A.VIEW_EDITOR_TOGGLE_ICAO_LOCATIONS_DIALOG_CONFIG]({commit}, icaoLocations) {
    commit(M.VIEW_EDITOR_TOGGLE_ICAO_LOCATIONS_DIALOG_CONFIG, icaoLocations);
  },
};

const mutations = {
  [M.VIEW_EDITOR_TOGGLE_ICAO_LOCATIONS_DIALOG_CONFIG](state, icaoLocations = []) {
    state.isOpen = !state.isOpen;
    state.availableIcaoLocations = icaoLocations;
  },
};

const getters = {
  isIcaoLocationsConfigDialogOpen: state => state.isOpen,
  getAvailableIcaoLocations: state => state.availableIcaoLocations
};

export default {
  state,
  actions,
  mutations,
  getters
};