<script>
export default {
  name: "GeozoneConflictItem",
  props: {
    conflict: Object,
  }
}
</script>

<template>
  <div class="conflicting-drone-plan-details m-0">
    <div>
      {{ $t('labels.operationPlan.geozoneId') }}: {{ conflict.objectId }}
    </div>
    <div>
      {{ $t('labels.operationPlan.conflictType') }}: {{ conflict.conflictType }}
    </div>
  </div>
</template>