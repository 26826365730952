<template>
  <div class="op-new-start-time-proposal-form-container">
    <ToggleButton class="op-new-start-time-proposal-form-toggle-button"
                  enabled-class="on"
                  disabled-class="off"
                  :name="$t('labels.operationPlan.proposeNewStartTime')"
                  :enabled="formIsEnabled"
                  :on-click-action="toggleForm"/>
    <div class="op-new-start-time-proposal-form" v-if="formIsEnabled">
      <PropertyDatePicker :title="$t('labels.operationPlan.proposedStartTime')"
                          :get-value="getProposedStartTime"
                          :set-value="setProposedStartTime"
                          :min-datetime="initialOpStartTime"
                          :is-mandatory="true"
                          :is-valid="this.isProposedStartTimeValid()"
                          :format="dateFormat"
                          id="proposed-start-time"/>
      <PropertyDatePicker :title="$t('labels.operationPlan.newEndTime')"
                          :get-value="() => endTime"
                          :set-value="() => {}"
                          :disabled="true"
                          :is-valid="true"
                          :format="dateFormat"
                          id="new-end-time"/>
    </div>
  </div>
</template>

<script>

import ToggleButton from '@/common/ToggleButton';
import PropertyDatePicker from '@/common/PropertyDatePicker';
import OpRejectionDialogUtils from '@/utils/dronePlans/OpRejectionDialogUtils';

export default {
  name: 'OpNewStartTimeProposalForm',
  props: ['operationPlan', 'output'],
  components: {
    ToggleButton,
    PropertyDatePicker
  },
  data() {
    return {
      formIsEnabled: false,
      dateFormat: "yyyy-MM-dd HH:mm",
      proposedStartTime: OpRejectionDialogUtils.getOpStartTime(this.operationPlan),
      endTime: OpRejectionDialogUtils.getOpEndTime(this.operationPlan)
    };
  },
  computed: {
    initialOpStartTime() {
      return OpRejectionDialogUtils.getOpStartTime(this.operationPlan);
    },
    initialOpEndTime() {
      return OpRejectionDialogUtils.getOpEndTime(this.operationPlan);
    }
  },
  methods: {
    toggleForm() {
      this.formIsEnabled = !this.formIsEnabled;
      this.output.newStartTimeIsProposed = this.formIsEnabled;
      if (!this.formIsEnabled) {
        this.output.isValid = true;
      }
    },
    getProposedStartTime() {
      return this.proposedStartTime;
    },
    setProposedStartTime(propName, newValue) {
      this.proposedStartTime = new Date(newValue);
      this.output.proposedTimeOffsetInMinutes =
        OpRejectionDialogUtils.getOffsetInMinutes(this.proposedStartTime, this.initialOpStartTime);
      this.endTime =
        OpRejectionDialogUtils.getOpEndTimeWithOffset(this.initialOpEndTime, this.output.proposedTimeOffsetInMinutes);
    },
    isProposedStartTimeValid() {
      this.output.isValid = this.formIsEnabled ?
        this.proposedStartTime.getTime() > this.initialOpStartTime.getTime() : true;
      return this.output.isValid;
    }
  }
}
</script>

<style src="./opRejectionPropsForm.css"/>
