<template>
  <VueDatePicker
    v-model="date"
    :format="this.format"
    :disabled="disabled"
    :min-date="minDate"
    :min-time="minTime"
    v-bind:clearable="false"
    :dark="!isDayThemeSelected"
    :class="customClass"
    :input-id="id"
    :teleport="true"
    ref="datepicker">
    <template #input-icon>
      <!--Empty to remove icon or place new SVG here -->
    </template>
  </VueDatePicker>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import {mapGetters} from "vuex";

export default {
  props: ['getValue', 'setValue', 'format', 'disabled', 'id', 'customClass', 'minDate', 'minTime'],
  components: {
    VueDatePicker
  },
  computed: {
    ...mapGetters({
      isDayThemeSelected: 'getIsDayThemeSelected',
    }),
    date: {
      get() {
        return this.getValue();
      },
      set(value) {
        this.setValue(value);
      }
    }
  }
}
</script>
<style src="./datePicker.css"/>