<template>
    <div id="information-panel-draggable-border" class="smartSISPanel">
        <div
            id="tap"
            ref="tap"
            :class="['draggable-border' + ((position === 'down' ) ? '-horizontal' : '')].join(' ')"
            :style="getStyle()"
            @mousedown.stop="startDrag"
        >
        </div>
    </div>
</template>

<script>
  import A from '../../constants/actions';

  export default {
    name: 'DraggableBorder',
    props: ['position', 'minSize', 'maxSize', 'bgColor'],
    data: () => {
      return {
        dragging: false,
        initialSize: 400, // dummy value, didn't know how to setup
        previousLeft: 400,
        previousConfig: 500,
      }
    },
    mounted() {
      this.$refs.tap.addEventListener('touchstart', this.startTouchDrag);
    },
    methods: {
      incrementSize(event) {
        var dragEvent = event.targetTouches ? event.targetTouches[0] : event;
        if (this.dragging) {
          let newSize;
          if (this.position === 'right') {
            newSize = this.previousLeft - (Number(dragEvent.clientX) - this.initialSize);
          } else {
            newSize = this.previousConfig - (Number(dragEvent.clientY) - this.initialSize);
          }
          if (newSize > this.minSize && newSize < this.maxSize)
            if (this.position === 'right')
              this.$store.dispatch(A.LEFT_PANEL_WIDTH_CHANGE, newSize);
            else
              this.$store.dispatch(A.CONFIGURATION_PANEL_HEIGHT_CHANGE, newSize);
        }
        this.$store.dispatch(A.MAP_REFRESH);
      },
      startTouchDrag(event) {
        this.initialSize = this.minSize;
        if (this.position === 'right') {
          this.previousLeft = this.$store.state.informationStore.width;
          this.initialSize = event.targetTouches[0].clientX;
        }
        else {
          this.previousConfig = this.$store.state.configurationStore.height;
          this.initialSize = event.targetTouches[0].clientY;
        }
        this.dragging = true;
        document.getElementById('tap').addEventListener('touchmove', this.incrementSize);
        document.getElementById('tap').addEventListener('touchend', this.stopTapDrag);
      },
      stopTapDrag() {
        this.dragging = false;
        document.getElementById('tap').removeEventListener('touchmove', this.incrementSize);
        document.getElementById('tap').removeEventListener('touchend', this.stopTapDrag);
      },
      startDrag(event) {
        this.initialSize = this.minSize;
        if (this.position === 'right') {
          this.previousLeft = this.$store.state.informationStore.width;
          this.initialSize = event.clientX;
        }
        else {
          this.previousConfig = this.$store.state.configurationStore.height;
          this.initialSize = event.clientY;
        }
        this.dragging = true;
        document.getElementById('app').addEventListener('mousemove', this.incrementSize);
        document.getElementById('app').addEventListener('mouseup', this.stopDrag);
      },
      stopDrag() {
        this.dragging = false;
        document.getElementById('app').removeEventListener('mousemove', this.incrementSize);
        document.getElementById('app').removeEventListener('mouseup', this.stopDrag);
      },
      getStyle() {
        return {'background-color': this.bgColor}
      }
    },
  }
</script>
<style src="./draggableBorder.css"></style>