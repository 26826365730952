import A from '../../constants/actions';
import M from '../../constants/mutations';

const state = {
  isOpen: false,
  message: null,
  operationPlan: null
};

const actions = {
  [A.OP_REJECTION_DIALOG_OPEN]({commit}, config) {
    commit(M.OP_REJECTION_DIALOG_OPEN, config);
  },

  [A.OP_REJECTION_DIALOG_CLOSE]({commit}) {
    commit(M.OP_REJECTION_DIALOG_CLOSE);
  }
};

const mutations = {
  [M.OP_REJECTION_DIALOG_OPEN](state, config) {
    state.isOpen = true;
    state.message = config.message;
    state.operationPlan = config.operationPlan;
  },

  [M.OP_REJECTION_DIALOG_CLOSE](state) {
    state.isOpen = false;
    state.message = null;
    state.operationPlan = null;
  }
};

const getters = {
  isOpRejectionDialogOpen: state => state.isOpen,
  opRejectionDialogMessage: state => state.message,
  opRejectionDialogOpInstance: state => state.operationPlan
};

export default {
  state,
  actions,
  mutations,
  getters
};
