const validationConfig = [
  {
    fieldName: 'maxSimultaneousOperations',
    isValid: (config) => {
      return config('maxSimultaneousOperationsEnabled') ?
        isValidNumberValue(config('maxSimultaneousOperations')) : true;
    },
    type: 'value'
  },
  {
    fieldName: 'maxNoiseLevel',
    isValid: (config) => {
      return config('maxNoiseLevelEnabled') ? isValidNumberValue(config('maxNoiseLevel')) : true;
    },
    type: 'value'
  },
  {
    fieldName: 'maximumAltitude',
    isValid: (config) => {
      return isValidNumberValue(config('maximumAltitude')) && isMinAltitudeLowerThanMaxAltitude(config);
    },
    type: 'value'
  },
  {
    fieldName: 'minimumAltitude',
    isValid: (config) => {
      return isValidNumberValue(config('minimumAltitude')) && isMinAltitudeLowerThanMaxAltitude(config);
    },
    type: 'value'
  },
  {
    fieldName: 'automaticApprovalEnabled',
    isValid: (config) => {
      return !config('automaticApprovalEnabled') || config('automaticApprovalEnabled') && !config('automaticRejection');
    },
    type: 'approval'
  },
  {
    fieldName: 'automaticTakeoffApprovalEnabled',
    isValid: (config) => {
      return !config('automaticTakeoffApprovalEnabled') ||
        config('automaticTakeoffApprovalEnabled') && !config('automaticRejection');
    },
    type: 'approval'
  },
  {
    fieldName: 'automaticRejection',
    isValid: (config) => {
      return !config('automaticRejection') ||
        config('automaticRejection') && !config('automaticTakeoffApprovalEnabled') && !config('automaticApprovalEnabled');
    },
    type: 'approval'
  },
]

function getConfigForField(fieldName) {
  return validationConfig.find(field => field.fieldName === fieldName);
}

function isValidNumberValue(value) {
  let isInteger = /^\d+$/.test(value);
  return value !== undefined && value !== null && value >= 0 && isInteger;
}

function isMinAltitudeLowerThanMaxAltitude(config) {
  return Number(config('minimumAltitude')) < Number(config('maximumAltitude'));
}

export default {
  validationConfig,
  getConfigForField
}