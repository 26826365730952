import A from '../../constants/actions';
import M from '../../constants/mutations';
import HudTabName from '../../constants/hudTabName';

const state = {
  openedTab: null,
  health: {
    servicesStateIsOpen: false,
    servicesUpdatedUntil: 0,
    servicesStates: []
  },
  coordinate: {
    lat: '-',
    lon: '-',
  },
};

const actions = {

  [A.HUD_SET_COORDINATE]({commit}, coordinate) {
    commit(M.HUD_SET_COORDINATE, coordinate);
  },

  [A.UPDATE_HEALTH_CHECK]({commit}, componentsHealth) {
    commit(M.UPDATE_HEALTH_CHECK, componentsHealth);
  },

  [A.DISPLAY_SERVICES_STATES]({commit}, health) {
    commit(M.DISPLAY_SERVICES_STATES, health);
  },

  [A.CLOSE_SERVICES_STATES]({commit}, health) {
    commit(M.CLOSE_SERVICES_STATES, health);
  },

  [A.HUD_SET_OPENED_TAB]({commit, getters, dispatch}, openedTabName) {
    if (getters.isGivenTabOpened(HudTabName.CONFIGURATION) && openedTabName !== HudTabName.CONFIGURATION) {
      dispatch(A.CONFIGURATION_PANEL_TOGGLE, openedTabName);
      dispatch(A.REMOVE_SELECT_FEATURE);
    } else {
      commit(M.HUD_SET_OPENED_TAB, openedTabName);
    }
  },

};

const mutations = {

  [M.HUD_SET_COORDINATE](state, coordinate) {
    state.coordinate.lat = coordinate.lat;
    state.coordinate.lon = coordinate.lon;
  },

  [M.UPDATE_HEALTH_CHECK](state, componentsHealth) {
    state.health.servicesStates = componentsHealth;
  },

  [M.DISPLAY_SERVICES_STATES](state) {
    state.health.servicesStateIsOpen = true;
  },

  [M.CLOSE_SERVICES_STATES](state) {
    state.health.servicesStateIsOpen = false;
  },

  [M.HUD_SET_OPENED_TAB](state, openedTabName) {
    state.openedTab = state.openedTab === openedTabName ? null : openedTabName;
  },

};

const getters = {
  isGivenTabOpened: state => tabName => state.openedTab === tabName,
};

export default {
  state,
  actions,
  getters,
  mutations,
};