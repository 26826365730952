import TimeService from "../services/timeService";
import AppConfig from "../config/appConfig";
import Store from "../store";
import Features from "../config/features";
import telemetryFeatures from "../constants/telemetryFeatures";
import A from "../constants/actions";

export default class SubscriptionUtils {

  static constructPartialSubscriptionConfig(affectedFeatures) {
    const minAltitudeInMeters = isAltitudeSliderEnabled() ? Store.getters.getMinFilteringAltitude :
      AppConfig.heightSlider.minimumHeightLimitInMeters;
    const maxAltitudeInMeters = isAltitudeSliderEnabled() ? Store.getters.getMaxFilteringAltitude :
      AppConfig.heightSlider.maximumHeightLimitInMeters;

    return {
      minAltitudeInMeters,
      maxAltitudeInMeters,
      viewPort: Store.getters.getCurrentViewPort,
      affectedFeatures: affectedFeatures,
      timeOffsetInMillies: TimeService.timeOffsetInMilliseconds(),
    };
  }

  static performPartialSubscribeForTimeSlider() {
    const affectedFeatures = getAffectedFeaturesForTimeSlider();
    if (affectedFeatures.length > 0) {
      const config = SubscriptionUtils.constructPartialSubscriptionConfig(affectedFeatures.map(f => f.id));
      Store.dispatch(A.INFORMATION_WIPE);
      Store.dispatch(A.MAP_WIPE_NONSTATIC_DATA, affectedFeatures.filter(f => f.displayOnMap).map(f => f.id));
      Store.dispatch(A.WEBSOCKET_PARTIAL_SUBSCRIBE, config);
    }
  }
}

function isAltitudeSliderEnabled() {
  return AppConfig.heightSlider.heightSliderEnabled && Store.getters.getAltitudeFilteredFeatures.length > 0;
}

function getAffectedFeaturesForTimeSlider() {
  const affectedFeatures = Features.getAvailableFeatures()
    .filter(f => f.staticDataType && Store.getters.getFeaturesConfiguredForCurrentView.includes(f.id));
  affectedFeatures.push(...Features.constructAvailableFeatures(telemetryFeatures.features));
  return affectedFeatures;
}