import A from '../../constants/actions';
import M from '../../constants/mutations';
import Store from '../';
import Features from '../../config/features';
import FeatureType from '../../constants/featureType';
import HudTabName from '../../constants/hudTabName';

const state = {
  isOpen: false,
  selectedId: null,
  selectedElemSnapshot: null,
  activeTab: 'OperationPlans',
  outlookPanelSelectedFilter: Features.getFeature(FeatureType.OPERATION_PLAN_OUTLOOK).filters[
  Features.getFeature(FeatureType.OPERATION_PLAN_OUTLOOK).filters.length - 1].value
};

const actions = {
  [A.UTM_PANEL_TOGGLE]({commit, dispatch}) {
    commit(M.HUD_SET_OPENED_TAB, HudTabName.UTM_PANEL);
    if (Store.state.informationStore.activeTab && Store.getters.isGivenTabOpened(HudTabName.UTM_PANEL)) {
      dispatch(A.INFORMATION_PANEL_SELECT_TAB, null);
    }
    if (Store.state.informationStore.droneIdToLink && !Store.getters.isGivenTabOpened(HudTabName.UTM_PANEL)) {
      dispatch(A.DRONE_ID_TO_LINK_SET, null);
    }
  },

  [A.UTM_PANEL_SELECT_TAB]({commit}, id) {
    commit(M.UTM_PANEL_SELECT_TAB, id);
    commit(M.EMBEDDED_MAP_DESTROY);
  },

  [A.UTM_PANEL_SELECT_ELEM]({commit}, elem) {
    commit(M.UTM_PANEL_SELECT_ELEM, elem);
  },

  [A.UTM_PANEL_SELECTED_ELEM_SNAPSHOT]({commit}, snapshot) {
    commit(M.UTM_PANEL_SELECTED_ELEM_SNAPSHOT, snapshot);
  },

  [A.UTM_PANEL_DESELECT_ELEM]({commit}) {
    commit(M.UTM_PANEL_DESELECT_ELEM);
  },

  [A.UTM_PANEL_OUTLOOK_PANEL_SELECT_FILTER]({commit}, filterValue) {
    commit(M.UTM_PANEL_OUTLOOK_PANEL_SELECT_FILTER, filterValue);
  }

};

const mutations = {

  [M.UTM_PANEL_SELECT_TAB](state, id) {
    state.activeTab = id;
  },

  [M.UTM_PANEL_SELECT_ELEM](state, id) {
    state.selectedId = id;
  },

  [M.UTM_PANEL_SELECTED_ELEM_SNAPSHOT](state, snapshot) {
    state.selectedElemSnapshot = snapshot;
  },

  [M.UTM_PANEL_DESELECT_ELEM](state) {
    state.selectedId = null;
    state.selectedElemSnapshot = null;
  },

  [M.UTM_PANEL_OUTLOOK_PANEL_SELECT_FILTER](state, filterValue) {
    state.outlookPanelSelectedFilter = filterValue;
  }

};

const getters = {
  outlookPanelSelectedFilter(state) {
    return state.outlookPanelSelectedFilter;
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};