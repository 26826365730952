<template>
  <div class="filterContainer w-100 h-100">
    <div class="filters filtersPanel">
      <div class="smartSISPanel">
        <h5 class="card-header">{{ $t('labels.common.filters') }}</h5>
        <div class="filterContent m-1">
          <div v-for="filter in filters" :key="filter.id">
            <button
              :id="'information_' + filter.id"
              :class="['btn', 'btn-primary', 'w-100', isSelected(filter.id) ? 'selected' : ''].join(' ')"
              @click="toggleFilter(filter.id)"
            >
              {{ filter.name }}
            </button>
          </div>
        </div>
      </div>
      <button id="filters-update" class="btn btn-primary mt-1" @click="update()">
        {{ $t('labels.common.update') }}
      </button>
    </div>
  </div>
</template>
<script>
import PermissionUtils from '../../../../utils/PermissionUtils';
import A from './../../../../constants/actions';

export default {
  name: 'FiltersDialog',

  computed: {
    filters() {
      return PermissionUtils.getAvailableNotamFilters().filter(f => f.filterType === this.$store.state.viewStore.currentView.filterType);
    },
    buttons() {
      return this.$store.state.dialogStore.buttons;
    },
  },
  methods: {
    update() {
      this.$store.dispatch(A.FIRFILTER_PANEL_TOGGLE);
    },
    toggleFilter(filterID) {
      this.$store.dispatch(A.VIEW_EDITOR_TOGGLE_NOTAM_FILTER, filterID);
    },
    isSelected(filter) {
      return this.$store.state.viewStore.currentView.filterIds ?
        this.$store.state.viewStore.currentView.filterIds.indexOf(filter) > -1 : false;
    }
  },

}
</script>
<style src="./viewEditor.css"></style>