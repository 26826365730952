import A from '../../constants/actions';
import M from '../../constants/mutations';
import GeozoneDefaultColorConfig from "../../config/geozone/geozoneDefaultColorConfig";
import FeatureType from "../../constants/featureType";
import Store from '../../store';
import GlobalConfigurationIdConfig from "../../config/GlobalConfigurationIdConfig";

const defaultConfiguration = {
  geozoneFeatureConfiguration: GeozoneDefaultColorConfig.getGeozoneDefaultColorConfig(),
  systemConfigurations: null,
  displayConfigurations: null,
};

const state = {
  currentConfiguration: JSON.parse(JSON.stringify(defaultConfiguration)),
  originalConfiguration: JSON.parse(JSON.stringify(defaultConfiguration)),
};

const actions = {
  [A.GLOBAL_CONFIGURATION_READ]({dispatch, commit, getters}, configuration) {
    commit(M.GLOBAL_CONFIGURATION_READ, configuration);
    dispatch(A.NOTIFICATION_SOUND_SET, getters.getGeneralSoundNotificationEnabled());
  },

  [A.GLOBAL_CONFIGURATION_SAVE]({commit}) {
    commit(M.GLOBAL_CONFIGURATION_SAVE);
  },

  [A.GLOBAL_CONFIGURATION_CANCEL_EDIT]({commit}) {
    commit(M.GLOBAL_CONFIGURATION_CANCEL_EDIT);
    const geozoneStyle = Store.getters.getStyleConfigForLayer(FeatureType.ED269);
    commit(M.REFRESH_LAYER, geozoneStyle);

  },

  [A.GLOBAL_CONFIGURATION_EDIT_GEOZONE_CONFIG]({commit}, config) {
    commit(M.GLOBAL_CONFIGURATION_EDIT_GEOZONE_CONFIG, config);
  },

  [A.GLOBAL_CONFIGURATION_EDIT_DISPLAY_CONFIG]({dispatch, commit}, config) {
    commit(M.GLOBAL_CONFIGURATION_EDIT_DISPLAY_CONFIG, config);
    dispatch(A.MAP_REFRESH_LAYERS_BY_FEATURE_TYPE, FeatureType.DRONE_ALERT);
    dispatch(A.MAP_REFRESH_LAYERS_BY_FEATURE_TYPE, FeatureType.OPERATION_PLAN_OUTLOOK);
  },

  [A.GLOBAL_CONFIGURATION_EDIT_SYSTEM_CONFIG]({commit}, config) {
    commit(M.GLOBAL_CONFIGURATION_EDIT_SYSTEM_CONFIG, config);
  }
};

const mutations = {
  [M.GLOBAL_CONFIGURATION_READ](state, config) {
    state.currentConfiguration = JSON.parse(JSON.stringify(config));
    state.originalConfiguration = JSON.parse(JSON.stringify(config));
  },

  [M.GLOBAL_CONFIGURATION_SAVE](state) {
    state.originalConfiguration = JSON.parse(JSON.stringify(state.currentConfiguration));
  },

  [M.GLOBAL_CONFIGURATION_CANCEL_EDIT](state) {
    state.currentConfiguration = JSON.parse(JSON.stringify(state.originalConfiguration));
  },

  [M.GLOBAL_CONFIGURATION_EDIT_GEOZONE_CONFIG](state, config) {
    const colorConfigIndex = state.currentConfiguration.geozoneFeatureConfiguration.colorConfigs.findIndex(
      colorConfig => colorConfig.restrictionType === config.restrictionType);
    const updatedConfig = state.currentConfiguration.geozoneFeatureConfiguration.colorConfigs[colorConfigIndex];
    updatedConfig[config.colorType] = config.value;
    if (colorConfigIndex > -1) {
      state.currentConfiguration.geozoneFeatureConfiguration.colorConfigs[colorConfigIndex] = updatedConfig;
    }
  },

  [M.GLOBAL_CONFIGURATION_EDIT_DISPLAY_CONFIG](state, config) {
    const displayConfigIndex = state.currentConfiguration.displayConfigurations.findIndex(
      displayConfig => displayConfig.id === config.id);
    if (displayConfigIndex > -1) {
      state.currentConfiguration.displayConfigurations[displayConfigIndex] = config;
    }
  },

  [M.GLOBAL_CONFIGURATION_EDIT_SYSTEM_CONFIG](state, config) {
    const systemConfigIndex = state.currentConfiguration.systemConfigurations.findIndex(
      systemConfig => systemConfig.id === config.id);
    if (systemConfigIndex > -1) {
      state.currentConfiguration.systemConfigurations[systemConfigIndex] = config;
    }
  }
};

const getters = {
  getGeozoneColorConfigByRestrictionType: state => type => {
    return state.currentConfiguration.geozoneFeatureConfiguration.colorConfigs
      .find(config => config.restrictionType === type);
  },
  getGlobalConfigs: state => {
    return state.currentConfiguration;
  },
  getOriginalGlobalConfigs: state => {
    return state.originalConfiguration;
  },
  getDisplayOfDrones(state) {
    return state.currentConfiguration.systemConfigurations
      .find(config => config.id === GlobalConfigurationIdConfig.SYSTEM_CONFIGURATION_IDS.DISPLAY_CONFORMANT_DRONE)
      ?.propertyValue === "true";
  },
  getDisplayOfResolvedMessage(state) {
    return state.currentConfiguration.systemConfigurations
      .find(config => config.id === GlobalConfigurationIdConfig.SYSTEM_CONFIGURATION_IDS.DISPLAY_RESOLVED_MESSAGE)
      ?.propertyValue === "true";
  },
  getDisplayOfUpcomingPlansOnMap: state => () => {
    return state.currentConfiguration.systemConfigurations
      ?.find(config => config.id === GlobalConfigurationIdConfig.SYSTEM_CONFIGURATION_IDS.DISPLAY_UPCOMING_PLANS_ON_MAP)
      ?.propertyValue === "true";
  },
  getDisplayOfRestrictAor: state => () => {
    return state.currentConfiguration.systemConfigurations
      ?.find(config => config.id === GlobalConfigurationIdConfig.SYSTEM_CONFIGURATION_IDS.DISPLAY_RESTRICT_AOR)
      ?.propertyValue === "true";
  },
  getAirspaceReservationConfigValue: state => () => {
    return state.currentConfiguration.systemConfigurations
      ?.find(config => config.id === GlobalConfigurationIdConfig.SYSTEM_CONFIGURATION_IDS.AIRSPACE_RESERVATION_FOR_HIGH_PRIORITY_PLANS)
      ?.propertyValue;
  },
  getGeneralSoundNotificationEnabled: state => () => {
    return state.currentConfiguration.systemConfigurations
      ?.find(config => config.id === GlobalConfigurationIdConfig.SYSTEM_CONFIGURATION_IDS.GENERAL_SOUND_NOTIFICATION)
      ?.propertyValue === "true";
  },
  getDisplayConfigurationById: state => id => {
    return state.currentConfiguration.displayConfigurations.find(config => config.id === id);
  },
  getSystemConfigurationById: state => id => {
    return state.currentConfiguration.systemConfigurations.find(config => config.id === id);
  }
};

export default {
  state,
  actions,
  getters,
  mutations,
};