export default {

  mapFilterProperties: [
    {
      name: 'type',
      propertyName: 'type',
      type: 'valueProp'
    },
    {
      name: 'status',
      propertyName: 'status',
      type: 'valueProp'
    },
    {
      name: 'filterClass',
      propertyName: 'notamClass',
      type: 'valueProp'
    },
    {
      name: 'traffic',
      propertyName: 'traffic',
      type: 'valueProp'
    },
    {
      name: 'purpose',
      propertyName: 'purpose',
      type: 'valueProp'
    },
    {
      name: 'scope',
      propertyName: 'scope',
      type: 'valueProp'
    },
    {
      name: 'series',
      propertyName: 'series',
      type: 'valueProp'
    },
    {
      name: 'number',
      propertyName: 'number',
      type: 'valueProp'
    },
    {
      name: 'qCodes',
      propertyName: 'selectionCode',
      type: 'listOfValueProps'
    },
    {
      name: 'categories',
      propertyName: 'category',
      type: 'listOfValueProps'
    },
    {
      name: 'minFl',
      propertyName: 'minimumFl',
      type: 'rangeProp'
    },
    {
      name: 'maxFl',
      propertyName: 'maximumFl',
      type: 'rangeProp'
    }
  ],

  traffics: ['', 'IV', 'I', 'V', 'K'],
  purposes: ['', 'NBO', 'BO', 'M', 'B', 'K'],
  scopes: ['', 'A', 'W', 'AE', 'E', 'AW', 'K'],
  types: [
    'labels.configurationPanel.notamFilter.airspace',
    'labels.configurationPanel.notamFilter.airportHeliport'
  ],
}