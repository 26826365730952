<template>
  <div id="hud-control-buttons" class="container-fluid">
    <div class="row mx-0" id="hud">
      <div id="map-layout-selector-wrapper" class="col-2 mx-0 px-0">
        {{ $t('labels.hud.layout') }}:
        <button
          id="day-button"
          :class="['btn', 'btn-primary', (isDayThemeSelected) ? 'selected' : ''].join(' ')"
          @click="selectDayThemeIfExist()"
          type="button"
        >
          <img v-bind:src="getButtonImg('day')" :alt="$t('labels.common.day')">
        </button>
        <button
          id="night-button"
          :class="['btn', 'btn-primary', (isNightThemeSelected) ? 'selected' : ''].join(' ')"
          @click="selectNightThemeIfExist()"
          type="button"
        >
          <img v-bind:src="getButtonImg('night')" :alt="$t('labels.common.night')">
        </button>
      </div>
      <LayerSelector class="col-2 mx-0 px-0"/>
      <div id="control-buttons" class="controls btn-group col-8 mx-0 px-0">
        <button
          id="ok-not-ok"
          :class="['btn', 'btn-primary', 'btn-health-state', healthServicesClass].join(' ')"
          @click="displayServicesState()"
          type="button"
        >
          {{ hasFailedServices ? $t('labels.hud.notOk') : $t('labels.common.ok') }}
        </button>
        <button
          id="3d-used"
          :class="['btn', 'btn-primary', (is3DModeUsed) ? 'selected' : ''].join(' ')"
          @click="enable3d()"
          type="button"
          v-if="is3DModeEnabled"
        >
          <img v-bind:src="getButtonImg('3d')" :alt="$t('labels.hud.3d')">
        </button>
        <button
          id="configuration-button"
          :class="['btn', 'btn-primary', (isConfigPanelOpen) ? 'selected' : ''].join(' ')"
          @click="toggleViewsPanel()"
          type="button"
        >
          <img v-bind:src="getButtonImg('configuration')" :alt="$t('labels.hud.config')">
        </button>
        <button
          id="sound-button"
          :class="['btn', 'btn-primary', (isMuted)? 'sound-off': 'sound-on'].join(' ')"
          @click="toggleNotificationSound()"
          type="button"
        >
          <img v-bind:src="getButtonImgForSound()" :alt="$t('labels.hud.sound')">
        </button>
        <button
          id="documents-button"
          :class="['btn', (isDocumentPanelOpen) ? 'selected' : '', isDocumentListUpdated ? 'btn-warning' : 'btn-primary'].join(' ')"
          @click="openDocumentsPanel()"
          type="button"
          v-if="isDocumentsEnabled"
        >
          <img v-bind:src="getButtonImg('documents')" :alt="$t('labels.hud.documents')">
        </button>
        <button
          id="roles-button"
          :class="['btn', (isRolePanelOpen) ? 'selected' : '', isUpdateAvailable ? 'btn-warning' : 'btn-primary'].join(' ')"
          @click="openRolesPanel()"
          type="button"
        >
          <img v-bind:src="getButtonImg('roles')" :alt="$t('labels.hud.roles')">
        </button>
        <button id="coordinates-button" class="btn btn-primary coordinates-button">
          <span class="coordinates">{{ this.coordinate.lat }} {{ this.coordinate.lon }}</span>
        </button>
        <button
          id="reset-view-button"
          @click="resetViewPort()"
          class="btn btn-primary"
          :disabled="isUtmPanelOpen"
        >
          <img v-bind:src="getButtonImg('resetView')" :alt="$t('labels.common.reset')">
        </button>
        <div class="btn btn-primary time-button">
          {{ time }} {{ $t('labels.hud.utc') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import A from '../../constants/actions';
import AppConfig from '../../config/appConfig';
import ImgUtils from '../../utils/ImgUtils';
import LayerSelector from '../../components/layer-selector/LayerSelector.vue';
import UserService from '../../services/smartSisUserService';
import TimeService from '../../services/timeService';
import WebsocketSubscriptionService from "../../services/websocketSubscriptionService";
import DialogUtils from "../../utils/DialogUtils";
import HudTabName from '../../constants/hudTabName';
import {setInterval} from 'worker-timers';

export default {
  name: 'HUD',
  components: {
    LayerSelector,
  },

  data: () => {
    return {
      time: TimeService.currentUtcTime().format('HH:mm:ss'),
      dayRegex: new RegExp(/^DAY/i),
      nightRegex: new RegExp(/^NIGHT/i),
    }
  },
  computed: {
    hasFailedServices() {
      return this.$store.state.hudStore.health.servicesStates.some(s => s.state === 'NOK' || s.state === 'Degraded');
    },
    healthServicesClass() {
      if (this.hasFailedServices) {
        return 'failed-services';
      } else if (this.$store.state.hudStore.health.servicesUpdatedUntil > Date.now()) {
        return 'services-updated';
      }
      return 'services-ok';
    },
    isDayThemeSelected() {
      return this.dayRegex.test(this.$store.state.themeStore.currentTheme.name);
    },
    isNightThemeSelected() {
      return this.nightRegex.test(this.$store.state.themeStore.currentTheme.name);
    },
    coordinate() {
      return this.$store.state.hudStore.coordinate;
    },
    isUpdateAvailable() {
      return this.$store.state.updateStore.isUpdateAvailable;
    },
    is3DModeEnabled() {
      return AppConfig.ui.enable3DMode;
    },
    isDocumentsEnabled() {
      return AppConfig.ui.enableDocs;
    },
    is3DModeUsed() {
      console.log('3D', this.$store.state.mapOperations.is3dModeEnabled);
      return this.$store.state.mapOperations.is3dModeEnabled;
    },
    isConfigPanelOpen() {
      return this.$store.getters.isGivenTabOpened(HudTabName.CONFIGURATION);
    },
    isRolePanelOpen() {
      return this.$store.getters.isGivenTabOpened(HudTabName.USER_ROLES);
    },
    isDocumentPanelOpen() {
      return this.$store.getters.isGivenTabOpened(HudTabName.DOCUMENTS);
    },
    isDocumentListUpdated() {
      return this.$store.state.documentsStore.isUpdated;
    },
    isMuted() {
      return this.$store.state.notificationStore.mute;
    },
    isUtmPanelOpen() {
      return this.$store.getters.isGivenTabOpened(HudTabName.UTM_PANEL);
    }
  },
  methods: {
    enable3d() {
      this.$store.dispatch(A.ENABLE_3D, true);
    },
    resetViewPort() {
      this.$store.dispatch(A.VIEW_SET_RESETTING_VIEWPORT_IN_PROGRESS);
      this.$store.dispatch(A.VIEW_RESET_VIEWPORT);
      this.$store.dispatch(A.MAP_RESET_ORIENTATION);
      this.$store.dispatch(A.VIEW_RESET_TIME_SLIDER);
      this.$store.dispatch(A.VIEW_RESET_ALTITUDE_SLIDER);
      this.$store.dispatch(A.TIME_SLIDER_RESET_DATE, TimeService.currentUtcTime());
      this.$store.dispatch(A.MAP_WIPE);
      this.$store.dispatch(A.INFORMATION_WIPE);

      WebsocketSubscriptionService.websocketSubscribe(this.$store);
    },
    openRolesPanel() {
      UserService.getConflictsOfResponsibility({
        onSuccess: (data) => {
          this.$store.dispatch(A.USER_MANAGEMENT_USER_CONNECTIONS_READ_ALL, data);
        }
      });
      this.$store.dispatch(A.HUD_SET_OPENED_TAB, HudTabName.USER_ROLES);
    },
    openDocumentsPanel() {
      this.$store.dispatch(A.HUD_SET_OPENED_TAB, HudTabName.DOCUMENTS);
      this.$store.dispatch(A.DOCUMENTS_RESET_IS_UPDATED);
    },
    toggleViewsPanel() {
      this.$store.dispatch(A.CONFIGURATION_PANEL_TOGGLE, HudTabName.CONFIGURATION);
      this.$store.dispatch(A.REMOVE_SELECT_FEATURE);
    },
    toggleNotificationSound() {
      this.$store.dispatch(A.NOTIFICATION_SOUND_TOGGLE);
    },
    getButtonImg(name) {
      return ImgUtils.getButtonImg(name, 'svg');
    },
    getButtonImgForSound() {
      const name = this.$store.state.notificationStore.mute ? 'mute' : 'unmute';
      return ImgUtils.getButtonImg(name, 'svg');
    },
    displayServicesState() {
      this.$store.dispatch(A.DISPLAY_SERVICES_STATES);
    },
    selectDayThemeIfExist() {
      this.selectThemeIfExists(this.dayRegex);
    },
    selectNightThemeIfExist() {
      this.selectThemeIfExists(this.nightRegex);
    },
    selectThemeIfExists(matchingRegex) {
      let themesMatchingRegex = this.$store.state.themeStore.themes.filter(theme => matchingRegex.test(theme.name));
      if (themesMatchingRegex.length > 0) {
        this.$store.dispatch(A.THEME_SELECT_THEME, themesMatchingRegex [0].id);
      } else {
        DialogUtils.errorNotification(this.$i18n.t('errorMessages.hudNoThemeForSelectedColorScheme'));
      }
    },
  },

  mounted: function () {
    const self = this;
    setInterval(() => {
      self.time = TimeService.currentUtcTime().format('HH:mm:ss');
    }, 1000);
  },

};
</script>

<style src="./hud.css"></style>
