import FeatureType from '../../constants/featureType';
import ThemeElements from '../../constants/theme-elements';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';

export default {
  id: FeatureType.CTR,
  name: 'features.ctr',
  icon: 'ctr',
  staticDataType: [],
  isViewPortBased: false,
  isFlightLevelBased: false,
  isNotificationsDisabled: true,
  maxNrOfFeaturesPerLocation: 100,
  featureIdentifier: 'id',
  maxResolution: 10000,
  declutter: false,
  zIndex: 1,
  flightLevel: null,
  theme: {
    elements: [
      ThemeElements.SHOW_TEXT,
      ThemeElements.BORDER_COLOR,
      ThemeElements.FILL_COLOR,
      ThemeElements.TEXT_COLOR,
      ThemeElements.TEXT_SIZE,
      ThemeElements.TEXT_BG_COLOR,
    ]
  },
  displayOnMap: true,
  displayInMessages: false,

  color: (colors) => {
    return colors.textColor;
  },

  defaultStyle: () => {
    return {
      showText: false,
      textSize: 14,
      iconSize: 100,
      colors: {
        borderColor: {r: 117, g: 133, b: 168, a: 1},
        fillColor: {r: 182, g: 182, b: 243, a: 0.27},
        textColor: {r: 100, g: 134, b: 77, a: 0.8},
        textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
        imageColor: {r: 239, g: 8, b: 8, a: 0.5}
      }
    }
  },

  geoJsonMapper: (feature) => {
    return new Feature({
      geometry: new GeoJSON().readGeometry(feature.geometry),
      id: feature.id,
      indexed_on: feature.indexed_on,
      effective_time_begin: feature.effective_time_begin,
      effective_time_end: feature.effective_time_end,
      featureType: feature.featureType
    });
  },
}