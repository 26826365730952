import FeatureType from '../../constants/featureType';
import {i18n} from '../../internationalization/index';

const SELECT_FEATURE_CONFIG = [
  {
    featureType: FeatureType.OPERATION_PLAN,
    displayedName:'labels.operationPlan.dronePlan',
    displayedField: 'publicTitle',
    defaultDisplayValue: 'labels.operationPlan.publicTitleMissing'
  },
  {
    featureType: FeatureType.ED269_BASE_FEATURE,
    displayedName: 'labels.geozone.geozone',
    displayedField: 'name',
    alternativeDisplayField: 'associatedFeatureId',
    defaultDisplayValue: 'labels.geozone.nameMissing'
  },
  {
    featureType: FeatureType.EVENT,
    displayedName: 'features.event',
    displayedField: 'id',
    defaultDisplayValue: 'labels.common.idMissing'
  },
  {
    featureType: FeatureType.RESPONSIBILITY_AREA,
    displayedName: 'features.aoR',
    displayedField: 'name',
    defaultDisplayValue: 'labels.common.nameMissing'
  },
  {
    featureType: FeatureType.LARA_AIRSPACE,
    displayedName: 'labels.laraAirspace.laraAirspace',
    displayedField: 'name',
    alternativeDisplayField: 'associatedFeatureId',
    defaultDisplayValue: 'labels.laraAirspace.nameMissing'
  },
];

function findConfigForFeatureType(featureType) {
  return SELECT_FEATURE_CONFIG.find(config => featureType?.startsWith(config.featureType));
}

function findDisplayedValueForFeature(feature) {
  return feature[findConfigForFeatureType(feature.featureType).displayedField] ||
    feature[findConfigForFeatureType(feature.featureType)?.alternativeDisplayField] ||
    i18n.global.t(findConfigForFeatureType(feature.featureType).defaultDisplayValue);
}

function findDisplayedName(feature) {
  return i18n.global.t(findConfigForFeatureType(feature.featureType).displayedName);
}

export default {
  findConfigForFeatureType,
  findDisplayedValueForFeature,
  findDisplayedName,
}