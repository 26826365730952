<template>
  <div>
    <div class="card smartSISPanel h-100">
      <h5 class="card-header">{{ $t('labels.configurationPanel.view.viewport') }}</h5>
      <div class="card-body snapshotContainer">
        <img class="snapshotImg" :src="snapshotSrc">
      </div>
      <div class="center">
        <table class="d-flex justify-content-center">
          <tbody>
          <th id="top-left-corner"></th>
          <tr>
            <td>
              <img class="m-2 viewportButton" v-bind:src="this.getButtonImg('arrow_top_left')">
            </td>
            <td>
              <strong>{{ $t('labels.configurationPanel.view.lat') }}:</strong>
              {{ this.formatFloat(this.viewPort.upperLeft.lat) }}
              <strong>{{ $t('labels.configurationPanel.view.lon') }}:</strong>
              {{ this.formatFloat(this.viewPort.upperLeft.lon) }}
            </td>
          </tr>
          <th id="bottom-right-corner"></th>
          <tr>
            <td>
              <img class="m-2 viewportButton" v-bind:src="this.getButtonImg('arrow_bottom_right')">
            </td>
            <td>
              <strong>{{ $t('labels.configurationPanel.view.lat') }}:</strong>
              {{ this.formatFloat(this.viewPort.bottomRight.lat) }}
              <strong>{{ $t('labels.configurationPanel.view.lon') }}:</strong>
              {{ this.formatFloat(this.viewPort.bottomRight.lon) }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div>
        <button
          id="viewConfiguration_ResetViewPort"
          :class="['btn', 'btn-primary', 'm-2', (this.viewPortChanged) ? 'active' : 'disabled'].join(' ')"
          :disabled="!this.viewPortChanged"
          @click="resetViewPort()"
        >
          {{ $t('labels.configurationPanel.view.resetViewport') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>

import A from '../../../../constants/actions';
import ImgUtils from "../../../../utils/ImgUtils";

export default {
  name: 'Viewport',
  computed: {
    viewPort() {
      return this.$store.state.viewStore.currentView.viewPort;
    },
    snapshotSrc() {
      return this.$store.state.mapOperations.snapshot;
    },
    viewPortChanged() {
      const currentViewPort = this.$store.state.viewStore.currentView.viewPort;
      const originalViewPort = this.$store.state.viewStore.originalView.viewPort;

      return JSON.stringify(currentViewPort) !== JSON.stringify(originalViewPort);
    }
  },
  methods: {
    getButtonImg(name) {
      return ImgUtils.getButtonImg(name, 'svg');
    },
    formatFloat(number) {
      return number;
    },
    resetViewPort() {
      this.$store.dispatch(A.VIEW_RESET_VIEWPORT);
    }
  }

}
</script>
<style src="./viewEditor.css"></style>