import GeozoneCreationDetails from "../../model/geozoneCreationDetails";
import MapUtils from "../MapUtils";
import {intersect} from "turf";
import Polygon from "ol/geom/Polygon";
import circleToPolygon from "circle-to-polygon";

export default class GeozoneValidationUtils {

  static applyGeozoneValidations(geozoneInstance) {
    return GEOZONE_VALIDATIONS
      .map(validate => validate(geozoneInstance))
      .every(validationResult => validationResult === true);
  }

  static validateGeozoneGeometry(geozoneInstance) {
    return isGeometryValid(geozoneInstance);
  }

  static isFirstCoordinateEqualWithLastCoordinate(coordinates) {
    const firstCoordinate = coordinates[0][0];
    const lastCoordinate = coordinates[0][coordinates[0].length - 1];
    return areTwoCoordinateEqual(firstCoordinate, lastCoordinate);
  }
}

const GEOZONE_VALIDATIONS = [
  geozoneInstance => isEveryFieldValid(GeozoneCreationDetails.geozoneCreationDetailsFields, geozoneInstance),
  isGeometryValid
];

function isEveryFieldValid(fieldConfigs, instance) {
  return fieldConfigs.every(fieldConfig => fieldConfig.isValid(fieldConfig.getValue(instance), instance));
}

function isGeometryValid(instance) {
  const coordinates = hasCircleGeometry(instance) ? convertCircleToPolygon(instance) :
    instance.geometry[0].horizontalProjection?.coordinates;

  if (!coordinates || !areCoordinatesInAllowedInterval(coordinates[0]) ||
    !(getNrOfDistinctCoordinates(coordinates[0]) >= 3)) {
    return false;
  }
  if (!GeozoneValidationUtils.isFirstCoordinateEqualWithLastCoordinate(coordinates)) {
    coordinates[0][coordinates[0].length] = coordinates[0][0];
  }
  const gzPolygon = new Polygon(coordinates);
  return MapUtils.getViewGeometries().some(geometry => geometriesIntersect(gzPolygon, geometry));
}

function areTwoCoordinateEqual(coord1, coord2) {
  return coord1[0] === coord2[0] && coord1[1] === coord2[1];
}

function areCoordinatesInAllowedInterval(coordinates) {
  return coordinates.every(coord => isValidLongitude(coord[0]) && isValidLatitude(coord[1]));
}

function isValidLongitude(longitude) {
  return longitude !== null && longitude >= -180 && longitude < 180;
}

function isValidLatitude(latitude) {
  return latitude !== null && latitude > -90 && latitude < 90;
}

function getNrOfDistinctCoordinates(coordinates) {
  let distinctCoordinates = [];
  coordinates.forEach(coordinate => {
    if (!distinctCoordinates.some(dc => areTwoCoordinateEqual(coordinate, dc))) {
      distinctCoordinates.push(coordinate);
    }
  })
  return distinctCoordinates.length;
}

function geometriesIntersect(geozone, viewGeometry) {
  return intersect(
    {type: geozone.getType(), coordinates: geozone.getCoordinates()},
    {type: viewGeometry.getType(), coordinates: viewGeometry.getCoordinates()}
  );
}

function hasCircleGeometry(geozone) {
  return geozone.geometry[0].horizontalProjection?.type === 'Circle';
}

function convertCircleToPolygon(geozone) {
  const circleGeometry = geozone.geometry[0].horizontalProjection;
  return circleToPolygon(circleGeometry.center, circleGeometry.radius).coordinates;
}