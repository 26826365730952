<template>
    <div class="numberItem input-group">
        <button
            id="theme-number-item-decrease"
            class="btn btn-primary"
            @click="onDecrease()"
        >
            -
        </button>
        <input
            class="form-control numberItemInput"
            type="text"
            min="0"
            @keyup="this.onChange"
            @change="this.onChange"
            :value="value">
        <div class="input-group-prepend">
            <div class="measurement input-group-text">{{this.getMeasurement()}}</div>
        </div>
        <button
            id="theme-number-item-increase"
            class="btn btn-primary"
            @click="onIncrease()"
        >
            +
        </button>
    </div>
</template>
<script>

  import A from '../../../../constants/actions';

  export default {
    name: 'NumberItem',
    props: ['value', 'layerStyle', 'element'],
    methods: {
      getMeasurement() {
        return this.element.measurement;
      },
      onChange(event) {
        event.target.value = event.target.value.trim() || 0;
        if (event.target.value.length > 0 && isNaN(event.target.value)) {
          event.preventDefault();
          event.target.value = this.value;
        } else {
          event.target.value = parseInt(event.target.value);
          this.updateStyle(this.layerStyle, this.element, parseInt(event.target.value));
        }
      },
      updateStyle(layerStyle, element, value) {
        this.element.update(layerStyle, value);
        this.$store.dispatch(A.THEME_UPDATE_LAYER_STYLING, layerStyle);
      },
      onDecrease() {
        if (this.value - 1 >= 0) {
          this.updateStyle(this.layerStyle, this.element, parseInt(this.value - 1));
        }
      },
      onIncrease() {
        this.updateStyle(this.layerStyle, this.element, parseInt(this.value) + 1);
      },
    }

  };
</script>
<style src="./themeEditor.css"></style>
