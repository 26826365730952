import A from '../../constants/actions';
import M from '../../constants/mutations';

const state = {
  thresholds: [],
  currentThreshold: null,
  originalThreshold: null,
};

const actions = {

  [A.THRESHOLD_READ_ALL_THRESHOLDS]({commit}, thresholds) {
    commit(M.THRESHOLD_READ_ALL_THRESHOLDS, thresholds);
    if (thresholds.length > 0) {
      commit(M.THRESHOLD_SELECT_THRESHOLDS, thresholds[0].id);
    }
  },

  [A.THRESHOLD_SELECT_THRESHOLDS]({commit}, thresholdId) {
    commit(M.THRESHOLD_SELECT_THRESHOLDS, thresholdId);
  },

  [A.THRESHOLD_SAVE_THRESHOLDS]({commit}) {
    commit(M.THRESHOLD_SAVE_THRESHOLDS);
  },

  [A.THRESHOLD_CANCEL_EDIT]({commit}) {
    commit(M.THRESHOLD_CANCEL_EDIT);
  },

  [A.THRESHOLD_UPDATE_VALUE]({commit}, thresholdUpdate) {
    commit(M.THRESHOLD_UPDATE_VALUE, thresholdUpdate);
  },

};

const mutations = {

  [M.THRESHOLD_READ_ALL_THRESHOLDS](state, thresholds) {
    state.thresholds = thresholds;
  },

  [M.THRESHOLD_SELECT_THRESHOLDS](state, thresholdId) {
    if (state.currentThreshold) {
      const index = state.thresholds.findIndex((threshold) => threshold.id === state.currentThreshold.id);
      state.thresholds[index] = JSON.parse(JSON.stringify(state.originalThreshold));
    }

    state.currentThreshold = state.thresholds.find((threshold) => {
      return threshold.id === thresholdId;
    });
    state.originalThreshold = JSON.parse(JSON.stringify(state.currentThreshold));
  },

  [M.THRESHOLD_CANCEL_EDIT](state) {
    const index = state.thresholds.findIndex((threshold) => threshold.id === state.currentThreshold.id);
    const originalThreshold = JSON.parse(JSON.stringify(state.originalThreshold));

    state.thresholds.splice(index, 1, originalThreshold);
    state.currentThreshold = state.thresholds[index];
  },

  [M.THRESHOLD_SAVE_THRESHOLDS](state) {
    state.originalThreshold = JSON.parse(JSON.stringify(state.currentThreshold));
  },

  [M.THRESHOLD_UPDATE_VALUE](state, thresholdUpdate) {
    const prop = state.currentThreshold.props.find(prop => prop.name === thresholdUpdate.propName);
    if (prop) {
      prop.thresholds[thresholdUpdate.thresholdIndex].value = thresholdUpdate.thresholdValue;
    }
  }
};

const getters = {};

export default {
  state,
  actions,
  getters,
  mutations,
};