<template>
  <div class="row m-0">
    <ListPanel
      :entries="responsibilityAreas"
      :entryStyle="getButtonStyle"
      :idPrefix="'ra'"
      :onSelectEntry="selectResponsibilityArea"
      class="pt-2 pr-2 pl-0"
    >
    </ListPanel>
    <UtmConfigurationEditor
      :is-utm-config-disabled="!isAllowedToEdit"
    ></UtmConfigurationEditor>
  </div>
</template>

<script>
import A from '../../../constants/actions';
import DialogUtils from '../../../utils/DialogUtils';
import ListPanel from '../list-panel/ListPanel.vue';
import PermissionUtils from '../../../utils/PermissionUtils';
import UtmConfigurationEditor from './editor/UtmConfigurationEditor.vue';
import Logger from '../../../utils/LoggerUtils';
import viewState from '../../../store/modules/view';
import {mapGetters} from "vuex";

export default {
  name: 'UTMConfiguration',
  components: {
    ListPanel,
    UtmConfigurationEditor
  },
  created() {
    const responsibilityAreas = this.responsibilityAreas;
    if (responsibilityAreas == null || responsibilityAreas.length === 0) {
      this.$store.dispatch(A.UTM_CONFIG_RESET);
      DialogUtils.errorNotification(this.$i18n.t('errorMessages.noRespArea'))
    } else {
      this.selectResponsibilityArea(responsibilityAreas[0]);
    }
  },
  computed: {
    ...mapGetters({
      getCurrentAorDesignator: 'getCurrentResponsibilityAreaDesignator',
    }),
    isAllowedToEdit() {
      return this.responsibilityAreas != null && this.responsibilityAreas.length > 0;
    },
    responsibilityAreas() {
      const aorInCurrentView = viewState.getters.getCurrentResponsibilityAreas(viewState.state);
      return this.$store.getters.getCurrentResponsibilityAreasForConfig
        .filter(config => aorInCurrentView.indexOf(config.designator) !== -1);
    }
  },
  methods: {
    selectResponsibilityArea(responsibilityArea) {
      const currentConfiguration = this.$store.state.utmConfigurationStore.currentConfiguration;
      const originalConfiguration = this.$store.state.utmConfigurationStore.originalConfiguration;
      if (PermissionUtils.isAllowedToEditUtmConfigurations() &&
        JSON.stringify(currentConfiguration) !== JSON.stringify(originalConfiguration)) {
        DialogUtils.confirmOperation(
          {
            message: this.$i18n.t('confirmationMessages.utmConfigurationChangeAorConfig'),
            yesAction: () => {
              this.$store.dispatch(A.UTM_CONFIG_SELECT_RESPONSIBILITY_AREA, responsibilityArea);
            }
          }
        );
      } else {
        Logger.info(this.$i18n.t('logMessages.newAorSelected', {
          aorId: responsibilityArea.id,
          aorName: responsibilityArea.name
        }));
        this.$store.dispatch(A.UTM_CONFIG_SELECT_RESPONSIBILITY_AREA, responsibilityArea);
      }
    },

    getButtonStyle(responsibilityArea) {
      return [
        'btn', 'btn-primary',
        'w-100',
        'm-1',
        'listButton',
        (this.getCurrentAorDesignator === responsibilityArea.designator) ? 'selected' : '',
      ].join(' ');
    }
  }
};
</script>