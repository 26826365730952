import FeatureType from '../../constants/featureType';
import ThemeElements from '../../constants/theme-elements';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';

export default {

  id: FeatureType.ROUTE_SEGMENT,
  name: 'features.routeSegment.name',
  abbreviation: 'features.routeSegment.abbreviation',
  isStaticDataType: true,
  featureIdentifier: 'id',
  isViewPortBased: true,
  isFlightLevelBased: false,
  maxResolution: 10000,
  declutter: true,
  zIndex: 1,
  flightLevel: null,
  theme: {
    elements: [
      ThemeElements.SHOW_TEXT,
      ThemeElements.BORDER_COLOR,
      ThemeElements.TEXT_COLOR,
      ThemeElements.TEXT_SIZE,
      ThemeElements.TEXT_BG_COLOR,
    ]
  },
  displayOnMap: true,
  displayInMessages: false,

  geoJsonMapper: (feature) => {
    return new Feature({
      geometry: new GeoJSON().readGeometry(feature.geometry),
      id: feature.timesliceId || feature.id || feature.featureId,
      maxResolution: 10000,
      featureType: FeatureType.ROUTE_SEGMENT,
      lowerLimit: feature.lowerLimit,
      upperLimit: feature.upperLimit,
      pathType: feature.pathType,
      trueTrack: feature.trueTrack,
      magneticTrack: feature.magneticTrack,
      turnDirection: feature.turnDirection,
      navigationType: feature.navigationType,
      start: feature.start.identifier,
      end: feature.end.identifier,
      requiredNavigationPerformance: feature.requiredNavigationPerformance,
      level: feature.level,
      availability: feature.availability,
    });
  },

  color: (colors) => {
    return colors.textColor;
  },

  defaultStyle: () => {
    return {
      showText: true,
      textSize: 14,
      iconSize: 10,
      colors: {
        borderColor: {r: 10, g: 170, b: 70, a: 1},
        fillColor: {r: 240, g: 228, b: 255, a: 0.1},
        textColor: {r: 10, g: 190, b: 90, a: 1},
        textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
      }
    }
  },

}