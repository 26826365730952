<template>
  <div>
    <div class="card smartSISPanel" id="airports">
      <div class="row m-0" style="flex-wrap: nowrap">
        <div class="p-0 m-0 w-100">
          <h5 class="card-header">{{ $t('labels.configurationPanel.groupAndRole.users') }}</h5>
        </div>
        <div class="text-right p-0 m-0">
          <button
            id="show-add-new-user-panel"
            class="list-group-item smartSISPanel addUserButton"
            @click="showAddUserPopup()"
          >
            <span>
              <img class="showUserPopupImg" v-bind:src="getButtonImg('plus')">
            </span>
          </button>
        </div>
      </div>
      <div class="list-group groupList" v-bind:style="{height: heightSize}">
        <template v-for="user in usersForCurrentGroup" :key="user.id">
          <div class="row m-0" style="flex-wrap: nowrap">
            <div class="p-0 m-0 w-100 hideOverflow">
              <div class="list-group-item smartSISPanel row addUserRow selected">
                <div :class="['userNameItem', 'col-6'].join(' ')">
                  {{ user.lastName + ' ' + ' ' + user.firstName }}
                </div>
                <div :class="['userEmailItem', 'col-6'].join(' ')">
                  {{ user.email }}
                </div>
              </div>
            </div>
            <div class="text-right p-0" style="margin: 0 1px">
              <button
                id="remove-user"
                class="list-group-item smartSISPanel removeUserButton"
                @click="removeUser(user)"
              >
                <span>
                  <img class="removeUserImg" v-bind:src="getButtonImg('remove')">
                </span>
              </button>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div v-if="showAddUserPanel()" class="addNewUserContainer card smartSISPanel">
      <AddNewUserPanel :usersForCurrentGroup="usersForCurrentGroup"/>
    </div>
  </div>
</template>
<script>

import ImgUtils from '../../../../utils/ImgUtils';
import A from '../../../../constants/actions';
import AddNewUserPanel from './AddNewUserPanel';
import SmartSisUserService from '../../../../services/smartSisUserService';
import {mapGetters} from 'vuex';
import ModelUtils from '../../../../utils/ModelUtils';
import LoggerUtils from '../../../../utils/LoggerUtils';
import DialogUtils from '../../../../utils/DialogUtils';

export default {
  name: 'Users',

  components: {
    AddNewUserPanel
  },

  data: () => {
    return {
      newRoleName: '',
    }
  },

  computed: {
    ...mapGetters({
      showUserPanel: 'showUserPanel',
      getUsersForCurrentGroup: 'getUsersForCurrentGroup',
      getCurrentGroupId: 'getCurrentGroupId',
    }),
    usersForCurrentGroup() {
      let usersForCurrentGroup = JSON.parse(JSON.stringify(this.getUsersForCurrentGroup));
      return usersForCurrentGroup.sort((a, b) => ModelUtils.sortUsers(a, b));
    },
    currentGroupId() {
      return this.getCurrentGroupId;
    },
    heightSize() {
      return this.$store.state.configurationStore.listViewHeight + 'px';
    },
  },

  methods: {
    getButtonImg(name) {
      return ImgUtils.getButtonImg(name, 'svg');
    },
    removeUser(user) {
      DialogUtils.confirmOperation({
        message: this.$i18n.t('confirmationMessages.groupRoleConfigurationRemoveUser', {
          userLastName: user.lastName,
          userFirstName: user.firstName
        }),
        yesAction: () => this.$store.dispatch(A.USER_REMOVE_USER_FROM_CURRENT_GROUP, user)
      });
    },
    showAddUserPopup() {
      this.$store.dispatch(A.USER_ADD_USER_TOGGLE, true);
      const config = {
        onSuccess: (data) => {
          this.$store.dispatch(A.USER_READ_ALL_USERS, data)
        },
        onError: (error) => {
          LoggerUtils.error(this.$i18n.t('errorMessages.groupRoleUserLoadError'));
          LoggerUtils.error(JSON.stringify(error));
          DialogUtils.errorNotification(this.$i18n.t('errorMessages.groupRoleUserLoadError'));
        }
      };
      SmartSisUserService.getAllUsers(config);
    },
    showAddUserPanel() {
      return this.showUserPanel;
    },
  }

}

</script>
<style src="./userEditor.css"></style>