import AppConfig from '../config/appConfig';

export default class NotificationConfig {

  static notifications = [
    {
      id: 'audio',
      name: 'Audio',
      visible: true,
    },
    {
      id: 'popup',
      name: 'Pop-up',
      visible: AppConfig.features.popups,
    },
    {
      id: 'visual',
      name: 'Visual',
      visible: true,
    }
  ];

  static getNotifications() {
    return NotificationConfig.notifications;
  }

}